import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { City, DEFAULT_CITY, ITALIAN_CITIES } from "Assets/ItalianCities";
import { COUNTRIES, Country, DEFAULT_COUNTRY } from "Assets/Nations";
import { hexToRgba } from "Utils/ColorConverter";
import { ValueAndSetter } from "Utils/ValueAndSetter";
import { FunctionComponent } from "react";

const COUNTRY_AUTOCOMPLETE = {
  placeholder: "Seleziona una nazione",
  label: "Nazione",
  id: "select-nation-autocomplete-id",
};
const CITY_AUTOCOMPLETE = {
  placeholder: "Seleziona una città",
  label: "Città",
  id: "select-city-autocomplete-id",
};
const SELECT_DATE_PLACEHOLDER = "Seleziona una data";

interface PickupDeliveryCardProps {
  title: string;
  selectedCountry: ValueAndSetter<Country>;
  selectedCity: ValueAndSetter<City>;
  selectedDate: ValueAndSetter<Date | undefined>;
}

export const PickupDeliveryCard: FunctionComponent<PickupDeliveryCardProps> = (
  props
) => {
  return (
    <Card sx={{ backgroundColor: `${hexToRgba("#8470FF", 0.1)} !important` }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {props.title}
        </Typography>
        <FormControl fullWidth>
          <Grid container rowGap={2} columnGap={1}>
            <Grid item xs={5}>
              <Autocomplete
                disableClearable
                fullWidth={true}
                getOptionLabel={(option) => option.label}
                id={COUNTRY_AUTOCOMPLETE.id}
                options={COUNTRIES}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={COUNTRY_AUTOCOMPLETE.label}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-value",
                    }}
                  />
                )}
                size={"small"}
                value={props.selectedCountry.value}
                onChange={(_, newInputValue) => {
                  props.selectedCountry.setValue(
                    COUNTRIES.find((country) => country === newInputValue) ||
                      DEFAULT_COUNTRY
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                disabled={props.selectedCountry.value !== DEFAULT_COUNTRY}
                disableClearable
                fullWidth
                getOptionLabel={(option) => option.label}
                id={CITY_AUTOCOMPLETE.id}
                options={ITALIAN_CITIES}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.label}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={CITY_AUTOCOMPLETE.label}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-value",
                    }}
                  />
                )}
                size={"small"}
                value={
                  props.selectedCountry.value === DEFAULT_COUNTRY
                    ? props.selectedCity.value
                    : undefined
                }
                onChange={(_, newInputValue) => {
                  props.selectedCity.setValue(
                    ITALIAN_CITIES.find((city) => city === newInputValue) ||
                      DEFAULT_CITY
                  );
                }}
              />
              {/* TODO: 
                    1. Add a date picker and use it for increasing the price.
                       Per la consegna nel tempo minimo, bisogna aggiungere 40% al prezzo
                       per la categoria di peso più bassa, e fino a 20% per quella più alta.
                       Vedi foglio VIGETans per calcolare meglio queste percentuali, e 
                       usa quel foglio per capire i tempi minimi di consegna in base ai KM 
                       da percorrere.
                    2. Aggiungi un bottone per accettare l'offerta e andare al pagamento, 
                       e scrivere "pagina in costruzione" su quella pagina. 
                      */}
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                disablePast
                format={"DD-MM-YYYY"}
                label={SELECT_DATE_PLACEHOLDER}
                slotProps={{ textField: { size: "small" } }}
                value={props.selectedDate.value}
                onChange={(newValue) => {
                  props.selectedDate.setValue(newValue || undefined);
                }}
              />
            </Grid>
          </Grid>
        </FormControl>
      </CardContent>
    </Card>
  );
};
