export interface City {
  label: string;
  longitude: number;
  latitude: number;
}

export const DEFAULT_CITY: City = {
  label: "Milano",
  longitude: 9.1903474,
  latitude: 45.46679408,
};

export const ITALIAN_CITIES: City[] = [
  {
    label: "Agliè",
    longitude: 7.7686,
    latitude: 45.36343304,
  },
  {
    label: "Airasca",
    longitude: 7.48443104,
    latitude: 44.91688598,
  },
  {
    label: "Ala di Stura",
    longitude: 7.30434392,
    latitude: 45.31511014,
  },
  {
    label: "Albiano d'Ivrea",
    longitude: 7.94914491,
    latitude: 45.43389336,
  },
  {
    label: "Alice Superiore",
    longitude: 7.77701858,
    latitude: 45.4600936,
  },
  {
    label: "Almese",
    longitude: 7.39518194,
    latitude: 45.11766321,
  },
  {
    label: "Alpette",
    longitude: 7.57859732,
    latitude: 45.40998223,
  },
  {
    label: "Alpignano",
    longitude: 7.52426733,
    latitude: 45.09446973,
  },
  {
    label: "Andezeno",
    longitude: 7.86994864,
    latitude: 45.03586095,
  },
  {
    label: "Andrate",
    longitude: 7.87383937,
    latitude: 45.52837479,
  },
  {
    label: "Angrogna",
    longitude: 7.22429215,
    latitude: 44.84344991,
  },
  {
    label: "Arignano",
    longitude: 7.90244635,
    latitude: 45.04402899,
  },
  {
    label: "Avigliana",
    longitude: 7.39632339,
    latitude: 45.07935742,
  },
  {
    label: "Azeglio",
    longitude: 7.9947707,
    latitude: 45.4248619,
  },
  {
    label: "Bairo",
    longitude: 7.75691809,
    latitude: 45.38624929,
  },
  {
    label: "Balangero",
    longitude: 7.51930957,
    latitude: 45.27003001,
  },
  {
    label: "Baldissero Canavese",
    longitude: 7.74444451,
    latitude: 45.40840358,
  },
  {
    label: "Baldissero Torinese",
    longitude: 7.81780767,
    latitude: 45.06842334,
  },
  {
    label: "Balme",
    longitude: 7.21831414,
    latitude: 45.30229406,
  },
  {
    label: "Banchette",
    longitude: 7.85656384,
    latitude: 45.4608501,
  },
  {
    label: "Barbania",
    longitude: 7.63051093,
    latitude: 45.2905002,
  },
  {
    label: "Bardonecchia",
    longitude: 6.70382393,
    latitude: 45.07816901,
  },
  {
    label: "Barone Canavese",
    longitude: 7.87055626,
    latitude: 45.32772558,
  },
  {
    label: "Beinasco",
    longitude: 7.57915804,
    latitude: 45.02207899,
  },
  {
    label: "Bibiana",
    longitude: 7.28978596,
    latitude: 44.79758015,
  },
  {
    label: "Bobbio Pellice",
    longitude: 7.11790449,
    latitude: 44.80770617,
  },
  {
    label: "Bollengo",
    longitude: 7.94534881,
    latitude: 45.4733572,
  },
  {
    label: "Borgaro Torinese",
    longitude: 7.65789441,
    latitude: 45.15198241,
  },
  {
    label: "Borgiallo",
    longitude: 7.66773369,
    latitude: 45.4168132,
  },
  {
    label: "Borgofranco d'Ivrea",
    longitude: 7.85852209,
    latitude: 45.51300686,
  },
  {
    label: "Borgomasino",
    longitude: 7.98879962,
    latitude: 45.36155134,
  },
  {
    label: "Borgone Susa",
    longitude: 7.24121397,
    latitude: 45.12313936,
  },
  {
    label: "Bosconero",
    longitude: 7.76506984,
    latitude: 45.26856393,
  },
  {
    label: "Brandizzo",
    longitude: 7.84147562,
    latitude: 45.17765247,
  },
  {
    label: "Bricherasio",
    longitude: 7.30288754,
    latitude: 44.82558164,
  },
  {
    label: "Brosso",
    longitude: 7.80333443,
    latitude: 45.49276005,
  },
  {
    label: "Brozolo",
    longitude: 8.07127843,
    latitude: 45.1174193,
  },
  {
    label: "Bruino",
    longitude: 7.46769111,
    latitude: 45.0202325,
  },
  {
    label: "Brusasco",
    longitude: 8.06009417,
    latitude: 45.15595512,
  },
  {
    label: "Bruzolo",
    longitude: 7.19397692,
    latitude: 45.14312487,
  },
  {
    label: "Buriasco",
    longitude: 7.41192286,
    latitude: 44.87335846,
  },
  {
    label: "Burolo",
    longitude: 7.93205672,
    latitude: 45.48411969,
  },
  {
    label: "Busano",
    longitude: 7.6557437,
    latitude: 45.32883648,
  },
  {
    label: "Bussoleno",
    longitude: 7.14931876,
    latitude: 45.13727952,
  },
  {
    label: "Buttigliera Alta",
    longitude: 7.43678073,
    latitude: 45.06701514,
  },
  {
    label: "Cafasse",
    longitude: 7.51812356,
    latitude: 45.24471151,
  },
  {
    label: "Caluso",
    longitude: 7.89150532,
    latitude: 45.30786205,
  },
  {
    label: "Cambiano",
    longitude: 7.77932471,
    latitude: 44.9734596,
  },
  {
    label: "Campiglione Fenile",
    longitude: 7.32503803,
    latitude: 44.8021244,
  },
  {
    label: "Candia Canavese",
    longitude: 7.8866125,
    latitude: 45.32816809,
  },
  {
    label: "Candiolo",
    longitude: 7.60121104,
    latitude: 44.96026547,
  },
  {
    label: "Canischio",
    longitude: 7.59521831,
    latitude: 45.37434585,
  },
  {
    label: "Cantalupa",
    longitude: 7.33260383,
    latitude: 44.94030975,
  },
  {
    label: "Cantoira",
    longitude: 7.38167977,
    latitude: 45.34323553,
  },
  {
    label: "Caprie",
    longitude: 7.33250655,
    latitude: 45.11786515,
  },
  {
    label: "Caravino",
    longitude: 7.9602136,
    latitude: 45.39860532,
  },
  {
    label: "Carema",
    longitude: 7.81230448,
    latitude: 45.58444747,
  },
  {
    label: "Carignano",
    longitude: 7.67534298,
    latitude: 44.90666664,
  },
  {
    label: "Carmagnola",
    longitude: 7.71815745,
    latitude: 44.84654865,
  },
  {
    label: "Casalborgone",
    longitude: 7.94044052,
    latitude: 45.1299627,
  },
  {
    label: "Cascinette d'Ivrea",
    longitude: 7.90469845,
    latitude: 45.47845422,
  },
  {
    label: "Caselette",
    longitude: 7.48320717,
    latitude: 45.10588105,
  },
  {
    label: "Caselle Torinese",
    longitude: 7.64818634,
    latitude: 45.17716554,
  },
  {
    label: "Castagneto Po",
    longitude: 7.88698844,
    latitude: 45.16060833,
  },
  {
    label: "Castagnole Piemonte",
    longitude: 7.56655619,
    latitude: 44.8986196,
  },
  {
    label: "Castellamonte",
    longitude: 7.70986533,
    latitude: 45.3833354,
  },
  {
    label: "Castelnuovo Nigra",
    longitude: 7.69511431,
    latitude: 45.43732305,
  },
  {
    label: "Castiglione Torinese",
    longitude: 7.8160937,
    latitude: 45.12330917,
  },
  {
    label: "Cavagnolo",
    longitude: 8.05131021,
    latitude: 45.15207451,
  },
  {
    label: "Cavour",
    longitude: 7.37523198,
    latitude: 44.78403126,
  },
  {
    label: "Cercenasco",
    longitude: 7.50125803,
    latitude: 44.86126074,
  },
  {
    label: "Ceres",
    longitude: 7.38934335,
    latitude: 45.31307899,
  },
  {
    label: "Ceresole Reale",
    longitude: 7.23254114,
    latitude: 45.43261794,
  },
  {
    label: "Cesana Torinese",
    longitude: 6.79422776,
    latitude: 44.95241086,
  },
  {
    label: "Chialamberto",
    longitude: 7.34570078,
    latitude: 45.36285948,
  },
  {
    label: "Chianocco",
    longitude: 7.16939346,
    latitude: 45.1485858,
  },
  {
    label: "Chiaverano",
    longitude: 7.90312753,
    latitude: 45.49939701,
  },
  {
    label: "Chieri",
    longitude: 7.82107157,
    latitude: 45.01315317,
  },
  {
    label: "Chiesanuova",
    longitude: 7.65605931,
    latitude: 45.41780821,
  },
  {
    label: "Chiomonte",
    longitude: 6.98338614,
    latitude: 45.11938583,
  },
  {
    label: "Chiusa di San Michele",
    longitude: 7.3264449,
    latitude: 45.10154711,
  },
  {
    label: "Chivasso",
    longitude: 7.8871395,
    latitude: 45.18855522,
  },
  {
    label: "Ciconio",
    longitude: 7.75987216,
    latitude: 45.33032199,
  },
  {
    label: "Cintano",
    longitude: 7.68662115,
    latitude: 45.42805026,
  },
  {
    label: "Cinzano",
    longitude: 7.92112148,
    latitude: 45.09389859,
  },
  {
    label: "Ciriè",
    longitude: 7.60378213,
    latitude: 45.23202337,
  },
  {
    label: "Claviere",
    longitude: 6.7493063,
    latitude: 44.93861812,
  },
  {
    label: "Coassolo Torinese",
    longitude: 7.46132937,
    latitude: 45.30071349,
  },
  {
    label: "Coazze",
    longitude: 7.29464354,
    latitude: 45.05141806,
  },
  {
    label: "Collegno",
    longitude: 7.56912947,
    latitude: 45.07740152,
  },
  {
    label: "Colleretto Castelnuovo",
    longitude: 7.67898169,
    latitude: 45.42140642,
  },
  {
    label: "Colleretto Giacosa",
    longitude: 7.79996443,
    latitude: 45.43288813,
  },
  {
    label: "Condove",
    longitude: 7.31072991,
    latitude: 45.11714338,
  },
  {
    label: "Corio",
    longitude: 7.53256155,
    latitude: 45.31274311,
  },
  {
    label: "Cossano Canavese",
    longitude: 7.99337324,
    latitude: 45.38833099,
  },
  {
    label: "Cuceglio",
    longitude: 7.81484395,
    latitude: 45.35892893,
  },
  {
    label: "Cumiana",
    longitude: 7.37437677,
    latitude: 44.98279566,
  },
  {
    label: "Cuorgnè",
    longitude: 7.64891501,
    latitude: 45.39122707,
  },
  {
    label: "Druento",
    longitude: 7.57620424,
    latitude: 45.13473277,
  },
  {
    label: "Exilles",
    longitude: 6.9296242,
    latitude: 45.09749088,
  },
  {
    label: "Favria",
    longitude: 7.68801437,
    latitude: 45.33284709,
  },
  {
    label: "Feletto",
    longitude: 7.747108,
    latitude: 45.30549111,
  },
  {
    label: "Fenestrelle",
    longitude: 7.05188671,
    latitude: 45.03429618,
  },
  {
    label: "Fiano",
    longitude: 7.51978357,
    latitude: 45.21812133,
  },
  {
    label: "Fiorano Canavese",
    longitude: 7.82897738,
    latitude: 45.46756986,
  },
  {
    label: "Foglizzo",
    longitude: 7.82317874,
    latitude: 45.27201738,
  },
  {
    label: "Forno Canavese",
    longitude: 7.58678049,
    latitude: 45.34683712,
  },
  {
    label: "Frassinetto",
    longitude: 7.60567906,
    latitude: 45.43603247,
  },
  {
    label: "Front",
    longitude: 7.66559657,
    latitude: 45.2795507,
  },
  {
    label: "Frossasco",
    longitude: 7.35077043,
    latitude: 44.93362239,
  },
  {
    label: "Garzigliana",
    longitude: 7.37473116,
    latitude: 44.83677569,
  },
  {
    label: "Gassino Torinese",
    longitude: 7.8248966,
    latitude: 45.1274528,
  },
  {
    label: "Germagnano",
    longitude: 7.46686447,
    latitude: 45.26218096,
  },
  {
    label: "Giaglione",
    longitude: 7.01413849,
    latitude: 45.13968801,
  },
  {
    label: "Giaveno",
    longitude: 7.35308652,
    latitude: 45.04475307,
  },
  {
    label: "Givoletto",
    longitude: 7.49677295,
    latitude: 45.15877935,
  },
  {
    label: "Gravere",
    longitude: 7.02107286,
    latitude: 45.1239184,
  },
  {
    label: "Groscavallo",
    longitude: 7.28229522,
    latitude: 45.36895345,
  },
  {
    label: "Grosso",
    longitude: 7.55663354,
    latitude: 45.25636722,
  },
  {
    label: "Grugliasco",
    longitude: 7.57780057,
    latitude: 45.06211095,
  },
  {
    label: "Ingria",
    longitude: 7.57086278,
    latitude: 45.46629106,
  },
  {
    label: "Inverso Pinasca",
    longitude: 7.21596397,
    latitude: 44.94722972,
  },
  {
    label: "Isolabella",
    longitude: 7.90926775,
    latitude: 44.90664468,
  },
  {
    label: "Issiglio",
    longitude: 7.75416284,
    latitude: 45.44680942,
  },
  {
    label: "Ivrea",
    longitude: 7.87596694,
    latitude: 45.46618879,
  },
  {
    label: "La Cassa",
    longitude: 7.51913001,
    latitude: 45.18182357,
  },
  {
    label: "La Loggia",
    longitude: 7.66770716,
    latitude: 44.9566781,
  },
  {
    label: "Lanzo Torinese",
    longitude: 7.4766156,
    latitude: 45.27231992,
  },
  {
    label: "Lauriano",
    longitude: 7.99166951,
    latitude: 45.15845502,
  },
  {
    label: "Leini",
    longitude: 7.71510758,
    latitude: 45.18393696,
  },
  {
    label: "Lemie",
    longitude: 7.29288179,
    latitude: 45.2285566,
  },
  {
    label: "Lessolo",
    longitude: 7.81421978,
    latitude: 45.47615621,
  },
  {
    label: "Levone",
    longitude: 7.60590068,
    latitude: 45.31810409,
  },
  {
    label: "Locana",
    longitude: 7.46126241,
    latitude: 45.41699254,
  },
  {
    label: "Lombardore",
    longitude: 7.73626699,
    latitude: 45.23570752,
  },
  {
    label: "Lombriasco",
    longitude: 7.6357787,
    latitude: 44.84160233,
  },
  {
    label: "Loranzè",
    longitude: 7.8108711,
    latitude: 45.44223125,
  },
  {
    label: "Lugnacco",
    longitude: 7.7806447,
    latitude: 45.44465477,
  },
  {
    label: "Luserna San Giovanni",
    longitude: 7.24579065,
    latitude: 44.81432177,
  },
  {
    label: "Lusernetta",
    longitude: 7.25023582,
    latitude: 44.8023662,
  },
  {
    label: "Lusigliè",
    longitude: 7.76646663,
    latitude: 45.31856534,
  },
  {
    label: "Macello",
    longitude: 7.40079259,
    latitude: 44.85117887,
  },
  {
    label: "Maglione",
    longitude: 8.01174454,
    latitude: 45.34770337,
  },
  {
    label: "Marentino",
    longitude: 7.87332971,
    latitude: 45.05800482,
  },
  {
    label: "Massello",
    longitude: 7.05631276,
    latitude: 44.95856162,
  },
  {
    label: "Mathi",
    longitude: 7.54132093,
    latitude: 45.25664137,
  },
  {
    label: "Mattie",
    longitude: 7.11215191,
    latitude: 45.1187052,
  },
  {
    label: "Mazzè",
    longitude: 7.93700162,
    latitude: 45.29853622,
  },
  {
    label: "Meana di Susa",
    longitude: 7.06814068,
    latitude: 45.12073332,
  },
  {
    label: "Mercenasco",
    longitude: 7.88256683,
    latitude: 45.35697859,
  },
  {
    label: "Meugliano",
    longitude: 7.7789622,
    latitude: 45.49006995,
  },
  {
    label: "Mezzenile",
    longitude: 7.39502001,
    latitude: 45.29240402,
  },
  {
    label: "Mombello di Torino",
    longitude: 7.9195483,
    latitude: 45.0464001,
  },
  {
    label: "Mompantero",
    longitude: 7.0579733,
    latitude: 45.13990657,
  },
  {
    label: "Monastero di Lanzo",
    longitude: 7.43913232,
    latitude: 45.30142445,
  },
  {
    label: "Moncalieri",
    longitude: 7.68524785,
    latitude: 45.00092557,
  },
  {
    label: "Moncenisio",
    longitude: 6.98356095,
    latitude: 45.20528865,
  },
  {
    label: "Montaldo Torinese",
    longitude: 7.85089356,
    latitude: 45.06636449,
  },
  {
    label: "Montalenghe",
    longitude: 7.83985641,
    latitude: 45.33772294,
  },
  {
    label: "Montalto Dora",
    longitude: 7.86379472,
    latitude: 45.49362402,
  },
  {
    label: "Montanaro",
    longitude: 7.85282569,
    latitude: 45.23289425,
  },
  {
    label: "Monteu da Po",
    longitude: 8.01478453,
    latitude: 45.15054215,
  },
  {
    label: "Moriondo Torinese",
    longitude: 7.94405562,
    latitude: 45.03906243,
  },
  {
    label: "Nichelino",
    longitude: 7.64737268,
    latitude: 44.99519052,
  },
  {
    label: "Noasca",
    longitude: 7.31354369,
    latitude: 45.45305086,
  },
  {
    label: "Nole",
    longitude: 7.57012122,
    latitude: 45.24523673,
  },
  {
    label: "Nomaglio",
    longitude: 7.85971537,
    latitude: 45.53518242,
  },
  {
    label: "None",
    longitude: 7.54123958,
    latitude: 44.93231301,
  },
  {
    label: "Novalesa",
    longitude: 7.01466579,
    latitude: 45.18763602,
  },
  {
    label: "Oglianico",
    longitude: 7.69274392,
    latitude: 45.34256696,
  },
  {
    label: "Orbassano",
    longitude: 7.53695676,
    latitude: 45.00703303,
  },
  {
    label: "Orio Canavese",
    longitude: 7.86029968,
    latitude: 45.32848958,
  },
  {
    label: "Osasco",
    longitude: 7.34316619,
    latitude: 44.84947467,
  },
  {
    label: "Osasio",
    longitude: 7.60752807,
    latitude: 44.86946279,
  },
  {
    label: "Oulx",
    longitude: 6.83221872,
    latitude: 45.03127204,
  },
  {
    label: "Ozegna",
    longitude: 7.74313424,
    latitude: 45.34901489,
  },
  {
    label: "Palazzo Canavese",
    longitude: 7.9780495,
    latitude: 45.45747804,
  },
  {
    label: "Pancalieri",
    longitude: 7.58816166,
    latitude: 44.83047398,
  },
  {
    label: "Parella",
    longitude: 7.7910275,
    latitude: 45.42998528,
  },
  {
    label: "Pavarolo",
    longitude: 7.83475798,
    latitude: 45.06892615,
  },
  {
    label: "Pavone Canavese",
    longitude: 7.85397607,
    latitude: 45.4352612,
  },
  {
    label: "Pecco",
    longitude: 7.77647961,
    latitude: 45.45206809,
  },
  {
    label: "Pecetto Torinese",
    longitude: 7.74869987,
    latitude: 45.01955866,
  },
  {
    label: "Perosa Argentina",
    longitude: 7.19237022,
    latitude: 44.95809915,
  },
  {
    label: "Perosa Canavese",
    longitude: 7.82925599,
    latitude: 45.39715691,
  },
  {
    label: "Perrero",
    longitude: 7.11526705,
    latitude: 44.93745704,
  },
  {
    label: "Pertusio",
    longitude: 7.63872415,
    latitude: 45.35339905,
  },
  {
    label: "Pessinetto",
    longitude: 7.40284722,
    latitude: 45.29054324,
  },
  {
    label: "Pianezza",
    longitude: 7.54635756,
    latitude: 45.10062332,
  },
  {
    label: "Pinasca",
    longitude: 7.22767284,
    latitude: 44.94266195,
  },
  {
    label: "Pinerolo",
    longitude: 7.33200099,
    latitude: 44.88733486,
  },
  {
    label: "Pino Torinese",
    longitude: 7.77216514,
    latitude: 45.0435201,
  },
  {
    label: "Piobesi Torinese",
    longitude: 7.61193106,
    latitude: 44.93305708,
  },
  {
    label: "Piossasco",
    longitude: 7.46275512,
    latitude: 44.99070006,
  },
  {
    label: "Piscina",
    longitude: 7.42589738,
    latitude: 44.9194642,
  },
  {
    label: "Piverone",
    longitude: 8.00710274,
    latitude: 45.44754377,
  },
  {
    label: "Poirino",
    longitude: 7.84361932,
    latitude: 44.92119885,
  },
  {
    label: "Pomaretto",
    longitude: 7.18220303,
    latitude: 44.95506624,
  },
  {
    label: "Pont-Canavese",
    longitude: 7.59357096,
    latitude: 45.42072843,
  },
  {
    label: "Porte",
    longitude: 7.2679851,
    latitude: 44.88756523,
  },
  {
    label: "Pragelatitudeo",
    longitude: 6.94229337,
    latitude: 45.0162756,
  },
  {
    label: "Prali",
    longitude: 7.04904363,
    latitude: 44.89040764,
  },
  {
    label: "Pralormo",
    longitude: 7.90066311,
    latitude: 44.86135787,
  },
  {
    label: "Pramollo",
    longitude: 7.21169848,
    latitude: 44.90458825,
  },
  {
    label: "Prarostino",
    longitude: 7.2697214,
    latitude: 44.86584045,
  },
  {
    label: "Prascorsano",
    longitude: 7.61570863,
    latitude: 45.36765655,
  },
  {
    label: "Pratiglione",
    longitude: 7.59609066,
    latitude: 45.35364806,
  },
  {
    label: "Quagliuzzo",
    longitude: 7.78183655,
    latitude: 45.42649496,
  },
  {
    label: "Quassolo",
    longitude: 7.83247599,
    latitude: 45.52493469,
  },
  {
    label: "Quincinetto",
    longitude: 7.80576899,
    latitude: 45.56081088,
  },
  {
    label: "Reano",
    longitude: 7.42955396,
    latitude: 45.05170702,
  },
  {
    label: "Ribordone",
    longitude: 7.50241918,
    latitude: 45.43160898,
  },
  {
    label: "Rivalba",
    longitude: 7.8870447,
    latitude: 45.11880638,
  },
  {
    label: "Rivalta di Torino",
    longitude: 7.52329257,
    latitude: 45.03454517,
  },
  {
    label: "Riva presso Chieri",
    longitude: 7.86919927,
    latitude: 44.98628102,
  },
  {
    label: "Rivara",
    longitude: 7.62506151,
    latitude: 45.33231529,
  },
  {
    label: "Rivarolo Canavese",
    longitude: 7.7252213,
    latitude: 45.33279488,
  },
  {
    label: "Rivarossa",
    longitude: 7.71719961,
    latitude: 45.25127899,
  },
  {
    label: "Rivoli",
    longitude: 7.53394487,
    latitude: 45.07062854,
  },
  {
    label: "Robassomero",
    longitude: 7.57015928,
    latitude: 45.20008262,
  },
  {
    label: "Rocca Canavese",
    longitude: 7.57853704,
    latitude: 45.30940721,
  },
  {
    label: "Roletto",
    longitude: 7.33066832,
    latitude: 44.92524919,
  },
  {
    label: "Romano Canavese",
    longitude: 7.86790447,
    latitude: 45.39134325,
  },
  {
    label: "Ronco Canavese",
    longitude: 7.54567243,
    latitude: 45.4994291,
  },
  {
    label: "Rondissone",
    longitude: 7.96581632,
    latitude: 45.24678796,
  },
  {
    label: "Rorà",
    longitude: 7.19849234,
    latitude: 44.79230499,
  },
  {
    label: "Roure",
    longitude: 7.12908516,
    latitude: 45.00108393,
  },
  {
    label: "Rosta",
    longitude: 7.4639672,
    latitude: 45.07142005,
  },
  {
    label: "Rubiana",
    longitude: 7.38239842,
    latitude: 45.13920427,
  },
  {
    label: "Rueglio",
    longitude: 7.75582201,
    latitude: 45.46784892,
  },
  {
    label: "Salassa",
    longitude: 7.68939186,
    latitude: 45.35955646,
  },
  {
    label: "Salbertrand",
    longitude: 6.88368812,
    latitude: 45.07237164,
  },
  {
    label: "Salerano Canavese",
    longitude: 7.84990248,
    latitude: 45.45644258,
  },
  {
    label: "Salza di Pinerolo",
    longitude: 7.05324489,
    latitude: 44.94054394,
  },
  {
    label: "Samone",
    longitude: 7.84564332,
    latitude: 45.44928553,
  },
  {
    label: "San Benigno Canavese",
    longitude: 7.78265511,
    latitude: 45.22396157,
  },
  {
    label: "San Carlo Canavese",
    longitude: 7.61016157,
    latitude: 45.24815307,
  },
  {
    label: "San Colombano Belmonte",
    longitude: 7.61845112,
    latitude: 45.38143971,
  },
  {
    label: "San Didero",
    longitude: 7.21515502,
    latitude: 45.13516257,
  },
  {
    label: "San Francesco al Campo",
    longitude: 7.65566462,
    latitude: 45.22553762,
  },
  {
    label: "Sangano",
    longitude: 7.45053852,
    latitude: 45.0262359,
  },
  {
    label: "San Germano Chisone",
    longitude: 7.24085653,
    latitude: 44.89871585,
  },
  {
    label: "San Gillio",
    longitude: 7.53383254,
    latitude: 45.14205994,
  },
  {
    label: "San Giorgio Canavese",
    longitude: 7.79425943,
    latitude: 45.33544394,
  },
  {
    label: "San Giorio di Susa",
    longitude: 7.17753154,
    latitude: 45.12841513,
  },
  {
    label: "San Giusto Canavese",
    longitude: 7.80833346,
    latitude: 45.31221415,
  },
  {
    label: "San Martino Canavese",
    longitude: 7.81836251,
    latitude: 45.39459427,
  },
  {
    label: "San Maurizio Canavese",
    longitude: 7.63075305,
    latitude: 45.21911823,
  },
  {
    label: "San Mauro Torinese",
    longitude: 7.76632833,
    latitude: 45.10309541,
  },
  {
    label: "San Pietro Val Lemina",
    longitude: 7.31355518,
    latitude: 44.90732828,
  },
  {
    label: "San Ponso",
    longitude: 7.66751665,
    latitude: 45.35081649,
  },
  {
    label: "San Raffaele Cimena",
    longitude: 7.84869672,
    latitude: 45.147225,
  },
  {
    label: "San Sebastiano da Po",
    longitude: 7.9534693,
    latitude: 45.16644198,
  },
  {
    label: "San Secondo di Pinerolo",
    longitude: 7.29864772,
    latitude: 44.86656606,
  },
  {
    label: "Sant'Ambrogio di Torino",
    longitude: 7.35931306,
    latitude: 45.09968774,
  },
  {
    label: "Sant'Antonino di Susa",
    longitude: 7.27350974,
    latitude: 45.10708303,
  },
  {
    label: "Santena",
    longitude: 7.77316006,
    latitude: 44.94968716,
  },
  {
    label: "Sauze di Cesana",
    longitude: 6.86049707,
    latitude: 44.94044645,
  },
  {
    label: "Sauze d'Oulx",
    longitude: 6.85824935,
    latitude: 45.02808681,
  },
  {
    label: "Scalenghe",
    longitude: 7.49796536,
    latitude: 44.88830917,
  },
  {
    label: "Scarmagno",
    longitude: 7.8407106,
    latitude: 45.38582635,
  },
  {
    label: "Sciolze",
    longitude: 7.87766236,
    latitude: 45.09503651,
  },
  {
    label: "Sestriere",
    longitude: 6.87655108,
    latitude: 44.95764886,
  },
  {
    label: "Settimo Rottaro",
    longitude: 7.99223087,
    latitude: 45.40489983,
  },
  {
    label: "Settimo Torinese",
    longitude: 7.77124007,
    latitude: 45.13698893,
  },
  {
    label: "Settimo Vittone",
    longitude: 7.831721,
    latitude: 45.54810664,
  },
  {
    label: "Sparone",
    longitude: 7.54421421,
    latitude: 45.41330062,
  },
  {
    label: "Strambinello",
    longitude: 7.76866207,
    latitude: 45.42324307,
  },
  {
    label: "Strambino",
    longitude: 7.88062828,
    latitude: 45.38458718,
  },
  {
    label: "Susa",
    longitude: 7.04641962,
    latitude: 45.13525075,
  },
  {
    label: "Tavagnasco",
    longitude: 7.82261044,
    latitude: 45.54322012,
  },
  {
    label: "Torino",
    longitude: 7.68068748,
    latitude: 45.0732745,
  },
  {
    label: "Torrazza Piemonte",
    longitude: 7.9749012,
    latitude: 45.21520992,
  },
  {
    label: "Torre Canavese",
    longitude: 7.76053919,
    latitude: 45.39182464,
  },
  {
    label: "Torre Pellice",
    longitude: 7.22397861,
    latitude: 44.82074148,
  },
  {
    label: "Trana",
    longitude: 7.41730319,
    latitude: 45.03709504,
  },
  {
    label: "Trausella",
    longitude: 7.76450377,
    latitude: 45.49022243,
  },
  {
    label: "Traversella",
    longitude: 7.75041006,
    latitude: 45.5094096,
  },
  {
    label: "Traves",
    longitude: 7.42974781,
    latitude: 45.27057879,
  },
  {
    label: "Trofarello",
    longitude: 7.7403033,
    latitude: 44.98596444,
  },
  {
    label: "Usseaux",
    longitude: 7.02687343,
    latitude: 45.04849799,
  },
  {
    label: "Usseglio",
    longitude: 7.21722031,
    latitude: 45.23272497,
  },
  {
    label: "Vaie",
    longitude: 7.28663072,
    latitude: 45.10189314,
  },
  {
    label: "Val della Torre",
    longitude: 7.44941143,
    latitude: 45.15556488,
  },
  {
    label: "Valgioie",
    longitude: 7.33963962,
    latitude: 45.07600826,
  },
  {
    label: "Vallo Torinese",
    longitude: 7.49928924,
    latitude: 45.22350106,
  },
  {
    label: "Valperga",
    longitude: 7.6567871,
    latitude: 45.37027102,
  },
  {
    label: "Valprato Soana",
    longitude: 7.5489665,
    latitude: 45.5223738,
  },
  {
    label: "Varisella",
    longitude: 7.48902531,
    latitude: 45.20984085,
  },
  {
    label: "Vauda Canavese",
    longitude: 7.6182079,
    latitude: 45.27924268,
  },
  {
    label: "Venaus",
    longitude: 7.01194778,
    latitude: 45.15470177,
  },
  {
    label: "Venaria Reale",
    longitude: 7.63149732,
    latitude: 45.1325374,
  },
  {
    label: "Verolengo",
    longitude: 7.96715786,
    latitude: 45.18904937,
  },
  {
    label: "Verrua Savoia",
    longitude: 8.09314758,
    latitude: 45.15655009,
  },
  {
    label: "Vestignè",
    longitude: 7.95387863,
    latitude: 45.38670164,
  },
  {
    label: "Vialfrè",
    longitude: 7.81817187,
    latitude: 45.38083894,
  },
  {
    label: "Vico Canavese",
    longitude: 7.77942268,
    latitude: 45.49432405,
  },
  {
    label: "Vidracco",
    longitude: 7.75901516,
    latitude: 45.43045472,
  },
  {
    label: "Vigone",
    longitude: 7.49452417,
    latitude: 44.84235593,
  },
  {
    label: "Villafranca Piemonte",
    longitude: 7.50450846,
    latitude: 44.78128218,
  },
  {
    label: "Villanova Canavese",
    longitude: 7.55354045,
    latitude: 45.24293558,
  },
  {
    label: "Villarbasse",
    longitude: 7.46800102,
    latitude: 45.04487159,
  },
  {
    label: "Villar Dora",
    longitude: 7.38382219,
    latitude: 45.11783797,
  },
  {
    label: "Villareggia",
    longitude: 7.97850007,
    latitude: 45.31101176,
  },
  {
    label: "Villar Focchiardo",
    longitude: 7.22668959,
    latitude: 45.10971506,
  },
  {
    label: "Villar Pellice",
    longitude: 7.15827939,
    latitude: 44.80859193,
  },
  {
    label: "Villar Perosa",
    longitude: 7.24926893,
    latitude: 44.92019309,
  },
  {
    label: "Villastellone",
    longitude: 7.7453532,
    latitude: 44.92155495,
  },
  {
    label: "Vinovo",
    longitude: 7.63424154,
    latitude: 44.94878443,
  },
  {
    label: "Virle Piemonte",
    longitude: 7.56889069,
    latitude: 44.86594197,
  },
  {
    label: "Vische",
    longitude: 7.95138039,
    latitude: 45.33156931,
  },
  {
    label: "Vistrorio",
    longitude: 7.76941978,
    latitude: 45.44109707,
  },
  {
    label: "Viù",
    longitude: 7.37646792,
    latitude: 45.23894208,
  },
  {
    label: "Volpiano",
    longitude: 7.77555033,
    latitude: 45.20189898,
  },
  {
    label: "Volvera",
    longitude: 7.51230483,
    latitude: 44.95502486,
  },
  {
    label: "Mappano",
    longitude: 7.707,
    latitude: 45.1475,
  },
  {
    label: "Alagna Valsesia",
    longitude: 7.93812982,
    latitude: 45.85332586,
  },
  {
    label: "Albano Vercellese",
    longitude: 8.38058282,
    latitude: 45.42511503,
  },
  {
    label: "Alice Castello",
    longitude: 8.07469466,
    latitude: 45.36652862,
  },
  {
    label: "Arborio",
    longitude: 8.38898545,
    latitude: 45.49608576,
  },
  {
    label: "Asigliano Vercellese",
    longitude: 8.40619426,
    latitude: 45.26117617,
  },
  {
    label: "Balmuccia",
    longitude: 8.13825637,
    latitude: 45.81834722,
  },
  {
    label: "Balocco",
    longitude: 8.28103358,
    latitude: 45.45553463,
  },
  {
    label: "Bianzè",
    longitude: 8.12006412,
    latitude: 45.30737818,
  },
  {
    label: "Boccioleto",
    longitude: 8.11329092,
    latitude: 45.82998665,
  },
  {
    label: "Borgo d'Ale",
    longitude: 8.05372704,
    latitude: 45.34991763,
  },
  {
    label: "Borgosesia",
    longitude: 8.27641052,
    latitude: 45.71672956,
  },
  {
    label: "Borgo Vercelli",
    longitude: 8.4663705,
    latitude: 45.35715818,
  },
  {
    label: "Breia",
    longitude: 8.30720564,
    latitude: 45.76411748,
  },
  {
    label: "Buronzo",
    longitude: 8.26833442,
    latitude: 45.48211892,
  },
  {
    label: "Campertogno",
    longitude: 8.03216717,
    latitude: 45.79867325,
  },
  {
    label: "Carcoforo",
    longitude: 8.04890161,
    latitude: 45.90888987,
  },
  {
    label: "Caresana",
    longitude: 8.50648564,
    latitude: 45.22108416,
  },
  {
    label: "Caresanablot",
    longitude: 8.38877273,
    latitude: 45.35735225,
  },
  {
    label: "Carisio",
    longitude: 8.19858607,
    latitude: 45.408461,
  },
  {
    label: "Casanova Elvo",
    longitude: 8.2933069,
    latitude: 45.39952964,
  },
  {
    label: "San Giacomo Vercellese",
    longitude: 8.32534514,
    latitude: 45.49741181,
  },
  {
    label: "Cellio",
    longitude: 8.31309615,
    latitude: 45.75546715,
  },
  {
    label: "Cervatto",
    longitude: 8.16235419,
    latitude: 45.88308084,
  },
  {
    label: "Cigliano",
    longitude: 8.021489,
    latitude: 45.309557,
  },
  {
    label: "Civiasco",
    longitude: 8.29278728,
    latitude: 45.80668518,
  },
  {
    label: "Collobiano",
    longitude: 8.34853582,
    latitude: 45.39712515,
  },
  {
    label: "Costanzana",
    longitude: 8.3700654,
    latitude: 45.23619745,
  },
  {
    label: "Cravagliana",
    longitude: 8.20112963,
    latitude: 45.84734738,
  },
  {
    label: "Crescentino",
    longitude: 8.10258275,
    latitude: 45.19232707,
  },
  {
    label: "Crova",
    longitude: 8.21192995,
    latitude: 45.33003762,
  },
  {
    label: "Desana",
    longitude: 8.35745053,
    latitude: 45.26873384,
  },
  {
    label: "Fobello",
    longitude: 8.15891887,
    latitude: 45.88955756,
  },
  {
    label: "Fontanetto Po",
    longitude: 8.19171653,
    latitude: 45.19339547,
  },
  {
    label: "Formigliana",
    longitude: 8.2929106,
    latitude: 45.42877725,
  },
  {
    label: "Gattinara",
    longitude: 8.37128081,
    latitude: 45.61734452,
  },
  {
    label: "Ghislarengo",
    longitude: 8.38432306,
    latitude: 45.52931694,
  },
  {
    label: "Greggio",
    longitude: 8.38247553,
    latitude: 45.45180309,
  },
  {
    label: "Guardabosone",
    longitude: 8.25032253,
    latitude: 45.7009391,
  },
  {
    label: "Lamporo",
    longitude: 8.0975502,
    latitude: 45.23008153,
  },
  {
    label: "Lenta",
    longitude: 8.38444808,
    latitude: 45.55556369,
  },
  {
    label: "Lignana",
    longitude: 8.34604641,
    latitude: 45.2852959,
  },
  {
    label: "Livorno Ferraris",
    longitude: 8.08199764,
    latitude: 45.28165932,
  },
  {
    label: "Lozzolo",
    longitude: 8.3220795,
    latitude: 45.62258776,
  },
  {
    label: "Mollia",
    longitude: 8.03096665,
    latitude: 45.81528283,
  },
  {
    label: "Moncrivello",
    longitude: 7.99586116,
    latitude: 45.33122991,
  },
  {
    label: "Motta de' Conti",
    longitude: 8.52077102,
    latitude: 45.19150237,
  },
  {
    label: "Olcenengo",
    longitude: 8.31138984,
    latitude: 45.3635942,
  },
  {
    label: "Oldenico",
    longitude: 8.3827208,
    latitude: 45.40235336,
  },
  {
    label: "Palazzolo Vercellese",
    longitude: 8.23282434,
    latitude: 45.18450002,
  },
  {
    label: "Pertengo",
    longitude: 8.41762904,
    latitude: 45.23562007,
  },
  {
    label: "Pezzana",
    longitude: 8.48420194,
    latitude: 45.26206373,
  },
  {
    label: "Pila",
    longitude: 8.08081524,
    latitude: 45.77024834,
  },
  {
    label: "Piode",
    longitude: 8.05308685,
    latitude: 45.76976604,
  },
  {
    label: "Postua",
    longitude: 8.23149631,
    latitude: 45.71418815,
  },
  {
    label: "Prarolo",
    longitude: 8.47934379,
    latitude: 45.28222769,
  },
  {
    label: "Quarona",
    longitude: 8.26542166,
    latitude: 45.75985229,
  },
  {
    label: "Quinto Vercellese",
    longitude: 8.36218487,
    latitude: 45.38047939,
  },
  {
    label: "Rassa",
    longitude: 8.0141935,
    latitude: 45.7686266,
  },
  {
    label: "Rima San Giuseppe",
    longitude: 8.02339104,
    latitude: 45.86531097,
  },
  {
    label: "Rimasco",
    longitude: 8.0629959,
    latitude: 45.85848339,
  },
  {
    label: "Rimella",
    longitude: 8.18259236,
    latitude: 45.90794146,
  },
  {
    label: "Riva Valdobbia",
    longitude: 7.95382909,
    latitude: 45.83327671,
  },
  {
    label: "Rive",
    longitude: 8.4183193,
    latitude: 45.21256687,
  },
  {
    label: "Roasio",
    longitude: 8.28892846,
    latitude: 45.60862395,
  },
  {
    label: "Ronsecco",
    longitude: 8.27777195,
    latitude: 45.25350322,
  },
  {
    label: "Rossa",
    longitude: 8.12564508,
    latitude: 45.83354703,
  },
  {
    label: "Rovasenda",
    longitude: 8.31361503,
    latitude: 45.53679031,
  },
  {
    label: "Sabbia",
    longitude: 8.23633112,
    latitude: 45.85685912,
  },
  {
    label: "Salasco",
    longitude: 8.26378089,
    latitude: 45.32528875,
  },
  {
    label: "Sali Vercellese",
    longitude: 8.32968249,
    latitude: 45.30933121,
  },
  {
    label: "Saluggia",
    longitude: 8.01113811,
    latitude: 45.23583395,
  },
  {
    label: "San Germano Vercellese",
    longitude: 8.25094964,
    latitude: 45.350183,
  },
  {
    label: "Santhià",
    longitude: 8.17435014,
    latitude: 45.366922,
  },
  {
    label: "Scopa",
    longitude: 8.11316044,
    latitude: 45.79171337,
  },
  {
    label: "Scopello",
    longitude: 8.09484168,
    latitude: 45.77367325,
  },
  {
    label: "Serravalle Sesia",
    longitude: 8.31147526,
    latitude: 45.68614932,
  },
  {
    label: "Stroppiana",
    longitude: 8.45347729,
    latitude: 45.2313955,
  },
  {
    label: "Tricerro",
    longitude: 8.32514946,
    latitude: 45.23379809,
  },
  {
    label: "Trino",
    longitude: 8.29690047,
    latitude: 45.19420619,
  },
  {
    label: "Tronzano Vercellese",
    longitude: 8.17469273,
    latitude: 45.3427904,
  },
  {
    label: "Valduggia",
    longitude: 8.32785665,
    latitude: 45.72638859,
  },
  {
    label: "Varallo",
    longitude: 8.25621194,
    latitude: 45.81403992,
  },
  {
    label: "Vercelli",
    longitude: 8.42323431,
    latitude: 45.32398135,
  },
  {
    label: "Villarboit",
    longitude: 8.33686901,
    latitude: 45.43665326,
  },
  {
    label: "Villatitudea",
    longitude: 8.43260279,
    latitude: 45.38561063,
  },
  {
    label: "Vocca",
    longitude: 8.18753037,
    latitude: 45.83098292,
  },
  {
    label: "Agrate Conturbia",
    longitude: 8.55992667,
    latitude: 45.67598127,
  },
  {
    label: "Ameno",
    longitude: 8.43942761,
    latitude: 45.78878319,
  },
  {
    label: "Armeno",
    longitude: 8.43917903,
    latitude: 45.82196603,
  },
  {
    label: "Arona",
    longitude: 8.55948977,
    latitude: 45.76039239,
  },
  {
    label: "Barengo",
    longitude: 8.51201322,
    latitude: 45.574775,
  },
  {
    label: "Bellinzago Novarese",
    longitude: 8.63973111,
    latitude: 45.57030363,
  },
  {
    label: "Biandrate",
    longitude: 8.46370551,
    latitude: 45.45378226,
  },
  {
    label: "Boca",
    longitude: 8.40870545,
    latitude: 45.67828729,
  },
  {
    label: "Bogogno",
    longitude: 8.53449913,
    latitude: 45.66716613,
  },
  {
    label: "Bolzano Novarese",
    longitude: 8.44415269,
    latitude: 45.76282586,
  },
  {
    label: "Borgolavezzaro",
    longitude: 8.69926765,
    latitude: 45.31838337,
  },
  {
    label: "Borgomanero",
    longitude: 8.46298661,
    latitude: 45.6988743,
  },
  {
    label: "Borgo Ticino",
    longitude: 8.60392229,
    latitude: 45.68806799,
  },
  {
    label: "Briga Novarese",
    longitude: 8.45159151,
    latitude: 45.73138349,
  },
  {
    label: "Briona",
    longitude: 8.4798194,
    latitude: 45.54219977,
  },
  {
    label: "Caltignaga",
    longitude: 8.58791485,
    latitude: 45.51708606,
  },
  {
    label: "Cameri",
    longitude: 8.66224358,
    latitude: 45.50102461,
  },
  {
    label: "Carpignano Sesia",
    longitude: 8.41834081,
    latitude: 45.5353062,
  },
  {
    label: "Casalbeltrame",
    longitude: 8.46837851,
    latitude: 45.43623977,
  },
  {
    label: "Casaleggio Novara",
    longitude: 8.49266595,
    latitude: 45.48630378,
  },
  {
    label: "Casalino",
    longitude: 8.5227352,
    latitude: 45.35768141,
  },
  {
    label: "Casalvolone",
    longitude: 8.46436466,
    latitude: 45.3994586,
  },
  {
    label: "Castellazzo Novarese",
    longitude: 8.48870753,
    latitude: 45.5139929,
  },
  {
    label: "Castelletto sopra Ticino",
    longitude: 8.64237321,
    latitude: 45.71405023,
  },
  {
    label: "Cavaglietto",
    longitude: 8.50091213,
    latitude: 45.60280053,
  },
  {
    label: "Cavaglio d'Agogna",
    longitude: 8.48278611,
    latitude: 45.6118122,
  },
  {
    label: "Cavallirio",
    longitude: 8.3967365,
    latitude: 45.66496084,
  },
  {
    label: "Cerano",
    longitude: 8.78085515,
    latitude: 45.41067427,
  },
  {
    label: "Colazza",
    longitude: 8.49908518,
    latitude: 45.79262548,
  },
  {
    label: "Comignago",
    longitude: 8.56512314,
    latitude: 45.7152394,
  },
  {
    label: "Cressa",
    longitude: 8.50748369,
    latitude: 45.64799409,
  },
  {
    label: "Cureggio",
    longitude: 8.46136101,
    latitude: 45.67513019,
  },
  {
    label: "Divignano",
    longitude: 8.60149532,
    latitude: 45.66227913,
  },
  {
    label: "Dormelletto",
    longitude: 8.57318421,
    latitude: 45.73016147,
  },
  {
    label: "Fara Novarese",
    longitude: 8.4591122,
    latitude: 45.55511789,
  },
  {
    label: "Fontaneto d'Agogna",
    longitude: 8.48458276,
    latitude: 45.63985923,
  },
  {
    label: "Galliate",
    longitude: 8.69527266,
    latitude: 45.47769535,
  },
  {
    label: "Garbagna Novarese",
    longitude: 8.6596796,
    latitude: 45.38665075,
  },
  {
    label: "Gargallo",
    longitude: 8.42921545,
    latitude: 45.72750435,
  },
  {
    label: "Gattico",
    longitude: 8.52053275,
    latitude: 45.70906459,
  },
  {
    label: "Ghemme",
    longitude: 8.42009104,
    latitude: 45.59781069,
  },
  {
    label: "Gozzano",
    longitude: 8.43465086,
    latitude: 45.74755669,
  },
  {
    label: "Granozzo con Monticello",
    longitude: 8.57413671,
    latitude: 45.36014053,
  },
  {
    label: "Grignasco",
    longitude: 8.33970898,
    latitude: 45.68190215,
  },
  {
    label: "Invorio",
    longitude: 8.48673976,
    latitude: 45.75590621,
  },
  {
    label: "Landiona",
    longitude: 8.42216872,
    latitude: 45.49690699,
  },
  {
    label: "Lesa",
    longitude: 8.56484439,
    latitude: 45.83011076,
  },
  {
    label: "Maggiora",
    longitude: 8.42099488,
    latitude: 45.69075412,
  },
  {
    label: "Mandello Vitta",
    longitude: 8.45918595,
    latitude: 45.49523236,
  },
  {
    label: "Marano Ticino",
    longitude: 8.63193647,
    latitude: 45.62982409,
  },
  {
    label: "Massino Visconti",
    longitude: 8.53880817,
    latitude: 45.82190383,
  },
  {
    label: "Meina",
    longitude: 8.53760183,
    latitude: 45.79003479,
  },
  {
    label: "Mezzomerico",
    longitude: 8.60462006,
    latitude: 45.62028414,
  },
  {
    label: "Miasino",
    longitude: 8.43036112,
    latitude: 45.80218966,
  },
  {
    label: "Momo",
    longitude: 8.55537053,
    latitude: 45.57391222,
  },
  {
    label: "Nebbiuno",
    longitude: 8.52423877,
    latitude: 45.80607594,
  },
  {
    label: "Nibbiola",
    longitude: 8.65635253,
    latitude: 45.3717854,
  },
  {
    label: "Novara",
    longitude: 8.62191588,
    latitude: 45.44588506,
  },
  {
    label: "Oleggio",
    longitude: 8.63736389,
    latitude: 45.59668789,
  },
  {
    label: "Oleggio Castello",
    longitude: 8.52971174,
    latitude: 45.74931605,
  },
  {
    label: "Orta San Giulio",
    longitude: 8.40568474,
    latitude: 45.79916255,
  },
  {
    label: "Paruzzaro",
    longitude: 8.51345761,
    latitude: 45.74868723,
  },
  {
    label: "Pella",
    longitude: 8.38311698,
    latitude: 45.79707067,
  },
  {
    label: "Pettenasco",
    longitude: 8.40857519,
    latitude: 45.81732142,
  },
  {
    label: "Pisano",
    longitude: 8.51043727,
    latitude: 45.79354856,
  },
  {
    label: "Pogno",
    longitude: 8.38307485,
    latitude: 45.75667233,
  },
  {
    label: "Pombia",
    longitude: 8.6323888,
    latitude: 45.64926665,
  },
  {
    label: "Prato Sesia",
    longitude: 8.37377395,
    latitude: 45.64787501,
  },
  {
    label: "Recetto",
    longitude: 8.43608827,
    latitude: 45.46080638,
  },
  {
    label: "Romagnano Sesia",
    longitude: 8.3828762,
    latitude: 45.63335002,
  },
  {
    label: "Romentino",
    longitude: 8.72085352,
    latitude: 45.46260428,
  },
  {
    label: "San Maurizio d'Opaglio",
    longitude: 8.3909581,
    latitude: 45.77247196,
  },
  {
    label: "San Nazzaro Sesia",
    longitude: 8.4235666,
    latitude: 45.43813129,
  },
  {
    label: "San Pietro Mosezzo",
    longitude: 8.54347212,
    latitude: 45.45561012,
  },
  {
    label: "Sillavengo",
    longitude: 8.44052712,
    latitude: 45.52045483,
  },
  {
    label: "Sizzano",
    longitude: 8.4373334,
    latitude: 45.57734631,
  },
  {
    label: "Soriso",
    longitude: 8.40936492,
    latitude: 45.74226936,
  },
  {
    label: "Sozzago",
    longitude: 8.72097747,
    latitude: 45.3978178,
  },
  {
    label: "Suno",
    longitude: 8.54092449,
    latitude: 45.63114506,
  },
  {
    label: "Terdobbiate",
    longitude: 8.69509702,
    latitude: 45.37635291,
  },
  {
    label: "Tornaco",
    longitude: 8.71790663,
    latitude: 45.35736427,
  },
  {
    label: "Trecate",
    longitude: 8.73505551,
    latitude: 45.43190444,
  },
  {
    label: "Vaprio d'Agogna",
    longitude: 8.55405064,
    latitude: 45.60353491,
  },
  {
    label: "Varallo Pombia",
    longitude: 8.6330528,
    latitude: 45.66670204,
  },
  {
    label: "Veruno",
    longitude: 8.53051508,
    latitude: 45.69094068,
  },
  {
    label: "Vespolatitudee",
    longitude: 8.66903813,
    latitude: 45.34947293,
  },
  {
    label: "Vicolungo",
    longitude: 8.46131719,
    latitude: 45.47882953,
  },
  {
    label: "Vinzaglio",
    longitude: 8.51840535,
    latitude: 45.32365543,
  },
  {
    label: "Acceglio",
    longitude: 6.99122231,
    latitude: 44.47580767,
  },
  {
    label: "Aisone",
    longitude: 7.22083358,
    latitude: 44.31344859,
  },
  {
    label: "Alba",
    longitude: 8.03569894,
    latitude: 44.70092208,
  },
  {
    label: "Albaretto della Torre",
    longitude: 8.06408216,
    latitude: 44.59591514,
  },
  {
    label: "Alto",
    longitude: 8.00368552,
    latitude: 44.10828374,
  },
  {
    label: "Argentera",
    longitude: 6.96937784,
    latitude: 44.3795888,
  },
  {
    label: "Arguello",
    longitude: 8.11067964,
    latitude: 44.5829271,
  },
  {
    label: "Bagnasco",
    longitude: 8.04258299,
    latitude: 44.30207165,
  },
  {
    label: "Bagnolo Piemonte",
    longitude: 7.31548217,
    latitude: 44.7599027,
  },
  {
    label: "Baldissero d'Alba",
    longitude: 7.91574918,
    latitude: 44.758713,
  },
  {
    label: "Barbaresco",
    longitude: 8.08046453,
    latitude: 44.72554836,
  },
  {
    label: "Barge",
    longitude: 7.32339364,
    latitude: 44.72522006,
  },
  {
    label: "Barolo",
    longitude: 7.94180811,
    latitude: 44.60988256,
  },
  {
    label: "Bastia Mondovì",
    longitude: 7.89332172,
    latitude: 44.44138607,
  },
  {
    label: "Battifollo",
    longitude: 8.00948124,
    latitude: 44.31917111,
  },
  {
    label: "Beinette",
    longitude: 7.64570648,
    latitude: 44.36566802,
  },
  {
    label: "Bellino",
    longitude: 7.01716043,
    latitude: 44.58060554,
  },
  {
    label: "Belvedere Langhe",
    longitude: 7.97416757,
    latitude: 44.49456526,
  },
  {
    label: "Bene Vagienna",
    longitude: 7.83282512,
    latitude: 44.54523956,
  },
  {
    label: "Benevello",
    longitude: 8.10591872,
    latitude: 44.62818908,
  },
  {
    label: "Bergolo",
    longitude: 8.18352587,
    latitude: 44.54801177,
  },
  {
    label: "Bernezzo",
    longitude: 7.43531003,
    latitude: 44.38860537,
  },
  {
    label: "Bonvicino",
    longitude: 8.01592005,
    latitude: 44.50362005,
  },
  {
    label: "Borgomale",
    longitude: 8.13275549,
    latitude: 44.62044163,
  },
  {
    label: "Borgo San Dalmazzo",
    longitude: 7.48746357,
    latitude: 44.32875997,
  },
  {
    label: "Bosia",
    longitude: 8.14620308,
    latitude: 44.60246692,
  },
  {
    label: "Bossolasco",
    longitude: 8.05750263,
    latitude: 44.52679083,
  },
  {
    label: "Boves",
    longitude: 7.55199457,
    latitude: 44.32981994,
  },
  {
    label: "Bra",
    longitude: 7.85474763,
    latitude: 44.69818228,
  },
  {
    label: "Briaglia",
    longitude: 7.87698925,
    latitude: 44.39511761,
  },
  {
    label: "Briga Alta",
    longitude: 7.75076032,
    latitude: 44.08321974,
  },
  {
    label: "Brondello",
    longitude: 7.40513697,
    latitude: 44.60005668,
  },
  {
    label: "Brossasco",
    longitude: 7.36044207,
    latitude: 44.57029687,
  },
  {
    label: "Busca",
    longitude: 7.47352127,
    latitude: 44.51369697,
  },
  {
    label: "Camerana",
    longitude: 8.14079853,
    latitude: 44.42266439,
  },
  {
    label: "Camo",
    longitude: 8.19352184,
    latitude: 44.69488958,
  },
  {
    label: "Canale",
    longitude: 7.99140953,
    latitude: 44.79720739,
  },
  {
    label: "Canosio",
    longitude: 7.08139097,
    latitude: 44.45532677,
  },
  {
    label: "Caprauna",
    longitude: 7.95494044,
    latitude: 44.11582328,
  },
  {
    label: "Caraglio",
    longitude: 7.42762073,
    latitude: 44.41771618,
  },
  {
    label: "Caramagna Piemonte",
    longitude: 7.74117579,
    latitude: 44.78043462,
  },
  {
    label: "Cardè",
    longitude: 7.47821589,
    latitude: 44.74417866,
  },
  {
    label: "Carrù",
    longitude: 7.88006416,
    latitude: 44.47859161,
  },
  {
    label: "Cartignano",
    longitude: 7.28769499,
    latitude: 44.47708439,
  },
  {
    label: "Casalgrasso",
    longitude: 7.62496057,
    latitude: 44.81950243,
  },
  {
    label: "Castagnito",
    longitude: 8.03015252,
    latitude: 44.75647805,
  },
  {
    label: "Casteldelfino",
    longitude: 7.06903166,
    latitude: 44.59097136,
  },
  {
    label: "Castellar",
    longitude: 7.43636974,
    latitude: 44.62157124,
  },
  {
    label: "Castelletto Stura",
    longitude: 7.63905085,
    latitude: 44.44422917,
  },
  {
    label: "Castelletto Uzzone",
    longitude: 8.18598322,
    latitude: 44.49257989,
  },
  {
    label: "Castellinaldo d'Alba",
    longitude: 8.03153502,
    latitude: 44.77533089,
  },
  {
    label: "Castellino Tanaro",
    longitude: 7.98188704,
    latitude: 44.42777401,
  },
  {
    label: "Castelmagno",
    longitude: 7.21232892,
    latitude: 44.40916447,
  },
  {
    label: "Castelnuovo di Ceva",
    longitude: 8.13019641,
    latitude: 44.35330865,
  },
  {
    label: "Castiglione Falletto",
    longitude: 7.97903465,
    latitude: 44.62333766,
  },
  {
    label: "Castiglione Tinella",
    longitude: 8.18943117,
    latitude: 44.72604642,
  },
  {
    label: "Castino",
    longitude: 8.18121378,
    latitude: 44.61699553,
  },
  {
    label: "Cavallerleone",
    longitude: 7.66273569,
    latitude: 44.73944316,
  },
  {
    label: "Cavallermaggiore",
    longitude: 7.68774788,
    latitude: 44.70785741,
  },
  {
    label: "Celle di Macra",
    longitude: 7.17967333,
    latitude: 44.48257098,
  },
  {
    label: "Centallo",
    longitude: 7.59014854,
    latitude: 44.50412404,
  },
  {
    label: "Ceresole Alba",
    longitude: 7.82056601,
    latitude: 44.79952425,
  },
  {
    label: "Cerretto Langhe",
    longitude: 8.09783957,
    latitude: 44.57433971,
  },
  {
    label: "Cervasca",
    longitude: 7.46788805,
    latitude: 44.38316033,
  },
  {
    label: "Cervere",
    longitude: 7.79316738,
    latitude: 44.63475191,
  },
  {
    label: "Ceva",
    longitude: 8.03521716,
    latitude: 44.38623533,
  },
  {
    label: "Cherasco",
    longitude: 7.85820345,
    latitude: 44.65222548,
  },
  {
    label: "Chiusa di Pesio",
    longitude: 7.67788414,
    latitude: 44.32477035,
  },
  {
    label: "Cigliè",
    longitude: 7.92622597,
    latitude: 44.43506854,
  },
  {
    label: "Cissone",
    longitude: 8.03050943,
    latitude: 44.56310368,
  },
  {
    label: "Clavesana",
    longitude: 7.91198215,
    latitude: 44.4821472,
  },
  {
    label: "Corneliano d'Alba",
    longitude: 7.96129163,
    latitude: 44.73553903,
  },
  {
    label: "Cortemilia",
    longitude: 8.19186669,
    latitude: 44.5808616,
  },
  {
    label: "Cossano Belbo",
    longitude: 8.19855921,
    latitude: 44.66906362,
  },
  {
    label: "Costigliole Saluzzo",
    longitude: 7.48493751,
    latitude: 44.56461241,
  },
  {
    label: "Cravanzana",
    longitude: 8.12818957,
    latitude: 44.57315552,
  },
  {
    label: "Crissolo",
    longitude: 7.15718954,
    latitude: 44.69910296,
  },
  {
    label: "Cuneo",
    longitude: 7.55117163,
    latitude: 44.39329624,
  },
  {
    label: "Demonte",
    longitude: 7.29751308,
    latitude: 44.31526154,
  },
  {
    label: "Diano d'Alba",
    longitude: 8.02766887,
    latitude: 44.65139204,
  },
  {
    label: "Dogliani",
    longitude: 7.94516725,
    latitude: 44.53009052,
  },
  {
    label: "Dronero",
    longitude: 7.36331795,
    latitude: 44.46697823,
  },
  {
    label: "Elva",
    longitude: 7.08963389,
    latitude: 44.53976498,
  },
  {
    label: "Entracque",
    longitude: 7.39780279,
    latitude: 44.24075683,
  },
  {
    label: "Envie",
    longitude: 7.37219219,
    latitude: 44.68175309,
  },
  {
    label: "Farigliano",
    longitude: 7.91702082,
    latitude: 44.51169894,
  },
  {
    label: "Faule",
    longitude: 7.58066365,
    latitude: 44.80542638,
  },
  {
    label: "Feisoglio",
    longitude: 8.10417587,
    latitude: 44.54335973,
  },
  {
    label: "Fossano",
    longitude: 7.72487008,
    latitude: 44.549398,
  },
  {
    label: "Frabosa Soprana",
    longitude: 7.80709202,
    latitude: 44.28747375,
  },
  {
    label: "Frabosa Sottana",
    longitude: 7.79831038,
    latitude: 44.30202535,
  },
  {
    label: "Frassino",
    longitude: 7.27564416,
    latitude: 44.571357,
  },
  {
    label: "Gaiola",
    longitude: 7.40676156,
    latitude: 44.33524752,
  },
  {
    label: "Gambasca",
    longitude: 7.34741432,
    latitude: 44.62937358,
  },
  {
    label: "Garessio",
    longitude: 8.02988403,
    latitude: 44.19729282,
  },
  {
    label: "Genola",
    longitude: 7.6627259,
    latitude: 44.58769184,
  },
  {
    label: "Gorzegno",
    longitude: 8.13511748,
    latitude: 44.51186,
  },
  {
    label: "Gottasecca",
    longitude: 8.16725259,
    latitude: 44.46169553,
  },
  {
    label: "Govone",
    longitude: 8.09995734,
    latitude: 44.80485973,
  },
  {
    label: "Grinzane Cavour",
    longitude: 7.98221368,
    latitude: 44.6565379,
  },
  {
    label: "Guarene",
    longitude: 8.03367466,
    latitude: 44.73780446,
  },
  {
    label: "Igliano",
    longitude: 8.01250347,
    latitude: 44.44353291,
  },
  {
    label: "Isasca",
    longitude: 7.38154761,
    latitude: 44.58729447,
  },
  {
    label: "Lagnasco",
    longitude: 7.55533712,
    latitude: 44.62466066,
  },
  {
    label: "La Morra",
    longitude: 7.93346395,
    latitude: 44.63734577,
  },
  {
    label: "Lequio Berria",
    longitude: 8.09767172,
    latitude: 44.60460498,
  },
  {
    label: "Lequio Tanaro",
    longitude: 7.8835988,
    latitude: 44.56023249,
  },
  {
    label: "Lesegno",
    longitude: 7.96650568,
    latitude: 44.40123357,
  },
  {
    label: "Levice",
    longitude: 8.15661951,
    latitude: 44.53765937,
  },
  {
    label: "Limone Piemonte",
    longitude: 7.57607947,
    latitude: 44.20129244,
  },
  {
    label: "Lisio",
    longitude: 7.97963402,
    latitude: 44.30669736,
  },
  {
    label: "Macra",
    longitude: 7.17920368,
    latitude: 44.49985871,
  },
  {
    label: "Magliano Alfieri",
    longitude: 8.06781101,
    latitude: 44.76646823,
  },
  {
    label: "Magliano Alpi",
    longitude: 7.79955227,
    latitude: 44.45810047,
  },
  {
    label: "Mango",
    longitude: 8.15033106,
    latitude: 44.68461474,
  },
  {
    label: "Manta",
    longitude: 7.48626156,
    latitude: 44.61433199,
  },
  {
    label: "Marene",
    longitude: 7.7291516,
    latitude: 44.66149658,
  },
  {
    label: "Margarita",
    longitude: 7.68685767,
    latitude: 44.40264638,
  },
  {
    label: "Marmora",
    longitude: 7.0944983,
    latitude: 44.4574949,
  },
  {
    label: "Marsaglia",
    longitude: 7.98298599,
    latitude: 44.45218424,
  },
  {
    label: "Martiniana Po",
    longitude: 7.36631509,
    latitude: 44.62641683,
  },
  {
    label: "Melle",
    longitude: 7.32058248,
    latitude: 44.56205512,
  },
  {
    label: "Moiola",
    longitude: 7.38887176,
    latitude: 44.31969065,
  },
  {
    label: "Mombarcaro",
    longitude: 8.08509313,
    latitude: 44.46776296,
  },
  {
    label: "Mombasiglio",
    longitude: 7.96999618,
    latitude: 44.36331302,
  },
  {
    label: "Monastero di Vasco",
    longitude: 7.82276111,
    latitude: 44.34017655,
  },
  {
    label: "Monasterolo Casotto",
    longitude: 7.94113913,
    latitude: 44.33083516,
  },
  {
    label: "Monasterolo di Savigliano",
    longitude: 7.62076965,
    latitude: 44.68633537,
  },
  {
    label: "Monchiero",
    longitude: 7.91814013,
    latitude: 44.57304646,
  },
  {
    label: "Mondovì",
    longitude: 7.81980578,
    latitude: 44.38717713,
  },
  {
    label: "Monesiglio",
    longitude: 8.11637954,
    latitude: 44.46489547,
  },
  {
    label: "Monforte d'Alba",
    longitude: 7.96840862,
    latitude: 44.58233541,
  },
  {
    label: "Montà",
    longitude: 7.96001468,
    latitude: 44.81309155,
  },
  {
    label: "Montaldo di Mondovì",
    longitude: 7.8658402,
    latitude: 44.31946922,
  },
  {
    label: "Montaldo Roero",
    longitude: 7.92446567,
    latitude: 44.76856722,
  },
  {
    label: "Montanera",
    longitude: 7.66557341,
    latitude: 44.46197795,
  },
  {
    label: "Montelupo Albese",
    longitude: 8.04638473,
    latitude: 44.6230682,
  },
  {
    label: "Montemale di Cuneo",
    longitude: 7.37281405,
    latitude: 44.43769447,
  },
  {
    label: "Monterosso Grana",
    longitude: 7.32391931,
    latitude: 44.4088882,
  },
  {
    label: "Monteu Roero",
    longitude: 7.93086916,
    latitude: 44.78023621,
  },
  {
    label: "Montezemolo",
    longitude: 8.14146684,
    latitude: 44.37823953,
  },
  {
    label: "Monticello d'Alba",
    longitude: 7.94100869,
    latitude: 44.71829049,
  },
  {
    label: "Moretta",
    longitude: 7.53692411,
    latitude: 44.76390518,
  },
  {
    label: "Morozzo",
    longitude: 7.71044647,
    latitude: 44.42304028,
  },
  {
    label: "Murazzano",
    longitude: 8.01884708,
    latitude: 44.47375439,
  },
  {
    label: "Murello",
    longitude: 7.60094237,
    latitude: 44.75295967,
  },
  {
    label: "Narzole",
    longitude: 7.87051966,
    latitude: 44.59415651,
  },
  {
    label: "Neive",
    longitude: 8.11549922,
    latitude: 44.72631566,
  },
  {
    label: "Neviglie",
    longitude: 8.1159764,
    latitude: 44.69212361,
  },
  {
    label: "Niella Belbo",
    longitude: 8.07912058,
    latitude: 44.51329019,
  },
  {
    label: "Niella Tanaro",
    longitude: 7.92135837,
    latitude: 44.41482647,
  },
  {
    label: "Novello",
    longitude: 7.92765526,
    latitude: 44.58715702,
  },
  {
    label: "Nucetto",
    longitude: 8.05891552,
    latitude: 44.33940152,
  },
  {
    label: "Oncino",
    longitude: 7.19025436,
    latitude: 44.67649775,
  },
  {
    label: "Ormea",
    longitude: 7.91069055,
    latitude: 44.14859437,
  },
  {
    label: "Ostana",
    longitude: 7.18848199,
    latitude: 44.6922177,
  },
  {
    label: "Paesana",
    longitude: 7.27655778,
    latitude: 44.68544648,
  },
  {
    label: "Pagno",
    longitude: 7.42993582,
    latitude: 44.61374464,
  },
  {
    label: "Pamparato",
    longitude: 7.91385092,
    latitude: 44.27712579,
  },
  {
    label: "Paroldo",
    longitude: 8.07131848,
    latitude: 44.43167462,
  },
  {
    label: "Perletto",
    longitude: 8.2140792,
    latitude: 44.5989965,
  },
  {
    label: "Perlo",
    longitude: 8.08360656,
    latitude: 44.33309061,
  },
  {
    label: "Peveragno",
    longitude: 7.61713926,
    latitude: 44.33001233,
  },
  {
    label: "Pezzolo Valle Uzzone",
    longitude: 8.19411,
    latitude: 44.5392765,
  },
  {
    label: "Pianfei",
    longitude: 7.70975813,
    latitude: 44.3704904,
  },
  {
    label: "Piasco",
    longitude: 7.45740743,
    latitude: 44.56855814,
  },
  {
    label: "Pietraporzio",
    longitude: 7.03194297,
    latitude: 44.34401168,
  },
  {
    label: "Piobesi d'Alba",
    longitude: 7.97851256,
    latitude: 44.73490945,
  },
  {
    label: "Piozzo",
    longitude: 7.89242201,
    latitude: 44.5157626,
  },
  {
    label: "Pocapaglia",
    longitude: 7.88718583,
    latitude: 44.71945971,
  },
  {
    label: "Polonghera",
    longitude: 7.59607299,
    latitude: 44.80335136,
  },
  {
    label: "Pontechianale",
    longitude: 7.02831148,
    latitude: 44.62198999,
  },
  {
    label: "Pradleves",
    longitude: 7.28208627,
    latitude: 44.41859801,
  },
  {
    label: "Prazzo",
    longitude: 7.05543047,
    latitude: 44.48307586,
  },
  {
    label: "Priero",
    longitude: 8.09241941,
    latitude: 44.3766176,
  },
  {
    label: "Priocca",
    longitude: 8.06272749,
    latitude: 44.79130724,
  },
  {
    label: "Priola",
    longitude: 8.02242382,
    latitude: 44.2459767,
  },
  {
    label: "Prunetto",
    longitude: 8.14431165,
    latitude: 44.48944911,
  },
  {
    label: "Racconigi",
    longitude: 7.67598165,
    latitude: 44.76782338,
  },
  {
    label: "Revello",
    longitude: 7.38900592,
    latitude: 44.65466177,
  },
  {
    label: "Rifreddo",
    longitude: 7.34909777,
    latitude: 44.65049802,
  },
  {
    label: "Rittana",
    longitude: 7.40063509,
    latitude: 44.35057088,
  },
  {
    label: "Roaschia",
    longitude: 7.45613996,
    latitude: 44.27013254,
  },
  {
    label: "Roascio",
    longitude: 8.02254642,
    latitude: 44.41716489,
  },
  {
    label: "Robilante",
    longitude: 7.51116274,
    latitude: 44.29422968,
  },
  {
    label: "Roburent",
    longitude: 7.89020684,
    latitude: 44.3049956,
  },
  {
    label: "Roccabruna",
    longitude: 7.34074709,
    latitude: 44.47629522,
  },
  {
    label: "Rocca Cigliè",
    longitude: 7.95114507,
    latitude: 44.44409132,
  },
  {
    label: "Rocca de' Baldi",
    longitude: 7.74569773,
    latitude: 44.43017736,
  },
  {
    label: "Roccaforte Mondovì",
    longitude: 7.74587877,
    latitude: 44.31842604,
  },
  {
    label: "Roccasparvera",
    longitude: 7.44134173,
    latitude: 44.34165607,
  },
  {
    label: "Roccavione",
    longitude: 7.48125617,
    latitude: 44.31440994,
  },
  {
    label: "Rocchetta Belbo",
    longitude: 8.17527183,
    latitude: 44.63713321,
  },
  {
    label: "Roddi",
    longitude: 7.97610755,
    latitude: 44.67845326,
  },
  {
    label: "Roddino",
    longitude: 8.01873571,
    latitude: 44.57410827,
  },
  {
    label: "Rodello",
    longitude: 8.05931571,
    latitude: 44.63061299,
  },
  {
    label: "Rossana",
    longitude: 7.43260669,
    latitude: 44.54249985,
  },
  {
    label: "Ruffia",
    longitude: 7.60244401,
    latitude: 44.70724824,
  },
  {
    label: "Sale delle Langhe",
    longitude: 8.08345546,
    latitude: 44.39706458,
  },
  {
    label: "Sale San Giovanni",
    longitude: 8.07860651,
    latitude: 44.39938022,
  },
  {
    label: "Saliceto",
    longitude: 8.16679127,
    latitude: 44.41327854,
  },
  {
    label: "Salmour",
    longitude: 7.79212298,
    latitude: 44.57815425,
  },
  {
    label: "Saluzzo",
    longitude: 7.49084739,
    latitude: 44.64702298,
  },
  {
    label: "Sambuco",
    longitude: 7.07781003,
    latitude: 44.33734246,
  },
  {
    label: "Sampeyre",
    longitude: 7.1888735,
    latitude: 44.57938316,
  },
  {
    label: "San Benedetto Belbo",
    longitude: 8.05785091,
    latitude: 44.48989293,
  },
  {
    label: "San Damiano Macra",
    longitude: 7.25630891,
    latitude: 44.48841462,
  },
  {
    label: "Sanfrè",
    longitude: 7.80354961,
    latitude: 44.75219136,
  },
  {
    label: "Sanfront",
    longitude: 7.32024792,
    latitude: 44.64583812,
  },
  {
    label: "San Michele Mondovì",
    longitude: 7.90824846,
    latitude: 44.37652817,
  },
  {
    label: "Sant'Albano Stura",
    longitude: 7.7210529,
    latitude: 44.50992775,
  },
  {
    label: "Santa Vittoria d'Alba",
    longitude: 7.93315556,
    latitude: 44.696531,
  },
  {
    label: "Santo Stefano Belbo",
    longitude: 8.23101616,
    latitude: 44.70749115,
  },
  {
    label: "Santo Stefano Roero",
    longitude: 7.93990949,
    latitude: 44.78881971,
  },
  {
    label: "Savigliano",
    longitude: 7.65747908,
    latitude: 44.64801868,
  },
  {
    label: "Scagnello",
    longitude: 7.98679354,
    latitude: 44.33230692,
  },
  {
    label: "Scarnafigi",
    longitude: 7.56692665,
    latitude: 44.68175572,
  },
  {
    label: "Serralunga d'Alba",
    longitude: 8.00061326,
    latitude: 44.60922192,
  },
  {
    label: "Serravalle Langhe",
    longitude: 8.05766192,
    latitude: 44.56091813,
  },
  {
    label: "Sinio",
    longitude: 8.02156085,
    latitude: 44.59948317,
  },
  {
    label: "Somano",
    longitude: 8.01140208,
    latitude: 44.53589341,
  },
  {
    label: "Sommariva del Bosco",
    longitude: 7.7842658,
    latitude: 44.77128582,
  },
  {
    label: "Sommariva Perno",
    longitude: 7.90557574,
    latitude: 44.74319256,
  },
  {
    label: "Stroppo",
    longitude: 7.12591238,
    latitude: 44.50564217,
  },
  {
    label: "Tarantasca",
    longitude: 7.54506036,
    latitude: 44.4934162,
  },
  {
    label: "Torre Bormida",
    longitude: 8.1556106,
    latitude: 44.56310269,
  },
  {
    label: "Torre Mondovì",
    longitude: 7.90035121,
    latitude: 44.35204035,
  },
  {
    label: "Torre San Giorgio",
    longitude: 7.52994094,
    latitude: 44.73582111,
  },
  {
    label: "Torresina",
    longitude: 8.03476395,
    latitude: 44.43220895,
  },
  {
    label: "Treiso",
    longitude: 8.08684916,
    latitude: 44.6893739,
  },
  {
    label: "Trezzo Tinella",
    longitude: 8.1075833,
    latitude: 44.67695385,
  },
  {
    label: "Trinità",
    longitude: 7.7580356,
    latitude: 44.50746901,
  },
  {
    label: "Valdieri",
    longitude: 7.39613571,
    latitude: 44.27764211,
  },
  {
    label: "Valgrana",
    longitude: 7.38061464,
    latitude: 44.40960891,
  },
  {
    label: "Valloriate",
    longitude: 7.37456517,
    latitude: 44.33814875,
  },
  {
    label: "Valmala",
    longitude: 7.34621408,
    latitude: 44.54428912,
  },
  {
    label: "Venasca",
    longitude: 7.39495176,
    latitude: 44.56254284,
  },
  {
    label: "Verduno",
    longitude: 7.93376689,
    latitude: 44.66812061,
  },
  {
    label: "Vernante",
    longitude: 7.53370331,
    latitude: 44.24556459,
  },
  {
    label: "Verzuolo",
    longitude: 7.48312762,
    latitude: 44.59955425,
  },
  {
    label: "Vezza d'Alba",
    longitude: 7.99609095,
    latitude: 44.76390017,
  },
  {
    label: "Vicoforte",
    longitude: 7.86498818,
    latitude: 44.37495568,
  },
  {
    label: "Vignolo",
    longitude: 7.46989353,
    latitude: 44.35955809,
  },
  {
    label: "Villafalletto",
    longitude: 7.53889467,
    latitude: 44.54428113,
  },
  {
    label: "Villanova Mondovì",
    longitude: 7.76209311,
    latitude: 44.34445968,
  },
  {
    label: "Villanova Solaro",
    longitude: 7.57603223,
    latitude: 44.72963767,
  },
  {
    label: "Villar San Costanzo",
    longitude: 7.38029785,
    latitude: 44.48300637,
  },
  {
    label: "Vinadio",
    longitude: 7.1743386,
    latitude: 44.30709073,
  },
  {
    label: "Viola",
    longitude: 7.96623533,
    latitude: 44.29047791,
  },
  {
    label: "Vottignasco",
    longitude: 7.57821349,
    latitude: 44.56481499,
  },
  {
    label: "Agliano Terme",
    longitude: 8.25110248,
    latitude: 44.79070477,
  },
  {
    label: "Albugnano",
    longitude: 7.9715283,
    latitude: 45.07801783,
  },
  {
    label: "Antignano",
    longitude: 8.13378727,
    latitude: 44.84544206,
  },
  {
    label: "Aramengo",
    longitude: 8.00198071,
    latitude: 45.09973309,
  },
  {
    label: "Asti",
    longitude: 8.20414255,
    latitude: 44.89912921,
  },
  {
    label: "Azzano d'Asti",
    longitude: 8.26765893,
    latitude: 44.87469786,
  },
  {
    label: "Baldichieri d'Asti",
    longitude: 8.09110376,
    latitude: 44.90699703,
  },
  {
    label: "Belveglio",
    longitude: 8.33010424,
    latitude: 44.83048705,
  },
  {
    label: "Berzano di San Pietro",
    longitude: 7.95404954,
    latitude: 45.09490798,
  },
  {
    label: "Bruno",
    longitude: 8.44027285,
    latitude: 44.79063282,
  },
  {
    label: "Bubbio",
    longitude: 8.29360889,
    latitude: 44.66384285,
  },
  {
    label: "Buttigliera d'Asti",
    longitude: 7.95051282,
    latitude: 45.02254621,
  },
  {
    label: "Calamandrana",
    longitude: 8.33562741,
    latitude: 44.73654927,
  },
  {
    label: "Calliano",
    longitude: 8.25732636,
    latitude: 45.00626143,
  },
  {
    label: "Calosso",
    longitude: 8.22718552,
    latitude: 44.73947663,
  },
  {
    label: "Camerano Casasco",
    longitude: 8.09088049,
    latitude: 44.98865953,
  },
  {
    label: "Canelli",
    longitude: 8.28820301,
    latitude: 44.71987867,
  },
  {
    label: "Cantarana",
    longitude: 8.03072454,
    latitude: 44.90038386,
  },
  {
    label: "Capriglio",
    longitude: 8.0081642,
    latitude: 45.00422942,
  },
  {
    label: "Casorzo",
    longitude: 8.33413974,
    latitude: 45.02281734,
  },
  {
    label: "Cassinasco",
    longitude: 8.30166091,
    latitude: 44.68863849,
  },
  {
    label: "Castagnole delle Lanze",
    longitude: 8.1491782,
    latitude: 44.75491789,
  },
  {
    label: "Castagnole Monferrato",
    longitude: 8.30609193,
    latitude: 44.96144381,
  },
  {
    label: "Castel Boglione",
    longitude: 8.38119567,
    latitude: 44.72061965,
  },
  {
    label: "Castell'Alfero",
    longitude: 8.20944516,
    latitude: 44.98270999,
  },
  {
    label: "Castellero",
    longitude: 8.07474856,
    latitude: 44.92400563,
  },
  {
    label: "Castelletto Molina",
    longitude: 8.43283445,
    latitude: 44.75030841,
  },
  {
    label: "Castello di Annone",
    longitude: 8.31368944,
    latitude: 44.87876512,
  },
  {
    label: "Castelnuovo Belbo",
    longitude: 8.41226127,
    latitude: 44.80181402,
  },
  {
    label: "Castelnuovo Calcea",
    longitude: 8.28563618,
    latitude: 44.78821506,
  },
  {
    label: "Castelnuovo Don Bosco",
    longitude: 7.96362449,
    latitude: 45.04134189,
  },
  {
    label: "Castel Rocchero",
    longitude: 8.41596578,
    latitude: 44.71879737,
  },
  {
    label: "Cellarengo",
    longitude: 7.94500108,
    latitude: 44.86599664,
  },
  {
    label: "Celle Enomondo",
    longitude: 8.12297302,
    latitude: 44.85663333,
  },
  {
    label: "Cerreto d'Asti",
    longitude: 8.03676471,
    latitude: 45.04976869,
  },
  {
    label: "Cerro Tanaro",
    longitude: 8.36020871,
    latitude: 44.87267038,
  },
  {
    label: "Cessole",
    longitude: 8.24320136,
    latitude: 44.64799259,
  },
  {
    label: "Chiusano d'Asti",
    longitude: 8.11780752,
    latitude: 44.98505458,
  },
  {
    label: "Cinaglio",
    longitude: 8.10016648,
    latitude: 44.97484379,
  },
  {
    label: "Cisterna d'Asti",
    longitude: 8.0048209,
    latitude: 44.82617595,
  },
  {
    label: "Coazzolo",
    longitude: 8.14514109,
    latitude: 44.72821487,
  },
  {
    label: "Cocconato",
    longitude: 8.03923935,
    latitude: 45.08718184,
  },
  {
    label: "Corsione",
    longitude: 8.14513636,
    latitude: 45.00074703,
  },
  {
    label: "Cortandone",
    longitude: 8.05757115,
    latitude: 44.95969083,
  },
  {
    label: "Cortanze",
    longitude: 8.08959339,
    latitude: 45.01522505,
  },
  {
    label: "Cortazzone",
    longitude: 8.06192379,
    latitude: 44.97953515,
  },
  {
    label: "Cortiglione",
    longitude: 8.35856506,
    latitude: 44.82301876,
  },
  {
    label: "Cossombrato",
    longitude: 8.13752723,
    latitude: 44.99412244,
  },
  {
    label: "Costigliole d'Asti",
    longitude: 8.18062795,
    latitude: 44.78822155,
  },
  {
    label: "Cunico",
    longitude: 8.09721152,
    latitude: 45.04043582,
  },
  {
    label: "Dusino San Michele",
    longitude: 7.97228137,
    latitude: 44.92018688,
  },
  {
    label: "Ferrere",
    longitude: 7.99385509,
    latitude: 44.87340426,
  },
  {
    label: "Fontanile",
    longitude: 8.42218563,
    latitude: 44.75337907,
  },
  {
    label: "Frinco",
    longitude: 8.17536565,
    latitude: 45.00558693,
  },
  {
    label: "Grana",
    longitude: 8.29912278,
    latitude: 44.99805155,
  },
  {
    label: "Grazzano Badoglio",
    longitude: 8.31355703,
    latitude: 45.03891548,
  },
  {
    label: "Incisa Scapaccino",
    longitude: 8.38094573,
    latitude: 44.80839835,
  },
  {
    label: "Isola d'Asti",
    longitude: 8.17721468,
    latitude: 44.82947547,
  },
  {
    label: "Loazzolo",
    longitude: 8.25852546,
    latitude: 44.66896792,
  },
  {
    label: "Maranzana",
    longitude: 8.47682029,
    latitude: 44.75977053,
  },
  {
    label: "Maretto",
    longitude: 8.03423581,
    latitude: 44.94435642,
  },
  {
    label: "Moasca",
    longitude: 8.27793953,
    latitude: 44.76255023,
  },
  {
    label: "Mombaldone",
    longitude: 8.32920396,
    latitude: 44.56923733,
  },
  {
    label: "Mombaruzzo",
    longitude: 8.44866667,
    latitude: 44.77102638,
  },
  {
    label: "Mombercelli",
    longitude: 8.2944273,
    latitude: 44.81697574,
  },
  {
    label: "Monale",
    longitude: 8.07164087,
    latitude: 44.93609673,
  },
  {
    label: "Monastero Bormida",
    longitude: 8.32462019,
    latitude: 44.64752301,
  },
  {
    label: "Moncalvo",
    longitude: 8.26346587,
    latitude: 45.05139958,
  },
  {
    label: "Moncucco Torinese",
    longitude: 7.93215605,
    latitude: 45.06788298,
  },
  {
    label: "Mongardino",
    longitude: 8.21869885,
    latitude: 44.84884344,
  },
  {
    label: "Montabone",
    longitude: 8.39030985,
    latitude: 44.69860169,
  },
  {
    label: "Montafia",
    longitude: 8.02515243,
    latitude: 44.98919545,
  },
  {
    label: "Montaldo Scarampi",
    longitude: 8.2573902,
    latitude: 44.82860036,
  },
  {
    label: "Montechiaro d'Asti",
    longitude: 8.11238215,
    latitude: 45.00821528,
  },
  {
    label: "Montegrosso d'Asti",
    longitude: 8.23874286,
    latitude: 44.82241214,
  },
  {
    label: "Montemagno",
    longitude: 8.3240069,
    latitude: 44.9840347,
  },
  {
    label: "Moransengo",
    longitude: 8.02486752,
    latitude: 45.11523742,
  },
  {
    label: "Nizza Monferrato",
    longitude: 8.35650287,
    latitude: 44.77361901,
  },
  {
    label: "Olmo Gentile",
    longitude: 8.24703487,
    latitude: 44.58555786,
  },
  {
    label: "Passerano Marmorito",
    longitude: 8.01879254,
    latitude: 45.05639415,
  },
  {
    label: "Penango",
    longitude: 8.25013241,
    latitude: 45.03325898,
  },
  {
    label: "Piea",
    longitude: 8.07208963,
    latitude: 45.02529094,
  },
  {
    label: "Pino d'Asti",
    longitude: 7.98625503,
    latitude: 45.05819329,
  },
  {
    label: "Piovà Massaia",
    longitude: 8.05094462,
    latitude: 45.05448578,
  },
  {
    label: "Portacomaro",
    longitude: 8.25851231,
    latitude: 44.95711305,
  },
  {
    label: "Quaranti",
    longitude: 8.44907922,
    latitude: 44.75104701,
  },
  {
    label: "Refrancore",
    longitude: 8.34271288,
    latitude: 44.93757725,
  },
  {
    label: "Revigliasco d'Asti",
    longitude: 8.1583648,
    latitude: 44.85873263,
  },
  {
    label: "Roatto",
    longitude: 8.02661283,
    latitude: 44.95150123,
  },
  {
    label: "Robella",
    longitude: 8.100056,
    latitude: 45.10233231,
  },
  {
    label: "Rocca d'Arazzo",
    longitude: 8.28581546,
    latitude: 44.87387745,
  },
  {
    label: "Roccaverano",
    longitude: 8.27193131,
    latitude: 44.59299081,
  },
  {
    label: "Rocchetta Palafea",
    longitude: 8.34572398,
    latitude: 44.70695062,
  },
  {
    label: "Rocchetta Tanaro",
    longitude: 8.3443271,
    latitude: 44.85886821,
  },
  {
    label: "San Damiano d'Asti",
    longitude: 8.06523095,
    latitude: 44.83463979,
  },
  {
    label: "San Giorgio Scarampi",
    longitude: 8.24202367,
    latitude: 44.61132016,
  },
  {
    label: "San Martino Alfieri",
    longitude: 8.10937091,
    latitude: 44.81852007,
  },
  {
    label: "San Marzano Oliveto",
    longitude: 8.29616262,
    latitude: 44.75322439,
  },
  {
    label: "San Paolo Solbrito",
    longitude: 7.97278064,
    latitude: 44.95226938,
  },
  {
    label: "Scurzolengo",
    longitude: 8.27826008,
    latitude: 44.96570842,
  },
  {
    label: "Serole",
    longitude: 8.25972366,
    latitude: 44.55375446,
  },
  {
    label: "Sessame",
    longitude: 8.33782926,
    latitude: 44.66894067,
  },
  {
    label: "Settime",
    longitude: 8.11289201,
    latitude: 44.9640894,
  },
  {
    label: "Soglio",
    longitude: 8.0781248,
    latitude: 44.99743108,
  },
  {
    label: "Tigliole",
    longitude: 8.07546861,
    latitude: 44.88727476,
  },
  {
    label: "Tonco",
    longitude: 8.18984201,
    latitude: 45.02410808,
  },
  {
    label: "Tonengo",
    longitude: 8.00209647,
    latitude: 45.11794499,
  },
  {
    label: "Vaglio Serra",
    longitude: 8.33893337,
    latitude: 44.79653568,
  },
  {
    label: "Valfenera",
    longitude: 7.96509857,
    latitude: 44.89687406,
  },
  {
    label: "Vesime",
    longitude: 8.22822517,
    latitude: 44.63719563,
  },
  {
    label: "Viale",
    longitude: 8.05229051,
    latitude: 44.99926995,
  },
  {
    label: "Viarigi",
    longitude: 8.35869057,
    latitude: 44.98045098,
  },
  {
    label: "Vigliano d'Asti",
    longitude: 8.23189059,
    latitude: 44.83510515,
  },
  {
    label: "Villafranca d'Asti",
    longitude: 8.03221225,
    latitude: 44.91367137,
  },
  {
    label: "Villanova d'Asti",
    longitude: 7.93722176,
    latitude: 44.94188973,
  },
  {
    label: "Villa San Secondo",
    longitude: 8.13525532,
    latitude: 45.00518407,
  },
  {
    label: "Vinchio",
    longitude: 8.31730154,
    latitude: 44.80740659,
  },
  {
    label: "Montiglio Monferrato",
    longitude: 8.09773822,
    latitude: 45.06741302,
  },
  {
    label: "Acqui Terme",
    longitude: 8.46975213,
    latitude: 44.67660776,
  },
  {
    label: "Albera Ligure",
    longitude: 9.06842676,
    latitude: 44.70091651,
  },
  {
    label: "Alessandria",
    longitude: 8.61540116,
    latitude: 44.91297351,
  },
  {
    label: "Alfiano Natta",
    longitude: 8.20845976,
    latitude: 45.04723245,
  },
  {
    label: "Alice Bel Colle",
    longitude: 8.45120062,
    latitude: 44.72578802,
  },
  {
    label: "Alluvioni Cambiò",
    longitude: 8.77257309,
    latitude: 44.99538211,
  },
  {
    label: "Altavilla Monferrato",
    longitude: 8.3757125,
    latitude: 44.9928995,
  },
  {
    label: "Alzano Scrivia",
    longitude: 8.88128698,
    latitude: 45.01755597,
  },
  {
    label: "Arquata Scrivia",
    longitude: 8.88574473,
    latitude: 44.68673968,
  },
  {
    label: "Avolasca",
    longitude: 8.96523824,
    latitude: 44.80296911,
  },
  {
    label: "Balzola",
    longitude: 8.40254698,
    latitude: 45.18319612,
  },
  {
    label: "Basaluzzo",
    longitude: 8.70593403,
    latitude: 44.76829415,
  },
  {
    label: "Bassignana",
    longitude: 8.73594306,
    latitude: 45.00321277,
  },
  {
    label: "Belforte Monferrato",
    longitude: 8.66160356,
    latitude: 44.62436936,
  },
  {
    label: "Bergamasco",
    longitude: 8.45521038,
    latitude: 44.82710536,
  },
  {
    label: "Berzano di Tortona",
    longitude: 8.95059588,
    latitude: 44.87645689,
  },
  {
    label: "Bistagno",
    longitude: 8.36858665,
    latitude: 44.66179377,
  },
  {
    label: "Borghetto di Borbera",
    longitude: 8.94535017,
    latitude: 44.73105311,
  },
  {
    label: "Borgoratto Alessandrino",
    longitude: 8.53942061,
    latitude: 44.83649029,
  },
  {
    label: "Borgo San Martino",
    longitude: 8.52362138,
    latitude: 45.0890903,
  },
  {
    label: "Bosco Marengo",
    longitude: 8.67599099,
    latitude: 44.82209118,
  },
  {
    label: "Bosio",
    longitude: 8.79191248,
    latitude: 44.64778929,
  },
  {
    label: "Bozzole",
    longitude: 8.60487718,
    latitude: 45.067876,
  },
  {
    label: "Brignano-Frascata",
    longitude: 9.04079602,
    latitude: 44.81195142,
  },
  {
    label: "Cabella Ligure",
    longitude: 9.0962927,
    latitude: 44.67308273,
  },
  {
    label: "Camagna Monferrato",
    longitude: 8.43033685,
    latitude: 45.01784708,
  },
  {
    label: "Camino",
    longitude: 8.28704469,
    latitude: 45.16220888,
  },
  {
    label: "Cantalupo Ligure",
    longitude: 9.04435913,
    latitude: 44.71897373,
  },
  {
    label: "Capriata d'Orba",
    longitude: 8.68893776,
    latitude: 44.72996604,
  },
  {
    label: "Carbonara Scrivia",
    longitude: 8.87077531,
    latitude: 44.84976758,
  },
  {
    label: "Carentino",
    longitude: 8.47046969,
    latitude: 44.82887672,
  },
  {
    label: "Carezzano",
    longitude: 8.90000819,
    latitude: 44.80675404,
  },
  {
    label: "Carpeneto",
    longitude: 8.60631572,
    latitude: 44.6772555,
  },
  {
    label: "Carrega Ligure",
    longitude: 9.17561244,
    latitude: 44.61907401,
  },
  {
    label: "Carrosio",
    longitude: 8.83233109,
    latitude: 44.65940501,
  },
  {
    label: "Cartosio",
    longitude: 8.41852913,
    latitude: 44.59120157,
  },
  {
    label: "Casal Cermelli",
    longitude: 8.62242452,
    latitude: 44.8357048,
  },
  {
    label: "Casaleggio Boiro",
    longitude: 8.7316846,
    latitude: 44.63430041,
  },
  {
    label: "Casale Monferrato",
    longitude: 8.45260603,
    latitude: 45.13502772,
  },
  {
    label: "Casalnoceto",
    longitude: 8.98198552,
    latitude: 44.91316314,
  },
  {
    label: "Casasco",
    longitude: 9.00605114,
    latitude: 44.82822593,
  },
  {
    label: "Cassano Spinola",
    longitude: 8.86300797,
    latitude: 44.76619504,
  },
  {
    label: "Cassine",
    longitude: 8.525483,
    latitude: 44.74821538,
  },
  {
    label: "Cassinelle",
    longitude: 8.56047456,
    latitude: 44.60684663,
  },
  {
    label: "Castellania",
    longitude: 8.93045228,
    latitude: 44.79884656,
  },
  {
    label: "Castellar Guidobono",
    longitude: 8.94673509,
    latitude: 44.90626728,
  },
  {
    label: "Castellazzo Bormida",
    longitude: 8.57711652,
    latitude: 44.84547363,
  },
  {
    label: "Castelletto d'Erro",
    longitude: 8.39441096,
    latitude: 44.62508569,
  },
  {
    label: "Castelletto d'Orba",
    longitude: 8.70225531,
    latitude: 44.68556836,
  },
  {
    label: "Castelletto Merli",
    longitude: 8.2405562,
    latitude: 45.07520084,
  },
  {
    label: "Castelletto Monferrato",
    longitude: 8.56624165,
    latitude: 44.97986948,
  },
  {
    label: "Castelnuovo Bormida",
    longitude: 8.5462034,
    latitude: 44.74368801,
  },
  {
    label: "Castelnuovo Scrivia",
    longitude: 8.88163236,
    latitude: 44.97942413,
  },
  {
    label: "Castelspina",
    longitude: 8.5824154,
    latitude: 44.80447097,
  },
  {
    label: "Cavatore",
    longitude: 8.45241293,
    latitude: 44.63096191,
  },
  {
    label: "Cella Monte",
    longitude: 8.39068715,
    latitude: 45.07480127,
  },
  {
    label: "Cereseto",
    longitude: 8.31694083,
    latitude: 45.08601084,
  },
  {
    label: "Cerreto Grue",
    longitude: 8.93197656,
    latitude: 44.84186342,
  },
  {
    label: "Cerrina Monferrato",
    longitude: 8.2152477,
    latitude: 45.12019189,
  },
  {
    label: "Coniolo",
    longitude: 8.37204723,
    latitude: 45.14690814,
  },
  {
    label: "Conzano",
    longitude: 8.45605851,
    latitude: 45.02054219,
  },
  {
    label: "Costa Vescovato",
    longitude: 8.92901622,
    latitude: 44.81820576,
  },
  {
    label: "Cremolino",
    longitude: 8.58583986,
    latitude: 44.63749767,
  },
  {
    label: "Cuccaro Monferrato",
    longitude: 8.45548032,
    latitude: 44.99071371,
  },
  {
    label: "Denice",
    longitude: 8.33401144,
    latitude: 44.59866478,
  },
  {
    label: "Dernice",
    longitude: 9.05007934,
    latitude: 44.76760327,
  },
  {
    label: "Fabbrica Curone",
    longitude: 9.15418217,
    latitude: 44.75482545,
  },
  {
    label: "Felizzano",
    longitude: 8.43580291,
    latitude: 44.89804289,
  },
  {
    label: "Fraconalto",
    longitude: 8.87795991,
    latitude: 44.5921573,
  },
  {
    label: "Francavilla Bisio",
    longitude: 8.73165894,
    latitude: 44.73589007,
  },
  {
    label: "Frascaro",
    longitude: 8.5326506,
    latitude: 44.82711359,
  },
  {
    label: "Frassinello Monferrato",
    longitude: 8.38660956,
    latitude: 45.03150897,
  },
  {
    label: "Frassineto Po",
    longitude: 8.53610328,
    latitude: 45.13401839,
  },
  {
    label: "Fresonara",
    longitude: 8.68484071,
    latitude: 44.78273653,
  },
  {
    label: "Frugarolo",
    longitude: 8.67906229,
    latitude: 44.8389863,
  },
  {
    label: "Fubine Monferrato",
    longitude: 8.42541266,
    latitude: 44.96556464,
  },
  {
    label: "Gabiano",
    longitude: 8.19315039,
    latitude: 45.15893956,
  },
  {
    label: "Gamalero",
    longitude: 8.5430521,
    latitude: 44.80892438,
  },
  {
    label: "Garbagna",
    longitude: 8.99903571,
    latitude: 44.78094061,
  },
  {
    label: "Gavazzana",
    longitude: 8.88625438,
    latitude: 44.77567686,
  },
  {
    label: "Gavi",
    longitude: 8.80360629,
    latitude: 44.68829965,
  },
  {
    label: "Giarole",
    longitude: 8.56785699,
    latitude: 45.0606548,
  },
  {
    label: "Gremiasco",
    longitude: 9.10473865,
    latitude: 44.79704719,
  },
  {
    label: "Grognardo",
    longitude: 8.49150662,
    latitude: 44.629935,
  },
  {
    label: "Grondona",
    longitude: 8.96254082,
    latitude: 44.69719799,
  },
  {
    label: "Guazzora",
    longitude: 8.84491063,
    latitude: 45.01359818,
  },
  {
    label: "Isola Sant'Antonio",
    longitude: 8.85040859,
    latitude: 45.03087376,
  },
  {
    label: "Lerma",
    longitude: 8.71251661,
    latitude: 44.63497457,
  },
  {
    label: "Lu",
    longitude: 8.48397707,
    latitude: 45.00183653,
  },
  {
    label: "Malvicino",
    longitude: 8.41406649,
    latitude: 44.55965891,
  },
  {
    label: "Masio",
    longitude: 8.40801092,
    latitude: 44.8693944,
  },
  {
    label: "Melazzo",
    longitude: 8.42690281,
    latitude: 44.64399212,
  },
  {
    label: "Merana",
    longitude: 8.29752453,
    latitude: 44.51779681,
  },
  {
    label: "Mirabello Monferrato",
    longitude: 8.52345012,
    latitude: 45.03574687,
  },
  {
    label: "Molare",
    longitude: 8.60108309,
    latitude: 44.62062138,
  },
  {
    label: "Molino dei Torti",
    longitude: 8.89203048,
    latitude: 45.02354187,
  },
  {
    label: "Mombello Monferrato",
    longitude: 8.25088371,
    latitude: 45.13302907,
  },
  {
    label: "Momperone",
    longitude: 9.03447924,
    latitude: 44.83864164,
  },
  {
    label: "Moncestino",
    longitude: 8.15840028,
    latitude: 45.15485139,
  },
  {
    label: "Mongiardino Ligure",
    longitude: 9.06126316,
    latitude: 44.63855849,
  },
  {
    label: "Monleale",
    longitude: 8.9843698,
    latitude: 44.88507491,
  },
  {
    label: "Montacuto",
    longitude: 9.10687493,
    latitude: 44.76675329,
  },
  {
    label: "Montaldeo",
    longitude: 8.7306833,
    latitude: 44.66653728,
  },
  {
    label: "Montaldo Bormida",
    longitude: 8.58900574,
    latitude: 44.68345546,
  },
  {
    label: "Montecastello",
    longitude: 8.68660651,
    latitude: 44.94895015,
  },
  {
    label: "Montechiaro d'Acqui",
    longitude: 8.37967335,
    latitude: 44.59498476,
  },
  {
    label: "Montegioco",
    longitude: 8.95408341,
    latitude: 44.83779799,
  },
  {
    label: "Montemarzino",
    longitude: 8.99313137,
    latitude: 44.84848618,
  },
  {
    label: "Morano sul Po",
    longitude: 8.36562081,
    latitude: 45.16403878,
  },
  {
    label: "Morbello",
    longitude: 8.51001025,
    latitude: 44.60347745,
  },
  {
    label: "Mornese",
    longitude: 8.75619133,
    latitude: 44.63908249,
  },
  {
    label: "Morsasco",
    longitude: 8.54909592,
    latitude: 44.66456561,
  },
  {
    label: "Murisengo",
    longitude: 8.137466,
    latitude: 45.08344752,
  },
  {
    label: "Novi Ligure",
    longitude: 8.78709895,
    latitude: 44.76018447,
  },
  {
    label: "Occimiano",
    longitude: 8.50665552,
    latitude: 45.06017802,
  },
  {
    label: "Odalengo Grande",
    longitude: 8.16672164,
    latitude: 45.10952242,
  },
  {
    label: "Odalengo Piccolo",
    longitude: 8.20951508,
    latitude: 45.07192832,
  },
  {
    label: "Olivola",
    longitude: 8.36696226,
    latitude: 45.03769495,
  },
  {
    label: "Orsara Bormida",
    longitude: 8.56243644,
    latitude: 44.69008849,
  },
  {
    label: "Ottiglio",
    longitude: 8.33914672,
    latitude: 45.05183484,
  },
  {
    label: "Ovada",
    longitude: 8.64638692,
    latitude: 44.63746785,
  },
  {
    label: "Oviglio",
    longitude: 8.48672464,
    latitude: 44.85980406,
  },
  {
    label: "Ozzano Monferrato",
    longitude: 8.37622616,
    latitude: 45.10189355,
  },
  {
    label: "Paderna",
    longitude: 8.89000207,
    latitude: 44.82072604,
  },
  {
    label: "Pareto",
    longitude: 8.38286675,
    latitude: 44.51574319,
  },
  {
    label: "Parodi Ligure",
    longitude: 8.758618,
    latitude: 44.67082366,
  },
  {
    label: "Pasturana",
    longitude: 8.74903841,
    latitude: 44.75067788,
  },
  {
    label: "Pecetto di Valenza",
    longitude: 8.6704681,
    latitude: 44.98971739,
  },
  {
    label: "Pietra Marazzi",
    longitude: 8.6705542,
    latitude: 44.94414223,
  },
  {
    label: "Piovera",
    longitude: 8.7349996,
    latitude: 44.95967234,
  },
  {
    label: "Pomaro Monferrato",
    longitude: 8.59519185,
    latitude: 45.06391823,
  },
  {
    label: "Pontecurone",
    longitude: 8.93593783,
    latitude: 44.95884144,
  },
  {
    label: "Pontestura",
    longitude: 8.33254095,
    latitude: 45.14364169,
  },
  {
    label: "Ponti",
    longitude: 8.36523345,
    latitude: 44.62741706,
  },
  {
    label: "Ponzano Monferrato",
    longitude: 8.26621831,
    latitude: 45.0844605,
  },
  {
    label: "Ponzone",
    longitude: 8.45960731,
    latitude: 44.58640594,
  },
  {
    label: "Pozzol Groppo",
    longitude: 9.02931411,
    latitude: 44.87745949,
  },
  {
    label: "Pozzolo Formigaro",
    longitude: 8.78154639,
    latitude: 44.79386971,
  },
  {
    label: "Prasco",
    longitude: 8.55285026,
    latitude: 44.64241408,
  },
  {
    label: "Predosa",
    longitude: 8.65923977,
    latitude: 44.75272978,
  },
  {
    label: "Quargnento",
    longitude: 8.48770761,
    latitude: 44.94484127,
  },
  {
    label: "Quattordio",
    longitude: 8.40598711,
    latitude: 44.89920749,
  },
  {
    label: "Ricaldone",
    longitude: 8.46963348,
    latitude: 44.73208972,
  },
  {
    label: "Rivalta Bormida",
    longitude: 8.55025847,
    latitude: 44.71137561,
  },
  {
    label: "Rivarone",
    longitude: 8.71569485,
    latitude: 44.97765973,
  },
  {
    label: "Roccaforte Ligure",
    longitude: 9.02776944,
    latitude: 44.67771211,
  },
  {
    label: "Rocca Grimalda",
    longitude: 8.64728666,
    latitude: 44.67110005,
  },
  {
    label: "Rocchetta Ligure",
    longitude: 9.05002771,
    latitude: 44.70654712,
  },
  {
    label: "Rosignano Monferrato",
    longitude: 8.39905893,
    latitude: 45.07984539,
  },
  {
    label: "Sala Monferrato",
    longitude: 8.35817633,
    latitude: 45.07539836,
  },
  {
    label: "Sale",
    longitude: 8.80897055,
    latitude: 44.98474756,
  },
  {
    label: "San Cristoforo",
    longitude: 8.74999574,
    latitude: 44.69241358,
  },
  {
    label: "San Giorgio Monferrato",
    longitude: 8.4148569,
    latitude: 45.10778635,
  },
  {
    label: "San Salvatore Monferrato",
    longitude: 8.56669038,
    latitude: 44.99480511,
  },
  {
    label: "San Sebastiano Curone",
    longitude: 9.06524327,
    latitude: 44.78561153,
  },
  {
    label: "Sant'Agata Fossili",
    longitude: 8.91893465,
    latitude: 44.78591123,
  },
  {
    label: "Sardigliano",
    longitude: 8.9076741,
    latitude: 44.75429733,
  },
  {
    label: "Sarezzano",
    longitude: 8.9153611,
    latitude: 44.86755745,
  },
  {
    label: "Serralunga di Crea",
    longitude: 8.2818606,
    latitude: 45.10074125,
  },
  {
    label: "Serravalle Scrivia",
    longitude: 8.85899164,
    latitude: 44.71947066,
  },
  {
    label: "Sezzadio",
    longitude: 8.57272968,
    latitude: 44.78558679,
  },
  {
    label: "Silvano d'Orba",
    longitude: 8.67101282,
    latitude: 44.68557224,
  },
  {
    label: "Solero",
    longitude: 8.50770965,
    latitude: 44.91976152,
  },
  {
    label: "Solonghello",
    longitude: 8.28183774,
    latitude: 45.13095204,
  },
  {
    label: "Spigno Monferrato",
    longitude: 8.33530262,
    latitude: 44.54303249,
  },
  {
    label: "Spineto Scrivia",
    longitude: 8.87238248,
    latitude: 44.83762769,
  },
  {
    label: "Stazzano",
    longitude: 8.86739327,
    latitude: 44.7272582,
  },
  {
    label: "Strevi",
    longitude: 8.52245997,
    latitude: 44.69866281,
  },
  {
    label: "Tagliolo Monferrato",
    longitude: 8.66825224,
    latitude: 44.63908436,
  },
  {
    label: "Tassarolo",
    longitude: 8.76950107,
    latitude: 44.72658554,
  },
  {
    label: "Terruggia",
    longitude: 8.44217858,
    latitude: 45.08305643,
  },
  {
    label: "Terzo",
    longitude: 8.42105351,
    latitude: 44.66953696,
  },
  {
    label: "Ticineto",
    longitude: 8.55555016,
    latitude: 45.09748798,
  },
  {
    label: "Tortona",
    longitude: 8.86015893,
    latitude: 44.89474291,
  },
  {
    label: "Treville",
    longitude: 8.35975854,
    latitude: 45.09714157,
  },
  {
    label: "Trisobbio",
    longitude: 8.58627568,
    latitude: 44.66230296,
  },
  {
    label: "Valenza",
    longitude: 8.64622619,
    latitude: 45.01446704,
  },
  {
    label: "Valmacca",
    longitude: 8.58233724,
    latitude: 45.10046486,
  },
  {
    label: "Vignale Monferrato",
    longitude: 8.39653661,
    latitude: 45.01093201,
  },
  {
    label: "Vignole Borbera",
    longitude: 8.8905588,
    latitude: 44.70829074,
  },
  {
    label: "Viguzzolo",
    longitude: 8.92008036,
    latitude: 44.90492247,
  },
  {
    label: "Villadeati",
    longitude: 8.16969885,
    latitude: 45.07244218,
  },
  {
    label: "Villalvernia",
    longitude: 8.85664062,
    latitude: 44.8176055,
  },
  {
    label: "Villamiroglio",
    longitude: 8.16975728,
    latitude: 45.13444627,
  },
  {
    label: "Villanova Monferrato",
    longitude: 8.47846784,
    latitude: 45.18178964,
  },
  {
    label: "Villaromagnano",
    longitude: 8.88713485,
    latitude: 44.85109184,
  },
  {
    label: "Visone",
    longitude: 8.50327751,
    latitude: 44.6620623,
  },
  {
    label: "Volpedo",
    longitude: 8.983383,
    latitude: 44.88880456,
  },
  {
    label: "Volpeglino",
    longitude: 8.95896398,
    latitude: 44.89305041,
  },
  {
    label: "Voltaggio",
    longitude: 8.84265701,
    latitude: 44.62013284,
  },
  {
    label: "Allein",
    longitude: 7.27247864,
    latitude: 45.81299906,
  },
  {
    label: "Antey-Saint-André",
    longitude: 7.59320879,
    latitude: 45.80495538,
  },
  {
    label: "Aosta",
    longitude: 7.32014937,
    latitude: 45.73750285,
  },
  {
    label: "Arnad",
    longitude: 7.72127572,
    latitude: 45.64497675,
  },
  {
    label: "Arvier",
    longitude: 7.16930437,
    latitude: 45.70355949,
  },
  {
    label: "Avise",
    longitude: 7.14132997,
    latitude: 45.70870165,
  },
  {
    label: "Ayas",
    longitude: 7.68815712,
    latitude: 45.81404412,
  },
  {
    label: "Aymavilles",
    longitude: 7.24054522,
    latitude: 45.70136784,
  },
  {
    label: "Bard",
    longitude: 7.74663423,
    latitude: 45.6079571,
  },
  {
    label: "Bionaz",
    longitude: 7.42307118,
    latitude: 45.87406789,
  },
  {
    label: "Brissogne",
    longitude: 7.40457445,
    latitude: 45.72718069,
  },
  {
    label: "Brusson",
    longitude: 7.73281238,
    latitude: 45.7586183,
  },
  {
    label: "Challand-Saint-Anselme",
    longitude: 7.73656482,
    latitude: 45.71639945,
  },
  {
    label: "Challand-Saint-Victor",
    longitude: 7.70646173,
    latitude: 45.68977437,
  },
  {
    label: "Chambave",
    longitude: 7.54994156,
    latitude: 45.74433587,
  },
  {
    label: "Chamois",
    longitude: 7.62028471,
    latitude: 45.83835687,
  },
  {
    label: "Champdepraz",
    longitude: 7.65775453,
    latitude: 45.68574043,
  },
  {
    label: "Champorcher",
    longitude: 7.62079406,
    latitude: 45.6236126,
  },
  {
    label: "Charvensod",
    longitude: 7.32540756,
    latitude: 45.72043469,
  },
  {
    label: "Châtillon",
    longitude: 7.61324866,
    latitude: 45.7495666,
  },
  {
    label: "Cogne",
    longitude: 7.35589684,
    latitude: 45.60810335,
  },
  {
    label: "Courmayeur",
    longitude: 6.97211063,
    latitude: 45.79304937,
  },
  {
    label: "Donnas",
    longitude: 7.76743948,
    latitude: 45.60348451,
  },
  {
    label: "Doues",
    longitude: 7.30664971,
    latitude: 45.81924591,
  },
  {
    label: "Emarèse",
    longitude: 7.70044113,
    latitude: 45.72469931,
  },
  {
    label: "Etroubles",
    longitude: 7.23295436,
    latitude: 45.81970089,
  },
  {
    label: "Fénis",
    longitude: 7.49429922,
    latitude: 45.73609933,
  },
  {
    label: "Fontainemore",
    longitude: 7.86097348,
    latitude: 45.6490006,
  },
  {
    label: "Gaby",
    longitude: 7.88234959,
    latitude: 45.71286294,
  },
  {
    label: "Gignod",
    longitude: 7.29617804,
    latitude: 45.77825204,
  },
  {
    label: "Gressan",
    longitude: 7.28944079,
    latitude: 45.72162078,
  },
  {
    label: "Gressoney-La-Trinité",
    longitude: 7.82308558,
    latitude: 45.82724198,
  },
  {
    label: "Gressoney-Saint-Jean",
    longitude: 7.82758597,
    latitude: 45.77699719,
  },
  {
    label: "Hône",
    longitude: 7.73573316,
    latitude: 45.61185509,
  },
  {
    label: "Introd",
    longitude: 7.18721781,
    latitude: 45.69147016,
  },
  {
    label: "Issime",
    longitude: 7.85421598,
    latitude: 45.68545132,
  },
  {
    label: "Issogne",
    longitude: 7.68441737,
    latitude: 45.65394996,
  },
  {
    label: "Jovençan",
    longitude: 7.27497757,
    latitude: 45.7139631,
  },
  {
    label: "La Magdeleine",
    longitude: 7.61909957,
    latitude: 45.81079736,
  },
  {
    label: "La Salle",
    longitude: 7.07259636,
    latitude: 45.74688856,
  },
  {
    label: "La Thuile",
    longitude: 6.94907203,
    latitude: 45.71683466,
  },
  {
    label: "Lillianes",
    longitude: 7.84507084,
    latitude: 45.63276575,
  },
  {
    label: "Montjovet",
    longitude: 7.6741874,
    latitude: 45.70421567,
  },
  {
    label: "Morgex",
    longitude: 7.03606923,
    latitude: 45.75698191,
  },
  {
    label: "Nus",
    longitude: 7.4669417,
    latitude: 45.73920602,
  },
  {
    label: "Ollomont",
    longitude: 7.31090328,
    latitude: 45.84910177,
  },
  {
    label: "Oyace",
    longitude: 7.38250599,
    latitude: 45.85069846,
  },
  {
    label: "Perloz",
    longitude: 7.80860292,
    latitude: 45.6131138,
  },
  {
    label: "Pollein",
    longitude: 7.35717211,
    latitude: 45.72804929,
  },
  {
    label: "Pontboset",
    longitude: 7.68765237,
    latitude: 45.6064306,
  },
  {
    label: "Pontey",
    longitude: 7.59134931,
    latitude: 45.73789995,
  },
  {
    label: "Pont-Saint-Martin",
    longitude: 7.797798,
    latitude: 45.59981254,
  },
  {
    label: "Pré-Saint-Didier",
    longitude: 6.98635298,
    latitude: 45.76376415,
  },
  {
    label: "Quart",
    longitude: 7.41560289,
    latitude: 45.74067954,
  },
  {
    label: "Rhêmes-Notre-Dame",
    longitude: 7.1185289,
    latitude: 45.56970721,
  },
  {
    label: "Rhêmes-Saint-Georges",
    longitude: 7.15524759,
    latitude: 45.65525593,
  },
  {
    label: "Roisan",
    longitude: 7.31057909,
    latitude: 45.7844233,
  },
  {
    label: "Saint-Christophe",
    longitude: 7.35781441,
    latitude: 45.74833047,
  },
  {
    label: "Saint-Denis",
    longitude: 7.55719219,
    latitude: 45.75217771,
  },
  {
    label: "Saint-Marcel",
    longitude: 7.44828666,
    latitude: 45.7324156,
  },
  {
    label: "Saint-Nicolas",
    longitude: 7.16788714,
    latitude: 45.7166175,
  },
  {
    label: "Saint-Oyen",
    longitude: 7.21240783,
    latitude: 45.82462227,
  },
  {
    label: "Saint-Pierre",
    longitude: 7.22628899,
    latitude: 45.71043364,
  },
  {
    label: "Saint-Rhémy-en-Bosses",
    longitude: 7.18206384,
    latitude: 45.8230588,
  },
  {
    label: "Saint-Vincent",
    longitude: 7.64750229,
    latitude: 45.75022016,
  },
  {
    label: "Sarre",
    longitude: 7.25764516,
    latitude: 45.71794426,
  },
  {
    label: "Torgnon",
    longitude: 7.57120579,
    latitude: 45.8014716,
  },
  {
    label: "Valgrisenche",
    longitude: 7.06475581,
    latitude: 45.6303374,
  },
  {
    label: "Valpelline",
    longitude: 7.32855375,
    latitude: 45.82642294,
  },
  {
    label: "Valsavarenche",
    longitude: 7.20831202,
    latitude: 45.59199131,
  },
  {
    label: "Valtournenche",
    longitude: 7.62270907,
    latitude: 45.8770846,
  },
  {
    label: "Verrayes",
    longitude: 7.53225413,
    latitude: 45.76144401,
  },
  {
    label: "Verrès",
    longitude: 7.68994077,
    latitude: 45.66872714,
  },
  {
    label: "Villeneuve",
    longitude: 7.20773981,
    latitude: 45.70275195,
  },
  {
    label: "Airole",
    longitude: 7.55373372,
    latitude: 43.87193229,
  },
  {
    label: "Apricale",
    longitude: 7.66047436,
    latitude: 43.88046591,
  },
  {
    label: "Aquila d'Arroscia",
    longitude: 8.00538059,
    latitude: 44.08368752,
  },
  {
    label: "Armo",
    longitude: 7.91400884,
    latitude: 44.08737513,
  },
  {
    label: "Aurigo",
    longitude: 7.92256375,
    latitude: 43.98301362,
  },
  {
    label: "Badalucco",
    longitude: 7.84704751,
    latitude: 43.91563657,
  },
  {
    label: "Bajardo",
    longitude: 7.71823202,
    latitude: 43.90350463,
  },
  {
    label: "Bordighera",
    longitude: 7.67188845,
    latitude: 43.77976186,
  },
  {
    label: "Borghetto d'Arroscia",
    longitude: 7.98182679,
    latitude: 44.05746703,
  },
  {
    label: "Borgomaro",
    longitude: 7.94489614,
    latitude: 43.97560219,
  },
  {
    label: "Camporosso",
    longitude: 7.62833199,
    latitude: 43.81518186,
  },
  {
    label: "Caravonica",
    longitude: 7.95887794,
    latitude: 43.99234154,
  },
  {
    label: "Carpasio",
    longitude: 7.86676773,
    latitude: 43.95985266,
  },
  {
    label: "Castellaro",
    longitude: 7.86831607,
    latitude: 43.86469941,
  },
  {
    label: "Castel Vittorio",
    longitude: 7.67318527,
    latitude: 43.92874403,
  },
  {
    label: "Ceriana",
    longitude: 7.77294636,
    latitude: 43.88094776,
  },
  {
    label: "Cervo",
    longitude: 8.11439209,
    latitude: 43.92533848,
  },
  {
    label: "Cesio",
    longitude: 7.9749718,
    latitude: 44.00771437,
  },
  {
    label: "Chiusanico",
    longitude: 7.9916401,
    latitude: 43.97324089,
  },
  {
    label: "Chiusavecchia",
    longitude: 7.983327,
    latitude: 43.9678916,
  },
  {
    label: "Cipressa",
    longitude: 7.9340954,
    latitude: 43.85295315,
  },
  {
    label: "Civezza",
    longitude: 7.95128441,
    latitude: 43.87933711,
  },
  {
    label: "Cosio d'Arroscia",
    longitude: 7.83116754,
    latitude: 44.07581718,
  },
  {
    label: "Costarainera",
    longitude: 7.94124004,
    latitude: 43.85453541,
  },
  {
    label: "Diano Arentino",
    longitude: 8.0422744,
    latitude: 43.94799843,
  },
  {
    label: "Diano Castello",
    longitude: 8.06665921,
    latitude: 43.92417641,
  },
  {
    label: "Diano Marina",
    longitude: 8.08179249,
    latitude: 43.91003445,
  },
  {
    label: "Diano San Pietro",
    longitude: 8.06878914,
    latitude: 43.93115748,
  },
  {
    label: "Dolceacqua",
    longitude: 7.62346525,
    latitude: 43.84811905,
  },
  {
    label: "Dolcedo",
    longitude: 7.95177621,
    latitude: 43.90694017,
  },
  {
    label: "Imperia",
    longitude: 8.0278503,
    latitude: 43.88570648,
  },
  {
    label: "Isolabona",
    longitude: 7.63886282,
    latitude: 43.87999064,
  },
  {
    label: "Lucinasco",
    longitude: 7.96275211,
    latitude: 43.96777563,
  },
  {
    label: "Mendatica",
    longitude: 7.80555956,
    latitude: 44.07637352,
  },
  {
    label: "Molini di Triora",
    longitude: 7.77432953,
    latitude: 43.99014112,
  },
  {
    label: "Montalto Ligure",
    longitude: 7.84447343,
    latitude: 43.92775131,
  },
  {
    label: "Montegrosso Pian latitudete",
    longitude: 7.81784428,
    latitude: 44.06660991,
  },
  {
    label: "Olivetta San Michele",
    longitude: 7.51550262,
    latitude: 43.87980841,
  },
  {
    label: "Ospedaletti",
    longitude: 7.71770138,
    latitude: 43.80074234,
  },
  {
    label: "Perinaldo",
    longitude: 7.67423944,
    latitude: 43.86619925,
  },
  {
    label: "Pietrabruna",
    longitude: 7.90347589,
    latitude: 43.88997458,
  },
  {
    label: "Pieve di Teco",
    longitude: 7.91521507,
    latitude: 44.04699787,
  },
  {
    label: "Pigna",
    longitude: 7.66338032,
    latitude: 43.9330097,
  },
  {
    label: "Pompeiana",
    longitude: 7.8885282,
    latitude: 43.85278181,
  },
  {
    label: "Pontedassio",
    longitude: 8.0126941,
    latitude: 43.94044493,
  },
  {
    label: "Pornassio",
    longitude: 7.87095357,
    latitude: 44.07026852,
  },
  {
    label: "Prelà",
    longitude: 7.9369914,
    latitude: 43.92622371,
  },
  {
    label: "Ranzo",
    longitude: 8.01446794,
    latitude: 44.05956136,
  },
  {
    label: "Rezzo",
    longitude: 7.87215721,
    latitude: 44.02031375,
  },
  {
    label: "Riva Ligure",
    longitude: 7.88171211,
    latitude: 43.83752404,
  },
  {
    label: "Rocchetta Nervina",
    longitude: 7.60029478,
    latitude: 43.89017646,
  },
  {
    label: "San Bartolomeo al Mare",
    longitude: 8.10216833,
    latitude: 43.92851288,
  },
  {
    label: "San Biagio della Cima",
    longitude: 7.64984983,
    latitude: 43.81878782,
  },
  {
    label: "San Lorenzo al Mare",
    longitude: 7.96388149,
    latitude: 43.85360939,
  },
  {
    label: "Sanremo",
    longitude: 7.78463261,
    latitude: 43.82100004,
  },
  {
    label: "Santo Stefano al Mare",
    longitude: 7.89907803,
    latitude: 43.83724835,
  },
  {
    label: "Seborga",
    longitude: 7.69416032,
    latitude: 43.82588301,
  },
  {
    label: "Soldano",
    longitude: 7.65407956,
    latitude: 43.82672282,
  },
  {
    label: "Taggia",
    longitude: 7.85052289,
    latitude: 43.84362744,
  },
  {
    label: "Terzorio",
    longitude: 7.8982476,
    latitude: 43.85284036,
  },
  {
    label: "Triora",
    longitude: 7.76211239,
    latitude: 43.99381326,
  },
  {
    label: "Vallebona",
    longitude: 7.66683606,
    latitude: 43.81328065,
  },
  {
    label: "Vallecrosia",
    longitude: 7.6437872,
    latitude: 43.79318232,
  },
  {
    label: "Vasia",
    longitude: 7.95410759,
    latitude: 43.93302326,
  },
  {
    label: "Ventimiglia",
    longitude: 7.60863489,
    latitude: 43.79003749,
  },
  {
    label: "Vessalico",
    longitude: 7.96224656,
    latitude: 44.04622744,
  },
  {
    label: "Villa Faraldi",
    longitude: 8.0914669,
    latitude: 43.96709172,
  },
  {
    label: "Alassio",
    longitude: 8.17296106,
    latitude: 44.00803855,
  },
  {
    label: "Albenga",
    longitude: 8.21294718,
    latitude: 44.04910721,
  },
  {
    label: "Albissola Marina",
    longitude: 8.50318133,
    latitude: 44.32724829,
  },
  {
    label: "Albisola Superiore",
    longitude: 8.51033951,
    latitude: 44.33968692,
  },
  {
    label: "Altare",
    longitude: 8.3439314,
    latitude: 44.33478282,
  },
  {
    label: "Andora",
    longitude: 8.14003459,
    latitude: 43.95824758,
  },
  {
    label: "Arnasco",
    longitude: 8.10696787,
    latitude: 44.07799865,
  },
  {
    label: "Balestrino",
    longitude: 8.17458666,
    latitude: 44.12489729,
  },
  {
    label: "Bardineto",
    longitude: 8.12938875,
    latitude: 44.19209639,
  },
  {
    label: "Bergeggi",
    longitude: 8.44410089,
    latitude: 44.24906108,
  },
  {
    label: "Boissano",
    longitude: 8.22123144,
    latitude: 44.13424252,
  },
  {
    label: "Borghetto Santo Spirito",
    longitude: 8.24198802,
    latitude: 44.11538413,
  },
  {
    label: "Borgio Verezzi",
    longitude: 8.3045609,
    latitude: 44.15987456,
  },
  {
    label: "Bormida",
    longitude: 8.23261121,
    latitude: 44.27865707,
  },
  {
    label: "Cairo Montenotte",
    longitude: 8.27417261,
    latitude: 44.39971795,
  },
  {
    label: "Calice Ligure",
    longitude: 8.29426933,
    latitude: 44.20491797,
  },
  {
    label: "Calizzano",
    longitude: 8.11537146,
    latitude: 44.235196,
  },
  {
    label: "Carcare",
    longitude: 8.29082811,
    latitude: 44.3579668,
  },
  {
    label: "Casanova Lerrone",
    longitude: 8.04827694,
    latitude: 44.03159416,
  },
  {
    label: "Castelbianco",
    longitude: 8.07479916,
    latitude: 44.11321558,
  },
  {
    label: "Castelvecchio di Rocca Barbena",
    longitude: 8.1155059,
    latitude: 44.13038323,
  },
  {
    label: "Celle Ligure",
    longitude: 8.54522349,
    latitude: 44.34206161,
  },
  {
    label: "Cengio",
    longitude: 8.20840962,
    latitude: 44.3875635,
  },
  {
    label: "Ceriale",
    longitude: 8.23206591,
    latitude: 44.09635674,
  },
  {
    label: "Cisano sul Neva",
    longitude: 8.14496038,
    latitude: 44.08729345,
  },
  {
    label: "Cosseria",
    longitude: 8.23445622,
    latitude: 44.36851305,
  },
  {
    label: "Dego",
    longitude: 8.31038187,
    latitude: 44.44959522,
  },
  {
    label: "Erli",
    longitude: 8.10533097,
    latitude: 44.13656262,
  },
  {
    label: "Finale Ligure",
    longitude: 8.34360444,
    latitude: 44.16902304,
  },
  {
    label: "Garlenda",
    longitude: 8.09679584,
    latitude: 44.03360058,
  },
  {
    label: "Giustenice",
    longitude: 8.24621583,
    latitude: 44.17232581,
  },
  {
    label: "Giusvalla",
    longitude: 8.3937268,
    latitude: 44.44947912,
  },
  {
    label: "Laigueglia",
    longitude: 8.15814419,
    latitude: 43.97461904,
  },
  {
    label: "Loano",
    longitude: 8.25977713,
    latitude: 44.12917319,
  },
  {
    label: "Magliolo",
    longitude: 8.25018606,
    latitude: 44.19163662,
  },
  {
    label: "Mallare",
    longitude: 8.29757667,
    latitude: 44.29146605,
  },
  {
    label: "Massimino",
    longitude: 8.07116002,
    latitude: 44.29939342,
  },
  {
    label: "Millesimo",
    longitude: 8.20581826,
    latitude: 44.36442319,
  },
  {
    label: "Mioglia",
    longitude: 8.4112728,
    latitude: 44.48986122,
  },
  {
    label: "Murialdo",
    longitude: 8.16135683,
    latitude: 44.31617201,
  },
  {
    label: "Nasino",
    longitude: 8.02784153,
    latitude: 44.11511382,
  },
  {
    label: "Noli",
    longitude: 8.41618422,
    latitude: 44.20566323,
  },
  {
    label: "Onzo",
    longitude: 8.05141127,
    latitude: 44.06959174,
  },
  {
    label: "Orco Feglino",
    longitude: 8.32516188,
    latitude: 44.21956675,
  },
  {
    label: "Ortovero",
    longitude: 8.09108827,
    latitude: 44.05382692,
  },
  {
    label: "Osiglia",
    longitude: 8.20061895,
    latitude: 44.28079135,
  },
  {
    label: "Pallare",
    longitude: 8.27496416,
    latitude: 44.32763192,
  },
  {
    label: "Piana Crixia",
    longitude: 8.30751198,
    latitude: 44.48527201,
  },
  {
    label: "Pietra Ligure",
    longitude: 8.282827,
    latitude: 44.14886132,
  },
  {
    label: "Plodio",
    longitude: 8.24352274,
    latitude: 44.35653151,
  },
  {
    label: "Pontinvrea",
    longitude: 8.43457011,
    latitude: 44.44489686,
  },
  {
    label: "Quiliano",
    longitude: 8.41137969,
    latitude: 44.29342208,
  },
  {
    label: "Rialto",
    longitude: 8.27472128,
    latitude: 44.21960792,
  },
  {
    label: "Roccavignale",
    longitude: 8.19201219,
    latitude: 44.36188556,
  },
  {
    label: "Sassello",
    longitude: 8.48994519,
    latitude: 44.47903665,
  },
  {
    label: "Savona",
    longitude: 8.48110865,
    latitude: 44.30750461,
  },
  {
    label: "Spotorno",
    longitude: 8.41946267,
    latitude: 44.22717185,
  },
  {
    label: "Stella",
    longitude: 8.49307801,
    latitude: 44.39689198,
  },
  {
    label: "Stellanello",
    longitude: 8.0593304,
    latitude: 44.00031785,
  },
  {
    label: "Testico",
    longitude: 8.03097385,
    latitude: 44.00536918,
  },
  {
    label: "Toirano",
    longitude: 8.20719338,
    latitude: 44.12691774,
  },
  {
    label: "Tovo San Giacomo",
    longitude: 8.26853791,
    latitude: 44.17670181,
  },
  {
    label: "Urbe",
    longitude: 8.59238717,
    latitude: 44.48775393,
  },
  {
    label: "Vado Ligure",
    longitude: 8.43628063,
    latitude: 44.26933816,
  },
  {
    label: "Varazze",
    longitude: 8.57639806,
    latitude: 44.35999892,
  },
  {
    label: "Vendone",
    longitude: 8.07425949,
    latitude: 44.07401137,
  },
  {
    label: "Vezzi Portio",
    longitude: 8.36194732,
    latitude: 44.22894653,
  },
  {
    label: "Villanova d'Albenga",
    longitude: 8.14308072,
    latitude: 44.04590077,
  },
  {
    label: "Zuccarello",
    longitude: 8.11565808,
    latitude: 44.11134732,
  },
  {
    label: "Arenzano",
    longitude: 8.68226057,
    latitude: 44.40179322,
  },
  {
    label: "Avegno",
    longitude: 9.15871776,
    latitude: 44.38230695,
  },
  {
    label: "Bargagli",
    longitude: 9.0883387,
    latitude: 44.44464301,
  },
  {
    label: "Bogliasco",
    longitude: 9.06978128,
    latitude: 44.37822158,
  },
  {
    label: "Borzonasca",
    longitude: 9.38679744,
    latitude: 44.42220304,
  },
  {
    label: "Busalla",
    longitude: 8.94498048,
    latitude: 44.57080117,
  },
  {
    label: "Camogli",
    longitude: 9.15713844,
    latitude: 44.34680493,
  },
  {
    label: "Campo Ligure",
    longitude: 8.69762914,
    latitude: 44.53838818,
  },
  {
    label: "Campomorone",
    longitude: 8.89267577,
    latitude: 44.5066955,
  },
  {
    label: "Carasco",
    longitude: 9.34404046,
    latitude: 44.35131469,
  },
  {
    label: "Casarza Ligure",
    longitude: 9.45345696,
    latitude: 44.27462061,
  },
  {
    label: "Casella",
    longitude: 8.99919877,
    latitude: 44.53499584,
  },
  {
    label: "Castiglione Chiavarese",
    longitude: 9.51354812,
    latitude: 44.27471107,
  },
  {
    label: "Ceranesi",
    longitude: 8.89459705,
    latitude: 44.50393511,
  },
  {
    label: "Chiavari",
    longitude: 9.32401708,
    latitude: 44.31671072,
  },
  {
    label: "Cicagna",
    longitude: 9.23594891,
    latitude: 44.4086,
  },
  {
    label: "Cogoleto",
    longitude: 8.64632983,
    latitude: 44.38946889,
  },
  {
    label: "Cogorno",
    longitude: 9.35217274,
    latitude: 44.32864607,
  },
  {
    label: "Coreglia Ligure",
    longitude: 9.26698739,
    latitude: 44.3920448,
  },
  {
    label: "Crocefieschi",
    longitude: 9.02281748,
    latitude: 44.58482451,
  },
  {
    label: "Davagna",
    longitude: 9.08668974,
    latitude: 44.46474451,
  },
  {
    label: "Fascia",
    longitude: 9.23626672,
    latitude: 44.56449009,
  },
  {
    label: "Favale di Malvaro",
    longitude: 9.25802513,
    latitude: 44.45203897,
  },
  {
    label: "Fontanigorda",
    longitude: 9.30494807,
    latitude: 44.54655632,
  },
  {
    label: "Genova",
    longitude: 8.9326992,
    latitude: 44.41149314,
  },
  {
    label: "Gorreto",
    longitude: 9.29209495,
    latitude: 44.604969,
  },
  {
    label: "Isola del Cantone",
    longitude: 8.95597042,
    latitude: 44.64584548,
  },
  {
    label: "Lavagna",
    longitude: 9.34403674,
    latitude: 44.30715764,
  },
  {
    label: "Leivi",
    longitude: 9.3182164,
    latitude: 44.34552337,
  },
  {
    label: "Lorsica",
    longitude: 9.27433441,
    latitude: 44.43234464,
  },
  {
    label: "Lumarzo",
    longitude: 9.13732081,
    latitude: 44.44356861,
  },
  {
    label: "Masone",
    longitude: 8.71169286,
    latitude: 44.50363621,
  },
  {
    label: "Mele",
    longitude: 8.74802865,
    latitude: 44.44540292,
  },
  {
    label: "Mezzanego",
    longitude: 9.37690562,
    latitude: 44.3836974,
  },
  {
    label: "Mignanego",
    longitude: 8.91468328,
    latitude: 44.52764357,
  },
  {
    label: "Moconesi",
    longitude: 9.21234739,
    latitude: 44.41952882,
  },
  {
    label: "Moneglia",
    longitude: 9.49012691,
    latitude: 44.23854867,
  },
  {
    label: "Montebruno",
    longitude: 9.24979047,
    latitude: 44.5270933,
  },
  {
    label: "Montoggio",
    longitude: 9.0441989,
    latitude: 44.51558055,
  },
  {
    label: "Ne",
    longitude: 9.39842041,
    latitude: 44.34436715,
  },
  {
    label: "Neirone",
    longitude: 9.19090098,
    latitude: 44.45425495,
  },
  {
    label: "Orero",
    longitude: 9.27460151,
    latitude: 44.40723425,
  },
  {
    label: "Pieve Ligure",
    longitude: 9.08689032,
    latitude: 44.37467039,
  },
  {
    label: "Portofino",
    longitude: 9.20722887,
    latitude: 44.30409182,
  },
  {
    label: "Propata",
    longitude: 9.18488278,
    latitude: 44.56471709,
  },
  {
    label: "Rapallo",
    longitude: 9.23174684,
    latitude: 44.35017452,
  },
  {
    label: "Recco",
    longitude: 9.14354904,
    latitude: 44.36219307,
  },
  {
    label: "Rezzoaglio",
    longitude: 9.38665818,
    latitude: 44.52570666,
  },
  {
    label: "Ronco Scrivia",
    longitude: 8.95498801,
    latitude: 44.60820328,
  },
  {
    label: "Rondanina",
    longitude: 9.21760907,
    latitude: 44.5623571,
  },
  {
    label: "Rossiglione",
    longitude: 8.67053508,
    latitude: 44.56211352,
  },
  {
    label: "Rovegno",
    longitude: 9.27867307,
    latitude: 44.57711393,
  },
  {
    label: "San Colombano Certenoli",
    longitude: 9.31312353,
    latitude: 44.37173186,
  },
  {
    label: "Santa Margherita Ligure",
    longitude: 9.20992464,
    latitude: 44.33486974,
  },
  {
    label: "Sant'Olcese",
    longitude: 8.96767968,
    latitude: 44.48366578,
  },
  {
    label: "Santo Stefano d'Aveto",
    longitude: 9.45092663,
    latitude: 44.54782889,
  },
  {
    label: "Savignone",
    longitude: 8.9879556,
    latitude: 44.56373567,
  },
  {
    label: "Serra Riccò",
    longitude: 8.93604499,
    latitude: 44.50784963,
  },
  {
    label: "Sestri Levante",
    longitude: 9.39316235,
    latitude: 44.26990054,
  },
  {
    label: "Sori",
    longitude: 9.10436515,
    latitude: 44.37278803,
  },
  {
    label: "Tiglieto",
    longitude: 8.62044541,
    latitude: 44.52535713,
  },
  {
    label: "Torriglia",
    longitude: 9.15777778,
    latitude: 44.51901939,
  },
  {
    label: "Tribogna",
    longitude: 9.19559972,
    latitude: 44.41627446,
  },
  {
    label: "Uscio",
    longitude: 9.16328775,
    latitude: 44.4132013,
  },
  {
    label: "Valbrevenna",
    longitude: 9.06502603,
    latitude: 44.55652959,
  },
  {
    label: "Vobbia",
    longitude: 9.03982341,
    latitude: 44.5997666,
  },
  {
    label: "Zoagli",
    longitude: 9.26724315,
    latitude: 44.3370341,
  },
  {
    label: "Ameglia",
    longitude: 9.95726865,
    latitude: 44.065747,
  },
  {
    label: "Arcola",
    longitude: 9.90585039,
    latitude: 44.11461457,
  },
  {
    label: "Beverino",
    longitude: 9.76927899,
    latitude: 44.19406922,
  },
  {
    label: "Bolano",
    longitude: 9.89528858,
    latitude: 44.18886645,
  },
  {
    label: "Bonassola",
    longitude: 9.58410218,
    latitude: 44.18335984,
  },
  {
    label: "Borghetto di Vara",
    longitude: 9.72160948,
    latitude: 44.22351961,
  },
  {
    label: "Brugnato",
    longitude: 9.72502225,
    latitude: 44.23686542,
  },
  {
    label: "Calice al Cornoviglio",
    longitude: 9.83583113,
    latitude: 44.2424782,
  },
  {
    label: "Carro",
    longitude: 9.60825868,
    latitude: 44.27271193,
  },
  {
    label: "Carrodano",
    longitude: 9.65615912,
    latitude: 44.24207789,
  },
  {
    label: "Castelnuovo Magra",
    longitude: 10.01820241,
    latitude: 44.09846668,
  },
  {
    label: "Deiva Marina",
    longitude: 9.5205608,
    latitude: 44.21886353,
  },
  {
    label: "Follo",
    longitude: 9.86214784,
    latitude: 44.16270731,
  },
  {
    label: "Framura",
    longitude: 9.55435807,
    latitude: 44.20891861,
  },
  {
    label: "La Spezia",
    longitude: 9.8281897,
    latitude: 44.10704991,
  },
  {
    label: "Lerici",
    longitude: 9.91098294,
    latitude: 44.07603286,
  },
  {
    label: "Levanto",
    longitude: 9.61246496,
    latitude: 44.16997519,
  },
  {
    label: "Maissana",
    longitude: 9.53501799,
    latitude: 44.33667186,
  },
  {
    label: "Monterosso al Mare",
    longitude: 9.65613667,
    latitude: 44.14629552,
  },
  {
    label: "Luni",
    longitude: 10.04202918,
    latitude: 44.08497076,
  },
  {
    label: "Pignone",
    longitude: 9.72404607,
    latitude: 44.17915951,
  },
  {
    label: "Portovenere",
    longitude: 9.83700513,
    latitude: 44.05579219,
  },
  {
    label: "Riccò del Golfo di Spezia",
    longitude: 9.76342359,
    latitude: 44.15555832,
  },
  {
    label: "Riomaggiore",
    longitude: 9.73814999,
    latitude: 44.09973171,
  },
  {
    label: "Rocchetta di Vara",
    longitude: 9.75689058,
    latitude: 44.25119026,
  },
  {
    label: "Santo Stefano di Magra",
    longitude: 9.91509755,
    latitude: 44.16241113,
  },
  {
    label: "Sarzana",
    longitude: 9.95967625,
    latitude: 44.11292985,
  },
  {
    label: "Sesta Godano",
    longitude: 9.67572402,
    latitude: 44.29399517,
  },
  {
    label: "Varese Ligure",
    longitude: 9.59567948,
    latitude: 44.3777309,
  },
  {
    label: "Vernazza",
    longitude: 9.68373233,
    latitude: 44.13522997,
  },
  {
    label: "Vezzano Ligure",
    longitude: 9.88447876,
    latitude: 44.14135989,
  },
  {
    label: "Zignago",
    longitude: 9.74495848,
    latitude: 44.27754123,
  },
  {
    label: "Agra",
    longitude: 8.77144627,
    latitude: 46.03408765,
  },
  {
    label: "Albizzate",
    longitude: 8.80354799,
    latitude: 45.72373624,
  },
  {
    label: "Angera",
    longitude: 8.57505473,
    latitude: 45.77248072,
  },
  {
    label: "Arcisate",
    longitude: 8.85979602,
    latitude: 45.85936588,
  },
  {
    label: "Arsago Seprio",
    longitude: 8.73102942,
    latitude: 45.68799132,
  },
  {
    label: "Azzate",
    longitude: 8.79686119,
    latitude: 45.78197525,
  },
  {
    label: "Azzio",
    longitude: 8.70629682,
    latitude: 45.88536153,
  },
  {
    label: "Barasso",
    longitude: 8.75810385,
    latitude: 45.84046799,
  },
  {
    label: "Bardello",
    longitude: 8.6982499,
    latitude: 45.8350432,
  },
  {
    label: "Bedero Valcuvia",
    longitude: 8.79441299,
    latitude: 45.91263579,
  },
  {
    label: "Besano",
    longitude: 8.88994859,
    latitude: 45.88923148,
  },
  {
    label: "Besnate",
    longitude: 8.76940011,
    latitude: 45.70035494,
  },
  {
    label: "Besozzo",
    longitude: 8.66515456,
    latitude: 45.85161622,
  },
  {
    label: "Biandronno",
    longitude: 8.71232632,
    latitude: 45.81939412,
  },
  {
    label: "Bisuschio",
    longitude: 8.87035829,
    latitude: 45.8724144,
  },
  {
    label: "Bodio Lomnago",
    longitude: 8.75143295,
    latitude: 45.78988982,
  },
  {
    label: "Brebbia",
    longitude: 8.64986902,
    latitude: 45.82856738,
  },
  {
    label: "Bregano",
    longitude: 8.69246682,
    latitude: 45.82367289,
  },
  {
    label: "Brenta",
    longitude: 8.68415016,
    latitude: 45.89520908,
  },
  {
    label: "Brezzo di Bedero",
    longitude: 8.71839172,
    latitude: 45.97788916,
  },
  {
    label: "Brinzio",
    longitude: 8.78680655,
    latitude: 45.88913557,
  },
  {
    label: "Brissago-Valtravaglia",
    longitude: 8.74641128,
    latitude: 45.94962111,
  },
  {
    label: "Brunello",
    longitude: 8.7958022,
    latitude: 45.76615745,
  },
  {
    label: "Brusimpiano",
    longitude: 8.88960338,
    latitude: 45.94718903,
  },
  {
    label: "Buguggiate",
    longitude: 8.80675732,
    latitude: 45.78525778,
  },
  {
    label: "Busto Arsizio",
    longitude: 8.85494196,
    latitude: 45.61508411,
  },
  {
    label: "Cadegliano-Viconago",
    longitude: 8.84466504,
    latitude: 45.95877781,
  },
  {
    label: "Cadrezzate",
    longitude: 8.6429034,
    latitude: 45.79438636,
  },
  {
    label: "Cairate",
    longitude: 8.87081304,
    latitude: 45.69202754,
  },
  {
    label: "Cantello",
    longitude: 8.89696935,
    latitude: 45.82105782,
  },
  {
    label: "Caravate",
    longitude: 8.65547691,
    latitude: 45.87950555,
  },
  {
    label: "Cardano al Campo",
    longitude: 8.77457849,
    latitude: 45.6466131,
  },
  {
    label: "Carnago",
    longitude: 8.83627171,
    latitude: 45.71828365,
  },
  {
    label: "Caronno Pertusella",
    longitude: 9.04786804,
    latitude: 45.59681856,
  },
  {
    label: "Caronno Varesino",
    longitude: 8.83098855,
    latitude: 45.73879258,
  },
  {
    label: "Casale Litta",
    longitude: 8.74033681,
    latitude: 45.76739057,
  },
  {
    label: "Casalzuigno",
    longitude: 8.70550787,
    latitude: 45.90326961,
  },
  {
    label: "Casciago",
    longitude: 8.78437936,
    latitude: 45.83477437,
  },
  {
    label: "Casorate Sempione",
    longitude: 8.74031796,
    latitude: 45.67171363,
  },
  {
    label: "Cassano Magnago",
    longitude: 8.82082527,
    latitude: 45.674381,
  },
  {
    label: "Cassano Valcuvia",
    longitude: 8.76817676,
    latitude: 45.932577,
  },
  {
    label: "Castellanza",
    longitude: 8.89758795,
    latitude: 45.61147087,
  },
  {
    label: "Castello Cabiaglio",
    longitude: 8.75481888,
    latitude: 45.89254824,
  },
  {
    label: "Castelseprio",
    longitude: 8.86127391,
    latitude: 45.71695339,
  },
  {
    label: "Castelveccana",
    longitude: 8.66483192,
    latitude: 45.94653339,
  },
  {
    label: "Castiglione Olona",
    longitude: 8.86808089,
    latitude: 45.75638484,
  },
  {
    label: "Castronno",
    longitude: 8.81288955,
    latitude: 45.74696138,
  },
  {
    label: "Cavaria con Premezzo",
    longitude: 8.79768031,
    latitude: 45.69454899,
  },
  {
    label: "Cazzago Brabbia",
    longitude: 8.73527589,
    latitude: 45.79535134,
  },
  {
    label: "Cislago",
    longitude: 8.97062131,
    latitude: 45.66174873,
  },
  {
    label: "Cittiglio",
    longitude: 8.66871307,
    latitude: 45.8946628,
  },
  {
    label: "Clivio",
    longitude: 8.93358998,
    latitude: 45.86445858,
  },
  {
    label: "Cocquio-Trevisago",
    longitude: 8.6965465,
    latitude: 45.85787962,
  },
  {
    label: "Comabbio",
    longitude: 8.67466593,
    latitude: 45.77263671,
  },
  {
    label: "Comerio",
    longitude: 8.74709374,
    latitude: 45.8426223,
  },
  {
    label: "Cremenaga",
    longitude: 8.80659213,
    latitude: 45.98924397,
  },
  {
    label: "Crosio della Valle",
    longitude: 8.76756905,
    latitude: 45.76163488,
  },
  {
    label: "Cuasso al Monte",
    longitude: 8.8798154,
    latitude: 45.91440418,
  },
  {
    label: "Cugliate-Fabiasco",
    longitude: 8.82065422,
    latitude: 45.94703032,
  },
  {
    label: "Cunardo",
    longitude: 8.80225408,
    latitude: 45.93781498,
  },
  {
    label: "Curiglia con Monteviasco",
    longitude: 8.8048167,
    latitude: 46.06064966,
  },
  {
    label: "Cuveglio",
    longitude: 8.73583523,
    latitude: 45.90596577,
  },
  {
    label: "Cuvio",
    longitude: 8.73138709,
    latitude: 45.89803114,
  },
  {
    label: "Daverio",
    longitude: 8.77150586,
    latitude: 45.77710951,
  },
  {
    label: "Dumenza",
    longitude: 8.78485575,
    latitude: 46.02277146,
  },
  {
    label: "Duno",
    longitude: 8.73854155,
    latitude: 45.91405764,
  },
  {
    label: "Fagnano Olona",
    longitude: 8.87410817,
    latitude: 45.66960007,
  },
  {
    label: "Ferno",
    longitude: 8.75884994,
    latitude: 45.61026875,
  },
  {
    label: "Ferrera di Varese",
    longitude: 8.78818329,
    latitude: 45.93232904,
  },
  {
    label: "Gallarate",
    longitude: 8.79329925,
    latitude: 45.66018302,
  },
  {
    label: "Galliate Lombardo",
    longitude: 8.76853346,
    latitude: 45.78629177,
  },
  {
    label: "Gavirate",
    longitude: 8.72477105,
    latitude: 45.84113211,
  },
  {
    label: "Gazzada Schianno",
    longitude: 8.82558358,
    latitude: 45.78025066,
  },
  {
    label: "Gemonio",
    longitude: 8.67884895,
    latitude: 45.87741982,
  },
  {
    label: "Gerenzano",
    longitude: 8.99858062,
    latitude: 45.63843744,
  },
  {
    label: "Germignaga",
    longitude: 8.72680431,
    latitude: 45.99340192,
  },
  {
    label: "Golasecca",
    longitude: 8.65509793,
    latitude: 45.696857,
  },
  {
    label: "Gorla Maggiore",
    longitude: 8.89352477,
    latitude: 45.66609126,
  },
  {
    label: "Gorla Minore",
    longitude: 8.89650863,
    latitude: 45.64326093,
  },
  {
    label: "Gornate Olona",
    longitude: 8.85910112,
    latitude: 45.73774934,
  },
  {
    label: "Grantola",
    longitude: 8.7707667,
    latitude: 45.94960692,
  },
  {
    label: "Inarzo",
    longitude: 8.7364261,
    latitude: 45.78677616,
  },
  {
    label: "Induno Olona",
    longitude: 8.83856569,
    latitude: 45.85196664,
  },
  {
    label: "Ispra",
    longitude: 8.61026566,
    latitude: 45.81617289,
  },
  {
    label: "Jerago con Orago",
    longitude: 8.79253825,
    latitude: 45.70524944,
  },
  {
    label: "Lavena Ponte Tresa",
    longitude: 8.86107511,
    latitude: 45.96092516,
  },
  {
    label: "Laveno-Mombello",
    longitude: 8.61535619,
    latitude: 45.91578637,
  },
  {
    label: "Leggiuno",
    longitude: 8.6193312,
    latitude: 45.87562703,
  },
  {
    label: "Lonate Ceppino",
    longitude: 8.87627239,
    latitude: 45.70528783,
  },
  {
    label: "Lonate Pozzolo",
    longitude: 8.75237386,
    latitude: 45.60005908,
  },
  {
    label: "Lozza",
    longitude: 8.85705579,
    latitude: 45.77548006,
  },
  {
    label: "Luino",
    longitude: 8.74242933,
    latitude: 46.00249108,
  },
  {
    label: "Luvinate",
    longitude: 8.77246432,
    latitude: 45.83955502,
  },
  {
    label: "Malgesso",
    longitude: 8.6761411,
    latitude: 45.82769458,
  },
  {
    label: "Malnate",
    longitude: 8.88111094,
    latitude: 45.79952624,
  },
  {
    label: "Marchirolo",
    longitude: 8.8357696,
    latitude: 45.95158873,
  },
  {
    label: "Marnate",
    longitude: 8.90387061,
    latitude: 45.62847701,
  },
  {
    label: "Marzio",
    longitude: 8.85776946,
    latitude: 45.93784232,
  },
  {
    label: "Masciago Primo",
    longitude: 8.78080342,
    latitude: 45.91642152,
  },
  {
    label: "Mercallo",
    longitude: 8.67210331,
    latitude: 45.74672525,
  },
  {
    label: "Mesenzana",
    longitude: 8.75881336,
    latitude: 45.94701873,
  },
  {
    label: "Montegrino Valtravaglia",
    longitude: 8.76812149,
    latitude: 45.97345415,
  },
  {
    label: "Monvalle",
    longitude: 8.63204654,
    latitude: 45.85759299,
  },
  {
    label: "Morazzone",
    longitude: 8.82937436,
    latitude: 45.76916148,
  },
  {
    label: "Mornago",
    longitude: 8.75239721,
    latitude: 45.74759383,
  },
  {
    label: "Oggiona con Santo Stefano",
    longitude: 8.81846582,
    latitude: 45.69730244,
  },
  {
    label: "Olgiate Olona",
    longitude: 8.89079261,
    latitude: 45.62776512,
  },
  {
    label: "Origgio",
    longitude: 9.01782113,
    latitude: 45.59963152,
  },
  {
    label: "Orino",
    longitude: 8.7129267,
    latitude: 45.88178979,
  },
  {
    label: "Osmate",
    longitude: 8.65520119,
    latitude: 45.78866971,
  },
  {
    label: "Porto Ceresio",
    longitude: 8.90627938,
    latitude: 45.90401736,
  },
  {
    label: "Porto Valtravaglia",
    longitude: 8.68034374,
    latitude: 45.96082147,
  },
  {
    label: "Rancio Valcuvia",
    longitude: 8.77019605,
    latitude: 45.91632586,
  },
  {
    label: "Ranco",
    longitude: 8.57472943,
    latitude: 45.79845478,
  },
  {
    label: "Saltrio",
    longitude: 8.92438188,
    latitude: 45.87355994,
  },
  {
    label: "Samarate",
    longitude: 8.78259875,
    latitude: 45.63092833,
  },
  {
    label: "Saronno",
    longitude: 9.03640459,
    latitude: 45.62373958,
  },
  {
    label: "Sesto Calende",
    longitude: 8.63164235,
    latitude: 45.72379362,
  },
  {
    label: "Solbiate Arno",
    longitude: 8.813467,
    latitude: 45.72056917,
  },
  {
    label: "Solbiate Olona",
    longitude: 8.88734116,
    latitude: 45.65359629,
  },
  {
    label: "Somma Lombardo",
    longitude: 8.70435241,
    latitude: 45.68377665,
  },
  {
    label: "Sumirago",
    longitude: 8.77937261,
    latitude: 45.74028467,
  },
  {
    label: "Taino",
    longitude: 8.61659007,
    latitude: 45.76498844,
  },
  {
    label: "Ternate",
    longitude: 8.69479817,
    latitude: 45.7823786,
  },
  {
    label: "Tradate",
    longitude: 8.90569481,
    latitude: 45.71375993,
  },
  {
    label: "Travedona-Monate",
    longitude: 8.67726088,
    latitude: 45.80570507,
  },
  {
    label: "Tronzano Lago Maggiore",
    longitude: 8.73551864,
    latitude: 46.0914661,
  },
  {
    label: "Uboldo",
    longitude: 9.00306867,
    latitude: 45.61071287,
  },
  {
    label: "Valganna",
    longitude: 8.82394745,
    latitude: 45.90276705,
  },
  {
    label: "Varano Borghi",
    longitude: 8.7019374,
    latitude: 45.77779049,
  },
  {
    label: "Varese",
    longitude: 8.82286834,
    latitude: 45.81701677,
  },
  {
    label: "Vedano Olona",
    longitude: 8.88635653,
    latitude: 45.77671212,
  },
  {
    label: "Venegono Inferiore",
    longitude: 8.89943301,
    latitude: 45.73587963,
  },
  {
    label: "Venegono Superiore",
    longitude: 8.90171645,
    latitude: 45.74912455,
  },
  {
    label: "Vergiate",
    longitude: 8.69540153,
    latitude: 45.7245355,
  },
  {
    label: "Viggiù",
    longitude: 8.90704038,
    latitude: 45.8720108,
  },
  {
    label: "Vizzola Ticino",
    longitude: 8.68505079,
    latitude: 45.63123483,
  },
  {
    label: "Sangiano",
    longitude: 8.63179044,
    latitude: 45.8762912,
  },
  {
    label: "Maccagno con Pino e Veddasca",
    longitude: 8.74237,
    latitude: 46.0426,
  },
  {
    label: "Albavilla",
    longitude: 9.18930004,
    latitude: 45.8010947,
  },
  {
    label: "Albese con Cassano",
    longitude: 9.16474347,
    latitude: 45.79543363,
  },
  {
    label: "Albiolo",
    longitude: 8.93736248,
    latitude: 45.80680091,
  },
  {
    label: "Alserio",
    longitude: 9.19837284,
    latitude: 45.77868888,
  },
  {
    label: "Alzate Brianza",
    longitude: 9.18202575,
    latitude: 45.76698007,
  },
  {
    label: "Anzano del Parco",
    longitude: 9.19875952,
    latitude: 45.77086446,
  },
  {
    label: "Appiano Gentile",
    longitude: 8.97949173,
    latitude: 45.73500438,
  },
  {
    label: "Argegno",
    longitude: 9.12740292,
    latitude: 45.94508355,
  },
  {
    label: "Arosio",
    longitude: 9.20766723,
    latitude: 45.71664742,
  },
  {
    label: "Asso",
    longitude: 9.26941885,
    latitude: 45.86165523,
  },
  {
    label: "Barni",
    longitude: 9.26396688,
    latitude: 45.91087559,
  },
  {
    label: "Bene Lario",
    longitude: 9.18360351,
    latitude: 46.0286252,
  },
  {
    label: "Beregazzo con Figliaro",
    longitude: 8.9508768,
    latitude: 45.77587949,
  },
  {
    label: "Binago",
    longitude: 8.92256563,
    latitude: 45.78468601,
  },
  {
    label: "Bizzarone",
    longitude: 8.94596475,
    latitude: 45.83503924,
  },
  {
    label: "Blessagno",
    longitude: 9.09739094,
    latitude: 45.95989389,
  },
  {
    label: "Blevio",
    longitude: 9.10486477,
    latitude: 45.84064094,
  },
  {
    label: "Bregnano",
    longitude: 9.06252406,
    latitude: 45.6989627,
  },
  {
    label: "Brenna",
    longitude: 9.18775301,
    latitude: 45.74164179,
  },
  {
    label: "Brienno",
    longitude: 9.13139045,
    latitude: 45.91174236,
  },
  {
    label: "Brunate",
    longitude: 9.09641584,
    latitude: 45.81977176,
  },
  {
    label: "Bulgarograsso",
    longitude: 9.01101248,
    latitude: 45.74813449,
  },
  {
    label: "Cabiate",
    longitude: 9.16670282,
    latitude: 45.67319795,
  },
  {
    label: "Cadorago",
    longitude: 9.03374726,
    latitude: 45.7239038,
  },
  {
    label: "Caglio",
    longitude: 9.23631564,
    latitude: 45.87057233,
  },
  {
    label: "Cagno",
    longitude: 8.92405315,
    latitude: 45.80876673,
  },
  {
    label: "Campione d'Italia",
    longitude: 8.970754,
    latitude: 45.97007944,
  },
  {
    label: "Cantù",
    longitude: 9.12658065,
    latitude: 45.73938659,
  },
  {
    label: "Canzo",
    longitude: 9.27350212,
    latitude: 45.85003757,
  },
  {
    label: "Capiago Intimiano",
    longitude: 9.1243087,
    latitude: 45.7721228,
  },
  {
    label: "Carate Urio",
    longitude: 9.12239702,
    latitude: 45.87196643,
  },
  {
    label: "Carbonate",
    longitude: 8.94040214,
    latitude: 45.68506128,
  },
  {
    label: "Carimate",
    longitude: 9.11525094,
    latitude: 45.70268568,
  },
  {
    label: "Carlazzo",
    longitude: 9.15649403,
    latitude: 46.04919611,
  },
  {
    label: "Carugo",
    longitude: 9.18915441,
    latitude: 45.70996915,
  },
  {
    label: "Casasco d'Intelvi",
    longitude: 9.07493421,
    latitude: 45.94424398,
  },
  {
    label: "Caslino d'Erba",
    longitude: 9.22712175,
    latitude: 45.83756065,
  },
  {
    label: "Casnate con Bernate",
    longitude: 9.0746556,
    latitude: 45.75679233,
  },
  {
    label: "Cassina Rizzardi",
    longitude: 9.03038994,
    latitude: 45.75103988,
  },
  {
    label: "Castelmarte",
    longitude: 9.23335971,
    latitude: 45.83250887,
  },
  {
    label: "Castelnuovo Bozzente",
    longitude: 8.94137967,
    latitude: 45.76345124,
  },
  {
    label: "Castiglione d'Intelvi",
    longitude: 9.09127875,
    latitude: 45.95530894,
  },
  {
    label: "Cavargna",
    longitude: 9.11202583,
    latitude: 46.09067552,
  },
  {
    label: "Cerano d'Intelvi",
    longitude: 9.08764297,
    latitude: 45.94446407,
  },
  {
    label: "Cermenate",
    longitude: 9.08268731,
    latitude: 45.698567,
  },
  {
    label: "Cernobbio",
    longitude: 9.07435334,
    latitude: 45.83962461,
  },
  {
    label: "Cirimido",
    longitude: 9.01108636,
    latitude: 45.69881381,
  },
  {
    label: "Claino con Osteno",
    longitude: 9.08434077,
    latitude: 46.00691802,
  },
  {
    label: "Colonno",
    longitude: 9.15409988,
    latitude: 45.95773383,
  },
  {
    label: "Como",
    longitude: 9.08515955,
    latitude: 45.8099912,
  },
  {
    label: "Corrido",
    longitude: 9.13701384,
    latitude: 46.04741939,
  },
  {
    label: "Cremia",
    longitude: 9.2707257,
    latitude: 46.0857975,
  },
  {
    label: "Cucciago",
    longitude: 9.09150332,
    latitude: 45.73888869,
  },
  {
    label: "Cusino",
    longitude: 9.15275144,
    latitude: 46.07437799,
  },
  {
    label: "Dizzasco",
    longitude: 9.10146455,
    latitude: 45.94387236,
  },
  {
    label: "Domaso",
    longitude: 9.32846683,
    latitude: 46.15194609,
  },
  {
    label: "Dongo",
    longitude: 9.27949844,
    latitude: 46.12302711,
  },
  {
    label: "Dosso del Liro",
    longitude: 9.27369623,
    latitude: 46.16368321,
  },
  {
    label: "Erba",
    longitude: 9.22318311,
    latitude: 45.81193507,
  },
  {
    label: "Eupilio",
    longitude: 9.26770263,
    latitude: 45.81692297,
  },
  {
    label: "Faggeto Lario",
    longitude: 9.15983766,
    latitude: 45.85807982,
  },
  {
    label: "Faloppio",
    longitude: 8.96320128,
    latitude: 45.81217463,
  },
  {
    label: "Fenegrò",
    longitude: 9.00273087,
    latitude: 45.70159962,
  },
  {
    label: "Figino Serenza",
    longitude: 9.12719392,
    latitude: 45.71163141,
  },
  {
    label: "Fino Mornasco",
    longitude: 9.04857887,
    latitude: 45.74611655,
  },
  {
    label: "Garzeno",
    longitude: 9.24985634,
    latitude: 46.13409064,
  },
  {
    label: "Gera Lario",
    longitude: 9.37110185,
    latitude: 46.17062506,
  },
  {
    label: "Grandate",
    longitude: 9.05829996,
    latitude: 45.7738937,
  },
  {
    label: "Grandola ed Uniti",
    longitude: 9.20713269,
    latitude: 46.03131732,
  },
  {
    label: "Griante",
    longitude: 9.23500949,
    latitude: 45.99694371,
  },
  {
    label: "Guanzate",
    longitude: 9.01094873,
    latitude: 45.72431329,
  },
  {
    label: "Inverigo",
    longitude: 9.21493527,
    latitude: 45.7329273,
  },
  {
    label: "Laglio",
    longitude: 9.14007012,
    latitude: 45.88352177,
  },
  {
    label: "Laino",
    longitude: 9.07385525,
    latitude: 45.98329717,
  },
  {
    label: "Lambrugo",
    longitude: 9.24151448,
    latitude: 45.75846534,
  },
  {
    label: "Lasnigo",
    longitude: 9.26407017,
    latitude: 45.88068666,
  },
  {
    label: "Lezzeno",
    longitude: 9.19108571,
    latitude: 45.94546302,
  },
  {
    label: "Limido Comasco",
    longitude: 8.98270779,
    latitude: 45.68970324,
  },
  {
    label: "Lipomo",
    longitude: 9.12147915,
    latitude: 45.79479996,
  },
  {
    label: "Livo",
    longitude: 9.30419652,
    latitude: 46.16890738,
  },
  {
    label: "Locate Varesino",
    longitude: 8.93081757,
    latitude: 45.69035206,
  },
  {
    label: "Lomazzo",
    longitude: 9.04014928,
    latitude: 45.69902477,
  },
  {
    label: "Longone al Segrino",
    longitude: 9.25099763,
    latitude: 45.81724782,
  },
  {
    label: "Luisago",
    longitude: 9.04345668,
    latitude: 45.76121718,
  },
  {
    label: "Lurago d'Erba",
    longitude: 9.22473624,
    latitude: 45.75348513,
  },
  {
    label: "Lurago Marinone",
    longitude: 8.98394626,
    latitude: 45.70780673,
  },
  {
    label: "Lurate Caccivio",
    longitude: 9.00067971,
    latitude: 45.7655543,
  },
  {
    label: "Magreglio",
    longitude: 9.26189473,
    latitude: 45.92002394,
  },
  {
    label: "Mariano Comense",
    longitude: 9.18251093,
    latitude: 45.69709757,
  },
  {
    label: "Maslianico",
    longitude: 9.04846523,
    latitude: 45.8425639,
  },
  {
    label: "Menaggio",
    longitude: 9.23891075,
    latitude: 46.02110324,
  },
  {
    label: "Merone",
    longitude: 9.2451163,
    latitude: 45.78638465,
  },
  {
    label: "Moltrasio",
    longitude: 9.09584337,
    latitude: 45.85915189,
  },
  {
    label: "Monguzzo",
    longitude: 9.23216904,
    latitude: 45.7828265,
  },
  {
    label: "Montano Lucino",
    longitude: 9.0412798,
    latitude: 45.78630139,
  },
  {
    label: "Montemezzo",
    longitude: 9.3769807,
    latitude: 46.17849115,
  },
  {
    label: "Montorfano",
    longitude: 9.14449546,
    latitude: 45.78540322,
  },
  {
    label: "Mozzate",
    longitude: 8.95594336,
    latitude: 45.67498965,
  },
  {
    label: "Musso",
    longitude: 9.27367815,
    latitude: 46.11081893,
  },
  {
    label: "Nesso",
    longitude: 9.15900161,
    latitude: 45.91072746,
  },
  {
    label: "Novedrate",
    longitude: 9.12348667,
    latitude: 45.69757654,
  },
  {
    label: "Olgiate Comasco",
    longitude: 8.96748481,
    latitude: 45.78582872,
  },
  {
    label: "Oltrona di San Mamette",
    longitude: 8.97597509,
    latitude: 45.75805508,
  },
  {
    label: "Orsenigo",
    longitude: 9.18506195,
    latitude: 45.77829952,
  },
  {
    label: "Peglio",
    longitude: 9.29479046,
    latitude: 46.16017984,
  },
  {
    label: "Pianello del Lario",
    longitude: 9.27647456,
    latitude: 46.10038478,
  },
  {
    label: "Pigra",
    longitude: 9.12646377,
    latitude: 45.95734691,
  },
  {
    label: "Plesio",
    longitude: 9.22786354,
    latitude: 46.04417749,
  },
  {
    label: "Pognana Lario",
    longitude: 9.15783719,
    latitude: 45.87739682,
  },
  {
    label: "Ponna",
    longitude: 9.09401197,
    latitude: 45.98963883,
  },
  {
    label: "Ponte Lambro",
    longitude: 9.22491745,
    latitude: 45.82699345,
  },
  {
    label: "Porlezza",
    longitude: 9.11981421,
    latitude: 46.03641282,
  },
  {
    label: "Proserpio",
    longitude: 9.24886541,
    latitude: 45.82765082,
  },
  {
    label: "Pusiano",
    longitude: 9.28214374,
    latitude: 45.8147783,
  },
  {
    label: "Rezzago",
    longitude: 9.2486021,
    latitude: 45.8667268,
  },
  {
    label: "Rodero",
    longitude: 8.91338826,
    latitude: 45.82263059,
  },
  {
    label: "Ronago",
    longitude: 8.98249961,
    latitude: 45.82976518,
  },
  {
    label: "Rovellasca",
    longitude: 9.0522964,
    latitude: 45.66794466,
  },
  {
    label: "Rovello Porro",
    longitude: 9.03981948,
    latitude: 45.65439932,
  },
  {
    label: "Sala Comacina",
    longitude: 9.16599583,
    latitude: 45.9642622,
  },
  {
    label: "San Bartolomeo Val Cavargna",
    longitude: 9.14622273,
    latitude: 46.08250497,
  },
  {
    label: "San Fedele Intelvi",
    longitude: 9.07651397,
    latitude: 45.96921287,
  },
  {
    label: "San Fermo della Battaglia",
    longitude: 9.0485,
    latitude: 45.8096,
  },
  {
    label: "San Nazzaro Val Cavargna",
    longitude: 9.13064701,
    latitude: 46.08922511,
  },
  {
    label: "Schignano",
    longitude: 9.10236459,
    latitude: 45.92790729,
  },
  {
    label: "Senna Comasco",
    longitude: 9.10732605,
    latitude: 45.76332515,
  },
  {
    label: "Solbiate",
    longitude: 8.93483499,
    latitude: 45.79234044,
  },
  {
    label: "Sorico",
    longitude: 9.37978706,
    latitude: 46.17212137,
  },
  {
    label: "Sormano",
    longitude: 9.24707675,
    latitude: 45.87943907,
  },
  {
    label: "Stazzona",
    longitude: 9.27725961,
    latitude: 46.13898602,
  },
  {
    label: "Tavernerio",
    longitude: 9.15042839,
    latitude: 45.79755247,
  },
  {
    label: "Torno",
    longitude: 9.11892139,
    latitude: 45.85871468,
  },
  {
    label: "Trezzone",
    longitude: 9.35533528,
    latitude: 46.17146264,
  },
  {
    label: "Turate",
    longitude: 9.001214,
    latitude: 45.65506841,
  },
  {
    label: "Uggiate-Trevano",
    longitude: 8.96174753,
    latitude: 45.82330253,
  },
  {
    label: "Valbrona",
    longitude: 9.29677259,
    latitude: 45.87778782,
  },
  {
    label: "Valmorea",
    longitude: 8.93063084,
    latitude: 45.81328901,
  },
  {
    label: "Val Rezzo",
    longitude: 9.11062175,
    latitude: 46.07228986,
  },
  {
    label: "Valsolda",
    longitude: 9.05613267,
    latitude: 46.02446056,
  },
  {
    label: "Veleso",
    longitude: 9.1808764,
    latitude: 45.90874583,
  },
  {
    label: "Veniano",
    longitude: 8.98498564,
    latitude: 45.71628916,
  },
  {
    label: "Vercana",
    longitude: 9.33043302,
    latitude: 46.160015,
  },
  {
    label: "Vertemate con Minoprio",
    longitude: 9.0721673,
    latitude: 45.7251672,
  },
  {
    label: "Villa Guardia",
    longitude: 9.02591509,
    latitude: 45.77472871,
  },
  {
    label: "Zelbio",
    longitude: 9.18089193,
    latitude: 45.90503059,
  },
  {
    label: "San Siro",
    longitude: 9.25851049,
    latitude: 46.0552164,
  },
  {
    label: "Gravedona ed Uniti",
    longitude: 9.30622961,
    latitude: 46.1481075,
  },
  {
    label: "Bellagio",
    longitude: 9.2459,
    latitude: 45.9627,
  },
  {
    label: "Colverde",
    longitude: 9.00494,
    latitude: 45.8105,
  },
  {
    label: "Tremezzina",
    longitude: 9.2135,
    latitude: 45.98231,
  },
  {
    label: "Alta Valle Intelvi",
    longitude: 9.0271,
    latitude: 45.9848,
  },
  {
    label: "Albaredo per San Marco",
    longitude: 9.59043893,
    latitude: 46.10292543,
  },
  {
    label: "Albosaggia",
    longitude: 9.85465398,
    latitude: 46.147398,
  },
  {
    label: "Andalo Valtellino",
    longitude: 9.47301339,
    latitude: 46.13447907,
  },
  {
    label: "Aprica",
    longitude: 10.15206343,
    latitude: 46.15334799,
  },
  {
    label: "Ardenno",
    longitude: 9.65172236,
    latitude: 46.17276047,
  },
  {
    label: "Bema",
    longitude: 9.56456455,
    latitude: 46.10831825,
  },
  {
    label: "Berbenno di Valtellina",
    longitude: 9.74318959,
    latitude: 46.16872282,
  },
  {
    label: "Bianzone",
    longitude: 10.10930218,
    latitude: 46.18393421,
  },
  {
    label: "Bormio",
    longitude: 10.37559509,
    latitude: 46.47015993,
  },
  {
    label: "Buglio in Monte",
    longitude: 9.67539142,
    latitude: 46.18335411,
  },
  {
    label: "Caiolo",
    longitude: 9.81705673,
    latitude: 46.15231836,
  },
  {
    label: "Campodolcino",
    longitude: 9.35431096,
    latitude: 46.40213345,
  },
  {
    label: "Caspoggio",
    longitude: 9.86176989,
    latitude: 46.26472617,
  },
  {
    label: "Castello dell'Acqua",
    longitude: 10.01578984,
    latitude: 46.14548538,
  },
  {
    label: "Castione Andevenno",
    longitude: 9.80029723,
    latitude: 46.1728621,
  },
  {
    label: "Cedrasco",
    longitude: 9.7680041,
    latitude: 46.14931462,
  },
  {
    label: "Cercino",
    longitude: 9.50818301,
    latitude: 46.15777754,
  },
  {
    label: "Chiavenna",
    longitude: 9.40548756,
    latitude: 46.32102771,
  },
  {
    label: "Chiesa in Valmalenco",
    longitude: 9.84832637,
    latitude: 46.26376909,
  },
  {
    label: "Chiuro",
    longitude: 9.98921231,
    latitude: 46.16955671,
  },
  {
    label: "Cino",
    longitude: 9.48531084,
    latitude: 46.15842853,
  },
  {
    label: "Civo",
    longitude: 9.58121409,
    latitude: 46.15634566,
  },
  {
    label: "Colorina",
    longitude: 9.7334632,
    latitude: 46.15184527,
  },
  {
    label: "Cosio Valtellino",
    longitude: 9.54986826,
    latitude: 46.13038313,
  },
  {
    label: "Dazio",
    longitude: 9.60020195,
    latitude: 46.16072439,
  },
  {
    label: "Delebio",
    longitude: 9.45925496,
    latitude: 46.1371564,
  },
  {
    label: "Dubino",
    longitude: 9.46088113,
    latitude: 46.15299771,
  },
  {
    label: "Faedo Valtellino",
    longitude: 9.90632697,
    latitude: 46.15308224,
  },
  {
    label: "Forcola",
    longitude: 9.66106927,
    latitude: 46.15823034,
  },
  {
    label: "Fusine",
    longitude: 9.75010748,
    latitude: 46.14946685,
  },
  {
    label: "Gerola Alta",
    longitude: 9.55022548,
    latitude: 46.06002756,
  },
  {
    label: "Gordona",
    longitude: 9.36858614,
    latitude: 46.2916854,
  },
  {
    label: "Grosio",
    longitude: 10.27571813,
    latitude: 46.29730226,
  },
  {
    label: "Grosotto",
    longitude: 10.2571315,
    latitude: 46.27878002,
  },
  {
    label: "Madesimo",
    longitude: 9.3441755,
    latitude: 46.42815069,
  },
  {
    label: "Lanzada",
    longitude: 9.87391438,
    latitude: 46.2693901,
  },
  {
    label: "Livigno",
    longitude: 10.13481042,
    latitude: 46.53867334,
  },
  {
    label: "Lovero",
    longitude: 10.23029598,
    latitude: 46.23084025,
  },
  {
    label: "Mantello",
    longitude: 9.48804765,
    latitude: 46.15290306,
  },
  {
    label: "Mazzo di Valtellina",
    longitude: 10.25480299,
    latitude: 46.25648281,
  },
  {
    label: "Mello",
    longitude: 9.54426446,
    latitude: 46.15462874,
  },
  {
    label: "Mese",
    longitude: 9.37945672,
    latitude: 46.30445337,
  },
  {
    label: "Montagna in Valtellina",
    longitude: 9.90064979,
    latitude: 46.17824643,
  },
  {
    label: "Morbegno",
    longitude: 9.56978082,
    latitude: 46.13045582,
  },
  {
    label: "Novate Mezzola",
    longitude: 9.4468224,
    latitude: 46.22198251,
  },
  {
    label: "Pedesina",
    longitude: 9.54952524,
    latitude: 46.08222157,
  },
  {
    label: "Piantedo",
    longitude: 9.43150447,
    latitude: 46.13392594,
  },
  {
    label: "Piateda",
    longitude: 9.93888683,
    latitude: 46.15975234,
  },
  {
    label: "Piuro",
    longitude: 9.4208521,
    latitude: 46.32946842,
  },
  {
    label: "Poggiridenti",
    longitude: 9.92865687,
    latitude: 46.17586738,
  },
  {
    label: "Ponte in Valtellina",
    longitude: 9.97748966,
    latitude: 46.17438989,
  },
  {
    label: "Postalesio",
    longitude: 9.77656146,
    latitude: 46.17378415,
  },
  {
    label: "Prata Camportaccio",
    longitude: 9.39499831,
    latitude: 46.30639156,
  },
  {
    label: "Rasura",
    longitude: 9.55292206,
    latitude: 46.10074327,
  },
  {
    label: "Rogolo",
    longitude: 9.48792786,
    latitude: 46.13629947,
  },
  {
    label: "Samolaco",
    longitude: 9.39512316,
    latitude: 46.24421886,
  },
  {
    label: "San Giacomo Filippo",
    longitude: 9.37042769,
    latitude: 46.33714147,
  },
  {
    label: "Sernio",
    longitude: 10.20332533,
    latitude: 46.22510846,
  },
  {
    label: "Sondalo",
    longitude: 10.32367779,
    latitude: 46.33202816,
  },
  {
    label: "Sondrio",
    longitude: 9.87147489,
    latitude: 46.1709926,
  },
  {
    label: "Spriana",
    longitude: 9.86446932,
    latitude: 46.22014856,
  },
  {
    label: "Talamona",
    longitude: 9.60947261,
    latitude: 46.13751073,
  },
  {
    label: "Tartano",
    longitude: 9.67931191,
    latitude: 46.10531855,
  },
  {
    label: "Teglio",
    longitude: 10.06328021,
    latitude: 46.17226169,
  },
  {
    label: "Tirano",
    longitude: 10.175315,
    latitude: 46.21687062,
  },
  {
    label: "Torre di Santa Maria",
    longitude: 9.8520957,
    latitude: 46.23261617,
  },
  {
    label: "Tovo di Sant'Agata",
    longitude: 10.24667088,
    latitude: 46.24504363,
  },
  {
    label: "Traona",
    longitude: 9.52678241,
    latitude: 46.14687842,
  },
  {
    label: "Tresivio",
    longitude: 9.94538135,
    latitude: 46.17469556,
  },
  {
    label: "Valdidentro",
    longitude: 10.29680566,
    latitude: 46.48933294,
  },
  {
    label: "Valdisotto",
    longitude: 10.35511049,
    latitude: 46.43167153,
  },
  {
    label: "Valfurva",
    longitude: 10.41086041,
    latitude: 46.46307994,
  },
  {
    label: "Val Masino",
    longitude: 9.636636,
    latitude: 46.21108357,
  },
  {
    label: "Verceia",
    longitude: 9.45276066,
    latitude: 46.19663273,
  },
  {
    label: "Vervio",
    longitude: 10.24053168,
    latitude: 46.25285098,
  },
  {
    label: "Villa di Chiavenna",
    longitude: 9.48896973,
    latitude: 46.33123766,
  },
  {
    label: "Villa di Tirano",
    longitude: 10.13320677,
    latitude: 46.20268316,
  },
  {
    label: "Abbiategrasso",
    longitude: 8.91748563,
    latitude: 45.39857615,
  },
  {
    label: "Albairate",
    longitude: 8.93517393,
    latitude: 45.41870614,
  },
  {
    label: "Arconate",
    longitude: 8.85151512,
    latitude: 45.54155555,
  },
  {
    label: "Arese",
    longitude: 9.0785758,
    latitude: 45.54987207,
  },
  {
    label: "Arluno",
    longitude: 8.95002987,
    latitude: 45.50899319,
  },
  {
    label: "Assago",
    longitude: 9.1311488,
    latitude: 45.40436068,
  },
  {
    label: "Bareggio",
    longitude: 8.99381888,
    latitude: 45.47645894,
  },
  {
    label: "Basiano",
    longitude: 9.46820192,
    latitude: 45.57442098,
  },
  {
    label: "Basiglio",
    longitude: 9.1614742,
    latitude: 45.36271707,
  },
  {
    label: "Bellinzago Lombardo",
    longitude: 9.44597053,
    latitude: 45.54083028,
  },
  {
    label: "Bernate Ticino",
    longitude: 8.81592589,
    latitude: 45.47932974,
  },
  {
    label: "Besate",
    longitude: 8.96731763,
    latitude: 45.31224654,
  },
  {
    label: "Binasco",
    longitude: 9.09965859,
    latitude: 45.33219808,
  },
  {
    label: "Boffalora sopra Ticino",
    longitude: 8.82849071,
    latitude: 45.46435927,
  },
  {
    label: "Bollatitudee",
    longitude: 9.11765508,
    latitude: 45.54384321,
  },
  {
    label: "Bresso",
    longitude: 9.18762977,
    latitude: 45.53760014,
  },
  {
    label: "Bubbiano",
    longitude: 9.01336985,
    latitude: 45.32732507,
  },
  {
    label: "Buccinasco",
    longitude: 9.10757505,
    latitude: 45.42126896,
  },
  {
    label: "Buscate",
    longitude: 8.81258989,
    latitude: 45.54600142,
  },
  {
    label: "Bussero",
    longitude: 9.37205136,
    latitude: 45.53791825,
  },
  {
    label: "Busto Garolfo",
    longitude: 8.88340873,
    latitude: 45.54605784,
  },
  {
    label: "Calvignasco",
    longitude: 9.02086669,
    latitude: 45.33179509,
  },
  {
    label: "Cambiago",
    longitude: 9.42416597,
    latitude: 45.57336649,
  },
  {
    label: "Canegrate",
    longitude: 8.92457328,
    latitude: 45.57254954,
  },
  {
    label: "Carpiano",
    longitude: 9.27463974,
    latitude: 45.34164161,
  },
  {
    label: "Carugate",
    longitude: 9.33843416,
    latitude: 45.54960324,
  },
  {
    label: "Casarile",
    longitude: 9.10320454,
    latitude: 45.31759199,
  },
  {
    label: "Casorezzo",
    longitude: 8.8990494,
    latitude: 45.524201,
  },
  {
    label: "Cassano d'Adda",
    longitude: 9.52054616,
    latitude: 45.52652047,
  },
  {
    label: "Cassina de' Pecchi",
    longitude: 9.36445401,
    latitude: 45.51996566,
  },
  {
    label: "Cassinetta di Lugagnano",
    longitude: 8.9068009,
    latitude: 45.42212844,
  },
  {
    label: "Castano Primo",
    longitude: 8.77635835,
    latitude: 45.55121165,
  },
  {
    label: "Cernusco sul Naviglio",
    longitude: 9.33502744,
    latitude: 45.52420326,
  },
  {
    label: "Cerro al Lambro",
    longitude: 9.33846435,
    latitude: 45.33157234,
  },
  {
    label: "Cerro Maggiore",
    longitude: 8.9507118,
    latitude: 45.59344478,
  },
  {
    label: "Cesano Boscone",
    longitude: 9.09327345,
    latitude: 45.44593779,
  },
  {
    label: "Cesate",
    longitude: 9.07544168,
    latitude: 45.59500977,
  },
  {
    label: "Cinisello Balsamo",
    longitude: 9.21357368,
    latitude: 45.55836354,
  },
  {
    label: "Cisliano",
    longitude: 8.9860794,
    latitude: 45.44604503,
  },
  {
    label: "Cologno Monzese",
    longitude: 9.27516655,
    latitude: 45.52979668,
  },
  {
    label: "Colturano",
    longitude: 9.34922924,
    latitude: 45.38189911,
  },
  {
    label: "Corbetta",
    longitude: 8.92189794,
    latitude: 45.46753305,
  },
  {
    label: "Cormano",
    longitude: 9.17254222,
    latitude: 45.54301244,
  },
  {
    label: "Cornaredo",
    longitude: 9.02583153,
    latitude: 45.50196836,
  },
  {
    label: "Corsico",
    longitude: 9.10863096,
    latitude: 45.43184871,
  },
  {
    label: "Cuggiono",
    longitude: 8.82157381,
    latitude: 45.50692962,
  },
  {
    label: "Cusago",
    longitude: 9.03350632,
    latitude: 45.44666694,
  },
  {
    label: "Cusano Milanino",
    longitude: 9.18157036,
    latitude: 45.55073318,
  },
  {
    label: "Dairago",
    longitude: 8.86615879,
    latitude: 45.56810463,
  },
  {
    label: "Dresano",
    longitude: 9.36121873,
    latitude: 45.37386953,
  },
  {
    label: "Gaggiano",
    longitude: 9.03451722,
    latitude: 45.40571729,
  },
  {
    label: "Garbagnate Milanese",
    longitude: 9.07748677,
    latitude: 45.57560674,
  },
  {
    label: "Gessate",
    longitude: 9.43675493,
    latitude: 45.55548598,
  },
  {
    label: "Gorgonzola",
    longitude: 9.40541159,
    latitude: 45.53026195,
  },
  {
    label: "Grezzago",
    longitude: 9.49442413,
    latitude: 45.59197707,
  },
  {
    label: "Gudo Visconti",
    longitude: 8.99924951,
    latitude: 45.37320485,
  },
  {
    label: "Inveruno",
    longitude: 8.85360485,
    latitude: 45.51294461,
  },
  {
    label: "Inzago",
    longitude: 9.48224972,
    latitude: 45.54114755,
  },
  {
    label: "Lacchiarella",
    longitude: 9.13737385,
    latitude: 45.32117579,
  },
  {
    label: "Lainate",
    longitude: 9.02714484,
    latitude: 45.57145592,
  },
  {
    label: "Legnano",
    longitude: 8.91854994,
    latitude: 45.59413716,
  },
  {
    label: "Liscate",
    longitude: 9.40946947,
    latitude: 45.48132241,
  },
  {
    label: "Locate di Triulzi",
    longitude: 9.22117365,
    latitude: 45.35766067,
  },
  {
    label: "Magenta",
    longitude: 8.8834256,
    latitude: 45.46610604,
  },
  {
    label: "Magnago",
    longitude: 8.80052532,
    latitude: 45.58059715,
  },
  {
    label: "Marcallo con Casone",
    longitude: 8.86750142,
    latitude: 45.48437101,
  },
  {
    label: "Masate",
    longitude: 9.46650825,
    latitude: 45.56842201,
  },
  {
    label: "Mediglia",
    longitude: 9.32377394,
    latitude: 45.40122623,
  },
  {
    label: "Melegnano",
    longitude: 9.32425217,
    latitude: 45.35753074,
  },
  {
    label: "Melzo",
    longitude: 9.4239803,
    latitude: 45.49793248,
  },
  {
    label: "Mesero",
    longitude: 8.85387846,
    latitude: 45.49826845,
  },
  {
    label: "Milano",
    longitude: 9.1903474,
    latitude: 45.46679408,
  },
  {
    label: "Morimondo",
    longitude: 8.95607819,
    latitude: 45.35379301,
  },
  {
    label: "Motta Visconti",
    longitude: 8.99299798,
    latitude: 45.29019224,
  },
  {
    label: "Nerviano",
    longitude: 8.97084999,
    latitude: 45.55398759,
  },
  {
    label: "Nosate",
    longitude: 8.72646999,
    latitude: 45.55078138,
  },
  {
    label: "Novate Milanese",
    longitude: 9.13588075,
    latitude: 45.53282247,
  },
  {
    label: "Noviglio",
    longitude: 9.07078309,
    latitude: 45.35609013,
  },
  {
    label: "Opera",
    longitude: 9.21145887,
    latitude: 45.37791048,
  },
  {
    label: "Ossona",
    longitude: 8.89458275,
    latitude: 45.50398861,
  },
  {
    label: "Ozzero",
    longitude: 8.92292595,
    latitude: 45.36877272,
  },
  {
    label: "Paderno Dugnano",
    longitude: 9.16606767,
    latitude: 45.5685516,
  },
  {
    label: "Pantigliate",
    longitude: 9.35392876,
    latitude: 45.43969396,
  },
  {
    label: "Parabiago",
    longitude: 8.95238691,
    latitude: 45.55801413,
  },
  {
    label: "Paullo",
    longitude: 9.39939608,
    latitude: 45.41702165,
  },
  {
    label: "Pero",
    longitude: 9.08902213,
    latitude: 45.51080482,
  },
  {
    label: "Peschiera Borromeo",
    longitude: 9.31109409,
    latitude: 45.43176867,
  },
  {
    label: "Pessano con Bornago",
    longitude: 9.38762275,
    latitude: 45.55027632,
  },
  {
    label: "Pieve Emanuele",
    longitude: 9.19968696,
    latitude: 45.35117871,
  },
  {
    label: "Pioltello",
    longitude: 9.32813782,
    latitude: 45.49677508,
  },
  {
    label: "Pogliano Milanese",
    longitude: 8.99355069,
    latitude: 45.53725726,
  },
  {
    label: "Pozzo d'Adda",
    longitude: 9.49955002,
    latitude: 45.57394857,
  },
  {
    label: "Pozzuolo Martesana",
    longitude: 9.45199534,
    latitude: 45.51240233,
  },
  {
    label: "Pregnana Milanese",
    longitude: 9.01001946,
    latitude: 45.51743762,
  },
  {
    label: "Rescaldina",
    longitude: 8.94754597,
    latitude: 45.61654739,
  },
  {
    label: "Rho",
    longitude: 9.04021698,
    latitude: 45.52890587,
  },
  {
    label: "Robecchetto con Induno",
    longitude: 8.76291649,
    latitude: 45.53494485,
  },
  {
    label: "Robecco sul Naviglio",
    longitude: 8.8904062,
    latitude: 45.43756541,
  },
  {
    label: "Rodano",
    longitude: 9.35708365,
    latitude: 45.47318177,
  },
  {
    label: "Rosate",
    longitude: 9.01680511,
    latitude: 45.3483508,
  },
  {
    label: "Rozzano",
    longitude: 9.15292695,
    latitude: 45.38101963,
  },
  {
    label: "San Colombano al Lambro",
    longitude: 9.48925035,
    latitude: 45.18132946,
  },
  {
    label: "San Donato Milanese",
    longitude: 9.26614911,
    latitude: 45.4169621,
  },
  {
    label: "San Giorgio su Legnano",
    longitude: 8.9164757,
    latitude: 45.57653352,
  },
  {
    label: "San Giuliano Milanese",
    longitude: 9.28492451,
    latitude: 45.40022835,
  },
  {
    label: "Santo Stefano Ticino",
    longitude: 8.91935219,
    latitude: 45.48778115,
  },
  {
    label: "San Vittore Olona",
    longitude: 8.94382391,
    latitude: 45.58760398,
  },
  {
    label: "San Zenone al Lambro",
    longitude: 9.35836311,
    latitude: 45.32647936,
  },
  {
    label: "Sedriano",
    longitude: 8.97088206,
    latitude: 45.48755545,
  },
  {
    label: "Segrate",
    longitude: 9.29293241,
    latitude: 45.49082418,
  },
  {
    label: "Senago",
    longitude: 9.11743158,
    latitude: 45.57519908,
  },
  {
    label: "Sesto San Giovanni",
    longitude: 9.23651246,
    latitude: 45.53352962,
  },
  {
    label: "Settala",
    longitude: 9.38688134,
    latitude: 45.45468517,
  },
  {
    label: "Settimo Milanese",
    longitude: 9.05677434,
    latitude: 45.48074151,
  },
  {
    label: "Solaro",
    longitude: 9.0795197,
    latitude: 45.61677245,
  },
  {
    label: "Trezzano Rosa",
    longitude: 9.48954108,
    latitude: 45.58497981,
  },
  {
    label: "Trezzano sul Naviglio",
    longitude: 9.06913584,
    latitude: 45.41867651,
  },
  {
    label: "Trezzo sull'Adda",
    longitude: 9.51924401,
    latitude: 45.61015321,
  },
  {
    label: "Tribiano",
    longitude: 9.36826311,
    latitude: 45.40419814,
  },
  {
    label: "Truccazzano",
    longitude: 9.46626196,
    latitude: 45.4835711,
  },
  {
    label: "Turbigo",
    longitude: 8.73520842,
    latitude: 45.52847138,
  },
  {
    label: "Vanzago",
    longitude: 9.00065442,
    latitude: 45.52649482,
  },
  {
    label: "Vaprio d'Adda",
    longitude: 9.53346804,
    latitude: 45.57645829,
  },
  {
    label: "Vermezzo",
    longitude: 8.98057958,
    latitude: 45.39681572,
  },
  {
    label: "Vernate",
    longitude: 9.06042559,
    latitude: 45.31588074,
  },
  {
    label: "Vignate",
    longitude: 9.37703718,
    latitude: 45.49636828,
  },
  {
    label: "Vimodrone",
    longitude: 9.28709221,
    latitude: 45.51432793,
  },
  {
    label: "Vittuone",
    longitude: 8.9533035,
    latitude: 45.48777405,
  },
  {
    label: "Vizzolo Predabissi",
    longitude: 9.34657618,
    latitude: 45.35700041,
  },
  {
    label: "Zelo Surrigone",
    longitude: 8.98468488,
    latitude: 45.38689115,
  },
  {
    label: "Zibido San Giacomo",
    longitude: 9.10587711,
    latitude: 45.35966489,
  },
  {
    label: "Villa Cortese",
    longitude: 8.88568965,
    latitude: 45.5655004,
  },
  {
    label: "Vanzaghello",
    longitude: 8.78555599,
    latitude: 45.57775891,
  },
  {
    label: "Baranzate",
    longitude: 9.11386757,
    latitude: 45.52729576,
  },
  {
    label: "Adrara San Martino",
    longitude: 9.9482497,
    latitude: 45.69819924,
  },
  {
    label: "Adrara San Rocco",
    longitude: 9.95771458,
    latitude: 45.71217274,
  },
  {
    label: "Albano Sant'Alessandro",
    longitude: 9.7662362,
    latitude: 45.68645495,
  },
  {
    label: "Albino",
    longitude: 9.7969345,
    latitude: 45.76059116,
  },
  {
    label: "Almè",
    longitude: 9.6175669,
    latitude: 45.74006437,
  },
  {
    label: "Almenno San Bartolomeo",
    longitude: 9.57826907,
    latitude: 45.74829449,
  },
  {
    label: "Almenno San Salvatore",
    longitude: 9.58738459,
    latitude: 45.74973808,
  },
  {
    label: "Alzano Lombardo",
    longitude: 9.72580631,
    latitude: 45.72747077,
  },
  {
    label: "Ambivere",
    longitude: 9.54722545,
    latitude: 45.71859384,
  },
  {
    label: "Antegnate",
    longitude: 9.79245129,
    latitude: 45.48444261,
  },
  {
    label: "Arcene",
    longitude: 9.61367879,
    latitude: 45.57551324,
  },
  {
    label: "Ardesio",
    longitude: 9.929649,
    latitude: 45.93819796,
  },
  {
    label: "Arzago d'Adda",
    longitude: 9.56429523,
    latitude: 45.47970041,
  },
  {
    label: "Averara",
    longitude: 9.63115452,
    latitude: 45.9885508,
  },
  {
    label: "Aviatico",
    longitude: 9.76892181,
    latitude: 45.7928051,
  },
  {
    label: "Azzano San Paolo",
    longitude: 9.67632432,
    latitude: 45.66191569,
  },
  {
    label: "Azzone",
    longitude: 10.11281471,
    latitude: 45.97902681,
  },
  {
    label: "Bagnatica",
    longitude: 9.78137877,
    latitude: 45.66367408,
  },
  {
    label: "Barbata",
    longitude: 9.77623374,
    latitude: 45.47490603,
  },
  {
    label: "Bariano",
    longitude: 9.70701505,
    latitude: 45.51477813,
  },
  {
    label: "Barzana",
    longitude: 9.56703564,
    latitude: 45.73364503,
  },
  {
    label: "Bedulita",
    longitude: 9.55092365,
    latitude: 45.79064007,
  },
  {
    label: "Berbenno",
    longitude: 9.56832347,
    latitude: 45.81171214,
  },
  {
    label: "Bergamo",
    longitude: 9.66842453,
    latitude: 45.69441368,
  },
  {
    label: "Berzo San Fermo",
    longitude: 9.90029675,
    latitude: 45.71817,
  },
  {
    label: "Bianzano",
    longitude: 9.91778785,
    latitude: 45.7733787,
  },
  {
    label: "Blello",
    longitude: 9.56433704,
    latitude: 45.8369178,
  },
  {
    label: "Bolgare",
    longitude: 9.8202383,
    latitude: 45.63185923,
  },
  {
    label: "Boltiere",
    longitude: 9.57823378,
    latitude: 45.59977641,
  },
  {
    label: "Bonate Sopra",
    longitude: 9.55923638,
    latitude: 45.6830883,
  },
  {
    label: "Bonate Sotto",
    longitude: 9.56130516,
    latitude: 45.66636307,
  },
  {
    label: "Borgo di Terzo",
    longitude: 9.89141094,
    latitude: 45.72095531,
  },
  {
    label: "Bossico",
    longitude: 10.04486839,
    latitude: 45.82701072,
  },
  {
    label: "Bottanuco",
    longitude: 9.50359425,
    latitude: 45.64081122,
  },
  {
    label: "Bracca",
    longitude: 9.70781335,
    latitude: 45.82266222,
  },
  {
    label: "Branzi",
    longitude: 9.75868016,
    latitude: 46.00494814,
  },
  {
    label: "Brembate",
    longitude: 9.55516253,
    latitude: 45.60388463,
  },
  {
    label: "Brembate di Sopra",
    longitude: 9.57758711,
    latitude: 45.71627848,
  },
  {
    label: "Brignano Gera d'Adda",
    longitude: 9.64789634,
    latitude: 45.5445147,
  },
  {
    label: "Brumano",
    longitude: 9.50061272,
    latitude: 45.85462791,
  },
  {
    label: "Brusaporto",
    longitude: 9.76126081,
    latitude: 45.67082667,
  },
  {
    label: "Calcinate",
    longitude: 9.80155201,
    latitude: 45.61977118,
  },
  {
    label: "Calcio",
    longitude: 9.85508435,
    latitude: 45.50766222,
  },
  {
    label: "Calusco d'Adda",
    longitude: 9.47138472,
    latitude: 45.68834716,
  },
  {
    label: "Calvenzano",
    longitude: 9.59815751,
    latitude: 45.49463312,
  },
  {
    label: "Camerata Cornello",
    longitude: 9.65566453,
    latitude: 45.89956035,
  },
  {
    label: "Canonica d'Adda",
    longitude: 9.54175897,
    latitude: 45.57456366,
  },
  {
    label: "Capizzone",
    longitude: 9.56852652,
    latitude: 45.78452319,
  },
  {
    label: "Capriate San Gervasio",
    longitude: 9.52730102,
    latitude: 45.60916831,
  },
  {
    label: "Caprino Bergamasco",
    longitude: 9.48158177,
    latitude: 45.74646782,
  },
  {
    label: "Caravaggio",
    longitude: 9.64310613,
    latitude: 45.49713767,
  },
  {
    label: "Carobbio degli Angeli",
    longitude: 9.82862581,
    latitude: 45.67045623,
  },
  {
    label: "Carona",
    longitude: 9.78549341,
    latitude: 46.02183878,
  },
  {
    label: "Carvico",
    longitude: 9.47982813,
    latitude: 45.70685592,
  },
  {
    label: "Casazza",
    longitude: 9.90631523,
    latitude: 45.75151263,
  },
  {
    label: "Casirate d'Adda",
    longitude: 9.56659968,
    latitude: 45.49235035,
  },
  {
    label: "Casnigo",
    longitude: 9.86745342,
    latitude: 45.81634563,
  },
  {
    label: "Cassiglio",
    longitude: 9.61212713,
    latitude: 45.96685843,
  },
  {
    label: "Castelli Calepio",
    longitude: 9.90225371,
    latitude: 45.64152654,
  },
  {
    label: "Castel Rozzone",
    longitude: 9.62373758,
    latitude: 45.55543684,
  },
  {
    label: "Castione della Presolana",
    longitude: 10.03640944,
    latitude: 45.90774598,
  },
  {
    label: "Castro",
    longitude: 10.06877391,
    latitude: 45.80380375,
  },
  {
    label: "Cavernago",
    longitude: 9.76373349,
    latitude: 45.62380299,
  },
  {
    label: "Cazzano Sant'Andrea",
    longitude: 9.88223113,
    latitude: 45.81122174,
  },
  {
    label: "Cenate Sopra",
    longitude: 9.82417576,
    latitude: 45.71174379,
  },
  {
    label: "Cenate Sotto",
    longitude: 9.82395442,
    latitude: 45.69842732,
  },
  {
    label: "Cene",
    longitude: 9.82557194,
    latitude: 45.77943252,
  },
  {
    label: "Cerete",
    longitude: 9.99497475,
    latitude: 45.86768981,
  },
  {
    label: "Chignolo d'Isola",
    longitude: 9.52722734,
    latitude: 45.66512539,
  },
  {
    label: "Chiuduno",
    longitude: 9.85376247,
    latitude: 45.64690256,
  },
  {
    label: "Cisano Bergamasco",
    longitude: 9.47436152,
    latitude: 45.74238525,
  },
  {
    label: "Ciserano",
    longitude: 9.60072632,
    latitude: 45.58667048,
  },
  {
    label: "Cividate al Piano",
    longitude: 9.8297951,
    latitude: 45.55425697,
  },
  {
    label: "Clusone",
    longitude: 9.94697311,
    latitude: 45.89156953,
  },
  {
    label: "Colere",
    longitude: 10.08572191,
    latitude: 45.97390225,
  },
  {
    label: "Cologno al Serio",
    longitude: 9.70610192,
    latitude: 45.57940957,
  },
  {
    label: "Colzate",
    longitude: 9.85631135,
    latitude: 45.81595823,
  },
  {
    label: "Comun Nuovo",
    longitude: 9.66473652,
    latitude: 45.62325078,
  },
  {
    label: "Corna Imagna",
    longitude: 9.5444751,
    latitude: 45.83152482,
  },
  {
    label: "Cortenuova",
    longitude: 9.78579765,
    latitude: 45.5386253,
  },
  {
    label: "Costa di Mezzate",
    longitude: 9.79445546,
    latitude: 45.66292809,
  },
  {
    label: "Costa Valle Imagna",
    longitude: 9.5056612,
    latitude: 45.80079491,
  },
  {
    label: "Costa Volpino",
    longitude: 10.09399319,
    latitude: 45.82794219,
  },
  {
    label: "Covo",
    longitude: 9.76854314,
    latitude: 45.50113193,
  },
  {
    label: "Credaro",
    longitude: 9.92901266,
    latitude: 45.65844869,
  },
  {
    label: "Curno",
    longitude: 9.61132252,
    latitude: 45.69154232,
  },
  {
    label: "Cusio",
    longitude: 9.6034789,
    latitude: 45.98943086,
  },
  {
    label: "Dalmine",
    longitude: 9.60117345,
    latitude: 45.64796557,
  },
  {
    label: "Dossena",
    longitude: 9.69696495,
    latitude: 45.87917249,
  },
  {
    label: "Endine Gaiano",
    longitude: 9.9764931,
    latitude: 45.79018606,
  },
  {
    label: "Entratico",
    longitude: 9.87476548,
    latitude: 45.70548346,
  },
  {
    label: "Fara Gera d'Adda",
    longitude: 9.53074769,
    latitude: 45.55086391,
  },
  {
    label: "Fara Olivana con Sola",
    longitude: 9.74973966,
    latitude: 45.49516206,
  },
  {
    label: "Filago",
    longitude: 9.55385295,
    latitude: 45.6362699,
  },
  {
    label: "Fino del Monte",
    longitude: 9.99215462,
    latitude: 45.8904533,
  },
  {
    label: "Fiorano al Serio",
    longitude: 9.84166374,
    latitude: 45.79948279,
  },
  {
    label: "Fontanella",
    longitude: 9.80044757,
    latitude: 45.47222286,
  },
  {
    label: "Fonteno",
    longitude: 10.01921554,
    latitude: 45.75786413,
  },
  {
    label: "Foppolo",
    longitude: 9.75497897,
    latitude: 46.04394127,
  },
  {
    label: "Foresto Sparso",
    longitude: 9.91367871,
    latitude: 45.69511225,
  },
  {
    label: "Fornovo San Giovanni",
    longitude: 9.67510261,
    latitude: 45.49614791,
  },
  {
    label: "Fuipiano Valle Imagna",
    longitude: 9.52497381,
    latitude: 45.85710204,
  },
  {
    label: "Gandellino",
    longitude: 9.94595319,
    latitude: 45.99065651,
  },
  {
    label: "Gandino",
    longitude: 9.90400497,
    latitude: 45.81128887,
  },
  {
    label: "Gandosso",
    longitude: 9.88989631,
    latitude: 45.66236043,
  },
  {
    label: "Gaverina Terme",
    longitude: 9.88615741,
    latitude: 45.75834161,
  },
  {
    label: "Gazzaniga",
    longitude: 9.83572599,
    latitude: 45.79743158,
  },
  {
    label: "Ghisalba",
    longitude: 9.75443504,
    latitude: 45.59161982,
  },
  {
    label: "Gorlago",
    longitude: 9.8291696,
    latitude: 45.67653217,
  },
  {
    label: "Gorle",
    longitude: 9.71877961,
    latitude: 45.70242869,
  },
  {
    label: "Gorno",
    longitude: 9.84340028,
    latitude: 45.86280091,
  },
  {
    label: "Grassobbio",
    longitude: 9.72770139,
    latitude: 45.65483964,
  },
  {
    label: "Gromo",
    longitude: 9.92990165,
    latitude: 45.97038555,
  },
  {
    label: "Grone",
    longitude: 9.90882163,
    latitude: 45.7272125,
  },
  {
    label: "Grumello del Monte",
    longitude: 9.86977119,
    latitude: 45.6340166,
  },
  {
    label: "Isola di Fondra",
    longitude: 9.7475071,
    latitude: 45.9807891,
  },
  {
    label: "Isso",
    longitude: 9.75897489,
    latitude: 45.476295,
  },
  {
    label: "Lallio",
    longitude: 9.6268812,
    latitude: 45.66731611,
  },
  {
    label: "Leffe",
    longitude: 9.88525625,
    latitude: 45.79979518,
  },
  {
    label: "Lenna",
    longitude: 9.67730257,
    latitude: 45.94318433,
  },
  {
    label: "Levate",
    longitude: 9.62447164,
    latitude: 45.62522363,
  },
  {
    label: "Locatello",
    longitude: 9.53254936,
    latitude: 45.83222039,
  },
  {
    label: "Lovere",
    longitude: 10.07160122,
    latitude: 45.81288555,
  },
  {
    label: "Lurano",
    longitude: 9.63944209,
    latitude: 45.5650278,
  },
  {
    label: "Luzzana",
    longitude: 9.87986502,
    latitude: 45.71597847,
  },
  {
    label: "Madone",
    longitude: 9.54994881,
    latitude: 45.65199573,
  },
  {
    label: "Mapello",
    longitude: 9.54573257,
    latitude: 45.70939829,
  },
  {
    label: "Martinengo",
    longitude: 9.76736896,
    latitude: 45.57167298,
  },
  {
    label: "Mezzoldo",
    longitude: 9.66566839,
    latitude: 46.01220471,
  },
  {
    label: "Misano di Gera d'Adda",
    longitude: 9.62059954,
    latitude: 45.4685511,
  },
  {
    label: "Moio de' Calvi",
    longitude: 9.70058412,
    latitude: 45.95144174,
  },
  {
    label: "Monasterolo del Castello",
    longitude: 9.93135584,
    latitude: 45.76326118,
  },
  {
    label: "Montello",
    longitude: 9.8050649,
    latitude: 45.67091191,
  },
  {
    label: "Morengo",
    longitude: 9.7022097,
    latitude: 45.53200212,
  },
  {
    label: "Mornico al Serio",
    longitude: 9.80871213,
    latitude: 45.590549,
  },
  {
    label: "Mozzanica",
    longitude: 9.69375604,
    latitude: 45.47709603,
  },
  {
    label: "Mozzo",
    longitude: 9.60902504,
    latitude: 45.7001771,
  },
  {
    label: "Nembro",
    longitude: 9.75892641,
    latitude: 45.74353321,
  },
  {
    label: "Olmo al Brembo",
    longitude: 9.64893935,
    latitude: 45.96953727,
  },
  {
    label: "Oltre il Colle",
    longitude: 9.76684312,
    latitude: 45.89272446,
  },
  {
    label: "Oltressenda Alta",
    longitude: 9.94421623,
    latitude: 45.91448234,
  },
  {
    label: "Oneta",
    longitude: 9.81927675,
    latitude: 45.87142446,
  },
  {
    label: "Onore",
    longitude: 10.00997787,
    latitude: 45.89257892,
  },
  {
    label: "Orio al Serio",
    longitude: 9.69151337,
    latitude: 45.67573293,
  },
  {
    label: "Ornica",
    longitude: 9.57845482,
    latitude: 45.98891231,
  },
  {
    label: "Osio Sopra",
    longitude: 9.5919245,
    latitude: 45.62977209,
  },
  {
    label: "Osio Sotto",
    longitude: 9.59270829,
    latitude: 45.61618219,
  },
  {
    label: "Pagazzano",
    longitude: 9.67331479,
    latitude: 45.53359411,
  },
  {
    label: "Paladina",
    longitude: 9.60637977,
    latitude: 45.72721062,
  },
  {
    label: "Palazzago",
    longitude: 9.53730572,
    latitude: 45.75122637,
  },
  {
    label: "Palosco",
    longitude: 9.83787414,
    latitude: 45.58347493,
  },
  {
    label: "Parre",
    longitude: 9.89189745,
    latitude: 45.87706854,
  },
  {
    label: "Parzanica",
    longitude: 10.03589843,
    latitude: 45.73811128,
  },
  {
    label: "Pedrengo",
    longitude: 9.7341355,
    latitude: 45.69842976,
  },
  {
    label: "Peia",
    longitude: 9.8970755,
    latitude: 45.79967397,
  },
  {
    label: "Pianico",
    longitude: 10.04353627,
    latitude: 45.81099519,
  },
  {
    label: "Piario",
    longitude: 9.92643911,
    latitude: 45.89596376,
  },
  {
    label: "Piazza Brembana",
    longitude: 9.67734981,
    latitude: 45.94631547,
  },
  {
    label: "Piazzatorre",
    longitude: 9.68557205,
    latitude: 45.99001243,
  },
  {
    label: "Piazzolo",
    longitude: 9.67228091,
    latitude: 45.97907415,
  },
  {
    label: "Pognano",
    longitude: 9.6419947,
    latitude: 45.58590063,
  },
  {
    label: "Ponte Nossa",
    longitude: 9.88739539,
    latitude: 45.86954276,
  },
  {
    label: "Ponteranica",
    longitude: 9.6547591,
    latitude: 45.73073708,
  },
  {
    label: "Ponte San Pietro",
    longitude: 9.5869042,
    latitude: 45.69898952,
  },
  {
    label: "Pontida",
    longitude: 9.50045312,
    latitude: 45.73147772,
  },
  {
    label: "Pontirolo Nuovo",
    longitude: 9.56780777,
    latitude: 45.5686725,
  },
  {
    label: "Pradalunga",
    longitude: 9.78432737,
    latitude: 45.74796639,
  },
  {
    label: "Predore",
    longitude: 10.01950385,
    latitude: 45.68066321,
  },
  {
    label: "Premolo",
    longitude: 9.8753348,
    latitude: 45.86859604,
  },
  {
    label: "Presezzo",
    longitude: 9.56670539,
    latitude: 45.69162228,
  },
  {
    label: "Pumenengo",
    longitude: 9.86772503,
    latitude: 45.48030174,
  },
  {
    label: "Ranica",
    longitude: 9.71460508,
    latitude: 45.72696442,
  },
  {
    label: "Ranzanico",
    longitude: 9.93612409,
    latitude: 45.78860457,
  },
  {
    label: "Riva di Solto",
    longitude: 10.03938351,
    latitude: 45.77417492,
  },
  {
    label: "Rogno",
    longitude: 10.13343949,
    latitude: 45.85782003,
  },
  {
    label: "Romano di Lombardia",
    longitude: 9.75316933,
    latitude: 45.51945126,
  },
  {
    label: "Roncobello",
    longitude: 9.74782375,
    latitude: 45.95643792,
  },
  {
    label: "Roncola",
    longitude: 9.56102894,
    latitude: 45.76925026,
  },
  {
    label: "Rota d'Imagna",
    longitude: 9.51152824,
    latitude: 45.83414786,
  },
  {
    label: "Rovetta",
    longitude: 9.98490261,
    latitude: 45.89111129,
  },
  {
    label: "San Giovanni Bianco",
    longitude: 9.65182322,
    latitude: 45.87456477,
  },
  {
    label: "San Paolo d'Argon",
    longitude: 9.80174843,
    latitude: 45.6876352,
  },
  {
    label: "San Pellegrino Terme",
    longitude: 9.66330535,
    latitude: 45.83833313,
  },
  {
    label: "Santa Brigida",
    longitude: 9.6234368,
    latitude: 45.9812844,
  },
  {
    label: "Sarnico",
    longitude: 9.95835303,
    latitude: 45.66799802,
  },
  {
    label: "Scanzorosciate",
    longitude: 9.73679641,
    latitude: 45.71186891,
  },
  {
    label: "Schilpario",
    longitude: 10.15451561,
    latitude: 46.00956474,
  },
  {
    label: "Sedrina",
    longitude: 9.62345823,
    latitude: 45.77991523,
  },
  {
    label: "Selvino",
    longitude: 9.75012725,
    latitude: 45.78074838,
  },
  {
    label: "Seriate",
    longitude: 9.71764216,
    latitude: 45.68353578,
  },
  {
    label: "Serina",
    longitude: 9.73009145,
    latitude: 45.87138372,
  },
  {
    label: "Solto Collina",
    longitude: 10.02592875,
    latitude: 45.78223969,
  },
  {
    label: "Songavazzo",
    longitude: 9.98981791,
    latitude: 45.88091617,
  },
  {
    label: "Sorisole",
    longitude: 9.66253698,
    latitude: 45.74474021,
  },
  {
    label: "Sotto il Monte Giovanni XXIII",
    longitude: 9.49887457,
    latitude: 45.70569781,
  },
  {
    label: "Sovere",
    longitude: 10.02552685,
    latitude: 45.82415622,
  },
  {
    label: "Spinone al Lago",
    longitude: 9.92154248,
    latitude: 45.76476001,
  },
  {
    label: "Spirano",
    longitude: 9.67098717,
    latitude: 45.58217246,
  },
  {
    label: "Stezzano",
    longitude: 9.65102483,
    latitude: 45.64975666,
  },
  {
    label: "Strozza",
    longitude: 9.57785981,
    latitude: 45.77424733,
  },
  {
    label: "Suisio",
    longitude: 9.50127414,
    latitude: 45.65978989,
  },
  {
    label: "Taleggio",
    longitude: 9.56480824,
    latitude: 45.8940301,
  },
  {
    label: "Tavernola Bergamasca",
    longitude: 10.04532871,
    latitude: 45.70922375,
  },
  {
    label: "Telgate",
    longitude: 9.85342403,
    latitude: 45.62729393,
  },
  {
    label: "Terno d'Isola",
    longitude: 9.53143321,
    latitude: 45.6835994,
  },
  {
    label: "Torre Boldone",
    longitude: 9.70773248,
    latitude: 45.71609034,
  },
  {
    label: "Torre de' Roveri",
    longitude: 9.77220696,
    latitude: 45.69951084,
  },
  {
    label: "Torre Pallavicina",
    longitude: 9.86262527,
    latitude: 45.44145792,
  },
  {
    label: "Trescore Balneario",
    longitude: 9.84277423,
    latitude: 45.69508834,
  },
  {
    label: "Treviglio",
    longitude: 9.5926499,
    latitude: 45.5217648,
  },
  {
    label: "Treviolo",
    longitude: 9.61127104,
    latitude: 45.67161825,
  },
  {
    label: "Ubiale Clanezzo",
    longitude: 9.61669209,
    latitude: 45.78339354,
  },
  {
    label: "Urgnano",
    longitude: 9.68917779,
    latitude: 45.59854143,
  },
  {
    label: "Valbondione",
    longitude: 10.01261961,
    latitude: 46.03798458,
  },
  {
    label: "Valbrembo",
    longitude: 9.60836917,
    latitude: 45.71643237,
  },
  {
    label: "Valgoglio",
    longitude: 9.91394219,
    latitude: 45.97559532,
  },
  {
    label: "Valleve",
    longitude: 9.74322887,
    latitude: 46.02819671,
  },
  {
    label: "Valnegra",
    longitude: 9.68946994,
    latitude: 45.94911785,
  },
  {
    label: "Valtorta",
    longitude: 9.53500677,
    latitude: 45.9775777,
  },
  {
    label: "Vedeseta",
    longitude: 9.54266151,
    latitude: 45.88945904,
  },
  {
    label: "Verdellino",
    longitude: 9.61534181,
    latitude: 45.60418483,
  },
  {
    label: "Verdello",
    longitude: 9.62702383,
    latitude: 45.60652189,
  },
  {
    label: "Vertova",
    longitude: 9.84987255,
    latitude: 45.80858625,
  },
  {
    label: "Viadanica",
    longitude: 9.96132435,
    latitude: 45.68552811,
  },
  {
    label: "Vigano San Martino",
    longitude: 9.89576413,
    latitude: 45.72609362,
  },
  {
    label: "Vigolo",
    longitude: 10.02631466,
    latitude: 45.71641986,
  },
  {
    label: "Villa d'Adda",
    longitude: 9.46461423,
    latitude: 45.71283271,
  },
  {
    label: "Villa d'Almè",
    longitude: 9.61792022,
    latitude: 45.7463448,
  },
  {
    label: "Villa di Serio",
    longitude: 9.73419702,
    latitude: 45.72011449,
  },
  {
    label: "Villa d'Ogna",
    longitude: 9.93053034,
    latitude: 45.90754175,
  },
  {
    label: "Villongo",
    longitude: 9.93475122,
    latitude: 45.67239404,
  },
  {
    label: "Vilminore di Scalve",
    longitude: 10.09528648,
    latitude: 45.99794954,
  },
  {
    label: "Zandobbio",
    longitude: 9.85241484,
    latitude: 45.68654224,
  },
  {
    label: "Zanica",
    longitude: 9.6809735,
    latitude: 45.6419557,
  },
  {
    label: "Zogno",
    longitude: 9.66791865,
    latitude: 45.7948216,
  },
  {
    label: "Costa Serina",
    longitude: 9.73922304,
    latitude: 45.83100576,
  },
  {
    label: "Algua",
    longitude: 9.72222924,
    latitude: 45.82583705,
  },
  {
    label: "Cornalba",
    longitude: 9.7446448,
    latitude: 45.85024428,
  },
  {
    label: "Medolago",
    longitude: 9.49354197,
    latitude: 45.67277395,
  },
  {
    label: "Solza",
    longitude: 9.49030904,
    latitude: 45.67777081,
  },
  {
    label: "Sant'Omobono Terme",
    longitude: 9.5187,
    latitude: 45.8168,
  },
  {
    label: "Val Brembilla",
    longitude: 9.59647,
    latitude: 45.8211,
  },
  {
    label: "Acquafredda",
    longitude: 10.41190041,
    latitude: 45.30716861,
  },
  {
    label: "Adro",
    longitude: 9.95535378,
    latitude: 45.62514272,
  },
  {
    label: "Agnosine",
    longitude: 10.3576843,
    latitude: 45.64771077,
  },
  {
    label: "Alfianello",
    longitude: 10.14824994,
    latitude: 45.26758605,
  },
  {
    label: "Anfo",
    longitude: 10.49379785,
    latitude: 45.76575,
  },
  {
    label: "Angolo Terme",
    longitude: 10.14687744,
    latitude: 45.89295916,
  },
  {
    label: "Artogne",
    longitude: 10.16452435,
    latitude: 45.84832151,
  },
  {
    label: "Azzano Mella",
    longitude: 10.11359436,
    latitude: 45.45482356,
  },
  {
    label: "Bagnolo Mella",
    longitude: 10.17928028,
    latitude: 45.42902132,
  },
  {
    label: "Bagolino",
    longitude: 10.46893818,
    latitude: 45.82122915,
  },
  {
    label: "Barbariga",
    longitude: 10.05674386,
    latitude: 45.40397218,
  },
  {
    label: "Barghe",
    longitude: 10.40612722,
    latitude: 45.67799548,
  },
  {
    label: "Bassano Bresciano",
    longitude: 10.12684596,
    latitude: 45.33019122,
  },
  {
    label: "Bedizzole",
    longitude: 10.42277837,
    latitude: 45.51035884,
  },
  {
    label: "Berlingo",
    longitude: 10.03295371,
    latitude: 45.50276977,
  },
  {
    label: "Berzo Demo",
    longitude: 10.33300826,
    latitude: 46.09287052,
  },
  {
    label: "Berzo Inferiore",
    longitude: 10.27867321,
    latitude: 45.93225933,
  },
  {
    label: "Bienno",
    longitude: 10.29546917,
    latitude: 45.93555453,
  },
  {
    label: "Bione",
    longitude: 10.34057147,
    latitude: 45.67402934,
  },
  {
    label: "Borgo San Giacomo",
    longitude: 9.97101776,
    latitude: 45.35011663,
  },
  {
    label: "Borgosatollo",
    longitude: 10.23603582,
    latitude: 45.47507645,
  },
  {
    label: "Borno",
    longitude: 10.20580785,
    latitude: 45.94679424,
  },
  {
    label: "Botticino",
    longitude: 10.32329909,
    latitude: 45.54329565,
  },
  {
    label: "Bovegno",
    longitude: 10.2722153,
    latitude: 45.79234745,
  },
  {
    label: "Bovezzo",
    longitude: 10.24349364,
    latitude: 45.59163275,
  },
  {
    label: "Brandico",
    longitude: 10.05092043,
    latitude: 45.45427209,
  },
  {
    label: "Braone",
    longitude: 10.34243041,
    latitude: 45.99021192,
  },
  {
    label: "Breno",
    longitude: 10.30204086,
    latitude: 45.95601285,
  },
  {
    label: "Brescia",
    longitude: 10.21910323,
    latitude: 45.53993052,
  },
  {
    label: "Brione",
    longitude: 10.14980761,
    latitude: 45.64071464,
  },
  {
    label: "Caino",
    longitude: 10.31575694,
    latitude: 45.61251414,
  },
  {
    label: "Calcinato",
    longitude: 10.41651764,
    latitude: 45.4574704,
  },
  {
    label: "Calvagese della Riviera",
    longitude: 10.44602336,
    latitude: 45.53921951,
  },
  {
    label: "Calvisano",
    longitude: 10.34287551,
    latitude: 45.34907813,
  },
  {
    label: "Capo di Ponte",
    longitude: 10.3428017,
    latitude: 46.02999867,
  },
  {
    label: "Capovalle",
    longitude: 10.5448427,
    latitude: 45.75406745,
  },
  {
    label: "Capriano del Colle",
    longitude: 10.12640959,
    latitude: 45.45516249,
  },
  {
    label: "Capriolo",
    longitude: 9.93602291,
    latitude: 45.63979785,
  },
  {
    label: "Carpenedolo",
    longitude: 10.4315258,
    latitude: 45.36118859,
  },
  {
    label: "Castegnato",
    longitude: 10.11683832,
    latitude: 45.5621169,
  },
  {
    label: "Castelcovati",
    longitude: 9.94319171,
    latitude: 45.5060743,
  },
  {
    label: "Castel Mella",
    longitude: 10.14188166,
    latitude: 45.49759872,
  },
  {
    label: "Castenedolo",
    longitude: 10.30043916,
    latitude: 45.47135901,
  },
  {
    label: "Casto",
    longitude: 10.32059748,
    latitude: 45.69508611,
  },
  {
    label: "Castrezzato",
    longitude: 9.97671546,
    latitude: 45.51108736,
  },
  {
    label: "Cazzago San Martino",
    longitude: 10.02501501,
    latitude: 45.58101331,
  },
  {
    label: "Cedegolo",
    longitude: 10.34999445,
    latitude: 46.07576226,
  },
  {
    label: "Cellatitudeica",
    longitude: 10.17777203,
    latitude: 45.58268839,
  },
  {
    label: "Cerveno",
    longitude: 10.32550765,
    latitude: 46.00231643,
  },
  {
    label: "Ceto",
    longitude: 10.3518498,
    latitude: 46.00235809,
  },
  {
    label: "Cevo",
    longitude: 10.37037619,
    latitude: 46.08019094,
  },
  {
    label: "Chiari",
    longitude: 9.93052864,
    latitude: 45.5368436,
  },
  {
    label: "Cigole",
    longitude: 10.19136828,
    latitude: 45.30993454,
  },
  {
    label: "Cimbergo",
    longitude: 10.36307614,
    latitude: 46.02385329,
  },
  {
    label: "Cividate Camuno",
    longitude: 10.27954747,
    latitude: 45.94348981,
  },
  {
    label: "Coccaglio",
    longitude: 9.97696104,
    latitude: 45.56238459,
  },
  {
    label: "Collebeato",
    longitude: 10.21388771,
    latitude: 45.58567193,
  },
  {
    label: "Collio",
    longitude: 10.33333286,
    latitude: 45.81116683,
  },
  {
    label: "Cologne",
    longitude: 9.94305337,
    latitude: 45.58560661,
  },
  {
    label: "Comezzano-Cizzago",
    longitude: 9.94904429,
    latitude: 45.47259447,
  },
  {
    label: "Concesio",
    longitude: 10.21846104,
    latitude: 45.60437212,
  },
  {
    label: "Corte Franca",
    longitude: 10.0069824,
    latitude: 45.63719867,
  },
  {
    label: "Corteno Golgi",
    longitude: 10.24224115,
    latitude: 46.16596898,
  },
  {
    label: "Corzano",
    longitude: 10.00779086,
    latitude: 45.4442463,
  },
  {
    label: "Darfo Boario Terme",
    longitude: 10.18048381,
    latitude: 45.88347362,
  },
  {
    label: "Dello",
    longitude: 10.07977147,
    latitude: 45.41654998,
  },
  {
    label: "Desenzano del Garda",
    longitude: 10.5415883,
    latitude: 45.46858257,
  },
  {
    label: "Edolo",
    longitude: 10.33711194,
    latitude: 46.1782314,
  },
  {
    label: "Erbusco",
    longitude: 9.97324124,
    latitude: 45.59951824,
  },
  {
    label: "Esine",
    longitude: 10.25917638,
    latitude: 45.92401953,
  },
  {
    label: "Fiesse",
    longitude: 10.32170366,
    latitude: 45.23334134,
  },
  {
    label: "Flero",
    longitude: 10.17589208,
    latitude: 45.48235605,
  },
  {
    label: "Gambara",
    longitude: 10.29778932,
    latitude: 45.25386029,
  },
  {
    label: "Gardone Riviera",
    longitude: 10.55799486,
    latitude: 45.62009093,
  },
  {
    label: "Gardone Val Trompia",
    longitude: 10.18222299,
    latitude: 45.68909413,
  },
  {
    label: "Gargnano",
    longitude: 10.65994462,
    latitude: 45.68981041,
  },
  {
    label: "Gavardo",
    longitude: 10.43467889,
    latitude: 45.58673467,
  },
  {
    label: "Ghedi",
    longitude: 10.27692536,
    latitude: 45.40219778,
  },
  {
    label: "Gianico",
    longitude: 10.1839733,
    latitude: 45.86531336,
  },
  {
    label: "Gottolengo",
    longitude: 10.27151767,
    latitude: 45.29173169,
  },
  {
    label: "Gussago",
    longitude: 10.15314832,
    latitude: 45.59242495,
  },
  {
    label: "Idro",
    longitude: 10.47401396,
    latitude: 45.73701964,
  },
  {
    label: "Incudine",
    longitude: 10.35819325,
    latitude: 46.22211483,
  },
  {
    label: "Irma",
    longitude: 10.28384537,
    latitude: 45.77119698,
  },
  {
    label: "Iseo",
    longitude: 10.05004398,
    latitude: 45.65982928,
  },
  {
    label: "Isorella",
    longitude: 10.31665753,
    latitude: 45.3075627,
  },
  {
    label: "Lavenone",
    longitude: 10.43796818,
    latitude: 45.73901274,
  },
  {
    label: "Leno",
    longitude: 10.21741867,
    latitude: 45.36749972,
  },
  {
    label: "Limone sul Garda",
    longitude: 10.79290209,
    latitude: 45.81333026,
  },
  {
    label: "Lodrino",
    longitude: 10.27760199,
    latitude: 45.71774346,
  },
  {
    label: "Lograto",
    longitude: 10.0574385,
    latitude: 45.48318568,
  },
  {
    label: "Lonato del Garda",
    longitude: 10.48429285,
    latitude: 45.46089172,
  },
  {
    label: "Longhena",
    longitude: 10.05965597,
    latitude: 45.43814089,
  },
  {
    label: "Losine",
    longitude: 10.31750867,
    latitude: 45.98358956,
  },
  {
    label: "Lozio",
    longitude: 10.26089085,
    latitude: 45.98563822,
  },
  {
    label: "Lumezzane",
    longitude: 10.261756,
    latitude: 45.65014976,
  },
  {
    label: "Maclodio",
    longitude: 10.04178291,
    latitude: 45.47674013,
  },
  {
    label: "Magasa",
    longitude: 10.6164034,
    latitude: 45.78147613,
  },
  {
    label: "Mairano",
    longitude: 10.07630769,
    latitude: 45.45020038,
  },
  {
    label: "Malegno",
    longitude: 10.2724111,
    latitude: 45.95070971,
  },
  {
    label: "Malonno",
    longitude: 10.31478286,
    latitude: 46.11866545,
  },
  {
    label: "Manerba del Garda",
    longitude: 10.55142577,
    latitude: 45.55009614,
  },
  {
    label: "Manerbio",
    longitude: 10.13902184,
    latitude: 45.35430213,
  },
  {
    label: "Marcheno",
    longitude: 10.21419546,
    latitude: 45.70599766,
  },
  {
    label: "Marmentino",
    longitude: 10.28194328,
    latitude: 45.75282746,
  },
  {
    label: "Marone",
    longitude: 10.09156211,
    latitude: 45.73709034,
  },
  {
    label: "Mazzano",
    longitude: 10.35979044,
    latitude: 45.50360347,
  },
  {
    label: "Milzano",
    longitude: 10.20043867,
    latitude: 45.27554151,
  },
  {
    label: "Moniga del Garda",
    longitude: 10.53704308,
    latitude: 45.53051675,
  },
  {
    label: "Monno",
    longitude: 10.33803738,
    latitude: 46.21261535,
  },
  {
    label: "Monte Isola",
    longitude: 10.07929693,
    latitude: 45.71498601,
  },
  {
    label: "Monticelli Brusati",
    longitude: 10.09789844,
    latitude: 45.63426021,
  },
  {
    label: "Montichiari",
    longitude: 10.39528281,
    latitude: 45.41224627,
  },
  {
    label: "Montirone",
    longitude: 10.23111054,
    latitude: 45.44377294,
  },
  {
    label: "Mura",
    longitude: 10.34201401,
    latitude: 45.71306707,
  },
  {
    label: "Muscoline",
    longitude: 10.46032704,
    latitude: 45.56438947,
  },
  {
    label: "Nave",
    longitude: 10.2790453,
    latitude: 45.58467547,
  },
  {
    label: "Niardo",
    longitude: 10.33589978,
    latitude: 45.97872723,
  },
  {
    label: "Nuvolento",
    longitude: 10.38327962,
    latitude: 45.54783467,
  },
  {
    label: "Nuvolera",
    longitude: 10.37015909,
    latitude: 45.53332556,
  },
  {
    label: "Odolo",
    longitude: 10.38824584,
    latitude: 45.64856079,
  },
  {
    label: "Offlaga",
    longitude: 10.11630783,
    latitude: 45.38652358,
  },
  {
    label: "Ome",
    longitude: 10.12416033,
    latitude: 45.62489528,
  },
  {
    label: "Ono San Pietro",
    longitude: 10.32924402,
    latitude: 46.01645153,
  },
  {
    label: "Orzinuovi",
    longitude: 9.92176686,
    latitude: 45.4024979,
  },
  {
    label: "Orzivecchi",
    longitude: 9.96045414,
    latitude: 45.42088848,
  },
  {
    label: "Ospitaletto",
    longitude: 10.07517023,
    latitude: 45.55686172,
  },
  {
    label: "Ossimo",
    longitude: 10.22847608,
    latitude: 45.94575301,
  },
  {
    label: "Padenghe sul Garda",
    longitude: 10.508363,
    latitude: 45.50945065,
  },
  {
    label: "Paderno Franciacorta",
    longitude: 10.07776175,
    latitude: 45.5904088,
  },
  {
    label: "Paisco Loveno",
    longitude: 10.29275715,
    latitude: 46.07887268,
  },
  {
    label: "Paitone",
    longitude: 10.39994355,
    latitude: 45.55300477,
  },
  {
    label: "Palazzolo sull'Oglio",
    longitude: 9.88582148,
    latitude: 45.59847763,
  },
  {
    label: "Paratico",
    longitude: 9.95761989,
    latitude: 45.66159516,
  },
  {
    label: "Paspardo",
    longitude: 10.37106349,
    latitude: 46.02986988,
  },
  {
    label: "Passirano",
    longitude: 10.06402728,
    latitude: 45.59815323,
  },
  {
    label: "Pavone del Mella",
    longitude: 10.20804853,
    latitude: 45.30332169,
  },
  {
    label: "San Paolo",
    longitude: 10.02462174,
    latitude: 45.37129663,
  },
  {
    label: "Pertica Alta",
    longitude: 10.34429218,
    latitude: 45.74264899,
  },
  {
    label: "Pertica Bassa",
    longitude: 10.37304046,
    latitude: 45.75333445,
  },
  {
    label: "Pezzaze",
    longitude: 10.23921621,
    latitude: 45.77493143,
  },
  {
    label: "Pian Camuno",
    longitude: 10.15748085,
    latitude: 45.8437174,
  },
  {
    label: "Pisogne",
    longitude: 10.10889801,
    latitude: 45.80480282,
  },
  {
    label: "Polaveno",
    longitude: 10.12424826,
    latitude: 45.66119599,
  },
  {
    label: "Polpenazze del Garda",
    longitude: 10.50463489,
    latitude: 45.5471873,
  },
  {
    label: "Pompiano",
    longitude: 9.9871549,
    latitude: 45.43278121,
  },
  {
    label: "Poncarale",
    longitude: 10.17396244,
    latitude: 45.46051196,
  },
  {
    label: "Ponte di Legno",
    longitude: 10.5114586,
    latitude: 46.25647207,
  },
  {
    label: "Pontevico",
    longitude: 10.08503009,
    latitude: 45.27359729,
  },
  {
    label: "Pontoglio",
    longitude: 9.85265228,
    latitude: 45.57062305,
  },
  {
    label: "Pozzolengo",
    longitude: 10.62709464,
    latitude: 45.40285678,
  },
  {
    label: "Pralboino",
    longitude: 10.21488169,
    latitude: 45.26809429,
  },
  {
    label: "Preseglie",
    longitude: 10.3929298,
    latitude: 45.66534492,
  },
  {
    label: "Prevalle",
    longitude: 10.42121638,
    latitude: 45.54705532,
  },
  {
    label: "Provaglio d'Iseo",
    longitude: 10.04214968,
    latitude: 45.63777633,
  },
  {
    label: "Provaglio Val Sabbia",
    longitude: 10.43392813,
    latitude: 45.68857672,
  },
  {
    label: "Puegnago sul Garda",
    longitude: 10.50991136,
    latitude: 45.57047242,
  },
  {
    label: "Quinzano d'Oglio",
    longitude: 10.00872258,
    latitude: 45.31368546,
  },
  {
    label: "Remedello",
    longitude: 10.37196398,
    latitude: 45.27889584,
  },
  {
    label: "Rezzato",
    longitude: 10.31849233,
    latitude: 45.51341274,
  },
  {
    label: "Roccafranca",
    longitude: 9.9119846,
    latitude: 45.46414262,
  },
  {
    label: "Rodengo Saiano",
    longitude: 10.10929589,
    latitude: 45.6005875,
  },
  {
    label: "Roè Volciano",
    longitude: 10.49404,
    latitude: 45.62400951,
  },
  {
    label: "Roncadelle",
    longitude: 10.1513087,
    latitude: 45.5280621,
  },
  {
    label: "Rovato",
    longitude: 9.99763789,
    latitude: 45.56808537,
  },
  {
    label: "Rudiano",
    longitude: 9.88423225,
    latitude: 45.4885185,
  },
  {
    label: "Sabbio Chiese",
    longitude: 10.41553263,
    latitude: 45.65676279,
  },
  {
    label: "Sale Marasino",
    longitude: 10.11288737,
    latitude: 45.71409232,
  },
  {
    label: "Salò",
    longitude: 10.5323114,
    latitude: 45.60713769,
  },
  {
    label: "San Felice del Benaco",
    longitude: 10.55377221,
    latitude: 45.58275139,
  },
  {
    label: "San Gervasio Bresciano",
    longitude: 10.14967036,
    latitude: 45.30601971,
  },
  {
    label: "San Zeno Naviglio",
    longitude: 10.21455357,
    latitude: 45.4913425,
  },
  {
    label: "Sarezzo",
    longitude: 10.20297691,
    latitude: 45.65378299,
  },
  {
    label: "Saviore dell'Adamello",
    longitude: 10.39820737,
    latitude: 46.08049071,
  },
  {
    label: "Sellero",
    longitude: 10.33811802,
    latitude: 46.06872235,
  },
  {
    label: "Seniga",
    longitude: 10.17716028,
    latitude: 45.24361392,
  },
  {
    label: "Serle",
    longitude: 10.36886737,
    latitude: 45.56174398,
  },
  {
    label: "Sirmione",
    longitude: 10.60946835,
    latitude: 45.46231487,
  },
  {
    label: "Soiano del Lago",
    longitude: 10.51363282,
    latitude: 45.53667141,
  },
  {
    label: "Sonico",
    longitude: 10.35211195,
    latitude: 46.16331325,
  },
  {
    label: "Sulzano",
    longitude: 10.10278206,
    latitude: 45.69073811,
  },
  {
    label: "Tavernole sul Mella",
    longitude: 10.24024832,
    latitude: 45.74807504,
  },
  {
    label: "Temù",
    longitude: 10.4704713,
    latitude: 46.24975113,
  },
  {
    label: "Tignale",
    longitude: 10.72185074,
    latitude: 45.73951579,
  },
  {
    label: "Torbole Casaglia",
    longitude: 10.10502624,
    latitude: 45.50830989,
  },
  {
    label: "Toscolano-Maderno",
    longitude: 10.60624725,
    latitude: 45.64167518,
  },
  {
    label: "Travagliato",
    longitude: 10.08021884,
    latitude: 45.52337617,
  },
  {
    label: "Tremosine sul Garda",
    longitude: 10.75381073,
    latitude: 45.76695652,
  },
  {
    label: "Trenzano",
    longitude: 10.01571144,
    latitude: 45.47595167,
  },
  {
    label: "Treviso Bresciano",
    longitude: 10.46199638,
    latitude: 45.71274204,
  },
  {
    label: "Urago d'Oglio",
    longitude: 9.87035354,
    latitude: 45.51333465,
  },
  {
    label: "Vallio Terme",
    longitude: 10.39578004,
    latitude: 45.60922643,
  },
  {
    label: "Valvestino",
    longitude: 10.59482345,
    latitude: 45.76184174,
  },
  {
    label: "Verolanuova",
    longitude: 10.07665283,
    latitude: 45.32577656,
  },
  {
    label: "Verolavecchia",
    longitude: 10.0518125,
    latitude: 45.33052185,
  },
  {
    label: "Vestone",
    longitude: 10.40369545,
    latitude: 45.70960367,
  },
  {
    label: "Vezza d'Oglio",
    longitude: 10.40008121,
    latitude: 46.24012026,
  },
  {
    label: "Villa Carcina",
    longitude: 10.19527204,
    latitude: 45.63141952,
  },
  {
    label: "Villachiara",
    longitude: 9.92960564,
    latitude: 45.35687315,
  },
  {
    label: "Villanuova sul Clisi",
    longitude: 10.4537092,
    latitude: 45.60166297,
  },
  {
    label: "Vione",
    longitude: 10.44779791,
    latitude: 46.24880802,
  },
  {
    label: "Visano",
    longitude: 10.36908371,
    latitude: 45.31626037,
  },
  {
    label: "Vobarno",
    longitude: 10.49655796,
    latitude: 45.64699577,
  },
  {
    label: "Zone",
    longitude: 10.11661357,
    latitude: 45.76219872,
  },
  {
    label: "Piancogno",
    longitude: 10.22051406,
    latitude: 45.91880417,
  },
  {
    label: "Alagna",
    longitude: 8.88945222,
    latitude: 45.16928391,
  },
  {
    label: "Albaredo Arnaboldi",
    longitude: 9.24350753,
    latitude: 45.10683265,
  },
  {
    label: "Albonese",
    longitude: 8.7061279,
    latitude: 45.2928504,
  },
  {
    label: "Albuzzano",
    longitude: 9.27717753,
    latitude: 45.18754704,
  },
  {
    label: "Arena Po",
    longitude: 9.36186971,
    latitude: 45.09443229,
  },
  {
    label: "Badia Pavese",
    longitude: 9.468549,
    latitude: 45.12117417,
  },
  {
    label: "Bagnaria",
    longitude: 9.12433895,
    latitude: 44.82607599,
  },
  {
    label: "Barbianello",
    longitude: 9.20599733,
    latitude: 45.07570319,
  },
  {
    label: "Bascapè",
    longitude: 9.31542149,
    latitude: 45.30464405,
  },
  {
    label: "Bastida Pancarana",
    longitude: 9.0915536,
    latitude: 45.08630345,
  },
  {
    label: "Battuda",
    longitude: 9.07740762,
    latitude: 45.27357591,
  },
  {
    label: "Belgioioso",
    longitude: 9.31602643,
    latitude: 45.16273674,
  },
  {
    label: "Bereguardo",
    longitude: 9.02449546,
    latitude: 45.25744652,
  },
  {
    label: "Borgarello",
    longitude: 9.14068907,
    latitude: 45.23942871,
  },
  {
    label: "Borgo Priolo",
    longitude: 9.14841048,
    latitude: 44.96650803,
  },
  {
    label: "Borgoratto Mormorolo",
    longitude: 9.19322114,
    latitude: 44.93011284,
  },
  {
    label: "Borgo San Siro",
    longitude: 8.91269284,
    latitude: 45.23483612,
  },
  {
    label: "Bornasco",
    longitude: 9.21835501,
    latitude: 45.26637718,
  },
  {
    label: "Bosnasco",
    longitude: 9.35689545,
    latitude: 45.0639987,
  },
  {
    label: "Brallo di Pregola",
    longitude: 9.28189064,
    latitude: 44.7384555,
  },
  {
    label: "Breme",
    longitude: 8.62332043,
    latitude: 45.12790235,
  },
  {
    label: "Bressana Bottarone",
    longitude: 9.1350806,
    latitude: 45.07364272,
  },
  {
    label: "Broni",
    longitude: 9.26129813,
    latitude: 45.06197007,
  },
  {
    label: "Calvignano",
    longitude: 9.16884328,
    latitude: 44.98267158,
  },
  {
    label: "Campospinoso",
    longitude: 9.24669485,
    latitude: 45.09360451,
  },
  {
    label: "Candia Lomellina",
    longitude: 8.59816452,
    latitude: 45.17473688,
  },
  {
    label: "Canevino",
    longitude: 9.2735732,
    latitude: 44.9447337,
  },
  {
    label: "Canneto Pavese",
    longitude: 9.28098021,
    latitude: 45.05115779,
  },
  {
    label: "Carbonara al Ticino",
    longitude: 9.05904974,
    latitude: 45.16531723,
  },
  {
    label: "Casanova Lonati",
    longitude: 9.21373599,
    latitude: 45.09441598,
  },
  {
    label: "Casatisma",
    longitude: 9.13096391,
    latitude: 45.04568877,
  },
  {
    label: "Casei Gerola",
    longitude: 8.92707305,
    latitude: 45.0063079,
  },
  {
    label: "Casorate Primo",
    longitude: 9.01813904,
    latitude: 45.3119243,
  },
  {
    label: "Cassolnovo",
    longitude: 8.80894596,
    latitude: 45.36582712,
  },
  {
    label: "Castana",
    longitude: 9.27189802,
    latitude: 45.02702606,
  },
  {
    label: "Casteggio",
    longitude: 9.12494869,
    latitude: 45.01239955,
  },
  {
    label: "Castelletto di Branduzzo",
    longitude: 9.09766637,
    latitude: 45.07018739,
  },
  {
    label: "Castello d'Agogna",
    longitude: 8.68937848,
    latitude: 45.23600468,
  },
  {
    label: "Castelnovetto",
    longitude: 8.61139904,
    latitude: 45.2543752,
  },
  {
    label: "Cava Manara",
    longitude: 9.10710573,
    latitude: 45.13887406,
  },
  {
    label: "Cecima",
    longitude: 9.08168596,
    latitude: 44.8478148,
  },
  {
    label: "Ceranova",
    longitude: 9.24226047,
    latitude: 45.26001191,
  },
  {
    label: "Ceretto Lomellina",
    longitude: 8.67241282,
    latitude: 45.24481961,
  },
  {
    label: "Cergnago",
    longitude: 8.76908632,
    latitude: 45.19717197,
  },
  {
    label: "Certosa di Pavia",
    longitude: 9.1274166,
    latitude: 45.25434477,
  },
  {
    label: "Cervesina",
    longitude: 9.01775898,
    latitude: 45.0611738,
  },
  {
    label: "Chignolo Po",
    longitude: 9.48820517,
    latitude: 45.15251471,
  },
  {
    label: "Cigognola",
    longitude: 9.24377127,
    latitude: 45.03309465,
  },
  {
    label: "Cilavegna",
    longitude: 8.74367717,
    latitude: 45.3104294,
  },
  {
    label: "Codevilla",
    longitude: 9.05549504,
    latitude: 44.96399925,
  },
  {
    label: "Confienza",
    longitude: 8.55760228,
    latitude: 45.33191517,
  },
  {
    label: "Copiano",
    longitude: 9.32090675,
    latitude: 45.19747789,
  },
  {
    label: "Corana",
    longitude: 8.96949055,
    latitude: 45.06288562,
  },
  {
    label: "Corvino San Quirico",
    longitude: 9.15892253,
    latitude: 45.01556343,
  },
  {
    label: "Costa de' Nobili",
    longitude: 9.37878994,
    latitude: 45.13242075,
  },
  {
    label: "Cozzo",
    longitude: 8.61109735,
    latitude: 45.19212476,
  },
  {
    label: "Cura Carpignano",
    longitude: 9.25707038,
    latitude: 45.21179419,
  },
  {
    label: "Dorno",
    longitude: 8.95195475,
    latitude: 45.15485373,
  },
  {
    label: "Ferrera Erbognone",
    longitude: 8.86376906,
    latitude: 45.11328526,
  },
  {
    label: "Filighera",
    longitude: 9.31407227,
    latitude: 45.17734084,
  },
  {
    label: "Fortunago",
    longitude: 9.18468553,
    latitude: 44.92159589,
  },
  {
    label: "Frascarolo",
    longitude: 8.68279849,
    latitude: 45.04690209,
  },
  {
    label: "Galliavola",
    longitude: 8.81862618,
    latitude: 45.09718401,
  },
  {
    label: "Gambarana",
    longitude: 8.76279748,
    latitude: 45.02873481,
  },
  {
    label: "Gambolò",
    longitude: 8.8615853,
    latitude: 45.24979779,
  },
  {
    label: "Garlasco",
    longitude: 8.92353757,
    latitude: 45.1963838,
  },
  {
    label: "Gerenzago",
    longitude: 9.36220485,
    latitude: 45.20355606,
  },
  {
    label: "Giussago",
    longitude: 9.13993742,
    latitude: 45.28429328,
  },
  {
    label: "Godiasco Salice Terme",
    longitude: 9.05797207,
    latitude: 44.89757219,
  },
  {
    label: "Golferenzo",
    longitude: 9.30696852,
    latitude: 44.96175776,
  },
  {
    label: "Gravellona Lomellina",
    longitude: 8.76399625,
    latitude: 45.3288489,
  },
  {
    label: "Gropello Cairoli",
    longitude: 8.99214762,
    latitude: 45.17717695,
  },
  {
    label: "Inverno e Monteleone",
    longitude: 9.38426433,
    latitude: 45.19858107,
  },
  {
    label: "Landriano",
    longitude: 9.25958712,
    latitude: 45.31128201,
  },
  {
    label: "Langosco",
    longitude: 8.56284388,
    latitude: 45.21533301,
  },
  {
    label: "Lardirago",
    longitude: 9.2343757,
    latitude: 45.23543708,
  },
  {
    label: "Linarolo",
    longitude: 9.2678855,
    latitude: 45.16021171,
  },
  {
    label: "Lirio",
    longitude: 9.25553586,
    latitude: 44.99401253,
  },
  {
    label: "Lomello",
    longitude: 8.79265237,
    latitude: 45.12176665,
  },
  {
    label: "Lungavilla",
    longitude: 9.0785878,
    latitude: 45.04490566,
  },
  {
    label: "Magherno",
    longitude: 9.32949555,
    latitude: 45.2224498,
  },
  {
    label: "Marcignago",
    longitude: 9.07736899,
    latitude: 45.25435216,
  },
  {
    label: "Marzano",
    longitude: 9.29457635,
    latitude: 45.247586,
  },
  {
    label: "Mede",
    longitude: 8.73661355,
    latitude: 45.09693827,
  },
  {
    label: "Menconico",
    longitude: 9.27906118,
    latitude: 44.79595014,
  },
  {
    label: "Mezzana Bigli",
    longitude: 8.84596057,
    latitude: 45.06088008,
  },
  {
    label: "Mezzana Rabattone",
    longitude: 9.03229261,
    latitude: 45.09470572,
  },
  {
    label: "Mezzanino",
    longitude: 9.20681323,
    latitude: 45.12638643,
  },
  {
    label: "Miradolo Terme",
    longitude: 9.44317001,
    latitude: 45.17163506,
  },
  {
    label: "Montalto Pavese",
    longitude: 9.20733549,
    latitude: 44.97961147,
  },
  {
    label: "Montebello della Battaglia",
    longitude: 9.10100735,
    latitude: 44.99970083,
  },
  {
    label: "Montecalvo Versiggia",
    longitude: 9.28398668,
    latitude: 44.97040409,
  },
  {
    label: "Montescano",
    longitude: 9.29733537,
    latitude: 45.03180352,
  },
  {
    label: "Montesegale",
    longitude: 9.12689667,
    latitude: 44.90637183,
  },
  {
    label: "Monticelli Pavese",
    longitude: 9.51274019,
    latitude: 45.11050666,
  },
  {
    label: "Montù Beccaria",
    longitude: 9.31241837,
    latitude: 45.03883483,
  },
  {
    label: "Mornico Losana",
    longitude: 9.20647093,
    latitude: 45.00921957,
  },
  {
    label: "Mortara",
    longitude: 8.73806527,
    latitude: 45.25183779,
  },
  {
    label: "Nicorvo",
    longitude: 8.66718852,
    latitude: 45.2851465,
  },
  {
    label: "Olevano di Lomellina",
    longitude: 8.71747483,
    latitude: 45.21321772,
  },
  {
    label: "Oliva Gessi",
    longitude: 9.17362666,
    latitude: 45.00533323,
  },
  {
    label: "Ottobiano",
    longitude: 8.8321528,
    latitude: 45.15127105,
  },
  {
    label: "Palestro",
    longitude: 8.53467742,
    latitude: 45.30584066,
  },
  {
    label: "Pancarana",
    longitude: 9.05106462,
    latitude: 45.07522925,
  },
  {
    label: "Parona",
    longitude: 8.74981845,
    latitude: 45.28261312,
  },
  {
    label: "Pavia",
    longitude: 9.16015719,
    latitude: 45.18509264,
  },
  {
    label: "Pietra de' Giorgi",
    longitude: 9.22976461,
    latitude: 45.0208258,
  },
  {
    label: "Pieve Albignola",
    longitude: 8.96031338,
    latitude: 45.11312662,
  },
  {
    label: "Pieve del Cairo",
    longitude: 8.80510102,
    latitude: 45.04897446,
  },
  {
    label: "Pieve Porto Morone",
    longitude: 9.43845739,
    latitude: 45.11250986,
  },
  {
    label: "Pinarolo Po",
    longitude: 9.1648143,
    latitude: 45.06602995,
  },
  {
    label: "Pizzale",
    longitude: 9.04731478,
    latitude: 45.03431401,
  },
  {
    label: "Ponte Nizza",
    longitude: 9.09737005,
    latitude: 44.85187405,
  },
  {
    label: "Portalbera",
    longitude: 9.32259546,
    latitude: 45.0994069,
  },
  {
    label: "Rea",
    longitude: 9.15434003,
    latitude: 45.11386906,
  },
  {
    label: "Redavalle",
    longitude: 9.20401766,
    latitude: 45.03773197,
  },
  {
    label: "Retorbido",
    longitude: 9.03634536,
    latitude: 44.94922806,
  },
  {
    label: "Rivanazzano Terme",
    longitude: 9.01644721,
    latitude: 44.93053102,
  },
  {
    label: "Robbio",
    longitude: 8.5939575,
    latitude: 45.28911604,
  },
  {
    label: "Robecco Pavese",
    longitude: 9.14865428,
    latitude: 45.04842105,
  },
  {
    label: "Rocca de' Giorgi",
    longitude: 9.25216656,
    latitude: 44.97158431,
  },
  {
    label: "Rocca Susella",
    longitude: 9.09596542,
    latitude: 44.91275863,
  },
  {
    label: "Rognano",
    longitude: 9.08992208,
    latitude: 45.28881563,
  },
  {
    label: "Romagnese",
    longitude: 9.3294808,
    latitude: 44.83722972,
  },
  {
    label: "Roncaro",
    longitude: 9.27692361,
    latitude: 45.22572761,
  },
  {
    label: "Rosasco",
    longitude: 8.57927243,
    latitude: 45.25030244,
  },
  {
    label: "Rovescala",
    longitude: 9.35012702,
    latitude: 45.01374627,
  },
  {
    label: "Ruino",
    longitude: 9.2734518,
    latitude: 44.92787354,
  },
  {
    label: "San Cipriano Po",
    longitude: 9.2808622,
    latitude: 45.10908756,
  },
  {
    label: "San Damiano al Colle",
    longitude: 9.34857225,
    latitude: 45.0271211,
  },
  {
    label: "San Genesio ed Uniti",
    longitude: 9.17987326,
    latitude: 45.232849,
  },
  {
    label: "San Giorgio di Lomellina",
    longitude: 8.79111791,
    latitude: 45.17380714,
  },
  {
    label: "San Martino Siccomario",
    longitude: 9.13451976,
    latitude: 45.15715963,
  },
  {
    label: "Sannazzaro de' Burgondi",
    longitude: 8.90828982,
    latitude: 45.10316308,
  },
  {
    label: "Santa Cristina e Bissone",
    longitude: 9.40113574,
    latitude: 45.15679448,
  },
  {
    label: "Santa Giuletta",
    longitude: 9.18159447,
    latitude: 45.03384034,
  },
  {
    label: "Sant'Alessio con Vialone",
    longitude: 9.22506259,
    latitude: 45.22209769,
  },
  {
    label: "Santa Margherita di Staffora",
    longitude: 9.24055817,
    latitude: 44.77079137,
  },
  {
    label: "Santa Maria della Versa",
    longitude: 9.29994477,
    latitude: 44.9846895,
  },
  {
    label: "Sant'Angelo Lomellina",
    longitude: 8.6431448,
    latitude: 45.2466213,
  },
  {
    label: "San Zenone al Po",
    longitude: 9.36114462,
    latitude: 45.10854539,
  },
  {
    label: "Sartirana Lomellina",
    longitude: 8.66185489,
    latitude: 45.11434048,
  },
  {
    label: "Scaldasole",
    longitude: 8.91019032,
    latitude: 45.12493797,
  },
  {
    label: "Semiana",
    longitude: 8.72970509,
    latitude: 45.13736987,
  },
  {
    label: "Silvano Pietra",
    longitude: 8.94817867,
    latitude: 45.03996573,
  },
  {
    label: "Siziano",
    longitude: 9.19872487,
    latitude: 45.32016711,
  },
  {
    label: "Sommo",
    longitude: 9.08377546,
    latitude: 45.13223484,
  },
  {
    label: "Spessa",
    longitude: 9.34922713,
    latitude: 45.11373851,
  },
  {
    label: "Stradella",
    longitude: 9.2951673,
    latitude: 45.07530591,
  },
  {
    label: "Suardi",
    longitude: 8.74304811,
    latitude: 45.02945257,
  },
  {
    label: "Torrazza Coste",
    longitude: 9.08644549,
    latitude: 44.97654992,
  },
  {
    label: "Torre Beretti e Castellaro",
    longitude: 8.67030548,
    latitude: 45.05996692,
  },
  {
    label: "Torre d'Arese",
    longitude: 9.31681664,
    latitude: 45.2424014,
  },
  {
    label: "Torre de' Negri",
    longitude: 9.33560207,
    latitude: 45.15066298,
  },
  {
    label: "Torre d'Isola",
    longitude: 9.07639687,
    latitude: 45.21746495,
  },
  {
    label: "Torrevecchia Pia",
    longitude: 9.29792661,
    latitude: 45.28286993,
  },
  {
    label: "Torricella Verzate",
    longitude: 9.17584206,
    latitude: 45.01559546,
  },
  {
    label: "Travacò Siccomario",
    longitude: 9.16151622,
    latitude: 45.15248202,
  },
  {
    label: "Trivolzio",
    longitude: 9.04708121,
    latitude: 45.26094339,
  },
  {
    label: "Tromello",
    longitude: 8.86959755,
    latitude: 45.21183831,
  },
  {
    label: "Trovo",
    longitude: 9.03576818,
    latitude: 45.28284959,
  },
  {
    label: "Val di Nizza",
    longitude: 9.16683303,
    latitude: 44.87933288,
  },
  {
    label: "Valeggio",
    longitude: 8.86129109,
    latitude: 45.15064328,
  },
  {
    label: "Valle Lomellina",
    longitude: 8.66495548,
    latitude: 45.14711708,
  },
  {
    label: "Valle Salimbene",
    longitude: 9.23298223,
    latitude: 45.16962743,
  },
  {
    label: "Valverde",
    longitude: 9.23333357,
    latitude: 44.86440068,
  },
  {
    label: "Varzi",
    longitude: 9.1962725,
    latitude: 44.82199953,
  },
  {
    label: "Velezzo Lomellina",
    longitude: 8.73721007,
    latitude: 45.16370704,
  },
  {
    label: "Vellezzo Bellini",
    longitude: 9.10213278,
    latitude: 45.26952835,
  },
  {
    label: "Verretto",
    longitude: 9.1076969,
    latitude: 45.04004064,
  },
  {
    label: "Verrua Po",
    longitude: 9.173167,
    latitude: 45.11132967,
  },
  {
    label: "Vidigulfo",
    longitude: 9.23483143,
    latitude: 45.29187437,
  },
  {
    label: "Vigevano",
    longitude: 8.85987557,
    latitude: 45.31761391,
  },
  {
    label: "Villa Biscossi",
    longitude: 8.78668897,
    latitude: 45.08995761,
  },
  {
    label: "Villanova d'Ardenghi",
    longitude: 9.04324484,
    latitude: 45.17133665,
  },
  {
    label: "Villanterio",
    longitude: 9.36041071,
    latitude: 45.22071855,
  },
  {
    label: "Vistarino",
    longitude: 9.30645859,
    latitude: 45.21147861,
  },
  {
    label: "Voghera",
    longitude: 9.00852362,
    latitude: 44.99312509,
  },
  {
    label: "Volpara",
    longitude: 9.29747619,
    latitude: 44.9537887,
  },
  {
    label: "Zavattarello",
    longitude: 9.2654367,
    latitude: 44.868745,
  },
  {
    label: "Zeccone",
    longitude: 9.19841152,
    latitude: 45.26093049,
  },
  {
    label: "Zeme",
    longitude: 8.6663117,
    latitude: 45.19492928,
  },
  {
    label: "Zenevredo",
    longitude: 9.32664307,
    latitude: 45.05390342,
  },
  {
    label: "Zerbo",
    longitude: 9.39545253,
    latitude: 45.11055284,
  },
  {
    label: "Zerbolò",
    longitude: 9.0143866,
    latitude: 45.20676064,
  },
  {
    label: "Zinasco",
    longitude: 9.02849669,
    latitude: 45.12824661,
  },
  {
    label: "Cornale e Bastida",
    longitude: 8.91089,
    latitude: 45.0439,
  },
  {
    label: "Corteolona e Genzone",
    longitude: 9.3704,
    latitude: 45.1588,
  },
  {
    label: "Acquanegra Cremonese",
    longitude: 9.88878548,
    latitude: 45.16831524,
  },
  {
    label: "Agnadello",
    longitude: 9.55920587,
    latitude: 45.44713529,
  },
  {
    label: "Annicco",
    longitude: 9.88185079,
    latitude: 45.24474357,
  },
  {
    label: "Azzanello",
    longitude: 9.91970439,
    latitude: 45.31311906,
  },
  {
    label: "Bagnolo Cremasco",
    longitude: 9.60916312,
    latitude: 45.36153682,
  },
  {
    label: "Bonemerse",
    longitude: 10.07836814,
    latitude: 45.11404791,
  },
  {
    label: "Bordolano",
    longitude: 9.98705144,
    latitude: 45.29285106,
  },
  {
    label: "Ca' d'Andrea",
    longitude: 10.27759481,
    latitude: 45.11917122,
  },
  {
    label: "Calvatone",
    longitude: 10.44206219,
    latitude: 45.12883088,
  },
  {
    label: "Camisano",
    longitude: 9.74619291,
    latitude: 45.44259064,
  },
  {
    label: "Campagnola Cremasca",
    longitude: 9.66944164,
    latitude: 45.39913388,
  },
  {
    label: "Capergnanica",
    longitude: 9.64414845,
    latitude: 45.33808233,
  },
  {
    label: "Cappella Cantone",
    longitude: 9.83810498,
    latitude: 45.24652266,
  },
  {
    label: "Cappella de' Picenardi",
    longitude: 10.23012535,
    latitude: 45.15912616,
  },
  {
    label: "Capralba",
    longitude: 9.64243814,
    latitude: 45.44227445,
  },
  {
    label: "Casalbuttano ed Uniti",
    longitude: 9.96168245,
    latitude: 45.25201488,
  },
  {
    label: "Casale Cremasco-Vidolasco",
    longitude: 9.71390281,
    latitude: 45.43160829,
  },
  {
    label: "Casaletto Ceredano",
    longitude: 9.61679215,
    latitude: 45.31812763,
  },
  {
    label: "Casaletto di Sopra",
    longitude: 9.78130227,
    latitude: 45.41861722,
  },
  {
    label: "Casaletto Vaprio",
    longitude: 9.6290426,
    latitude: 45.40727512,
  },
  {
    label: "Casalmaggiore",
    longitude: 10.41378078,
    latitude: 44.98742209,
  },
  {
    label: "Casalmorano",
    longitude: 9.89784097,
    latitude: 45.28708993,
  },
  {
    label: "Casteldidone",
    longitude: 10.40578209,
    latitude: 45.07067841,
  },
  {
    label: "Castel Gabbiano",
    longitude: 9.71762345,
    latitude: 45.46846383,
  },
  {
    label: "Castelleone",
    longitude: 9.76489432,
    latitude: 45.29350315,
  },
  {
    label: "Castelverde",
    longitude: 9.99696281,
    latitude: 45.18570753,
  },
  {
    label: "Castelvisconti",
    longitude: 9.94220743,
    latitude: 45.30567509,
  },
  {
    label: "Cella Dati",
    longitude: 10.22126378,
    latitude: 45.09615732,
  },
  {
    label: "Chieve",
    longitude: 9.6161229,
    latitude: 45.33750304,
  },
  {
    label: "Cicognolo",
    longitude: 10.1940419,
    latitude: 45.16761765,
  },
  {
    label: "Cingia de' Botti",
    longitude: 10.27527725,
    latitude: 45.0841602,
  },
  {
    label: "Corte de' Cortesi con Cignone",
    longitude: 10.00735107,
    latitude: 45.27264649,
  },
  {
    label: "Corte de' Frati",
    longitude: 10.09695797,
    latitude: 45.22006346,
  },
  {
    label: "Credera Rubbiano",
    longitude: 9.65512749,
    latitude: 45.30286286,
  },
  {
    label: "Crema",
    longitude: 9.6873619,
    latitude: 45.36329144,
  },
  {
    label: "Cremona",
    longitude: 10.02420865,
    latitude: 45.13336675,
  },
  {
    label: "Cremosano",
    longitude: 9.64028087,
    latitude: 45.3957885,
  },
  {
    label: "Crotta d'Adda",
    longitude: 9.85252768,
    latitude: 45.16136831,
  },
  {
    label: "Cumignano sul Naviglio",
    longitude: 9.83631833,
    latitude: 45.35455461,
  },
  {
    label: "Derovere",
    longitude: 10.24830654,
    latitude: 45.11014217,
  },
  {
    label: "Dovera",
    longitude: 9.5381977,
    latitude: 45.36350267,
  },
  {
    label: "Drizzona",
    longitude: 10.34969182,
    latitude: 45.14025008,
  },
  {
    label: "Fiesco",
    longitude: 9.77855341,
    latitude: 45.33639762,
  },
  {
    label: "Formigara",
    longitude: 9.77073788,
    latitude: 45.22306217,
  },
  {
    label: "Gabbioneta-Binanuova",
    longitude: 10.2216801,
    latitude: 45.21581505,
  },
  {
    label: "Gadesco-Pieve Delmona",
    longitude: 10.11603436,
    latitude: 45.15529613,
  },
  {
    label: "Genivolta",
    longitude: 9.87778112,
    latitude: 45.33487444,
  },
  {
    label: "Gerre de' Caprioli",
    longitude: 10.03838081,
    latitude: 45.10680778,
  },
  {
    label: "Gombito",
    longitude: 9.7300216,
    latitude: 45.26222878,
  },
  {
    label: "Grontardo",
    longitude: 10.15161743,
    latitude: 45.20140495,
  },
  {
    label: "Grumello Cremonese ed Uniti",
    longitude: 9.86736262,
    latitude: 45.19324785,
  },
  {
    label: "Gussola",
    longitude: 10.34635794,
    latitude: 45.00988015,
  },
  {
    label: "Isola Dovarese",
    longitude: 10.31183922,
    latitude: 45.17346097,
  },
  {
    label: "Izano",
    longitude: 9.75509291,
    latitude: 45.35573177,
  },
  {
    label: "Madignano",
    longitude: 9.72762376,
    latitude: 45.34334663,
  },
  {
    label: "Malagnino",
    longitude: 10.11422694,
    latitude: 45.13494893,
  },
  {
    label: "Martignana di Po",
    longitude: 10.37927438,
    latitude: 45.01090343,
  },
  {
    label: "Monte Cremasco",
    longitude: 9.57285773,
    latitude: 45.37223703,
  },
  {
    label: "Montodine",
    longitude: 9.71079969,
    latitude: 45.28626458,
  },
  {
    label: "Moscazzano",
    longitude: 9.68132273,
    latitude: 45.29357867,
  },
  {
    label: "Motta Baluffi",
    longitude: 10.25959107,
    latitude: 45.05446451,
  },
  {
    label: "Offanengo",
    longitude: 9.74131684,
    latitude: 45.38160349,
  },
  {
    label: "Olmeneta",
    longitude: 10.02474325,
    latitude: 45.23446724,
  },
  {
    label: "Ostiano",
    longitude: 10.24873256,
    latitude: 45.22180341,
  },
  {
    label: "Paderno Ponchielli",
    longitude: 9.92758336,
    latitude: 45.23876047,
  },
  {
    label: "Palazzo Pignano",
    longitude: 9.57793306,
    latitude: 45.38905006,
  },
  {
    label: "Pandino",
    longitude: 9.55269092,
    latitude: 45.40668143,
  },
  {
    label: "Persico Dosimo",
    longitude: 10.10335531,
    latitude: 45.18462151,
  },
  {
    label: "Pescarolo ed Uniti",
    longitude: 10.18509595,
    latitude: 45.19704222,
  },
  {
    label: "Pessina Cremonese",
    longitude: 10.24812085,
    latitude: 45.18504294,
  },
  {
    label: "Piadena",
    longitude: 10.3678837,
    latitude: 45.13115093,
  },
  {
    label: "Pianengo",
    longitude: 9.69280925,
    latitude: 45.40046685,
  },
  {
    label: "Pieranica",
    longitude: 9.61072341,
    latitude: 45.42516414,
  },
  {
    label: "Pieve d'Olmi",
    longitude: 10.12205833,
    latitude: 45.09182746,
  },
  {
    label: "Pieve San Giacomo",
    longitude: 10.18674451,
    latitude: 45.12808126,
  },
  {
    label: "Pizzighettone",
    longitude: 9.78313165,
    latitude: 45.18664763,
  },
  {
    label: "Pozzaglio ed Uniti",
    longitude: 10.04846986,
    latitude: 45.20096583,
  },
  {
    label: "Quintano",
    longitude: 9.61843663,
    latitude: 45.42024179,
  },
  {
    label: "Ricengo",
    longitude: 9.72366022,
    latitude: 45.40554807,
  },
  {
    label: "Ripalta Arpina",
    longitude: 9.72730407,
    latitude: 45.30163222,
  },
  {
    label: "Ripalta Cremasca",
    longitude: 9.69085968,
    latitude: 45.33548635,
  },
  {
    label: "Ripalta Guerina",
    longitude: 9.70454366,
    latitude: 45.30599095,
  },
  {
    label: "Rivarolo del Re ed Uniti",
    longitude: 10.4687891,
    latitude: 45.0278583,
  },
  {
    label: "Rivolta d'Adda",
    longitude: 9.51356807,
    latitude: 45.46963995,
  },
  {
    label: "Robecco d'Oglio",
    longitude: 10.07860016,
    latitude: 45.26006795,
  },
  {
    label: "Romanengo",
    longitude: 9.78545791,
    latitude: 45.37734979,
  },
  {
    label: "Salvirola",
    longitude: 9.78035753,
    latitude: 45.35457978,
  },
  {
    label: "San Bassano",
    longitude: 9.81348816,
    latitude: 45.24650968,
  },
  {
    label: "San Daniele Po",
    longitude: 10.17634391,
    latitude: 45.06726612,
  },
  {
    label: "San Giovanni in Croce",
    longitude: 10.378411,
    latitude: 45.07745004,
  },
  {
    label: "San Martino del Lago",
    longitude: 10.31565993,
    latitude: 45.07242871,
  },
  {
    label: "Scandolara Ravara",
    longitude: 10.30212945,
    latitude: 45.05308515,
  },
  {
    label: "Scandolara Ripa d'Oglio",
    longitude: 10.15714461,
    latitude: 45.22170438,
  },
  {
    label: "Sergnano",
    longitude: 9.70478826,
    latitude: 45.42811556,
  },
  {
    label: "Sesto ed Uniti",
    longitude: 9.91670824,
    latitude: 45.17550294,
  },
  {
    label: "Solarolo Rainerio",
    longitude: 10.3570868,
    latitude: 45.08126027,
  },
  {
    label: "Soncino",
    longitude: 9.87356025,
    latitude: 45.39988968,
  },
  {
    label: "Soresina",
    longitude: 9.85425666,
    latitude: 45.28863511,
  },
  {
    label: "Sospiro",
    longitude: 10.15925599,
    latitude: 45.10565613,
  },
  {
    label: "Spinadesco",
    longitude: 9.92828507,
    latitude: 45.14822339,
  },
  {
    label: "Spineda",
    longitude: 10.51651065,
    latitude: 45.06039475,
  },
  {
    label: "Spino d'Adda",
    longitude: 9.49243269,
    latitude: 45.40052368,
  },
  {
    label: "Stagno Lombardo",
    longitude: 10.08795842,
    latitude: 45.07323308,
  },
  {
    label: "Ticengo",
    longitude: 9.82820963,
    latitude: 45.36915344,
  },
  {
    label: "Torlino Vimercati",
    longitude: 9.59681136,
    latitude: 45.41851042,
  },
  {
    label: "Tornata",
    longitude: 10.43084995,
    latitude: 45.10466712,
  },
  {
    label: "Torre de' Picenardi",
    longitude: 10.28686148,
    latitude: 45.14400071,
  },
  {
    label: "Torricella del Pizzo",
    longitude: 10.29564633,
    latitude: 45.02004282,
  },
  {
    label: "Trescore Cremasco",
    longitude: 9.62544229,
    latitude: 45.4016592,
  },
  {
    label: "Trigolo",
    longitude: 9.81161994,
    latitude: 45.32926498,
  },
  {
    label: "Vaiano Cremasco",
    longitude: 9.59127217,
    latitude: 45.37249775,
  },
  {
    label: "Vailatitudee",
    longitude: 9.60501009,
    latitude: 45.46162258,
  },
  {
    label: "Vescovato",
    longitude: 10.16811976,
    latitude: 45.17636519,
  },
  {
    label: "Volongo",
    longitude: 10.30250641,
    latitude: 45.21154507,
  },
  {
    label: "Voltido",
    longitude: 10.33339356,
    latitude: 45.11175931,
  },
  {
    label: "Acquanegra sul Chiese",
    longitude: 10.43338521,
    latitude: 45.16049553,
  },
  {
    label: "Asola",
    longitude: 10.41432135,
    latitude: 45.22055281,
  },
  {
    label: "Bagnolo San Vito",
    longitude: 10.88141283,
    latitude: 45.09248928,
  },
  {
    label: "Bigarello",
    longitude: 10.89839216,
    latitude: 45.18012071,
  },
  {
    label: "Borgofranco sul Po",
    longitude: 11.20473631,
    latitude: 45.04941983,
  },
  {
    label: "Bozzolo",
    longitude: 10.48451639,
    latitude: 45.10531346,
  },
  {
    label: "Canneto sull'Oglio",
    longitude: 10.37540525,
    latitude: 45.15029003,
  },
  {
    label: "Carbonara di Po",
    longitude: 11.22920362,
    latitude: 45.03536191,
  },
  {
    label: "Casalmoro",
    longitude: 10.40798158,
    latitude: 45.2589957,
  },
  {
    label: "Casaloldo",
    longitude: 10.48213987,
    latitude: 45.25257166,
  },
  {
    label: "Casalromano",
    longitude: 10.36510881,
    latitude: 45.1972989,
  },
  {
    label: "Castelbelforte",
    longitude: 10.89219834,
    latitude: 45.21408104,
  },
  {
    label: "Castel d'Ario",
    longitude: 10.9714046,
    latitude: 45.18572114,
  },
  {
    label: "Castel Goffredo",
    longitude: 10.47462636,
    latitude: 45.29795068,
  },
  {
    label: "Castellucchio",
    longitude: 10.64451007,
    latitude: 45.14673489,
  },
  {
    label: "Castiglione delle Stiviere",
    longitude: 10.49104943,
    latitude: 45.3864959,
  },
  {
    label: "Cavriana",
    longitude: 10.60099734,
    latitude: 45.34991118,
  },
  {
    label: "Ceresara",
    longitude: 10.56562252,
    latitude: 45.26332286,
  },
  {
    label: "Commessaggio",
    longitude: 10.54534234,
    latitude: 45.03460205,
  },
  {
    label: "Curtatone",
    longitude: 10.7162709,
    latitude: 45.13376694,
  },
  {
    label: "Dosolo",
    longitude: 10.64141144,
    latitude: 44.95374837,
  },
  {
    label: "Gazoldo degli Ippoliti",
    longitude: 10.57957827,
    latitude: 45.19989216,
  },
  {
    label: "Gazzuolo",
    longitude: 10.58184611,
    latitude: 45.06885217,
  },
  {
    label: "Goito",
    longitude: 10.67547029,
    latitude: 45.25521903,
  },
  {
    label: "Gonzaga",
    longitude: 10.82119368,
    latitude: 44.95474885,
  },
  {
    label: "Guidizzolo",
    longitude: 10.5835291,
    latitude: 45.31803119,
  },
  {
    label: "Magnacavallo",
    longitude: 11.1847367,
    latitude: 45.00462322,
  },
  {
    label: "Mantova",
    longitude: 10.79277363,
    latitude: 45.15726772,
  },
  {
    label: "Marcaria",
    longitude: 10.53358846,
    latitude: 45.11961873,
  },
  {
    label: "Mariana Mantovana",
    longitude: 10.4876164,
    latitude: 45.19403347,
  },
  {
    label: "Marmirolo",
    longitude: 10.75731353,
    latitude: 45.22234005,
  },
  {
    label: "Medole",
    longitude: 10.50745401,
    latitude: 45.32707278,
  },
  {
    label: "Moglia",
    longitude: 10.91192569,
    latitude: 44.93033909,
  },
  {
    label: "Monzambano",
    longitude: 10.69313808,
    latitude: 45.38711484,
  },
  {
    label: "Motteggiana",
    longitude: 10.76444048,
    latitude: 45.03144077,
  },
  {
    label: "Ostiglia",
    longitude: 11.13143318,
    latitude: 45.06890884,
  },
  {
    label: "Pegognaga",
    longitude: 10.85904901,
    latitude: 44.99610137,
  },
  {
    label: "Pieve di Coriano",
    longitude: 11.10811985,
    latitude: 45.03293697,
  },
  {
    label: "Piubega",
    longitude: 10.53502787,
    latitude: 45.2275724,
  },
  {
    label: "Poggio Rusco",
    longitude: 11.11882241,
    latitude: 44.97742721,
  },
  {
    label: "Pomponesco",
    longitude: 10.59041521,
    latitude: 44.92807036,
  },
  {
    label: "Ponti sul Mincio",
    longitude: 10.68746271,
    latitude: 45.41155546,
  },
  {
    label: "Porto Mantovano",
    longitude: 10.79001314,
    latitude: 45.18877514,
  },
  {
    label: "Quingentole",
    longitude: 11.04575205,
    latitude: 45.0398812,
  },
  {
    label: "Quistello",
    longitude: 10.98043645,
    latitude: 45.00906722,
  },
  {
    label: "Redondesco",
    longitude: 10.51284971,
    latitude: 45.16667057,
  },
  {
    label: "Revere",
    longitude: 11.12697807,
    latitude: 45.05788625,
  },
  {
    label: "Rivarolo Mantovano",
    longitude: 10.43101188,
    latitude: 45.07056103,
  },
  {
    label: "Rodigo",
    longitude: 10.62430268,
    latitude: 45.19935682,
  },
  {
    label: "Roncoferraro",
    longitude: 10.95100592,
    latitude: 45.13324175,
  },
  {
    label: "Roverbella",
    longitude: 10.76451212,
    latitude: 45.26503872,
  },
  {
    label: "Sabbioneta",
    longitude: 10.48991359,
    latitude: 44.99975993,
  },
  {
    label: "San Benedetto Po",
    longitude: 10.92848767,
    latitude: 45.04107354,
  },
  {
    label: "San Giacomo delle Segnate",
    longitude: 11.03316413,
    latitude: 44.97181021,
  },
  {
    label: "San Giorgio di Mantova",
    longitude: 10.84589851,
    latitude: 45.16526842,
  },
  {
    label: "San Giovanni del Dosso",
    longitude: 11.08112346,
    latitude: 44.96608634,
  },
  {
    label: "San Martino dall'Argine",
    longitude: 10.52014901,
    latitude: 45.10070268,
  },
  {
    label: "Schivenoglia",
    longitude: 11.07747756,
    latitude: 44.99115212,
  },
  {
    label: "Sermide e Felonica",
    longitude: 11.2989691,
    latitude: 45.00602954,
  },
  {
    label: "Serravalle a Po",
    longitude: 11.07981063,
    latitude: 45.06565239,
  },
  {
    label: "Solferino",
    longitude: 10.57297894,
    latitude: 45.37181299,
  },
  {
    label: "Sustinente",
    longitude: 11.01814371,
    latitude: 45.06881028,
  },
  {
    label: "Suzzara",
    longitude: 10.74561894,
    latitude: 44.99023318,
  },
  {
    label: "Viadana",
    longitude: 10.52143199,
    latitude: 44.92611892,
  },
  {
    label: "Villa Poma",
    longitude: 11.11664577,
    latitude: 44.99915395,
  },
  {
    label: "Villimpenta",
    longitude: 11.0293895,
    latitude: 45.1449413,
  },
  {
    label: "Volta Mantovana",
    longitude: 10.65949338,
    latitude: 45.31957585,
  },
  {
    label: "Borgo Virgilio",
    longitude: 10.775,
    latitude: 45.088,
  },
  {
    label: "Aldino",
    longitude: 11.35402888,
    latitude: 46.36783596,
  },
  {
    label: "Andriano",
    longitude: 11.23293128,
    latitude: 46.52023315,
  },
  {
    label: "Anterivo",
    longitude: 11.36675092,
    latitude: 46.2774946,
  },
  {
    label: "Appiano sulla strada del vino",
    longitude: 11.25885656,
    latitude: 46.45527585,
  },
  {
    label: "Avelengo",
    longitude: 11.22475188,
    latitude: 46.64671212,
  },
  {
    label: "Badia",
    longitude: 11.89342082,
    latitude: 46.61067544,
  },
  {
    label: "Barbiano",
    longitude: 11.51987095,
    latitude: 46.60364899,
  },
  {
    label: "Bolzano",
    longitude: 11.35662422,
    latitude: 46.49933453,
  },
  {
    label: "Braies",
    longitude: 12.1325695,
    latitude: 46.71973406,
  },
  {
    label: "Brennero",
    longitude: 11.44322879,
    latitude: 46.93771816,
  },
  {
    label: "Bressanone",
    longitude: 11.65699492,
    latitude: 46.71643929,
  },
  {
    label: "Bronzolo",
    longitude: 11.31989385,
    latitude: 46.40460343,
  },
  {
    label: "Brunico",
    longitude: 11.9372984,
    latitude: 46.79738401,
  },
  {
    label: "Caines",
    longitude: 11.1752993,
    latitude: 46.6965165,
  },
  {
    label: "Caldaro sulla strada del vino",
    longitude: 11.24515892,
    latitude: 46.41317465,
  },
  {
    label: "Campo di Trens",
    longitude: 11.48395496,
    latitude: 46.87111849,
  },
  {
    label: "Campo Tures",
    longitude: 11.95188039,
    latitude: 46.91992376,
  },
  {
    label: "Castelbello-Ciardes",
    longitude: 10.90114298,
    latitude: 46.62831219,
  },
  {
    label: "Castelrotto",
    longitude: 11.55946126,
    latitude: 46.56780623,
  },
  {
    label: "Cermes",
    longitude: 11.14503298,
    latitude: 46.63279289,
  },
  {
    label: "Chienes",
    longitude: 11.83631922,
    latitude: 46.80644678,
  },
  {
    label: "Chiusa",
    longitude: 11.56809214,
    latitude: 46.64167054,
  },
  {
    label: "Cornedo all'Isarco",
    longitude: 11.39267489,
    latitude: 46.49425422,
  },
  {
    label: "Cortaccia sulla strada del vino",
    longitude: 11.22355148,
    latitude: 46.31331764,
  },
  {
    label: "Cortina sulla strada del vino",
    longitude: 11.22259368,
    latitude: 46.26868917,
  },
  {
    label: "Corvara in Badia",
    longitude: 11.8745233,
    latitude: 46.55291042,
  },
  {
    label: "Curon Venosta",
    longitude: 10.54216821,
    latitude: 46.80669161,
  },
  {
    label: "Dobbiaco",
    longitude: 12.22019811,
    latitude: 46.73551057,
  },
  {
    label: "Egna",
    longitude: 11.27282548,
    latitude: 46.31387437,
  },
  {
    label: "Falzes",
    longitude: 11.8826532,
    latitude: 46.8117497,
  },
  {
    label: "Fiè allo Sciliar",
    longitude: 11.50139797,
    latitude: 46.51753578,
  },
  {
    label: "Fortezza",
    longitude: 11.61256174,
    latitude: 46.78644534,
  },
  {
    label: "Funes",
    longitude: 11.68057452,
    latitude: 46.64226751,
  },
  {
    label: "Gais",
    longitude: 11.9477335,
    latitude: 46.835277,
  },
  {
    label: "Gargazzone",
    longitude: 11.20131739,
    latitude: 46.58471676,
  },
  {
    label: "Glorenza",
    longitude: 10.55567836,
    latitude: 46.67116521,
  },
  {
    label: "Laces",
    longitude: 10.85946518,
    latitude: 46.61763702,
  },
  {
    label: "Lagundo",
    longitude: 11.11775573,
    latitude: 46.67753737,
  },
  {
    label: "Laion",
    longitude: 11.56460096,
    latitude: 46.60876374,
  },
  {
    label: "Laives",
    longitude: 11.33846501,
    latitude: 46.42648803,
  },
  {
    label: "Lana",
    longitude: 11.1452916,
    latitude: 46.6161126,
  },
  {
    label: "Lasa",
    longitude: 10.69756468,
    latitude: 46.61787377,
  },
  {
    label: "Lauregno",
    longitude: 11.06224733,
    latitude: 46.45420512,
  },
  {
    label: "Luson",
    longitude: 11.76072155,
    latitude: 46.74589084,
  },
  {
    label: "Magrè sulla strada del vino",
    longitude: 11.20897141,
    latitude: 46.28686737,
  },
  {
    label: "Malles Venosta",
    longitude: 10.54480017,
    latitude: 46.68953918,
  },
  {
    label: "Marebbe",
    longitude: 11.93270219,
    latitude: 46.69754471,
  },
  {
    label: "Marlengo",
    longitude: 11.14041814,
    latitude: 46.65479398,
  },
  {
    label: "Martello",
    longitude: 10.78260147,
    latitude: 46.56632365,
  },
  {
    label: "Meltina",
    longitude: 11.25557484,
    latitude: 46.58637551,
  },
  {
    label: "Merano",
    longitude: 11.1618378,
    latitude: 46.67176409,
  },
  {
    label: "Monguelfo-Tesido",
    longitude: 12.10478072,
    latitude: 46.75693562,
  },
  {
    label: "Montagna",
    longitude: 11.30272954,
    latitude: 46.3313476,
  },
  {
    label: "Moso in Passiria",
    longitude: 11.16900266,
    latitude: 46.83012323,
  },
  {
    label: "Nalles",
    longitude: 11.20422581,
    latitude: 46.54109648,
  },
  {
    label: "Naturno",
    longitude: 11.0053329,
    latitude: 46.65103584,
  },
  {
    label: "Naz-Sciaves",
    longitude: 11.66584939,
    latitude: 46.77053266,
  },
  {
    label: "Nova Levante",
    longitude: 11.53555423,
    latitude: 46.42978743,
  },
  {
    label: "Nova Ponente",
    longitude: 11.42559558,
    latitude: 46.41411435,
  },
  {
    label: "Ora",
    longitude: 11.29831873,
    latitude: 46.34811537,
  },
  {
    label: "Ortisei",
    longitude: 11.67180248,
    latitude: 46.57611397,
  },
  {
    label: "Parcines",
    longitude: 11.07350936,
    latitude: 46.6844223,
  },
  {
    label: "Perca",
    longitude: 11.98322218,
    latitude: 46.79145734,
  },
  {
    label: "Plaus",
    longitude: 11.04236898,
    latitude: 46.65569401,
  },
  {
    label: "Ponte Gardena",
    longitude: 11.53033945,
    latitude: 46.59706769,
  },
  {
    label: "Postal",
    longitude: 11.1915481,
    latitude: 46.6096293,
  },
  {
    label: "Prato allo Stelvio",
    longitude: 10.58959468,
    latitude: 46.6175649,
  },
  {
    label: "Predoi",
    longitude: 12.10286682,
    latitude: 47.03849873,
  },
  {
    label: "Proves",
    longitude: 11.02231835,
    latitude: 46.47617681,
  },
  {
    label: "Racines",
    longitude: 11.37850769,
    latitude: 46.88271114,
  },
  {
    label: "Rasun-Anterselva",
    longitude: 12.0493348,
    latitude: 46.78077135,
  },
  {
    label: "Renon",
    longitude: 11.4630202,
    latitude: 46.53670501,
  },
  {
    label: "Rifiano",
    longitude: 11.18033767,
    latitude: 46.70159182,
  },
  {
    label: "Rio di Pusteria",
    longitude: 11.66780079,
    latitude: 46.79702773,
  },
  {
    label: "Rodengo",
    longitude: 11.69495569,
    latitude: 46.77932062,
  },
  {
    label: "Salorno",
    longitude: 11.21297097,
    latitude: 46.2391079,
  },
  {
    label: "San Candido",
    longitude: 12.28204175,
    latitude: 46.73242428,
  },
  {
    label: "San Genesio Atesino",
    longitude: 11.33158202,
    latitude: 46.53564865,
  },
  {
    label: "San Leonardo in Passiria",
    longitude: 11.24328928,
    latitude: 46.81167592,
  },
  {
    label: "San Lorenzo di Sebato",
    longitude: 11.90177375,
    latitude: 46.78352784,
  },
  {
    label: "San Martino in Badia",
    longitude: 11.89801735,
    latitude: 46.681889,
  },
  {
    label: "San Martino in Passiria",
    longitude: 11.226956,
    latitude: 46.78260956,
  },
  {
    label: "San Pancrazio",
    longitude: 11.08504315,
    latitude: 46.58690151,
  },
  {
    label: "Santa Cristina Valgardena",
    longitude: 11.72132932,
    latitude: 46.55853007,
  },
  {
    label: "Sarentino",
    longitude: 11.35624145,
    latitude: 46.64270185,
  },
  {
    label: "Scena",
    longitude: 11.18728565,
    latitude: 46.69005377,
  },
  {
    label: "Selva dei Molini",
    longitude: 11.86059136,
    latitude: 46.89130115,
  },
  {
    label: "Selva di Val Gardena",
    longitude: 11.75880704,
    latitude: 46.55599324,
  },
  {
    label: "Senales",
    longitude: 10.90957203,
    latitude: 46.70539541,
  },
  {
    label: "Sesto",
    longitude: 12.35031535,
    latitude: 46.70209527,
  },
  {
    label: "Silandro",
    longitude: 10.77314173,
    latitude: 46.62793135,
  },
  {
    label: "Sluderno",
    longitude: 10.58533678,
    latitude: 46.66422759,
  },
  {
    label: "Stelvio",
    longitude: 10.54555359,
    latitude: 46.59769763,
  },
  {
    label: "Terento",
    longitude: 11.77656972,
    latitude: 46.82894661,
  },
  {
    label: "Terlano",
    longitude: 11.249642,
    latitude: 46.52883169,
  },
  {
    label: "Termeno sulla strada del vino",
    longitude: 11.23934849,
    latitude: 46.34140482,
  },
  {
    label: "Tesimo",
    longitude: 11.16913009,
    latitude: 46.56409126,
  },
  {
    label: "Tires",
    longitude: 11.52769626,
    latitude: 46.46717216,
  },
  {
    label: "Tirolo",
    longitude: 11.15357574,
    latitude: 46.69045029,
  },
  {
    label: "Trodena nel parco naturale",
    longitude: 11.34933722,
    latitude: 46.32292639,
  },
  {
    label: "Tubre",
    longitude: 10.46436095,
    latitude: 46.64602194,
  },
  {
    label: "Ultimo",
    longitude: 11.00306602,
    latitude: 46.54883009,
  },
  {
    label: "Vadena",
    longitude: 11.30517982,
    latitude: 46.41449692,
  },
  {
    label: "Valdaora",
    longitude: 12.0309743,
    latitude: 46.76140696,
  },
  {
    label: "Val di Vizze",
    longitude: 11.46246781,
    latitude: 46.89971353,
  },
  {
    label: "Valle Aurina",
    longitude: 11.98080229,
    latitude: 46.99686383,
  },
  {
    label: "Valle di Casies",
    longitude: 12.22719988,
    latitude: 46.81102783,
  },
  {
    label: "Vandoies",
    longitude: 11.72213188,
    latitude: 46.81614527,
  },
  {
    label: "Varna",
    longitude: 11.63583954,
    latitude: 46.74006051,
  },
  {
    label: "Verano",
    longitude: 11.22574181,
    latitude: 46.60511072,
  },
  {
    label: "Villabassa",
    longitude: 12.169067,
    latitude: 46.73775971,
  },
  {
    label: "Villandro",
    longitude: 11.5398251,
    latitude: 46.63140507,
  },
  {
    label: "Vipiteno",
    longitude: 11.43179468,
    latitude: 46.8979722,
  },
  {
    label: "Velturno",
    longitude: 11.60009608,
    latitude: 46.66947108,
  },
  {
    label: "La Valle",
    longitude: 11.92581012,
    latitude: 46.65661658,
  },
  {
    label: "Senale-San Felice",
    longitude: 11.13247907,
    latitude: 46.49215807,
  },
  {
    label: "Ala",
    longitude: 11.00475333,
    latitude: 45.75676703,
  },
  {
    label: "Albiano",
    longitude: 11.19376768,
    latitude: 46.14520833,
  },
  {
    label: "Aldeno",
    longitude: 11.09131825,
    latitude: 45.97728775,
  },
  {
    label: "Andalo",
    longitude: 11.0032873,
    latitude: 46.16487823,
  },
  {
    label: "Arco",
    longitude: 10.8861933,
    latitude: 45.91928662,
  },
  {
    label: "Avio",
    longitude: 10.94154266,
    latitude: 45.73545329,
  },
  {
    label: "Baselga di Pinè",
    longitude: 11.2445269,
    latitude: 46.12998915,
  },
  {
    label: "Bedollo",
    longitude: 11.30321802,
    latitude: 46.16142431,
  },
  {
    label: "Besenello",
    longitude: 11.10618753,
    latitude: 45.94568733,
  },
  {
    label: "Bieno",
    longitude: 11.5536885,
    latitude: 46.08024712,
  },
  {
    label: "Bleggio Superiore",
    longitude: 10.83705955,
    latitude: 46.0253275,
  },
  {
    label: "Bocenago",
    longitude: 10.75908629,
    latitude: 46.11843068,
  },
  {
    label: "Bondone",
    longitude: 10.55125567,
    latitude: 45.80542077,
  },
  {
    label: "Borgo Valsugana",
    longitude: 11.45648568,
    latitude: 46.05304141,
  },
  {
    label: "Brentonico",
    longitude: 10.95493373,
    latitude: 45.81886908,
  },
  {
    label: "Bresimo",
    longitude: 10.96861301,
    latitude: 46.41044397,
  },
  {
    label: "Brez",
    longitude: 11.10727867,
    latitude: 46.43104038,
  },
  {
    label: "Caderzone Terme",
    longitude: 10.75692332,
    latitude: 46.12941256,
  },
  {
    label: "Cagnò",
    longitude: 11.04211957,
    latitude: 46.39464733,
  },
  {
    label: "Calceranica al Lago",
    longitude: 11.2423754,
    latitude: 46.00375375,
  },
  {
    label: "Caldes",
    longitude: 10.9428125,
    latitude: 46.36513314,
  },
  {
    label: "Caldonazzo",
    longitude: 11.26184714,
    latitude: 45.99140479,
  },
  {
    label: "Calliano",
    longitude: 11.09432828,
    latitude: 45.9342713,
  },
  {
    label: "Campitello di Fassa",
    longitude: 11.74009284,
    latitude: 46.47574796,
  },
  {
    label: "Campodenno",
    longitude: 11.03336746,
    latitude: 46.25731146,
  },
  {
    label: "Canal San Bovo",
    longitude: 11.73137986,
    latitude: 46.15631465,
  },
  {
    label: "Canazei",
    longitude: 11.77129957,
    latitude: 46.47683463,
  },
  {
    label: "Capriana",
    longitude: 11.3391705,
    latitude: 46.26297128,
  },
  {
    label: "Carano",
    longitude: 11.4400563,
    latitude: 46.2923022,
  },
  {
    label: "Carisolo",
    longitude: 10.76386451,
    latitude: 46.16904792,
  },
  {
    label: "Carzano",
    longitude: 11.4936291,
    latitude: 46.07087639,
  },
  {
    label: "Castel Condino",
    longitude: 10.60283973,
    latitude: 45.91521143,
  },
  {
    label: "Castelfondo",
    longitude: 11.11655375,
    latitude: 46.45597424,
  },
  {
    label: "Castello-Molina di Fiemme",
    longitude: 11.43470654,
    latitude: 46.28124969,
  },
  {
    label: "Castello Tesino",
    longitude: 11.63370534,
    latitude: 46.06241572,
  },
  {
    label: "Castelnuovo",
    longitude: 11.49419964,
    latitude: 46.05226247,
  },
  {
    label: "Cavalese",
    longitude: 11.46043364,
    latitude: 46.29129819,
  },
  {
    label: "Cavareno",
    longitude: 11.13660275,
    latitude: 46.4079151,
  },
  {
    label: "Cavedago",
    longitude: 11.02805643,
    latitude: 46.18352917,
  },
  {
    label: "Cavedine",
    longitude: 10.97082265,
    latitude: 45.99391529,
  },
  {
    label: "Cavizzana",
    longitude: 10.95837224,
    latitude: 46.36818861,
  },
  {
    label: "Cimone",
    longitude: 11.06846607,
    latitude: 45.97819064,
  },
  {
    label: "Cinte Tesino",
    longitude: 11.61619434,
    latitude: 46.0543692,
  },
  {
    label: "Cis",
    longitude: 11.00233845,
    latitude: 46.40005451,
  },
  {
    label: "Civezzano",
    longitude: 11.17963203,
    latitude: 46.09005862,
  },
  {
    label: "Cles",
    longitude: 11.03564375,
    latitude: 46.36440465,
  },
  {
    label: "Cloz",
    longitude: 11.08663729,
    latitude: 46.41892512,
  },
  {
    label: "Commezzadura",
    longitude: 10.83926199,
    latitude: 46.32122317,
  },
  {
    label: "Croviana",
    longitude: 10.90454728,
    latitude: 46.34463628,
  },
  {
    label: "Daiano",
    longitude: 11.44788293,
    latitude: 46.30067015,
  },
  {
    label: "Dambel",
    longitude: 11.09302262,
    latitude: 46.40491482,
  },
  {
    label: "Denno",
    longitude: 11.05132614,
    latitude: 46.27435425,
  },
  {
    label: "Drena",
    longitude: 10.9445963,
    latitude: 45.9711691,
  },
  {
    label: "Dro",
    longitude: 10.9118157,
    latitude: 45.96254924,
  },
  {
    label: "Faedo",
    longitude: 11.16248613,
    latitude: 46.192726,
  },
  {
    label: "Fai della Paganella",
    longitude: 11.0695608,
    latitude: 46.17838429,
  },
  {
    label: "Fiavè",
    longitude: 10.84202477,
    latitude: 46.0048069,
  },
  {
    label: "Fierozzo",
    longitude: 11.31823589,
    latitude: 46.11178063,
  },
  {
    label: "Folgaria",
    longitude: 11.17014101,
    latitude: 45.91663324,
  },
  {
    label: "Fondo",
    longitude: 11.13606457,
    latitude: 46.44012582,
  },
  {
    label: "Fornace",
    longitude: 11.20580077,
    latitude: 46.11723367,
  },
  {
    label: "Frassilongo",
    longitude: 11.29212297,
    latitude: 46.08565885,
  },
  {
    label: "Garniga Terme",
    longitude: 11.08588791,
    latitude: 46.00482982,
  },
  {
    label: "Giovo",
    longitude: 11.1516807,
    latitude: 46.15682949,
  },
  {
    label: "Giustino",
    longitude: 10.76847784,
    latitude: 46.14878032,
  },
  {
    label: "Grigno",
    longitude: 11.63561779,
    latitude: 46.01717508,
  },
  {
    label: "Imer",
    longitude: 11.79680143,
    latitude: 46.15109445,
  },
  {
    label: "Isera",
    longitude: 11.0083521,
    latitude: 45.8870757,
  },
  {
    label: "Lavarone",
    longitude: 11.26970396,
    latitude: 45.93938938,
  },
  {
    label: "Lavis",
    longitude: 11.11173415,
    latitude: 46.13971519,
  },
  {
    label: "Levico Terme",
    longitude: 11.30232766,
    latitude: 46.01101281,
  },
  {
    label: "Livo",
    longitude: 11.01916869,
    latitude: 46.40554978,
  },
  {
    label: "Lona-Lases",
    longitude: 11.21882443,
    latitude: 46.14679565,
  },
  {
    label: "Luserna",
    longitude: 11.32439104,
    latitude: 45.92193218,
  },
  {
    label: "Malè",
    longitude: 10.91248259,
    latitude: 46.35269834,
  },
  {
    label: "Malosco",
    longitude: 11.1445235,
    latitude: 46.43499359,
  },
  {
    label: "Massimeno",
    longitude: 10.77300566,
    latitude: 46.142305,
  },
  {
    label: "Mazzin",
    longitude: 11.72840998,
    latitude: 46.47036363,
  },
  {
    label: "Mezzana",
    longitude: 10.80277007,
    latitude: 46.31580915,
  },
  {
    label: "Mezzano",
    longitude: 11.80595311,
    latitude: 46.15452726,
  },
  {
    label: "Mezzocorona",
    longitude: 11.11849988,
    latitude: 46.21649042,
  },
  {
    label: "Mezzolombardo",
    longitude: 11.09051799,
    latitude: 46.21351447,
  },
  {
    label: "Moena",
    longitude: 11.66021931,
    latitude: 46.37660648,
  },
  {
    label: "Molveno",
    longitude: 10.9662535,
    latitude: 46.14288959,
  },
  {
    label: "Mori",
    longitude: 10.97549449,
    latitude: 45.85195052,
  },
  {
    label: "Nago-Torbole",
    longitude: 10.87605352,
    latitude: 45.87057555,
  },
  {
    label: "Nave San Rocco",
    longitude: 11.10735653,
    latitude: 46.1666899,
  },
  {
    label: "Nogaredo",
    longitude: 11.02309066,
    latitude: 45.91201029,
  },
  {
    label: "Nomi",
    longitude: 11.07125718,
    latitude: 45.92895668,
  },
  {
    label: "Novaledo",
    longitude: 11.36579931,
    latitude: 46.02257583,
  },
  {
    label: "Ospedaletto",
    longitude: 11.552903,
    latitude: 46.04363279,
  },
  {
    label: "Ossana",
    longitude: 10.73804321,
    latitude: 46.30627677,
  },
  {
    label: "Palù del Fersina",
    longitude: 11.3521341,
    latitude: 46.1299084,
  },
  {
    label: "Panchià",
    longitude: 11.5422322,
    latitude: 46.28567937,
  },
  {
    label: "Ronzo-Chienis",
    longitude: 10.94990671,
    latitude: 45.89079928,
  },
  {
    label: "Peio",
    longitude: 10.68907789,
    latitude: 46.35528769,
  },
  {
    label: "Pellizzano",
    longitude: 10.7605651,
    latitude: 46.30938639,
  },
  {
    label: "Pelugo",
    longitude: 10.72334177,
    latitude: 46.08811132,
  },
  {
    label: "Pergine Valsugana",
    longitude: 11.24049381,
    latitude: 46.0603175,
  },
  {
    label: "Pieve Tesino",
    longitude: 11.61057369,
    latitude: 46.06901125,
  },
  {
    label: "Pinzolo",
    longitude: 10.76660594,
    latitude: 46.16001113,
  },
  {
    label: "Pomarolo",
    longitude: 11.04305509,
    latitude: 45.92679872,
  },
  {
    label: "Pozza di Fassa",
    longitude: 11.68654422,
    latitude: 46.43070096,
  },
  {
    label: "Predazzo",
    longitude: 11.60278164,
    latitude: 46.31256391,
  },
  {
    label: "Rabbi",
    longitude: 10.85065549,
    latitude: 46.39825276,
  },
  {
    label: "Revò",
    longitude: 11.05782178,
    latitude: 46.39103316,
  },
  {
    label: "Riva del Garda",
    longitude: 10.83888821,
    latitude: 45.88504406,
  },
  {
    label: "Romallo",
    longitude: 11.06836216,
    latitude: 46.39783103,
  },
  {
    label: "Romeno",
    longitude: 11.12018975,
    latitude: 46.39439766,
  },
  {
    label: "Roncegno Terme",
    longitude: 11.40968867,
    latitude: 46.05098573,
  },
  {
    label: "Ronchi Valsugana",
    longitude: 11.43429735,
    latitude: 46.06713704,
  },
  {
    label: "Ronzone",
    longitude: 11.14999907,
    latitude: 46.42333978,
  },
  {
    label: "Roverè della Luna",
    longitude: 11.16960836,
    latitude: 46.24998794,
  },
  {
    label: "Rovereto",
    longitude: 11.04517514,
    latitude: 45.88648404,
  },
  {
    label: "Ruffrè-Mendola",
    longitude: 11.17746883,
    latitude: 46.41450529,
  },
  {
    label: "Rumo",
    longitude: 11.01732679,
    latitude: 46.44106943,
  },
  {
    label: "Sagron Mis",
    longitude: 11.94327632,
    latitude: 46.19492556,
  },
  {
    label: "Samone",
    longitude: 11.52037588,
    latitude: 46.08187379,
  },
  {
    label: "San Michele all'Adige",
    longitude: 11.13405098,
    latitude: 46.1926646,
  },
  {
    label: "Sant'Orsola Terme",
    longitude: 11.3025579,
    latitude: 46.1082001,
  },
  {
    label: "Sanzeno",
    longitude: 11.06805927,
    latitude: 46.37441809,
  },
  {
    label: "Sarnonico",
    longitude: 11.14140892,
    latitude: 46.42004432,
  },
  {
    label: "Scurelle",
    longitude: 11.50758651,
    latitude: 46.06448377,
  },
  {
    label: "Segonzano",
    longitude: 11.2587658,
    latitude: 46.19036068,
  },
  {
    label: "Sfruz",
    longitude: 11.11547195,
    latitude: 46.33716824,
  },
  {
    label: "Soraga di Fassa",
    longitude: 11.66881239,
    latitude: 46.39603664,
  },
  {
    label: "Sover",
    longitude: 11.31532726,
    latitude: 46.22118281,
  },
  {
    label: "Spiazzo",
    longitude: 10.73679917,
    latitude: 46.10284998,
  },
  {
    label: "Spormaggiore",
    longitude: 11.04822748,
    latitude: 46.21857725,
  },
  {
    label: "Sporminore",
    longitude: 11.03119056,
    latitude: 46.23711782,
  },
  {
    label: "Stenico",
    longitude: 10.85359956,
    latitude: 46.05301338,
  },
  {
    label: "Storo",
    longitude: 10.58018609,
    latitude: 45.84927357,
  },
  {
    label: "Strembo",
    longitude: 10.75161495,
    latitude: 46.12001215,
  },
  {
    label: "Telve",
    longitude: 11.47888916,
    latitude: 46.07144614,
  },
  {
    label: "Telve di Sopra",
    longitude: 11.47255576,
    latitude: 46.07231279,
  },
  {
    label: "Tenna",
    longitude: 11.2662951,
    latitude: 46.0145839,
  },
  {
    label: "Tenno",
    longitude: 10.83264413,
    latitude: 45.91860092,
  },
  {
    label: "Terragnolo",
    longitude: 11.1530395,
    latitude: 45.878702,
  },
  {
    label: "Terzolas",
    longitude: 10.92611911,
    latitude: 46.3613707,
  },
  {
    label: "Tesero",
    longitude: 11.51118835,
    latitude: 46.28933907,
  },
  {
    label: "Tione di Trento",
    longitude: 10.72326426,
    latitude: 46.03171404,
  },
  {
    label: "Ton",
    longitude: 11.08570405,
    latitude: 46.26589858,
  },
  {
    label: "Torcegno",
    longitude: 11.45001604,
    latitude: 46.0732826,
  },
  {
    label: "Trambileno",
    longitude: 11.07340897,
    latitude: 45.86969867,
  },
  {
    label: "Trento",
    longitude: 11.12123097,
    latitude: 46.06893511,
  },
  {
    label: "Valfloriana",
    longitude: 11.34214006,
    latitude: 46.24896578,
  },
  {
    label: "Vallarsa",
    longitude: 11.11763173,
    latitude: 45.78259232,
  },
  {
    label: "Varena",
    longitude: 11.4560065,
    latitude: 46.3033557,
  },
  {
    label: "Vermiglio",
    longitude: 10.69285089,
    latitude: 46.29882667,
  },
  {
    label: "Vignola-Falesina",
    longitude: 11.27816706,
    latitude: 46.04391844,
  },
  {
    label: "Vigo di Fassa",
    longitude: 11.6733468,
    latitude: 46.42040429,
  },
  {
    label: "Villa Lagarina",
    longitude: 11.03184685,
    latitude: 45.91604151,
  },
  {
    label: "Volano",
    longitude: 11.06079067,
    latitude: 45.91719314,
  },
  {
    label: "Zambana",
    longitude: 11.09603972,
    latitude: 46.15125132,
  },
  {
    label: "Ziano di Fiemme",
    longitude: 11.56112305,
    latitude: 46.28646875,
  },
  {
    label: "Comano Terme",
    longitude: 10.8688522,
    latitude: 46.03166101,
  },
  {
    label: "Ledro",
    longitude: 10.73311322,
    latitude: 45.88870857,
  },
  {
    label: "Predaia",
    longitude: 11.0731,
    latitude: 46.3234,
  },
  {
    label: "San Lorenzo Dorsino",
    longitude: 10.9095,
    latitude: 46.0775,
  },
  {
    label: "Valdaone",
    longitude: 10.6221,
    latitude: 45.9481,
  },
  {
    label: "Dimaro Folgarida",
    longitude: 10.8718,
    latitude: 46.3271,
  },
  {
    label: "Pieve di Bono-Prezzo",
    longitude: 10.6413,
    latitude: 45.9431,
  },
  {
    label: "Altavalle",
    longitude: 11.2383,
    latitude: 46.1822,
  },
  {
    label: "Altopiano della Vigolana",
    longitude: 11.1997,
    latitude: 46.0065,
  },
  {
    label: "Amblar-Don",
    longitude: 11.1375,
    latitude: 46.3902,
  },
  {
    label: "Borgo Chiese",
    longitude: 10.6013,
    latitude: 45.8906,
  },
  {
    label: "Borgo Lares",
    longitude: 10.7521,
    latitude: 46.0352,
  },
  {
    label: "Castel Ivano",
    longitude: 11.5211,
    latitude: 46.0683,
  },
  {
    label: "Cembra Lisignago",
    longitude: 11.2218,
    latitude: 46.1746,
  },
  {
    label: "Contà",
    longitude: 11.0241,
    latitude: 46.3108,
  },
  {
    label: "Madruzzo",
    longitude: 10.9841,
    latitude: 46.026,
  },
  {
    label: "Porte di Rendena",
    longitude: 10.7121,
    latitude: 46.0626,
  },
  {
    label: "Primiero San Martino di Castrozza",
    longitude: 11.8298,
    latitude: 46.1771,
  },
  {
    label: "Sella Giudicarie",
    longitude: 10.6692,
    latitude: 45.9836,
  },
  {
    label: "Tre Ville",
    longitude: 10.7783,
    latitude: 46.0545,
  },
  {
    label: "Vallelaghi",
    longitude: 11.0005,
    latitude: 46.08,
  },
  {
    label: "Ville d'Anaunia",
    longitude: 11.024,
    latitude: 46.3298,
  },
  {
    label: "Affi",
    longitude: 10.77315447,
    latitude: 45.55157835,
  },
  {
    label: "Albaredo d'Adige",
    longitude: 11.26758294,
    latitude: 45.31738813,
  },
  {
    label: "Angiari",
    longitude: 11.28372239,
    latitude: 45.21802033,
  },
  {
    label: "Arcole",
    longitude: 11.28695082,
    latitude: 45.35768001,
  },
  {
    label: "Badia Calavena",
    longitude: 11.156357,
    latitude: 45.56389465,
  },
  {
    label: "Bardolino",
    longitude: 10.71966767,
    latitude: 45.54709416,
  },
  {
    label: "Belfiore",
    longitude: 11.20842171,
    latitude: 45.38036769,
  },
  {
    label: "Bevilacqua",
    longitude: 11.39819298,
    latitude: 45.23344659,
  },
  {
    label: "Bonavigo",
    longitude: 11.27970781,
    latitude: 45.25754447,
  },
  {
    label: "Boschi Sant'Anna",
    longitude: 11.35793023,
    latitude: 45.21945717,
  },
  {
    label: "Bosco Chiesanuova",
    longitude: 11.02941969,
    latitude: 45.62213312,
  },
  {
    label: "Bovolone",
    longitude: 11.11706145,
    latitude: 45.25798824,
  },
  {
    label: "Brentino Belluno",
    longitude: 10.89346482,
    latitude: 45.65616869,
  },
  {
    label: "Brenzone sul Garda",
    longitude: 10.76747345,
    latitude: 45.7089034,
  },
  {
    label: "Bussolengo",
    longitude: 10.84641902,
    latitude: 45.47459264,
  },
  {
    label: "Buttapietra",
    longitude: 10.99849693,
    latitude: 45.3419854,
  },
  {
    label: "Caldiero",
    longitude: 11.18377975,
    latitude: 45.41209588,
  },
  {
    label: "Caprino Veronese",
    longitude: 10.79377937,
    latitude: 45.60656855,
  },
  {
    label: "Casaleone",
    longitude: 11.20022173,
    latitude: 45.16977267,
  },
  {
    label: "Castagnaro",
    longitude: 11.41083411,
    latitude: 45.12049934,
  },
  {
    label: "Castel d'Azzano",
    longitude: 10.9440042,
    latitude: 45.35358762,
  },
  {
    label: "Castelnuovo del Garda",
    longitude: 10.76370019,
    latitude: 45.44038139,
  },
  {
    label: "Cavaion Veronese",
    longitude: 10.77168306,
    latitude: 45.53828849,
  },
  {
    label: "Cazzano di Tramigna",
    longitude: 11.20348545,
    latitude: 45.4724391,
  },
  {
    label: "Cerea",
    longitude: 11.21287303,
    latitude: 45.19202052,
  },
  {
    label: "Cerro Veronese",
    longitude: 11.04159037,
    latitude: 45.57418406,
  },
  {
    label: "Cologna Veneta",
    longitude: 11.38439416,
    latitude: 45.31068675,
  },
  {
    label: "Colognola ai Colli",
    longitude: 11.18671113,
    latitude: 45.43439433,
  },
  {
    label: "Concamarise",
    longitude: 11.13883353,
    latitude: 45.20735525,
  },
  {
    label: "Costermano sul Garda",
    longitude: 10.73848849,
    latitude: 45.58656732,
  },
  {
    label: "Dolcè",
    longitude: 10.85270293,
    latitude: 45.60004522,
  },
  {
    label: "Erbè",
    longitude: 10.96733995,
    latitude: 45.24246329,
  },
  {
    label: "Erbezzo",
    longitude: 11.00055525,
    latitude: 45.64171145,
  },
  {
    label: "Ferrara di Monte Baldo",
    longitude: 10.85428478,
    latitude: 45.67566724,
  },
  {
    label: "Fumane",
    longitude: 10.88211313,
    latitude: 45.54316629,
  },
  {
    label: "Garda",
    longitude: 10.7070209,
    latitude: 45.5749689,
  },
  {
    label: "Gazzo Veronese",
    longitude: 11.1036212,
    latitude: 45.14249825,
  },
  {
    label: "Grezzana",
    longitude: 11.04763369,
    latitude: 45.54636615,
  },
  {
    label: "Illasi",
    longitude: 11.18418675,
    latitude: 45.46593265,
  },
  {
    label: "Isola della Scala",
    longitude: 11.00753615,
    latitude: 45.2736633,
  },
  {
    label: "Isola Rizza",
    longitude: 11.19870989,
    latitude: 45.29144,
  },
  {
    label: "Lavagno",
    longitude: 11.13167087,
    latitude: 45.44066715,
  },
  {
    label: "Lazise",
    longitude: 10.73247071,
    latitude: 45.50507105,
  },
  {
    label: "Legnago",
    longitude: 11.30751817,
    latitude: 45.19138954,
  },
  {
    label: "Malcesine",
    longitude: 10.80781851,
    latitude: 45.76284647,
  },
  {
    label: "Marano di Valpolicella",
    longitude: 10.91541415,
    latitude: 45.55384279,
  },
  {
    label: "Mezzane di Sotto",
    longitude: 11.12755767,
    latitude: 45.4810716,
  },
  {
    label: "Minerbe",
    longitude: 11.34069697,
    latitude: 45.24175652,
  },
  {
    label: "Montecchia di Crosara",
    longitude: 11.25318493,
    latitude: 45.48539704,
  },
  {
    label: "Monteforte d'Alpone",
    longitude: 11.28505257,
    latitude: 45.42225537,
  },
  {
    label: "Mozzecane",
    longitude: 10.81771082,
    latitude: 45.30970544,
  },
  {
    label: "Negrar",
    longitude: 10.9378251,
    latitude: 45.53065538,
  },
  {
    label: "Nogara",
    longitude: 11.06090553,
    latitude: 45.1778399,
  },
  {
    label: "Nogarole Rocca",
    longitude: 10.88371218,
    latitude: 45.29164131,
  },
  {
    label: "Oppeano",
    longitude: 11.1783499,
    latitude: 45.30789824,
  },
  {
    label: "Palù",
    longitude: 11.1545684,
    latitude: 45.32469174,
  },
  {
    label: "Pastrengo",
    longitude: 10.80027342,
    latitude: 45.49217619,
  },
  {
    label: "Pescantina",
    longitude: 10.86724425,
    latitude: 45.48256141,
  },
  {
    label: "Peschiera del Garda",
    longitude: 10.69194074,
    latitude: 45.43913874,
  },
  {
    label: "Povegliano Veronese",
    longitude: 10.88092951,
    latitude: 45.347262,
  },
  {
    label: "Pressana",
    longitude: 11.40197974,
    latitude: 45.28478649,
  },
  {
    label: "Rivoli Veronese",
    longitude: 10.81250539,
    latitude: 45.57114567,
  },
  {
    label: "Roncà",
    longitude: 11.29014374,
    latitude: 45.4794542,
  },
  {
    label: "Ronco all'Adige",
    longitude: 11.24641156,
    latitude: 45.33570342,
  },
  {
    label: "Roverchiara",
    longitude: 11.24789009,
    latitude: 45.27241826,
  },
  {
    label: "Roveredo di Guà",
    longitude: 11.44267592,
    latitude: 45.27272571,
  },
  {
    label: "Roverè Veronese",
    longitude: 11.06565542,
    latitude: 45.59333994,
  },
  {
    label: "Salizzole",
    longitude: 11.0838142,
    latitude: 45.2427723,
  },
  {
    label: "San Bonifacio",
    longitude: 11.2699234,
    latitude: 45.39592296,
  },
  {
    label: "San Giovanni Ilarione",
    longitude: 11.23577605,
    latitude: 45.52093328,
  },
  {
    label: "San Giovanni Lupatoto",
    longitude: 11.05019475,
    latitude: 45.38008715,
  },
  {
    label: "Sanguinetto",
    longitude: 11.1511796,
    latitude: 45.18274607,
  },
  {
    label: "San Martino Buon Albergo",
    longitude: 11.09313392,
    latitude: 45.42214578,
  },
  {
    label: "San Mauro di Saline",
    longitude: 11.11247008,
    latitude: 45.56520995,
  },
  {
    label: "San Pietro di Morubio",
    longitude: 11.23223844,
    latitude: 45.2481843,
  },
  {
    label: "San Pietro in Cariano",
    longitude: 10.88646128,
    latitude: 45.51731003,
  },
  {
    label: "Sant'Ambrogio di Valpolicella",
    longitude: 10.83367953,
    latitude: 45.52462519,
  },
  {
    label: "Sant'Anna d'Alfaedo",
    longitude: 10.95108168,
    latitude: 45.62713353,
  },
  {
    label: "San Zeno di Montagna",
    longitude: 10.73235851,
    latitude: 45.63794962,
  },
  {
    label: "Selva di Progno",
    longitude: 11.1388199,
    latitude: 45.61231964,
  },
  {
    label: "Soave",
    longitude: 11.24862237,
    latitude: 45.42261572,
  },
  {
    label: "Sommacampagna",
    longitude: 10.8404048,
    latitude: 45.40710167,
  },
  {
    label: "Sona",
    longitude: 10.8326653,
    latitude: 45.43402698,
  },
  {
    label: "Sorgà",
    longitude: 10.9805369,
    latitude: 45.21202822,
  },
  {
    label: "Terrazzo",
    longitude: 11.398472,
    latitude: 45.173327,
  },
  {
    label: "Torri del Benaco",
    longitude: 10.68521785,
    latitude: 45.60807997,
  },
  {
    label: "Tregnago",
    longitude: 11.1646208,
    latitude: 45.51663794,
  },
  {
    label: "Trevenzuolo",
    longitude: 10.93304145,
    latitude: 45.27130528,
  },
  {
    label: "Valeggio sul Mincio",
    longitude: 10.73444725,
    latitude: 45.3536397,
  },
  {
    label: "Velo Veronese",
    longitude: 11.0958906,
    latitude: 45.60528021,
  },
  {
    label: "Verona",
    longitude: 10.99352685,
    latitude: 45.43839046,
  },
  {
    label: "Veronella",
    longitude: 11.32049292,
    latitude: 45.32308406,
  },
  {
    label: "Vestenanova",
    longitude: 11.2276199,
    latitude: 45.57345303,
  },
  {
    label: "Vigasio",
    longitude: 10.94334454,
    latitude: 45.31745297,
  },
  {
    label: "Villa Bartolomea",
    longitude: 11.35332886,
    latitude: 45.1592825,
  },
  {
    label: "Villafranca di Verona",
    longitude: 10.8467368,
    latitude: 45.35080445,
  },
  {
    label: "Zevio",
    longitude: 11.13533549,
    latitude: 45.37555774,
  },
  {
    label: "Zimella",
    longitude: 11.33920608,
    latitude: 45.36352253,
  },
  {
    label: "Agugliaro",
    longitude: 11.5849044,
    latitude: 45.323978,
  },
  {
    label: "Albettone",
    longitude: 11.5851886,
    latitude: 45.35832713,
  },
  {
    label: "Alonte",
    longitude: 11.42661929,
    latitude: 45.36584993,
  },
  {
    label: "Altavilla Vicentina",
    longitude: 11.46460545,
    latitude: 45.51280375,
  },
  {
    label: "Altissimo",
    longitude: 11.25123374,
    latitude: 45.61513104,
  },
  {
    label: "Arcugnano",
    longitude: 11.55075114,
    latitude: 45.49612153,
  },
  {
    label: "Arsiero",
    longitude: 11.35034581,
    latitude: 45.80361106,
  },
  {
    label: "Arzignano",
    longitude: 11.33227401,
    latitude: 45.52104336,
  },
  {
    label: "Asiago",
    longitude: 11.50911782,
    latitude: 45.87570138,
  },
  {
    label: "Asigliano Veneto",
    longitude: 11.44617803,
    latitude: 45.30448851,
  },
  {
    label: "Barbarano Vicentino",
    longitude: 11.53957638,
    latitude: 45.41001941,
  },
  {
    label: "Bassano del Grappa",
    longitude: 11.73417428,
    latitude: 45.76713634,
  },
  {
    label: "Bolzano Vicentino",
    longitude: 11.62206811,
    latitude: 45.60072795,
  },
  {
    label: "Breganze",
    longitude: 11.56530663,
    latitude: 45.70825991,
  },
  {
    label: "Brendola",
    longitude: 11.45521671,
    latitude: 45.4693637,
  },
  {
    label: "Bressanvido",
    longitude: 11.63337487,
    latitude: 45.64632826,
  },
  {
    label: "Brogliano",
    longitude: 11.36445049,
    latitude: 45.58840264,
  },
  {
    label: "Caldogno",
    longitude: 11.50045986,
    latitude: 45.61197969,
  },
  {
    label: "Caltrano",
    longitude: 11.46056025,
    latitude: 45.7703147,
  },
  {
    label: "Calvene",
    longitude: 11.51222821,
    latitude: 45.76589155,
  },
  {
    label: "Camisano Vicentino",
    longitude: 11.7133179,
    latitude: 45.52207011,
  },
  {
    label: "Campiglia dei Berici",
    longitude: 11.5390233,
    latitude: 45.33669469,
  },
  {
    label: "Campolongo sul Brenta",
    longitude: 11.70229205,
    latitude: 45.8276454,
  },
  {
    label: "Carrè",
    longitude: 11.45876776,
    latitude: 45.74740715,
  },
  {
    label: "Cartigliano",
    longitude: 11.69408163,
    latitude: 45.71350485,
  },
  {
    label: "Cassola",
    longitude: 11.79893158,
    latitude: 45.73258837,
  },
  {
    label: "Castegnero",
    longitude: 11.59917249,
    latitude: 45.43629541,
  },
  {
    label: "Castelgomberto",
    longitude: 11.39446599,
    latitude: 45.58593478,
  },
  {
    label: "Chiampo",
    longitude: 11.28166114,
    latitude: 45.5448488,
  },
  {
    label: "Chiuppano",
    longitude: 11.4640907,
    latitude: 45.76130213,
  },
  {
    label: "Cismon del Grappa",
    longitude: 11.73155839,
    latitude: 45.91895087,
  },
  {
    label: "Cogollo del Cengio",
    longitude: 11.42067648,
    latitude: 45.78633484,
  },
  {
    label: "Conco",
    longitude: 11.60516387,
    latitude: 45.79833785,
  },
  {
    label: "Cornedo Vicentino",
    longitude: 11.3431958,
    latitude: 45.61412912,
  },
  {
    label: "Costabissara",
    longitude: 11.48766249,
    latitude: 45.58544915,
  },
  {
    label: "Creazzo",
    longitude: 11.47891333,
    latitude: 45.53185379,
  },
  {
    label: "Crespadoro",
    longitude: 11.22603326,
    latitude: 45.61942225,
  },
  {
    label: "Dueville",
    longitude: 11.54876502,
    latitude: 45.63568959,
  },
  {
    label: "Enego",
    longitude: 11.70971745,
    latitude: 45.93996639,
  },
  {
    label: "Fara Vicentino",
    longitude: 11.54622632,
    latitude: 45.74045266,
  },
  {
    label: "Foza",
    longitude: 11.63075604,
    latitude: 45.89624973,
  },
  {
    label: "Gallio",
    longitude: 11.54916365,
    latitude: 45.89236675,
  },
  {
    label: "Gambellara",
    longitude: 11.34144585,
    latitude: 45.46024868,
  },
  {
    label: "Gambugliano",
    longitude: 11.4400832,
    latitude: 45.58871037,
  },
  {
    label: "Grisignano di Zocco",
    longitude: 11.69881754,
    latitude: 45.47536152,
  },
  {
    label: "Grumolo delle Abbadesse",
    longitude: 11.65897872,
    latitude: 45.5162254,
  },
  {
    label: "Isola Vicentina",
    longitude: 11.44151256,
    latitude: 45.62964307,
  },
  {
    label: "Laghi",
    longitude: 11.2732358,
    latitude: 45.82422328,
  },
  {
    label: "Lastebasse",
    longitude: 11.26972979,
    latitude: 45.91614794,
  },
  {
    label: "Longare",
    longitude: 11.6070214,
    latitude: 45.4787581,
  },
  {
    label: "Lonigo",
    longitude: 11.3874321,
    latitude: 45.38882824,
  },
  {
    label: "Lugo di Vicenza",
    longitude: 11.52285737,
    latitude: 45.75313291,
  },
  {
    label: "Lusiana",
    longitude: 11.57676483,
    latitude: 45.78627131,
  },
  {
    label: "Malo",
    longitude: 11.40443548,
    latitude: 45.65864175,
  },
  {
    label: "Marano Vicentino",
    longitude: 11.43028802,
    latitude: 45.69239216,
  },
  {
    label: "Marostica",
    longitude: 11.65594798,
    latitude: 45.74739086,
  },
  {
    label: "Mason Vicentino",
    longitude: 11.60608269,
    latitude: 45.71564268,
  },
  {
    label: "Molvena",
    longitude: 11.61222518,
    latitude: 45.73374116,
  },
  {
    label: "Montebello Vicentino",
    longitude: 11.38584039,
    latitude: 45.45758965,
  },
  {
    label: "Montecchio Maggiore",
    longitude: 11.40733711,
    latitude: 45.5058177,
  },
  {
    label: "Montecchio Precalcino",
    longitude: 11.56163342,
    latitude: 45.66394881,
  },
  {
    label: "Monte di Malo",
    longitude: 11.36141421,
    latitude: 45.66036987,
  },
  {
    label: "Montegalda",
    longitude: 11.67286172,
    latitude: 45.4435153,
  },
  {
    label: "Montegaldella",
    longitude: 11.6740893,
    latitude: 45.4360794,
  },
  {
    label: "Monteviale",
    longitude: 11.45789904,
    latitude: 45.56075624,
  },
  {
    label: "Monticello Conte Otto",
    longitude: 11.58046801,
    latitude: 45.59526268,
  },
  {
    label: "Montorso Vicentino",
    longitude: 11.36221682,
    latitude: 45.48964758,
  },
  {
    label: "Mossano",
    longitude: 11.55056209,
    latitude: 45.42020941,
  },
  {
    label: "Mussolente",
    longitude: 11.8011807,
    latitude: 45.77897016,
  },
  {
    label: "Nanto",
    longitude: 11.59156379,
    latitude: 45.42525542,
  },
  {
    label: "Nogarole Vicentino",
    longitude: 11.28793126,
    latitude: 45.55947576,
  },
  {
    label: "Nove",
    longitude: 11.67925055,
    latitude: 45.72461246,
  },
  {
    label: "Noventa Vicentina",
    longitude: 11.54046115,
    latitude: 45.29090677,
  },
  {
    label: "Orgiano",
    longitude: 11.46648317,
    latitude: 45.35159051,
  },
  {
    label: "Pedemonte",
    longitude: 11.30587602,
    latitude: 45.90852413,
  },
  {
    label: "Pianezze",
    longitude: 11.62485617,
    latitude: 45.73872318,
  },
  {
    label: "Piovene Rocchette",
    longitude: 11.43021106,
    latitude: 45.75878286,
  },
  {
    label: "Pojana Maggiore",
    longitude: 11.50137755,
    latitude: 45.29206822,
  },
  {
    label: "Posina",
    longitude: 11.26119293,
    latitude: 45.7903879,
  },
  {
    label: "Pove del Grappa",
    longitude: 11.72905121,
    latitude: 45.7989028,
  },
  {
    label: "Pozzoleone",
    longitude: 11.67925911,
    latitude: 45.6491244,
  },
  {
    label: "Quinto Vicentino",
    longitude: 11.62502675,
    latitude: 45.57305624,
  },
  {
    label: "Recoaro Terme",
    longitude: 11.22602664,
    latitude: 45.70511802,
  },
  {
    label: "Roana",
    longitude: 11.47829848,
    latitude: 45.86856467,
  },
  {
    label: "Romano d'Ezzelino",
    longitude: 11.77070358,
    latitude: 45.77455276,
  },
  {
    label: "Rosà",
    longitude: 11.76154737,
    latitude: 45.72476307,
  },
  {
    label: "Rossano Veneto",
    longitude: 11.79989962,
    latitude: 45.70506862,
  },
  {
    label: "Rotzo",
    longitude: 11.40041599,
    latitude: 45.86389841,
  },
  {
    label: "Salcedo",
    longitude: 11.56575973,
    latitude: 45.75846117,
  },
  {
    label: "Sandrigo",
    longitude: 11.60278141,
    latitude: 45.66132289,
  },
  {
    label: "San Nazario",
    longitude: 11.6894689,
    latitude: 45.84109352,
  },
  {
    label: "San Pietro Mussolino",
    longitude: 11.25814473,
    latitude: 45.58730477,
  },
  {
    label: "Santorso",
    longitude: 11.38872662,
    latitude: 45.73769126,
  },
  {
    label: "San Vito di Leguzzano",
    longitude: 11.37618523,
    latitude: 45.6817516,
  },
  {
    label: "Sarcedo",
    longitude: 11.52618688,
    latitude: 45.70807199,
  },
  {
    label: "Sarego",
    longitude: 11.40501801,
    latitude: 45.40713279,
  },
  {
    label: "Schiavon",
    longitude: 11.64427868,
    latitude: 45.69774909,
  },
  {
    label: "Schio",
    longitude: 11.3569363,
    latitude: 45.71305838,
  },
  {
    label: "Solagna",
    longitude: 11.71799623,
    latitude: 45.81691835,
  },
  {
    label: "Sossano",
    longitude: 11.50993774,
    latitude: 45.35856611,
  },
  {
    label: "Sovizzo",
    longitude: 11.44311405,
    latitude: 45.52658981,
  },
  {
    label: "Tezze sul Brenta",
    longitude: 11.7043846,
    latitude: 45.68743489,
  },
  {
    label: "Thiene",
    longitude: 11.47834926,
    latitude: 45.70731095,
  },
  {
    label: "Tonezza del Cimone",
    longitude: 11.34624499,
    latitude: 45.85817388,
  },
  {
    label: "Torrebelvicino",
    longitude: 11.30659415,
    latitude: 45.71663937,
  },
  {
    label: "Torri di Quartesolo",
    longitude: 11.61629167,
    latitude: 45.51956158,
  },
  {
    label: "Trissino",
    longitude: 11.37421844,
    latitude: 45.56514048,
  },
  {
    label: "Valdagno",
    longitude: 11.29947777,
    latitude: 45.64537815,
  },
  {
    label: "Valdastico",
    longitude: 11.36335101,
    latitude: 45.883245,
  },
  {
    label: "Valli del Pasubio",
    longitude: 11.25985618,
    latitude: 45.74179583,
  },
  {
    label: "Valstagna",
    longitude: 11.65762256,
    latitude: 45.86064391,
  },
  {
    label: "Velo d'Astico",
    longitude: 11.3661874,
    latitude: 45.78864682,
  },
  {
    label: "Vicenza",
    longitude: 11.54597109,
    latitude: 45.54749699,
  },
  {
    label: "Villaga",
    longitude: 11.53399082,
    latitude: 45.40228544,
  },
  {
    label: "Villaverla",
    longitude: 11.49358505,
    latitude: 45.65498251,
  },
  {
    label: "Zanè",
    longitude: 11.45407587,
    latitude: 45.72061342,
  },
  {
    label: "Zermeghedo",
    longitude: 11.36889383,
    latitude: 45.47543474,
  },
  {
    label: "Zovencedo",
    longitude: 11.50265841,
    latitude: 45.4279979,
  },
  {
    label: "Zugliano",
    longitude: 11.5214291,
    latitude: 45.73361151,
  },
  {
    label: "Val Liona",
    longitude: 11.4641,
    latitude: 45.4208,
  },
  {
    label: "Agordo",
    longitude: 12.03334539,
    latitude: 46.28146762,
  },
  {
    label: "Alano di Piave",
    longitude: 11.909106,
    latitude: 45.90803048,
  },
  {
    label: "Alleghe",
    longitude: 12.02054508,
    latitude: 46.40628388,
  },
  {
    label: "Arsiè",
    longitude: 11.75713827,
    latitude: 45.98441935,
  },
  {
    label: "Auronzo di Cadore",
    longitude: 12.44484194,
    latitude: 46.5502226,
  },
  {
    label: "Belluno",
    longitude: 12.21704167,
    latitude: 46.13837528,
  },
  {
    label: "Borca di Cadore",
    longitude: 12.21876708,
    latitude: 46.43812191,
  },
  {
    label: "Calalzo di Cadore",
    longitude: 12.38057214,
    latitude: 46.44728055,
  },
  {
    label: "Cencenighe Agordino",
    longitude: 11.96626282,
    latitude: 46.35125355,
  },
  {
    label: "Cesiomaggiore",
    longitude: 11.9871697,
    latitude: 46.08869043,
  },
  {
    label: "Chies d'Alpago",
    longitude: 12.38684047,
    latitude: 46.17265521,
  },
  {
    label: "Cibiana di Cadore",
    longitude: 12.29077183,
    latitude: 46.39170291,
  },
  {
    label: "Colle Santa Lucia",
    longitude: 12.0142861,
    latitude: 46.44728947,
  },
  {
    label: "Comelico Superiore",
    longitude: 12.51601408,
    latitude: 46.5887541,
  },
  {
    label: "Cortina d'Ampezzo",
    longitude: 12.13849837,
    latitude: 46.53665759,
  },
  {
    label: "Danta di Cadore",
    longitude: 12.52005181,
    latitude: 46.56695409,
  },
  {
    label: "Domegge di Cadore",
    longitude: 12.41577376,
    latitude: 46.46029547,
  },
  {
    label: "Falcade",
    longitude: 11.87275512,
    latitude: 46.35826889,
  },
  {
    label: "Feltre",
    longitude: 11.90990666,
    latitude: 46.0178257,
  },
  {
    label: "Fonzaso",
    longitude: 11.79959802,
    latitude: 46.0177974,
  },
  {
    label: "Canale d'Agordo",
    longitude: 11.91485046,
    latitude: 46.36046517,
  },
  {
    label: "Gosaldo",
    longitude: 11.95622264,
    latitude: 46.22054916,
  },
  {
    label: "Lamon",
    longitude: 11.748633,
    latitude: 46.04727464,
  },
  {
    label: "La Valle Agordina",
    longitude: 12.0680953,
    latitude: 46.28216156,
  },
  {
    label: "Lentiai",
    longitude: 12.02084611,
    latitude: 46.04484151,
  },
  {
    label: "Limana",
    longitude: 12.1802729,
    latitude: 46.09913641,
  },
  {
    label: "Livinallongo del Col di Lana",
    longitude: 11.95439152,
    latitude: 46.48148087,
  },
  {
    label: "Lorenzago di Cadore",
    longitude: 12.45904559,
    latitude: 46.480106,
  },
  {
    label: "Lozzo di Cadore",
    longitude: 12.44296923,
    latitude: 46.48468002,
  },
  {
    label: "Mel",
    longitude: 12.07941813,
    latitude: 46.0624011,
  },
  {
    label: "Ospitale di Cadore",
    longitude: 12.32348069,
    latitude: 46.32910295,
  },
  {
    label: "Pedavena",
    longitude: 11.88143634,
    latitude: 46.0393884,
  },
  {
    label: "Perarolo di Cadore",
    longitude: 12.35529812,
    latitude: 46.3973938,
  },
  {
    label: "Pieve di Cadore",
    longitude: 12.37484327,
    latitude: 46.42936956,
  },
  {
    label: "Ponte nelle Alpi",
    longitude: 12.30135502,
    latitude: 46.17299811,
  },
  {
    label: "Rivamonte Agordino",
    longitude: 12.02447609,
    latitude: 46.25343637,
  },
  {
    label: "Rocca Pietore",
    longitude: 11.97753959,
    latitude: 46.43470659,
  },
  {
    label: "San Gregorio nelle Alpi",
    longitude: 12.02628185,
    latitude: 46.10324431,
  },
  {
    label: "San Nicolò di Comelico",
    longitude: 12.52757476,
    latitude: 46.5822302,
  },
  {
    label: "San Pietro di Cadore",
    longitude: 12.5859949,
    latitude: 46.57068179,
  },
  {
    label: "Santa Giustina",
    longitude: 12.04245466,
    latitude: 46.08338157,
  },
  {
    label: "San Tomaso Agordino",
    longitude: 11.97526948,
    latitude: 46.38119584,
  },
  {
    label: "Santo Stefano di Cadore",
    longitude: 12.54967101,
    latitude: 46.55776559,
  },
  {
    label: "San Vito di Cadore",
    longitude: 12.20479218,
    latitude: 46.46014853,
  },
  {
    label: "Sappada",
    longitude: 12.68327803,
    latitude: 46.56583815,
  },
  {
    label: "Sedico",
    longitude: 12.0970935,
    latitude: 46.11086562,
  },
  {
    label: "Selva di Cadore",
    longitude: 12.03441589,
    latitude: 46.45152121,
  },
  {
    label: "Seren del Grappa",
    longitude: 11.84358065,
    latitude: 45.98878641,
  },
  {
    label: "Sospirolo",
    longitude: 12.07315918,
    latitude: 46.14199626,
  },
  {
    label: "Soverzene",
    longitude: 12.30371082,
    latitude: 46.20376509,
  },
  {
    label: "Sovramonte",
    longitude: 11.78655496,
    latitude: 46.05658939,
  },
  {
    label: "Taibon Agordino",
    longitude: 12.01330881,
    latitude: 46.29879566,
  },
  {
    label: "Tambre",
    longitude: 12.42311882,
    latitude: 46.1293412,
  },
  {
    label: "Trichiana",
    longitude: 12.13606586,
    latitude: 46.06772796,
  },
  {
    label: "Vallada Agordina",
    longitude: 11.9309195,
    latitude: 46.36814758,
  },
  {
    label: "Valle di Cadore",
    longitude: 12.32782408,
    latitude: 46.41704431,
  },
  {
    label: "Vigo di Cadore",
    longitude: 12.4712231,
    latitude: 46.49967004,
  },
  {
    label: "Vodo Cadore",
    longitude: 12.25031842,
    latitude: 46.41810062,
  },
  {
    label: "Voltago Agordino",
    longitude: 12.00492985,
    latitude: 46.27198279,
  },
  {
    label: "Zoppè di Cadore",
    longitude: 12.17264345,
    latitude: 46.38639193,
  },
  {
    label: "Quero Vas",
    longitude: 11.9208,
    latitude: 45.9405,
  },
  {
    label: "Longarone",
    longitude: 12.3,
    latitude: 46.2666,
  },
  {
    label: "Alpago",
    longitude: 12.3525,
    latitude: 46.1263,
  },
  {
    label: "Val di Zoldo",
    longitude: 12.183,
    latitude: 46.3487,
  },
  {
    label: "Altivole",
    longitude: 11.95579071,
    latitude: 45.7539904,
  },
  {
    label: "Arcade",
    longitude: 12.21902869,
    latitude: 45.78501021,
  },
  {
    label: "Asolo",
    longitude: 11.91236715,
    latitude: 45.80217304,
  },
  {
    label: "Borso del Grappa",
    longitude: 11.79930684,
    latitude: 45.82099181,
  },
  {
    label: "Breda di Piave",
    longitude: 12.33099505,
    latitude: 45.7240999,
  },
  {
    label: "Caerano di San Marco",
    longitude: 12.00115938,
    latitude: 45.78526897,
  },
  {
    label: "Cappella Maggiore",
    longitude: 12.36259492,
    latitude: 45.96900509,
  },
  {
    label: "Carbonera",
    longitude: 12.28902247,
    latitude: 45.68157107,
  },
  {
    label: "Casale sul Sile",
    longitude: 12.32562804,
    latitude: 45.59747105,
  },
  {
    label: "Casier",
    longitude: 12.25497457,
    latitude: 45.63422021,
  },
  {
    label: "Castelcucco",
    longitude: 11.88382755,
    latitude: 45.83318302,
  },
  {
    label: "Castelfranco Veneto",
    longitude: 11.92639656,
    latitude: 45.6713595,
  },
  {
    label: "Castello di Godego",
    longitude: 11.88101881,
    latitude: 45.6921393,
  },
  {
    label: "Cavaso del Tomba",
    longitude: 11.89521161,
    latitude: 45.8603445,
  },
  {
    label: "Cessalto",
    longitude: 12.6137971,
    latitude: 45.71275461,
  },
  {
    label: "Chiarano",
    longitude: 12.58213662,
    latitude: 45.72841045,
  },
  {
    label: "Cimadolmo",
    longitude: 12.36129567,
    latitude: 45.78770133,
  },
  {
    label: "Cison di Valmarino",
    longitude: 12.14322163,
    latitude: 45.97001173,
  },
  {
    label: "Codognè",
    longitude: 12.42960265,
    latitude: 45.87033061,
  },
  {
    label: "Colle Umberto",
    longitude: 12.34151373,
    latitude: 45.94051353,
  },
  {
    label: "Conegliano",
    longitude: 12.29772652,
    latitude: 45.88717298,
  },
  {
    label: "Cordignano",
    longitude: 12.41545245,
    latitude: 45.94895396,
  },
  {
    label: "Cornuda",
    longitude: 12.00822039,
    latitude: 45.83182956,
  },
  {
    label: "Crespano del Grappa",
    longitude: 11.83877907,
    latitude: 45.82729401,
  },
  {
    label: "Crocetta del Montello",
    longitude: 12.03524524,
    latitude: 45.82493568,
  },
  {
    label: "Farra di Soligo",
    longitude: 12.12658368,
    latitude: 45.9054339,
  },
  {
    label: "Follina",
    longitude: 12.11894993,
    latitude: 45.95280564,
  },
  {
    label: "Fontanelle",
    longitude: 12.46824407,
    latitude: 45.83092189,
  },
  {
    label: "Fonte",
    longitude: 11.86728087,
    latitude: 45.78793779,
  },
  {
    label: "Fregona",
    longitude: 12.33941755,
    latitude: 46.00746944,
  },
  {
    label: "Gaiarine",
    longitude: 12.48167791,
    latitude: 45.88046047,
  },
  {
    label: "Giavera del Montello",
    longitude: 12.16928942,
    latitude: 45.79379271,
  },
  {
    label: "Godega di Sant'Urbano",
    longitude: 12.39779903,
    latitude: 45.93172971,
  },
  {
    label: "Gorgo al Monticano",
    longitude: 12.55434462,
    latitude: 45.78546694,
  },
  {
    label: "Istrana",
    longitude: 12.10328328,
    latitude: 45.67767069,
  },
  {
    label: "Loria",
    longitude: 11.86653396,
    latitude: 45.72835905,
  },
  {
    label: "Mansuè",
    longitude: 12.53758696,
    latitude: 45.82211552,
  },
  {
    label: "Mareno di Piave",
    longitude: 12.35218627,
    latitude: 45.84085592,
  },
  {
    label: "Maser",
    longitude: 11.97199708,
    latitude: 45.80745333,
  },
  {
    label: "Maserada sul Piave",
    longitude: 12.31849257,
    latitude: 45.74969489,
  },
  {
    label: "Meduna di Livenza",
    longitude: 12.61350058,
    latitude: 45.8052467,
  },
  {
    label: "Miane",
    longitude: 12.09097425,
    latitude: 45.94290515,
  },
  {
    label: "Mogliano Veneto",
    longitude: 12.23645377,
    latitude: 45.56158644,
  },
  {
    label: "Monastier di Treviso",
    longitude: 12.43574158,
    latitude: 45.64952077,
  },
  {
    label: "Monfumo",
    longitude: 11.92035358,
    latitude: 45.83047344,
  },
  {
    label: "Montebelluna",
    longitude: 12.04511131,
    latitude: 45.77612579,
  },
  {
    label: "Morgano",
    longitude: 12.08347971,
    latitude: 45.6343557,
  },
  {
    label: "Moriago della Battaglia",
    longitude: 12.10660135,
    latitude: 45.86690701,
  },
  {
    label: "Motta di Livenza",
    longitude: 12.61025463,
    latitude: 45.77664033,
  },
  {
    label: "Nervesa della Battaglia",
    longitude: 12.20742259,
    latitude: 45.82448857,
  },
  {
    label: "Oderzo",
    longitude: 12.49312816,
    latitude: 45.7808856,
  },
  {
    label: "Ormelle",
    longitude: 12.42014379,
    latitude: 45.77874437,
  },
  {
    label: "Orsago",
    longitude: 12.42359612,
    latitude: 45.93087785,
  },
  {
    label: "Paderno del Grappa",
    longitude: 11.85247417,
    latitude: 45.82652202,
  },
  {
    label: "Paese",
    longitude: 12.1535354,
    latitude: 45.67164128,
  },
  {
    label: "Pederobba",
    longitude: 11.9858198,
    latitude: 45.86083922,
  },
  {
    label: "Pieve di Soligo",
    longitude: 12.17467924,
    latitude: 45.90000744,
  },
  {
    label: "Ponte di Piave",
    longitude: 12.46395801,
    latitude: 45.71601581,
  },
  {
    label: "Ponzano Veneto",
    longitude: 12.20710968,
    latitude: 45.71909082,
  },
  {
    label: "Portobuffolè",
    longitude: 12.53844639,
    latitude: 45.85392083,
  },
  {
    label: "Possagno",
    longitude: 11.88144288,
    latitude: 45.85468919,
  },
  {
    label: "Povegliano",
    longitude: 12.20589471,
    latitude: 45.75996116,
  },
  {
    label: "Preganziol",
    longitude: 12.2350928,
    latitude: 45.60142391,
  },
  {
    label: "Quinto di Treviso",
    longitude: 12.1660218,
    latitude: 45.64599842,
  },
  {
    label: "Refrontolo",
    longitude: 12.20793062,
    latitude: 45.92513984,
  },
  {
    label: "Resana",
    longitude: 11.95553095,
    latitude: 45.63431959,
  },
  {
    label: "Revine Lago",
    longitude: 12.23174589,
    latitude: 45.99209099,
  },
  {
    label: "Riese Pio X",
    longitude: 11.91677129,
    latitude: 45.72967365,
  },
  {
    label: "Roncade",
    longitude: 12.37395321,
    latitude: 45.63038435,
  },
  {
    label: "Salgareda",
    longitude: 12.4946761,
    latitude: 45.70342899,
  },
  {
    label: "San Biagio di Callalta",
    longitude: 12.37749251,
    latitude: 45.68476004,
  },
  {
    label: "San Fior",
    longitude: 12.35840806,
    latitude: 45.92083905,
  },
  {
    label: "San Pietro di Feletto",
    longitude: 12.25162708,
    latitude: 45.91304227,
  },
  {
    label: "San Polo di Piave",
    longitude: 12.3941508,
    latitude: 45.79118761,
  },
  {
    label: "Santa Lucia di Piave",
    longitude: 12.28427107,
    latitude: 45.84917801,
  },
  {
    label: "San Vendemiano",
    longitude: 12.33902208,
    latitude: 45.89125168,
  },
  {
    label: "San Zenone degli Ezzelini",
    longitude: 11.8403389,
    latitude: 45.77891765,
  },
  {
    label: "Sarmede",
    longitude: 12.38636097,
    latitude: 45.97850128,
  },
  {
    label: "Segusino",
    longitude: 11.95407027,
    latitude: 45.91669813,
  },
  {
    label: "Sernaglia della Battaglia",
    longitude: 12.13182515,
    latitude: 45.87276915,
  },
  {
    label: "Silea",
    longitude: 12.29875466,
    latitude: 45.65505223,
  },
  {
    label: "Spresiano",
    longitude: 12.25929856,
    latitude: 45.7794287,
  },
  {
    label: "Susegana",
    longitude: 12.25004471,
    latitude: 45.85089827,
  },
  {
    label: "Tarzo",
    longitude: 12.23217934,
    latitude: 45.97296664,
  },
  {
    label: "Trevignano",
    longitude: 12.08906536,
    latitude: 45.74265934,
  },
  {
    label: "Treviso",
    longitude: 12.24507364,
    latitude: 45.6675457,
  },
  {
    label: "Valdobbiadene",
    longitude: 11.99523685,
    latitude: 45.90129788,
  },
  {
    label: "Vazzola",
    longitude: 12.37918712,
    latitude: 45.83784071,
  },
  {
    label: "Vedelago",
    longitude: 12.01832113,
    latitude: 45.68707301,
  },
  {
    label: "Vidor",
    longitude: 12.03882696,
    latitude: 45.8634641,
  },
  {
    label: "Villorba",
    longitude: 12.26036446,
    latitude: 45.71286824,
  },
  {
    label: "Vittorio Veneto",
    longitude: 12.29614378,
    latitude: 45.98992454,
  },
  {
    label: "Volpago del Montello",
    longitude: 12.11866145,
    latitude: 45.77745214,
  },
  {
    label: "Zenson di Piave",
    longitude: 12.4926002,
    latitude: 45.67823917,
  },
  {
    label: "Zero Branco",
    longitude: 12.16410528,
    latitude: 45.59933512,
  },
  {
    label: "Annone Veneto",
    longitude: 12.6830159,
    latitude: 45.79437439,
  },
  {
    label: "Campagna Lupia",
    longitude: 12.09812525,
    latitude: 45.35344114,
  },
  {
    label: "Campolongo Maggiore",
    longitude: 12.04809236,
    latitude: 45.33141166,
  },
  {
    label: "Camponogara",
    longitude: 12.07261888,
    latitude: 45.38508648,
  },
  {
    label: "Caorle",
    longitude: 12.88762303,
    latitude: 45.59802295,
  },
  {
    label: "Cavarzere",
    longitude: 12.08371372,
    latitude: 45.13680407,
  },
  {
    label: "Ceggia",
    longitude: 12.63741526,
    latitude: 45.6863088,
  },
  {
    label: "Chioggia",
    longitude: 12.27943819,
    latitude: 45.22007077,
  },
  {
    label: "Cinto Caomaggiore",
    longitude: 12.78563953,
    latitude: 45.82576393,
  },
  {
    label: "Cona",
    longitude: 12.03912026,
    latitude: 45.19295736,
  },
  {
    label: "Concordia Sagittaria",
    longitude: 12.84598543,
    latitude: 45.75582222,
  },
  {
    label: "Dolo",
    longitude: 12.07432841,
    latitude: 45.42595888,
  },
  {
    label: "Eraclea",
    longitude: 12.67353295,
    latitude: 45.57551562,
  },
  {
    label: "Fiesso d'Artico",
    longitude: 12.03080558,
    latitude: 45.41657925,
  },
  {
    label: "Fossalta di Piave",
    longitude: 12.51122447,
    latitude: 45.64583019,
  },
  {
    label: "Fossalta di Portogruaro",
    longitude: 12.90745002,
    latitude: 45.79292789,
  },
  {
    label: "Fossò",
    longitude: 12.04802305,
    latitude: 45.38585799,
  },
  {
    label: "Gruaro",
    longitude: 12.84002601,
    latitude: 45.83072227,
  },
  {
    label: "Jesolo",
    longitude: 12.63646208,
    latitude: 45.53350167,
  },
  {
    label: "Marcon",
    longitude: 12.29936983,
    latitude: 45.55446676,
  },
  {
    label: "Martellago",
    longitude: 12.15989187,
    latitude: 45.54613274,
  },
  {
    label: "Meolo",
    longitude: 12.45265571,
    latitude: 45.61903318,
  },
  {
    label: "Mira",
    longitude: 12.12336744,
    latitude: 45.43288104,
  },
  {
    label: "Mirano",
    longitude: 12.10916956,
    latitude: 45.49292054,
  },
  {
    label: "Musile di Piave",
    longitude: 12.56333817,
    latitude: 45.62231014,
  },
  {
    label: "Noale",
    longitude: 12.07041016,
    latitude: 45.55055402,
  },
  {
    label: "Noventa di Piave",
    longitude: 12.52967597,
    latitude: 45.66286319,
  },
  {
    label: "Pianiga",
    longitude: 12.03004479,
    latitude: 45.45560048,
  },
  {
    label: "Portogruaro",
    longitude: 12.83740983,
    latitude: 45.77576572,
  },
  {
    label: "Pramaggiore",
    longitude: 12.7346179,
    latitude: 45.81219066,
  },
  {
    label: "Quarto d'Altino",
    longitude: 12.3714279,
    latitude: 45.58155434,
  },
  {
    label: "Salzano",
    longitude: 12.09771298,
    latitude: 45.52127265,
  },
  {
    label: "San Donà di Piave",
    longitude: 12.56428253,
    latitude: 45.62946343,
  },
  {
    label: "San Michele al Tagliamento",
    longitude: 12.99493968,
    latitude: 45.76387362,
  },
  {
    label: "Santa Maria di Sala",
    longitude: 12.03547005,
    latitude: 45.50475249,
  },
  {
    label: "San Stino di Livenza",
    longitude: 12.67956354,
    latitude: 45.73053829,
  },
  {
    label: "Scorzè",
    longitude: 12.10886652,
    latitude: 45.57204179,
  },
  {
    label: "Spinea",
    longitude: 12.16470092,
    latitude: 45.49171461,
  },
  {
    label: "Stra",
    longitude: 12.00488007,
    latitude: 45.40990815,
  },
  {
    label: "Teglio Veneto",
    longitude: 12.88432685,
    latitude: 45.81601799,
  },
  {
    label: "Torre di Mosto",
    longitude: 12.71063722,
    latitude: 45.69071804,
  },
  {
    label: "Venezia",
    longitude: 12.33845214,
    latitude: 45.43490484,
  },
  {
    label: "Vigonovo",
    longitude: 12.00774615,
    latitude: 45.38543454,
  },
  {
    label: "Cavallino-Treporti",
    longitude: 12.45371806,
    latitude: 45.4534756,
  },
  {
    label: "Abano Terme",
    longitude: 11.78871302,
    latitude: 45.35938697,
  },
  {
    label: "Agna",
    longitude: 11.95672802,
    latitude: 45.16977788,
  },
  {
    label: "Albignasego",
    longitude: 11.86837713,
    latitude: 45.34641419,
  },
  {
    label: "Anguillara Veneta",
    longitude: 11.8860307,
    latitude: 45.13873545,
  },
  {
    label: "Arquà Petrarca",
    longitude: 11.71639502,
    latitude: 45.27003806,
  },
  {
    label: "Arre",
    longitude: 11.93183887,
    latitude: 45.21609585,
  },
  {
    label: "Arzergrande",
    longitude: 12.04649593,
    latitude: 45.27341307,
  },
  {
    label: "Bagnoli di Sopra",
    longitude: 11.88137227,
    latitude: 45.18515279,
  },
  {
    label: "Baone",
    longitude: 11.68844419,
    latitude: 45.24424772,
  },
  {
    label: "Barbona",
    longitude: 11.66057999,
    latitude: 45.10656219,
  },
  {
    label: "Battaglia Terme",
    longitude: 11.77375044,
    latitude: 45.28889131,
  },
  {
    label: "Boara Pisani",
    longitude: 11.78347605,
    latitude: 45.10729174,
  },
  {
    label: "Borgoricco",
    longitude: 11.96850373,
    latitude: 45.53281751,
  },
  {
    label: "Bovolenta",
    longitude: 11.94209027,
    latitude: 45.26867717,
  },
  {
    label: "Brugine",
    longitude: 11.99269059,
    latitude: 45.29830159,
  },
  {
    label: "Cadoneghe",
    longitude: 11.91006573,
    latitude: 45.44779555,
  },
  {
    label: "Campodarsego",
    longitude: 11.90728136,
    latitude: 45.50230715,
  },
  {
    label: "Campodoro",
    longitude: 11.7516502,
    latitude: 45.49315894,
  },
  {
    label: "Camposampiero",
    longitude: 11.93198749,
    latitude: 45.57184005,
  },
  {
    label: "Campo San Martino",
    longitude: 11.81194645,
    latitude: 45.54785594,
  },
  {
    label: "Candiana",
    longitude: 11.99122715,
    latitude: 45.22040959,
  },
  {
    label: "Carceri",
    longitude: 11.62129294,
    latitude: 45.1956694,
  },
  {
    label: "Carmignano di Brenta",
    longitude: 11.7001609,
    latitude: 45.62875727,
  },
  {
    label: "Cartura",
    longitude: 11.86261057,
    latitude: 45.26813687,
  },
  {
    label: "Casale di Scodosia",
    longitude: 11.46636731,
    latitude: 45.18780161,
  },
  {
    label: "Casalserugo",
    longitude: 11.91480701,
    latitude: 45.31632022,
  },
  {
    label: "Castelbaldo",
    longitude: 11.45267984,
    latitude: 45.12113955,
  },
  {
    label: "Cervarese Santa Croce",
    longitude: 11.69190168,
    latitude: 45.40293414,
  },
  {
    label: "Cinto Euganeo",
    longitude: 11.66112122,
    latitude: 45.2913041,
  },
  {
    label: "Cittadella",
    longitude: 11.78365405,
    latitude: 45.64858355,
  },
  {
    label: "Codevigo",
    longitude: 12.10331958,
    latitude: 45.26795503,
  },
  {
    label: "Conselve",
    longitude: 11.87569415,
    latitude: 45.2322392,
  },
  {
    label: "Correzzola",
    longitude: 12.06709506,
    latitude: 45.23529705,
  },
  {
    label: "Curtarolo",
    longitude: 11.83222499,
    latitude: 45.52211798,
  },
  {
    label: "Este",
    longitude: 11.65604994,
    latitude: 45.22792066,
  },
  {
    label: "Fontaniva",
    longitude: 11.75286392,
    latitude: 45.63638349,
  },
  {
    label: "Galliera Veneta",
    longitude: 11.82944868,
    latitude: 45.6628177,
  },
  {
    label: "Galzignano Terme",
    longitude: 11.72825465,
    latitude: 45.30857934,
  },
  {
    label: "Gazzo",
    longitude: 11.7073364,
    latitude: 45.5822821,
  },
  {
    label: "Grantorto",
    longitude: 11.7310615,
    latitude: 45.5992761,
  },
  {
    label: "Granze",
    longitude: 11.71328468,
    latitude: 45.15430503,
  },
  {
    label: "Legnaro",
    longitude: 11.96356973,
    latitude: 45.34399238,
  },
  {
    label: "Limena",
    longitude: 11.84366036,
    latitude: 45.4789126,
  },
  {
    label: "Loreggia",
    longitude: 11.94403157,
    latitude: 45.58820049,
  },
  {
    label: "Lozzo Atestino",
    longitude: 11.63136512,
    latitude: 45.29111089,
  },
  {
    label: "Maserà di Padova",
    longitude: 11.86353019,
    latitude: 45.3201723,
  },
  {
    label: "Masi",
    longitude: 11.49174779,
    latitude: 45.10842207,
  },
  {
    label: "Massanzago",
    longitude: 12.00425748,
    latitude: 45.55531756,
  },
  {
    label: "Megliadino San Fidenzio",
    longitude: 11.51745765,
    latitude: 45.21759002,
  },
  {
    label: "Megliadino San Vitale",
    longitude: 11.52411812,
    latitude: 45.19236677,
  },
  {
    label: "Merlara",
    longitude: 11.44223809,
    latitude: 45.16698886,
  },
  {
    label: "Mestrino",
    longitude: 11.75878722,
    latitude: 45.44354385,
  },
  {
    label: "Monselice",
    longitude: 11.75011493,
    latitude: 45.23946775,
  },
  {
    label: "Montagnana",
    longitude: 11.46714863,
    latitude: 45.23198272,
  },
  {
    label: "Montegrotto Terme",
    longitude: 11.7836376,
    latitude: 45.33173579,
  },
  {
    label: "Noventa Padovana",
    longitude: 11.95288558,
    latitude: 45.41603363,
  },
  {
    label: "Ospedaletto Euganeo",
    longitude: 11.61058506,
    latitude: 45.22252696,
  },
  {
    label: "Padova",
    longitude: 11.87608719,
    latitude: 45.40692987,
  },
  {
    label: "Pernumia",
    longitude: 11.78693849,
    latitude: 45.25704645,
  },
  {
    label: "Piacenza d'Adige",
    longitude: 11.54716049,
    latitude: 45.12742532,
  },
  {
    label: "Piazzola sul Brenta",
    longitude: 11.78645309,
    latitude: 45.54173454,
  },
  {
    label: "Piombino Dese",
    longitude: 11.99850921,
    latitude: 45.60764299,
  },
  {
    label: "Piove di Sacco",
    longitude: 12.03515504,
    latitude: 45.29611168,
  },
  {
    label: "Polverara",
    longitude: 11.95740084,
    latitude: 45.31061473,
  },
  {
    label: "Ponso",
    longitude: 11.58635702,
    latitude: 45.19214526,
  },
  {
    label: "Pontelongo",
    longitude: 12.0191135,
    latitude: 45.25029105,
  },
  {
    label: "Ponte San Nicolò",
    longitude: 11.93065542,
    latitude: 45.36411624,
  },
  {
    label: "Pozzonovo",
    longitude: 11.79014771,
    latitude: 45.19825709,
  },
  {
    label: "Rovolon",
    longitude: 11.65216534,
    latitude: 45.38575243,
  },
  {
    label: "Rubano",
    longitude: 11.78660146,
    latitude: 45.4267064,
  },
  {
    label: "Saccolongo",
    longitude: 11.74356216,
    latitude: 45.40568166,
  },
  {
    label: "Saletto",
    longitude: 11.53501181,
    latitude: 45.22694636,
  },
  {
    label: "San Giorgio delle Pertiche",
    longitude: 11.9099252,
    latitude: 45.54200455,
  },
  {
    label: "San Giorgio in Bosco",
    longitude: 11.8065543,
    latitude: 45.59317253,
  },
  {
    label: "San Martino di Lupari",
    longitude: 11.853268,
    latitude: 45.65068255,
  },
  {
    label: "San Pietro in Gu",
    longitude: 11.66922503,
    latitude: 45.61349032,
  },
  {
    label: "San Pietro Viminario",
    longitude: 11.81834149,
    latitude: 45.24501944,
  },
  {
    label: "Santa Giustina in Colle",
    longitude: 11.90617658,
    latitude: 45.56364159,
  },
  {
    label: "Santa Margherita d'Adige",
    longitude: 11.55649018,
    latitude: 45.21462189,
  },
  {
    label: "Sant'Angelo di Piove di Sacco",
    longitude: 12.0077355,
    latitude: 45.3461566,
  },
  {
    label: "Sant'Elena",
    longitude: 11.70956584,
    latitude: 45.18616257,
  },
  {
    label: "Sant'Urbano",
    longitude: 11.61974875,
    latitude: 45.1133772,
  },
  {
    label: "Saonara",
    longitude: 11.9849181,
    latitude: 45.3719899,
  },
  {
    label: "Selvazzano Dentro",
    longitude: 11.78098097,
    latitude: 45.39451864,
  },
  {
    label: "Solesino",
    longitude: 11.74225417,
    latitude: 45.17773351,
  },
  {
    label: "Stanghella",
    longitude: 11.75641414,
    latitude: 45.13492987,
  },
  {
    label: "Teolo",
    longitude: 11.70863149,
    latitude: 45.36679217,
  },
  {
    label: "Terrassa Padovana",
    longitude: 11.90358026,
    latitude: 45.2454278,
  },
  {
    label: "Tombolo",
    longitude: 11.8252901,
    latitude: 45.6471918,
  },
  {
    label: "Torreglia",
    longitude: 11.72466311,
    latitude: 45.33602654,
  },
  {
    label: "Trebaseleghe",
    longitude: 12.05139711,
    latitude: 45.59161249,
  },
  {
    label: "Tribano",
    longitude: 11.83325679,
    latitude: 45.21060057,
  },
  {
    label: "Urbana",
    longitude: 11.44355514,
    latitude: 45.19085256,
  },
  {
    label: "Veggiano",
    longitude: 11.71371049,
    latitude: 45.44789092,
  },
  {
    label: "Vescovana",
    longitude: 11.70803646,
    latitude: 45.13395221,
  },
  {
    label: "Vighizzolo d'Este",
    longitude: 11.6237418,
    latitude: 45.17632694,
  },
  {
    label: "Vigodarzere",
    longitude: 11.87882777,
    latitude: 45.46062925,
  },
  {
    label: "Vigonza",
    longitude: 11.98306167,
    latitude: 45.4436389,
  },
  {
    label: "Villa del Conte",
    longitude: 11.85934739,
    latitude: 45.58349343,
  },
  {
    label: "Villa Estense",
    longitude: 11.66718671,
    latitude: 45.17219801,
  },
  {
    label: "Villafranca Padovana",
    longitude: 11.79716302,
    latitude: 45.49498769,
  },
  {
    label: "Villanova di Camposampiero",
    longitude: 11.97277231,
    latitude: 45.48964457,
  },
  {
    label: "Vo'",
    longitude: 11.64097889,
    latitude: 45.32854266,
  },
  {
    label: "Due Carrare",
    longitude: 11.82089803,
    latitude: 45.29153743,
  },
  {
    label: "Adria",
    longitude: 12.05644639,
    latitude: 45.05465533,
  },
  {
    label: "Ariano nel Polesine",
    longitude: 12.12108456,
    latitude: 44.94521256,
  },
  {
    label: "Arquà Polesine",
    longitude: 11.74136883,
    latitude: 45.00936992,
  },
  {
    label: "Badia Polesine",
    longitude: 11.49137703,
    latitude: 45.09431007,
  },
  {
    label: "Bagnolo di Po",
    longitude: 11.49996488,
    latitude: 45.01516346,
  },
  {
    label: "Bergantino",
    longitude: 11.25302072,
    latitude: 45.06037885,
  },
  {
    label: "Bosaro",
    longitude: 11.76454653,
    latitude: 45.00062333,
  },
  {
    label: "Calto",
    longitude: 11.35600087,
    latitude: 44.9916859,
  },
  {
    label: "Canaro",
    longitude: 11.67653246,
    latitude: 44.93534554,
  },
  {
    label: "Canda",
    longitude: 11.50858838,
    latitude: 45.03390304,
  },
  {
    label: "Castelguglielmo",
    longitude: 11.53728106,
    latitude: 45.02570002,
  },
  {
    label: "Castelmassa",
    longitude: 11.31040392,
    latitude: 45.01768746,
  },
  {
    label: "Castelnovo Bariano",
    longitude: 11.28626445,
    latitude: 45.02669385,
  },
  {
    label: "Ceneselli",
    longitude: 11.3694204,
    latitude: 45.01308419,
  },
  {
    label: "Ceregnano",
    longitude: 11.87058022,
    latitude: 45.05007482,
  },
  {
    label: "Corbola",
    longitude: 12.0774246,
    latitude: 45.005632,
  },
  {
    label: "Costa di Rovigo",
    longitude: 11.69407504,
    latitude: 45.04881045,
  },
  {
    label: "Crespino",
    longitude: 11.88644751,
    latitude: 44.98332247,
  },
  {
    label: "Ficarolo",
    longitude: 11.43256055,
    latitude: 44.9519754,
  },
  {
    label: "Fiesso Umbertiano",
    longitude: 11.60785403,
    latitude: 44.9617601,
  },
  {
    label: "Frassinelle Polesine",
    longitude: 11.69769786,
    latitude: 44.99672352,
  },
  {
    label: "Fratta Polesine",
    longitude: 11.64100037,
    latitude: 45.03168685,
  },
  {
    label: "Gaiba",
    longitude: 11.48159726,
    latitude: 44.9482981,
  },
  {
    label: "Gavello",
    longitude: 11.91423793,
    latitude: 45.02151244,
  },
  {
    label: "Giacciano con Baruchella",
    longitude: 11.45064279,
    latitude: 45.06536475,
  },
  {
    label: "Guarda Veneta",
    longitude: 11.8026365,
    latitude: 44.97979308,
  },
  {
    label: "Lendinara",
    longitude: 11.59868789,
    latitude: 45.08346989,
  },
  {
    label: "Loreo",
    longitude: 12.18923031,
    latitude: 45.06360111,
  },
  {
    label: "Lusia",
    longitude: 11.66301877,
    latitude: 45.10104177,
  },
  {
    label: "Melara",
    longitude: 11.19772995,
    latitude: 45.06184838,
  },
  {
    label: "Occhiobello",
    longitude: 11.57945086,
    latitude: 44.91937113,
  },
  {
    label: "Papozze",
    longitude: 12.03106078,
    latitude: 44.98786848,
  },
  {
    label: "Pettorazza Grimani",
    longitude: 11.98879519,
    latitude: 45.13602119,
  },
  {
    label: "Pincara",
    longitude: 11.61154196,
    latitude: 44.99305255,
  },
  {
    label: "Polesella",
    longitude: 11.75509475,
    latitude: 44.96254896,
  },
  {
    label: "Pontecchio Polesine",
    longitude: 11.81156731,
    latitude: 45.019166,
  },
  {
    label: "Porto Tolle",
    longitude: 12.33457629,
    latitude: 44.95253376,
  },
  {
    label: "Rosolina",
    longitude: 12.2461296,
    latitude: 45.07588808,
  },
  {
    label: "Rovigo",
    longitude: 11.79007,
    latitude: 45.07107288,
  },
  {
    label: "Salara",
    longitude: 11.42607427,
    latitude: 44.98422166,
  },
  {
    label: "San Bellino",
    longitude: 11.58903837,
    latitude: 45.02849154,
  },
  {
    label: "San Martino di Venezze",
    longitude: 11.86852699,
    latitude: 45.13130764,
  },
  {
    label: "Stienta",
    longitude: 11.54403684,
    latitude: 44.93820004,
  },
  {
    label: "Taglio di Po",
    longitude: 12.20916834,
    latitude: 45.0080457,
  },
  {
    label: "Trecenta",
    longitude: 11.46046156,
    latitude: 45.03086142,
  },
  {
    label: "Villadose",
    longitude: 11.88966857,
    latitude: 45.07367115,
  },
  {
    label: "Villamarzana",
    longitude: 11.69227409,
    latitude: 45.01096153,
  },
  {
    label: "Villanova del Ghebbo",
    longitude: 11.64439455,
    latitude: 45.0556132,
  },
  {
    label: "Villanova Marchesana",
    longitude: 11.96702351,
    latitude: 44.99125704,
  },
  {
    label: "Porto Viro",
    longitude: 12.21811845,
    latitude: 45.02597454,
  },
  {
    label: "Aiello del Friuli",
    longitude: 13.36246488,
    latitude: 45.87068299,
  },
  {
    label: "Amaro",
    longitude: 13.09474064,
    latitude: 46.3736167,
  },
  {
    label: "Ampezzo",
    longitude: 12.79457813,
    latitude: 46.41666451,
  },
  {
    label: "Aquileia",
    longitude: 13.36493966,
    latitude: 45.76643933,
  },
  {
    label: "Arta Terme",
    longitude: 13.02486016,
    latitude: 46.47288214,
  },
  {
    label: "Artegna",
    longitude: 13.1559816,
    latitude: 46.2418532,
  },
  {
    label: "Attimis",
    longitude: 13.30511815,
    latitude: 46.18881181,
  },
  {
    label: "Bagnaria Arsa",
    longitude: 13.30170021,
    latitude: 45.8874056,
  },
  {
    label: "Basiliano",
    longitude: 13.1066967,
    latitude: 46.0161983,
  },
  {
    label: "Bertiolo",
    longitude: 13.0553148,
    latitude: 45.94329723,
  },
  {
    label: "Bicinicco",
    longitude: 13.24990756,
    latitude: 45.93099725,
  },
  {
    label: "Bordano",
    longitude: 13.10601048,
    latitude: 46.315382,
  },
  {
    label: "Buja",
    longitude: 13.12574372,
    latitude: 46.20892856,
  },
  {
    label: "Buttrio",
    longitude: 13.33039931,
    latitude: 46.0130863,
  },
  {
    label: "Camino al Tagliamento",
    longitude: 12.9446488,
    latitude: 45.92744556,
  },
  {
    label: "Campoformido",
    longitude: 13.16004808,
    latitude: 46.01983457,
  },
  {
    label: "Carlino",
    longitude: 13.18810617,
    latitude: 45.80227999,
  },
  {
    label: "Cassacco",
    longitude: 13.1861074,
    latitude: 46.17384978,
  },
  {
    label: "Castions di Strada",
    longitude: 13.18486007,
    latitude: 45.90844801,
  },
  {
    label: "Cavazzo Carnico",
    longitude: 13.04045787,
    latitude: 46.36768379,
  },
  {
    label: "Cercivento",
    longitude: 12.98772245,
    latitude: 46.52713434,
  },
  {
    label: "Cervignano del Friuli",
    longitude: 13.33441517,
    latitude: 45.82326739,
  },
  {
    label: "Chiopris-Viscone",
    longitude: 13.40249962,
    latitude: 45.92451986,
  },
  {
    label: "Chiusaforte",
    longitude: 13.31048694,
    latitude: 46.40822245,
  },
  {
    label: "Cividale del Friuli",
    longitude: 13.43202825,
    latitude: 46.09357444,
  },
  {
    label: "Codroipo",
    longitude: 12.97693057,
    latitude: 45.96181162,
  },
  {
    label: "Colloredo di Monte Albano",
    longitude: 13.13796575,
    latitude: 46.16600585,
  },
  {
    label: "Comeglians",
    longitude: 12.86791668,
    latitude: 46.51358001,
  },
  {
    label: "Corno di Rosazzo",
    longitude: 13.4416842,
    latitude: 45.9968218,
  },
  {
    label: "Coseano",
    longitude: 13.01868635,
    latitude: 46.09680741,
  },
  {
    label: "Dignano",
    longitude: 12.93863788,
    latitude: 46.08769436,
  },
  {
    label: "Dogna",
    longitude: 13.31540594,
    latitude: 46.44804944,
  },
  {
    label: "Drenchia",
    longitude: 13.62206703,
    latitude: 46.17516991,
  },
  {
    label: "Enemonzo",
    longitude: 12.87934307,
    latitude: 46.41030448,
  },
  {
    label: "Faedis",
    longitude: 13.34411062,
    latitude: 46.15244221,
  },
  {
    label: "Fagagna",
    longitude: 13.08393536,
    latitude: 46.11325942,
  },
  {
    label: "Fiumicello",
    longitude: 13.40948596,
    latitude: 45.79066418,
  },
  {
    label: "Flaibano",
    longitude: 12.98114309,
    latitude: 46.06005178,
  },
  {
    label: "Forni Avoltri",
    longitude: 12.77691062,
    latitude: 46.58517936,
  },
  {
    label: "Forni di Sopra",
    longitude: 12.57845506,
    latitude: 46.42381739,
  },
  {
    label: "Forni di Sotto",
    longitude: 12.67180848,
    latitude: 46.39363187,
  },
  {
    label: "Gemona del Friuli",
    longitude: 13.1397359,
    latitude: 46.27700513,
  },
  {
    label: "Gonars",
    longitude: 13.23507614,
    latitude: 45.8965683,
  },
  {
    label: "Grimacco",
    longitude: 13.5933851,
    latitude: 46.15641912,
  },
  {
    label: "latitudeisana",
    longitude: 12.99529474,
    latitude: 45.77595742,
  },
  {
    label: "Lauco",
    longitude: 12.93416713,
    latitude: 46.42340881,
  },
  {
    label: "Lestizza",
    longitude: 13.14084214,
    latitude: 45.95760766,
  },
  {
    label: "Lignano Sabbiadoro",
    longitude: 13.12981973,
    latitude: 45.68825007,
  },
  {
    label: "Ligosullo",
    longitude: 13.07310636,
    latitude: 46.53899194,
  },
  {
    label: "Lusevera",
    longitude: 13.26040118,
    latitude: 46.2646838,
  },
  {
    label: "Magnano in Riviera",
    longitude: 13.17659877,
    latitude: 46.22962657,
  },
  {
    label: "Majano",
    longitude: 13.07553655,
    latitude: 46.18536637,
  },
  {
    label: "Malborghetto Valbruna",
    longitude: 13.44002065,
    latitude: 46.50676576,
  },
  {
    label: "Manzano",
    longitude: 13.38479712,
    latitude: 45.98999113,
  },
  {
    label: "Marano Lagunare",
    longitude: 13.16762312,
    latitude: 45.76717763,
  },
  {
    label: "Martignacco",
    longitude: 13.13039277,
    latitude: 46.09820704,
  },
  {
    label: "Mereto di Tomba",
    longitude: 13.047841,
    latitude: 46.05112492,
  },
  {
    label: "Moggio Udinese",
    longitude: 13.19492346,
    latitude: 46.40991503,
  },
  {
    label: "Moimacco",
    longitude: 13.38114127,
    latitude: 46.09167091,
  },
  {
    label: "Montenars",
    longitude: 13.18037225,
    latitude: 46.25672653,
  },
  {
    label: "Mortegliano",
    longitude: 13.172092,
    latitude: 45.94489201,
  },
  {
    label: "Moruzzo",
    longitude: 13.12372436,
    latitude: 46.11968491,
  },
  {
    label: "Muzzana del Turgnano",
    longitude: 13.127795,
    latitude: 45.8174304,
  },
  {
    label: "Nimis",
    longitude: 13.2653077,
    latitude: 46.20033621,
  },
  {
    label: "Osoppo",
    longitude: 13.08076343,
    latitude: 46.25421306,
  },
  {
    label: "Ovaro",
    longitude: 12.86519153,
    latitude: 46.48425676,
  },
  {
    label: "Pagnacco",
    longitude: 13.18811705,
    latitude: 46.12418413,
  },
  {
    label: "Palazzolo dello Stella",
    longitude: 13.07946068,
    latitude: 45.80437445,
  },
  {
    label: "Palmanova",
    longitude: 13.30986713,
    latitude: 45.90475787,
  },
  {
    label: "Paluzza",
    longitude: 13.01543349,
    latitude: 46.52895043,
  },
  {
    label: "Pasian di Prato",
    longitude: 13.19079537,
    latitude: 46.04924747,
  },
  {
    label: "Paularo",
    longitude: 13.11669413,
    latitude: 46.5303216,
  },
  {
    label: "Pavia di Udine",
    longitude: 13.28121731,
    latitude: 45.97819483,
  },
  {
    label: "Pocenia",
    longitude: 13.09849237,
    latitude: 45.83722792,
  },
  {
    label: "Pontebba",
    longitude: 13.30383638,
    latitude: 46.50664097,
  },
  {
    label: "Porpetto",
    longitude: 13.21546521,
    latitude: 45.85923431,
  },
  {
    label: "Povoletto",
    longitude: 13.2984561,
    latitude: 46.11810466,
  },
  {
    label: "Pozzuolo del Friuli",
    longitude: 13.19656731,
    latitude: 45.98408187,
  },
  {
    label: "Pradamano",
    longitude: 13.29942757,
    latitude: 46.03124656,
  },
  {
    label: "Prato Carnico",
    longitude: 12.79765758,
    latitude: 46.52015181,
  },
  {
    label: "Precenicco",
    longitude: 13.07870988,
    latitude: 45.78971199,
  },
  {
    label: "Premariacco",
    longitude: 13.39425309,
    latitude: 46.05896873,
  },
  {
    label: "Preone",
    longitude: 12.86798438,
    latitude: 46.39518497,
  },
  {
    label: "Prepotto",
    longitude: 13.47948784,
    latitude: 46.04592821,
  },
  {
    label: "Pulfero",
    longitude: 13.4842703,
    latitude: 46.17324022,
  },
  {
    label: "Ragogna",
    longitude: 12.97822739,
    latitude: 46.1771286,
  },
  {
    label: "Ravascletto",
    longitude: 12.92222794,
    latitude: 46.52562321,
  },
  {
    label: "Raveo",
    longitude: 12.87001247,
    latitude: 46.43469374,
  },
  {
    label: "Reana del Rojale",
    longitude: 13.24368407,
    latitude: 46.1493562,
  },
  {
    label: "Remanzacco",
    longitude: 13.32478658,
    latitude: 46.08524186,
  },
  {
    label: "Resia",
    longitude: 13.30575122,
    latitude: 46.37347365,
  },
  {
    label: "Resiutta",
    longitude: 13.2200989,
    latitude: 46.39323412,
  },
  {
    label: "Rigolatitudeo",
    longitude: 12.85406953,
    latitude: 46.54959532,
  },
  {
    label: "Rive d'Arcano",
    longitude: 13.03098045,
    latitude: 46.12595201,
  },
  {
    label: "Ronchis",
    longitude: 12.99607394,
    latitude: 45.80581421,
  },
  {
    label: "Ruda",
    longitude: 13.39969411,
    latitude: 45.83697676,
  },
  {
    label: "San Daniele del Friuli",
    longitude: 13.01132516,
    latitude: 46.16097487,
  },
  {
    label: "San Giorgio di Nogaro",
    longitude: 13.20699084,
    latitude: 45.83302124,
  },
  {
    label: "San Giovanni al Natisone",
    longitude: 13.40396207,
    latitude: 45.98091614,
  },
  {
    label: "San Leonardo",
    longitude: 13.52451558,
    latitude: 46.12166606,
  },
  {
    label: "San Pietro al Natisone",
    longitude: 13.48542522,
    latitude: 46.12758925,
  },
  {
    label: "Santa Maria la Longa",
    longitude: 13.28860792,
    latitude: 45.93307138,
  },
  {
    label: "San Vito al Torre",
    longitude: 13.37020873,
    latitude: 45.89570974,
  },
  {
    label: "San Vito di Fagagna",
    longitude: 13.05693569,
    latitude: 46.08820128,
  },
  {
    label: "Sauris",
    longitude: 12.70812853,
    latitude: 46.46663779,
  },
  {
    label: "Savogna",
    longitude: 13.53369569,
    latitude: 46.15970716,
  },
  {
    label: "Sedegliano",
    longitude: 12.97606963,
    latitude: 46.0134924,
  },
  {
    label: "Socchieve",
    longitude: 12.82351225,
    latitude: 46.40335997,
  },
  {
    label: "Stregna",
    longitude: 13.57794183,
    latitude: 46.12693729,
  },
  {
    label: "Sutrio",
    longitude: 12.98993413,
    latitude: 46.51228013,
  },
  {
    label: "Taipana",
    longitude: 13.34525708,
    latitude: 46.25300548,
  },
  {
    label: "Talmassons",
    longitude: 13.11591406,
    latitude: 45.92966569,
  },
  {
    label: "Tarcento",
    longitude: 13.2219988,
    latitude: 46.21555718,
  },
  {
    label: "Tarvisio",
    longitude: 13.57331932,
    latitude: 46.50611417,
  },
  {
    label: "Tavagnacco",
    longitude: 13.21480412,
    latitude: 46.10113315,
  },
  {
    label: "Terzo d'Aquileia",
    longitude: 13.34633364,
    latitude: 45.80060701,
  },
  {
    label: "Tolmezzo",
    longitude: 13.01488425,
    latitude: 46.40578211,
  },
  {
    label: "Torreano",
    longitude: 13.43248143,
    latitude: 46.13020551,
  },
  {
    label: "Torviscosa",
    longitude: 13.27460478,
    latitude: 45.82348256,
  },
  {
    label: "Trasaghis",
    longitude: 13.07538131,
    latitude: 46.28235573,
  },
  {
    label: "Treppo Carnico",
    longitude: 13.04237979,
    latitude: 46.53343245,
  },
  {
    label: "Treppo Grande",
    longitude: 13.15702145,
    latitude: 46.1909768,
  },
  {
    label: "Tricesimo",
    longitude: 13.21252411,
    latitude: 46.1622232,
  },
  {
    label: "Trivignano Udinese",
    longitude: 13.34749236,
    latitude: 45.94148508,
  },
  {
    label: "Udine",
    longitude: 13.23483835,
    latitude: 46.06255516,
  },
  {
    label: "Varmo",
    longitude: 12.98815313,
    latitude: 45.88673763,
  },
  {
    label: "Venzone",
    longitude: 13.13901777,
    latitude: 46.33369092,
  },
  {
    label: "Verzegnis",
    longitude: 12.99356605,
    latitude: 46.38960903,
  },
  {
    label: "Villa Santina",
    longitude: 12.92401346,
    latitude: 46.41546059,
  },
  {
    label: "Villa Vicentina",
    longitude: 13.39372817,
    latitude: 45.81660921,
  },
  {
    label: "Visco",
    longitude: 13.34787914,
    latitude: 45.89153902,
  },
  {
    label: "Zuglio",
    longitude: 13.02642844,
    latitude: 46.4609536,
  },
  {
    label: "Forgaria nel Friuli",
    longitude: 12.96819898,
    latitude: 46.22399223,
  },
  {
    label: "Campolongo Tapogliano",
    longitude: 13.39404557,
    latitude: 45.8643292,
  },
  {
    label: "Rivignano Teor",
    longitude: 13.0526,
    latitude: 45.8676,
  },
  {
    label: "Capriva del Friuli",
    longitude: 13.51459288,
    latitude: 45.94210858,
  },
  {
    label: "Cormons",
    longitude: 13.473645,
    latitude: 45.96065793,
  },
  {
    label: "Doberdò del Lago",
    longitude: 13.54022608,
    latitude: 45.84451526,
  },
  {
    label: "Dolegna del Collio",
    longitude: 13.47925922,
    latitude: 46.03170197,
  },
  {
    label: "Farra d'Isonzo",
    longitude: 13.51793613,
    latitude: 45.90713241,
  },
  {
    label: "Fogliano Redipuglia",
    longitude: 13.48097696,
    latitude: 45.86652567,
  },
  {
    label: "Gorizia",
    longitude: 13.62212511,
    latitude: 45.94149817,
  },
  {
    label: "Gradisca d'Isonzo",
    longitude: 13.50376194,
    latitude: 45.88888504,
  },
  {
    label: "Grado",
    longitude: 13.38646638,
    latitude: 45.67546729,
  },
  {
    label: "Mariano del Friuli",
    longitude: 13.46110441,
    latitude: 45.91670852,
  },
  {
    label: "Medea",
    longitude: 13.42393366,
    latitude: 45.91670305,
  },
  {
    label: "Monfalcone",
    longitude: 13.53264669,
    latitude: 45.8092332,
  },
  {
    label: "Moraro",
    longitude: 13.4952863,
    latitude: 45.93022135,
  },
  {
    label: "Mossa",
    longitude: 13.54789689,
    latitude: 45.9384312,
  },
  {
    label: "Romans d'Isonzo",
    longitude: 13.43903628,
    latitude: 45.89048678,
  },
  {
    label: "Ronchi dei Legionari",
    longitude: 13.50071104,
    latitude: 45.82600297,
  },
  {
    label: "Sagrado",
    longitude: 13.48520992,
    latitude: 45.87646345,
  },
  {
    label: "San Canzian d'Isonzo",
    longitude: 13.44340001,
    latitude: 45.80955159,
  },
  {
    label: "San Floriano del Collio",
    longitude: 13.58532999,
    latitude: 45.98220053,
  },
  {
    label: "San Lorenzo Isontino",
    longitude: 13.52672146,
    latitude: 45.93269205,
  },
  {
    label: "San Pier d'Isonzo",
    longitude: 13.46066353,
    latitude: 45.84594738,
  },
  {
    label: "Savogna d'Isonzo",
    longitude: 13.57096878,
    latitude: 45.90174446,
  },
  {
    label: "Staranzano",
    longitude: 13.50027729,
    latitude: 45.80550919,
  },
  {
    label: "Turriaco",
    longitude: 13.44481913,
    latitude: 45.82088729,
  },
  {
    label: "Villesse",
    longitude: 13.43813261,
    latitude: 45.85978499,
  },
  {
    label: "Duino-Aurisina",
    longitude: 13.65969344,
    latitude: 45.75423105,
  },
  {
    label: "Monrupino",
    longitude: 13.80115596,
    latitude: 45.71887272,
  },
  {
    label: "Muggia",
    longitude: 13.76729852,
    latitude: 45.60439421,
  },
  {
    label: "San Dorligo della Valle-Dolina",
    longitude: 13.85675254,
    latitude: 45.60794211,
  },
  {
    label: "Sgonico",
    longitude: 13.7495744,
    latitude: 45.73657771,
  },
  {
    label: "Trieste",
    longitude: 13.7681366,
    latitude: 45.6494354,
  },
  {
    label: "Agazzano",
    longitude: 9.52061417,
    latitude: 44.94680696,
  },
  {
    label: "Alseno",
    longitude: 9.96455314,
    latitude: 44.89633345,
  },
  {
    label: "Besenzone",
    longitude: 9.95361533,
    latitude: 44.98756521,
  },
  {
    label: "Bettola",
    longitude: 9.60537884,
    latitude: 44.77616157,
  },
  {
    label: "Bobbio",
    longitude: 9.3866028,
    latitude: 44.76640748,
  },
  {
    label: "Borgonovo Val Tidone",
    longitude: 9.44685905,
    latitude: 45.01803598,
  },
  {
    label: "Cadeo",
    longitude: 9.85223652,
    latitude: 44.96546213,
  },
  {
    label: "Calendasco",
    longitude: 9.59382034,
    latitude: 45.08773797,
  },
  {
    label: "Caminata",
    longitude: 9.31032396,
    latitude: 44.90967943,
  },
  {
    label: "Caorso",
    longitude: 9.87192947,
    latitude: 45.04960632,
  },
  {
    label: "Carpaneto Piacentino",
    longitude: 9.78844912,
    latitude: 44.91504872,
  },
  {
    label: "Castell'Arquato",
    longitude: 9.86760437,
    latitude: 44.85119746,
  },
  {
    label: "Castel San Giovanni",
    longitude: 9.43404574,
    latitude: 45.058522,
  },
  {
    label: "Castelvetro Piacentino",
    longitude: 9.98802767,
    latitude: 45.09665508,
  },
  {
    label: "Cerignale",
    longitude: 9.35096916,
    latitude: 44.67749339,
  },
  {
    label: "Coli",
    longitude: 9.41282471,
    latitude: 44.74342184,
  },
  {
    label: "Corte Brugnatella",
    longitude: 9.37972249,
    latitude: 44.7124657,
  },
  {
    label: "Cortemaggiore",
    longitude: 9.93102887,
    latitude: 44.99676617,
  },
  {
    label: "Farini",
    longitude: 9.57071362,
    latitude: 44.71333632,
  },
  {
    label: "Ferriere",
    longitude: 9.4980816,
    latitude: 44.64566827,
  },
  {
    label: "Fiorenzuola d'Arda",
    longitude: 9.90753067,
    latitude: 44.92865482,
  },
  {
    label: "Gazzola",
    longitude: 9.54713497,
    latitude: 44.95930024,
  },
  {
    label: "Gossolengo",
    longitude: 9.61405155,
    latitude: 45.00440954,
  },
  {
    label: "Gragnano Trebbiense",
    longitude: 9.56911828,
    latitude: 45.01213126,
  },
  {
    label: "Gropparello",
    longitude: 9.73032984,
    latitude: 44.83228479,
  },
  {
    label: "Lugagnano Val d'Arda",
    longitude: 9.82645377,
    latitude: 44.82253469,
  },
  {
    label: "Monticelli d'Ongina",
    longitude: 9.93326726,
    latitude: 45.08985171,
  },
  {
    label: "Morfasso",
    longitude: 9.70325197,
    latitude: 44.72166972,
  },
  {
    label: "Nibbiano",
    longitude: 9.32712464,
    latitude: 44.90527366,
  },
  {
    label: "Ottone",
    longitude: 9.33211607,
    latitude: 44.6224158,
  },
  {
    label: "Pecorara",
    longitude: 9.3836234,
    latitude: 44.87549564,
  },
  {
    label: "Piacenza",
    longitude: 9.6926326,
    latitude: 45.05193461,
  },
  {
    label: "Pianello Val Tidone",
    longitude: 9.40478819,
    latitude: 44.94837468,
  },
  {
    label: "Piozzano",
    longitude: 9.49436594,
    latitude: 44.92540331,
  },
  {
    label: "Podenzano",
    longitude: 9.68530999,
    latitude: 44.95486441,
  },
  {
    label: "Ponte dell'Olio",
    longitude: 9.64285028,
    latitude: 44.86789793,
  },
  {
    label: "Pontenure",
    longitude: 9.78615007,
    latitude: 44.99963969,
  },
  {
    label: "Rivergaro",
    longitude: 9.597564,
    latitude: 44.91070815,
  },
  {
    label: "Rottofreno",
    longitude: 9.55041345,
    latitude: 45.05704553,
  },
  {
    label: "San Giorgio Piacentino",
    longitude: 9.73755104,
    latitude: 44.95411196,
  },
  {
    label: "San Pietro in Cerro",
    longitude: 9.9513817,
    latitude: 45.02132785,
  },
  {
    label: "Sarmato",
    longitude: 9.49148647,
    latitude: 45.06041673,
  },
  {
    label: "Travo",
    longitude: 9.54283789,
    latitude: 44.86103734,
  },
  {
    label: "Vernasca",
    longitude: 9.8295902,
    latitude: 44.79992889,
  },
  {
    label: "Vigolzone",
    longitude: 9.66979001,
    latitude: 44.91152219,
  },
  {
    label: "Villanova sull'Arda",
    longitude: 9.99741768,
    latitude: 45.02582296,
  },
  {
    label: "Zerba",
    longitude: 9.28770811,
    latitude: 44.66561656,
  },
  {
    label: "Ziano Piacentino",
    longitude: 9.40263763,
    latitude: 45.00223085,
  },
  {
    label: "Albareto",
    longitude: 9.70052425,
    latitude: 44.44581523,
  },
  {
    label: "Bardi",
    longitude: 9.73199489,
    latitude: 44.63281298,
  },
  {
    label: "Bedonia",
    longitude: 9.63131421,
    latitude: 44.50349971,
  },
  {
    label: "Berceto",
    longitude: 9.98940249,
    latitude: 44.50952856,
  },
  {
    label: "Bore",
    longitude: 9.79435653,
    latitude: 44.71900964,
  },
  {
    label: "Borgo Val di Taro",
    longitude: 9.76766726,
    latitude: 44.48803032,
  },
  {
    label: "Busseto",
    longitude: 10.04132309,
    latitude: 44.98132896,
  },
  {
    label: "Calestano",
    longitude: 10.12214913,
    latitude: 44.60066532,
  },
  {
    label: "Collecchio",
    longitude: 10.21579629,
    latitude: 44.75280057,
  },
  {
    label: "Colorno",
    longitude: 10.37564974,
    latitude: 44.92879525,
  },
  {
    label: "Compiano",
    longitude: 9.66242528,
    latitude: 44.49596295,
  },
  {
    label: "Corniglio",
    longitude: 10.08868709,
    latitude: 44.47620016,
  },
  {
    label: "Felino",
    longitude: 10.24072079,
    latitude: 44.69552364,
  },
  {
    label: "Fidenza",
    longitude: 10.0614143,
    latitude: 44.86708803,
  },
  {
    label: "Fontanellatitudeo",
    longitude: 10.17341646,
    latitude: 44.8824621,
  },
  {
    label: "Fontevivo",
    longitude: 10.17537802,
    latitude: 44.85642698,
  },
  {
    label: "Fornovo di Taro",
    longitude: 10.09872874,
    latitude: 44.68925506,
  },
  {
    label: "Langhirano",
    longitude: 10.26642403,
    latitude: 44.61445195,
  },
  {
    label: "Lesignano de' Bagni",
    longitude: 10.301662,
    latitude: 44.64403405,
  },
  {
    label: "Medesano",
    longitude: 10.13996119,
    latitude: 44.75681741,
  },
  {
    label: "Mezzani",
    longitude: 10.43590236,
    latitude: 44.92278554,
  },
  {
    label: "Monchio delle Corti",
    longitude: 10.12320469,
    latitude: 44.41237474,
  },
  {
    label: "Montechiarugolo",
    longitude: 10.42246193,
    latitude: 44.69357882,
  },
  {
    label: "Neviano degli Arduini",
    longitude: 10.31775249,
    latitude: 44.58365252,
  },
  {
    label: "Noceto",
    longitude: 10.17719303,
    latitude: 44.8102618,
  },
  {
    label: "Palanzano",
    longitude: 10.19240365,
    latitude: 44.43535314,
  },
  {
    label: "Parma",
    longitude: 10.32834985,
    latitude: 44.80107394,
  },
  {
    label: "Pellegrino Parmense",
    longitude: 9.92683827,
    latitude: 44.73332758,
  },
  {
    label: "Roccabianca",
    longitude: 10.21812063,
    latitude: 45.00811844,
  },
  {
    label: "Sala Baganza",
    longitude: 10.2282385,
    latitude: 44.71552513,
  },
  {
    label: "Salsomaggiore Terme",
    longitude: 9.97809444,
    latitude: 44.81568328,
  },
  {
    label: "San Secondo Parmense",
    longitude: 10.22690616,
    latitude: 44.91915259,
  },
  {
    label: "Solignano",
    longitude: 9.97940192,
    latitude: 44.61511003,
  },
  {
    label: "Soragna",
    longitude: 10.12232256,
    latitude: 44.92689728,
  },
  {
    label: "Sorbolo",
    longitude: 10.4483477,
    latitude: 44.84647613,
  },
  {
    label: "Terenzo",
    longitude: 10.08763674,
    latitude: 44.61000551,
  },
  {
    label: "Tizzano Val Parma",
    longitude: 10.19893974,
    latitude: 44.52085941,
  },
  {
    label: "Tornolo",
    longitude: 9.62448869,
    latitude: 44.48491075,
  },
  {
    label: "Torrile",
    longitude: 10.35507653,
    latitude: 44.89060669,
  },
  {
    label: "Traversetolo",
    longitude: 10.38163949,
    latitude: 44.63974916,
  },
  {
    label: "Valmozzola",
    longitude: 9.88488216,
    latitude: 44.5682637,
  },
  {
    label: "Varano de' Melegari",
    longitude: 10.01428207,
    latitude: 44.68707407,
  },
  {
    label: "Varsi",
    longitude: 9.84568279,
    latitude: 44.66291223,
  },
  {
    label: "Sissa Trecasali",
    longitude: 10.2591,
    latitude: 44.9583,
  },
  {
    label: "Polesine Zibello",
    longitude: 10.1307,
    latitude: 45.0194,
  },
  {
    label: "Albinea",
    longitude: 10.60205897,
    latitude: 44.61962564,
  },
  {
    label: "Bagnolo in Piano",
    longitude: 10.67290388,
    latitude: 44.76142985,
  },
  {
    label: "Baiso",
    longitude: 10.59879329,
    latitude: 44.49525048,
  },
  {
    label: "Bibbiano",
    longitude: 10.47336979,
    latitude: 44.66326108,
  },
  {
    label: "Boretto",
    longitude: 10.55344135,
    latitude: 44.90453732,
  },
  {
    label: "Brescello",
    longitude: 10.51601194,
    latitude: 44.90153576,
  },
  {
    label: "Cadelbosco di Sopra",
    longitude: 10.59438134,
    latitude: 44.76292885,
  },
  {
    label: "Campagnola Emilia",
    longitude: 10.75917028,
    latitude: 44.84131327,
  },
  {
    label: "Campegine",
    longitude: 10.53111576,
    latitude: 44.78138282,
  },
  {
    label: "Carpineti",
    longitude: 10.51954928,
    latitude: 44.45468453,
  },
  {
    label: "Casalgrande",
    longitude: 10.73926724,
    latitude: 44.58987354,
  },
  {
    label: "Casina",
    longitude: 10.50046464,
    latitude: 44.51163601,
  },
  {
    label: "Castellarano",
    longitude: 10.73064321,
    latitude: 44.50955318,
  },
  {
    label: "Castelnovo di Sotto",
    longitude: 10.56368003,
    latitude: 44.81160229,
  },
  {
    label: "Castelnovo ne' Monti",
    longitude: 10.4032633,
    latitude: 44.4374874,
  },
  {
    label: "Cavriago",
    longitude: 10.52673362,
    latitude: 44.69485856,
  },
  {
    label: "Canossa",
    longitude: 10.40841827,
    latitude: 44.59250139,
  },
  {
    label: "Correggio",
    longitude: 10.78121506,
    latitude: 44.7713424,
  },
  {
    label: "Fabbrico",
    longitude: 10.80950419,
    latitude: 44.87159776,
  },
  {
    label: "Gattatico",
    longitude: 10.47375082,
    latitude: 44.80622594,
  },
  {
    label: "Gualtieri",
    longitude: 10.62949227,
    latitude: 44.90496117,
  },
  {
    label: "Guastalla",
    longitude: 10.65403902,
    latitude: 44.92239814,
  },
  {
    label: "Luzzara",
    longitude: 10.68587518,
    latitude: 44.9600928,
  },
  {
    label: "Montecchio Emilia",
    longitude: 10.44658013,
    latitude: 44.70013955,
  },
  {
    label: "Novellara",
    longitude: 10.73188909,
    latitude: 44.84512566,
  },
  {
    label: "Poviglio",
    longitude: 10.54151678,
    latitude: 44.84080694,
  },
  {
    label: "Quattro Castella",
    longitude: 10.47269433,
    latitude: 44.63577225,
  },
  {
    label: "Reggiolo",
    longitude: 10.80428415,
    latitude: 44.91859155,
  },
  {
    label: "Reggio nell'Emilia",
    longitude: 10.63007973,
    latitude: 44.69735289,
  },
  {
    label: "Rio Saliceto",
    longitude: 10.80290118,
    latitude: 44.80931994,
  },
  {
    label: "Rolo",
    longitude: 10.85634928,
    latitude: 44.88720187,
  },
  {
    label: "Rubiera",
    longitude: 10.78325846,
    latitude: 44.65330024,
  },
  {
    label: "San Martino in Rio",
    longitude: 10.78465766,
    latitude: 44.73269779,
  },
  {
    label: "San Polo d'Enza",
    longitude: 10.42090246,
    latitude: 44.6264989,
  },
  {
    label: "Sant'Ilario d'Enza",
    longitude: 10.45143035,
    latitude: 44.76059788,
  },
  {
    label: "Scandiano",
    longitude: 10.6882162,
    latitude: 44.59938975,
  },
  {
    label: "Toano",
    longitude: 10.55894757,
    latitude: 44.37638071,
  },
  {
    label: "Vetto",
    longitude: 10.33924241,
    latitude: 44.48404763,
  },
  {
    label: "Vezzano sul Crostolo",
    longitude: 10.54644152,
    latitude: 44.60091521,
  },
  {
    label: "Viano",
    longitude: 10.62040753,
    latitude: 44.54828799,
  },
  {
    label: "Villa Minozzo",
    longitude: 10.4671925,
    latitude: 44.36521328,
  },
  {
    label: "Ventasso",
    longitude: 10.3244,
    latitude: 44.3698,
  },
  {
    label: "Bastiglia",
    longitude: 10.99981167,
    latitude: 44.72644995,
  },
  {
    label: "Bomporto",
    longitude: 11.04278703,
    latitude: 44.72737543,
  },
  {
    label: "Campogalliano",
    longitude: 10.84125789,
    latitude: 44.69044118,
  },
  {
    label: "Camposanto",
    longitude: 11.13827895,
    latitude: 44.78790421,
  },
  {
    label: "Carpi",
    longitude: 10.88526276,
    latitude: 44.78247939,
  },
  {
    label: "Castelfranco Emilia",
    longitude: 11.05298979,
    latitude: 44.59636639,
  },
  {
    label: "Castelnuovo Rangone",
    longitude: 10.93964894,
    latitude: 44.54913164,
  },
  {
    label: "Castelvetro di Modena",
    longitude: 10.94303246,
    latitude: 44.50346628,
  },
  {
    label: "Cavezzo",
    longitude: 11.02990462,
    latitude: 44.83770031,
  },
  {
    label: "Concordia sulla Secchia",
    longitude: 10.98281203,
    latitude: 44.91223653,
  },
  {
    label: "Fanano",
    longitude: 10.79514031,
    latitude: 44.20740301,
  },
  {
    label: "Finale Emilia",
    longitude: 11.2957332,
    latitude: 44.83221342,
  },
  {
    label: "Fiorano Modenese",
    longitude: 10.81142031,
    latitude: 44.53908792,
  },
  {
    label: "Fiumalbo",
    longitude: 10.64739757,
    latitude: 44.17951733,
  },
  {
    label: "Formigine",
    longitude: 10.84018896,
    latitude: 44.57598268,
  },
  {
    label: "Frassinoro",
    longitude: 10.57313174,
    latitude: 44.295372,
  },
  {
    label: "Guiglia",
    longitude: 10.95727133,
    latitude: 44.42798092,
  },
  {
    label: "Lama Mocogno",
    longitude: 10.73062306,
    latitude: 44.30830825,
  },
  {
    label: "Maranello",
    longitude: 10.86744783,
    latitude: 44.52512137,
  },
  {
    label: "Marano sul Panaro",
    longitude: 10.96628727,
    latitude: 44.45611609,
  },
  {
    label: "Medolla",
    longitude: 11.07147687,
    latitude: 44.84894472,
  },
  {
    label: "Mirandola",
    longitude: 11.06607716,
    latitude: 44.88707754,
  },
  {
    label: "Modena",
    longitude: 10.92615487,
    latitude: 44.64600008,
  },
  {
    label: "Montecreto",
    longitude: 10.72073466,
    latitude: 44.24895605,
  },
  {
    label: "Montefiorino",
    longitude: 10.62532596,
    latitude: 44.3594493,
  },
  {
    label: "Montese",
    longitude: 10.94036858,
    latitude: 44.2674702,
  },
  {
    label: "Nonantola",
    longitude: 11.0431178,
    latitude: 44.67778226,
  },
  {
    label: "Novi di Modena",
    longitude: 10.90134261,
    latitude: 44.89356712,
  },
  {
    label: "Palagano",
    longitude: 10.64775313,
    latitude: 44.32072428,
  },
  {
    label: "Pavullo nel Frignano",
    longitude: 10.83398646,
    latitude: 44.33947055,
  },
  {
    label: "Pievepelago",
    longitude: 10.61697591,
    latitude: 44.20702795,
  },
  {
    label: "Polinago",
    longitude: 10.72311171,
    latitude: 44.34435962,
  },
  {
    label: "Prignano sulla Secchia",
    longitude: 10.68952425,
    latitude: 44.43599034,
  },
  {
    label: "Ravarino",
    longitude: 11.09999795,
    latitude: 44.72446933,
  },
  {
    label: "Riolunato",
    longitude: 10.65302864,
    latitude: 44.23049165,
  },
  {
    label: "San Cesario sul Panaro",
    longitude: 11.03416799,
    latitude: 44.56156734,
  },
  {
    label: "San Felice sul Panaro",
    longitude: 11.14140434,
    latitude: 44.83931946,
  },
  {
    label: "San Possidonio",
    longitude: 10.99678633,
    latitude: 44.89099436,
  },
  {
    label: "San Prospero",
    longitude: 11.02289265,
    latitude: 44.7879002,
  },
  {
    label: "Sassuolo",
    longitude: 10.78270189,
    latitude: 44.54032551,
  },
  {
    label: "Savignano sul Panaro",
    longitude: 11.03375535,
    latitude: 44.47959376,
  },
  {
    label: "Serramazzoni",
    longitude: 10.79019017,
    latitude: 44.42123564,
  },
  {
    label: "Sestola",
    longitude: 10.77138108,
    latitude: 44.2301482,
  },
  {
    label: "Soliera",
    longitude: 10.92473124,
    latitude: 44.73814012,
  },
  {
    label: "Spilamberto",
    longitude: 11.02374056,
    latitude: 44.5337143,
  },
  {
    label: "Vignola",
    longitude: 11.01075743,
    latitude: 44.47953672,
  },
  {
    label: "Zocca",
    longitude: 10.99527518,
    latitude: 44.34506492,
  },
  {
    label: "Anzola dell'Emilia",
    longitude: 11.19326943,
    latitude: 44.546483,
  },
  {
    label: "Argelatitudeo",
    longitude: 11.34764547,
    latitude: 44.64232695,
  },
  {
    label: "Baricella",
    longitude: 11.53455005,
    latitude: 44.64706061,
  },
  {
    label: "Bentivoglio",
    longitude: 11.41786388,
    latitude: 44.63514888,
  },
  {
    label: "Bologna",
    longitude: 11.3417208,
    latitude: 44.4943668,
  },
  {
    label: "Borgo Tossignano",
    longitude: 11.58922857,
    latitude: 44.27730823,
  },
  {
    label: "Budrio",
    longitude: 11.53563979,
    latitude: 44.53751606,
  },
  {
    label: "Calderara di Reno",
    longitude: 11.27202395,
    latitude: 44.56608171,
  },
  {
    label: "Camugnano",
    longitude: 11.0873928,
    latitude: 44.16896504,
  },
  {
    label: "Casalecchio di Reno",
    longitude: 11.28246876,
    latitude: 44.48119825,
  },
  {
    label: "Casalfiumanese",
    longitude: 11.6162058,
    latitude: 44.29762885,
  },
  {
    label: "Castel d'Aiano",
    longitude: 11.00130563,
    latitude: 44.28065252,
  },
  {
    label: "Castel del Rio",
    longitude: 11.50442811,
    latitude: 44.21315532,
  },
  {
    label: "Castel di Casio",
    longitude: 11.03637007,
    latitude: 44.1630604,
  },
  {
    label: "Castel Guelfo di Bologna",
    longitude: 11.67895413,
    latitude: 44.43181598,
  },
  {
    label: "Castello d'Argile",
    longitude: 11.29660018,
    latitude: 44.68123191,
  },
  {
    label: "Castel Maggiore",
    longitude: 11.36489158,
    latitude: 44.57499666,
  },
  {
    label: "Castel San Pietro Terme",
    longitude: 11.58917845,
    latitude: 44.39812256,
  },
  {
    label: "Castenaso",
    longitude: 11.46878718,
    latitude: 44.50891745,
  },
  {
    label: "Castiglione dei Pepoli",
    longitude: 11.15391212,
    latitude: 44.14165584,
  },
  {
    label: "Crevalcore",
    longitude: 11.14703461,
    latitude: 44.7226444,
  },
  {
    label: "Dozza",
    longitude: 11.62867786,
    latitude: 44.3594388,
  },
  {
    label: "Fontanelice",
    longitude: 11.55836993,
    latitude: 44.25745852,
  },
  {
    label: "Gaggio Montano",
    longitude: 10.93624639,
    latitude: 44.19900456,
  },
  {
    label: "Galliera",
    longitude: 11.43594172,
    latitude: 44.74473998,
  },
  {
    label: "Granarolo dell'Emilia",
    longitude: 11.44769522,
    latitude: 44.55342273,
  },
  {
    label: "Grizzana Morandi",
    longitude: 11.15075094,
    latitude: 44.25551286,
  },
  {
    label: "Imola",
    longitude: 11.71415595,
    latitude: 44.35307392,
  },
  {
    label: "Lizzano in Belvedere",
    longitude: 10.89366252,
    latitude: 44.16164363,
  },
  {
    label: "Loiano",
    longitude: 11.32172919,
    latitude: 44.27017987,
  },
  {
    label: "Malalbergo",
    longitude: 11.53397861,
    latitude: 44.71890504,
  },
  {
    label: "Marzabotto",
    longitude: 11.20514029,
    latitude: 44.34038509,
  },
  {
    label: "Medicina",
    longitude: 11.64047564,
    latitude: 44.47644406,
  },
  {
    label: "Minerbio",
    longitude: 11.49080289,
    latitude: 44.62520112,
  },
  {
    label: "Molinella",
    longitude: 11.67032164,
    latitude: 44.62036923,
  },
  {
    label: "Monghidoro",
    longitude: 11.31663057,
    latitude: 44.21944409,
  },
  {
    label: "Monterenzio",
    longitude: 11.40500671,
    latitude: 44.3252789,
  },
  {
    label: "Monte San Pietro",
    longitude: 11.1996852,
    latitude: 44.45776182,
  },
  {
    label: "Monzuno",
    longitude: 11.27144388,
    latitude: 44.27680857,
  },
  {
    label: "Mordano",
    longitude: 11.81053436,
    latitude: 44.39772446,
  },
  {
    label: "Ozzano dell'Emilia",
    longitude: 11.47820208,
    latitude: 44.44483656,
  },
  {
    label: "Pianoro",
    longitude: 11.34325984,
    latitude: 44.38510945,
  },
  {
    label: "Pieve di Cento",
    longitude: 11.30700366,
    latitude: 44.71363268,
  },
  {
    label: "Sala Bolognese",
    longitude: 11.27686821,
    latitude: 44.62979729,
  },
  {
    label: "San Benedetto Val di Sambro",
    longitude: 11.23306698,
    latitude: 44.21557469,
  },
  {
    label: "San Giorgio di Piano",
    longitude: 11.3751386,
    latitude: 44.64698354,
  },
  {
    label: "San Giovanni in Persiceto",
    longitude: 11.18779855,
    latitude: 44.63877152,
  },
  {
    label: "San Lazzaro di Savena",
    longitude: 11.40805744,
    latitude: 44.47047676,
  },
  {
    label: "San Pietro in Casale",
    longitude: 11.40619416,
    latitude: 44.70262524,
  },
  {
    label: "Sant'Agata Bolognese",
    longitude: 11.13381036,
    latitude: 44.66454406,
  },
  {
    label: "Sasso Marconi",
    longitude: 11.24491727,
    latitude: 44.39437522,
  },
  {
    label: "Vergato",
    longitude: 11.11278346,
    latitude: 44.28394026,
  },
  {
    label: "Zola Predosa",
    longitude: 11.21818344,
    latitude: 44.49223785,
  },
  {
    label: "Valsamoggia",
    longitude: 11.0804,
    latitude: 44.5031,
  },
  {
    label: "Alto Reno Terme",
    longitude: 10.9733,
    latitude: 44.1601,
  },
  {
    label: "Argenta",
    longitude: 11.83704263,
    latitude: 44.61443583,
  },
  {
    label: "Berra",
    longitude: 11.97857633,
    latitude: 44.97935353,
  },
  {
    label: "Bondeno",
    longitude: 11.41846422,
    latitude: 44.88521609,
  },
  {
    label: "Cento",
    longitude: 11.29060992,
    latitude: 44.72755647,
  },
  {
    label: "Codigoro",
    longitude: 12.10501471,
    latitude: 44.83183932,
  },
  {
    label: "Comacchio",
    longitude: 12.18387254,
    latitude: 44.69462384,
  },
  {
    label: "Copparo",
    longitude: 11.82871999,
    latitude: 44.89547576,
  },
  {
    label: "Ferrara",
    longitude: 11.61868934,
    latitude: 44.83599085,
  },
  {
    label: "Formignana",
    longitude: 11.858723,
    latitude: 44.8424683,
  },
  {
    label: "Jolanda di Savoia",
    longitude: 11.97450851,
    latitude: 44.88615417,
  },
  {
    label: "Lagosanto",
    longitude: 12.14100144,
    latitude: 44.76541458,
  },
  {
    label: "Masi Torello",
    longitude: 11.7952732,
    latitude: 44.79403664,
  },
  {
    label: "Mesola",
    longitude: 12.2292449,
    latitude: 44.92248699,
  },
  {
    label: "Ostellatitudeo",
    longitude: 11.94082589,
    latitude: 44.74541029,
  },
  {
    label: "Poggio Renatico",
    longitude: 11.48545381,
    latitude: 44.7647145,
  },
  {
    label: "Portomaggiore",
    longitude: 11.80577819,
    latitude: 44.69780545,
  },
  {
    label: "Ro",
    longitude: 11.76486674,
    latitude: 44.94537415,
  },
  {
    label: "Vigarano Mainarda",
    longitude: 11.50031563,
    latitude: 44.84176192,
  },
  {
    label: "Voghiera",
    longitude: 11.75124557,
    latitude: 44.75814047,
  },
  {
    label: "Tresigallo",
    longitude: 11.89681521,
    latitude: 44.81778085,
  },
  {
    label: "Goro",
    longitude: 12.30276124,
    latitude: 44.85381576,
  },
  {
    label: "Fiscaglia",
    longitude: 11.9754,
    latitude: 44.7988,
  },
  {
    label: "Terre del Reno",
    longitude: 11.3892,
    latitude: 44.7932,
  },
  {
    label: "Alfonsine",
    longitude: 12.04064787,
    latitude: 44.50631817,
  },
  {
    label: "Bagnacavallo",
    longitude: 11.97697277,
    latitude: 44.41630012,
  },
  {
    label: "Bagnara di Romagna",
    longitude: 11.82581789,
    latitude: 44.38882779,
  },
  {
    label: "Brisighella",
    longitude: 11.76902039,
    latitude: 44.22134642,
  },
  {
    label: "Casola Valsenio",
    longitude: 11.62307624,
    latitude: 44.22351906,
  },
  {
    label: "Castel Bolognese",
    longitude: 11.79894865,
    latitude: 44.31911226,
  },
  {
    label: "Cervia",
    longitude: 12.3498153,
    latitude: 44.26092823,
  },
  {
    label: "Conselice",
    longitude: 11.82906464,
    latitude: 44.51266493,
  },
  {
    label: "Cotignola",
    longitude: 11.94221614,
    latitude: 44.38439045,
  },
  {
    label: "Faenza",
    longitude: 11.88279721,
    latitude: 44.28543714,
  },
  {
    label: "Fusignano",
    longitude: 11.9593004,
    latitude: 44.46802428,
  },
  {
    label: "Lugo",
    longitude: 11.9114825,
    latitude: 44.42083388,
  },
  {
    label: "Massa Lombarda",
    longitude: 11.82821317,
    latitude: 44.44657069,
  },
  {
    label: "Ravenna",
    longitude: 12.19913937,
    latitude: 44.41722492,
  },
  {
    label: "Riolo Terme",
    longitude: 11.72454789,
    latitude: 44.27615818,
  },
  {
    label: "Russi",
    longitude: 12.03289251,
    latitude: 44.37242208,
  },
  {
    label: "Sant'Agata sul Santerno",
    longitude: 11.86165453,
    latitude: 44.44233184,
  },
  {
    label: "Solarolo",
    longitude: 11.84375254,
    latitude: 44.36017093,
  },
  {
    label: "Bagno di Romagna",
    longitude: 11.9768623,
    latitude: 43.8578076,
  },
  {
    label: "Bertinoro",
    longitude: 12.13425106,
    latitude: 44.14891351,
  },
  {
    label: "Borghi",
    longitude: 12.35456963,
    latitude: 44.03146437,
  },
  {
    label: "Castrocaro Terme e Terra del Sole",
    longitude: 11.95002717,
    latitude: 44.1760681,
  },
  {
    label: "Cesena",
    longitude: 12.24217492,
    latitude: 44.13654899,
  },
  {
    label: "Cesenatico",
    longitude: 12.39544168,
    latitude: 44.19953513,
  },
  {
    label: "Civitella di Romagna",
    longitude: 11.93825799,
    latitude: 44.00686055,
  },
  {
    label: "Dovadola",
    longitude: 11.88746782,
    latitude: 44.12161957,
  },
  {
    label: "Forlì",
    longitude: 12.04068608,
    latitude: 44.22268559,
  },
  {
    label: "Forlimpopoli",
    longitude: 12.12663769,
    latitude: 44.18722836,
  },
  {
    label: "Galeata",
    longitude: 11.91037026,
    latitude: 43.9964232,
  },
  {
    label: "Gambettola",
    longitude: 12.33907227,
    latitude: 44.12000641,
  },
  {
    label: "Gatteo",
    longitude: 12.38611016,
    latitude: 44.1096172,
  },
  {
    label: "Longiano",
    longitude: 12.32692236,
    latitude: 44.07409338,
  },
  {
    label: "Meldola",
    longitude: 12.06061678,
    latitude: 44.12697469,
  },
  {
    label: "Mercato Saraceno",
    longitude: 12.19584445,
    latitude: 43.95701341,
  },
  {
    label: "Modigliana",
    longitude: 11.79260095,
    latitude: 44.15627344,
  },
  {
    label: "Montiano",
    longitude: 12.30424275,
    latitude: 44.08351204,
  },
  {
    label: "Portico e San Benedetto",
    longitude: 11.78091956,
    latitude: 44.02586511,
  },
  {
    label: "Predappio",
    longitude: 11.98050097,
    latitude: 44.10181434,
  },
  {
    label: "Premilcuore",
    longitude: 11.77569302,
    latitude: 43.97641573,
  },
  {
    label: "Rocca San Casciano",
    longitude: 11.84223308,
    latitude: 44.0614546,
  },
  {
    label: "Roncofreddo",
    longitude: 12.31808653,
    latitude: 44.04188275,
  },
  {
    label: "San Mauro Pascoli",
    longitude: 12.41549224,
    latitude: 44.10703239,
  },
  {
    label: "Santa Sofia",
    longitude: 11.90795612,
    latitude: 43.94689873,
  },
  {
    label: "Sarsina",
    longitude: 12.14086214,
    latitude: 43.9185833,
  },
  {
    label: "Savignano sul Rubicone",
    longitude: 12.39908408,
    latitude: 44.09209836,
  },
  {
    label: "Sogliano al Rubicone",
    longitude: 12.30013583,
    latitude: 44.0048826,
  },
  {
    label: "Tredozio",
    longitude: 11.7437038,
    latitude: 44.07935407,
  },
  {
    label: "Verghereto",
    longitude: 12.0058776,
    latitude: 43.79564841,
  },
  {
    label: "Acqualagna",
    longitude: 12.67087178,
    latitude: 43.61673002,
  },
  {
    label: "Apecchio",
    longitude: 12.41964013,
    latitude: 43.55830201,
  },
  {
    label: "Auditore",
    longitude: 12.57175016,
    latitude: 43.82118648,
  },
  {
    label: "Belforte all'Isauro",
    longitude: 12.37562574,
    latitude: 43.7170107,
  },
  {
    label: "Borgo Pace",
    longitude: 12.29461948,
    latitude: 43.6581701,
  },
  {
    label: "Cagli",
    longitude: 12.64801492,
    latitude: 43.54638176,
  },
  {
    label: "Cantiano",
    longitude: 12.62723295,
    latitude: 43.4736579,
  },
  {
    label: "Carpegna",
    longitude: 12.33599429,
    latitude: 43.78121521,
  },
  {
    label: "Cartoceto",
    longitude: 12.88376809,
    latitude: 43.76458096,
  },
  {
    label: "Fano",
    longitude: 13.01966068,
    latitude: 43.84340093,
  },
  {
    label: "Fermignano",
    longitude: 12.64528129,
    latitude: 43.6764905,
  },
  {
    label: "Fossombrone",
    longitude: 12.80571229,
    latitude: 43.68885252,
  },
  {
    label: "Fratte Rosa",
    longitude: 12.90097388,
    latitude: 43.63196171,
  },
  {
    label: "Frontino",
    longitude: 12.3774383,
    latitude: 43.76458228,
  },
  {
    label: "Frontone",
    longitude: 12.73891053,
    latitude: 43.51462189,
  },
  {
    label: "Gabicce Mare",
    longitude: 12.75648023,
    latitude: 43.96658771,
  },
  {
    label: "Gradara",
    longitude: 12.77300459,
    latitude: 43.94077611,
  },
  {
    label: "Isola del Piano",
    longitude: 12.78293406,
    latitude: 43.7371568,
  },
  {
    label: "Lunano",
    longitude: 12.44098613,
    latitude: 43.73047,
  },
  {
    label: "Macerata Feltria",
    longitude: 12.44277531,
    latitude: 43.80308276,
  },
  {
    label: "Mercatello sul Metauro",
    longitude: 12.33675362,
    latitude: 43.64750954,
  },
  {
    label: "Mercatino Conca",
    longitude: 12.49350427,
    latitude: 43.87042655,
  },
  {
    label: "Mombaroccio",
    longitude: 12.85487947,
    latitude: 43.79683666,
  },
  {
    label: "Mondavio",
    longitude: 12.9686012,
    latitude: 43.67453553,
  },
  {
    label: "Mondolfo",
    longitude: 13.09546209,
    latitude: 43.75151916,
  },
  {
    label: "Montecalvo in Foglia",
    longitude: 12.6315798,
    latitude: 43.8115456,
  },
  {
    label: "Monte Cerignone",
    longitude: 12.41263275,
    latitude: 43.84098868,
  },
  {
    label: "Monteciccardo",
    longitude: 12.80825569,
    latitude: 43.81930079,
  },
  {
    label: "Montecopiolo",
    longitude: 12.35938073,
    latitude: 43.841273,
  },
  {
    label: "Montefelcino",
    longitude: 12.83320397,
    latitude: 43.73506647,
  },
  {
    label: "Monte Grimano Terme",
    longitude: 12.47284802,
    latitude: 43.8654428,
  },
  {
    label: "Montelabbate",
    longitude: 12.79076238,
    latitude: 43.84907238,
  },
  {
    label: "Monte Porzio",
    longitude: 13.04518523,
    latitude: 43.68986551,
  },
  {
    label: "Peglio",
    longitude: 12.49603827,
    latitude: 43.6960392,
  },
  {
    label: "Pergola",
    longitude: 12.83598983,
    latitude: 43.56288219,
  },
  {
    label: "Pesaro",
    longitude: 12.91345991,
    latitude: 43.91014021,
  },
  {
    label: "Petriano",
    longitude: 12.73335409,
    latitude: 43.78031434,
  },
  {
    label: "Piandimeleto",
    longitude: 12.41513494,
    latitude: 43.72416077,
  },
  {
    label: "Pietrarubbia",
    longitude: 12.37740064,
    latitude: 43.80420265,
  },
  {
    label: "Piobbico",
    longitude: 12.5103492,
    latitude: 43.58882426,
  },
  {
    label: "San Costanzo",
    longitude: 13.06950997,
    latitude: 43.76311801,
  },
  {
    label: "San Lorenzo in Campo",
    longitude: 12.94543344,
    latitude: 43.60342232,
  },
  {
    label: "Sant'Angelo in Vado",
    longitude: 12.41326777,
    latitude: 43.66489783,
  },
  {
    label: "Sant'Ippolito",
    longitude: 12.87103494,
    latitude: 43.6848265,
  },
  {
    label: "Sassocorvaro",
    longitude: 12.49531808,
    latitude: 43.78094127,
  },
  {
    label: "Sassofeltrio",
    longitude: 12.50930402,
    latitude: 43.89243764,
  },
  {
    label: "Serra Sant'Abbondio",
    longitude: 12.77061803,
    latitude: 43.49085038,
  },
  {
    label: "Tavoleto",
    longitude: 12.59263731,
    latitude: 43.84623247,
  },
  {
    label: "Tavullia",
    longitude: 12.75319778,
    latitude: 43.89794226,
  },
  {
    label: "Urbania",
    longitude: 12.52426743,
    latitude: 43.66837713,
  },
  {
    label: "Urbino",
    longitude: 12.63729902,
    latitude: 43.72512229,
  },
  {
    label: "Vallefoglia",
    longitude: 12.8013,
    latitude: 43.8264,
  },
  {
    label: "Colli al Metauro",
    longitude: 12.8982,
    latitude: 43.7546,
  },
  {
    label: "Terre Roveresche",
    longitude: 12.9641,
    latitude: 43.6876,
  },
  {
    label: "Agugliano",
    longitude: 13.38580376,
    latitude: 43.54337654,
  },
  {
    label: "Ancona",
    longitude: 13.51887537,
    latitude: 43.61675973,
  },
  {
    label: "Arcevia",
    longitude: 12.93959861,
    latitude: 43.49999837,
  },
  {
    label: "Barbara",
    longitude: 13.02473927,
    latitude: 43.58080064,
  },
  {
    label: "Belvedere Ostrense",
    longitude: 13.16682409,
    latitude: 43.58043733,
  },
  {
    label: "Camerano",
    longitude: 13.55302876,
    latitude: 43.53092763,
  },
  {
    label: "Camerata Picena",
    longitude: 13.35275403,
    latitude: 43.57820462,
  },
  {
    label: "Castelbellino",
    longitude: 13.14606651,
    latitude: 43.48783546,
  },
  {
    label: "Castelfidardo",
    longitude: 13.5465963,
    latitude: 43.46452646,
  },
  {
    label: "Castelleone di Suasa",
    longitude: 12.97676838,
    latitude: 43.60724312,
  },
  {
    label: "Castelplanio",
    longitude: 13.08135253,
    latitude: 43.49458947,
  },
  {
    label: "Cerreto d'Esi",
    longitude: 12.98515333,
    latitude: 43.32074084,
  },
  {
    label: "Chiaravalle",
    longitude: 13.32555184,
    latitude: 43.59973296,
  },
  {
    label: "Corinaldo",
    longitude: 13.04772759,
    latitude: 43.64884868,
  },
  {
    label: "Cupramontana",
    longitude: 13.11567288,
    latitude: 43.44555295,
  },
  {
    label: "Fabriano",
    longitude: 12.90472385,
    latitude: 43.33626864,
  },
  {
    label: "Falconara Marittima",
    longitude: 13.39392666,
    latitude: 43.623291,
  },
  {
    label: "Filottrano",
    longitude: 13.35128311,
    latitude: 43.43567741,
  },
  {
    label: "Genga",
    longitude: 12.93510378,
    latitude: 43.42959914,
  },
  {
    label: "Jesi",
    longitude: 13.24382689,
    latitude: 43.52281933,
  },
  {
    label: "Loreto",
    longitude: 13.60792879,
    latitude: 43.44028357,
  },
  {
    label: "Maiolatitudei Spontini",
    longitude: 13.11910582,
    latitude: 43.47733777,
  },
  {
    label: "Mergo",
    longitude: 13.03738719,
    latitude: 43.47142461,
  },
  {
    label: "Monsano",
    longitude: 13.25030883,
    latitude: 43.5634831,
  },
  {
    label: "Montecarotto",
    longitude: 13.06452723,
    latitude: 43.526364,
  },
  {
    label: "Montemarciano",
    longitude: 13.31022808,
    latitude: 43.64008533,
  },
  {
    label: "Monte Roberto",
    longitude: 13.1385133,
    latitude: 43.48144004,
  },
  {
    label: "Monte San Vito",
    longitude: 13.27097232,
    latitude: 43.60058381,
  },
  {
    label: "Morro d'Alba",
    longitude: 13.21405308,
    latitude: 43.6011298,
  },
  {
    label: "Numana",
    longitude: 13.62151362,
    latitude: 43.513472,
  },
  {
    label: "Offagna",
    longitude: 13.44148054,
    latitude: 43.52725632,
  },
  {
    label: "Osimo",
    longitude: 13.48190682,
    latitude: 43.48618905,
  },
  {
    label: "Ostra",
    longitude: 13.15838859,
    latitude: 43.61268103,
  },
  {
    label: "Ostra Vetere",
    longitude: 13.05736333,
    latitude: 43.60371382,
  },
  {
    label: "Poggio San Marcello",
    longitude: 13.0770505,
    latitude: 43.51159656,
  },
  {
    label: "Polverigi",
    longitude: 13.39412242,
    latitude: 43.52520561,
  },
  {
    label: "Rosora",
    longitude: 13.06919258,
    latitude: 43.48275533,
  },
  {
    label: "San Marcello",
    longitude: 13.20620835,
    latitude: 43.57673897,
  },
  {
    label: "San Paolo di Jesi",
    longitude: 13.17356825,
    latitude: 43.45488092,
  },
  {
    label: "Santa Maria Nuova",
    longitude: 13.31053369,
    latitude: 43.49594448,
  },
  {
    label: "Sassoferrato",
    longitude: 12.85865231,
    latitude: 43.43599892,
  },
  {
    label: "Senigallia",
    longitude: 13.21778017,
    latitude: 43.71482786,
  },
  {
    label: "Serra de' Conti",
    longitude: 13.03777268,
    latitude: 43.5425796,
  },
  {
    label: "Serra San Quirico",
    longitude: 13.01483261,
    latitude: 43.44757996,
  },
  {
    label: "Sirolo",
    longitude: 13.61604009,
    latitude: 43.52601874,
  },
  {
    label: "Staffolo",
    longitude: 13.18631858,
    latitude: 43.43286075,
  },
  {
    label: "Trecastelli",
    longitude: 13.1064,
    latitude: 43.6718,
  },
  {
    label: "Apiro",
    longitude: 13.13101333,
    latitude: 43.39247319,
  },
  {
    label: "Appignano",
    longitude: 13.34623812,
    latitude: 43.36406969,
  },
  {
    label: "Belforte del Chienti",
    longitude: 13.23772508,
    latitude: 43.16425472,
  },
  {
    label: "Bolognola",
    longitude: 13.22684641,
    latitude: 42.99426104,
  },
  {
    label: "Caldarola",
    longitude: 13.22627038,
    latitude: 43.13845473,
  },
  {
    label: "Camerino",
    longitude: 13.06766302,
    latitude: 43.13536473,
  },
  {
    label: "Camporotondo di Fiastrone",
    longitude: 13.26552578,
    latitude: 43.13179596,
  },
  {
    label: "Castelraimondo",
    longitude: 13.05769967,
    latitude: 43.21000258,
  },
  {
    label: "Castelsantangelo sul Nera",
    longitude: 13.15307613,
    latitude: 42.89447489,
  },
  {
    label: "Cessapalombo",
    longitude: 13.25840962,
    latitude: 43.10910049,
  },
  {
    label: "Cingoli",
    longitude: 13.21646831,
    latitude: 43.3742808,
  },
  {
    label: "Civitanova Marche",
    longitude: 13.72803,
    latitude: 43.30668202,
  },
  {
    label: "Colmurano",
    longitude: 13.35670534,
    latitude: 43.16387163,
  },
  {
    label: "Corridonia",
    longitude: 13.50886818,
    latitude: 43.24818514,
  },
  {
    label: "Esanatoglia",
    longitude: 12.94616909,
    latitude: 43.25145608,
  },
  {
    label: "Fiastra",
    longitude: 13.1565,
    latitude: 43.0368,
  },
  {
    label: "Fiuminata",
    longitude: 12.93168275,
    latitude: 43.18864438,
  },
  {
    label: "Gagliole",
    longitude: 13.06808297,
    latitude: 43.23946186,
  },
  {
    label: "Gualdo",
    longitude: 13.33794374,
    latitude: 43.06735915,
  },
  {
    label: "Loro Piceno",
    longitude: 13.41619153,
    latitude: 43.16610273,
  },
  {
    label: "Macerata",
    longitude: 13.45307188,
    latitude: 43.30023926,
  },
  {
    label: "Matelica",
    longitude: 13.00951788,
    latitude: 43.25661381,
  },
  {
    label: "Mogliano",
    longitude: 13.4808227,
    latitude: 43.18600351,
  },
  {
    label: "Montecassiano",
    longitude: 13.43593734,
    latitude: 43.36375365,
  },
  {
    label: "Monte Cavallo",
    longitude: 13.00115702,
    latitude: 42.99356324,
  },
  {
    label: "Montecosaro",
    longitude: 13.63525257,
    latitude: 43.31684668,
  },
  {
    label: "Montefano",
    longitude: 13.43975137,
    latitude: 43.41191862,
  },
  {
    label: "Montelupone",
    longitude: 13.56712367,
    latitude: 43.3443849,
  },
  {
    label: "Monte San Giusto",
    longitude: 13.59427638,
    latitude: 43.23601473,
  },
  {
    label: "Monte San Martino",
    longitude: 13.43937111,
    latitude: 43.03117231,
  },
  {
    label: "Morrovalle",
    longitude: 13.58002604,
    latitude: 43.31468877,
  },
  {
    label: "Muccia",
    longitude: 13.04405182,
    latitude: 43.08209517,
  },
  {
    label: "Penna San Giovanni",
    longitude: 13.42374445,
    latitude: 43.05657462,
  },
  {
    label: "Petriolo",
    longitude: 13.46562823,
    latitude: 43.22102655,
  },
  {
    label: "Pieve Torina",
    longitude: 13.05038725,
    latitude: 43.04432836,
  },
  {
    label: "Pioraco",
    longitude: 12.98484416,
    latitude: 43.17878275,
  },
  {
    label: "Poggio San Vicino",
    longitude: 13.07883903,
    latitude: 43.37471006,
  },
  {
    label: "Pollenza",
    longitude: 13.34760396,
    latitude: 43.26782215,
  },
  {
    label: "Porto Recanati",
    longitude: 13.6648944,
    latitude: 43.42983471,
  },
  {
    label: "Potenza Picena",
    longitude: 13.62025852,
    latitude: 43.36625779,
  },
  {
    label: "Recanati",
    longitude: 13.54883759,
    latitude: 43.40383437,
  },
  {
    label: "Ripe San Ginesio",
    longitude: 13.3674957,
    latitude: 43.14304895,
  },
  {
    label: "San Ginesio",
    longitude: 13.31672887,
    latitude: 43.10801114,
  },
  {
    label: "San Severino Marche",
    longitude: 13.17711312,
    latitude: 43.22885753,
  },
  {
    label: "Sant'Angelo in Pontano",
    longitude: 13.3954781,
    latitude: 43.09929122,
  },
  {
    label: "Sarnano",
    longitude: 13.30106674,
    latitude: 43.035477,
  },
  {
    label: "Sefro",
    longitude: 12.94925569,
    latitude: 43.14624647,
  },
  {
    label: "Serrapetrona",
    longitude: 13.18937283,
    latitude: 43.17691964,
  },
  {
    label: "Serravalle di Chienti",
    longitude: 12.9520226,
    latitude: 43.07184653,
  },
  {
    label: "Tolentino",
    longitude: 13.28429984,
    latitude: 43.20872655,
  },
  {
    label: "Treia",
    longitude: 13.31230423,
    latitude: 43.31128023,
  },
  {
    label: "Urbisaglia",
    longitude: 13.37679031,
    latitude: 43.19704423,
  },
  {
    label: "Ussita",
    longitude: 13.13845668,
    latitude: 42.9440838,
  },
  {
    label: "Visso",
    longitude: 13.08830764,
    latitude: 42.93075813,
  },
  {
    label: "Valfornace",
    longitude: 13.0839,
    latitude: 43.0649,
  },
  {
    label: "Acquasanta Terme",
    longitude: 13.40930458,
    latitude: 42.76912283,
  },
  {
    label: "Acquaviva Picena",
    longitude: 13.8146435,
    latitude: 42.94473063,
  },
  {
    label: "Appignano del Tronto",
    longitude: 13.66604194,
    latitude: 42.89908619,
  },
  {
    label: "Arquata del Tronto",
    longitude: 13.29677295,
    latitude: 42.77264083,
  },
  {
    label: "Ascoli Piceno",
    longitude: 13.57691135,
    latitude: 42.85322304,
  },
  {
    label: "Carassai",
    longitude: 13.68373044,
    latitude: 43.03214724,
  },
  {
    label: "Castel di Lama",
    longitude: 13.71289574,
    latitude: 42.86891296,
  },
  {
    label: "Castignano",
    longitude: 13.62155661,
    latitude: 42.93806266,
  },
  {
    label: "Castorano",
    longitude: 13.72762244,
    latitude: 42.89833352,
  },
  {
    label: "Colli del Tronto",
    longitude: 13.74815942,
    latitude: 42.87800984,
  },
  {
    label: "Comunanza",
    longitude: 13.41391783,
    latitude: 42.9575834,
  },
  {
    label: "Cossignano",
    longitude: 13.68791015,
    latitude: 42.98414793,
  },
  {
    label: "Cupra Marittima",
    longitude: 13.85901545,
    latitude: 43.0250875,
  },
  {
    label: "Folignano",
    longitude: 13.63258423,
    latitude: 42.82103982,
  },
  {
    label: "Force",
    longitude: 13.49104814,
    latitude: 42.9630041,
  },
  {
    label: "Grottammare",
    longitude: 13.86834111,
    latitude: 42.98846372,
  },
  {
    label: "Maltignano",
    longitude: 13.68717844,
    latitude: 42.83163236,
  },
  {
    label: "Massignano",
    longitude: 13.79702416,
    latitude: 43.05085704,
  },
  {
    label: "Monsampolo del Tronto",
    longitude: 13.79095317,
    latitude: 42.89746968,
  },
  {
    label: "Montalto delle Marche",
    longitude: 13.60713303,
    latitude: 42.98736823,
  },
  {
    label: "Montedinove",
    longitude: 13.5869669,
    latitude: 42.97074448,
  },
  {
    label: "Montefiore dell'Aso",
    longitude: 13.7508984,
    latitude: 43.051616,
  },
  {
    label: "Montegallo",
    longitude: 13.33299105,
    latitude: 42.842195,
  },
  {
    label: "Montemonaco",
    longitude: 13.32695984,
    latitude: 42.89949543,
  },
  {
    label: "Monteprandone",
    longitude: 13.83573683,
    latitude: 42.92009938,
  },
  {
    label: "Offida",
    longitude: 13.691931,
    latitude: 42.93519978,
  },
  {
    label: "Palmiano",
    longitude: 13.45855304,
    latitude: 42.8993202,
  },
  {
    label: "Ripatransone",
    longitude: 13.76264711,
    latitude: 43.00016638,
  },
  {
    label: "Roccafluvione",
    longitude: 13.4752157,
    latitude: 42.86126733,
  },
  {
    label: "Rotella",
    longitude: 13.5609324,
    latitude: 42.95452723,
  },
  {
    label: "San Benedetto del Tronto",
    longitude: 13.88291098,
    latitude: 42.9436327,
  },
  {
    label: "Spinetoli",
    longitude: 13.77341044,
    latitude: 42.88933863,
  },
  {
    label: "Venarotta",
    longitude: 13.49282294,
    latitude: 42.88118491,
  },
  {
    label: "Aulla",
    longitude: 9.96815494,
    latitude: 44.20934409,
  },
  {
    label: "Bagnone",
    longitude: 9.99442801,
    latitude: 44.315389,
  },
  {
    label: "Carrara",
    longitude: 10.09678309,
    latitude: 44.07729932,
  },
  {
    label: "Casola in Lunigiana",
    longitude: 10.17571802,
    latitude: 44.20117148,
  },
  {
    label: "Comano",
    longitude: 10.13487911,
    latitude: 44.2972261,
  },
  {
    label: "Filatitudetiera",
    longitude: 9.93687773,
    latitude: 44.33127853,
  },
  {
    label: "Fivizzano",
    longitude: 10.1228326,
    latitude: 44.23706089,
  },
  {
    label: "Fosdinovo",
    longitude: 10.0190589,
    latitude: 44.13438049,
  },
  {
    label: "Licciana Nardi",
    longitude: 10.03853952,
    latitude: 44.26498452,
  },
  {
    label: "Massa",
    longitude: 10.14173829,
    latitude: 44.03674425,
  },
  {
    label: "Montignoso",
    longitude: 10.17648313,
    latitude: 44.01814629,
  },
  {
    label: "Mulazzo",
    longitude: 9.9070755,
    latitude: 44.32166365,
  },
  {
    label: "Podenzana",
    longitude: 9.94122257,
    latitude: 44.20637426,
  },
  {
    label: "Pontremoli",
    longitude: 9.88294832,
    latitude: 44.37738694,
  },
  {
    label: "Tresana",
    longitude: 9.95155878,
    latitude: 44.23824409,
  },
  {
    label: "Villafranca in Lunigiana",
    longitude: 9.95102604,
    latitude: 44.2919847,
  },
  {
    label: "Zeri",
    longitude: 9.76350258,
    latitude: 44.35291688,
  },
  {
    label: "Altopascio",
    longitude: 10.67474472,
    latitude: 43.81503652,
  },
  {
    label: "Bagni di Lucca",
    longitude: 10.59120328,
    latitude: 44.01054783,
  },
  {
    label: "Barga",
    longitude: 10.48477616,
    latitude: 44.0730479,
  },
  {
    label: "Borgo a Mozzano",
    longitude: 10.54360068,
    latitude: 43.97852311,
  },
  {
    label: "Camaiore",
    longitude: 10.30417448,
    latitude: 43.93859723,
  },
  {
    label: "Camporgiano",
    longitude: 10.33379883,
    latitude: 44.15864597,
  },
  {
    label: "Capannori",
    longitude: 10.57479954,
    latitude: 43.84891682,
  },
  {
    label: "Careggine",
    longitude: 10.32438676,
    latitude: 44.12081249,
  },
  {
    label: "Castelnuovo di Garfagnana",
    longitude: 10.41166069,
    latitude: 44.11031578,
  },
  {
    label: "Castiglione di Garfagnana",
    longitude: 10.41126782,
    latitude: 44.14908854,
  },
  {
    label: "Coreglia Antelminelli",
    longitude: 10.52530192,
    latitude: 44.0645269,
  },
  {
    label: "Forte dei Marmi",
    longitude: 10.16670554,
    latitude: 43.96017596,
  },
  {
    label: "Fosciandora",
    longitude: 10.45540829,
    latitude: 44.11523789,
  },
  {
    label: "Gallicano",
    longitude: 10.4356557,
    latitude: 44.0604687,
  },
  {
    label: "Lucca",
    longitude: 10.50151366,
    latitude: 43.84432282,
  },
  {
    label: "Massarosa",
    longitude: 10.34180619,
    latitude: 43.86849625,
  },
  {
    label: "Minucciano",
    longitude: 10.20756436,
    latitude: 44.17031081,
  },
  {
    label: "Molazzana",
    longitude: 10.41843796,
    latitude: 44.07259333,
  },
  {
    label: "Montecarlo",
    longitude: 10.6685708,
    latitude: 43.85036404,
  },
  {
    label: "Pescaglia",
    longitude: 10.41128318,
    latitude: 43.96642357,
  },
  {
    label: "Piazza al Serchio",
    longitude: 10.30001696,
    latitude: 44.18256511,
  },
  {
    label: "Pietrasanta",
    longitude: 10.2276881,
    latitude: 43.95955549,
  },
  {
    label: "Pieve Fosciana",
    longitude: 10.41068576,
    latitude: 44.13062789,
  },
  {
    label: "Porcari",
    longitude: 10.6167067,
    latitude: 43.83966221,
  },
  {
    label: "San Romano in Garfagnana",
    longitude: 10.34543881,
    latitude: 44.16896414,
  },
  {
    label: "Seravezza",
    longitude: 10.22843772,
    latitude: 43.99518964,
  },
  {
    label: "Stazzema",
    longitude: 10.29563014,
    latitude: 43.99711505,
  },
  {
    label: "Vagli Sotto",
    longitude: 10.28039662,
    latitude: 44.1143894,
  },
  {
    label: "Viareggio",
    longitude: 10.24981154,
    latitude: 43.8669427,
  },
  {
    label: "Villa Basilica",
    longitude: 10.64509707,
    latitude: 43.92777564,
  },
  {
    label: "Villa Collemandina",
    longitude: 10.39757231,
    latitude: 44.15957668,
  },
  {
    label: "Fabbriche di Vergemoli",
    longitude: 10.4275,
    latitude: 43.9973,
  },
  {
    label: "Sillano Giuncugnano",
    longitude: 10.3024,
    latitude: 44.225,
  },
  {
    label: "Agliana",
    longitude: 11.00653402,
    latitude: 43.90278508,
  },
  {
    label: "Buggiano",
    longitude: 10.72753655,
    latitude: 43.87993288,
  },
  {
    label: "Lamporecchio",
    longitude: 10.89678764,
    latitude: 43.81697342,
  },
  {
    label: "Larciano",
    longitude: 10.87719405,
    latitude: 43.82466175,
  },
  {
    label: "Marliana",
    longitude: 10.77005514,
    latitude: 43.9340605,
  },
  {
    label: "Massa e Cozzile",
    longitude: 10.74467251,
    latitude: 43.9099152,
  },
  {
    label: "Monsummano Terme",
    longitude: 10.81442271,
    latitude: 43.8715962,
  },
  {
    label: "Montale",
    longitude: 11.02226655,
    latitude: 43.93473923,
  },
  {
    label: "Montecatini-Terme",
    longitude: 10.77278426,
    latitude: 43.88409638,
  },
  {
    label: "Pescia",
    longitude: 10.68859305,
    latitude: 43.90315898,
  },
  {
    label: "Pieve a Nievole",
    longitude: 10.79465882,
    latitude: 43.87897547,
  },
  {
    label: "Pistoia",
    longitude: 10.91734146,
    latitude: 43.933465,
  },
  {
    label: "Ponte Buggianese",
    longitude: 10.74744648,
    latitude: 43.84275902,
  },
  {
    label: "Quarrata",
    longitude: 10.97861288,
    latitude: 43.84759372,
  },
  {
    label: "Sambuca Pistoiese",
    longitude: 10.99899531,
    latitude: 44.10404639,
  },
  {
    label: "Serravalle Pistoiese",
    longitude: 10.83198084,
    latitude: 43.90559434,
  },
  {
    label: "Uzzano",
    longitude: 10.70907718,
    latitude: 43.88171633,
  },
  {
    label: "Chiesina Uzzanese",
    longitude: 10.71950713,
    latitude: 43.83917408,
  },
  {
    label: "Abetone Cutigliano",
    longitude: 10.7564,
    latitude: 44.1004,
  },
  {
    label: "San Marcello Piteglio",
    longitude: 10.7929,
    latitude: 44.0574,
  },
  {
    label: "Bagno a Ripoli",
    longitude: 11.3193908,
    latitude: 43.75141731,
  },
  {
    label: "Barberino di Mugello",
    longitude: 11.23857988,
    latitude: 44.00043999,
  },
  {
    label: "Barberino Val d'Elsa",
    longitude: 11.1711665,
    latitude: 43.54173193,
  },
  {
    label: "Borgo San Lorenzo",
    longitude: 11.3876842,
    latitude: 43.95478735,
  },
  {
    label: "Calenzano",
    longitude: 11.16719438,
    latitude: 43.8650838,
  },
  {
    label: "Campi Bisenzio",
    longitude: 11.13783581,
    latitude: 43.82056713,
  },
  {
    label: "Capraia e Limite",
    longitude: 10.9764279,
    latitude: 43.74246149,
  },
  {
    label: "Castelfiorentino",
    longitude: 10.97130931,
    latitude: 43.60573438,
  },
  {
    label: "Cerreto Guidi",
    longitude: 10.87757536,
    latitude: 43.75966924,
  },
  {
    label: "Certaldo",
    longitude: 11.04076435,
    latitude: 43.54777896,
  },
  {
    label: "Dicomano",
    longitude: 11.52362001,
    latitude: 43.89150207,
  },
  {
    label: "Empoli",
    longitude: 10.94718391,
    latitude: 43.71908066,
  },
  {
    label: "Fiesole",
    longitude: 11.2931763,
    latitude: 43.80686437,
  },
  {
    label: "Firenze",
    longitude: 11.25588885,
    latitude: 43.76923077,
  },
  {
    label: "Firenzuola",
    longitude: 11.37882827,
    latitude: 44.11760454,
  },
  {
    label: "Fucecchio",
    longitude: 10.81008689,
    latitude: 43.72861331,
  },
  {
    label: "Gambassi Terme",
    longitude: 10.95316019,
    latitude: 43.53861741,
  },
  {
    label: "Greve in Chianti",
    longitude: 11.31715767,
    latitude: 43.58324882,
  },
  {
    label: "Impruneta",
    longitude: 11.25440777,
    latitude: 43.68573641,
  },
  {
    label: "Lastra a Signa",
    longitude: 11.10606378,
    latitude: 43.77012377,
  },
  {
    label: "Londa",
    longitude: 11.56720489,
    latitude: 43.86010261,
  },
  {
    label: "Marradi",
    longitude: 11.61276552,
    latitude: 44.07546428,
  },
  {
    label: "Montaione",
    longitude: 10.91225102,
    latitude: 43.55227888,
  },
  {
    label: "Montelupo Fiorentino",
    longitude: 11.02076036,
    latitude: 43.72947256,
  },
  {
    label: "Montespertoli",
    longitude: 11.07533259,
    latitude: 43.64312984,
  },
  {
    label: "Palazzuolo sul Senio",
    longitude: 11.54729229,
    latitude: 44.11226722,
  },
  {
    label: "Pelago",
    longitude: 11.50636044,
    latitude: 43.77024562,
  },
  {
    label: "Pontassieve",
    longitude: 11.43986631,
    latitude: 43.77581305,
  },
  {
    label: "Reggello",
    longitude: 11.53638325,
    latitude: 43.6825495,
  },
  {
    label: "Rignano sull'Arno",
    longitude: 11.45352494,
    latitude: 43.72206137,
  },
  {
    label: "Rufina",
    longitude: 11.48791691,
    latitude: 43.82663785,
  },
  {
    label: "San Casciano in Val di Pesa",
    longitude: 11.18439255,
    latitude: 43.65656239,
  },
  {
    label: "San Godenzo",
    longitude: 11.61961735,
    latitude: 43.92627716,
  },
  {
    label: "Scandicci",
    longitude: 11.18133036,
    latitude: 43.75822592,
  },
  {
    label: "Sesto Fiorentino",
    longitude: 11.19842773,
    latitude: 43.83219044,
  },
  {
    label: "Signa",
    longitude: 11.09755773,
    latitude: 43.78238807,
  },
  {
    label: "Tavarnelle Val di Pesa",
    longitude: 11.172685,
    latitude: 43.56090425,
  },
  {
    label: "Vaglia",
    longitude: 11.28135672,
    latitude: 43.90656867,
  },
  {
    label: "Vicchio",
    longitude: 11.46543614,
    latitude: 43.93378303,
  },
  {
    label: "Vinci",
    longitude: 10.92648684,
    latitude: 43.78713658,
  },
  {
    label: "Figline e Incisa Valdarno",
    longitude: 11.4449,
    latitude: 43.6291,
  },
  {
    label: "Scarperia e San Piero",
    longitude: 11.356,
    latitude: 43.996,
  },
  {
    label: "Bibbona",
    longitude: 10.59818454,
    latitude: 43.2694391,
  },
  {
    label: "Campiglia Marittima",
    longitude: 10.61687906,
    latitude: 43.05902779,
  },
  {
    label: "Campo nell'Elba",
    longitude: 10.23362865,
    latitude: 42.74483726,
  },
  {
    label: "Capoliveri",
    longitude: 10.38033226,
    latitude: 42.743128,
  },
  {
    label: "Capraia Isola",
    longitude: 9.84226451,
    latitude: 43.049924,
  },
  {
    label: "Castagneto Carducci",
    longitude: 10.60997361,
    latitude: 43.16065888,
  },
  {
    label: "Cecina",
    longitude: 10.51936525,
    latitude: 43.31218404,
  },
  {
    label: "Collesalvetti",
    longitude: 10.47606442,
    latitude: 43.58921788,
  },
  {
    label: "Livorno",
    longitude: 10.3086781,
    latitude: 43.55234873,
  },
  {
    label: "Marciana",
    longitude: 10.16796514,
    latitude: 42.79000005,
  },
  {
    label: "Marciana Marina",
    longitude: 10.19683023,
    latitude: 42.80471612,
  },
  {
    label: "Piombino",
    longitude: 10.52741432,
    latitude: 42.92197069,
  },
  {
    label: "Porto Azzurro",
    longitude: 10.39872636,
    latitude: 42.76458226,
  },
  {
    label: "Portoferraio",
    longitude: 10.33119908,
    latitude: 42.8152666,
  },
  {
    label: "Rio Marina",
    longitude: 10.42518064,
    latitude: 42.81458647,
  },
  {
    label: "Rio nell'Elba",
    longitude: 10.39926162,
    latitude: 42.81296376,
  },
  {
    label: "Rosignano Marittimo",
    longitude: 10.47269194,
    latitude: 43.40533113,
  },
  {
    label: "San Vincenzo",
    longitude: 10.53823511,
    latitude: 43.100846,
  },
  {
    label: "Sassetta",
    longitude: 10.64398729,
    latitude: 43.12836352,
  },
  {
    label: "Suvereto",
    longitude: 10.67910503,
    latitude: 43.07925126,
  },
  {
    label: "Bientina",
    longitude: 10.61983755,
    latitude: 43.71011358,
  },
  {
    label: "Buti",
    longitude: 10.58735644,
    latitude: 43.72731222,
  },
  {
    label: "Calci",
    longitude: 10.51568636,
    latitude: 43.72555225,
  },
  {
    label: "Calcinaia",
    longitude: 10.61566661,
    latitude: 43.68299475,
  },
  {
    label: "Capannoli",
    longitude: 10.67864818,
    latitude: 43.58386618,
  },
  {
    label: "Casale Marittimo",
    longitude: 10.61552922,
    latitude: 43.29725055,
  },
  {
    label: "Cascina",
    longitude: 10.54927706,
    latitude: 43.67654137,
  },
  {
    label: "Castelfranco di Sotto",
    longitude: 10.74523981,
    latitude: 43.69969607,
  },
  {
    label: "Castellina Marittima",
    longitude: 10.57548836,
    latitude: 43.41197379,
  },
  {
    label: "Castelnuovo di Val di Cecina",
    longitude: 10.90600651,
    latitude: 43.20690066,
  },
  {
    label: "Chianni",
    longitude: 10.64255484,
    latitude: 43.48583046,
  },
  {
    label: "Fauglia",
    longitude: 10.51604084,
    latitude: 43.56700256,
  },
  {
    label: "Guardistallo",
    longitude: 10.63299137,
    latitude: 43.31332535,
  },
  {
    label: "Lajatico",
    longitude: 10.72851258,
    latitude: 43.4750754,
  },
  {
    label: "Montecatini Val di Cecina",
    longitude: 10.74815959,
    latitude: 43.39200876,
  },
  {
    label: "Montescudaio",
    longitude: 10.62778699,
    latitude: 43.32635373,
  },
  {
    label: "Monteverdi Marittimo",
    longitude: 10.71524937,
    latitude: 43.17700523,
  },
  {
    label: "Montopoli in Val d'Arno",
    longitude: 10.76042631,
    latitude: 43.66858781,
  },
  {
    label: "Orciano Pisano",
    longitude: 10.51010989,
    latitude: 43.49126495,
  },
  {
    label: "Palaia",
    longitude: 10.77307881,
    latitude: 43.60482308,
  },
  {
    label: "Peccioli",
    longitude: 10.72104789,
    latitude: 43.54800598,
  },
  {
    label: "Pisa",
    longitude: 10.40127259,
    latitude: 43.71553206,
  },
  {
    label: "Pomarance",
    longitude: 10.87381734,
    latitude: 43.30011927,
  },
  {
    label: "Ponsacco",
    longitude: 10.63360648,
    latitude: 43.62021159,
  },
  {
    label: "Pontedera",
    longitude: 10.63600988,
    latitude: 43.66392507,
  },
  {
    label: "Riparbella",
    longitude: 10.5997036,
    latitude: 43.36468323,
  },
  {
    label: "San Giuliano Terme",
    longitude: 10.44248952,
    latitude: 43.76334156,
  },
  {
    label: "San Miniato",
    longitude: 10.85239053,
    latitude: 43.67940707,
  },
  {
    label: "Santa Croce sull'Arno",
    longitude: 10.77839086,
    latitude: 43.71143945,
  },
  {
    label: "Santa Luce",
    longitude: 10.56481909,
    latitude: 43.47250273,
  },
  {
    label: "Santa Maria a Monte",
    longitude: 10.6923682,
    latitude: 43.69866516,
  },
  {
    label: "Terricciola",
    longitude: 10.6760384,
    latitude: 43.52463915,
  },
  {
    label: "Vecchiano",
    longitude: 10.38659926,
    latitude: 43.78159787,
  },
  {
    label: "Vicopisano",
    longitude: 10.58439105,
    latitude: 43.69975486,
  },
  {
    label: "Volterra",
    longitude: 10.85941327,
    latitude: 43.40170812,
  },
  {
    label: "Casciana Terme Lari",
    longitude: 10.5935,
    latitude: 43.5663,
  },
  {
    label: "Crespina Lorenzana",
    longitude: 10.561,
    latitude: 43.572,
  },
  {
    label: "Anghiari",
    longitude: 12.05683986,
    latitude: 43.54052916,
  },
  {
    label: "Arezzo",
    longitude: 11.88228845,
    latitude: 43.46642752,
  },
  {
    label: "Badia Tedalda",
    longitude: 12.18557888,
    latitude: 43.70640891,
  },
  {
    label: "Bibbiena",
    longitude: 11.81814145,
    latitude: 43.69445741,
  },
  {
    label: "Bucine",
    longitude: 11.61688357,
    latitude: 43.47908084,
  },
  {
    label: "Capolona",
    longitude: 11.85984396,
    latitude: 43.56342938,
  },
  {
    label: "Caprese Michelangelo",
    longitude: 11.9857741,
    latitude: 43.64399457,
  },
  {
    label: "Castel Focognano",
    longitude: 11.83563947,
    latitude: 43.65159472,
  },
  {
    label: "Castel San Niccolò",
    longitude: 11.70459128,
    latitude: 43.74104367,
  },
  {
    label: "Castiglion Fibocchi",
    longitude: 11.76321567,
    latitude: 43.52756607,
  },
  {
    label: "Castiglion Fiorentino",
    longitude: 11.92386582,
    latitude: 43.3416424,
  },
  {
    label: "Cavriglia",
    longitude: 11.48906249,
    latitude: 43.52369378,
  },
  {
    label: "Chitignano",
    longitude: 11.88026444,
    latitude: 43.66138179,
  },
  {
    label: "Chiusi della Verna",
    longitude: 11.93500938,
    latitude: 43.69971795,
  },
  {
    label: "Civitella in Val di Chiana",
    longitude: 11.77091094,
    latitude: 43.40321351,
  },
  {
    label: "Cortona",
    longitude: 11.98505582,
    latitude: 43.27511338,
  },
  {
    label: "Foiano della Chiana",
    longitude: 11.81847239,
    latitude: 43.25248532,
  },
  {
    label: "latitudeerina",
    longitude: 11.70929387,
    latitude: 43.50769221,
  },
  {
    label: "Loro Ciuffenna",
    longitude: 11.63153773,
    latitude: 43.59331237,
  },
  {
    label: "Lucignano",
    longitude: 11.74707426,
    latitude: 43.2754946,
  },
  {
    label: "Marciano della Chiana",
    longitude: 11.78635617,
    latitude: 43.30513199,
  },
  {
    label: "Montemignaio",
    longitude: 11.61990952,
    latitude: 43.74012371,
  },
  {
    label: "Monterchi",
    longitude: 12.11189748,
    latitude: 43.4855994,
  },
  {
    label: "Monte San Savino",
    longitude: 11.72533603,
    latitude: 43.33138534,
  },
  {
    label: "Montevarchi",
    longitude: 11.56746999,
    latitude: 43.52360592,
  },
  {
    label: "Ortignano Raggiolo",
    longitude: 11.75250906,
    latitude: 43.68151385,
  },
  {
    label: "Pergine Valdarno",
    longitude: 11.68589579,
    latitude: 43.47055703,
  },
  {
    label: "Pieve Santo Stefano",
    longitude: 12.04174551,
    latitude: 43.66849635,
  },
  {
    label: "Poppi",
    longitude: 11.76530501,
    latitude: 43.72453716,
  },
  {
    label: "San Giovanni Valdarno",
    longitude: 11.52962785,
    latitude: 43.56673601,
  },
  {
    label: "Sansepolcro",
    longitude: 12.1413222,
    latitude: 43.57014563,
  },
  {
    label: "Sestino",
    longitude: 12.29696916,
    latitude: 43.70841671,
  },
  {
    label: "Subbiano",
    longitude: 11.87028084,
    latitude: 43.575559,
  },
  {
    label: "Talla",
    longitude: 11.78712862,
    latitude: 43.60200466,
  },
  {
    label: "Terranuova Bracciolini",
    longitude: 11.58582871,
    latitude: 43.55094739,
  },
  {
    label: "Castelfranco Piandiscò",
    longitude: 11.5583,
    latitude: 43.622,
  },
  {
    label: "Pratovecchio Stia",
    longitude: 11.7084,
    latitude: 43.7982,
  },
  {
    label: "Abbadia San Salvatore",
    longitude: 11.67750649,
    latitude: 42.8809692,
  },
  {
    label: "Asciano",
    longitude: 11.55944599,
    latitude: 43.2349413,
  },
  {
    label: "Buonconvento",
    longitude: 11.48190606,
    latitude: 43.13793178,
  },
  {
    label: "Casole d'Elsa",
    longitude: 11.04312925,
    latitude: 43.34050477,
  },
  {
    label: "Castellina in Chianti",
    longitude: 11.28565793,
    latitude: 43.47001172,
  },
  {
    label: "Castelnuovo Berardenga",
    longitude: 11.50313661,
    latitude: 43.34549126,
  },
  {
    label: "Castiglione d'Orcia",
    longitude: 11.61639322,
    latitude: 43.00437783,
  },
  {
    label: "Cetona",
    longitude: 11.90064548,
    latitude: 42.96289227,
  },
  {
    label: "Chianciano Terme",
    longitude: 11.83180864,
    latitude: 43.05883948,
  },
  {
    label: "Chiusdino",
    longitude: 11.08812438,
    latitude: 43.15508188,
  },
  {
    label: "Chiusi",
    longitude: 11.94762106,
    latitude: 43.01673334,
  },
  {
    label: "Colle di Val d'Elsa",
    longitude: 11.11775175,
    latitude: 43.42121405,
  },
  {
    label: "Gaiole in Chianti",
    longitude: 11.43348047,
    latitude: 43.46876092,
  },
  {
    label: "Montepulciano",
    longitude: 11.78070207,
    latitude: 43.09255459,
  },
  {
    label: "Monteriggioni",
    longitude: 11.21861104,
    latitude: 43.3930625,
  },
  {
    label: "Monteroni d'Arbia",
    longitude: 11.42187191,
    latitude: 43.23108759,
  },
  {
    label: "Monticiano",
    longitude: 11.17972512,
    latitude: 43.13936806,
  },
  {
    label: "Murlo",
    longitude: 11.39110337,
    latitude: 43.16848863,
  },
  {
    label: "Piancastagnaio",
    longitude: 11.69182147,
    latitude: 42.84943624,
  },
  {
    label: "Pienza",
    longitude: 11.67996125,
    latitude: 43.07632711,
  },
  {
    label: "Poggibonsi",
    longitude: 11.14662795,
    latitude: 43.46687289,
  },
  {
    label: "Radda in Chianti",
    longitude: 11.37441024,
    latitude: 43.48654044,
  },
  {
    label: "Radicofani",
    longitude: 11.76637984,
    latitude: 42.89698978,
  },
  {
    label: "Radicondoli",
    longitude: 11.04131758,
    latitude: 43.26112158,
  },
  {
    label: "Rapolano Terme",
    longitude: 11.60415849,
    latitude: 43.2865348,
  },
  {
    label: "San Casciano dei Bagni",
    longitude: 11.87536298,
    latitude: 42.87131921,
  },
  {
    label: "San Gimignano",
    longitude: 11.04317837,
    latitude: 43.46776788,
  },
  {
    label: "San Quirico d'Orcia",
    longitude: 11.60328179,
    latitude: 43.06026966,
  },
  {
    label: "Sarteano",
    longitude: 11.86917024,
    latitude: 42.9896017,
  },
  {
    label: "Siena",
    longitude: 11.33190988,
    latitude: 43.31816374,
  },
  {
    label: "Sinalunga",
    longitude: 11.73606159,
    latitude: 43.21239106,
  },
  {
    label: "Sovicille",
    longitude: 11.22834289,
    latitude: 43.28018779,
  },
  {
    label: "Torrita di Siena",
    longitude: 11.77188985,
    latitude: 43.16663712,
  },
  {
    label: "Trequanda",
    longitude: 11.66777341,
    latitude: 43.1886534,
  },
  {
    label: "Montalcino",
    longitude: 11.4906,
    latitude: 43.0592,
  },
  {
    label: "Arcidosso",
    longitude: 11.53879434,
    latitude: 42.87146264,
  },
  {
    label: "Campagnatico",
    longitude: 11.27494204,
    latitude: 42.88278613,
  },
  {
    label: "Capalbio",
    longitude: 11.42201384,
    latitude: 42.45325601,
  },
  {
    label: "Castel del Piano",
    longitude: 11.53774028,
    latitude: 42.89305163,
  },
  {
    label: "Castell'Azzara",
    longitude: 11.69777485,
    latitude: 42.77354029,
  },
  {
    label: "Castiglione della Pescaia",
    longitude: 10.87882923,
    latitude: 42.76202972,
  },
  {
    label: "Cinigiano",
    longitude: 11.39417166,
    latitude: 42.89108419,
  },
  {
    label: "Civitella Paganico",
    longitude: 11.28161679,
    latitude: 42.99397382,
  },
  {
    label: "Follonica",
    longitude: 10.75988154,
    latitude: 42.92260165,
  },
  {
    label: "Gavorrano",
    longitude: 10.90568187,
    latitude: 42.92519152,
  },
  {
    label: "Grosseto",
    longitude: 11.11356398,
    latitude: 42.76026757,
  },
  {
    label: "Isola del Giglio",
    longitude: 10.90090066,
    latitude: 42.36570981,
  },
  {
    label: "Magliano in Toscana",
    longitude: 11.29353658,
    latitude: 42.59855637,
  },
  {
    label: "Manciano",
    longitude: 11.51546068,
    latitude: 42.58733998,
  },
  {
    label: "Massa Marittima",
    longitude: 10.88793453,
    latitude: 43.05020006,
  },
  {
    label: "Monte Argentario",
    longitude: 11.11584279,
    latitude: 42.43915327,
  },
  {
    label: "Montieri",
    longitude: 11.01621415,
    latitude: 43.13049342,
  },
  {
    label: "Orbetello",
    longitude: 11.20942799,
    latitude: 42.43737248,
  },
  {
    label: "Pitigliano",
    longitude: 11.66941633,
    latitude: 42.63491609,
  },
  {
    label: "Roccalbegna",
    longitude: 11.50925451,
    latitude: 42.78414266,
  },
  {
    label: "Roccastrada",
    longitude: 11.16742825,
    latitude: 43.01121349,
  },
  {
    label: "Santa Fiora",
    longitude: 11.5857496,
    latitude: 42.83136068,
  },
  {
    label: "Scansano",
    longitude: 11.33315036,
    latitude: 42.68789328,
  },
  {
    label: "Scarlino",
    longitude: 10.8519372,
    latitude: 42.90730227,
  },
  {
    label: "Seggiano",
    longitude: 11.55731399,
    latitude: 42.92798751,
  },
  {
    label: "Sorano",
    longitude: 11.71382965,
    latitude: 42.68173237,
  },
  {
    label: "Monterotondo Marittimo",
    longitude: 10.85584758,
    latitude: 43.14550719,
  },
  {
    label: "Semproniano",
    longitude: 11.54169139,
    latitude: 42.73024835,
  },
  {
    label: "Assisi",
    longitude: 12.61540396,
    latitude: 43.07082764,
  },
  {
    label: "Bastia Umbra",
    longitude: 12.5492598,
    latitude: 43.06650647,
  },
  {
    label: "Bettona",
    longitude: 12.48493174,
    latitude: 43.01296467,
  },
  {
    label: "Bevagna",
    longitude: 12.60912589,
    latitude: 42.9339354,
  },
  {
    label: "Campello sul Clitunno",
    longitude: 12.7769285,
    latitude: 42.82007685,
  },
  {
    label: "Cannara",
    longitude: 12.58325585,
    latitude: 42.99544132,
  },
  {
    label: "Cascia",
    longitude: 13.01313943,
    latitude: 42.71824629,
  },
  {
    label: "Castel Ritaldi",
    longitude: 12.67225912,
    latitude: 42.8230519,
  },
  {
    label: "Castiglione del Lago",
    longitude: 12.05357675,
    latitude: 43.1272779,
  },
  {
    label: "Cerreto di Spoleto",
    longitude: 12.91638888,
    latitude: 42.81933452,
  },
  {
    label: "Citerna",
    longitude: 12.1164701,
    latitude: 43.49826711,
  },
  {
    label: "Città della Pieve",
    longitude: 12.0050632,
    latitude: 42.95281727,
  },
  {
    label: "Città di Castello",
    longitude: 12.23814701,
    latitude: 43.45737865,
  },
  {
    label: "Collazzone",
    longitude: 12.43788685,
    latitude: 42.89426307,
  },
  {
    label: "Corciano",
    longitude: 12.28640075,
    latitude: 43.12854903,
  },
  {
    label: "Costacciaro",
    longitude: 12.71192029,
    latitude: 43.35890844,
  },
  {
    label: "Deruta",
    longitude: 12.41989716,
    latitude: 42.98223798,
  },
  {
    label: "Foligno",
    longitude: 12.703587,
    latitude: 42.95569525,
  },
  {
    label: "Fossato di Vico",
    longitude: 12.76164768,
    latitude: 43.29433869,
  },
  {
    label: "Fratta Todina",
    longitude: 12.36643365,
    latitude: 42.85753894,
  },
  {
    label: "Giano dell'Umbria",
    longitude: 12.57717981,
    latitude: 42.83371291,
  },
  {
    label: "Gualdo Cattaneo",
    longitude: 12.55584061,
    latitude: 42.90919383,
  },
  {
    label: "Gualdo Tadino",
    longitude: 12.78482744,
    latitude: 43.23094978,
  },
  {
    label: "Gubbio",
    longitude: 12.57875535,
    latitude: 43.35352094,
  },
  {
    label: "Lisciano Niccone",
    longitude: 12.14336777,
    latitude: 43.246024,
  },
  {
    label: "Magione",
    longitude: 12.20540863,
    latitude: 43.14260687,
  },
  {
    label: "Marsciano",
    longitude: 12.33954166,
    latitude: 42.91019962,
  },
  {
    label: "Massa Martana",
    longitude: 12.52301353,
    latitude: 42.77556262,
  },
  {
    label: "Monte Castello di Vibio",
    longitude: 12.35226566,
    latitude: 42.84031952,
  },
  {
    label: "Montefalco",
    longitude: 12.65251997,
    latitude: 42.89392022,
  },
  {
    label: "Monteleone di Spoleto",
    longitude: 12.9515888,
    latitude: 42.65112459,
  },
  {
    label: "Monte Santa Maria Tiberina",
    longitude: 12.16292237,
    latitude: 43.43681364,
  },
  {
    label: "Montone",
    longitude: 12.3268388,
    latitude: 43.36371769,
  },
  {
    label: "Nocera Umbra",
    longitude: 12.79031861,
    latitude: 43.11094788,
  },
  {
    label: "Norcia",
    longitude: 13.09285285,
    latitude: 42.7923616,
  },
  {
    label: "Paciano",
    longitude: 12.07100814,
    latitude: 43.02209431,
  },
  {
    label: "Panicale",
    longitude: 12.10062935,
    latitude: 43.02935621,
  },
  {
    label: "Passignano sul Trasimeno",
    longitude: 12.13688363,
    latitude: 43.18495083,
  },
  {
    label: "Perugia",
    longitude: 12.38824699,
    latitude: 43.1067584,
  },
  {
    label: "Piegaro",
    longitude: 12.08582134,
    latitude: 42.97051088,
  },
  {
    label: "Pietralunga",
    longitude: 12.43684299,
    latitude: 43.44218931,
  },
  {
    label: "Poggiodomo",
    longitude: 12.93472332,
    latitude: 42.71167666,
  },
  {
    label: "Preci",
    longitude: 13.0387186,
    latitude: 42.87868098,
  },
  {
    label: "San Giustino",
    longitude: 12.17675249,
    latitude: 43.54864869,
  },
  {
    label: "Sant'Anatolia di Narco",
    longitude: 12.83600807,
    latitude: 42.73311656,
  },
  {
    label: "Scheggia e Pascelupo",
    longitude: 12.66800081,
    latitude: 43.40373635,
  },
  {
    label: "Scheggino",
    longitude: 12.83118953,
    latitude: 42.71258733,
  },
  {
    label: "Sellano",
    longitude: 12.92706915,
    latitude: 42.88882783,
  },
  {
    label: "Sigillo",
    longitude: 12.74076941,
    latitude: 43.3312836,
  },
  {
    label: "Spello",
    longitude: 12.67126173,
    latitude: 42.99250937,
  },
  {
    label: "Spoleto",
    longitude: 12.73830119,
    latitude: 42.73448355,
  },
  {
    label: "Todi",
    longitude: 12.40689673,
    latitude: 42.7822488,
  },
  {
    label: "Torgiano",
    longitude: 12.43398052,
    latitude: 43.0257264,
  },
  {
    label: "Trevi",
    longitude: 12.74769941,
    latitude: 42.87712949,
  },
  {
    label: "Tuoro sul Trasimeno",
    longitude: 12.07173598,
    latitude: 43.20875849,
  },
  {
    label: "Umbertide",
    longitude: 12.32693492,
    latitude: 43.30646239,
  },
  {
    label: "Valfabbrica",
    longitude: 12.60129441,
    latitude: 43.15940657,
  },
  {
    label: "Vallo di Nera",
    longitude: 12.86220539,
    latitude: 42.76931597,
  },
  {
    label: "Valtopina",
    longitude: 12.75447309,
    latitude: 43.0590367,
  },
  {
    label: "Acquasparta",
    longitude: 12.54631473,
    latitude: 42.69062742,
  },
  {
    label: "Allerona",
    longitude: 11.97385887,
    latitude: 42.81170398,
  },
  {
    label: "Alviano",
    longitude: 12.29564824,
    latitude: 42.58772713,
  },
  {
    label: "Amelia",
    longitude: 12.41191794,
    latitude: 42.55889701,
  },
  {
    label: "Arrone",
    longitude: 12.76962517,
    latitude: 42.58314672,
  },
  {
    label: "Attigliano",
    longitude: 12.28942454,
    latitude: 42.51493803,
  },
  {
    label: "Baschi",
    longitude: 12.2162764,
    latitude: 42.67013123,
  },
  {
    label: "Calvi dell'Umbria",
    longitude: 12.56678631,
    latitude: 42.40114111,
  },
  {
    label: "Castel Giorgio",
    longitude: 11.97960194,
    latitude: 42.70779275,
  },
  {
    label: "Castel Viscardo",
    longitude: 12.00153506,
    latitude: 42.7557424,
  },
  {
    label: "Fabro",
    longitude: 12.01294186,
    latitude: 42.86344686,
  },
  {
    label: "Ferentillo",
    longitude: 12.78986721,
    latitude: 42.62053296,
  },
  {
    label: "Ficulle",
    longitude: 12.06634307,
    latitude: 42.83632012,
  },
  {
    label: "Giove",
    longitude: 12.3250503,
    latitude: 42.50928609,
  },
  {
    label: "Guardea",
    longitude: 12.29723847,
    latitude: 42.62353076,
  },
  {
    label: "Lugnano in Teverina",
    longitude: 12.33032186,
    latitude: 42.57428706,
  },
  {
    label: "Montecastrilli",
    longitude: 12.4877868,
    latitude: 42.64979219,
  },
  {
    label: "Montecchio",
    longitude: 12.28799914,
    latitude: 42.66237686,
  },
  {
    label: "Montefranco",
    longitude: 12.76660902,
    latitude: 42.59704856,
  },
  {
    label: "Montegabbione",
    longitude: 12.09252585,
    latitude: 42.92123817,
  },
  {
    label: "Monteleone d'Orvieto",
    longitude: 12.05156237,
    latitude: 42.91646062,
  },
  {
    label: "Narni",
    longitude: 12.51528967,
    latitude: 42.5192581,
  },
  {
    label: "Orvieto",
    longitude: 12.10831017,
    latitude: 42.71817773,
  },
  {
    label: "Otricoli",
    longitude: 12.47829719,
    latitude: 42.4233429,
  },
  {
    label: "Parrano",
    longitude: 12.10569682,
    latitude: 42.86353142,
  },
  {
    label: "Penna in Teverina",
    longitude: 12.3553855,
    latitude: 42.49301561,
  },
  {
    label: "Polino",
    longitude: 12.84380126,
    latitude: 42.58480961,
  },
  {
    label: "Porano",
    longitude: 12.10199382,
    latitude: 42.68703342,
  },
  {
    label: "San Gemini",
    longitude: 12.54624736,
    latitude: 42.61338039,
  },
  {
    label: "San Venanzo",
    longitude: 12.26620541,
    latitude: 42.86885453,
  },
  {
    label: "Stroncone",
    longitude: 12.66274663,
    latitude: 42.49825057,
  },
  {
    label: "Terni",
    longitude: 12.64668751,
    latitude: 42.56071258,
  },
  {
    label: "Avigliano Umbro",
    longitude: 12.42809534,
    latitude: 42.65508185,
  },
  {
    label: "Acquapendente",
    longitude: 11.86505396,
    latitude: 42.74404371,
  },
  {
    label: "Arlena di Castro",
    longitude: 11.82358342,
    latitude: 42.46300273,
  },
  {
    label: "Bagnoregio",
    longitude: 12.09850331,
    latitude: 42.62607501,
  },
  {
    label: "Barbarano Romano",
    longitude: 12.06596285,
    latitude: 42.25224905,
  },
  {
    label: "Bassano Romano",
    longitude: 12.19265891,
    latitude: 42.21828248,
  },
  {
    label: "Bassano in Teverina",
    longitude: 12.30840046,
    latitude: 42.46308252,
  },
  {
    label: "Blera",
    longitude: 12.02576207,
    latitude: 42.27419127,
  },
  {
    label: "Bolsena",
    longitude: 11.98758564,
    latitude: 42.64473634,
  },
  {
    label: "Bomarzo",
    longitude: 12.25137261,
    latitude: 42.49102137,
  },
  {
    label: "Calcata",
    longitude: 12.42572011,
    latitude: 42.21975805,
  },
  {
    label: "Canepina",
    longitude: 12.2336513,
    latitude: 42.3808029,
  },
  {
    label: "Canino",
    longitude: 11.74907335,
    latitude: 42.46417405,
  },
  {
    label: "Capodimonte",
    longitude: 11.90629883,
    latitude: 42.54894033,
  },
  {
    label: "Capranica",
    longitude: 12.17806154,
    latitude: 42.25667881,
  },
  {
    label: "Caprarola",
    longitude: 12.2379676,
    latitude: 42.32757149,
  },
  {
    label: "Carbognano",
    longitude: 12.26677248,
    latitude: 42.33211081,
  },
  {
    label: "Castel Sant'Elia",
    longitude: 12.36809312,
    latitude: 42.24932447,
  },
  {
    label: "Castiglione in Teverina",
    longitude: 12.20411325,
    latitude: 42.64495596,
  },
  {
    label: "Celleno",
    longitude: 12.12640548,
    latitude: 42.55980332,
  },
  {
    label: "Cellere",
    longitude: 11.76784122,
    latitude: 42.50798961,
  },
  {
    label: "Civita Castellana",
    longitude: 12.41176641,
    latitude: 42.28917672,
  },
  {
    label: "Civitella d'Agliano",
    longitude: 12.18795854,
    latitude: 42.60622651,
  },
  {
    label: "Corchiano",
    longitude: 12.3566403,
    latitude: 42.34558918,
  },
  {
    label: "Fabrica di Roma",
    longitude: 12.29517695,
    latitude: 42.33473421,
  },
  {
    label: "Faleria",
    longitude: 12.44319267,
    latitude: 42.2262545,
  },
  {
    label: "Farnese",
    longitude: 11.72449715,
    latitude: 42.55018022,
  },
  {
    label: "Gallese",
    longitude: 12.4044919,
    latitude: 42.3724145,
  },
  {
    label: "Gradoli",
    longitude: 11.85609185,
    latitude: 42.64373853,
  },
  {
    label: "Graffignano",
    longitude: 12.20480114,
    latitude: 42.57319312,
  },
  {
    label: "Grotte di Castro",
    longitude: 11.87023789,
    latitude: 42.67394823,
  },
  {
    label: "Ischia di Castro",
    longitude: 11.75781453,
    latitude: 42.54417034,
  },
  {
    label: "latitudeera",
    longitude: 11.8284298,
    latitude: 42.62640578,
  },
  {
    label: "Lubriano",
    longitude: 12.10712247,
    latitude: 42.63534826,
  },
  {
    label: "Marta",
    longitude: 11.92515695,
    latitude: 42.53500095,
  },
  {
    label: "Montalto di Castro",
    longitude: 11.6041783,
    latitude: 42.35322958,
  },
  {
    label: "Montefiascone",
    longitude: 12.03036871,
    latitude: 42.5376144,
  },
  {
    label: "Monte Romano",
    longitude: 11.89877735,
    latitude: 42.26791669,
  },
  {
    label: "Monterosi",
    longitude: 12.30994148,
    latitude: 42.19559259,
  },
  {
    label: "Nepi",
    longitude: 12.34881332,
    latitude: 42.2423702,
  },
  {
    label: "Onano",
    longitude: 11.81498404,
    latitude: 42.69372417,
  },
  {
    label: "Oriolo Romano",
    longitude: 12.13806733,
    latitude: 42.15846313,
  },
  {
    label: "Orte",
    longitude: 12.38639201,
    latitude: 42.46044511,
  },
  {
    label: "Piansano",
    longitude: 11.82824744,
    latitude: 42.5178869,
  },
  {
    label: "Proceno",
    longitude: 11.83043541,
    latitude: 42.75720626,
  },
  {
    label: "Ronciglione",
    longitude: 12.21573343,
    latitude: 42.291719,
  },
  {
    label: "Villa San Giovanni in Tuscia",
    longitude: 12.05294039,
    latitude: 42.28352046,
  },
  {
    label: "San Lorenzo Nuovo",
    longitude: 11.90609726,
    latitude: 42.68559315,
  },
  {
    label: "Soriano nel Cimino",
    longitude: 12.23472232,
    latitude: 42.41892819,
  },
  {
    label: "Sutri",
    longitude: 12.22484309,
    latitude: 42.24202105,
  },
  {
    label: "Tarquinia",
    longitude: 11.75823363,
    latitude: 42.25445005,
  },
  {
    label: "Tessennano",
    longitude: 11.79232316,
    latitude: 42.48041292,
  },
  {
    label: "Tuscania",
    longitude: 11.87564309,
    latitude: 42.41671675,
  },
  {
    label: "Valentano",
    longitude: 11.81838045,
    latitude: 42.56920061,
  },
  {
    label: "Vallerano",
    longitude: 12.26395806,
    latitude: 42.38378156,
  },
  {
    label: "Vasanello",
    longitude: 12.34649455,
    latitude: 42.41815812,
  },
  {
    label: "Vejano",
    longitude: 12.0952763,
    latitude: 42.21630253,
  },
  {
    label: "Vetralla",
    longitude: 12.05352034,
    latitude: 42.32081893,
  },
  {
    label: "Vignanello",
    longitude: 12.27807018,
    latitude: 42.3838434,
  },
  {
    label: "Viterbo",
    longitude: 12.10473417,
    latitude: 42.41738279,
  },
  {
    label: "Vitorchiano",
    longitude: 12.17422339,
    latitude: 42.47031031,
  },
  {
    label: "Accumoli",
    longitude: 13.24768618,
    latitude: 42.69478482,
  },
  {
    label: "Amatrice",
    longitude: 13.28838645,
    latitude: 42.62938445,
  },
  {
    label: "Antrodoco",
    longitude: 13.08020398,
    latitude: 42.41753444,
  },
  {
    label: "Ascrea",
    longitude: 12.99672207,
    latitude: 42.19569977,
  },
  {
    label: "Belmonte in Sabina",
    longitude: 12.89269099,
    latitude: 42.31825684,
  },
  {
    label: "Borbona",
    longitude: 13.13254691,
    latitude: 42.51224599,
  },
  {
    label: "Borgorose",
    longitude: 13.23257107,
    latitude: 42.19338673,
  },
  {
    label: "Borgo Velino",
    longitude: 13.05849178,
    latitude: 42.40560326,
  },
  {
    label: "Cantalice",
    longitude: 12.90354982,
    latitude: 42.4682975,
  },
  {
    label: "Cantalupo in Sabina",
    longitude: 12.6467734,
    latitude: 42.30787333,
  },
  {
    label: "Casaprota",
    longitude: 12.80366243,
    latitude: 42.25184635,
  },
  {
    label: "Casperia",
    longitude: 12.67030246,
    latitude: 42.33795917,
  },
  {
    label: "Castel di Tora",
    longitude: 12.9643336,
    latitude: 42.21549311,
  },
  {
    label: "Castelnuovo di Farfa",
    longitude: 12.74269994,
    latitude: 42.23191048,
  },
  {
    label: "Castel Sant'Angelo",
    longitude: 13.02993539,
    latitude: 42.39477735,
  },
  {
    label: "Cittaducale",
    longitude: 12.94815044,
    latitude: 42.38666058,
  },
  {
    label: "Cittareale",
    longitude: 13.15983457,
    latitude: 42.61761561,
  },
  {
    label: "Collalto Sabino",
    longitude: 13.04828421,
    latitude: 42.13642854,
  },
  {
    label: "Colle di Tora",
    longitude: 12.94777706,
    latitude: 42.21208443,
  },
  {
    label: "Collegiove",
    longitude: 13.03707139,
    latitude: 42.17488382,
  },
  {
    label: "Collevecchio",
    longitude: 12.55272005,
    latitude: 42.33544102,
  },
  {
    label: "Colli sul Velino",
    longitude: 12.77970786,
    latitude: 42.49898368,
  },
  {
    label: "Concerviano",
    longitude: 12.98572319,
    latitude: 42.3234483,
  },
  {
    label: "Configni",
    longitude: 12.64234768,
    latitude: 42.42362526,
  },
  {
    label: "Contigliano",
    longitude: 12.76654796,
    latitude: 42.41074729,
  },
  {
    label: "Cottanello",
    longitude: 12.68643862,
    latitude: 42.40618185,
  },
  {
    label: "Fara in Sabina",
    longitude: 12.72925133,
    latitude: 42.20952713,
  },
  {
    label: "Fiamignano",
    longitude: 13.12550508,
    latitude: 42.264822,
  },
  {
    label: "Forano",
    longitude: 12.59503004,
    latitude: 42.29791477,
  },
  {
    label: "Frasso Sabino",
    longitude: 12.80531328,
    latitude: 42.2296654,
  },
  {
    label: "Greccio",
    longitude: 12.76910487,
    latitude: 42.45368971,
  },
  {
    label: "Labro",
    longitude: 12.80017121,
    latitude: 42.52577061,
  },
  {
    label: "Leonessa",
    longitude: 12.9638991,
    latitude: 42.56148656,
  },
  {
    label: "Longone Sabino",
    longitude: 12.96549467,
    latitude: 42.27242688,
  },
  {
    label: "Magliano Sabina",
    longitude: 12.48099427,
    latitude: 42.36133088,
  },
  {
    label: "Marcetelli",
    longitude: 13.0472278,
    latitude: 42.22678804,
  },
  {
    label: "Micigliano",
    longitude: 13.05493259,
    latitude: 42.45094662,
  },
  {
    label: "Mompeo",
    longitude: 12.75117077,
    latitude: 42.24771216,
  },
  {
    label: "Montasola",
    longitude: 12.68099168,
    latitude: 42.38556136,
  },
  {
    label: "Montebuono",
    longitude: 12.59770719,
    latitude: 42.36590805,
  },
  {
    label: "Monteleone Sabino",
    longitude: 12.85902262,
    latitude: 42.23257917,
  },
  {
    label: "Montenero Sabino",
    longitude: 12.81316502,
    latitude: 42.28124115,
  },
  {
    label: "Monte San Giovanni in Sabina",
    longitude: 12.77768379,
    latitude: 42.32818184,
  },
  {
    label: "Montopoli di Sabina",
    longitude: 12.69191102,
    latitude: 42.244707,
  },
  {
    label: "Morro Reatino",
    longitude: 12.83216365,
    latitude: 42.52584956,
  },
  {
    label: "Nespolo",
    longitude: 13.06948311,
    latitude: 42.15574574,
  },
  {
    label: "Orvinio",
    longitude: 12.93747278,
    latitude: 42.13155692,
  },
  {
    label: "Paganico Sabino",
    longitude: 12.99748681,
    latitude: 42.18954914,
  },
  {
    label: "Pescorocchiano",
    longitude: 13.14849923,
    latitude: 42.20605943,
  },
  {
    label: "Petrella Salto",
    longitude: 13.06836049,
    latitude: 42.29456266,
  },
  {
    label: "Poggio Bustone",
    longitude: 12.88579738,
    latitude: 42.50213769,
  },
  {
    label: "Poggio Catino",
    longitude: 12.69180997,
    latitude: 42.29507251,
  },
  {
    label: "Poggio Mirteto",
    longitude: 12.68365453,
    latitude: 42.26459033,
  },
  {
    label: "Poggio Moiano",
    longitude: 12.87711591,
    latitude: 42.20187049,
  },
  {
    label: "Poggio Nativo",
    longitude: 12.79532867,
    latitude: 42.21582394,
  },
  {
    label: "Poggio San Lorenzo",
    longitude: 12.84551099,
    latitude: 42.25290356,
  },
  {
    label: "Posta",
    longitude: 13.0962445,
    latitude: 42.52345282,
  },
  {
    label: "Pozzaglia Sabina",
    longitude: 12.96455168,
    latitude: 42.15979452,
  },
  {
    label: "Rieti",
    longitude: 12.86205941,
    latitude: 42.40488444,
  },
  {
    label: "Rivodutri",
    longitude: 12.85515228,
    latitude: 42.51603831,
  },
  {
    label: "Roccantica",
    longitude: 12.69233445,
    latitude: 42.32080116,
  },
  {
    label: "Rocca Sinibalda",
    longitude: 12.92576363,
    latitude: 42.27421911,
  },
  {
    label: "Salisano",
    longitude: 12.74561588,
    latitude: 42.25748301,
  },
  {
    label: "Scandriglia",
    longitude: 12.84210334,
    latitude: 42.16506269,
  },
  {
    label: "Selci",
    longitude: 12.62266916,
    latitude: 42.31971812,
  },
  {
    label: "Stimigliano",
    longitude: 12.56568827,
    latitude: 42.29775911,
  },
  {
    label: "Tarano",
    longitude: 12.5948933,
    latitude: 42.35584072,
  },
  {
    label: "Toffia",
    longitude: 12.752368,
    latitude: 42.21251146,
  },
  {
    label: "Torricella in Sabina",
    longitude: 12.86972234,
    latitude: 42.26074633,
  },
  {
    label: "Torri in Sabina",
    longitude: 12.63846745,
    latitude: 42.35308155,
  },
  {
    label: "Turania",
    longitude: 13.00977455,
    latitude: 42.137431,
  },
  {
    label: "Vacone",
    longitude: 12.63579383,
    latitude: 42.3862681,
  },
  {
    label: "Varco Sabino",
    longitude: 13.01996173,
    latitude: 42.23963177,
  },
  {
    label: "Affile",
    longitude: 13.09728573,
    latitude: 41.8843797,
  },
  {
    label: "Agosta",
    longitude: 13.03208284,
    latitude: 41.98200211,
  },
  {
    label: "Albano Laziale",
    longitude: 12.65870332,
    latitude: 41.72911179,
  },
  {
    label: "Allumiere",
    longitude: 11.90213898,
    latitude: 42.15653936,
  },
  {
    label: "Anguillara Sabazia",
    longitude: 12.26978922,
    latitude: 42.09291489,
  },
  {
    label: "Anticoli Corrado",
    longitude: 12.99038438,
    latitude: 42.00933844,
  },
  {
    label: "Anzio",
    longitude: 12.62871796,
    latitude: 41.44805488,
  },
  {
    label: "Arcinazzo Romano",
    longitude: 13.11410074,
    latitude: 41.88021199,
  },
  {
    label: "Ariccia",
    longitude: 12.67058132,
    latitude: 41.72002036,
  },
  {
    label: "Arsoli",
    longitude: 13.01652592,
    latitude: 42.04140907,
  },
  {
    label: "Artena",
    longitude: 12.91536174,
    latitude: 41.73866267,
  },
  {
    label: "Bellegra",
    longitude: 13.02804743,
    latitude: 41.88107829,
  },
  {
    label: "Bracciano",
    longitude: 12.17562398,
    latitude: 42.10335079,
  },
  {
    label: "Camerata Nuova",
    longitude: 13.10813431,
    latitude: 42.01844898,
  },
  {
    label: "Campagnano di Roma",
    longitude: 12.38325316,
    latitude: 42.14081533,
  },
  {
    label: "Canale Monterano",
    longitude: 12.1035349,
    latitude: 42.13666615,
  },
  {
    label: "Canterano",
    longitude: 13.03756772,
    latitude: 41.94185299,
  },
  {
    label: "Capena",
    longitude: 12.53927993,
    latitude: 42.14078338,
  },
  {
    label: "Capranica Prenestina",
    longitude: 12.95234869,
    latitude: 41.86207232,
  },
  {
    label: "Carpineto Romano",
    longitude: 13.085303,
    latitude: 41.60441817,
  },
  {
    label: "Casape",
    longitude: 12.88613851,
    latitude: 41.90640585,
  },
  {
    label: "Castel Gandolfo",
    longitude: 12.65046085,
    latitude: 41.74675972,
  },
  {
    label: "Castel Madama",
    longitude: 12.86857387,
    latitude: 41.97566126,
  },
  {
    label: "Castelnuovo di Porto",
    longitude: 12.50044876,
    latitude: 42.1257946,
  },
  {
    label: "Castel San Pietro Romano",
    longitude: 12.89475264,
    latitude: 41.8463723,
  },
  {
    label: "Cave",
    longitude: 12.93178739,
    latitude: 41.81808415,
  },
  {
    label: "Cerreto Laziale",
    longitude: 12.98165037,
    latitude: 41.94436521,
  },
  {
    label: "Cervara di Roma",
    longitude: 13.06769786,
    latitude: 41.98808485,
  },
  {
    label: "Cerveteri",
    longitude: 12.09896835,
    latitude: 41.99824253,
  },
  {
    label: "Ciciliano",
    longitude: 12.9416369,
    latitude: 41.96123855,
  },
  {
    label: "Cineto Romano",
    longitude: 12.96392369,
    latitude: 42.05024048,
  },
  {
    label: "Civitavecchia",
    longitude: 11.79337907,
    latitude: 42.09212993,
  },
  {
    label: "Civitella San Paolo",
    longitude: 12.58250291,
    latitude: 42.19588589,
  },
  {
    label: "Colleferro",
    longitude: 13.00559155,
    latitude: 41.73041246,
  },
  {
    label: "Colonna",
    longitude: 12.75111571,
    latitude: 41.83430617,
  },
  {
    label: "Fiano Romano",
    longitude: 12.59244302,
    latitude: 42.17200417,
  },
  {
    label: "Filacciano",
    longitude: 12.59771008,
    latitude: 42.25517909,
  },
  {
    label: "Formello",
    longitude: 12.40084704,
    latitude: 42.08022139,
  },
  {
    label: "Frascati",
    longitude: 12.68079241,
    latitude: 41.80598978,
  },
  {
    label: "Gallicano nel Lazio",
    longitude: 12.82256314,
    latitude: 41.87003789,
  },
  {
    label: "Gavignano",
    longitude: 13.05260268,
    latitude: 41.69808256,
  },
  {
    label: "Genazzano",
    longitude: 12.97235401,
    latitude: 41.83171814,
  },
  {
    label: "Genzano di Roma",
    longitude: 12.69132211,
    latitude: 41.70690415,
  },
  {
    label: "Gerano",
    longitude: 12.99397258,
    latitude: 41.93381754,
  },
  {
    label: "Gorga",
    longitude: 13.11078959,
    latitude: 41.6559423,
  },
  {
    label: "Grottaferrata",
    longitude: 12.66772877,
    latitude: 41.78846097,
  },
  {
    label: "Guidonia Montecelio",
    longitude: 12.72638633,
    latitude: 42.000078,
  },
  {
    label: "Jenne",
    longitude: 13.16781049,
    latitude: 41.88747034,
  },
  {
    label: "Labico",
    longitude: 12.88606511,
    latitude: 41.78562954,
  },
  {
    label: "Lanuvio",
    longitude: 12.7029783,
    latitude: 41.67976979,
  },
  {
    label: "Licenza",
    longitude: 12.90172348,
    latitude: 42.07436079,
  },
  {
    label: "Magliano Romano",
    longitude: 12.43629649,
    latitude: 42.15986389,
  },
  {
    label: "Mandela",
    longitude: 12.92375346,
    latitude: 42.0319438,
  },
  {
    label: "Manziana",
    longitude: 12.12824574,
    latitude: 42.12945259,
  },
  {
    label: "Marano Equo",
    longitude: 13.01686199,
    latitude: 41.99465909,
  },
  {
    label: "Marcellina",
    longitude: 12.80724546,
    latitude: 42.02299629,
  },
  {
    label: "Marino",
    longitude: 12.65860986,
    latitude: 41.76970177,
  },
  {
    label: "Mazzano Romano",
    longitude: 12.39943403,
    latitude: 42.20466917,
  },
  {
    label: "Mentana",
    longitude: 12.63722447,
    latitude: 42.03370532,
  },
  {
    label: "Monte Compatri",
    longitude: 12.73699939,
    latitude: 41.8082795,
  },
  {
    label: "Monteflavio",
    longitude: 12.83070249,
    latitude: 42.11099032,
  },
  {
    label: "Montelanico",
    longitude: 13.0395477,
    latitude: 41.64954284,
  },
  {
    label: "Montelibretti",
    longitude: 12.73887802,
    latitude: 42.13494236,
  },
  {
    label: "Monte Porzio Catone",
    longitude: 12.71666163,
    latitude: 41.81560031,
  },
  {
    label: "Monterotondo",
    longitude: 12.61624809,
    latitude: 42.05132067,
  },
  {
    label: "Montorio Romano",
    longitude: 12.80593453,
    latitude: 42.13726876,
  },
  {
    label: "Moricone",
    longitude: 12.77393687,
    latitude: 42.11659725,
  },
  {
    label: "Morlupo",
    longitude: 12.50441308,
    latitude: 42.14283117,
  },
  {
    label: "Nazzano",
    longitude: 12.59725095,
    latitude: 42.22864975,
  },
  {
    label: "Nemi",
    longitude: 12.71442613,
    latitude: 41.72015954,
  },
  {
    label: "Nerola",
    longitude: 12.78665137,
    latitude: 42.16202285,
  },
  {
    label: "Nettuno",
    longitude: 12.66141549,
    latitude: 41.45748878,
  },
  {
    label: "Olevano Romano",
    longitude: 13.03247714,
    latitude: 41.8607251,
  },
  {
    label: "Palestrina",
    longitude: 12.88915634,
    latitude: 41.83942538,
  },
  {
    label: "Palombara Sabina",
    longitude: 12.76511218,
    latitude: 42.066546,
  },
  {
    label: "Percile",
    longitude: 12.91040936,
    latitude: 42.09466158,
  },
  {
    label: "Pisoniano",
    longitude: 12.95900084,
    latitude: 41.90814687,
  },
  {
    label: "Poli",
    longitude: 12.89328898,
    latitude: 41.88717142,
  },
  {
    label: "Pomezia",
    longitude: 12.50163468,
    latitude: 41.67003149,
  },
  {
    label: "Ponzano Romano",
    longitude: 12.57186287,
    latitude: 42.25820276,
  },
  {
    label: "Riano",
    longitude: 12.52273093,
    latitude: 42.09138788,
  },
  {
    label: "Rignano Flaminio",
    longitude: 12.48070804,
    latitude: 42.20974459,
  },
  {
    label: "Riofreddo",
    longitude: 12.99949492,
    latitude: 42.0613698,
  },
  {
    label: "Rocca Canterano",
    longitude: 13.02386774,
    latitude: 41.95497407,
  },
  {
    label: "Rocca di Cave",
    longitude: 12.94559134,
    latitude: 41.84634046,
  },
  {
    label: "Rocca di Papa",
    longitude: 12.70842384,
    latitude: 41.76199841,
  },
  {
    label: "Roccagiovine",
    longitude: 12.89860198,
    latitude: 42.05023163,
  },
  {
    label: "Rocca Priora",
    longitude: 12.76324846,
    latitude: 41.79422359,
  },
  {
    label: "Rocca Santo Stefano",
    longitude: 13.02446967,
    latitude: 41.91052219,
  },
  {
    label: "Roiate",
    longitude: 13.06445203,
    latitude: 41.87412437,
  },
  {
    label: "Roma",
    longitude: 12.48366723,
    latitude: 41.89277044,
  },
  {
    label: "Roviano",
    longitude: 12.99452797,
    latitude: 42.02614365,
  },
  {
    label: "Sacrofano",
    longitude: 12.44781552,
    latitude: 42.10575247,
  },
  {
    label: "Sambuci",
    longitude: 12.93740404,
    latitude: 41.98627446,
  },
  {
    label: "San Gregorio da Sassola",
    longitude: 12.87253225,
    latitude: 41.91815747,
  },
  {
    label: "San Polo dei Cavalieri",
    longitude: 12.84004589,
    latitude: 42.01230773,
  },
  {
    label: "Santa Marinella",
    longitude: 11.85414402,
    latitude: 42.0335655,
  },
  {
    label: "Sant'Angelo Romano",
    longitude: 12.71302578,
    latitude: 42.03401823,
  },
  {
    label: "Sant'Oreste",
    longitude: 12.51988081,
    latitude: 42.23405018,
  },
  {
    label: "San Vito Romano",
    longitude: 12.9833423,
    latitude: 41.88276576,
  },
  {
    label: "Saracinesco",
    longitude: 12.95229741,
    latitude: 42.00407117,
  },
  {
    label: "Segni",
    longitude: 13.02317379,
    latitude: 41.69145242,
  },
  {
    label: "Subiaco",
    longitude: 13.09973738,
    latitude: 41.92523064,
  },
  {
    label: "Tivoli",
    longitude: 12.79846907,
    latitude: 41.9634984,
  },
  {
    label: "Tolfa",
    longitude: 11.93775828,
    latitude: 42.14957732,
  },
  {
    label: "Torrita Tiberina",
    longitude: 12.61740749,
    latitude: 42.2367824,
  },
  {
    label: "Trevignano Romano",
    longitude: 12.24515101,
    latitude: 42.15575986,
  },
  {
    label: "Vallepietra",
    longitude: 13.23084982,
    latitude: 41.9267951,
  },
  {
    label: "Vallinfreda",
    longitude: 12.99540737,
    latitude: 42.08448991,
  },
  {
    label: "Valmontone",
    longitude: 12.91774603,
    latitude: 41.77688498,
  },
  {
    label: "Velletri",
    longitude: 12.77719681,
    latitude: 41.68638171,
  },
  {
    label: "Vicovaro",
    longitude: 12.891534,
    latitude: 42.01448366,
  },
  {
    label: "Vivaro Romano",
    longitude: 13.00664217,
    latitude: 42.10031667,
  },
  {
    label: "Zagarolo",
    longitude: 12.82963823,
    latitude: 41.83986542,
  },
  {
    label: "Lariano",
    longitude: 12.83261406,
    latitude: 41.72792282,
  },
  {
    label: "Ladispoli",
    longitude: 12.0699859,
    latitude: 41.95487983,
  },
  {
    label: "Ardea",
    longitude: 12.54537478,
    latitude: 41.60929055,
  },
  {
    label: "Ciampino",
    longitude: 12.60168888,
    latitude: 41.80288346,
  },
  {
    label: "San Cesareo",
    longitude: 12.80070668,
    latitude: 41.8214659,
  },
  {
    label: "Fiumicino",
    longitude: 12.23686815,
    latitude: 41.77221205,
  },
  {
    label: "Fonte Nuova",
    longitude: 12.61887253,
    latitude: 41.99627325,
  },
  {
    label: "Aprilia",
    longitude: 12.64816922,
    latitude: 41.59415376,
  },
  {
    label: "Bassiano",
    longitude: 13.03225445,
    latitude: 41.54933965,
  },
  {
    label: "Campodimele",
    longitude: 13.53023042,
    latitude: 41.38979943,
  },
  {
    label: "Castelforte",
    longitude: 13.82274862,
    latitude: 41.30023026,
  },
  {
    label: "Cisterna di latitudeina",
    longitude: 12.82827445,
    latitude: 41.59277039,
  },
  {
    label: "Cori",
    longitude: 12.91320333,
    latitude: 41.64241501,
  },
  {
    label: "Fondi",
    longitude: 13.42990802,
    latitude: 41.35691056,
  },
  {
    label: "Formia",
    longitude: 13.60592654,
    latitude: 41.25568883,
  },
  {
    label: "Gaeta",
    longitude: 13.57195666,
    latitude: 41.21336432,
  },
  {
    label: "Itri",
    longitude: 13.5312565,
    latitude: 41.29091913,
  },
  {
    label: "latitudeina",
    longitude: 12.90368485,
    latitude: 41.46759464,
  },
  {
    label: "Lenola",
    longitude: 13.46182316,
    latitude: 41.40949055,
  },
  {
    label: "Maenza",
    longitude: 13.18067589,
    latitude: 41.52275399,
  },
  {
    label: "Minturno",
    longitude: 13.74656442,
    latitude: 41.26245383,
  },
  {
    label: "Monte San Biagio",
    longitude: 13.35115039,
    latitude: 41.35296788,
  },
  {
    label: "Norma",
    longitude: 12.97252223,
    latitude: 41.58528452,
  },
  {
    label: "Pontinia",
    longitude: 13.0440276,
    latitude: 41.40822926,
  },
  {
    label: "Ponza",
    longitude: 12.96868459,
    latitude: 40.89486838,
  },
  {
    label: "Priverno",
    longitude: 13.18065288,
    latitude: 41.47400223,
  },
  {
    label: "Prossedi",
    longitude: 13.26105909,
    latitude: 41.51691062,
  },
  {
    label: "Roccagorga",
    longitude: 13.15533995,
    latitude: 41.52611175,
  },
  {
    label: "Rocca Massima",
    longitude: 12.91986368,
    latitude: 41.67836612,
  },
  {
    label: "Roccasecca dei Volsci",
    longitude: 13.21249479,
    latitude: 41.47982903,
  },
  {
    label: "Sabaudia",
    longitude: 13.02490624,
    latitude: 41.30012397,
  },
  {
    label: "San Felice Circeo",
    longitude: 13.08869724,
    latitude: 41.23348225,
  },
  {
    label: "Santi Cosma e Damiano",
    longitude: 13.81401718,
    latitude: 41.29983205,
  },
  {
    label: "Sermoneta",
    longitude: 12.98383195,
    latitude: 41.54973365,
  },
  {
    label: "Sezze",
    longitude: 13.05876813,
    latitude: 41.49794258,
  },
  {
    label: "Sonnino",
    longitude: 13.24168557,
    latitude: 41.41424407,
  },
  {
    label: "Sperlonga",
    longitude: 13.4361534,
    latitude: 41.25734903,
  },
  {
    label: "Spigno Saturnia",
    longitude: 13.73422918,
    latitude: 41.30554026,
  },
  {
    label: "Terracina",
    longitude: 13.24878732,
    latitude: 41.29189727,
  },
  {
    label: "Ventotene",
    longitude: 13.43304688,
    latitude: 40.79636158,
  },
  {
    label: "Acquafondata",
    longitude: 13.95216686,
    latitude: 41.54312171,
  },
  {
    label: "Acuto",
    longitude: 13.17302627,
    latitude: 41.79186612,
  },
  {
    label: "Alatituderi",
    longitude: 13.34210735,
    latitude: 41.72647125,
  },
  {
    label: "Alvito",
    longitude: 13.74263677,
    latitude: 41.68776332,
  },
  {
    label: "Amaseno",
    longitude: 13.33578221,
    latitude: 41.46812425,
  },
  {
    label: "Anagni",
    longitude: 13.15452041,
    latitude: 41.74393633,
  },
  {
    label: "Aquino",
    longitude: 13.70399751,
    latitude: 41.49248588,
  },
  {
    label: "Arce",
    longitude: 13.57406212,
    latitude: 41.5877795,
  },
  {
    label: "Arnara",
    longitude: 13.38767675,
    latitude: 41.58376978,
  },
  {
    label: "Arpino",
    longitude: 13.61079096,
    latitude: 41.64812799,
  },
  {
    label: "Atina",
    longitude: 13.80035528,
    latitude: 41.61982849,
  },
  {
    label: "Ausonia",
    longitude: 13.74885639,
    latitude: 41.35424556,
  },
  {
    label: "Belmonte Castello",
    longitude: 13.81174168,
    latitude: 41.57825599,
  },
  {
    label: "Boville Ernica",
    longitude: 13.47086194,
    latitude: 41.64313009,
  },
  {
    label: "Broccostella",
    longitude: 13.63445627,
    latitude: 41.70351486,
  },
  {
    label: "Campoli Appennino",
    longitude: 13.678822,
    latitude: 41.73786216,
  },
  {
    label: "Casalatitudetico",
    longitude: 13.72466715,
    latitude: 41.62146632,
  },
  {
    label: "Casalvieri",
    longitude: 13.71315701,
    latitude: 41.63141639,
  },
  {
    label: "Cassino",
    longitude: 13.83012821,
    latitude: 41.49267611,
  },
  {
    label: "Castelliri",
    longitude: 13.55048133,
    latitude: 41.67931153,
  },
  {
    label: "Castelnuovo Parano",
    longitude: 13.75533546,
    latitude: 41.37776072,
  },
  {
    label: "Castrocielo",
    longitude: 13.69628897,
    latitude: 41.53117816,
  },
  {
    label: "Castro dei Volsci",
    longitude: 13.40667433,
    latitude: 41.50865805,
  },
  {
    label: "Ceccano",
    longitude: 13.33363716,
    latitude: 41.5685648,
  },
  {
    label: "Ceprano",
    longitude: 13.51942338,
    latitude: 41.54568915,
  },
  {
    label: "Cervaro",
    longitude: 13.90505228,
    latitude: 41.48103896,
  },
  {
    label: "Colfelice",
    longitude: 13.60565991,
    latitude: 41.55588125,
  },
  {
    label: "Collepardo",
    longitude: 13.36771371,
    latitude: 41.76118715,
  },
  {
    label: "Colle San Magno",
    longitude: 13.6939922,
    latitude: 41.5498351,
  },
  {
    label: "Coreno Ausonio",
    longitude: 13.77731912,
    latitude: 41.34629044,
  },
  {
    label: "Esperia",
    longitude: 13.68493518,
    latitude: 41.38519021,
  },
  {
    label: "Falvaterra",
    longitude: 13.52259063,
    latitude: 41.50386136,
  },
  {
    label: "Ferentino",
    longitude: 13.25284809,
    latitude: 41.69231575,
  },
  {
    label: "Filettino",
    longitude: 13.32412179,
    latitude: 41.89024232,
  },
  {
    label: "Fiuggi",
    longitude: 13.22124822,
    latitude: 41.80425759,
  },
  {
    label: "Fontana Liri",
    longitude: 13.54994777,
    latitude: 41.61106913,
  },
  {
    label: "Fontechiari",
    longitude: 13.67654778,
    latitude: 41.67003168,
  },
  {
    label: "Frosinone",
    longitude: 13.35117166,
    latitude: 41.63964569,
  },
  {
    label: "Fumone",
    longitude: 13.28946381,
    latitude: 41.72795166,
  },
  {
    label: "Gallinaro",
    longitude: 13.79863898,
    latitude: 41.65778491,
  },
  {
    label: "Giuliano di Roma",
    longitude: 13.27971133,
    latitude: 41.53983203,
  },
  {
    label: "Guarcino",
    longitude: 13.31389882,
    latitude: 41.79951641,
  },
  {
    label: "Isola del Liri",
    longitude: 13.57497154,
    latitude: 41.678419,
  },
  {
    label: "Monte San Giovanni Campano",
    longitude: 13.51586013,
    latitude: 41.63904533,
  },
  {
    label: "Morolo",
    longitude: 13.19702178,
    latitude: 41.63783758,
  },
  {
    label: "Paliano",
    longitude: 13.05934171,
    latitude: 41.80489346,
  },
  {
    label: "Pastena",
    longitude: 13.49201899,
    latitude: 41.4679456,
  },
  {
    label: "Patrica",
    longitude: 13.2438732,
    latitude: 41.59212998,
  },
  {
    label: "Pescosolido",
    longitude: 13.65572861,
    latitude: 41.74971968,
  },
  {
    label: "Picinisco",
    longitude: 13.86717295,
    latitude: 41.64682749,
  },
  {
    label: "Pico",
    longitude: 13.55871303,
    latitude: 41.45170844,
  },
  {
    label: "Piedimonte San Germano",
    longitude: 13.75034131,
    latitude: 41.49737148,
  },
  {
    label: "Piglio",
    longitude: 13.14554512,
    latitude: 41.8301581,
  },
  {
    label: "Pignataro Interamna",
    longitude: 13.78895277,
    latitude: 41.43728173,
  },
  {
    label: "Pofi",
    longitude: 13.41527811,
    latitude: 41.56545286,
  },
  {
    label: "Pontecorvo",
    longitude: 13.66537138,
    latitude: 41.45740222,
  },
  {
    label: "Posta Fibreno",
    longitude: 13.69687631,
    latitude: 41.69339124,
  },
  {
    label: "Ripi",
    longitude: 13.42648911,
    latitude: 41.61271944,
  },
  {
    label: "Rocca d'Arce",
    longitude: 13.5837364,
    latitude: 41.58753355,
  },
  {
    label: "Roccasecca",
    longitude: 13.66861615,
    latitude: 41.55183951,
  },
  {
    label: "San Biagio Saracinisco",
    longitude: 13.927667,
    latitude: 41.61349672,
  },
  {
    label: "San Donato Val di Comino",
    longitude: 13.81377968,
    latitude: 41.70814975,
  },
  {
    label: "San Giorgio a Liri",
    longitude: 13.76661817,
    latitude: 41.40686611,
  },
  {
    label: "San Giovanni Incarico",
    longitude: 13.55718757,
    latitude: 41.50302084,
  },
  {
    label: "Sant'Ambrogio sul Garigliano",
    longitude: 13.86911698,
    latitude: 41.39037063,
  },
  {
    label: "Sant'Andrea del Garigliano",
    longitude: 13.8419098,
    latitude: 41.36584859,
  },
  {
    label: "Sant'Apollinare",
    longitude: 13.83064469,
    latitude: 41.40194816,
  },
  {
    label: "Sant'Elia Fiumerapido",
    longitude: 13.86540818,
    latitude: 41.53966434,
  },
  {
    label: "Santopadre",
    longitude: 13.63483503,
    latitude: 41.60306777,
  },
  {
    label: "San Vittore del Lazio",
    longitude: 13.93553502,
    latitude: 41.46432341,
  },
  {
    label: "Serrone",
    longitude: 13.09654728,
    latitude: 41.84066558,
  },
  {
    label: "Settefrati",
    longitude: 13.85022232,
    latitude: 41.67028051,
  },
  {
    label: "Sgurgola",
    longitude: 13.14978577,
    latitude: 41.67072454,
  },
  {
    label: "Sora",
    longitude: 13.61362757,
    latitude: 41.72008003,
  },
  {
    label: "Strangolagalli",
    longitude: 13.49189605,
    latitude: 41.60110077,
  },
  {
    label: "Supino",
    longitude: 13.22332679,
    latitude: 41.60898643,
  },
  {
    label: "Terelle",
    longitude: 13.77894017,
    latitude: 41.55356649,
  },
  {
    label: "Torre Cajetani",
    longitude: 13.26303686,
    latitude: 41.78921542,
  },
  {
    label: "Torrice",
    longitude: 13.39810945,
    latitude: 41.62950876,
  },
  {
    label: "Trevi nel Lazio",
    longitude: 13.2481053,
    latitude: 41.86223006,
  },
  {
    label: "Trivigliano",
    longitude: 13.27282046,
    latitude: 41.77595255,
  },
  {
    label: "Vallecorsa",
    longitude: 13.40576692,
    latitude: 41.44465676,
  },
  {
    label: "Vallemaio",
    longitude: 13.81155025,
    latitude: 41.36610154,
  },
  {
    label: "Vallerotonda",
    longitude: 13.91259942,
    latitude: 41.55217081,
  },
  {
    label: "Veroli",
    longitude: 13.41787776,
    latitude: 41.69100425,
  },
  {
    label: "Vicalvi",
    longitude: 13.70593287,
    latitude: 41.67650371,
  },
  {
    label: "Vico nel Lazio",
    longitude: 13.3415973,
    latitude: 41.77679317,
  },
  {
    label: "Villa latitudeina",
    longitude: 13.837356,
    latitude: 41.61381675,
  },
  {
    label: "Villa Santa Lucia",
    longitude: 13.76979347,
    latitude: 41.50910263,
  },
  {
    label: "Villa Santo Stefano",
    longitude: 13.31096059,
    latitude: 41.51691711,
  },
  {
    label: "Viticuso",
    longitude: 13.96868058,
    latitude: 41.52461286,
  },
  {
    label: "Ailano",
    longitude: 14.20351931,
    latitude: 41.39076138,
  },
  {
    label: "Alife",
    longitude: 14.33616107,
    latitude: 41.32713742,
  },
  {
    label: "Alvignano",
    longitude: 14.33676719,
    latitude: 41.24491961,
  },
  {
    label: "Arienzo",
    longitude: 14.49379698,
    latitude: 41.02554469,
  },
  {
    label: "Aversa",
    longitude: 14.20627126,
    latitude: 40.9730782,
  },
  {
    label: "Baia e latitudeina",
    longitude: 14.25034037,
    latitude: 41.3005341,
  },
  {
    label: "Bellona",
    longitude: 14.22849069,
    latitude: 41.16032848,
  },
  {
    label: "Caianello",
    longitude: 14.0780728,
    latitude: 41.30426509,
  },
  {
    label: "Caiazzo",
    longitude: 14.36567455,
    latitude: 41.17756413,
  },
  {
    label: "Calvi Risorta",
    longitude: 14.13101024,
    latitude: 41.2155971,
  },
  {
    label: "Camigliano",
    longitude: 14.21035628,
    latitude: 41.18289425,
  },
  {
    label: "Cancello ed Arnone",
    longitude: 14.02597438,
    latitude: 41.07353284,
  },
  {
    label: "Capodrise",
    longitude: 14.30075451,
    latitude: 41.04196082,
  },
  {
    label: "Capriati a Volturno",
    longitude: 14.14610942,
    latitude: 41.46926476,
  },
  {
    label: "Capua",
    longitude: 14.21085054,
    latitude: 41.10762792,
  },
  {
    label: "Carinaro",
    longitude: 14.21700446,
    latitude: 40.98895817,
  },
  {
    label: "Carinola",
    longitude: 13.98181287,
    latitude: 41.18743798,
  },
  {
    label: "Casagiove",
    longitude: 14.31119949,
    latitude: 41.08218576,
  },
  {
    label: "Casal di Principe",
    longitude: 14.13055884,
    latitude: 41.00735255,
  },
  {
    label: "Casaluce",
    longitude: 14.19437506,
    latitude: 41.00234452,
  },
  {
    label: "Casapulla",
    longitude: 14.28855719,
    latitude: 41.07477399,
  },
  {
    label: "Caserta",
    longitude: 14.33240485,
    latitude: 41.07465878,
  },
  {
    label: "Castel Campagnano",
    longitude: 14.4530029,
    latitude: 41.18239826,
  },
  {
    label: "Castel di Sasso",
    longitude: 14.28435634,
    latitude: 41.19769212,
  },
  {
    label: "Castello del Matese",
    longitude: 14.37791299,
    latitude: 41.36675267,
  },
  {
    label: "Castel Morrone",
    longitude: 14.35762396,
    latitude: 41.12028848,
  },
  {
    label: "Castel Volturno",
    longitude: 13.94094427,
    latitude: 41.0346053,
  },
  {
    label: "Cervino",
    longitude: 14.42434334,
    latitude: 41.03971,
  },
  {
    label: "Cesa",
    longitude: 14.23044571,
    latitude: 40.96254438,
  },
  {
    label: "Ciorlano",
    longitude: 14.15804795,
    latitude: 41.45047076,
  },
  {
    label: "Conca della Campania",
    longitude: 13.99142237,
    latitude: 41.33173758,
  },
  {
    label: "Curti",
    longitude: 14.27655605,
    latitude: 41.07449401,
  },
  {
    label: "Dragoni",
    longitude: 14.30845696,
    latitude: 41.27339687,
  },
  {
    label: "Fontegreca",
    longitude: 14.1838485,
    latitude: 41.45652512,
  },
  {
    label: "Formicola",
    longitude: 14.23299177,
    latitude: 41.21071714,
  },
  {
    label: "Francolise",
    longitude: 14.05618895,
    latitude: 41.18521699,
  },
  {
    label: "Frignano",
    longitude: 14.17949932,
    latitude: 40.99496906,
  },
  {
    label: "Gallo Matese",
    longitude: 14.22518593,
    latitude: 41.46498739,
  },
  {
    label: "Galluccio",
    longitude: 13.95613005,
    latitude: 41.34148314,
  },
  {
    label: "Giano Vetusto",
    longitude: 14.19310542,
    latitude: 41.20407654,
  },
  {
    label: "Gioia Sannitica",
    longitude: 14.44380863,
    latitude: 41.29965161,
  },
  {
    label: "Grazzanise",
    longitude: 14.09822789,
    latitude: 41.09020288,
  },
  {
    label: "Gricignano di Aversa",
    longitude: 14.23285595,
    latitude: 40.98152228,
  },
  {
    label: "Letino",
    longitude: 14.24998246,
    latitude: 41.45233405,
  },
  {
    label: "Liberi",
    longitude: 14.28220876,
    latitude: 41.2227652,
  },
  {
    label: "Lusciano",
    longitude: 14.19168991,
    latitude: 40.96967738,
  },
  {
    label: "Macerata Campania",
    longitude: 14.27444243,
    latitude: 41.06309777,
  },
  {
    label: "Maddaloni",
    longitude: 14.38381932,
    latitude: 41.03861142,
  },
  {
    label: "Marcianise",
    longitude: 14.2994533,
    latitude: 41.03554506,
  },
  {
    label: "Marzano Appio",
    longitude: 14.03933051,
    latitude: 41.31628332,
  },
  {
    label: "Mignano Monte Lungo",
    longitude: 13.98743095,
    latitude: 41.40673147,
  },
  {
    label: "Mondragone",
    longitude: 13.88716123,
    latitude: 41.11293737,
  },
  {
    label: "Orta di Atella",
    longitude: 14.26935489,
    latitude: 40.96645147,
  },
  {
    label: "Parete",
    longitude: 14.16421608,
    latitude: 40.95650571,
  },
  {
    label: "Pastorano",
    longitude: 14.20148269,
    latitude: 41.18228429,
  },
  {
    label: "Piana di Monte Verna",
    longitude: 14.3340482,
    latitude: 41.16755929,
  },
  {
    label: "Piedimonte Matese",
    longitude: 14.37421618,
    latitude: 41.35594304,
  },
  {
    label: "Pietramelara",
    longitude: 14.18614179,
    latitude: 41.26686257,
  },
  {
    label: "Pietravairano",
    longitude: 14.16221729,
    latitude: 41.32194969,
  },
  {
    label: "Pignataro Maggiore",
    longitude: 14.17125085,
    latitude: 41.18929052,
  },
  {
    label: "Pontelatitudeone",
    longitude: 14.25104785,
    latitude: 41.19344415,
  },
  {
    label: "Portico di Caserta",
    longitude: 14.28053771,
    latitude: 41.0564905,
  },
  {
    label: "Prata Sannita",
    longitude: 14.20345479,
    latitude: 41.4322714,
  },
  {
    label: "Pratella",
    longitude: 14.17913123,
    latitude: 41.40578832,
  },
  {
    label: "Presenzano",
    longitude: 14.07857094,
    latitude: 41.37746119,
  },
  {
    label: "Raviscanina",
    longitude: 14.24281635,
    latitude: 41.37052383,
  },
  {
    label: "Recale",
    longitude: 14.30302105,
    latitude: 41.0563861,
  },
  {
    label: "Riardo",
    longitude: 14.14904208,
    latitude: 41.2639894,
  },
  {
    label: "Rocca d'Evandro",
    longitude: 13.89662243,
    latitude: 41.39470214,
  },
  {
    label: "Roccamonfina",
    longitude: 13.97905493,
    latitude: 41.28683417,
  },
  {
    label: "Roccaromana",
    longitude: 14.22319539,
    latitude: 41.27400185,
  },
  {
    label: "Rocchetta e Croce",
    longitude: 14.15764611,
    latitude: 41.23715985,
  },
  {
    label: "Ruviano",
    longitude: 14.40983327,
    latitude: 41.2106267,
  },
  {
    label: "San Cipriano d'Aversa",
    longitude: 14.13456689,
    latitude: 40.99968716,
  },
  {
    label: "San Felice a Cancello",
    longitude: 14.48097397,
    latitude: 41.0117889,
  },
  {
    label: "San Gregorio Matese",
    longitude: 14.37217206,
    latitude: 41.38555981,
  },
  {
    label: "San Marcellino",
    longitude: 14.17390517,
    latitude: 40.98671424,
  },
  {
    label: "San Nicola la Strada",
    longitude: 14.33135439,
    latitude: 41.05449475,
  },
  {
    label: "San Pietro Infine",
    longitude: 13.95697782,
    latitude: 41.44589132,
  },
  {
    label: "San Potito Sannitico",
    longitude: 14.39297847,
    latitude: 41.33832178,
  },
  {
    label: "San Prisco",
    longitude: 14.27869652,
    latitude: 41.08589926,
  },
  {
    label: "Santa Maria a Vico",
    longitude: 14.4634954,
    latitude: 41.02724272,
  },
  {
    label: "Santa Maria Capua Vetere",
    longitude: 14.26158174,
    latitude: 41.07755469,
  },
  {
    label: "Santa Maria la Fossa",
    longitude: 14.13109737,
    latitude: 41.09328983,
  },
  {
    label: "San Tammaro",
    longitude: 14.23202202,
    latitude: 41.07668838,
  },
  {
    label: "Sant'Angelo d'Alife",
    longitude: 14.25965786,
    latitude: 41.3626447,
  },
  {
    label: "Sant'Arpino",
    longitude: 14.25231539,
    latitude: 40.95788128,
  },
  {
    label: "Sessa Aurunca",
    longitude: 13.93278984,
    latitude: 41.24311381,
  },
  {
    label: "Sparanise",
    longitude: 14.0963742,
    latitude: 41.19057397,
  },
  {
    label: "Succivo",
    longitude: 14.25317294,
    latitude: 40.96550646,
  },
  {
    label: "Teano",
    longitude: 14.06867782,
    latitude: 41.24967248,
  },
  {
    label: "Teverola",
    longitude: 14.20834928,
    latitude: 40.99332422,
  },
  {
    label: "Tora e Piccilli",
    longitude: 14.02195483,
    latitude: 41.33834732,
  },
  {
    label: "Trentola Ducenta",
    longitude: 14.17561733,
    latitude: 40.97565323,
  },
  {
    label: "Vairano Patenora",
    longitude: 14.13172659,
    latitude: 41.33306519,
  },
  {
    label: "Valle Agricola",
    longitude: 14.25593108,
    latitude: 41.42427958,
  },
  {
    label: "Valle di Maddaloni",
    longitude: 14.41679476,
    latitude: 41.08011467,
  },
  {
    label: "Villa di Briano",
    longitude: 14.16848121,
    latitude: 40.99834392,
  },
  {
    label: "Villa Literno",
    longitude: 14.0741081,
    latitude: 41.00973652,
  },
  {
    label: "Vitulazio",
    longitude: 14.21898751,
    latitude: 41.16412896,
  },
  {
    label: "Falciano del Massico",
    longitude: 13.9488624,
    latitude: 41.16754649,
  },
  {
    label: "Cellole",
    longitude: 13.85489865,
    latitude: 41.20444362,
  },
  {
    label: "Casapesenna",
    longitude: 14.13302867,
    latitude: 40.98903859,
  },
  {
    label: "San Marco Evangelista",
    longitude: 14.34320904,
    latitude: 41.03761062,
  },
  {
    label: "Airola",
    longitude: 14.56140691,
    latitude: 41.06632744,
  },
  {
    label: "Amorosi",
    longitude: 14.46115943,
    latitude: 41.20530843,
  },
  {
    label: "Apice",
    longitude: 14.93107277,
    latitude: 41.11857609,
  },
  {
    label: "Apollosa",
    longitude: 14.7045246,
    latitude: 41.09342313,
  },
  {
    label: "Arpaia",
    longitude: 14.55176809,
    latitude: 41.03685746,
  },
  {
    label: "Arpaise",
    longitude: 14.74370822,
    latitude: 41.03063198,
  },
  {
    label: "Baselice",
    longitude: 14.97257424,
    latitude: 41.39742456,
  },
  {
    label: "Benevento",
    longitude: 14.7815172,
    latitude: 41.12969986,
  },
  {
    label: "Bonea",
    longitude: 14.61856038,
    latitude: 41.07494435,
  },
  {
    label: "Bucciano",
    longitude: 14.56996946,
    latitude: 41.07704547,
  },
  {
    label: "Buonalbergo",
    longitude: 14.97735441,
    latitude: 41.22130851,
  },
  {
    label: "Calvi",
    longitude: 14.86690871,
    latitude: 41.07242545,
  },
  {
    label: "Campolatitudetaro",
    longitude: 14.73043517,
    latitude: 41.28693249,
  },
  {
    label: "Campoli del Monte Taburno",
    longitude: 14.64649475,
    latitude: 41.1307419,
  },
  {
    label: "Casalduni",
    longitude: 14.69521255,
    latitude: 41.2602866,
  },
  {
    label: "Castelfranco in Miscano",
    longitude: 15.08257087,
    latitude: 41.29686774,
  },
  {
    label: "Castelpagano",
    longitude: 14.80667997,
    latitude: 41.40251506,
  },
  {
    label: "Castelpoto",
    longitude: 14.69971087,
    latitude: 41.14096887,
  },
  {
    label: "Castelvenere",
    longitude: 14.54743509,
    latitude: 41.2346114,
  },
  {
    label: "Castelvetere in Val Fortore",
    longitude: 14.94213224,
    latitude: 41.4419691,
  },
  {
    label: "Cautano",
    longitude: 14.63796656,
    latitude: 41.1492033,
  },
  {
    label: "Ceppaloni",
    longitude: 14.76112488,
    latitude: 41.0455693,
  },
  {
    label: "Cerreto Sannita",
    longitude: 14.5568909,
    latitude: 41.28321897,
  },
  {
    label: "Circello",
    longitude: 14.80828522,
    latitude: 41.35602914,
  },
  {
    label: "Colle Sannita",
    longitude: 14.83295842,
    latitude: 41.36376927,
  },
  {
    label: "Cusano Mutri",
    longitude: 14.50879373,
    latitude: 41.33756175,
  },
  {
    label: "Dugenta",
    longitude: 14.4537261,
    latitude: 41.13518764,
  },
  {
    label: "Durazzano",
    longitude: 14.44690145,
    latitude: 41.06139884,
  },
  {
    label: "Faicchio",
    longitude: 14.47948684,
    latitude: 41.27840301,
  },
  {
    label: "Foglianise",
    longitude: 14.67155655,
    latitude: 41.15696538,
  },
  {
    label: "Foiano di Val Fortore",
    longitude: 14.97403127,
    latitude: 41.35245427,
  },
  {
    label: "Forchia",
    longitude: 14.53683858,
    latitude: 41.03021164,
  },
  {
    label: "Fragneto l'Abate",
    longitude: 14.78200173,
    latitude: 41.25561235,
  },
  {
    label: "Fragneto Monforte",
    longitude: 14.76342439,
    latitude: 41.2463355,
  },
  {
    label: "Frasso Telesino",
    longitude: 14.52740031,
    latitude: 41.15752604,
  },
  {
    label: "Ginestra degli Schiavoni",
    longitude: 15.0423827,
    latitude: 41.27943084,
  },
  {
    label: "Guardia Sanframondi",
    longitude: 14.60000778,
    latitude: 41.25651724,
  },
  {
    label: "Limatola",
    longitude: 14.3943766,
    latitude: 41.14092629,
  },
  {
    label: "Melizzano",
    longitude: 14.50444253,
    latitude: 41.16133116,
  },
  {
    label: "Moiano",
    longitude: 14.54636351,
    latitude: 41.07824066,
  },
  {
    label: "Molinara",
    longitude: 14.90990199,
    latitude: 41.29376033,
  },
  {
    label: "Montefalcone di Val Fortore",
    longitude: 15.00925499,
    latitude: 41.32609614,
  },
  {
    label: "Montesarchio",
    longitude: 14.64054086,
    latitude: 41.06363722,
  },
  {
    label: "Morcone",
    longitude: 14.66404873,
    latitude: 41.34020414,
  },
  {
    label: "Paduli",
    longitude: 14.88730278,
    latitude: 41.16859857,
  },
  {
    label: "Pago Veiano",
    longitude: 14.87351296,
    latitude: 41.24652793,
  },
  {
    label: "Pannarano",
    longitude: 14.70242051,
    latitude: 41.01129378,
  },
  {
    label: "Paolisi",
    longitude: 14.57917957,
    latitude: 41.03730626,
  },
  {
    label: "Paupisi",
    longitude: 14.66457333,
    latitude: 41.19661931,
  },
  {
    label: "Pesco Sannita",
    longitude: 14.81092001,
    latitude: 41.23435632,
  },
  {
    label: "Pietraroja",
    longitude: 14.54987015,
    latitude: 41.34731855,
  },
  {
    label: "Pietrelcina",
    longitude: 14.84874802,
    latitude: 41.19688012,
  },
  {
    label: "Ponte",
    longitude: 14.68997449,
    latitude: 41.2130152,
  },
  {
    label: "Pontelandolfo",
    longitude: 14.69073975,
    latitude: 41.28849538,
  },
  {
    label: "Puglianello",
    longitude: 14.45023497,
    latitude: 41.22178939,
  },
  {
    label: "Reino",
    longitude: 14.82258257,
    latitude: 41.29228226,
  },
  {
    label: "San Bartolomeo in Galdo",
    longitude: 15.01507903,
    latitude: 41.40978352,
  },
  {
    label: "San Giorgio del Sannio",
    longitude: 14.85355966,
    latitude: 41.06240988,
  },
  {
    label: "San Giorgio La Molara",
    longitude: 14.91764227,
    latitude: 41.27116885,
  },
  {
    label: "San Leucio del Sannio",
    longitude: 14.75767623,
    latitude: 41.07269478,
  },
  {
    label: "San Lorenzello",
    longitude: 14.54117138,
    latitude: 41.27603221,
  },
  {
    label: "San Lorenzo Maggiore",
    longitude: 14.62567243,
    latitude: 41.25030378,
  },
  {
    label: "San Lupo",
    longitude: 14.63510545,
    latitude: 41.26044702,
  },
  {
    label: "San Marco dei Cavoti",
    longitude: 14.8806843,
    latitude: 41.30601106,
  },
  {
    label: "San Martino Sannita",
    longitude: 14.83492289,
    latitude: 41.06545883,
  },
  {
    label: "San Nazzaro",
    longitude: 14.8567939,
    latitude: 41.04971394,
  },
  {
    label: "San Nicola Manfredi",
    longitude: 14.8244282,
    latitude: 41.07246771,
  },
  {
    label: "San Salvatore Telesino",
    longitude: 14.49488,
    latitude: 41.23579869,
  },
  {
    label: "Santa Croce del Sannio",
    longitude: 14.73229291,
    latitude: 41.38792716,
  },
  {
    label: "Sant'Agata de' Goti",
    longitude: 14.50380976,
    latitude: 41.09079593,
  },
  {
    label: "Sant'Angelo a Cupolo",
    longitude: 14.80350645,
    latitude: 41.06797799,
  },
  {
    label: "Sassinoro",
    longitude: 14.66230742,
    latitude: 41.37466846,
  },
  {
    label: "Solopaca",
    longitude: 14.55292382,
    latitude: 41.19401297,
  },
  {
    label: "Telese Terme",
    longitude: 14.53112065,
    latitude: 41.21950482,
  },
  {
    label: "Tocco Caudio",
    longitude: 14.63248011,
    latitude: 41.12514712,
  },
  {
    label: "Torrecuso",
    longitude: 14.68101848,
    latitude: 41.18589327,
  },
  {
    label: "Vitulano",
    longitude: 14.65004535,
    latitude: 41.17284664,
  },
  {
    label: "Sant'Arcangelo Trimonte",
    longitude: 14.93882825,
    latitude: 41.16901148,
  },
  {
    label: "Acerra",
    longitude: 14.37118408,
    latitude: 40.94586399,
  },
  {
    label: "Afragola",
    longitude: 14.30986619,
    latitude: 40.92236049,
  },
  {
    label: "Agerola",
    longitude: 14.54491648,
    latitude: 40.63798358,
  },
  {
    label: "Anacapri",
    longitude: 14.21653675,
    latitude: 40.55142386,
  },
  {
    label: "Arzano",
    longitude: 14.26920831,
    latitude: 40.91062506,
  },
  {
    label: "Bacoli",
    longitude: 14.07840386,
    latitude: 40.79708543,
  },
  {
    label: "Barano d'Ischia",
    longitude: 13.91624742,
    latitude: 40.71063245,
  },
  {
    label: "Boscoreale",
    longitude: 14.47527288,
    latitude: 40.77531827,
  },
  {
    label: "Boscotrecase",
    longitude: 14.46107403,
    latitude: 40.77626907,
  },
  {
    label: "Brusciano",
    longitude: 14.42253614,
    latitude: 40.92061462,
  },
  {
    label: "Caivano",
    longitude: 14.30106405,
    latitude: 40.9567586,
  },
  {
    label: "Calvizzano",
    longitude: 14.18959008,
    latitude: 40.90751841,
  },
  {
    label: "Camposano",
    longitude: 14.52498309,
    latitude: 40.95590031,
  },
  {
    label: "Capri",
    longitude: 14.24304642,
    latitude: 40.55096848,
  },
  {
    label: "Carbonara di Nola",
    longitude: 14.57608435,
    latitude: 40.87511529,
  },
  {
    label: "Cardito",
    longitude: 14.29533786,
    latitude: 40.94647626,
  },
  {
    label: "Casalnuovo di Napoli",
    longitude: 14.3511862,
    latitude: 40.91481074,
  },
  {
    label: "Casamarciano",
    longitude: 14.55358491,
    latitude: 40.9328915,
  },
  {
    label: "Casamicciola Terme",
    longitude: 13.90747888,
    latitude: 40.74671279,
  },
  {
    label: "Casandrino",
    longitude: 14.25137922,
    latitude: 40.93573734,
  },
  {
    label: "Casavatore",
    longitude: 14.27452776,
    latitude: 40.90011759,
  },
  {
    label: "Casola di Napoli",
    longitude: 14.52863632,
    latitude: 40.69435863,
  },
  {
    label: "Casoria",
    longitude: 14.29026011,
    latitude: 40.90566059,
  },
  {
    label: "Castellammare di Stabia",
    longitude: 14.48032413,
    latitude: 40.69452529,
  },
  {
    label: "Castello di Cisterna",
    longitude: 14.40789431,
    latitude: 40.91669614,
  },
  {
    label: "Cercola",
    longitude: 14.35891456,
    latitude: 40.86004789,
  },
  {
    label: "Cicciano",
    longitude: 14.54320212,
    latitude: 40.96363427,
  },
  {
    label: "Cimitile",
    longitude: 14.52673141,
    latitude: 40.9397187,
  },
  {
    label: "Comiziano",
    longitude: 14.54934835,
    latitude: 40.95251006,
  },
  {
    label: "Crispano",
    longitude: 14.2849217,
    latitude: 40.95261244,
  },
  {
    label: "Forio",
    longitude: 13.85726258,
    latitude: 40.73778595,
  },
  {
    label: "Frattamaggiore",
    longitude: 14.27258035,
    latitude: 40.94120241,
  },
  {
    label: "Frattaminore",
    longitude: 14.27090135,
    latitude: 40.95564984,
  },
  {
    label: "Giugliano in Campania",
    longitude: 14.20108889,
    latitude: 40.92833418,
  },
  {
    label: "Gragnano",
    longitude: 14.52098181,
    latitude: 40.68982752,
  },
  {
    label: "Grumo Nevano",
    longitude: 14.25975251,
    latitude: 40.93825066,
  },
  {
    label: "Ischia",
    longitude: 13.94133431,
    latitude: 40.74142841,
  },
  {
    label: "Lacco Ameno",
    longitude: 13.88438822,
    latitude: 40.75451533,
  },
  {
    label: "Lettere",
    longitude: 14.54461293,
    latitude: 40.70441338,
  },
  {
    label: "Liveri",
    longitude: 14.56713469,
    latitude: 40.90236257,
  },
  {
    label: "Marano di Napoli",
    longitude: 14.19036418,
    latitude: 40.89862689,
  },
  {
    label: "Mariglianella",
    longitude: 14.43852429,
    latitude: 40.92662492,
  },
  {
    label: "Marigliano",
    longitude: 14.45701884,
    latitude: 40.92523504,
  },
  {
    label: "Massa Lubrense",
    longitude: 14.34403548,
    latitude: 40.61112017,
  },
  {
    label: "Melito di Napoli",
    longitude: 14.23270068,
    latitude: 40.92402544,
  },
  {
    label: "Meta",
    longitude: 14.4126854,
    latitude: 40.64403352,
  },
  {
    label: "Monte di Procida",
    longitude: 14.05389718,
    latitude: 40.79500583,
  },
  {
    label: "Mugnano di Napoli",
    longitude: 14.2064913,
    latitude: 40.90950996,
  },
  {
    label: "Napoli",
    longitude: 14.25085002,
    latitude: 40.83956554,
  },
  {
    label: "Nola",
    longitude: 14.52683267,
    latitude: 40.92632842,
  },
  {
    label: "Ottaviano",
    longitude: 14.47744172,
    latitude: 40.84936031,
  },
  {
    label: "Palma Campania",
    longitude: 14.55633716,
    latitude: 40.86703164,
  },
  {
    label: "Piano di Sorrento",
    longitude: 14.4132217,
    latitude: 40.63390065,
  },
  {
    label: "Pimonte",
    longitude: 14.51345416,
    latitude: 40.67368689,
  },
  {
    label: "Poggiomarino",
    longitude: 14.54080543,
    latitude: 40.79866598,
  },
  {
    label: "Pollena Trocchia",
    longitude: 14.37874203,
    latitude: 40.85809414,
  },
  {
    label: "Pomigliano d'Arco",
    longitude: 14.39335586,
    latitude: 40.90689555,
  },
  {
    label: "Pompei",
    longitude: 14.50001431,
    latitude: 40.74841783,
  },
  {
    label: "Portici",
    longitude: 14.35421459,
    latitude: 40.82375707,
  },
  {
    label: "Pozzuoli",
    longitude: 14.0908948,
    latitude: 40.84681848,
  },
  {
    label: "Procida",
    longitude: 14.02403961,
    latitude: 40.76578674,
  },
  {
    label: "Qualiano",
    longitude: 14.15086705,
    latitude: 40.9202007,
  },
  {
    label: "Quarto",
    longitude: 14.14350978,
    latitude: 40.87770075,
  },
  {
    label: "Ercolano",
    longitude: 14.34571485,
    latitude: 40.80927768,
  },
  {
    label: "Roccarainola",
    longitude: 14.56205809,
    latitude: 40.97242207,
  },
  {
    label: "San Gennaro Vesuviano",
    longitude: 14.52929174,
    latitude: 40.86118903,
  },
  {
    label: "San Giorgio a Cremano",
    longitude: 14.33392579,
    latitude: 40.82921168,
  },
  {
    label: "San Giuseppe Vesuviano",
    longitude: 14.50617697,
    latitude: 40.83425724,
  },
  {
    label: "San Paolo Bel Sito",
    longitude: 14.5483885,
    latitude: 40.91271807,
  },
  {
    label: "San Sebastiano al Vesuvio",
    longitude: 14.37178089,
    latitude: 40.84105537,
  },
  {
    label: "Sant'Agnello",
    longitude: 14.39764006,
    latitude: 40.63474436,
  },
  {
    label: "Sant'Anastasia",
    longitude: 14.40211956,
    latitude: 40.86700769,
  },
  {
    label: "Sant'Antimo",
    longitude: 14.23602873,
    latitude: 40.93327136,
  },
  {
    label: "Sant'Antonio Abate",
    longitude: 14.54695442,
    latitude: 40.72247196,
  },
  {
    label: "San Vitaliano",
    longitude: 14.48219363,
    latitude: 40.92292749,
  },
  {
    label: "Saviano",
    longitude: 14.50962223,
    latitude: 40.91112824,
  },
  {
    label: "Scisciano",
    longitude: 14.48281569,
    latitude: 40.9174085,
  },
  {
    label: "Serrara Fontana",
    longitude: 13.89310828,
    latitude: 40.71185886,
  },
  {
    label: "Somma Vesuviana",
    longitude: 14.4397519,
    latitude: 40.86831181,
  },
  {
    label: "Sorrento",
    longitude: 14.37853298,
    latitude: 40.6282273,
  },
  {
    label: "Striano",
    longitude: 14.57788595,
    latitude: 40.81385972,
  },
  {
    label: "Terzigno",
    longitude: 14.49767475,
    latitude: 40.80768222,
  },
  {
    label: "Torre Annunziata",
    longitude: 14.44758612,
    latitude: 40.75817312,
  },
  {
    label: "Torre del Greco",
    longitude: 14.36552432,
    latitude: 40.78669006,
  },
  {
    label: "Tufino",
    longitude: 14.56516692,
    latitude: 40.95558945,
  },
  {
    label: "Vico Equense",
    longitude: 14.42533592,
    latitude: 40.66443364,
  },
  {
    label: "Villaricca",
    longitude: 14.19481827,
    latitude: 40.92181295,
  },
  {
    label: "Visciano",
    longitude: 14.58724433,
    latitude: 40.92372561,
  },
  {
    label: "Volla",
    longitude: 14.34632248,
    latitude: 40.87950851,
  },
  {
    label: "Santa Maria la Carità",
    longitude: 14.51294142,
    latitude: 40.72100053,
  },
  {
    label: "Trecase",
    longitude: 14.43595161,
    latitude: 40.76986714,
  },
  {
    label: "Massa di Somma",
    longitude: 14.377398,
    latitude: 40.84660798,
  },
  {
    label: "Aiello del Sabato",
    longitude: 14.81982895,
    latitude: 40.88916045,
  },
  {
    label: "Altavilla Irpina",
    longitude: 14.77745859,
    latitude: 41.00583009,
  },
  {
    label: "Andretta",
    longitude: 15.32147231,
    latitude: 40.93254843,
  },
  {
    label: "Aquilonia",
    longitude: 15.47175714,
    latitude: 40.98660751,
  },
  {
    label: "Ariano Irpino",
    longitude: 15.09006787,
    latitude: 41.15385883,
  },
  {
    label: "Atripalda",
    longitude: 14.83665185,
    latitude: 40.91799979,
  },
  {
    label: "Avella",
    longitude: 14.5999459,
    latitude: 40.96033617,
  },
  {
    label: "Avellino",
    longitude: 14.79528841,
    latitude: 40.91404698,
  },
  {
    label: "Bagnoli Irpino",
    longitude: 15.06982281,
    latitude: 40.83266201,
  },
  {
    label: "Baiano",
    longitude: 14.61712905,
    latitude: 40.95141659,
  },
  {
    label: "Bisaccia",
    longitude: 15.37502023,
    latitude: 41.01318144,
  },
  {
    label: "Bonito",
    longitude: 15.00287184,
    latitude: 41.10231266,
  },
  {
    label: "Cairano",
    longitude: 15.3701511,
    latitude: 40.89634583,
  },
  {
    label: "Calabritto",
    longitude: 15.21975331,
    latitude: 40.78359575,
  },
  {
    label: "Calitri",
    longitude: 15.43866059,
    latitude: 40.90045423,
  },
  {
    label: "Candida",
    longitude: 14.87371614,
    latitude: 40.94130764,
  },
  {
    label: "Caposele",
    longitude: 15.22614616,
    latitude: 40.8136263,
  },
  {
    label: "Capriglia Irpina",
    longitude: 14.77543902,
    latitude: 40.95580197,
  },
  {
    label: "Carife",
    longitude: 15.20812925,
    latitude: 41.02744097,
  },
  {
    label: "Casalbore",
    longitude: 15.00405855,
    latitude: 41.23420855,
  },
  {
    label: "Cassano Irpino",
    longitude: 15.02612109,
    latitude: 40.87018845,
  },
  {
    label: "Castel Baronia",
    longitude: 15.18836128,
    latitude: 41.04719906,
  },
  {
    label: "Castelfranci",
    longitude: 15.04098844,
    latitude: 40.93067693,
  },
  {
    label: "Castelvetere sul Calore",
    longitude: 14.98607475,
    latitude: 40.929916,
  },
  {
    label: "Cervinara",
    longitude: 14.6172194,
    latitude: 41.02073547,
  },
  {
    label: "Cesinali",
    longitude: 14.82975792,
    latitude: 40.89612766,
  },
  {
    label: "Chianche",
    longitude: 14.79090136,
    latitude: 41.0437038,
  },
  {
    label: "Chiusano di San Domenico",
    longitude: 14.9167278,
    latitude: 40.9319137,
  },
  {
    label: "Contrada",
    longitude: 14.77328593,
    latitude: 40.86717659,
  },
  {
    label: "Conza della Campania",
    longitude: 15.33570326,
    latitude: 40.85724766,
  },
  {
    label: "Domicella",
    longitude: 14.58677206,
    latitude: 40.87931663,
  },
  {
    label: "Flumeri",
    longitude: 15.15352418,
    latitude: 41.07341274,
  },
  {
    label: "Fontanarosa",
    longitude: 15.02052275,
    latitude: 41.02040568,
  },
  {
    label: "Forino",
    longitude: 14.73716998,
    latitude: 40.86173617,
  },
  {
    label: "Frigento",
    longitude: 15.09768868,
    latitude: 41.0119868,
  },
  {
    label: "Gesualdo",
    longitude: 15.07043379,
    latitude: 41.00658622,
  },
  {
    label: "Greci",
    longitude: 15.16972604,
    latitude: 41.24965844,
  },
  {
    label: "Grottaminarda",
    longitude: 15.06009606,
    latitude: 41.07097386,
  },
  {
    label: "Grottolella",
    longitude: 14.78616017,
    latitude: 40.97232978,
  },
  {
    label: "Guardia Lombardi",
    longitude: 15.20764925,
    latitude: 40.95465584,
  },
  {
    label: "Lacedonia",
    longitude: 15.42476077,
    latitude: 41.04944523,
  },
  {
    label: "Lapio",
    longitude: 14.94786184,
    latitude: 40.98448735,
  },
  {
    label: "Lauro",
    longitude: 14.63234651,
    latitude: 40.87827998,
  },
  {
    label: "Lioni",
    longitude: 15.18844472,
    latitude: 40.8774926,
  },
  {
    label: "Luogosano",
    longitude: 14.99036768,
    latitude: 40.98776695,
  },
  {
    label: "Manocalzati",
    longitude: 14.84913222,
    latitude: 40.94143363,
  },
  {
    label: "Marzano di Nola",
    longitude: 14.58178648,
    latitude: 40.90020918,
  },
  {
    label: "Melito Irpino",
    longitude: 15.05388527,
    latitude: 41.10289907,
  },
  {
    label: "Mercogliano",
    longitude: 14.7361319,
    latitude: 40.92011509,
  },
  {
    label: "Mirabella Eclano",
    longitude: 14.99217637,
    latitude: 41.04137986,
  },
  {
    label: "Montaguto",
    longitude: 15.25015638,
    latitude: 41.24905317,
  },
  {
    label: "Montecalvo Irpino",
    longitude: 15.03161925,
    latitude: 41.19419528,
  },
  {
    label: "Montefalcione",
    longitude: 14.88450673,
    latitude: 40.96406724,
  },
  {
    label: "Monteforte Irpino",
    longitude: 14.71387573,
    latitude: 40.89001296,
  },
  {
    label: "Montefredane",
    longitude: 14.81298531,
    latitude: 40.96033326,
  },
  {
    label: "Montefusco",
    longitude: 14.85521289,
    latitude: 41.03769145,
  },
  {
    label: "Montella",
    longitude: 15.01665759,
    latitude: 40.84228603,
  },
  {
    label: "Montemarano",
    longitude: 14.99692121,
    latitude: 40.9198231,
  },
  {
    label: "Montemiletto",
    longitude: 14.90719412,
    latitude: 41.01146661,
  },
  {
    label: "Monteverde",
    longitude: 15.53273882,
    latitude: 40.9998117,
  },
  {
    label: "Morra De Sanctis",
    longitude: 15.24396648,
    latitude: 40.92311991,
  },
  {
    label: "Moschiano",
    longitude: 14.65948381,
    latitude: 40.87466861,
  },
  {
    label: "Mugnano del Cardinale",
    longitude: 14.64256166,
    latitude: 40.93962605,
  },
  {
    label: "Nusco",
    longitude: 15.08447214,
    latitude: 40.88613977,
  },
  {
    label: "Ospedaletto d'Alpinolo",
    longitude: 14.74554627,
    latitude: 40.93919199,
  },
  {
    label: "Pago del Vallo di Lauro",
    longitude: 14.60518668,
    latitude: 40.89678318,
  },
  {
    label: "Parolise",
    longitude: 14.88241645,
    latitude: 40.93089171,
  },
  {
    label: "Paternopoli",
    longitude: 15.03493023,
    latitude: 40.97416859,
  },
  {
    label: "Petruro Irpino",
    longitude: 14.79729044,
    latitude: 41.03158777,
  },
  {
    label: "Pietradefusi",
    longitude: 14.88931134,
    latitude: 41.03740745,
  },
  {
    label: "Pietrastornina",
    longitude: 14.7295658,
    latitude: 40.9920379,
  },
  {
    label: "Prata di Principato Ultra",
    longitude: 14.83708173,
    latitude: 40.9864953,
  },
  {
    label: "Pratola Serra",
    longitude: 14.85232404,
    latitude: 40.98539583,
  },
  {
    label: "Quadrelle",
    longitude: 14.63818968,
    latitude: 40.94710126,
  },
  {
    label: "Quindici",
    longitude: 14.64823982,
    latitude: 40.86250574,
  },
  {
    label: "Roccabascerana",
    longitude: 14.7164608,
    latitude: 41.01810007,
  },
  {
    label: "Rocca San Felice",
    longitude: 15.16512361,
    latitude: 40.95101486,
  },
  {
    label: "Rotondi",
    longitude: 14.59640064,
    latitude: 41.0340276,
  },
  {
    label: "Salza Irpina",
    longitude: 14.89109819,
    latitude: 40.9180322,
  },
  {
    label: "San Mango sul Calore",
    longitude: 14.97251308,
    latitude: 40.95889899,
  },
  {
    label: "San Martino Valle Caudina",
    longitude: 14.66485987,
    latitude: 41.02546343,
  },
  {
    label: "San Michele di Serino",
    longitude: 14.85550389,
    latitude: 40.87717111,
  },
  {
    label: "San Nicola Baronia",
    longitude: 15.1981313,
    latitude: 41.05945327,
  },
  {
    label: "San Potito Ultra",
    longitude: 14.87123621,
    latitude: 40.92795775,
  },
  {
    label: "San Sossio Baronia",
    longitude: 15.20329259,
    latitude: 41.06694004,
  },
  {
    label: "Santa Lucia di Serino",
    longitude: 14.87201353,
    latitude: 40.87327776,
  },
  {
    label: "Sant'Andrea di Conza",
    longitude: 15.37173773,
    latitude: 40.8441276,
  },
  {
    label: "Sant'Angelo all'Esca",
    longitude: 14.9931644,
    latitude: 41.00652306,
  },
  {
    label: "Sant'Angelo a Scala",
    longitude: 14.73969557,
    latitude: 40.97592276,
  },
  {
    label: "Sant'Angelo dei Lombardi",
    longitude: 15.17650215,
    latitude: 40.92740593,
  },
  {
    label: "Santa Paolina",
    longitude: 14.84630005,
    latitude: 41.02201833,
  },
  {
    label: "Santo Stefano del Sole",
    longitude: 14.86673772,
    latitude: 40.89414063,
  },
  {
    label: "Savignano Irpino",
    longitude: 15.17744194,
    latitude: 41.22824236,
  },
  {
    label: "Scampitella",
    longitude: 15.29644646,
    latitude: 41.092238,
  },
  {
    label: "Senerchia",
    longitude: 15.20501562,
    latitude: 40.74133261,
  },
  {
    label: "Serino",
    longitude: 14.87230746,
    latitude: 40.85518973,
  },
  {
    label: "Sirignano",
    longitude: 14.62882453,
    latitude: 40.94768496,
  },
  {
    label: "Solofra",
    longitude: 14.84650623,
    latitude: 40.83056029,
  },
  {
    label: "Sorbo Serpico",
    longitude: 14.88709497,
    latitude: 40.91685069,
  },
  {
    label: "Sperone",
    longitude: 14.60282378,
    latitude: 40.95191535,
  },
  {
    label: "Sturno",
    longitude: 15.11247993,
    latitude: 41.02217144,
  },
  {
    label: "Summonte",
    longitude: 14.74697511,
    latitude: 40.95008734,
  },
  {
    label: "Taurano",
    longitude: 14.63611268,
    latitude: 40.88402399,
  },
  {
    label: "Taurasi",
    longitude: 14.95788884,
    latitude: 41.00680479,
  },
  {
    label: "Teora",
    longitude: 15.25278579,
    latitude: 40.85109065,
  },
  {
    label: "Torella dei Lombardi",
    longitude: 15.11455072,
    latitude: 40.9421849,
  },
  {
    label: "Torre Le Nocelle",
    longitude: 14.91263268,
    latitude: 41.02075599,
  },
  {
    label: "Torrioni",
    longitude: 14.81351286,
    latitude: 41.03410042,
  },
  {
    label: "Trevico",
    longitude: 15.232918,
    latitude: 41.04808037,
  },
  {
    label: "Tufo",
    longitude: 14.82425423,
    latitude: 41.00995285,
  },
  {
    label: "Vallatitudea",
    longitude: 15.25135623,
    latitude: 41.03692496,
  },
  {
    label: "Vallesaccarda",
    longitude: 15.25196934,
    latitude: 41.06441021,
  },
  {
    label: "Venticano",
    longitude: 14.9175222,
    latitude: 41.046155,
  },
  {
    label: "Villamaina",
    longitude: 15.08817941,
    latitude: 40.97121879,
  },
  {
    label: "Villanova del Battista",
    longitude: 15.15847289,
    latitude: 41.12245092,
  },
  {
    label: "Volturara Irpina",
    longitude: 14.91716971,
    latitude: 40.87882068,
  },
  {
    label: "Zungoli",
    longitude: 15.2025556,
    latitude: 41.12766031,
  },
  {
    label: "Montoro",
    longitude: 14.76,
    latitude: 40.8216,
  },
  {
    label: "Acerno",
    longitude: 15.05943917,
    latitude: 40.738303,
  },
  {
    label: "Agropoli",
    longitude: 14.99158917,
    latitude: 40.34962772,
  },
  {
    label: "Albanella",
    longitude: 15.11515285,
    latitude: 40.47977693,
  },
  {
    label: "Alfano",
    longitude: 15.42591415,
    latitude: 40.17766666,
  },
  {
    label: "Altavilla Silentina",
    longitude: 15.13759173,
    latitude: 40.5271738,
  },
  {
    label: "Amalfi",
    longitude: 14.60521014,
    latitude: 40.63424015,
  },
  {
    label: "Angri",
    longitude: 14.56844128,
    latitude: 40.74321786,
  },
  {
    label: "Aquara",
    longitude: 15.25320988,
    latitude: 40.44280787,
  },
  {
    label: "Ascea",
    longitude: 15.18401535,
    latitude: 40.14337522,
  },
  {
    label: "Atena Lucana",
    longitude: 15.55815913,
    latitude: 40.45463856,
  },
  {
    label: "Atrani",
    longitude: 14.60842588,
    latitude: 40.63651748,
  },
  {
    label: "Auletta",
    longitude: 15.42410729,
    latitude: 40.55814193,
  },
  {
    label: "Baronissi",
    longitude: 14.77021449,
    latitude: 40.74643066,
  },
  {
    label: "Battipaglia",
    longitude: 14.98344118,
    latitude: 40.60767715,
  },
  {
    label: "Bellosguardo",
    longitude: 15.31021488,
    latitude: 40.42181689,
  },
  {
    label: "Bracigliano",
    longitude: 14.7110483,
    latitude: 40.82473827,
  },
  {
    label: "Buccino",
    longitude: 15.36732267,
    latitude: 40.63105957,
  },
  {
    label: "Buonabitacolo",
    longitude: 15.6193631,
    latitude: 40.26880972,
  },
  {
    label: "Caggiano",
    longitude: 15.49648212,
    latitude: 40.56715845,
  },
  {
    label: "Calvanico",
    longitude: 14.82938846,
    latitude: 40.77618838,
  },
  {
    label: "Camerota",
    longitude: 15.37015789,
    latitude: 40.03319085,
  },
  {
    label: "Campagna",
    longitude: 15.10702337,
    latitude: 40.66560507,
  },
  {
    label: "Campora",
    longitude: 15.29274679,
    latitude: 40.30525408,
  },
  {
    label: "Cannalonga",
    longitude: 15.29260531,
    latitude: 40.24358439,
  },
  {
    label: "Capaccio Paestum",
    longitude: 15.08075018,
    latitude: 40.42303253,
  },
  {
    label: "Casalbuono",
    longitude: 15.68296763,
    latitude: 40.21445576,
  },
  {
    label: "Casaletto Spartano",
    longitude: 15.61856324,
    latitude: 40.15165481,
  },
  {
    label: "Casal Velino",
    longitude: 15.10958371,
    latitude: 40.18895029,
  },
  {
    label: "Caselle in Pittari",
    longitude: 15.54391906,
    latitude: 40.17243756,
  },
  {
    label: "Castelcivita",
    longitude: 15.23299597,
    latitude: 40.49297088,
  },
  {
    label: "Castellabate",
    longitude: 14.94919693,
    latitude: 40.28643787,
  },
  {
    label: "Castelnuovo Cilento",
    longitude: 15.17802281,
    latitude: 40.21846064,
  },
  {
    label: "Castelnuovo di Conza",
    longitude: 15.3212614,
    latitude: 40.81816168,
  },
  {
    label: "Castel San Giorgio",
    longitude: 14.69776639,
    latitude: 40.78015493,
  },
  {
    label: "Castel San Lorenzo",
    longitude: 15.2277694,
    latitude: 40.4168339,
  },
  {
    label: "Castiglione del Genovesi",
    longitude: 14.84822627,
    latitude: 40.72504572,
  },
  {
    label: "Cava de' Tirreni",
    longitude: 14.70597263,
    latitude: 40.70080592,
  },
  {
    label: "Celle di Bulgheria",
    longitude: 15.40573557,
    latitude: 40.09491702,
  },
  {
    label: "Centola",
    longitude: 15.30985282,
    latitude: 40.06581271,
  },
  {
    label: "Ceraso",
    longitude: 15.25854922,
    latitude: 40.19295735,
  },
  {
    label: "Cetara",
    longitude: 14.70091545,
    latitude: 40.64675721,
  },
  {
    label: "Cicerale",
    longitude: 15.12671799,
    latitude: 40.34367888,
  },
  {
    label: "Colliano",
    longitude: 15.29243072,
    latitude: 40.72519023,
  },
  {
    label: "Conca dei Marini",
    longitude: 14.57424895,
    latitude: 40.61787925,
  },
  {
    label: "Controne",
    longitude: 15.20382179,
    latitude: 40.5097371,
  },
  {
    label: "Contursi Terme",
    longitude: 15.24108792,
    latitude: 40.64914887,
  },
  {
    label: "Corbara",
    longitude: 14.59252635,
    latitude: 40.72278294,
  },
  {
    label: "Corleto Monforte",
    longitude: 15.3808319,
    latitude: 40.43759293,
  },
  {
    label: "Cuccaro Vetere",
    longitude: 15.30677565,
    latitude: 40.16404177,
  },
  {
    label: "Eboli",
    longitude: 15.05445396,
    latitude: 40.61818665,
  },
  {
    label: "Felitto",
    longitude: 15.24796465,
    latitude: 40.37069734,
  },
  {
    label: "Fisciano",
    longitude: 14.79914629,
    latitude: 40.77147015,
  },
  {
    label: "Furore",
    longitude: 14.55018156,
    latitude: 40.6202308,
  },
  {
    label: "Futani",
    longitude: 15.32365677,
    latitude: 40.1515587,
  },
  {
    label: "Giffoni Sei Casali",
    longitude: 14.90366253,
    latitude: 40.71885055,
  },
  {
    label: "Giffoni Valle Piana",
    longitude: 14.94330697,
    latitude: 40.71749901,
  },
  {
    label: "Gioi",
    longitude: 15.21750556,
    latitude: 40.28988655,
  },
  {
    label: "Giungano",
    longitude: 15.1079167,
    latitude: 40.39449625,
  },
  {
    label: "Ispani",
    longitude: 15.56174844,
    latitude: 40.08578455,
  },
  {
    label: "Laureana Cilento",
    longitude: 15.0389141,
    latitude: 40.30089628,
  },
  {
    label: "Laurino",
    longitude: 15.33665078,
    latitude: 40.33673573,
  },
  {
    label: "Laurito",
    longitude: 15.4082743,
    latitude: 40.16852532,
  },
  {
    label: "Laviano",
    longitude: 15.30507214,
    latitude: 40.78461052,
  },
  {
    label: "Lustra",
    longitude: 15.06831965,
    latitude: 40.28840206,
  },
  {
    label: "Magliano Vetere",
    longitude: 15.23635242,
    latitude: 40.34702141,
  },
  {
    label: "Maiori",
    longitude: 14.64223366,
    latitude: 40.65086502,
  },
  {
    label: "Mercato San Severino",
    longitude: 14.75357737,
    latitude: 40.7862946,
  },
  {
    label: "Minori",
    longitude: 14.62615215,
    latitude: 40.64966885,
  },
  {
    label: "Moio della Civitella",
    longitude: 15.27040016,
    latitude: 40.24504519,
  },
  {
    label: "Montano Antilia",
    longitude: 15.36150956,
    latitude: 40.16238439,
  },
  {
    label: "Montecorice",
    longitude: 14.98504292,
    latitude: 40.2344441,
  },
  {
    label: "Montecorvino Pugliano",
    longitude: 14.93025526,
    latitude: 40.67615197,
  },
  {
    label: "Montecorvino Rovella",
    longitude: 14.97694216,
    latitude: 40.69579529,
  },
  {
    label: "Monteforte Cilento",
    longitude: 15.1946132,
    latitude: 40.36468598,
  },
  {
    label: "Monte San Giacomo",
    longitude: 15.54367406,
    latitude: 40.34139154,
  },
  {
    label: "Montesano sulla Marcellana",
    longitude: 15.70118507,
    latitude: 40.27557832,
  },
  {
    label: "Morigerati",
    longitude: 15.55508055,
    latitude: 40.14055717,
  },
  {
    label: "Nocera Inferiore",
    longitude: 14.64142303,
    latitude: 40.74358321,
  },
  {
    label: "Nocera Superiore",
    longitude: 14.67117888,
    latitude: 40.74156986,
  },
  {
    label: "Novi Velia",
    longitude: 15.28710598,
    latitude: 40.22457101,
  },
  {
    label: "Ogliastro Cilento",
    longitude: 15.0463799,
    latitude: 40.35112816,
  },
  {
    label: "Olevano sul Tusciano",
    longitude: 15.02199175,
    latitude: 40.65735787,
  },
  {
    label: "Oliveto Citra",
    longitude: 15.23612189,
    latitude: 40.69162499,
  },
  {
    label: "Omignano",
    longitude: 15.08406539,
    latitude: 40.24932151,
  },
  {
    label: "Orria",
    longitude: 15.17140938,
    latitude: 40.2994358,
  },
  {
    label: "Ottati",
    longitude: 15.31533553,
    latitude: 40.46332147,
  },
  {
    label: "Padula",
    longitude: 15.6595203,
    latitude: 40.34105151,
  },
  {
    label: "Pagani",
    longitude: 14.61944041,
    latitude: 40.74107352,
  },
  {
    label: "Palomonte",
    longitude: 15.29173957,
    latitude: 40.66015522,
  },
  {
    label: "Pellezzano",
    longitude: 14.75782962,
    latitude: 40.72587297,
  },
  {
    label: "Perdifumo",
    longitude: 15.0165195,
    latitude: 40.26694175,
  },
  {
    label: "Perito",
    longitude: 15.14745558,
    latitude: 40.29853863,
  },
  {
    label: "Pertosa",
    longitude: 15.44852735,
    latitude: 40.54320589,
  },
  {
    label: "Petina",
    longitude: 15.37018384,
    latitude: 40.53140417,
  },
  {
    label: "Piaggine",
    longitude: 15.37724436,
    latitude: 40.34317707,
  },
  {
    label: "Pisciotta",
    longitude: 15.23370925,
    latitude: 40.10773484,
  },
  {
    label: "Polla",
    longitude: 15.49325713,
    latitude: 40.51419911,
  },
  {
    label: "Pollica",
    longitude: 15.05734127,
    latitude: 40.19070542,
  },
  {
    label: "Pontecagnano Faiano",
    longitude: 14.87340385,
    latitude: 40.64296854,
  },
  {
    label: "Positano",
    longitude: 14.4836174,
    latitude: 40.62888712,
  },
  {
    label: "Postiglione",
    longitude: 15.2330423,
    latitude: 40.55724143,
  },
  {
    label: "Praiano",
    longitude: 14.53257625,
    latitude: 40.61144748,
  },
  {
    label: "Prignano Cilento",
    longitude: 15.06923061,
    latitude: 40.32986596,
  },
  {
    label: "Ravello",
    longitude: 14.61364727,
    latitude: 40.65284481,
  },
  {
    label: "Ricigliano",
    longitude: 15.4836057,
    latitude: 40.6680866,
  },
  {
    label: "Roccadaspide",
    longitude: 15.19428476,
    latitude: 40.42604608,
  },
  {
    label: "Roccagloriosa",
    longitude: 15.43757851,
    latitude: 40.10605474,
  },
  {
    label: "Roccapiemonte",
    longitude: 14.69268111,
    latitude: 40.76236839,
  },
  {
    label: "Rofrano",
    longitude: 15.42723315,
    latitude: 40.21046845,
  },
  {
    label: "Romagnano al Monte",
    longitude: 15.43453415,
    latitude: 40.6279482,
  },
  {
    label: "Roscigno",
    longitude: 15.34453927,
    latitude: 40.40037084,
  },
  {
    label: "Rutino",
    longitude: 15.07009494,
    latitude: 40.29988229,
  },
  {
    label: "Sacco",
    longitude: 15.3729118,
    latitude: 40.38178119,
  },
  {
    label: "Sala Consilina",
    longitude: 15.59084298,
    latitude: 40.4026612,
  },
  {
    label: "Salento",
    longitude: 15.18841701,
    latitude: 40.24899255,
  },
  {
    label: "Salerno",
    longitude: 14.75940296,
    latitude: 40.67821959,
  },
  {
    label: "Salvitelle",
    longitude: 15.45931382,
    latitude: 40.59106183,
  },
  {
    label: "San Cipriano Picentino",
    longitude: 14.87235531,
    latitude: 40.72040176,
  },
  {
    label: "San Giovanni a Piro",
    longitude: 15.44984225,
    latitude: 40.05043571,
  },
  {
    label: "San Gregorio Magno",
    longitude: 15.39940921,
    latitude: 40.65616247,
  },
  {
    label: "San Mango Piemonte",
    longitude: 14.83918854,
    latitude: 40.70024082,
  },
  {
    label: "San Marzano sul Sarno",
    longitude: 14.58798217,
    latitude: 40.77460793,
  },
  {
    label: "San Mauro Cilento",
    longitude: 15.04476431,
    latitude: 40.22628217,
  },
  {
    label: "San Mauro la Bruca",
    longitude: 15.28600011,
    latitude: 40.12159144,
  },
  {
    label: "San Pietro al Tanagro",
    longitude: 15.48754102,
    latitude: 40.45403757,
  },
  {
    label: "San Rufo",
    longitude: 15.461657,
    latitude: 40.43434882,
  },
  {
    label: "Santa Marina",
    longitude: 15.54152852,
    latitude: 40.10520303,
  },
  {
    label: "Sant'Angelo a Fasanella",
    longitude: 15.34366051,
    latitude: 40.45704286,
  },
  {
    label: "Sant'Arsenio",
    longitude: 15.4864055,
    latitude: 40.47110216,
  },
  {
    label: "Sant'Egidio del Monte Albino",
    longitude: 14.59895358,
    latitude: 40.73592269,
  },
  {
    label: "Santomenna",
    longitude: 15.32026395,
    latitude: 40.80708262,
  },
  {
    label: "San Valentino Torio",
    longitude: 14.60094288,
    latitude: 40.79182154,
  },
  {
    label: "Sanza",
    longitude: 15.55330616,
    latitude: 40.24463844,
  },
  {
    label: "Sapri",
    longitude: 15.63073166,
    latitude: 40.07392713,
  },
  {
    label: "Sarno",
    longitude: 14.61889943,
    latitude: 40.8153339,
  },
  {
    label: "Sassano",
    longitude: 15.56594212,
    latitude: 40.34208235,
  },
  {
    label: "Scafati",
    longitude: 14.52623502,
    latitude: 40.74806338,
  },
  {
    label: "Scala",
    longitude: 14.60706553,
    latitude: 40.65594208,
  },
  {
    label: "Serramezzana",
    longitude: 15.03228574,
    latitude: 40.24454152,
  },
  {
    label: "Serre",
    longitude: 15.18780345,
    latitude: 40.58118831,
  },
  {
    label: "Sessa Cilento",
    longitude: 15.07527263,
    latitude: 40.25928966,
  },
  {
    label: "Siano",
    longitude: 14.69362433,
    latitude: 40.8009862,
  },
  {
    label: "Sicignano degli Alburni",
    longitude: 15.30595541,
    latitude: 40.55933929,
  },
  {
    label: "Stella Cilento",
    longitude: 15.09330128,
    latitude: 40.23160695,
  },
  {
    label: "Stio",
    longitude: 15.25520959,
    latitude: 40.3108142,
  },
  {
    label: "Teggiano",
    longitude: 15.54040859,
    latitude: 40.3796533,
  },
  {
    label: "Torchiara",
    longitude: 15.05369631,
    latitude: 40.3216153,
  },
  {
    label: "Torraca",
    longitude: 15.63631714,
    latitude: 40.11098775,
  },
  {
    label: "Torre Orsaia",
    longitude: 15.47143926,
    latitude: 40.13094394,
  },
  {
    label: "Tortorella",
    longitude: 15.60625318,
    latitude: 40.14235689,
  },
  {
    label: "Tramonti",
    longitude: 14.64019238,
    latitude: 40.69483916,
  },
  {
    label: "Trentinara",
    longitude: 15.10735868,
    latitude: 40.4006827,
  },
  {
    label: "Valle dell'Angelo",
    longitude: 15.36868776,
    latitude: 40.34377463,
  },
  {
    label: "Vallo della Lucania",
    longitude: 15.26707186,
    latitude: 40.22877547,
  },
  {
    label: "Valva",
    longitude: 15.26988886,
    latitude: 40.73970939,
  },
  {
    label: "Vibonati",
    longitude: 15.57993678,
    latitude: 40.10320009,
  },
  {
    label: "Vietri sul Mare",
    longitude: 14.7281568,
    latitude: 40.67202929,
  },
  {
    label: "Bellizzi",
    longitude: 14.94602767,
    latitude: 40.62218574,
  },
  {
    label: "Acciano",
    longitude: 13.71834861,
    latitude: 42.1767172,
  },
  {
    label: "Aielli",
    longitude: 13.5902834,
    latitude: 42.08021928,
  },
  {
    label: "Alfedena",
    longitude: 14.03435518,
    latitude: 41.73396985,
  },
  {
    label: "Anversa degli Abruzzi",
    longitude: 13.8035817,
    latitude: 41.99463497,
  },
  {
    label: "Ateleta",
    longitude: 14.19778348,
    latitude: 41.85503505,
  },
  {
    label: "Avezzano",
    longitude: 13.4267507,
    latitude: 42.03068032,
  },
  {
    label: "Balsorano",
    longitude: 13.56334801,
    latitude: 41.80361236,
  },
  {
    label: "Barete",
    longitude: 13.28126973,
    latitude: 42.45096144,
  },
  {
    label: "Barisciano",
    longitude: 13.59077801,
    latitude: 42.32702005,
  },
  {
    label: "Barrea",
    longitude: 13.99152867,
    latitude: 41.75758413,
  },
  {
    label: "Bisegna",
    longitude: 13.75748539,
    latitude: 41.92098736,
  },
  {
    label: "Bugnara",
    longitude: 13.85871232,
    latitude: 42.0221591,
  },
  {
    label: "Cagnano Amiterno",
    longitude: 13.22460313,
    latitude: 42.45911331,
  },
  {
    label: "Calascio",
    longitude: 13.69480342,
    latitude: 42.32552057,
  },
  {
    label: "Campo di Giove",
    longitude: 14.04454313,
    latitude: 42.01192578,
  },
  {
    label: "Campotosto",
    longitude: 13.36989188,
    latitude: 42.55735732,
  },
  {
    label: "Canistro",
    longitude: 13.41146423,
    latitude: 41.94121103,
  },
  {
    label: "Cansano",
    longitude: 14.01095792,
    latitude: 42.00461222,
  },
  {
    label: "Capestrano",
    longitude: 13.76827562,
    latitude: 42.2674233,
  },
  {
    label: "Capistrello",
    longitude: 13.3896681,
    latitude: 41.96555325,
  },
  {
    label: "Capitignano",
    longitude: 13.30080121,
    latitude: 42.52023244,
  },
  {
    label: "Caporciano",
    longitude: 13.67134169,
    latitude: 42.25185802,
  },
  {
    label: "Cappadocia",
    longitude: 13.27922691,
    latitude: 42.00705716,
  },
  {
    label: "Carapelle Calvisio",
    longitude: 13.68320669,
    latitude: 42.29914841,
  },
  {
    label: "Carsoli",
    longitude: 13.08268758,
    latitude: 42.09641669,
  },
  {
    label: "Castel del Monte",
    longitude: 13.72647549,
    latitude: 42.3648464,
  },
  {
    label: "Castel di Ieri",
    longitude: 13.74075737,
    latitude: 42.11406339,
  },
  {
    label: "Castel di Sangro",
    longitude: 14.10848588,
    latitude: 41.78521078,
  },
  {
    label: "Castellafiume",
    longitude: 13.33468595,
    latitude: 41.9879634,
  },
  {
    label: "Castelvecchio Calvisio",
    longitude: 13.68873416,
    latitude: 42.31015377,
  },
  {
    label: "Castelvecchio Subequo",
    longitude: 13.72883016,
    latitude: 42.131473,
  },
  {
    label: "Celano",
    longitude: 13.54822195,
    latitude: 42.08483293,
  },
  {
    label: "Cerchio",
    longitude: 13.60051272,
    latitude: 42.06411666,
  },
  {
    label: "Civita d'Antino",
    longitude: 13.47212788,
    latitude: 41.8863737,
  },
  {
    label: "Civitella Alfedena",
    longitude: 13.94054793,
    latitude: 41.765321,
  },
  {
    label: "Civitella Roveto",
    longitude: 13.42687644,
    latitude: 41.91621541,
  },
  {
    label: "Cocullo",
    longitude: 13.77471751,
    latitude: 42.03315435,
  },
  {
    label: "Collarmele",
    longitude: 13.62642991,
    latitude: 42.06132915,
  },
  {
    label: "Collelongo",
    longitude: 13.58350663,
    latitude: 41.88639196,
  },
  {
    label: "Collepietro",
    longitude: 13.78056281,
    latitude: 42.22095541,
  },
  {
    label: "Corfinio",
    longitude: 13.84386453,
    latitude: 42.12415709,
  },
  {
    label: "Fagnano Alto",
    longitude: 13.57498963,
    latitude: 42.25372071,
  },
  {
    label: "Fontecchio",
    longitude: 13.60659031,
    latitude: 42.23163903,
  },
  {
    label: "Fossa",
    longitude: 13.48781584,
    latitude: 42.2933,
  },
  {
    label: "Gagliano Aterno",
    longitude: 13.70086464,
    latitude: 42.12626341,
  },
  {
    label: "Gioia dei Marsi",
    longitude: 13.69322355,
    latitude: 41.95423177,
  },
  {
    label: "Goriano Sicoli",
    longitude: 13.77339081,
    latitude: 42.07982763,
  },
  {
    label: "Introdacqua",
    longitude: 13.89919227,
    latitude: 42.00769338,
  },
  {
    label: "L'Aquila",
    longitude: 13.39843828,
    latitude: 42.35122195,
  },
  {
    label: "Lecce nei Marsi",
    longitude: 13.68719733,
    latitude: 41.93294714,
  },
  {
    label: "Luco dei Marsi",
    longitude: 13.46898624,
    latitude: 41.96188883,
  },
  {
    label: "Lucoli",
    longitude: 13.3377175,
    latitude: 42.29102275,
  },
  {
    label: "Magliano de' Marsi",
    longitude: 13.36478878,
    latitude: 42.09228508,
  },
  {
    label: "Massa d'Albe",
    longitude: 13.39220278,
    latitude: 42.10899275,
  },
  {
    label: "Molina Aterno",
    longitude: 13.73574921,
    latitude: 42.14804614,
  },
  {
    label: "Montereale",
    longitude: 13.2450867,
    latitude: 42.52407436,
  },
  {
    label: "Morino",
    longitude: 13.45754199,
    latitude: 41.86476305,
  },
  {
    label: "Navelli",
    longitude: 13.73031427,
    latitude: 42.23627016,
  },
  {
    label: "Ocre",
    longitude: 13.47303035,
    latitude: 42.28381801,
  },
  {
    label: "Ofena",
    longitude: 13.75886039,
    latitude: 42.32815414,
  },
  {
    label: "Opi",
    longitude: 13.82999707,
    latitude: 41.77947207,
  },
  {
    label: "Oricola",
    longitude: 13.038902,
    latitude: 42.04987795,
  },
  {
    label: "Ortona dei Marsi",
    longitude: 13.72916655,
    latitude: 41.99781346,
  },
  {
    label: "Ortucchio",
    longitude: 13.64540931,
    latitude: 41.9548841,
  },
  {
    label: "Ovindoli",
    longitude: 13.51768083,
    latitude: 42.13311965,
  },
  {
    label: "Pacentro",
    longitude: 13.99339126,
    latitude: 42.05107519,
  },
  {
    label: "Pereto",
    longitude: 13.10151382,
    latitude: 42.05807359,
  },
  {
    label: "Pescasseroli",
    longitude: 13.78921936,
    latitude: 41.80863309,
  },
  {
    label: "Pescina",
    longitude: 13.66006389,
    latitude: 42.02607152,
  },
  {
    label: "Pescocostanzo",
    longitude: 14.06577793,
    latitude: 41.89027447,
  },
  {
    label: "Pettorano sul Gizio",
    longitude: 13.96035129,
    latitude: 41.97280833,
  },
  {
    label: "Pizzoli",
    longitude: 13.29540074,
    latitude: 42.43621147,
  },
  {
    label: "Poggio Picenze",
    longitude: 13.54219621,
    latitude: 42.32067735,
  },
  {
    label: "Prata d'Ansidonia",
    longitude: 13.60628119,
    latitude: 42.27824054,
  },
  {
    label: "Pratola Peligna",
    longitude: 13.87243478,
    latitude: 42.09736189,
  },
  {
    label: "Prezza",
    longitude: 13.83319794,
    latitude: 42.05774787,
  },
  {
    label: "Raiano",
    longitude: 13.80833401,
    latitude: 42.10280428,
  },
  {
    label: "Rivisondoli",
    longitude: 14.06636277,
    latitude: 41.87039501,
  },
  {
    label: "Roccacasale",
    longitude: 13.88998309,
    latitude: 42.12230998,
  },
  {
    label: "Rocca di Botte",
    longitude: 13.06821692,
    latitude: 42.02626728,
  },
  {
    label: "Rocca di Cambio",
    longitude: 13.48939593,
    latitude: 42.23678496,
  },
  {
    label: "Rocca di Mezzo",
    longitude: 13.51866211,
    latitude: 42.20778601,
  },
  {
    label: "Rocca Pia",
    longitude: 13.97694415,
    latitude: 41.93478803,
  },
  {
    label: "Roccaraso",
    longitude: 14.07855708,
    latitude: 41.84626771,
  },
  {
    label: "San Benedetto dei Marsi",
    longitude: 13.62330577,
    latitude: 42.00645326,
  },
  {
    label: "San Benedetto in Perillis",
    longitude: 13.76975802,
    latitude: 42.18278375,
  },
  {
    label: "San Demetrio ne' Vestini",
    longitude: 13.55876665,
    latitude: 42.28805466,
  },
  {
    label: "San Pio delle Camere",
    longitude: 13.65480659,
    latitude: 42.28623381,
  },
  {
    label: "Sante Marie",
    longitude: 13.20298412,
    latitude: 42.10286019,
  },
  {
    label: "Sant'Eusanio Forconese",
    longitude: 13.52439142,
    latitude: 42.28870031,
  },
  {
    label: "Santo Stefano di Sessanio",
    longitude: 13.64651672,
    latitude: 42.34223754,
  },
  {
    label: "San Vincenzo Valle Roveto",
    longitude: 13.52378608,
    latitude: 41.83373081,
  },
  {
    label: "Scanno",
    longitude: 13.88116045,
    latitude: 41.9023341,
  },
  {
    label: "Scontrone",
    longitude: 14.03905072,
    latitude: 41.74620234,
  },
  {
    label: "Scoppito",
    longitude: 13.25647866,
    latitude: 42.37106636,
  },
  {
    label: "Scurcola Marsicana",
    longitude: 13.34240445,
    latitude: 42.06445594,
  },
  {
    label: "Secinaro",
    longitude: 13.67902536,
    latitude: 42.15252692,
  },
  {
    label: "Sulmona",
    longitude: 13.92405522,
    latitude: 42.04780655,
  },
  {
    label: "Tagliacozzo",
    longitude: 13.2554916,
    latitude: 42.06871211,
  },
  {
    label: "Tione degli Abruzzi",
    longitude: 13.63574573,
    latitude: 42.20389024,
  },
  {
    label: "Tornimparte",
    longitude: 13.30033128,
    latitude: 42.29095419,
  },
  {
    label: "Trasacco",
    longitude: 13.53880825,
    latitude: 41.95809518,
  },
  {
    label: "Villalago",
    longitude: 13.83577383,
    latitude: 41.93601049,
  },
  {
    label: "Villa Santa Lucia degli Abruzzi",
    longitude: 13.77819281,
    latitude: 42.33302782,
  },
  {
    label: "Villa Sant'Angelo",
    longitude: 13.53754168,
    latitude: 42.27002885,
  },
  {
    label: "Villavallelonga",
    longitude: 13.62453677,
    latitude: 41.86734949,
  },
  {
    label: "Villetta Barrea",
    longitude: 13.92775237,
    latitude: 41.77660064,
  },
  {
    label: "Vittorito",
    longitude: 13.81616784,
    latitude: 42.1254495,
  },
  {
    label: "Alba Adriatica",
    longitude: 13.92095195,
    latitude: 42.83459777,
  },
  {
    label: "Ancarano",
    longitude: 13.74139609,
    latitude: 42.83680898,
  },
  {
    label: "Arsita",
    longitude: 13.78595109,
    latitude: 42.50307767,
  },
  {
    label: "Atri",
    longitude: 13.97541817,
    latitude: 42.57971067,
  },
  {
    label: "Basciano",
    longitude: 13.73863139,
    latitude: 42.59570914,
  },
  {
    label: "Bellante",
    longitude: 13.80535903,
    latitude: 42.74460127,
  },
  {
    label: "Bisenti",
    longitude: 13.80368606,
    latitude: 42.52877601,
  },
  {
    label: "Campli",
    longitude: 13.68635588,
    latitude: 42.72636043,
  },
  {
    label: "Canzano",
    longitude: 13.80311212,
    latitude: 42.64718163,
  },
  {
    label: "Castel Castagna",
    longitude: 13.71708406,
    latitude: 42.54294987,
  },
  {
    label: "Castellalto",
    longitude: 13.81746384,
    latitude: 42.67539533,
  },
  {
    label: "Castelli",
    longitude: 13.71171618,
    latitude: 42.48941029,
  },
  {
    label: "Castiglione Messer Raimondo",
    longitude: 13.88233591,
    latitude: 42.53143207,
  },
  {
    label: "Castilenti",
    longitude: 13.9177664,
    latitude: 42.53336403,
  },
  {
    label: "Cellino Attanasio",
    longitude: 13.85952212,
    latitude: 42.5862723,
  },
  {
    label: "Cermignano",
    longitude: 13.79237577,
    latitude: 42.58862483,
  },
  {
    label: "Civitella del Tronto",
    longitude: 13.66549149,
    latitude: 42.77170903,
  },
  {
    label: "Colledara",
    longitude: 13.67892239,
    latitude: 42.53967093,
  },
  {
    label: "Colonnella",
    longitude: 13.86756702,
    latitude: 42.87176703,
  },
  {
    label: "Controguerra",
    longitude: 13.82056897,
    latitude: 42.85567003,
  },
  {
    label: "Corropoli",
    longitude: 13.83486055,
    latitude: 42.82761332,
  },
  {
    label: "Cortino",
    longitude: 13.50772388,
    latitude: 42.62169309,
  },
  {
    label: "Crognaleto",
    longitude: 13.4790555,
    latitude: 42.54772307,
  },
  {
    label: "Fano Adriano",
    longitude: 13.53702451,
    latitude: 42.55134216,
  },
  {
    label: "Giulianova",
    longitude: 13.95671861,
    latitude: 42.7526577,
  },
  {
    label: "Isola del Gran Sasso d'Italia",
    longitude: 13.66201352,
    latitude: 42.50254059,
  },
  {
    label: "Montefino",
    longitude: 13.8844286,
    latitude: 42.54481325,
  },
  {
    label: "Montorio al Vomano",
    longitude: 13.63132023,
    latitude: 42.58305103,
  },
  {
    label: "Morro d'Oro",
    longitude: 13.91962038,
    latitude: 42.66403035,
  },
  {
    label: "Mosciano Sant'Angelo",
    longitude: 13.88789032,
    latitude: 42.7499814,
  },
  {
    label: "Nereto",
    longitude: 13.81799839,
    latitude: 42.82034259,
  },
  {
    label: "Notaresco",
    longitude: 13.89404399,
    latitude: 42.6590295,
  },
  {
    label: "Penna Sant'Andrea",
    longitude: 13.77150625,
    latitude: 42.5936281,
  },
  {
    label: "Pietracamela",
    longitude: 13.55375913,
    latitude: 42.52322515,
  },
  {
    label: "Pineto",
    longitude: 14.06747199,
    latitude: 42.60827893,
  },
  {
    label: "Rocca Santa Maria",
    longitude: 13.52689341,
    latitude: 42.68584163,
  },
  {
    label: "Roseto degli Abruzzi",
    longitude: 14.01155502,
    latitude: 42.6801656,
  },
  {
    label: "Sant'Egidio alla Vibrata",
    longitude: 13.71649081,
    latitude: 42.8254944,
  },
  {
    label: "Sant'Omero",
    longitude: 13.80289563,
    latitude: 42.78665683,
  },
  {
    label: "Silvi",
    longitude: 14.11395586,
    latitude: 42.55680778,
  },
  {
    label: "Teramo",
    longitude: 13.70439981,
    latitude: 42.6589177,
  },
  {
    label: "Torano Nuovo",
    longitude: 13.77702409,
    latitude: 42.82337481,
  },
  {
    label: "Torricella Sicura",
    longitude: 13.65508442,
    latitude: 42.65690638,
  },
  {
    label: "Tortoreto",
    longitude: 13.91333283,
    latitude: 42.80398284,
  },
  {
    label: "Tossicia",
    longitude: 13.64881267,
    latitude: 42.54575594,
  },
  {
    label: "Valle Castellana",
    longitude: 13.49762647,
    latitude: 42.73540693,
  },
  {
    label: "Martinsicuro",
    longitude: 13.91574129,
    latitude: 42.88555717,
  },
  {
    label: "Abbateggio",
    longitude: 14.01007278,
    latitude: 42.22446748,
  },
  {
    label: "Alanno",
    longitude: 13.96933279,
    latitude: 42.29443285,
  },
  {
    label: "Bolognano",
    longitude: 13.95981998,
    latitude: 42.21702314,
  },
  {
    label: "Brittoli",
    longitude: 13.8609368,
    latitude: 42.31648387,
  },
  {
    label: "Bussi sul Tirino",
    longitude: 13.82665193,
    latitude: 42.21040316,
  },
  {
    label: "Cappelle sul Tavo",
    longitude: 14.10331839,
    latitude: 42.4651845,
  },
  {
    label: "Caramanico Terme",
    longitude: 14.00313927,
    latitude: 42.15724285,
  },
  {
    label: "Carpineto della Nora",
    longitude: 13.85931512,
    latitude: 42.332807,
  },
  {
    label: "Castiglione a Casauria",
    longitude: 13.89398603,
    latitude: 42.23627853,
  },
  {
    label: "Catignano",
    longitude: 13.94976689,
    latitude: 42.34603499,
  },
  {
    label: "Cepagatti",
    longitude: 14.07304231,
    latitude: 42.36462231,
  },
  {
    label: "Città Sant'Angelo",
    longitude: 14.05867961,
    latitude: 42.51832347,
  },
  {
    label: "Civitaquana",
    longitude: 13.89728216,
    latitude: 42.32623508,
  },
  {
    label: "Civitella Casanova",
    longitude: 13.88894505,
    latitude: 42.36472189,
  },
  {
    label: "Collecorvino",
    longitude: 14.01436592,
    latitude: 42.45822402,
  },
  {
    label: "Corvara",
    longitude: 13.88791714,
    latitude: 42.27704553,
  },
  {
    label: "Cugnoli",
    longitude: 13.93306399,
    latitude: 42.308199,
  },
  {
    label: "Elice",
    longitude: 13.96826815,
    latitude: 42.51869149,
  },
  {
    label: "Farindola",
    longitude: 13.82197443,
    latitude: 42.44155572,
  },
  {
    label: "Lettomanoppello",
    longitude: 14.03618703,
    latitude: 42.23688323,
  },
  {
    label: "Loreto Aprutino",
    longitude: 13.98748336,
    latitude: 42.43234542,
  },
  {
    label: "Manoppello",
    longitude: 14.06045336,
    latitude: 42.26106208,
  },
  {
    label: "Montebello di Bertona",
    longitude: 13.86964621,
    latitude: 42.41651749,
  },
  {
    label: "Montesilvano",
    longitude: 14.14938288,
    latitude: 42.51412705,
  },
  {
    label: "Moscufo",
    longitude: 14.05507837,
    latitude: 42.42851208,
  },
  {
    label: "Nocciano",
    longitude: 13.98409517,
    latitude: 42.3340024,
  },
  {
    label: "Penne",
    longitude: 13.92738518,
    latitude: 42.4580394,
  },
  {
    label: "Pescara",
    longitude: 14.21364841,
    latitude: 42.46458397,
  },
  {
    label: "Pescosansonesco",
    longitude: 13.88437192,
    latitude: 42.2540411,
  },
  {
    label: "Pianella",
    longitude: 14.04604868,
    latitude: 42.39631063,
  },
  {
    label: "Picciano",
    longitude: 13.99134633,
    latitude: 42.47480789,
  },
  {
    label: "Pietranico",
    longitude: 13.91085166,
    latitude: 42.2762409,
  },
  {
    label: "Popoli",
    longitude: 13.83311196,
    latitude: 42.17116694,
  },
  {
    label: "Roccamorice",
    longitude: 14.02387818,
    latitude: 42.21376708,
  },
  {
    label: "Rosciano",
    longitude: 14.04719852,
    latitude: 42.32247679,
  },
  {
    label: "Salle",
    longitude: 13.95924336,
    latitude: 42.1770416,
  },
  {
    label: "Sant'Eufemia a Maiella",
    longitude: 14.02770664,
    latitude: 42.12538882,
  },
  {
    label: "San Valentino in Abruzzo Citeriore",
    longitude: 13.98720812,
    latitude: 42.23270318,
  },
  {
    label: "Scafa",
    longitude: 14.00213203,
    latitude: 42.26786996,
  },
  {
    label: "Serramonacesca",
    longitude: 14.09369441,
    latitude: 42.24771535,
  },
  {
    label: "Spoltore",
    longitude: 14.14148276,
    latitude: 42.45568799,
  },
  {
    label: "Tocco da Casauria",
    longitude: 13.9138465,
    latitude: 42.21792904,
  },
  {
    label: "Torre de' Passeri",
    longitude: 13.92788172,
    latitude: 42.24496105,
  },
  {
    label: "Turrivalignani",
    longitude: 14.02867836,
    latitude: 42.26295569,
  },
  {
    label: "Vicoli",
    longitude: 13.89515003,
    latitude: 42.33984925,
  },
  {
    label: "Villa Celiera",
    longitude: 13.85920343,
    latitude: 42.38068849,
  },
  {
    label: "Altino",
    longitude: 14.33260982,
    latitude: 42.1024207,
  },
  {
    label: "Archi",
    longitude: 14.38157415,
    latitude: 42.08914074,
  },
  {
    label: "Ari",
    longitude: 14.2601366,
    latitude: 42.29111265,
  },
  {
    label: "Arielli",
    longitude: 14.30477819,
    latitude: 42.26499697,
  },
  {
    label: "Atessa",
    longitude: 14.44308594,
    latitude: 42.06734847,
  },
  {
    label: "Bomba",
    longitude: 14.36623306,
    latitude: 42.0351818,
  },
  {
    label: "Borrello",
    longitude: 14.30325539,
    latitude: 41.92040984,
  },
  {
    label: "Bucchianico",
    longitude: 14.18070314,
    latitude: 42.30434387,
  },
  {
    label: "Montebello sul Sangro",
    longitude: 14.32504319,
    latitude: 41.98663266,
  },
  {
    label: "Canosa Sannita",
    longitude: 14.30186987,
    latitude: 42.29528868,
  },
  {
    label: "Carpineto Sinello",
    longitude: 14.50777459,
    latitude: 42.01363552,
  },
  {
    label: "Carunchio",
    longitude: 14.52671055,
    latitude: 41.91698351,
  },
  {
    label: "Casacanditella",
    longitude: 14.19883416,
    latitude: 42.24832401,
  },
  {
    label: "Casalanguida",
    longitude: 14.49639625,
    latitude: 42.03726885,
  },
  {
    label: "Casalbordino",
    longitude: 14.58488508,
    latitude: 42.1507226,
  },
  {
    label: "Casalincontrada",
    longitude: 14.13186259,
    latitude: 42.28624654,
  },
  {
    label: "Casoli",
    longitude: 14.29043913,
    latitude: 42.1150425,
  },
  {
    label: "Castel Frentano",
    longitude: 14.35661796,
    latitude: 42.19622327,
  },
  {
    label: "Castelguidone",
    longitude: 14.52299348,
    latitude: 41.82349049,
  },
  {
    label: "Castiglione Messer Marino",
    longitude: 14.45170154,
    latitude: 41.86693563,
  },
  {
    label: "Celenza sul Trigno",
    longitude: 14.58113433,
    latitude: 41.87177294,
  },
  {
    label: "Chieti",
    longitude: 14.16754591,
    latitude: 42.35103166,
  },
  {
    label: "Civitaluparella",
    longitude: 14.30305092,
    latitude: 41.9448394,
  },
  {
    label: "Civitella Messer Raimondo",
    longitude: 14.2155553,
    latitude: 42.0878727,
  },
  {
    label: "Colledimacine",
    longitude: 14.20159134,
    latitude: 42.00460744,
  },
  {
    label: "Colledimezzo",
    longitude: 14.38107294,
    latitude: 41.98569614,
  },
  {
    label: "Crecchio",
    longitude: 14.32675702,
    latitude: 42.29857221,
  },
  {
    label: "Cupello",
    longitude: 14.67089987,
    latitude: 42.07224492,
  },
  {
    label: "Dogliola",
    longitude: 14.63647046,
    latitude: 41.94191728,
  },
  {
    label: "Fara Filiorum Petri",
    longitude: 14.18612026,
    latitude: 42.24897245,
  },
  {
    label: "Fara San Martino",
    longitude: 14.20544873,
    latitude: 42.09024764,
  },
  {
    label: "Filetto",
    longitude: 14.24428861,
    latitude: 42.22593239,
  },
  {
    label: "Fossacesia",
    longitude: 14.48006072,
    latitude: 42.24537842,
  },
  {
    label: "Fraine",
    longitude: 14.48694155,
    latitude: 41.90475596,
  },
  {
    label: "Francavilla al Mare",
    longitude: 14.29181707,
    latitude: 42.4176665,
  },
  {
    label: "Fresagrandinaria",
    longitude: 14.66316103,
    latitude: 41.97557881,
  },
  {
    label: "Frisa",
    longitude: 14.3706206,
    latitude: 42.26096809,
  },
  {
    label: "Furci",
    longitude: 14.5865475,
    latitude: 42.00849187,
  },
  {
    label: "Gamberale",
    longitude: 14.20753823,
    latitude: 41.9054693,
  },
  {
    label: "Gessopalena",
    longitude: 14.27375218,
    latitude: 42.05634892,
  },
  {
    label: "Gissi",
    longitude: 14.54652963,
    latitude: 42.02081826,
  },
  {
    label: "Giuliano Teatino",
    longitude: 14.2754346,
    latitude: 42.29314227,
  },
  {
    label: "Guardiagrele",
    longitude: 14.2202068,
    latitude: 42.19114112,
  },
  {
    label: "Guilmi",
    longitude: 14.47597444,
    latitude: 41.99703463,
  },
  {
    label: "Lama dei Peligni",
    longitude: 14.18876325,
    latitude: 42.0424842,
  },
  {
    label: "Lanciano",
    longitude: 14.3904074,
    latitude: 42.2313339,
  },
  {
    label: "Lentella",
    longitude: 14.67654213,
    latitude: 41.99720552,
  },
  {
    label: "Lettopalena",
    longitude: 14.15809945,
    latitude: 42.00306774,
  },
  {
    label: "Liscia",
    longitude: 14.55501307,
    latitude: 41.95546155,
  },
  {
    label: "Miglianico",
    longitude: 14.29153807,
    latitude: 42.35950925,
  },
  {
    label: "Montazzoli",
    longitude: 14.42936125,
    latitude: 41.94818588,
  },
  {
    label: "Monteferrante",
    longitude: 14.38819112,
    latitude: 41.95456895,
  },
  {
    label: "Montelapiano",
    longitude: 14.34234639,
    latitude: 41.96128136,
  },
  {
    label: "Montenerodomo",
    longitude: 14.25180284,
    latitude: 41.97660203,
  },
  {
    label: "Monteodorisio",
    longitude: 14.65273428,
    latitude: 42.08807769,
  },
  {
    label: "Mozzagrogna",
    longitude: 14.44467908,
    latitude: 42.21283045,
  },
  {
    label: "Orsogna",
    longitude: 14.28209955,
    latitude: 42.21867175,
  },
  {
    label: "Ortona",
    longitude: 14.40347679,
    latitude: 42.35563767,
  },
  {
    label: "Paglieta",
    longitude: 14.49851779,
    latitude: 42.16270714,
  },
  {
    label: "Palena",
    longitude: 14.13777392,
    latitude: 41.98344831,
  },
  {
    label: "Palmoli",
    longitude: 14.5807302,
    latitude: 41.93876829,
  },
  {
    label: "Palombaro",
    longitude: 14.22995712,
    latitude: 42.12413743,
  },
  {
    label: "Pennadomo",
    longitude: 14.32326089,
    latitude: 42.00447119,
  },
  {
    label: "Pennapiedimonte",
    longitude: 14.19491874,
    latitude: 42.15198245,
  },
  {
    label: "Perano",
    longitude: 14.39650019,
    latitude: 42.10783909,
  },
  {
    label: "Pizzoferrato",
    longitude: 14.23726867,
    latitude: 41.92227603,
  },
  {
    label: "Poggiofiorito",
    longitude: 14.3212818,
    latitude: 42.25410606,
  },
  {
    label: "Pollutri",
    longitude: 14.59412768,
    latitude: 42.1373019,
  },
  {
    label: "Pretoro",
    longitude: 14.14250416,
    latitude: 42.21678292,
  },
  {
    label: "Quadri",
    longitude: 14.28837751,
    latitude: 41.9258167,
  },
  {
    label: "Rapino",
    longitude: 14.18758994,
    latitude: 42.21170465,
  },
  {
    label: "Ripa Teatina",
    longitude: 14.23365475,
    latitude: 42.3574808,
  },
  {
    label: "Roccamontepiano",
    longitude: 14.12187201,
    latitude: 42.24763993,
  },
  {
    label: "Rocca San Giovanni",
    longitude: 14.4649516,
    latitude: 42.25110384,
  },
  {
    label: "Roccascalegna",
    longitude: 14.30670227,
    latitude: 42.0621504,
  },
  {
    label: "Roccaspinalveti",
    longitude: 14.47120846,
    latitude: 41.93807804,
  },
  {
    label: "Roio del Sangro",
    longitude: 14.37280599,
    latitude: 41.91163774,
  },
  {
    label: "Rosello",
    longitude: 14.34900426,
    latitude: 41.90157264,
  },
  {
    label: "San Buono",
    longitude: 14.56934722,
    latitude: 41.98061831,
  },
  {
    label: "San Giovanni Lipioni",
    longitude: 14.56198491,
    latitude: 41.84278544,
  },
  {
    label: "San Giovanni Teatino",
    longitude: 14.18675059,
    latitude: 42.42197679,
  },
  {
    label: "San Martino sulla Marrucina",
    longitude: 14.21412499,
    latitude: 42.22504222,
  },
  {
    label: "San Salvo",
    longitude: 14.73140605,
    latitude: 42.04555595,
  },
  {
    label: "Santa Maria Imbaro",
    longitude: 14.44706971,
    latitude: 42.2199428,
  },
  {
    label: "Sant'Eusanio del Sangro",
    longitude: 14.33198374,
    latitude: 42.16821529,
  },
  {
    label: "San Vito Chietino",
    longitude: 14.44571588,
    latitude: 42.30260547,
  },
  {
    label: "Scerni",
    longitude: 14.5690808,
    latitude: 42.11176682,
  },
  {
    label: "Schiavi di Abruzzo",
    longitude: 14.48539577,
    latitude: 41.81570705,
  },
  {
    label: "Taranta Peligna",
    longitude: 14.16912406,
    latitude: 42.01988257,
  },
  {
    label: "Tollo",
    longitude: 14.31773812,
    latitude: 42.33949616,
  },
  {
    label: "Torino di Sangro",
    longitude: 14.54089262,
    latitude: 42.18811907,
  },
  {
    label: "Tornareccio",
    longitude: 14.41281186,
    latitude: 42.03637888,
  },
  {
    label: "Torrebruna",
    longitude: 14.54114564,
    latitude: 41.86800245,
  },
  {
    label: "Torrevecchia Teatina",
    longitude: 14.20607116,
    latitude: 42.379534,
  },
  {
    label: "Torricella Peligna",
    longitude: 14.26111517,
    latitude: 42.02182687,
  },
  {
    label: "Treglio",
    longitude: 14.42487271,
    latitude: 42.26734432,
  },
  {
    label: "Tufillo",
    longitude: 14.6263562,
    latitude: 41.91632933,
  },
  {
    label: "Vacri",
    longitude: 14.23278786,
    latitude: 42.29777577,
  },
  {
    label: "Vasto",
    longitude: 14.70791975,
    latitude: 42.1122107,
  },
  {
    label: "Villalfonsina",
    longitude: 14.57408929,
    latitude: 42.16480253,
  },
  {
    label: "Villamagna",
    longitude: 14.23673219,
    latitude: 42.3332315,
  },
  {
    label: "Villa Santa Maria",
    longitude: 14.35065336,
    latitude: 41.9512081,
  },
  {
    label: "Pietraferrazzana",
    longitude: 14.37371043,
    latitude: 41.96963057,
  },
  {
    label: "Fallo",
    longitude: 14.32238942,
    latitude: 41.93896858,
  },
  {
    label: "Acquaviva Collecroce",
    longitude: 14.74673587,
    latitude: 41.86790077,
  },
  {
    label: "Baranello",
    longitude: 14.56051884,
    latitude: 41.52681952,
  },
  {
    label: "Bojano",
    longitude: 14.47143659,
    latitude: 41.48282294,
  },
  {
    label: "Bonefro",
    longitude: 14.93419633,
    latitude: 41.70269449,
  },
  {
    label: "Busso",
    longitude: 14.5621033,
    latitude: 41.55478706,
  },
  {
    label: "Campobasso",
    longitude: 14.65916084,
    latitude: 41.55774753,
  },
  {
    label: "Campochiaro",
    longitude: 14.50564133,
    latitude: 41.44869752,
  },
  {
    label: "Campodipietra",
    longitude: 14.74692986,
    latitude: 41.55759354,
  },
  {
    label: "Campolieto",
    longitude: 14.76695123,
    latitude: 41.63517218,
  },
  {
    label: "Campomarino",
    longitude: 15.03585859,
    latitude: 41.95270988,
  },
  {
    label: "Casacalenda",
    longitude: 14.84713322,
    latitude: 41.73955595,
  },
  {
    label: "Casalciprano",
    longitude: 14.52872488,
    latitude: 41.58167453,
  },
  {
    label: "Castelbottaccio",
    longitude: 14.70710867,
    latitude: 41.75296874,
  },
  {
    label: "Castellino del Biferno",
    longitude: 14.73138196,
    latitude: 41.70220417,
  },
  {
    label: "Castelmauro",
    longitude: 14.71042486,
    latitude: 41.82940389,
  },
  {
    label: "Castropignano",
    longitude: 14.56170054,
    latitude: 41.61981865,
  },
  {
    label: "Cercemaggiore",
    longitude: 14.72209513,
    latitude: 41.460307,
  },
  {
    label: "Cercepiccola",
    longitude: 14.66804192,
    latitude: 41.4593197,
  },
  {
    label: "Civitacampomarano",
    longitude: 14.69042466,
    latitude: 41.78067471,
  },
  {
    label: "Colle d'Anchise",
    longitude: 14.51834488,
    latitude: 41.50912393,
  },
  {
    label: "Colletorto",
    longitude: 14.97294248,
    latitude: 41.66318277,
  },
  {
    label: "Duronia",
    longitude: 14.45882262,
    latitude: 41.65829817,
  },
  {
    label: "Ferrazzano",
    longitude: 14.67094603,
    latitude: 41.53066428,
  },
  {
    label: "Fossalto",
    longitude: 14.54519468,
    latitude: 41.67318906,
  },
  {
    label: "Gambatesa",
    longitude: 14.91403196,
    latitude: 41.50926746,
  },
  {
    label: "Gildone",
    longitude: 14.74023451,
    latitude: 41.50953342,
  },
  {
    label: "Guardialfiera",
    longitude: 14.79330214,
    latitude: 41.80250678,
  },
  {
    label: "Guardiaregia",
    longitude: 14.54137717,
    latitude: 41.43520831,
  },
  {
    label: "Guglionesi",
    longitude: 14.91393616,
    latitude: 41.91069571,
  },
  {
    label: "Jelsi",
    longitude: 14.79609556,
    latitude: 41.51841393,
  },
  {
    label: "Larino",
    longitude: 14.91073537,
    latitude: 41.80019959,
  },
  {
    label: "Limosano",
    longitude: 14.62117165,
    latitude: 41.67663217,
  },
  {
    label: "Lucito",
    longitude: 14.68822014,
    latitude: 41.73180254,
  },
  {
    label: "Lupara",
    longitude: 14.73323184,
    latitude: 41.76214071,
  },
  {
    label: "Macchia Valfortore",
    longitude: 14.91121553,
    latitude: 41.59333465,
  },
  {
    label: "Mafalda",
    longitude: 14.71253364,
    latitude: 41.94150604,
  },
  {
    label: "Matrice",
    longitude: 14.71100166,
    latitude: 41.61240375,
  },
  {
    label: "Mirabello Sannitico",
    longitude: 14.67207595,
    latitude: 41.51528658,
  },
  {
    label: "Molise",
    longitude: 14.4938167,
    latitude: 41.63057681,
  },
  {
    label: "Monacilioni",
    longitude: 14.81045323,
    latitude: 41.61043407,
  },
  {
    label: "Montagano",
    longitude: 14.67376583,
    latitude: 41.64635308,
  },
  {
    label: "Montecilfone",
    longitude: 14.83760622,
    latitude: 41.9018992,
  },
  {
    label: "Montefalcone nel Sannio",
    longitude: 14.63891419,
    latitude: 41.86487478,
  },
  {
    label: "Montelongo",
    longitude: 14.94867286,
    latitude: 41.73583626,
  },
  {
    label: "Montemitro",
    longitude: 14.64675722,
    latitude: 41.88802627,
  },
  {
    label: "Montenero di Bisaccia",
    longitude: 14.78121409,
    latitude: 41.95724749,
  },
  {
    label: "Montorio nei Frentani",
    longitude: 14.93224945,
    latitude: 41.75894407,
  },
  {
    label: "Morrone del Sannio",
    longitude: 14.77825644,
    latitude: 41.71215826,
  },
  {
    label: "Oratino",
    longitude: 14.58452376,
    latitude: 41.58604251,
  },
  {
    label: "Palatitudea",
    longitude: 14.78673649,
    latitude: 41.88847581,
  },
  {
    label: "Petacciato",
    longitude: 14.86010345,
    latitude: 42.01345249,
  },
  {
    label: "Petrella Tifernina",
    longitude: 14.6949298,
    latitude: 41.69133245,
  },
  {
    label: "Pietracatella",
    longitude: 14.87345353,
    latitude: 41.58156822,
  },
  {
    label: "Pietracupa",
    longitude: 14.52115404,
    latitude: 41.68229064,
  },
  {
    label: "Portocannone",
    longitude: 15.01219636,
    latitude: 41.91425709,
  },
  {
    label: "Provvidenti",
    longitude: 14.82236009,
    latitude: 41.71856241,
  },
  {
    label: "Riccia",
    longitude: 14.83638205,
    latitude: 41.48654569,
  },
  {
    label: "Ripabottoni",
    longitude: 14.80653221,
    latitude: 41.68854667,
  },
  {
    label: "Ripalimosani",
    longitude: 14.66280057,
    latitude: 41.61126223,
  },
  {
    label: "Roccavivara",
    longitude: 14.59927052,
    latitude: 41.83483309,
  },
  {
    label: "Rotello",
    longitude: 15.00524088,
    latitude: 41.74890977,
  },
  {
    label: "Salcito",
    longitude: 14.51082012,
    latitude: 41.74735883,
  },
  {
    label: "San Biase",
    longitude: 14.58943786,
    latitude: 41.71493388,
  },
  {
    label: "San Felice del Molise",
    longitude: 14.7006632,
    latitude: 41.89079789,
  },
  {
    label: "San Giacomo degli Schiavoni",
    longitude: 14.94762377,
    latitude: 41.96492899,
  },
  {
    label: "San Giovanni in Galdo",
    longitude: 14.74753546,
    latitude: 41.58839525,
  },
  {
    label: "San Giuliano del Sannio",
    longitude: 14.63963922,
    latitude: 41.45612637,
  },
  {
    label: "San Giuliano di Puglia",
    longitude: 14.96368605,
    latitude: 41.68525805,
  },
  {
    label: "San Martino in Pensilis",
    longitude: 15.0105776,
    latitude: 41.87003497,
  },
  {
    label: "San Massimo",
    longitude: 14.40981406,
    latitude: 41.49266548,
  },
  {
    label: "San Polo Matese",
    longitude: 14.49375592,
    latitude: 41.45936407,
  },
  {
    label: "Santa Croce di Magliano",
    longitude: 14.99112744,
    latitude: 41.71252381,
  },
  {
    label: "Sant'Angelo Limosano",
    longitude: 14.60373639,
    latitude: 41.69278955,
  },
  {
    label: "Sant'Elia a Pianisi",
    longitude: 14.87532734,
    latitude: 41.62179751,
  },
  {
    label: "Sepino",
    longitude: 14.61903343,
    latitude: 41.40711636,
  },
  {
    label: "Spinete",
    longitude: 14.48755127,
    latitude: 41.54337771,
  },
  {
    label: "Tavenna",
    longitude: 14.76327385,
    latitude: 41.90863693,
  },
  {
    label: "Termoli",
    longitude: 14.99554396,
    latitude: 42.00270989,
  },
  {
    label: "Torella del Sannio",
    longitude: 14.52155973,
    latitude: 41.6404357,
  },
  {
    label: "Toro",
    longitude: 14.76595207,
    latitude: 41.57393556,
  },
  {
    label: "Trivento",
    longitude: 14.55096703,
    latitude: 41.78409543,
  },
  {
    label: "Tufara",
    longitude: 14.94578377,
    latitude: 41.48184744,
  },
  {
    label: "Ururi",
    longitude: 15.01182942,
    latitude: 41.81517818,
  },
  {
    label: "Vinchiaturo",
    longitude: 14.5865651,
    latitude: 41.49329871,
  },
  {
    label: "Accadia",
    longitude: 15.33092322,
    latitude: 41.15811611,
  },
  {
    label: "Alberona",
    longitude: 15.1242782,
    latitude: 41.43047911,
  },
  {
    label: "Anzano di Puglia",
    longitude: 15.29034082,
    latitude: 41.1195674,
  },
  {
    label: "Apricena",
    longitude: 15.44323605,
    latitude: 41.78540257,
  },
  {
    label: "Ascoli Satriano",
    longitude: 15.56026594,
    latitude: 41.20814602,
  },
  {
    label: "Biccari",
    longitude: 15.19449634,
    latitude: 41.39743603,
  },
  {
    label: "Bovino",
    longitude: 15.34167092,
    latitude: 41.24923673,
  },
  {
    label: "Cagnano Varano",
    longitude: 15.77260093,
    latitude: 41.82868404,
  },
  {
    label: "Candela",
    longitude: 15.51395846,
    latitude: 41.13773409,
  },
  {
    label: "Carapelle",
    longitude: 15.69370809,
    latitude: 41.36283549,
  },
  {
    label: "Carlantino",
    longitude: 14.97663434,
    latitude: 41.59228664,
  },
  {
    label: "Carpino",
    longitude: 15.85563222,
    latitude: 41.84463118,
  },
  {
    label: "Casalnuovo Monterotaro",
    longitude: 15.10240065,
    latitude: 41.61838389,
  },
  {
    label: "Casalvecchio di Puglia",
    longitude: 15.11094792,
    latitude: 41.59578579,
  },
  {
    label: "Castelluccio dei Sauri",
    longitude: 15.47395057,
    latitude: 41.30409745,
  },
  {
    label: "Castelluccio Valmaggiore",
    longitude: 15.20332643,
    latitude: 41.34310971,
  },
  {
    label: "Castelnuovo della Daunia",
    longitude: 15.11835063,
    latitude: 41.58268079,
  },
  {
    label: "Celenza Valfortore",
    longitude: 14.97751316,
    latitude: 41.562127,
  },
  {
    label: "Celle di San Vito",
    longitude: 15.18121617,
    latitude: 41.32506709,
  },
  {
    label: "Cerignola",
    longitude: 15.90368339,
    latitude: 41.26522173,
  },
  {
    label: "Chieuti",
    longitude: 15.16543404,
    latitude: 41.84448459,
  },
  {
    label: "Deliceto",
    longitude: 15.38733133,
    latitude: 41.22267573,
  },
  {
    label: "Faeto",
    longitude: 15.1612235,
    latitude: 41.32324211,
  },
  {
    label: "Foggia",
    longitude: 15.54305185,
    latitude: 41.46226862,
  },
  {
    label: "Ischitella",
    longitude: 15.89973987,
    latitude: 41.90430864,
  },
  {
    label: "Isole Tremiti",
    longitude: 15.50437208,
    latitude: 42.12042295,
  },
  {
    label: "Lesina",
    longitude: 15.35505648,
    latitude: 41.86338784,
  },
  {
    label: "Lucera",
    longitude: 15.3329611,
    latitude: 41.50614765,
  },
  {
    label: "Manfredonia",
    longitude: 15.91921162,
    latitude: 41.62846824,
  },
  {
    label: "Mattinata",
    longitude: 16.05144319,
    latitude: 41.71053395,
  },
  {
    label: "Monteleone di Puglia",
    longitude: 15.26001604,
    latitude: 41.16478567,
  },
  {
    label: "Monte Sant'Angelo",
    longitude: 15.9581316,
    latitude: 41.70617736,
  },
  {
    label: "Motta Montecorvino",
    longitude: 15.11338242,
    latitude: 41.50734139,
  },
  {
    label: "Orsara di Puglia",
    longitude: 15.26580826,
    latitude: 41.28114863,
  },
  {
    label: "Orta Nova",
    longitude: 15.70726369,
    latitude: 41.32605603,
  },
  {
    label: "Panni",
    longitude: 15.27707676,
    latitude: 41.220257,
  },
  {
    label: "Peschici",
    longitude: 16.01572138,
    latitude: 41.94660438,
  },
  {
    label: "Pietramontecorvino",
    longitude: 15.12977998,
    latitude: 41.54313339,
  },
  {
    label: "Poggio Imperiale",
    longitude: 15.36585931,
    latitude: 41.82189799,
  },
  {
    label: "Rignano Garganico",
    longitude: 15.58784608,
    latitude: 41.67717458,
  },
  {
    label: "Rocchetta Sant'Antonio",
    longitude: 15.45988092,
    latitude: 41.10279082,
  },
  {
    label: "Rodi Garganico",
    longitude: 15.88122636,
    latitude: 41.92813684,
  },
  {
    label: "Roseto Valfortore",
    longitude: 15.09485308,
    latitude: 41.37072716,
  },
  {
    label: "San Giovanni Rotondo",
    longitude: 15.72663755,
    latitude: 41.70781228,
  },
  {
    label: "San Marco in Lamis",
    longitude: 15.63842925,
    latitude: 41.71296267,
  },
  {
    label: "San Marco la Catola",
    longitude: 15.00631645,
    latitude: 41.5256998,
  },
  {
    label: "San Nicandro Garganico",
    longitude: 15.56127649,
    latitude: 41.83890584,
  },
  {
    label: "San Paolo di Civitate",
    longitude: 15.25890288,
    latitude: 41.74075399,
  },
  {
    label: "San Severo",
    longitude: 15.38073828,
    latitude: 41.68587342,
  },
  {
    label: "Sant'Agata di Puglia",
    longitude: 15.37938715,
    latitude: 41.15031932,
  },
  {
    label: "Serracapriola",
    longitude: 15.16194691,
    latitude: 41.81008146,
  },
  {
    label: "Stornara",
    longitude: 15.76780547,
    latitude: 41.28816457,
  },
  {
    label: "Stornarella",
    longitude: 15.73022051,
    latitude: 41.25638123,
  },
  {
    label: "Torremaggiore",
    longitude: 15.2869709,
    latitude: 41.69048638,
  },
  {
    label: "Troia",
    longitude: 15.30810993,
    latitude: 41.36167365,
  },
  {
    label: "Vico del Gargano",
    longitude: 15.95551854,
    latitude: 41.89687107,
  },
  {
    label: "Vieste",
    longitude: 16.18063171,
    latitude: 41.88161463,
  },
  {
    label: "Volturara Appula",
    longitude: 15.05175994,
    latitude: 41.49500946,
  },
  {
    label: "Volturino",
    longitude: 15.12438071,
    latitude: 41.47818089,
  },
  {
    label: "Ordona",
    longitude: 15.62856362,
    latitude: 41.31537102,
  },
  {
    label: "Zapponeta",
    longitude: 15.95522951,
    latitude: 41.45581634,
  },
  {
    label: "Acquaviva delle Fonti",
    longitude: 16.84189135,
    latitude: 40.89657272,
  },
  {
    label: "Adelfia",
    longitude: 16.87199903,
    latitude: 41.00183306,
  },
  {
    label: "Alberobello",
    longitude: 17.23753495,
    latitude: 40.78373019,
  },
  {
    label: "Altamura",
    longitude: 16.55231244,
    latitude: 40.82794435,
  },
  {
    label: "Bari",
    longitude: 16.86737016,
    latitude: 41.12559562,
  },
  {
    label: "Binetto",
    longitude: 16.7084594,
    latitude: 41.02419809,
  },
  {
    label: "Bitetto",
    longitude: 16.74784981,
    latitude: 41.03868353,
  },
  {
    label: "Bitonto",
    longitude: 16.69431895,
    latitude: 41.1100182,
  },
  {
    label: "Bitritto",
    longitude: 16.82580397,
    latitude: 41.04106828,
  },
  {
    label: "Capurso",
    longitude: 16.92020895,
    latitude: 41.04788754,
  },
  {
    label: "Casamassima",
    longitude: 16.91806851,
    latitude: 40.9553792,
  },
  {
    label: "Cassano delle Murge",
    longitude: 16.77174521,
    latitude: 40.88972597,
  },
  {
    label: "Castellana Grotte",
    longitude: 17.16978544,
    latitude: 40.88249993,
  },
  {
    label: "Cellamare",
    longitude: 16.92906646,
    latitude: 41.01842322,
  },
  {
    label: "Conversano",
    longitude: 17.11524325,
    latitude: 40.96608891,
  },
  {
    label: "Corato",
    longitude: 16.41326508,
    latitude: 41.15379819,
  },
  {
    label: "Gioia del Colle",
    longitude: 16.92406768,
    latitude: 40.80069471,
  },
  {
    label: "Giovinazzo",
    longitude: 16.67261845,
    latitude: 41.18810652,
  },
  {
    label: "Gravina in Puglia",
    longitude: 16.41620671,
    latitude: 40.81940669,
  },
  {
    label: "Grumo Appula",
    longitude: 16.71064948,
    latitude: 41.01221421,
  },
  {
    label: "Locorotondo",
    longitude: 17.32662454,
    latitude: 40.75237666,
  },
  {
    label: "Modugno",
    longitude: 16.78027023,
    latitude: 41.08270034,
  },
  {
    label: "Mola di Bari",
    longitude: 17.08171739,
    latitude: 41.06092712,
  },
  {
    label: "Molfetta",
    longitude: 16.60063235,
    latitude: 41.20503057,
  },
  {
    label: "Monopoli",
    longitude: 17.30210276,
    latitude: 40.95258074,
  },
  {
    label: "Noci",
    longitude: 17.12247971,
    latitude: 40.79482177,
  },
  {
    label: "Noicattaro",
    longitude: 16.98483633,
    latitude: 41.03390073,
  },
  {
    label: "Palo del Colle",
    longitude: 16.70401477,
    latitude: 41.05664789,
  },
  {
    label: "Poggiorsini",
    longitude: 16.25244813,
    latitude: 40.9149399,
  },
  {
    label: "Polignano a Mare",
    longitude: 17.21913392,
    latitude: 40.99215729,
  },
  {
    label: "Putignano",
    longitude: 17.1191007,
    latitude: 40.84963964,
  },
  {
    label: "Rutigliano",
    longitude: 17.0079923,
    latitude: 41.01071718,
  },
  {
    label: "Ruvo di Puglia",
    longitude: 16.48485116,
    latitude: 41.11699654,
  },
  {
    label: "Sammichele di Bari",
    longitude: 16.95448003,
    latitude: 40.88789514,
  },
  {
    label: "Sannicandro di Bari",
    longitude: 16.79941328,
    latitude: 41.00069222,
  },
  {
    label: "Santeramo in Colle",
    longitude: 16.7525404,
    latitude: 40.79461702,
  },
  {
    label: "Terlizzi",
    longitude: 16.54212656,
    latitude: 41.13091344,
  },
  {
    label: "Toritto",
    longitude: 16.67887346,
    latitude: 40.99581817,
  },
  {
    label: "Triggiano",
    longitude: 16.92503073,
    latitude: 41.06530997,
  },
  {
    label: "Turi",
    longitude: 17.01911065,
    latitude: 40.91562236,
  },
  {
    label: "Valenzano",
    longitude: 16.88758285,
    latitude: 41.04215431,
  },
  {
    label: "Avetrana",
    longitude: 17.72553974,
    latitude: 40.3486326,
  },
  {
    label: "Carosino",
    longitude: 17.39806119,
    latitude: 40.46599866,
  },
  {
    label: "Castellaneta",
    longitude: 16.93207342,
    latitude: 40.63026114,
  },
  {
    label: "Crispiano",
    longitude: 17.23103294,
    latitude: 40.60522527,
  },
  {
    label: "Faggiano",
    longitude: 17.38859729,
    latitude: 40.42157835,
  },
  {
    label: "Fragagnano",
    longitude: 17.47454788,
    latitude: 40.43190187,
  },
  {
    label: "Ginosa",
    longitude: 16.75757236,
    latitude: 40.57760797,
  },
  {
    label: "Grottaglie",
    longitude: 17.42624084,
    latitude: 40.53345498,
  },
  {
    label: "latitudeerza",
    longitude: 16.79904247,
    latitude: 40.62247626,
  },
  {
    label: "Leporano",
    longitude: 17.33263766,
    latitude: 40.38387163,
  },
  {
    label: "Lizzano",
    longitude: 17.4488364,
    latitude: 40.39057131,
  },
  {
    label: "Manduria",
    longitude: 17.63431392,
    latitude: 40.39896695,
  },
  {
    label: "Martina Franca",
    longitude: 17.33897058,
    latitude: 40.70492676,
  },
  {
    label: "Maruggio",
    longitude: 17.57036287,
    latitude: 40.31996532,
  },
  {
    label: "Massafra",
    longitude: 17.11245596,
    latitude: 40.58819549,
  },
  {
    label: "Monteiasi",
    longitude: 17.3790407,
    latitude: 40.50212393,
  },
  {
    label: "Montemesola",
    longitude: 17.33540332,
    latitude: 40.56230201,
  },
  {
    label: "Monteparano",
    longitude: 17.41370242,
    latitude: 40.44410057,
  },
  {
    label: "Mottola",
    longitude: 17.03828609,
    latitude: 40.63320579,
  },
  {
    label: "Palagianello",
    longitude: 16.97802218,
    latitude: 40.61116442,
  },
  {
    label: "Palagiano",
    longitude: 17.03719997,
    latitude: 40.57850783,
  },
  {
    label: "Pulsano",
    longitude: 17.35250362,
    latitude: 40.38296602,
  },
  {
    label: "Roccaforzata",
    longitude: 17.38887156,
    latitude: 40.43768778,
  },
  {
    label: "San Giorgio Ionico",
    longitude: 17.3835432,
    latitude: 40.45939299,
  },
  {
    label: "San Marzano di San Giuseppe",
    longitude: 17.50559472,
    latitude: 40.4509492,
  },
  {
    label: "Sava",
    longitude: 17.5562317,
    latitude: 40.40332168,
  },
  {
    label: "Taranto",
    longitude: 17.23237621,
    latitude: 40.47354719,
  },
  {
    label: "Torricella",
    longitude: 17.5017528,
    latitude: 40.35499641,
  },
  {
    label: "Statte",
    longitude: 17.20061721,
    latitude: 40.56357214,
  },
  {
    label: "Brindisi",
    longitude: 17.94602372,
    latitude: 40.63848508,
  },
  {
    label: "Carovigno",
    longitude: 17.65938885,
    latitude: 40.70709775,
  },
  {
    label: "Ceglie Messapica",
    longitude: 17.51709246,
    latitude: 40.64697359,
  },
  {
    label: "Cellino San Marco",
    longitude: 17.96963924,
    latitude: 40.4742839,
  },
  {
    label: "Cisternino",
    longitude: 17.42571204,
    latitude: 40.73932206,
  },
  {
    label: "Erchie",
    longitude: 17.73260551,
    latitude: 40.43843463,
  },
  {
    label: "Fasano",
    longitude: 17.35899339,
    latitude: 40.83440238,
  },
  {
    label: "Francavilla Fontana",
    longitude: 17.58338396,
    latitude: 40.53287722,
  },
  {
    label: "latitudeiano",
    longitude: 17.71636285,
    latitude: 40.55457921,
  },
  {
    label: "Mesagne",
    longitude: 17.80858234,
    latitude: 40.5545929,
  },
  {
    label: "Oria",
    longitude: 17.64606913,
    latitude: 40.50270713,
  },
  {
    label: "Ostuni",
    longitude: 17.57782936,
    latitude: 40.7321986,
  },
  {
    label: "San Donaci",
    longitude: 17.92215403,
    latitude: 40.4467828,
  },
  {
    label: "San Michele Salentino",
    longitude: 17.63151178,
    latitude: 40.62900695,
  },
  {
    label: "San Pancrazio Salentino",
    longitude: 17.83834669,
    latitude: 40.41722359,
  },
  {
    label: "San Pietro Vernotico",
    longitude: 17.99716791,
    latitude: 40.48837377,
  },
  {
    label: "San Vito dei Normanni",
    longitude: 17.70328188,
    latitude: 40.65645909,
  },
  {
    label: "Torchiarolo",
    longitude: 18.05525443,
    latitude: 40.48027665,
  },
  {
    label: "Torre Santa Susanna",
    longitude: 17.74281229,
    latitude: 40.46385303,
  },
  {
    label: "Villa Castelli",
    longitude: 17.47588931,
    latitude: 40.57717011,
  },
  {
    label: "Acquarica del Capo",
    longitude: 18.24994845,
    latitude: 39.90548118,
  },
  {
    label: "Alessano",
    longitude: 18.33717833,
    latitude: 39.88496383,
  },
  {
    label: "Alezio",
    longitude: 18.0622259,
    latitude: 40.06010922,
  },
  {
    label: "Alliste",
    longitude: 18.08950629,
    latitude: 39.94751318,
  },
  {
    label: "Andrano",
    longitude: 18.38680849,
    latitude: 39.98358689,
  },
  {
    label: "Aradeo",
    longitude: 18.13479344,
    latitude: 40.12769568,
  },
  {
    label: "Arnesano",
    longitude: 18.0896641,
    latitude: 40.3353575,
  },
  {
    label: "Bagnolo del Salento",
    longitude: 18.35271075,
    latitude: 40.14816512,
  },
  {
    label: "Botrugno",
    longitude: 18.32463753,
    latitude: 40.06444093,
  },
  {
    label: "Calimera",
    longitude: 18.28005637,
    latitude: 40.25121302,
  },
  {
    label: "Campi Salentina",
    longitude: 18.02029811,
    latitude: 40.39918282,
  },
  {
    label: "Cannole",
    longitude: 18.36351441,
    latitude: 40.16786412,
  },
  {
    label: "Caprarica di Lecce",
    longitude: 18.24503297,
    latitude: 40.26288717,
  },
  {
    label: "Carmiano",
    longitude: 18.04546614,
    latitude: 40.34555275,
  },
  {
    label: "Carpignano Salentino",
    longitude: 18.34056743,
    latitude: 40.19794197,
  },
  {
    label: "Casarano",
    longitude: 18.16107134,
    latitude: 40.00917327,
  },
  {
    label: "Castri di Lecce",
    longitude: 18.26679641,
    latitude: 40.27266762,
  },
  {
    label: "Castrignano de' Greci",
    longitude: 18.29524914,
    latitude: 40.17484585,
  },
  {
    label: "Castrignano del Capo",
    longitude: 18.35351827,
    latitude: 39.82937641,
  },
  {
    label: "Cavallino",
    longitude: 18.19450195,
    latitude: 40.31566554,
  },
  {
    label: "Collepasso",
    longitude: 18.16293668,
    latitude: 40.07320903,
  },
  {
    label: "Copertino",
    longitude: 18.04781615,
    latitude: 40.27185847,
  },
  {
    label: "Corigliano d'Otranto",
    longitude: 18.26174637,
    latitude: 40.1599076,
  },
  {
    label: "Corsano",
    longitude: 18.36969874,
    latitude: 39.88968158,
  },
  {
    label: "Cursi",
    longitude: 18.31409756,
    latitude: 40.15011438,
  },
  {
    label: "Cutrofiano",
    longitude: 18.19990507,
    latitude: 40.12722019,
  },
  {
    label: "Diso",
    longitude: 18.38716924,
    latitude: 40.00821624,
  },
  {
    label: "Gagliano del Capo",
    longitude: 18.36948476,
    latitude: 39.84652739,
  },
  {
    label: "Galatitudeina",
    longitude: 18.17227503,
    latitude: 40.1739561,
  },
  {
    label: "Galatitudeone",
    longitude: 18.07167875,
    latitude: 40.14844824,
  },
  {
    label: "Gallipoli",
    longitude: 17.97422723,
    latitude: 40.05318073,
  },
  {
    label: "Giuggianello",
    longitude: 18.36869462,
    latitude: 40.0939455,
  },
  {
    label: "Giurdignano",
    longitude: 18.43113227,
    latitude: 40.12373984,
  },
  {
    label: "Guagnano",
    longitude: 17.94936025,
    latitude: 40.40342818,
  },
  {
    label: "Lecce",
    longitude: 18.17190671,
    latitude: 40.35354239,
  },
  {
    label: "Lequile",
    longitude: 18.14099269,
    latitude: 40.30715326,
  },
  {
    label: "Leverano",
    longitude: 17.99623839,
    latitude: 40.28662216,
  },
  {
    label: "Lizzanello",
    longitude: 18.22213852,
    latitude: 40.30354492,
  },
  {
    label: "Maglie",
    longitude: 18.29885845,
    latitude: 40.1210185,
  },
  {
    label: "Martano",
    longitude: 18.3012099,
    latitude: 40.20383567,
  },
  {
    label: "Martignano",
    longitude: 18.25893498,
    latitude: 40.23747962,
  },
  {
    label: "Matino",
    longitude: 18.13410681,
    latitude: 40.0326378,
  },
  {
    label: "Melendugno",
    longitude: 18.33705321,
    latitude: 40.26684234,
  },
  {
    label: "Melissano",
    longitude: 18.12361956,
    latitude: 39.97354513,
  },
  {
    label: "Melpignano",
    longitude: 18.28944514,
    latitude: 40.15476064,
  },
  {
    label: "Miggiano",
    longitude: 18.30685161,
    latitude: 39.96506708,
  },
  {
    label: "Minervino di Lecce",
    longitude: 18.42237093,
    latitude: 40.09260096,
  },
  {
    label: "Monteroni di Lecce",
    longitude: 18.09755191,
    latitude: 40.32881508,
  },
  {
    label: "Montesano Salentino",
    longitude: 18.32185742,
    latitude: 39.97592261,
  },
  {
    label: "Morciano di Leuca",
    longitude: 18.31139501,
    latitude: 39.84696956,
  },
  {
    label: "Muro Leccese",
    longitude: 18.33934823,
    latitude: 40.09998966,
  },
  {
    label: "Nardò",
    longitude: 18.0311969,
    latitude: 40.17594334,
  },
  {
    label: "Neviano",
    longitude: 18.1190494,
    latitude: 40.10542544,
  },
  {
    label: "Nociglia",
    longitude: 18.33033533,
    latitude: 40.03625463,
  },
  {
    label: "Novoli",
    longitude: 18.04877654,
    latitude: 40.37587469,
  },
  {
    label: "Ortelle",
    longitude: 18.39302399,
    latitude: 40.03423711,
  },
  {
    label: "Otranto",
    longitude: 18.49113602,
    latitude: 40.14635263,
  },
  {
    label: "Palmariggi",
    longitude: 18.38130645,
    latitude: 40.13162763,
  },
  {
    label: "Parabita",
    longitude: 18.12660455,
    latitude: 40.04540729,
  },
  {
    label: "Patù",
    longitude: 18.33873558,
    latitude: 39.83964829,
  },
  {
    label: "Poggiardo",
    longitude: 18.37686486,
    latitude: 40.05343131,
  },
  {
    label: "Presicce",
    longitude: 18.26346868,
    latitude: 39.90097666,
  },
  {
    label: "Racale",
    longitude: 18.09060083,
    latitude: 39.95745733,
  },
  {
    label: "Ruffano",
    longitude: 18.24679773,
    latitude: 39.98356548,
  },
  {
    label: "Salice Salentino",
    longitude: 17.96517392,
    latitude: 40.3848274,
  },
  {
    label: "Salve",
    longitude: 18.29407773,
    latitude: 39.86183396,
  },
  {
    label: "Sanarica",
    longitude: 18.34937653,
    latitude: 40.08763417,
  },
  {
    label: "San Cesario di Lecce",
    longitude: 18.16030678,
    latitude: 40.30186738,
  },
  {
    label: "San Donato di Lecce",
    longitude: 18.18352346,
    latitude: 40.26813039,
  },
  {
    label: "Sannicola",
    longitude: 18.06406498,
    latitude: 40.0938655,
  },
  {
    label: "San Pietro in Lama",
    longitude: 18.13186913,
    latitude: 40.30916402,
  },
  {
    label: "Santa Cesarea Terme",
    longitude: 18.46336483,
    latitude: 40.03785658,
  },
  {
    label: "Scorrano",
    longitude: 18.29836405,
    latitude: 40.09007771,
  },
  {
    label: "Seclì",
    longitude: 18.1049571,
    latitude: 40.13156773,
  },
  {
    label: "Sogliano Cavour",
    longitude: 18.19941365,
    latitude: 40.1462344,
  },
  {
    label: "Soleto",
    longitude: 18.20719445,
    latitude: 40.18758093,
  },
  {
    label: "Specchia",
    longitude: 18.2960837,
    latitude: 39.9388402,
  },
  {
    label: "Spongano",
    longitude: 18.36378986,
    latitude: 40.01313351,
  },
  {
    label: "Squinzano",
    longitude: 18.04581742,
    latitude: 40.43444312,
  },
  {
    label: "Sternatia",
    longitude: 18.22384546,
    latitude: 40.22165452,
  },
  {
    label: "Supersano",
    longitude: 18.2389304,
    latitude: 40.02005803,
  },
  {
    label: "Surano",
    longitude: 18.34162096,
    latitude: 40.02834008,
  },
  {
    label: "Surbo",
    longitude: 18.13158796,
    latitude: 40.3975535,
  },
  {
    label: "Taurisano",
    longitude: 18.21437844,
    latitude: 39.95561521,
  },
  {
    label: "Taviano",
    longitude: 18.08849335,
    latitude: 39.98298824,
  },
  {
    label: "Tiggiano",
    longitude: 18.36723225,
    latitude: 39.90279044,
  },
  {
    label: "Trepuzzi",
    longitude: 18.07369438,
    latitude: 40.40515874,
  },
  {
    label: "Tricase",
    longitude: 18.35076185,
    latitude: 39.93495217,
  },
  {
    label: "Tuglie",
    longitude: 18.10298903,
    latitude: 40.0701834,
  },
  {
    label: "Ugento",
    longitude: 18.16167767,
    latitude: 39.92856835,
  },
  {
    label: "Uggiano la Chiesa",
    longitude: 18.45398921,
    latitude: 40.09952043,
  },
  {
    label: "Veglie",
    longitude: 17.9657407,
    latitude: 40.3350564,
  },
  {
    label: "Vernole",
    longitude: 18.29874011,
    latitude: 40.28846006,
  },
  {
    label: "Zollino",
    longitude: 18.25138495,
    latitude: 40.20522776,
  },
  {
    label: "San Cassiano",
    longitude: 18.32997694,
    latitude: 40.05392496,
  },
  {
    label: "Castro",
    longitude: 18.42476926,
    latitude: 40.00789743,
  },
  {
    label: "Porto Cesareo",
    longitude: 17.89965588,
    latitude: 40.26034869,
  },
  {
    label: "Abriola",
    longitude: 15.81220103,
    latitude: 40.50793246,
  },
  {
    label: "Acerenza",
    longitude: 15.94167632,
    latitude: 40.79553337,
  },
  {
    label: "Albano di Lucania",
    longitude: 16.03615712,
    latitude: 40.58299757,
  },
  {
    label: "Anzi",
    longitude: 15.92392583,
    latitude: 40.51449458,
  },
  {
    label: "Armento",
    longitude: 16.06647994,
    latitude: 40.30519121,
  },
  {
    label: "Atella",
    longitude: 15.65274277,
    latitude: 40.87742205,
  },
  {
    label: "Avigliano",
    longitude: 15.71780199,
    latitude: 40.72977773,
  },
  {
    label: "Balvano",
    longitude: 15.5120599,
    latitude: 40.65124604,
  },
  {
    label: "Banzi",
    longitude: 16.01328897,
    latitude: 40.86033582,
  },
  {
    label: "Baragiano",
    longitude: 15.59420625,
    latitude: 40.67956537,
  },
  {
    label: "Barile",
    longitude: 15.67273006,
    latitude: 40.94608063,
  },
  {
    label: "Bella",
    longitude: 15.53778824,
    latitude: 40.75859333,
  },
  {
    label: "Brienza",
    longitude: 15.62812791,
    latitude: 40.47737411,
  },
  {
    label: "Brindisi Montagna",
    longitude: 15.94006746,
    latitude: 40.60989564,
  },
  {
    label: "Calvello",
    longitude: 15.84722246,
    latitude: 40.47524794,
  },
  {
    label: "Calvera",
    longitude: 16.14361898,
    latitude: 40.14870645,
  },
  {
    label: "Campomaggiore",
    longitude: 16.07368168,
    latitude: 40.56711111,
  },
  {
    label: "Cancellara",
    longitude: 15.92512422,
    latitude: 40.73079144,
  },
  {
    label: "Carbone",
    longitude: 16.08959862,
    latitude: 40.14162812,
  },
  {
    label: "San Paolo Albanese",
    longitude: 16.33497822,
    latitude: 40.03567219,
  },
  {
    label: "Castelgrande",
    longitude: 15.43306356,
    latitude: 40.78522391,
  },
  {
    label: "Castelluccio Inferiore",
    longitude: 15.97289786,
    latitude: 40.00510219,
  },
  {
    label: "Castelluccio Superiore",
    longitude: 15.97672071,
    latitude: 40.00960137,
  },
  {
    label: "Castelmezzano",
    longitude: 16.04813505,
    latitude: 40.53066884,
  },
  {
    label: "Castelsaraceno",
    longitude: 15.99271067,
    latitude: 40.16502524,
  },
  {
    label: "Castronuovo di Sant'Andrea",
    longitude: 16.18608885,
    latitude: 40.18853667,
  },
  {
    label: "Cersosimo",
    longitude: 16.34928247,
    latitude: 40.04571251,
  },
  {
    label: "Chiaromonte",
    longitude: 16.21499371,
    latitude: 40.12432913,
  },
  {
    label: "Corleto Perticara",
    longitude: 16.04249691,
    latitude: 40.38381925,
  },
  {
    label: "Episcopia",
    longitude: 16.09814314,
    latitude: 40.07445859,
  },
  {
    label: "Fardella",
    longitude: 16.16670786,
    latitude: 40.11410687,
  },
  {
    label: "Filiano",
    longitude: 15.70685304,
    latitude: 40.80923118,
  },
  {
    label: "Forenza",
    longitude: 15.85713912,
    latitude: 40.85937373,
  },
  {
    label: "Francavilla in Sinni",
    longitude: 16.20324151,
    latitude: 40.08167877,
  },
  {
    label: "Gallicchio",
    longitude: 16.1385519,
    latitude: 40.2889207,
  },
  {
    label: "Genzano di Lucania",
    longitude: 16.03153721,
    latitude: 40.85035736,
  },
  {
    label: "Grumento Nova",
    longitude: 15.88805745,
    latitude: 40.28241322,
  },
  {
    label: "Guardia Perticara",
    longitude: 16.09803315,
    latitude: 40.36357858,
  },
  {
    label: "Lagonegro",
    longitude: 15.76315029,
    latitude: 40.12254692,
  },
  {
    label: "latituderonico",
    longitude: 16.00906565,
    latitude: 40.08856189,
  },
  {
    label: "Laurenzana",
    longitude: 15.97216645,
    latitude: 40.45946607,
  },
  {
    label: "Lauria",
    longitude: 15.83691374,
    latitude: 40.04728639,
  },
  {
    label: "Lavello",
    longitude: 15.80105357,
    latitude: 41.04995683,
  },
  {
    label: "Maratea",
    longitude: 15.72462363,
    latitude: 39.99577652,
  },
  {
    label: "Marsico Nuovo",
    longitude: 15.73422735,
    latitude: 40.41920065,
  },
  {
    label: "Marsicovetere",
    longitude: 15.82637958,
    latitude: 40.37461443,
  },
  {
    label: "Maschito",
    longitude: 15.83142626,
    latitude: 40.90673081,
  },
  {
    label: "Melfi",
    longitude: 15.65291477,
    latitude: 40.99443652,
  },
  {
    label: "Missanello",
    longitude: 16.16521106,
    latitude: 40.28219989,
  },
  {
    label: "Moliterno",
    longitude: 15.86875843,
    latitude: 40.24206817,
  },
  {
    label: "Montemilone",
    longitude: 15.96716118,
    latitude: 41.02707434,
  },
  {
    label: "Montemurro",
    longitude: 15.99390939,
    latitude: 40.29920411,
  },
  {
    label: "Muro Lucano",
    longitude: 15.48370392,
    latitude: 40.75282099,
  },
  {
    label: "Nemoli",
    longitude: 15.79926643,
    latitude: 40.06594249,
  },
  {
    label: "Noepoli",
    longitude: 16.32807311,
    latitude: 40.08716905,
  },
  {
    label: "Oppido Lucano",
    longitude: 15.99007,
    latitude: 40.76600381,
  },
  {
    label: "Palazzo San Gervasio",
    longitude: 15.98079291,
    latitude: 40.93060791,
  },
  {
    label: "Pescopagano",
    longitude: 15.39691847,
    latitude: 40.83588628,
  },
  {
    label: "Picerno",
    longitude: 15.63601218,
    latitude: 40.63709859,
  },
  {
    label: "Pietragalla",
    longitude: 15.88239065,
    latitude: 40.74810952,
  },
  {
    label: "Pietrapertosa",
    longitude: 16.06348863,
    latitude: 40.51565096,
  },
  {
    label: "Pignola",
    longitude: 15.78495758,
    latitude: 40.57402537,
  },
  {
    label: "Potenza",
    longitude: 15.8051495,
    latitude: 40.63947047,
  },
  {
    label: "Rapolla",
    longitude: 15.67282295,
    latitude: 40.9771796,
  },
  {
    label: "Rapone",
    longitude: 15.49812111,
    latitude: 40.84646843,
  },
  {
    label: "Rionero in Vulture",
    longitude: 15.67379736,
    latitude: 40.92699724,
  },
  {
    label: "Ripacandida",
    longitude: 15.72739325,
    latitude: 40.91285178,
  },
  {
    label: "Rivello",
    longitude: 15.75776262,
    latitude: 40.07722285,
  },
  {
    label: "Roccanova",
    longitude: 16.20509967,
    latitude: 40.21423492,
  },
  {
    label: "Rotonda",
    longitude: 16.03619099,
    latitude: 39.95197376,
  },
  {
    label: "Ruoti",
    longitude: 15.67621806,
    latitude: 40.71745491,
  },
  {
    label: "Ruvo del Monte",
    longitude: 15.54130242,
    latitude: 40.84734475,
  },
  {
    label: "San Chirico Nuovo",
    longitude: 16.07946219,
    latitude: 40.67691792,
  },
  {
    label: "San Chirico Raparo",
    longitude: 16.0764212,
    latitude: 40.19058465,
  },
  {
    label: "San Costantino Albanese",
    longitude: 16.30450619,
    latitude: 40.03530035,
  },
  {
    label: "San Fele",
    longitude: 15.53923256,
    latitude: 40.8192069,
  },
  {
    label: "San Martino d'Agri",
    longitude: 16.0506595,
    latitude: 40.23694838,
  },
  {
    label: "San Severino Lucano",
    longitude: 16.14190034,
    latitude: 40.01829313,
  },
  {
    label: "Sant'Angelo Le Fratte",
    longitude: 15.55913692,
    latitude: 40.546251,
  },
  {
    label: "Sant'Arcangelo",
    longitude: 16.27604824,
    latitude: 40.24728624,
  },
  {
    label: "Sarconi",
    longitude: 15.8882346,
    latitude: 40.24783612,
  },
  {
    label: "Sasso di Castalda",
    longitude: 15.67790803,
    latitude: 40.48649161,
  },
  {
    label: "Satriano di Lucania",
    longitude: 15.63631022,
    latitude: 40.54506103,
  },
  {
    label: "Savoia di Lucania",
    longitude: 15.5522549,
    latitude: 40.56996614,
  },
  {
    label: "Senise",
    longitude: 16.28972558,
    latitude: 40.14353619,
  },
  {
    label: "Spinoso",
    longitude: 15.9661533,
    latitude: 40.26538033,
  },
  {
    label: "Teana",
    longitude: 16.1501737,
    latitude: 40.12493251,
  },
  {
    label: "Terranova di Pollino",
    longitude: 16.298274,
    latitude: 39.97681195,
  },
  {
    label: "Tito",
    longitude: 15.67775851,
    latitude: 40.5828868,
  },
  {
    label: "Tolve",
    longitude: 16.01172916,
    latitude: 40.69092102,
  },
  {
    label: "Tramutola",
    longitude: 15.78758509,
    latitude: 40.32123382,
  },
  {
    label: "Trecchina",
    longitude: 15.77972341,
    latitude: 40.02660321,
  },
  {
    label: "Trivigno",
    longitude: 15.98928892,
    latitude: 40.58094351,
  },
  {
    label: "Vaglio Basilicata",
    longitude: 15.91999931,
    latitude: 40.66580154,
  },
  {
    label: "Venosa",
    longitude: 15.82328489,
    latitude: 40.96454919,
  },
  {
    label: "Vietri di Potenza",
    longitude: 15.50701739,
    latitude: 40.59962203,
  },
  {
    label: "Viggianello",
    longitude: 16.08803972,
    latitude: 39.97298788,
  },
  {
    label: "Viggiano",
    longitude: 15.90337366,
    latitude: 40.34025865,
  },
  {
    label: "Ginestra",
    longitude: 15.73408091,
    latitude: 40.93048625,
  },
  {
    label: "Paterno",
    longitude: 15.7321489,
    latitude: 40.37741426,
  },
  {
    label: "Accettura",
    longitude: 16.15735845,
    latitude: 40.49150467,
  },
  {
    label: "Aliano",
    longitude: 16.23105383,
    latitude: 40.31395877,
  },
  {
    label: "Bernalda",
    longitude: 16.68637158,
    latitude: 40.40817994,
  },
  {
    label: "Calciano",
    longitude: 16.19011051,
    latitude: 40.58737349,
  },
  {
    label: "Cirigliano",
    longitude: 16.17121386,
    latitude: 40.39302202,
  },
  {
    label: "Colobraro",
    longitude: 16.42339518,
    latitude: 40.18797719,
  },
  {
    label: "Craco",
    longitude: 16.49379247,
    latitude: 40.35861858,
  },
  {
    label: "Ferrandina",
    longitude: 16.45683053,
    latitude: 40.49290877,
  },
  {
    label: "Garaguso",
    longitude: 16.22716804,
    latitude: 40.54882367,
  },
  {
    label: "Gorgoglione",
    longitude: 16.1431053,
    latitude: 40.39461473,
  },
  {
    label: "Grassano",
    longitude: 16.2842797,
    latitude: 40.63354407,
  },
  {
    label: "Grottole",
    longitude: 16.37666373,
    latitude: 40.6020065,
  },
  {
    label: "Irsina",
    longitude: 16.24315213,
    latitude: 40.74568908,
  },
  {
    label: "Matera",
    longitude: 16.59792694,
    latitude: 40.66751166,
  },
  {
    label: "Miglionico",
    longitude: 16.50157848,
    latitude: 40.56577013,
  },
  {
    label: "Montalbano Jonico",
    longitude: 16.56883554,
    latitude: 40.28804803,
  },
  {
    label: "Montescaglioso",
    longitude: 16.66391314,
    latitude: 40.55204436,
  },
  {
    label: "Nova Siri",
    longitude: 16.53877288,
    latitude: 40.1471329,
  },
  {
    label: "Oliveto Lucano",
    longitude: 16.18509551,
    latitude: 40.53625912,
  },
  {
    label: "Pisticci",
    longitude: 16.55864113,
    latitude: 40.39072951,
  },
  {
    label: "Policoro",
    longitude: 16.66968702,
    latitude: 40.20871929,
  },
  {
    label: "Pomarico",
    longitude: 16.54624726,
    latitude: 40.5178246,
  },
  {
    label: "Rotondella",
    longitude: 16.52561695,
    latitude: 40.17256273,
  },
  {
    label: "Salandra",
    longitude: 16.31965068,
    latitude: 40.52555001,
  },
  {
    label: "San Giorgio Lucano",
    longitude: 16.38951662,
    latitude: 40.11417325,
  },
  {
    label: "San Mauro Forte",
    longitude: 16.25233844,
    latitude: 40.48722182,
  },
  {
    label: "Stigliano",
    longitude: 16.22805419,
    latitude: 40.40250081,
  },
  {
    label: "Tricarico",
    longitude: 16.14057812,
    latitude: 40.61895517,
  },
  {
    label: "Tursi",
    longitude: 16.47720972,
    latitude: 40.24649534,
  },
  {
    label: "Valsinni",
    longitude: 16.44198726,
    latitude: 40.1706747,
  },
  {
    label: "Scanzano Jonico",
    longitude: 16.69091204,
    latitude: 40.2503214,
  },
  {
    label: "Acquaformosa",
    longitude: 16.09007863,
    latitude: 39.72177269,
  },
  {
    label: "Acquappesa",
    longitude: 15.95403649,
    latitude: 39.49462453,
  },
  {
    label: "Acri",
    longitude: 16.38155051,
    latitude: 39.48835683,
  },
  {
    label: "Aiello Calabro",
    longitude: 16.16704573,
    latitude: 39.11696903,
  },
  {
    label: "Aieta",
    longitude: 15.82365257,
    latitude: 39.92835498,
  },
  {
    label: "Albidona",
    longitude: 16.47454413,
    latitude: 39.92268533,
  },
  {
    label: "Alessandria del Carretto",
    longitude: 16.37827535,
    latitude: 39.95853578,
  },
  {
    label: "Altilia",
    longitude: 16.25269561,
    latitude: 39.13010851,
  },
  {
    label: "Altomonte",
    longitude: 16.12925055,
    latitude: 39.69820643,
  },
  {
    label: "Amantea",
    longitude: 16.08001218,
    latitude: 39.13287384,
  },
  {
    label: "Amendolara",
    longitude: 16.58410071,
    latitude: 39.95101261,
  },
  {
    label: "Aprigliano",
    longitude: 16.34080544,
    latitude: 39.24053515,
  },
  {
    label: "Belmonte Calabro",
    longitude: 16.07789547,
    latitude: 39.16056055,
  },
  {
    label: "Belsito",
    longitude: 16.28794816,
    latitude: 39.17401078,
  },
  {
    label: "Belvedere Marittimo",
    longitude: 15.8613154,
    latitude: 39.61804375,
  },
  {
    label: "Bianchi",
    longitude: 16.41066245,
    latitude: 39.10080642,
  },
  {
    label: "Bisignano",
    longitude: 16.28652064,
    latitude: 39.5126271,
  },
  {
    label: "Bocchigliero",
    longitude: 16.7562776,
    latitude: 39.41775539,
  },
  {
    label: "Bonifati",
    longitude: 15.90507114,
    latitude: 39.58691422,
  },
  {
    label: "Buonvicino",
    longitude: 15.88391307,
    latitude: 39.68892272,
  },
  {
    label: "Calopezzati",
    longitude: 16.80171204,
    latitude: 39.55889224,
  },
  {
    label: "Caloveto",
    longitude: 16.75764264,
    latitude: 39.50754475,
  },
  {
    label: "Campana",
    longitude: 16.82601626,
    latitude: 39.40982925,
  },
  {
    label: "Canna",
    longitude: 16.50524217,
    latitude: 40.09477355,
  },
  {
    label: "Cariati",
    longitude: 16.94708938,
    latitude: 39.49578816,
  },
  {
    label: "Carolei",
    longitude: 16.21860838,
    latitude: 39.2530973,
  },
  {
    label: "Carpanzano",
    longitude: 16.30357187,
    latitude: 39.14766081,
  },
  {
    label: "Cassano all'Ionio",
    longitude: 16.31920823,
    latitude: 39.78187129,
  },
  {
    label: "Castiglione Cosentino",
    longitude: 16.2882154,
    latitude: 39.35271086,
  },
  {
    label: "Castrolibero",
    longitude: 16.19453661,
    latitude: 39.3100225,
  },
  {
    label: "Castroregio",
    longitude: 16.47831522,
    latitude: 39.99257445,
  },
  {
    label: "Castrovillari",
    longitude: 16.20229605,
    latitude: 39.81097584,
  },
  {
    label: "Celico",
    longitude: 16.34028978,
    latitude: 39.31066737,
  },
  {
    label: "Cellara",
    longitude: 16.33276902,
    latitude: 39.21796333,
  },
  {
    label: "Cerchiara di Calabria",
    longitude: 16.38164245,
    latitude: 39.85915335,
  },
  {
    label: "Cerisano",
    longitude: 16.17836482,
    latitude: 39.27454637,
  },
  {
    label: "Cervicati",
    longitude: 16.12681954,
    latitude: 39.54236798,
  },
  {
    label: "Cerzeto",
    longitude: 16.11397992,
    latitude: 39.50771022,
  },
  {
    label: "Cetraro",
    longitude: 15.94302226,
    latitude: 39.51621474,
  },
  {
    label: "Civita",
    longitude: 16.31318974,
    latitude: 39.82770121,
  },
  {
    label: "Cleto",
    longitude: 16.16592667,
    latitude: 39.1165804,
  },
  {
    label: "Colosimi",
    longitude: 16.39984966,
    latitude: 39.11886134,
  },
  {
    label: "Corigliano Calabro",
    longitude: 16.52186588,
    latitude: 39.59484252,
  },
  {
    label: "Cosenza",
    longitude: 16.25609864,
    latitude: 39.29308673,
  },
  {
    label: "Cropalatitudei",
    longitude: 16.72606403,
    latitude: 39.51563374,
  },
  {
    label: "Crosia",
    longitude: 16.76987711,
    latitude: 39.5701096,
  },
  {
    label: "Diamante",
    longitude: 15.82176185,
    latitude: 39.67632526,
  },
  {
    label: "Dipignano",
    longitude: 16.25224534,
    latitude: 39.23767313,
  },
  {
    label: "Domanico",
    longitude: 16.20734893,
    latitude: 39.21523217,
  },
  {
    label: "Fagnano Castello",
    longitude: 16.05445487,
    latitude: 39.56115859,
  },
  {
    label: "Falconara Albanese",
    longitude: 16.08827857,
    latitude: 39.27437583,
  },
  {
    label: "Figline Vegliaturo",
    longitude: 16.32940444,
    latitude: 39.22605287,
  },
  {
    label: "Firmo",
    longitude: 16.17833922,
    latitude: 39.72207745,
  },
  {
    label: "Fiumefreddo Bruzio",
    longitude: 16.06634886,
    latitude: 39.23589126,
  },
  {
    label: "Francavilla Marittima",
    longitude: 16.38751107,
    latitude: 39.81978031,
  },
  {
    label: "Frascineto",
    longitude: 16.26281179,
    latitude: 39.83697242,
  },
  {
    label: "Fuscaldo",
    longitude: 16.03134735,
    latitude: 39.41626329,
  },
  {
    label: "Grimaldi",
    longitude: 16.23468119,
    latitude: 39.13972195,
  },
  {
    label: "Grisolia",
    longitude: 15.85375821,
    latitude: 39.72645185,
  },
  {
    label: "Guardia Piemontese",
    longitude: 15.99968553,
    latitude: 39.46520252,
  },
  {
    label: "Lago",
    longitude: 16.1491028,
    latitude: 39.16655127,
  },
  {
    label: "Laino Borgo",
    longitude: 15.97230029,
    latitude: 39.95478426,
  },
  {
    label: "Laino Castello",
    longitude: 15.97444333,
    latitude: 39.9344474,
  },
  {
    label: "Lappano",
    longitude: 16.31382807,
    latitude: 39.31926681,
  },
  {
    label: "latitudetarico",
    longitude: 16.13660369,
    latitude: 39.46209103,
  },
  {
    label: "Longobardi",
    longitude: 16.07681773,
    latitude: 39.20742335,
  },
  {
    label: "Longobucco",
    longitude: 16.60949425,
    latitude: 39.44871877,
  },
  {
    label: "Lungro",
    longitude: 16.12174742,
    latitude: 39.74407353,
  },
  {
    label: "Luzzi",
    longitude: 16.28817661,
    latitude: 39.44634155,
  },
  {
    label: "Maierà",
    longitude: 15.85041145,
    latitude: 39.71612827,
  },
  {
    label: "Malito",
    longitude: 16.24646658,
    latitude: 39.15887133,
  },
  {
    label: "Malvito",
    longitude: 16.05340915,
    latitude: 39.60105409,
  },
  {
    label: "Mandatoriccio",
    longitude: 16.83669521,
    latitude: 39.4702289,
  },
  {
    label: "Mangone",
    longitude: 16.33262497,
    latitude: 39.20437182,
  },
  {
    label: "Marano Marchesato",
    longitude: 16.1736201,
    latitude: 39.31506293,
  },
  {
    label: "Marano Principato",
    longitude: 16.17424589,
    latitude: 39.30129249,
  },
  {
    label: "Marzi",
    longitude: 16.30704697,
    latitude: 39.17062752,
  },
  {
    label: "Mendicino",
    longitude: 16.19288767,
    latitude: 39.26211657,
  },
  {
    label: "Mongrassano",
    longitude: 16.11144528,
    latitude: 39.52715037,
  },
  {
    label: "Montalto Uffugo",
    longitude: 16.15846935,
    latitude: 39.40552343,
  },
  {
    label: "Montegiordano",
    longitude: 16.53244946,
    latitude: 40.04407506,
  },
  {
    label: "Morano Calabro",
    longitude: 16.13534776,
    latitude: 39.84180415,
  },
  {
    label: "Mormanno",
    longitude: 15.98760211,
    latitude: 39.88973067,
  },
  {
    label: "Mottafollone",
    longitude: 16.06664094,
    latitude: 39.64705834,
  },
  {
    label: "Nocara",
    longitude: 16.48353728,
    latitude: 40.09894271,
  },
  {
    label: "Oriolo",
    longitude: 16.44448908,
    latitude: 40.05293541,
  },
  {
    label: "Orsomarso",
    longitude: 15.90784885,
    latitude: 39.79908366,
  },
  {
    label: "Paludi",
    longitude: 16.67857124,
    latitude: 39.53101927,
  },
  {
    label: "Panettieri",
    longitude: 16.45375019,
    latitude: 39.06007069,
  },
  {
    label: "Paola",
    longitude: 16.04152008,
    latitude: 39.35993586,
  },
  {
    label: "Papasidero",
    longitude: 15.90580955,
    latitude: 39.87158979,
  },
  {
    label: "Parenti",
    longitude: 16.41276477,
    latitude: 39.16280163,
  },
  {
    label: "Paterno Calabro",
    longitude: 16.26310291,
    latitude: 39.22515259,
  },
  {
    label: "Pedivigliano",
    longitude: 16.30486327,
    latitude: 39.1103358,
  },
  {
    label: "Piane Crati",
    longitude: 16.32298308,
    latitude: 39.23479514,
  },
  {
    label: "Pietrafitta",
    longitude: 16.33876248,
    latitude: 39.26192011,
  },
  {
    label: "Pietrapaola",
    longitude: 16.81598135,
    latitude: 39.48683052,
  },
  {
    label: "Platitudeaci",
    longitude: 16.43147687,
    latitude: 39.90070112,
  },
  {
    label: "Praia a Mare",
    longitude: 15.77978015,
    latitude: 39.89531631,
  },
  {
    label: "Rende",
    longitude: 16.18041387,
    latitude: 39.3315362,
  },
  {
    label: "Rocca Imperiale",
    longitude: 16.57535113,
    latitude: 40.11056597,
  },
  {
    label: "Roggiano Gravina",
    longitude: 16.15813054,
    latitude: 39.6164758,
  },
  {
    label: "Rogliano",
    longitude: 16.32531753,
    latitude: 39.18006073,
  },
  {
    label: "Rose",
    longitude: 16.29788201,
    latitude: 39.39925354,
  },
  {
    label: "Roseto Capo Spulico",
    longitude: 16.60126564,
    latitude: 39.98665166,
  },
  {
    label: "Rossano",
    longitude: 16.63286791,
    latitude: 39.57523072,
  },
  {
    label: "Rota Greca",
    longitude: 16.11350876,
    latitude: 39.46700606,
  },
  {
    label: "Rovito",
    longitude: 16.32271924,
    latitude: 39.30990247,
  },
  {
    label: "San Basile",
    longitude: 16.16360832,
    latitude: 39.81096645,
  },
  {
    label: "San Benedetto Ullano",
    longitude: 16.12332625,
    latitude: 39.42628928,
  },
  {
    label: "San Cosmo Albanese",
    longitude: 16.42056575,
    latitude: 39.58234229,
  },
  {
    label: "San Demetrio Corone",
    longitude: 16.36319433,
    latitude: 39.5676736,
  },
  {
    label: "San Donato di Ninea",
    longitude: 16.04820188,
    latitude: 39.71189174,
  },
  {
    label: "San Fili",
    longitude: 16.14233499,
    latitude: 39.33762813,
  },
  {
    label: "Sangineto",
    longitude: 15.91251148,
    latitude: 39.60366647,
  },
  {
    label: "San Giorgio Albanese",
    longitude: 16.45453461,
    latitude: 39.58075449,
  },
  {
    label: "San Giovanni in Fiore",
    longitude: 16.69731802,
    latitude: 39.25365492,
  },
  {
    label: "San Lorenzo Bellizzi",
    longitude: 16.32822504,
    latitude: 39.88922996,
  },
  {
    label: "San Lorenzo del Vallo",
    longitude: 16.29472655,
    latitude: 39.66685921,
  },
  {
    label: "San Lucido",
    longitude: 16.05094144,
    latitude: 39.30820078,
  },
  {
    label: "San Marco Argentano",
    longitude: 16.11786811,
    latitude: 39.55681601,
  },
  {
    label: "San Martino di Finita",
    longitude: 16.10711702,
    latitude: 39.49068894,
  },
  {
    label: "San Nicola Arcella",
    longitude: 15.79364896,
    latitude: 39.8457919,
  },
  {
    label: "San Pietro in Amantea",
    longitude: 16.11243497,
    latitude: 39.13658207,
  },
  {
    label: "San Pietro in Guarano",
    longitude: 16.31210874,
    latitude: 39.34473972,
  },
  {
    label: "San Sosti",
    longitude: 16.02916167,
    latitude: 39.65815182,
  },
  {
    label: "Santa Caterina Albanese",
    longitude: 16.07067974,
    latitude: 39.58577673,
  },
  {
    label: "Santa Domenica Talao",
    longitude: 15.85542937,
    latitude: 39.81957215,
  },
  {
    label: "Sant'Agata di Esaro",
    longitude: 15.98238081,
    latitude: 39.62090696,
  },
  {
    label: "Santa Maria del Cedro",
    longitude: 15.83623515,
    latitude: 39.74889043,
  },
  {
    label: "Santa Sofia d'Epiro",
    longitude: 16.32883248,
    latitude: 39.54597475,
  },
  {
    label: "Santo Stefano di Rogliano",
    longitude: 16.3207926,
    latitude: 39.19223486,
  },
  {
    label: "San Vincenzo La Costa",
    longitude: 16.1508893,
    latitude: 39.36475905,
  },
  {
    label: "Saracena",
    longitude: 16.15618881,
    latitude: 39.77527455,
  },
  {
    label: "Scala Coeli",
    longitude: 16.88589056,
    latitude: 39.4488244,
  },
  {
    label: "Scalea",
    longitude: 15.79100532,
    latitude: 39.80643078,
  },
  {
    label: "Scigliano",
    longitude: 16.30670827,
    latitude: 39.12822708,
  },
  {
    label: "Serra d'Aiello",
    longitude: 16.12612198,
    latitude: 39.09003923,
  },
  {
    label: "Spezzano Albanese",
    longitude: 16.30856798,
    latitude: 39.66184414,
  },
  {
    label: "Spezzano della Sila",
    longitude: 16.33736492,
    latitude: 39.29780432,
  },
  {
    label: "Tarsia",
    longitude: 16.27301582,
    latitude: 39.61728492,
  },
  {
    label: "Terranova da Sibari",
    longitude: 16.34380151,
    latitude: 39.65582009,
  },
  {
    label: "Terravecchia",
    longitude: 16.94752399,
    latitude: 39.46366527,
  },
  {
    label: "Torano Castello",
    longitude: 16.14471775,
    latitude: 39.50489706,
  },
  {
    label: "Tortora",
    longitude: 15.76829121,
    latitude: 39.92210156,
  },
  {
    label: "Trebisacce",
    longitude: 16.53046229,
    latitude: 39.86679844,
  },
  {
    label: "Vaccarizzo Albanese",
    longitude: 16.43323022,
    latitude: 39.58707282,
  },
  {
    label: "Verbicaro",
    longitude: 15.91112224,
    latitude: 39.75488674,
  },
  {
    label: "Villapiana",
    longitude: 16.45512175,
    latitude: 39.84696351,
  },
  {
    label: "Zumpano",
    longitude: 16.29350577,
    latitude: 39.31063234,
  },
  {
    label: "Casali del Manco",
    longitude: 16.3373,
    latitude: 39.2854,
  },
  {
    label: "Albi",
    longitude: 16.59721201,
    latitude: 39.02455692,
  },
  {
    label: "Amaroni",
    longitude: 16.4483454,
    latitude: 38.79361598,
  },
  {
    label: "Amato",
    longitude: 16.46276195,
    latitude: 38.94139316,
  },
  {
    label: "Andali",
    longitude: 16.76704577,
    latitude: 39.01394149,
  },
  {
    label: "Argusto",
    longitude: 16.4358786,
    latitude: 38.68080662,
  },
  {
    label: "Badolatitudeo",
    longitude: 16.52589516,
    latitude: 38.56828186,
  },
  {
    label: "Belcastro",
    longitude: 16.78755057,
    latitude: 39.01740669,
  },
  {
    label: "Borgia",
    longitude: 16.51425094,
    latitude: 38.82732561,
  },
  {
    label: "Botricello",
    longitude: 16.86126924,
    latitude: 38.93733666,
  },
  {
    label: "Caraffa di Catanzaro",
    longitude: 16.48724946,
    latitude: 38.88035603,
  },
  {
    label: "Cardinale",
    longitude: 16.38613748,
    latitude: 38.64241295,
  },
  {
    label: "Carlopoli",
    longitude: 16.4548706,
    latitude: 39.05716335,
  },
  {
    label: "Catanzaro",
    longitude: 16.59440429,
    latitude: 38.90597588,
  },
  {
    label: "Cenadi",
    longitude: 16.41005658,
    latitude: 38.71724722,
  },
  {
    label: "Centrache",
    longitude: 16.43017684,
    latitude: 38.72952427,
  },
  {
    label: "Cerva",
    longitude: 16.74247366,
    latitude: 39.020321,
  },
  {
    label: "Chiaravalle Centrale",
    longitude: 16.41419571,
    latitude: 38.68407674,
  },
  {
    label: "Cicala",
    longitude: 16.48636411,
    latitude: 39.02308071,
  },
  {
    label: "Conflenti",
    longitude: 16.28432695,
    latitude: 39.07239526,
  },
  {
    label: "Cortale",
    longitude: 16.41124273,
    latitude: 38.84236651,
  },
  {
    label: "Cropani",
    longitude: 16.78283041,
    latitude: 38.96821334,
  },
  {
    label: "Curinga",
    longitude: 16.3187758,
    latitude: 38.82892067,
  },
  {
    label: "Davoli",
    longitude: 16.48682424,
    latitude: 38.64896491,
  },
  {
    label: "Decollatitudeura",
    longitude: 16.35690396,
    latitude: 39.0467195,
  },
  {
    label: "Falerna",
    longitude: 16.16835256,
    latitude: 39.00218588,
  },
  {
    label: "Feroleto Antico",
    longitude: 16.38742265,
    latitude: 38.96176671,
  },
  {
    label: "Fossato Serralta",
    longitude: 16.57983872,
    latitude: 38.99573276,
  },
  {
    label: "Gagliato",
    longitude: 16.46351216,
    latitude: 38.67682445,
  },
  {
    label: "Gasperina",
    longitude: 16.50959271,
    latitude: 38.73926423,
  },
  {
    label: "Gimigliano",
    longitude: 16.52911804,
    latitude: 38.97544283,
  },
  {
    label: "Girifalco",
    longitude: 16.42554823,
    latitude: 38.82241011,
  },
  {
    label: "Gizzeria",
    longitude: 16.2081232,
    latitude: 38.97907018,
  },
  {
    label: "Guardavalle",
    longitude: 16.50627486,
    latitude: 38.50590327,
  },
  {
    label: "Isca sullo Ionio",
    longitude: 16.51897903,
    latitude: 38.60055649,
  },
  {
    label: "Jacurso",
    longitude: 16.37858506,
    latitude: 38.84737159,
  },
  {
    label: "Magisano",
    longitude: 16.62866462,
    latitude: 39.01487563,
  },
  {
    label: "Maida",
    longitude: 16.3654825,
    latitude: 38.8582749,
  },
  {
    label: "Marcedusa",
    longitude: 16.83579737,
    latitude: 39.02703278,
  },
  {
    label: "Marcellinara",
    longitude: 16.49442654,
    latitude: 38.92926798,
  },
  {
    label: "Martirano",
    longitude: 16.24758566,
    latitude: 39.08303308,
  },
  {
    label: "Martirano Lombardo",
    longitude: 16.23105462,
    latitude: 39.07572552,
  },
  {
    label: "Miglierina",
    longitude: 16.47246304,
    latitude: 38.94761147,
  },
  {
    label: "Montauro",
    longitude: 16.5097935,
    latitude: 38.74977718,
  },
  {
    label: "Montepaone",
    longitude: 16.49864626,
    latitude: 38.7216876,
  },
  {
    label: "Motta Santa Lucia",
    longitude: 16.29286748,
    latitude: 39.09207639,
  },
  {
    label: "Nocera Terinese",
    longitude: 16.16400496,
    latitude: 39.03744782,
  },
  {
    label: "Olivadi",
    longitude: 16.42098923,
    latitude: 38.72581188,
  },
  {
    label: "Palermiti",
    longitude: 16.45199284,
    latitude: 38.74827454,
  },
  {
    label: "Pentone",
    longitude: 16.58213116,
    latitude: 38.9849907,
  },
  {
    label: "Petrizzi",
    longitude: 16.47400505,
    latitude: 38.70249016,
  },
  {
    label: "Petronà",
    longitude: 16.75617072,
    latitude: 39.03947894,
  },
  {
    label: "Pianopoli",
    longitude: 16.389177,
    latitude: 38.95436631,
  },
  {
    label: "Platitudeania",
    longitude: 16.31876806,
    latitude: 39.00442189,
  },
  {
    label: "San Floro",
    longitude: 16.51859679,
    latitude: 38.8377011,
  },
  {
    label: "San Mango d'Aquino",
    longitude: 16.18970726,
    latitude: 39.06008104,
  },
  {
    label: "San Pietro a Maida",
    longitude: 16.34770558,
    latitude: 38.84635655,
  },
  {
    label: "San Pietro Apostolo",
    longitude: 16.46900408,
    latitude: 39.00470624,
  },
  {
    label: "San Sostene",
    longitude: 16.48751549,
    latitude: 38.63732681,
  },
  {
    label: "Santa Caterina dello Ionio",
    longitude: 16.52101095,
    latitude: 38.53497539,
  },
  {
    label: "Sant'Andrea Apostolo dello Ionio",
    longitude: 16.52858453,
    latitude: 38.62325931,
  },
  {
    label: "San Vito sullo Ionio",
    longitude: 16.40711715,
    latitude: 38.71060653,
  },
  {
    label: "Satriano",
    longitude: 16.4829091,
    latitude: 38.66777759,
  },
  {
    label: "Sellia",
    longitude: 16.62821011,
    latitude: 38.98166655,
  },
  {
    label: "Sellia Marina",
    longitude: 16.74500566,
    latitude: 38.90365941,
  },
  {
    label: "Serrastretta",
    longitude: 16.41807486,
    latitude: 39.01258872,
  },
  {
    label: "Sersale",
    longitude: 16.72561316,
    latitude: 39.01215179,
  },
  {
    label: "Settingiano",
    longitude: 16.51330397,
    latitude: 38.9141382,
  },
  {
    label: "Simeri Crichi",
    longitude: 16.6461672,
    latitude: 38.95525846,
  },
  {
    label: "Sorbo San Basile",
    longitude: 16.56936887,
    latitude: 39.01940302,
  },
  {
    label: "Soverato",
    longitude: 16.54941142,
    latitude: 38.68848067,
  },
  {
    label: "Soveria Mannelli",
    longitude: 16.37342158,
    latitude: 39.08409185,
  },
  {
    label: "Soveria Simeri",
    longitude: 16.68043869,
    latitude: 38.94626392,
  },
  {
    label: "Squillace",
    longitude: 16.51924102,
    latitude: 38.78125492,
  },
  {
    label: "Stalettì",
    longitude: 16.54099411,
    latitude: 38.76617764,
  },
  {
    label: "Taverna",
    longitude: 16.5824687,
    latitude: 39.01999644,
  },
  {
    label: "Tiriolo",
    longitude: 16.50752021,
    latitude: 38.94621058,
  },
  {
    label: "Torre di Ruggiero",
    longitude: 16.37279955,
    latitude: 38.65307662,
  },
  {
    label: "Vallefiorita",
    longitude: 16.46438351,
    latitude: 38.77635451,
  },
  {
    label: "Zagarise",
    longitude: 16.66059993,
    latitude: 38.99974523,
  },
  {
    label: "Lamezia Terme",
    longitude: 16.31939038,
    latitude: 38.97522103,
  },
  {
    label: "Africo",
    longitude: 16.130377,
    latitude: 38.05016319,
  },
  {
    label: "Agnana Calabra",
    longitude: 16.22514898,
    latitude: 38.30235892,
  },
  {
    label: "Anoia",
    longitude: 16.08372087,
    latitude: 38.43579838,
  },
  {
    label: "Antonimina",
    longitude: 16.14824322,
    latitude: 38.27297826,
  },
  {
    label: "Ardore",
    longitude: 16.16839948,
    latitude: 38.19172299,
  },
  {
    label: "Bagaladi",
    longitude: 15.82056507,
    latitude: 38.02537012,
  },
  {
    label: "Bagnara Calabra",
    longitude: 15.80043949,
    latitude: 38.28421816,
  },
  {
    label: "Benestare",
    longitude: 16.1393209,
    latitude: 38.18655556,
  },
  {
    label: "Bianco",
    longitude: 16.14854306,
    latitude: 38.08886468,
  },
  {
    label: "Bivongi",
    longitude: 16.4533908,
    latitude: 38.48167294,
  },
  {
    label: "Bova",
    longitude: 15.93180202,
    latitude: 37.99457061,
  },
  {
    label: "Bovalino",
    longitude: 16.17412033,
    latitude: 38.14668654,
  },
  {
    label: "Bova Marina",
    longitude: 15.92080751,
    latitude: 37.93239132,
  },
  {
    label: "Brancaleone",
    longitude: 16.10305034,
    latitude: 37.96702175,
  },
  {
    label: "Bruzzano Zeffirio",
    longitude: 16.08133547,
    latitude: 38.01262452,
  },
  {
    label: "Calanna",
    longitude: 15.72262678,
    latitude: 38.1825707,
  },
  {
    label: "Camini",
    longitude: 16.48172971,
    latitude: 38.4320328,
  },
  {
    label: "Campo Calabro",
    longitude: 15.65805338,
    latitude: 38.2147002,
  },
  {
    label: "Candidoni",
    longitude: 16.08572443,
    latitude: 38.50498542,
  },
  {
    label: "Canolo",
    longitude: 16.20036564,
    latitude: 38.31514174,
  },
  {
    label: "Caraffa del Bianco",
    longitude: 16.08542014,
    latitude: 38.09217034,
  },
  {
    label: "Cardeto",
    longitude: 15.76576886,
    latitude: 38.08440345,
  },
  {
    label: "Careri",
    longitude: 16.11621133,
    latitude: 38.1786256,
  },
  {
    label: "Casignana",
    longitude: 16.09009354,
    latitude: 38.09931577,
  },
  {
    label: "Caulonia",
    longitude: 16.41049336,
    latitude: 38.38139328,
  },
  {
    label: "Ciminà",
    longitude: 16.14154703,
    latitude: 38.24667515,
  },
  {
    label: "Cinquefrondi",
    longitude: 16.09663388,
    latitude: 38.41618816,
  },
  {
    label: "Cittanova",
    longitude: 16.08037793,
    latitude: 38.35538079,
  },
  {
    label: "Condofuri",
    longitude: 15.85901179,
    latitude: 38.00426486,
  },
  {
    label: "Cosoleto",
    longitude: 15.92922458,
    latitude: 38.27458562,
  },
  {
    label: "Delianuova",
    longitude: 15.9181632,
    latitude: 38.24038051,
  },
  {
    label: "Feroleto della Chiesa",
    longitude: 16.07012309,
    latitude: 38.46609357,
  },
  {
    label: "Ferruzzano",
    longitude: 16.08764893,
    latitude: 38.03933721,
  },
  {
    label: "Fiumara",
    longitude: 15.69216824,
    latitude: 38.2108742,
  },
  {
    label: "Galatitudero",
    longitude: 16.10849412,
    latitude: 38.46026898,
  },
  {
    label: "Gerace",
    longitude: 16.2181618,
    latitude: 38.27160155,
  },
  {
    label: "Giffone",
    longitude: 16.14999572,
    latitude: 38.43887002,
  },
  {
    label: "Gioia Tauro",
    longitude: 15.89729676,
    latitude: 38.42988045,
  },
  {
    label: "Gioiosa Ionica",
    longitude: 16.30252468,
    latitude: 38.33631823,
  },
  {
    label: "Grotteria",
    longitude: 16.26520385,
    latitude: 38.36845592,
  },
  {
    label: "Laganadi",
    longitude: 15.74195731,
    latitude: 38.174435,
  },
  {
    label: "Laureana di Borrello",
    longitude: 16.08187729,
    latitude: 38.48956467,
  },
  {
    label: "Locri",
    longitude: 16.26402974,
    latitude: 38.2367479,
  },
  {
    label: "Mammola",
    longitude: 16.24051878,
    latitude: 38.3620735,
  },
  {
    label: "Marina di Gioiosa Ionica",
    longitude: 16.32907275,
    latitude: 38.30332259,
  },
  {
    label: "Maropati",
    longitude: 16.09650582,
    latitude: 38.44266096,
  },
  {
    label: "Martone",
    longitude: 16.28731158,
    latitude: 38.35374942,
  },
  {
    label: "Melicuccà",
    longitude: 15.88191801,
    latitude: 38.30220447,
  },
  {
    label: "Melicucco",
    longitude: 16.05978024,
    latitude: 38.43395838,
  },
  {
    label: "Melito di Porto Salvo",
    longitude: 15.78509471,
    latitude: 37.92087265,
  },
  {
    label: "Molochio",
    longitude: 16.03183873,
    latitude: 38.30483208,
  },
  {
    label: "Monasterace",
    longitude: 16.55274871,
    latitude: 38.45363666,
  },
  {
    label: "Montebello Ionico",
    longitude: 15.75827051,
    latitude: 37.98169028,
  },
  {
    label: "Motta San Giovanni",
    longitude: 15.6973174,
    latitude: 38.00175728,
  },
  {
    label: "Oppido Mamertina",
    longitude: 15.98559889,
    latitude: 38.29261123,
  },
  {
    label: "Palizzi",
    longitude: 15.98331894,
    latitude: 37.91927937,
  },
  {
    label: "Palmi",
    longitude: 15.84981529,
    latitude: 38.35738161,
  },
  {
    label: "Pazzano",
    longitude: 16.45051702,
    latitude: 38.47716405,
  },
  {
    label: "Placanica",
    longitude: 16.45345062,
    latitude: 38.40980046,
  },
  {
    label: "Platitudeì",
    longitude: 16.04396894,
    latitude: 38.22028042,
  },
  {
    label: "Polistena",
    longitude: 16.07617637,
    latitude: 38.40797361,
  },
  {
    label: "Portigliola",
    longitude: 16.20285092,
    latitude: 38.22769007,
  },
  {
    label: "Reggio di Calabria",
    longitude: 15.64345359,
    latitude: 38.10922766,
  },
  {
    label: "Riace",
    longitude: 16.48085458,
    latitude: 38.41805567,
  },
  {
    label: "Rizziconi",
    longitude: 15.95875839,
    latitude: 38.41185425,
  },
  {
    label: "Roccaforte del Greco",
    longitude: 15.89960399,
    latitude: 38.04591019,
  },
  {
    label: "Roccella Ionica",
    longitude: 16.40346997,
    latitude: 38.32524775,
  },
  {
    label: "Roghudi",
    longitude: 15.76536718,
    latitude: 37.92499481,
  },
  {
    label: "Rosarno",
    longitude: 15.98283216,
    latitude: 38.487612,
  },
  {
    label: "Samo",
    longitude: 16.05630274,
    latitude: 38.07413293,
  },
  {
    label: "San Giorgio Morgeto",
    longitude: 16.10355491,
    latitude: 38.38702256,
  },
  {
    label: "San Giovanni di Gerace",
    longitude: 16.27789996,
    latitude: 38.36576679,
  },
  {
    label: "San Lorenzo",
    longitude: 15.83383671,
    latitude: 38.01063508,
  },
  {
    label: "San Luca",
    longitude: 16.06726483,
    latitude: 38.1451323,
  },
  {
    label: "San Pietro di Caridà",
    longitude: 16.13242869,
    latitude: 38.52168913,
  },
  {
    label: "San Procopio",
    longitude: 15.88943153,
    latitude: 38.28173876,
  },
  {
    label: "San Roberto",
    longitude: 15.73555509,
    latitude: 38.21145453,
  },
  {
    label: "Santa Cristina d'Aspromonte",
    longitude: 15.97003517,
    latitude: 38.2542747,
  },
  {
    label: "Sant'Agata del Bianco",
    longitude: 16.08251031,
    latitude: 38.09242278,
  },
  {
    label: "Sant'Alessio in Aspromonte",
    longitude: 15.75730556,
    latitude: 38.17219708,
  },
  {
    label: "Sant'Eufemia d'Aspromonte",
    longitude: 15.85473446,
    latitude: 38.26208056,
  },
  {
    label: "Sant'Ilario dello Ionio",
    longitude: 16.19253276,
    latitude: 38.22083738,
  },
  {
    label: "Santo Stefano in Aspromonte",
    longitude: 15.78934566,
    latitude: 38.16737949,
  },
  {
    label: "Scido",
    longitude: 15.93202,
    latitude: 38.24385347,
  },
  {
    label: "Scilla",
    longitude: 15.71525562,
    latitude: 38.25334976,
  },
  {
    label: "Seminara",
    longitude: 15.87049687,
    latitude: 38.33609976,
  },
  {
    label: "Serrata",
    longitude: 16.10156868,
    latitude: 38.5127728,
  },
  {
    label: "Siderno",
    longitude: 16.29758961,
    latitude: 38.26870019,
  },
  {
    label: "Sinopoli",
    longitude: 15.87741532,
    latitude: 38.26350265,
  },
  {
    label: "Staiti",
    longitude: 16.03350092,
    latitude: 38.00005281,
  },
  {
    label: "Stignano",
    longitude: 16.47047933,
    latitude: 38.41820448,
  },
  {
    label: "Stilo",
    longitude: 16.46707105,
    latitude: 38.47560489,
  },
  {
    label: "Taurianova",
    longitude: 16.01302264,
    latitude: 38.35256099,
  },
  {
    label: "Terranova Sappo Minulio",
    longitude: 16.00746647,
    latitude: 38.32174864,
  },
  {
    label: "Varapodio",
    longitude: 15.98322935,
    latitude: 38.3146494,
  },
  {
    label: "Villa San Giovanni",
    longitude: 15.63692204,
    latitude: 38.21916718,
  },
  {
    label: "San Ferdinando",
    longitude: 15.91565813,
    latitude: 38.48384424,
  },
  {
    label: "Alcamo",
    longitude: 12.96497004,
    latitude: 37.98060309,
  },
  {
    label: "Buseto Palizzolo",
    longitude: 12.71132304,
    latitude: 38.01217232,
  },
  {
    label: "Calatitudeafimi-Segesta",
    longitude: 12.86271362,
    latitude: 37.91416321,
  },
  {
    label: "Campobello di Mazara",
    longitude: 12.74409014,
    latitude: 37.63458999,
  },
  {
    label: "Castellammare del Golfo",
    longitude: 12.8804435,
    latitude: 38.02637875,
  },
  {
    label: "Castelvetrano",
    longitude: 12.79163803,
    latitude: 37.67916124,
  },
  {
    label: "Custonaci",
    longitude: 12.67554057,
    latitude: 38.07802811,
  },
  {
    label: "Erice",
    longitude: 12.58703706,
    latitude: 38.03810338,
  },
  {
    label: "Favignana",
    longitude: 12.32724071,
    latitude: 37.93177599,
  },
  {
    label: "Gibellina",
    longitude: 12.86981861,
    latitude: 37.80994146,
  },
  {
    label: "Marsala",
    longitude: 12.43494685,
    latitude: 37.79917261,
  },
  {
    label: "Mazara del Vallo",
    longitude: 12.58972014,
    latitude: 37.65134848,
  },
  {
    label: "Paceco",
    longitude: 12.55709849,
    latitude: 37.98109237,
  },
  {
    label: "Pantelleria",
    longitude: 11.94474454,
    latitude: 36.83189316,
  },
  {
    label: "Partanna",
    longitude: 12.88966372,
    latitude: 37.7213966,
  },
  {
    label: "Poggioreale",
    longitude: 13.03394289,
    latitude: 37.76214589,
  },
  {
    label: "Salaparuta",
    longitude: 13.00627343,
    latitude: 37.75697501,
  },
  {
    label: "Salemi",
    longitude: 12.80150907,
    latitude: 37.81600398,
  },
  {
    label: "Santa Ninfa",
    longitude: 12.88021819,
    latitude: 37.77382222,
  },
  {
    label: "San Vito Lo Capo",
    longitude: 12.73502088,
    latitude: 38.17309895,
  },
  {
    label: "Trapani",
    longitude: 12.51365685,
    latitude: 38.01850065,
  },
  {
    label: "Valderice",
    longitude: 12.61749353,
    latitude: 38.03794811,
  },
  {
    label: "Vita",
    longitude: 12.81458875,
    latitude: 37.86842619,
  },
  {
    label: "Petrosino",
    longitude: 12.49232372,
    latitude: 37.71276653,
  },
  {
    label: "Alia",
    longitude: 13.71312654,
    latitude: 37.77857858,
  },
  {
    label: "Alimena",
    longitude: 14.11367406,
    latitude: 37.69212923,
  },
  {
    label: "Aliminusa",
    longitude: 13.78104483,
    latitude: 37.86417485,
  },
  {
    label: "Altavilla Milicia",
    longitude: 13.55045349,
    latitude: 38.0423171,
  },
  {
    label: "Altofonte",
    longitude: 13.29829071,
    latitude: 38.04435532,
  },
  {
    label: "Bagheria",
    longitude: 13.51098444,
    latitude: 38.07888292,
  },
  {
    label: "Balestrate",
    longitude: 13.00438972,
    latitude: 38.05131521,
  },
  {
    label: "Baucina",
    longitude: 13.53723989,
    latitude: 37.92512717,
  },
  {
    label: "Belmonte Mezzagno",
    longitude: 13.39011115,
    latitude: 38.0483523,
  },
  {
    label: "Bisacquino",
    longitude: 13.25873047,
    latitude: 37.70578743,
  },
  {
    label: "Bolognetta",
    longitude: 13.45567566,
    latitude: 37.96471871,
  },
  {
    label: "Bompietro",
    longitude: 14.09952174,
    latitude: 37.74708402,
  },
  {
    label: "Borgetto",
    longitude: 13.1427413,
    latitude: 38.04642071,
  },
  {
    label: "Caccamo",
    longitude: 13.6635321,
    latitude: 37.93179987,
  },
  {
    label: "Caltavuturo",
    longitude: 13.89007124,
    latitude: 37.82030861,
  },
  {
    label: "Campofelice di Fitalia",
    longitude: 13.48457087,
    latitude: 37.82621584,
  },
  {
    label: "Campofelice di Roccella",
    longitude: 13.88584537,
    latitude: 37.9920103,
  },
  {
    label: "Campofiorito",
    longitude: 13.26918551,
    latitude: 37.75326228,
  },
  {
    label: "Camporeale",
    longitude: 13.09530579,
    latitude: 37.89700076,
  },
  {
    label: "Capaci",
    longitude: 13.23935186,
    latitude: 38.17083872,
  },
  {
    label: "Carini",
    longitude: 13.18240939,
    latitude: 38.1331909,
  },
  {
    label: "Castelbuono",
    longitude: 14.08825699,
    latitude: 37.93384847,
  },
  {
    label: "Casteldaccia",
    longitude: 13.53252086,
    latitude: 38.05536784,
  },
  {
    label: "Castellana Sicula",
    longitude: 14.0440936,
    latitude: 37.78176388,
  },
  {
    label: "Castronovo di Sicilia",
    longitude: 13.6037351,
    latitude: 37.67842244,
  },
  {
    label: "Cefalà Diana",
    longitude: 13.46371561,
    latitude: 37.9154063,
  },
  {
    label: "Cefalù",
    longitude: 14.02202407,
    latitude: 38.03969852,
  },
  {
    label: "Cerda",
    longitude: 13.81537105,
    latitude: 37.9038606,
  },
  {
    label: "Chiusa Sclafani",
    longitude: 13.26987223,
    latitude: 37.67706196,
  },
  {
    label: "Ciminna",
    longitude: 13.5619533,
    latitude: 37.89726194,
  },
  {
    label: "Cinisi",
    longitude: 13.10913663,
    latitude: 38.15490295,
  },
  {
    label: "Collesano",
    longitude: 13.93754507,
    latitude: 37.92105542,
  },
  {
    label: "Contessa Entellina",
    longitude: 13.18403998,
    latitude: 37.72819592,
  },
  {
    label: "Corleone",
    longitude: 13.3015804,
    latitude: 37.81204186,
  },
  {
    label: "Ficarazzi",
    longitude: 13.46320747,
    latitude: 38.08835152,
  },
  {
    label: "Gangi",
    longitude: 14.20524912,
    latitude: 37.7981533,
  },
  {
    label: "Geraci Siculo",
    longitude: 14.15415104,
    latitude: 37.85898758,
  },
  {
    label: "Giardinello",
    longitude: 13.1567837,
    latitude: 38.08861222,
  },
  {
    label: "Giuliana",
    longitude: 13.23787124,
    latitude: 37.67426741,
  },
  {
    label: "Godrano",
    longitude: 13.4286185,
    latitude: 37.90303566,
  },
  {
    label: "Gratteri",
    longitude: 13.97307322,
    latitude: 37.96731008,
  },
  {
    label: "Isnello",
    longitude: 14.00607302,
    latitude: 37.94398431,
  },
  {
    label: "Isola delle Femmine",
    longitude: 13.24620555,
    latitude: 38.2005898,
  },
  {
    label: "Lascari",
    longitude: 13.9411201,
    latitude: 37.99988341,
  },
  {
    label: "Lercara Friddi",
    longitude: 13.60283501,
    latitude: 37.74748969,
  },
  {
    label: "Marineo",
    longitude: 13.41410836,
    latitude: 37.9526806,
  },
  {
    label: "Mezzojuso",
    longitude: 13.46450986,
    latitude: 37.86449557,
  },
  {
    label: "Misilmeri",
    longitude: 13.45154671,
    latitude: 38.0351406,
  },
  {
    label: "Monreale",
    longitude: 13.29170019,
    latitude: 38.0823269,
  },
  {
    label: "Montelepre",
    longitude: 13.17326674,
    latitude: 38.08979235,
  },
  {
    label: "Montemaggiore Belsito",
    longitude: 13.76257765,
    latitude: 37.84789269,
  },
  {
    label: "Palazzo Adriano",
    longitude: 13.37965852,
    latitude: 37.68162495,
  },
  {
    label: "Palermo",
    longitude: 13.36235675,
    latitude: 38.11569725,
  },
  {
    label: "Partinico",
    longitude: 13.11521899,
    latitude: 38.0480822,
  },
  {
    label: "Petralia Soprana",
    longitude: 14.10825065,
    latitude: 37.79634285,
  },
  {
    label: "Petralia Sottana",
    longitude: 14.09221617,
    latitude: 37.805943,
  },
  {
    label: "Piana degli Albanesi",
    longitude: 13.28199289,
    latitude: 37.99447148,
  },
  {
    label: "Polizzi Generosa",
    longitude: 13.99947177,
    latitude: 37.81291089,
  },
  {
    label: "Pollina",
    longitude: 14.14658207,
    latitude: 37.99424976,
  },
  {
    label: "Prizzi",
    longitude: 13.4309912,
    latitude: 37.72165331,
  },
  {
    label: "Roccamena",
    longitude: 13.15530062,
    latitude: 37.8385929,
  },
  {
    label: "Roccapalumba",
    longitude: 13.6394537,
    latitude: 37.80607308,
  },
  {
    label: "San Cipirello",
    longitude: 13.18170348,
    latitude: 37.96022597,
  },
  {
    label: "San Giuseppe Jato",
    longitude: 13.18116224,
    latitude: 37.96819483,
  },
  {
    label: "San Mauro Castelverde",
    longitude: 14.19009345,
    latitude: 37.91446229,
  },
  {
    label: "Santa Cristina Gela",
    longitude: 13.32751612,
    latitude: 37.98508619,
  },
  {
    label: "Santa Flavia",
    longitude: 13.52499914,
    latitude: 38.08217373,
  },
  {
    label: "Sciara",
    longitude: 13.76261496,
    latitude: 37.91464342,
  },
  {
    label: "Sclafani Bagni",
    longitude: 13.85460399,
    latitude: 37.82112946,
  },
  {
    label: "Termini Imerese",
    longitude: 13.69594357,
    latitude: 37.98680894,
  },
  {
    label: "Terrasini",
    longitude: 13.08238845,
    latitude: 38.15295377,
  },
  {
    label: "Torretta",
    longitude: 13.23313847,
    latitude: 38.1311782,
  },
  {
    label: "Trabia",
    longitude: 13.65541386,
    latitude: 37.99575625,
  },
  {
    label: "Trappeto",
    longitude: 13.03652566,
    latitude: 38.06655571,
  },
  {
    label: "Ustica",
    longitude: 13.19284289,
    latitude: 38.71007807,
  },
  {
    label: "Valledolmo",
    longitude: 13.82825937,
    latitude: 37.74708325,
  },
  {
    label: "Ventimiglia di Sicilia",
    longitude: 13.56791057,
    latitude: 37.92614112,
  },
  {
    label: "Vicari",
    longitude: 13.57075051,
    latitude: 37.82657579,
  },
  {
    label: "Villabate",
    longitude: 13.44209183,
    latitude: 38.07564273,
  },
  {
    label: "Villafrati",
    longitude: 13.48672178,
    latitude: 37.9080738,
  },
  {
    label: "Scillatitudeo",
    longitude: 13.9068297,
    latitude: 37.85670474,
  },
  {
    label: "Blufi",
    longitude: 14.07674064,
    latitude: 37.75230981,
  },
  {
    label: "Alcara li Fusi",
    longitude: 14.70108558,
    latitude: 38.02145385,
  },
  {
    label: "Alì",
    longitude: 15.41797276,
    latitude: 38.02694065,
  },
  {
    label: "Alì Terme",
    longitude: 15.42261725,
    latitude: 38.00375238,
  },
  {
    label: "Antillo",
    longitude: 15.24539421,
    latitude: 37.97612979,
  },
  {
    label: "Barcellona Pozzo di Gotto",
    longitude: 15.21857534,
    latitude: 38.14692512,
  },
  {
    label: "Basicò",
    longitude: 15.06175499,
    latitude: 38.06159926,
  },
  {
    label: "Brolo",
    longitude: 14.82771691,
    latitude: 38.15684761,
  },
  {
    label: "Capizzi",
    longitude: 14.47841766,
    latitude: 37.84887419,
  },
  {
    label: "Capo d'Orlando",
    longitude: 14.74392536,
    latitude: 38.16030299,
  },
  {
    label: "Capri Leone",
    longitude: 14.70723848,
    latitude: 38.1075257,
  },
  {
    label: "Caronia",
    longitude: 14.44076582,
    latitude: 38.02470888,
  },
  {
    label: "Casalvecchio Siculo",
    longitude: 15.32349977,
    latitude: 37.95821414,
  },
  {
    label: "Castel di Lucio",
    longitude: 14.31045875,
    latitude: 37.88722344,
  },
  {
    label: "Castell'Umberto",
    longitude: 14.80687881,
    latitude: 38.0845797,
  },
  {
    label: "Castelmola",
    longitude: 15.27665979,
    latitude: 37.85818779,
  },
  {
    label: "Castroreale",
    longitude: 15.21102502,
    latitude: 38.09848356,
  },
  {
    label: "Cesarò",
    longitude: 14.71217827,
    latitude: 37.84375488,
  },
  {
    label: "Condrò",
    longitude: 15.32648511,
    latitude: 38.17570357,
  },
  {
    label: "Falcone",
    longitude: 15.08228905,
    latitude: 38.11643166,
  },
  {
    label: "Ficarra",
    longitude: 14.8274778,
    latitude: 38.1104203,
  },
  {
    label: "Fiumedinisi",
    longitude: 15.38231276,
    latitude: 38.02502794,
  },
  {
    label: "Floresta",
    longitude: 14.90945173,
    latitude: 37.98770217,
  },
  {
    label: "Fondachelli-Fantina",
    longitude: 15.1730753,
    latitude: 37.98597775,
  },
  {
    label: "Forza d'Agrò",
    longitude: 15.3351305,
    latitude: 37.91411237,
  },
  {
    label: "Francavilla di Sicilia",
    longitude: 15.13614134,
    latitude: 37.90124653,
  },
  {
    label: "Frazzanò",
    longitude: 14.74474753,
    latitude: 38.07234359,
  },
  {
    label: "Furci Siculo",
    longitude: 15.37997427,
    latitude: 37.9609047,
  },
  {
    label: "Furnari",
    longitude: 15.12548931,
    latitude: 38.10475339,
  },
  {
    label: "Gaggi",
    longitude: 15.22055836,
    latitude: 37.86086335,
  },
  {
    label: "Galatitudei Mamertino",
    longitude: 14.77102197,
    latitude: 38.03302982,
  },
  {
    label: "Gallodoro",
    longitude: 15.29381546,
    latitude: 37.90168112,
  },
  {
    label: "Giardini-Naxos",
    longitude: 15.27742465,
    latitude: 37.84023248,
  },
  {
    label: "Gioiosa Marea",
    longitude: 14.89284233,
    latitude: 38.17198745,
  },
  {
    label: "Graniti",
    longitude: 15.22670686,
    latitude: 37.89133028,
  },
  {
    label: "Gualtieri Sicaminò",
    longitude: 15.31675986,
    latitude: 38.16306516,
  },
  {
    label: "Itala",
    longitude: 15.43731628,
    latitude: 38.0506133,
  },
  {
    label: "Leni",
    longitude: 14.82416615,
    latitude: 38.55494763,
  },
  {
    label: "Letojanni",
    longitude: 15.3062556,
    latitude: 37.88030919,
  },
  {
    label: "Librizzi",
    longitude: 14.95818725,
    latitude: 38.09716507,
  },
  {
    label: "Limina",
    longitude: 15.27264247,
    latitude: 37.94087859,
  },
  {
    label: "Lipari",
    longitude: 14.95652135,
    latitude: 38.46903961,
  },
  {
    label: "Longi",
    longitude: 14.75261325,
    latitude: 38.02716872,
  },
  {
    label: "Malfa",
    longitude: 14.83604364,
    latitude: 38.57699743,
  },
  {
    label: "Malvagna",
    longitude: 15.05435477,
    latitude: 37.91866067,
  },
  {
    label: "Mandanici",
    longitude: 15.31711083,
    latitude: 38.00229698,
  },
  {
    label: "Mazzarrà Sant'Andrea",
    longitude: 15.13585547,
    latitude: 38.08979715,
  },
  {
    label: "Merì",
    longitude: 15.24644375,
    latitude: 38.16794237,
  },
  {
    label: "Messina",
    longitude: 15.55572383,
    latitude: 38.19395841,
  },
  {
    label: "Milazzo",
    longitude: 15.24274412,
    latitude: 38.22087052,
  },
  {
    label: "Militello Rosmarino",
    longitude: 14.67603944,
    latitude: 38.04635773,
  },
  {
    label: "Mirto",
    longitude: 14.75285354,
    latitude: 38.08512412,
  },
  {
    label: "Mistretta",
    longitude: 14.36235493,
    latitude: 37.93048648,
  },
  {
    label: "Moio Alcantara",
    longitude: 15.05052936,
    latitude: 37.89883558,
  },
  {
    label: "Monforte San Giorgio",
    longitude: 15.38351724,
    latitude: 38.15686649,
  },
  {
    label: "Mongiuffi Melia",
    longitude: 15.27493092,
    latitude: 37.90419803,
  },
  {
    label: "Montagnareale",
    longitude: 14.94770863,
    latitude: 38.13246075,
  },
  {
    label: "Montalbano Elicona",
    longitude: 15.01413113,
    latitude: 38.02371254,
  },
  {
    label: "Motta Camastra",
    longitude: 15.17250433,
    latitude: 37.89568789,
  },
  {
    label: "Motta d'Affermo",
    longitude: 14.30206148,
    latitude: 37.98129062,
  },
  {
    label: "Naso",
    longitude: 14.78820006,
    latitude: 38.12194429,
  },
  {
    label: "Nizza di Sicilia",
    longitude: 15.40794343,
    latitude: 37.98886,
  },
  {
    label: "Novara di Sicilia",
    longitude: 15.13193797,
    latitude: 38.01624974,
  },
  {
    label: "Oliveri",
    longitude: 15.05947815,
    latitude: 38.12463376,
  },
  {
    label: "Pace del Mela",
    longitude: 15.30570478,
    latitude: 38.18012927,
  },
  {
    label: "Pagliara",
    longitude: 15.35949936,
    latitude: 37.98658779,
  },
  {
    label: "Patti",
    longitude: 14.96403605,
    latitude: 38.13978414,
  },
  {
    label: "Pettineo",
    longitude: 14.29122447,
    latitude: 37.96661363,
  },
  {
    label: "Piraino",
    longitude: 14.86316622,
    latitude: 38.16189942,
  },
  {
    label: "Raccuja",
    longitude: 14.91065006,
    latitude: 38.05561324,
  },
  {
    label: "Reitano",
    longitude: 14.34444665,
    latitude: 37.97307659,
  },
  {
    label: "Roccafiorita",
    longitude: 15.26846879,
    latitude: 37.92987623,
  },
  {
    label: "Roccalumera",
    longitude: 15.39453262,
    latitude: 37.97509309,
  },
  {
    label: "Roccavaldina",
    longitude: 15.37497616,
    latitude: 38.18230046,
  },
  {
    label: "Roccella Valdemone",
    longitude: 15.0095185,
    latitude: 37.93275497,
  },
  {
    label: "Rodì Milici",
    longitude: 15.16957002,
    latitude: 38.11045739,
  },
  {
    label: "Rometta",
    longitude: 15.41549487,
    latitude: 38.17265545,
  },
  {
    label: "San Filippo del Mela",
    longitude: 15.27181211,
    latitude: 38.16929056,
  },
  {
    label: "San Fratello",
    longitude: 14.59622906,
    latitude: 38.01289142,
  },
  {
    label: "San Marco d'Alunzio",
    longitude: 14.69883524,
    latitude: 38.07304978,
  },
  {
    label: "San Pier Niceto",
    longitude: 15.35151876,
    latitude: 38.15873308,
  },
  {
    label: "San Piero Patti",
    longitude: 14.96622365,
    latitude: 38.0506834,
  },
  {
    label: "San Salvatore di Fitalia",
    longitude: 14.77745536,
    latitude: 38.07054476,
  },
  {
    label: "Santa Domenica Vittoria",
    longitude: 14.9618867,
    latitude: 37.91554631,
  },
  {
    label: "Sant'Agata di Militello",
    longitude: 14.63367737,
    latitude: 38.0695205,
  },
  {
    label: "Sant'Alessio Siculo",
    longitude: 15.34477639,
    latitude: 37.91757739,
  },
  {
    label: "Santa Lucia del Mela",
    longitude: 15.28317623,
    latitude: 38.13999317,
  },
  {
    label: "Santa Marina Salina",
    longitude: 14.87169526,
    latitude: 38.55942223,
  },
  {
    label: "Sant'Angelo di Brolo",
    longitude: 14.88215123,
    latitude: 38.11320212,
  },
  {
    label: "Santa Teresa di Riva",
    longitude: 15.36794666,
    latitude: 37.94571282,
  },
  {
    label: "San Teodoro",
    longitude: 14.69937355,
    latitude: 37.8478077,
  },
  {
    label: "Santo Stefano di Camastra",
    longitude: 14.34832712,
    latitude: 38.01634772,
  },
  {
    label: "Saponara",
    longitude: 15.43425497,
    latitude: 38.19386679,
  },
  {
    label: "Savoca",
    longitude: 15.33979136,
    latitude: 37.9560734,
  },
  {
    label: "Scaletta Zanclea",
    longitude: 15.45932762,
    latitude: 38.0428556,
  },
  {
    label: "Sinagra",
    longitude: 14.84952801,
    latitude: 38.08323735,
  },
  {
    label: "Spadafora",
    longitude: 15.37413714,
    latitude: 38.22207704,
  },
  {
    label: "Taormina",
    longitude: 15.28308351,
    latitude: 37.85113293,
  },
  {
    label: "Torregrotta",
    longitude: 15.35231584,
    latitude: 38.19165519,
  },
  {
    label: "Tortorici",
    longitude: 14.82486377,
    latitude: 38.03054087,
  },
  {
    label: "Tripi",
    longitude: 15.09943005,
    latitude: 38.04933749,
  },
  {
    label: "Tusa",
    longitude: 14.23852776,
    latitude: 37.98456978,
  },
  {
    label: "Ucria",
    longitude: 14.88092158,
    latitude: 38.04562699,
  },
  {
    label: "Valdina",
    longitude: 15.3707382,
    latitude: 38.19349868,
  },
  {
    label: "Venetico",
    longitude: 15.36479822,
    latitude: 38.21905395,
  },
  {
    label: "Villafranca Tirrena",
    longitude: 15.43249938,
    latitude: 38.23545409,
  },
  {
    label: "Terme Vigliatore",
    longitude: 15.16241103,
    latitude: 38.13672717,
  },
  {
    label: "Acquedolci",
    longitude: 14.58806194,
    latitude: 38.05636112,
  },
  {
    label: "Torrenova",
    longitude: 14.67733979,
    latitude: 38.091126,
  },
  {
    label: "Agrigento",
    longitude: 13.58457496,
    latitude: 37.30971088,
  },
  {
    label: "Alessandria della Rocca",
    longitude: 13.45348842,
    latitude: 37.56973494,
  },
  {
    label: "Aragona",
    longitude: 13.61778892,
    latitude: 37.40243058,
  },
  {
    label: "Bivona",
    longitude: 13.44040048,
    latitude: 37.61810993,
  },
  {
    label: "Burgio",
    longitude: 13.28953712,
    latitude: 37.59887056,
  },
  {
    label: "Calamonaci",
    longitude: 13.29090306,
    latitude: 37.52534229,
  },
  {
    label: "Caltabellotta",
    longitude: 13.21565014,
    latitude: 37.57690304,
  },
  {
    label: "Camastra",
    longitude: 13.79377357,
    latitude: 37.25215719,
  },
  {
    label: "Cammarata",
    longitude: 13.63797741,
    latitude: 37.63272878,
  },
  {
    label: "Campobello di Licata",
    longitude: 13.91888981,
    latitude: 37.25978333,
  },
  {
    label: "Canicattì",
    longitude: 13.84999085,
    latitude: 37.36023871,
  },
  {
    label: "Casteltermini",
    longitude: 13.64340312,
    latitude: 37.54081015,
  },
  {
    label: "Castrofilippo",
    longitude: 13.75018349,
    latitude: 37.34868982,
  },
  {
    label: "Cattolica Eraclea",
    longitude: 13.39539987,
    latitude: 37.43952809,
  },
  {
    label: "Cianciana",
    longitude: 13.43525819,
    latitude: 37.51744705,
  },
  {
    label: "Comitini",
    longitude: 13.64362024,
    latitude: 37.40860888,
  },
  {
    label: "Favara",
    longitude: 13.65646307,
    latitude: 37.31070969,
  },
  {
    label: "Grotte",
    longitude: 13.6991796,
    latitude: 37.4025486,
  },
  {
    label: "Joppolo Giancaxio",
    longitude: 13.55409781,
    latitude: 37.38774566,
  },
  {
    label: "Lampedusa e Linosa",
    longitude: 12.60984005,
    latitude: 35.50195501,
  },
  {
    label: "Licata",
    longitude: 13.93718336,
    latitude: 37.10156734,
  },
  {
    label: "Lucca Sicula",
    longitude: 13.30670721,
    latitude: 37.57845576,
  },
  {
    label: "Menfi",
    longitude: 12.96641122,
    latitude: 37.59822493,
  },
  {
    label: "Montallegro",
    longitude: 13.35067955,
    latitude: 37.39108905,
  },
  {
    label: "Montevago",
    longitude: 12.99017731,
    latitude: 37.70262274,
  },
  {
    label: "Naro",
    longitude: 13.79204148,
    latitude: 37.29606377,
  },
  {
    label: "Palma di Montechiaro",
    longitude: 13.7636752,
    latitude: 37.19167642,
  },
  {
    label: "Porto Empedocle",
    longitude: 13.52746716,
    latitude: 37.28846526,
  },
  {
    label: "Racalmuto",
    longitude: 13.73141734,
    latitude: 37.40771285,
  },
  {
    label: "Raffadali",
    longitude: 13.53109425,
    latitude: 37.40489693,
  },
  {
    label: "Ravanusa",
    longitude: 13.97248171,
    latitude: 37.26823623,
  },
  {
    label: "Realmonte",
    longitude: 13.46279155,
    latitude: 37.30680567,
  },
  {
    label: "Ribera",
    longitude: 13.2686576,
    latitude: 37.50382096,
  },
  {
    label: "Sambuca di Sicilia",
    longitude: 13.11072583,
    latitude: 37.64742058,
  },
  {
    label: "San Biagio Platitudeani",
    longitude: 13.52422822,
    latitude: 37.50899211,
  },
  {
    label: "San Giovanni Gemini",
    longitude: 13.6412019,
    latitude: 37.62994447,
  },
  {
    label: "Santa Elisabetta",
    longitude: 13.55490487,
    latitude: 37.43158452,
  },
  {
    label: "Santa Margherita di Belice",
    longitude: 13.02402423,
    latitude: 37.6919725,
  },
  {
    label: "Sant'Angelo Muxaro",
    longitude: 13.54427616,
    latitude: 37.47918744,
  },
  {
    label: "Santo Stefano Quisquina",
    longitude: 13.49031996,
    latitude: 37.62469807,
  },
  {
    label: "Sciacca",
    longitude: 13.08292613,
    latitude: 37.50777148,
  },
  {
    label: "Siculiana",
    longitude: 13.42004274,
    latitude: 37.33648457,
  },
  {
    label: "Villafranca Sicula",
    longitude: 13.29093513,
    latitude: 37.58683326,
  },
  {
    label: "Acquaviva Platitudeani",
    longitude: 13.70014279,
    latitude: 37.57170412,
  },
  {
    label: "Bompensiere",
    longitude: 13.7806399,
    latitude: 37.47263349,
  },
  {
    label: "Butera",
    longitude: 14.18628296,
    latitude: 37.18643008,
  },
  {
    label: "Caltanissetta",
    longitude: 14.06184986,
    latitude: 37.49213171,
  },
  {
    label: "Campofranco",
    longitude: 13.71255278,
    latitude: 37.51200991,
  },
  {
    label: "Delia",
    longitude: 13.92762543,
    latitude: 37.35605898,
  },
  {
    label: "Gela",
    longitude: 14.25035002,
    latitude: 37.06545435,
  },
  {
    label: "Marianopoli",
    longitude: 13.91508083,
    latitude: 37.59883246,
  },
  {
    label: "Mazzarino",
    longitude: 14.21424397,
    latitude: 37.30500006,
  },
  {
    label: "Milena",
    longitude: 13.73627968,
    latitude: 37.47145256,
  },
  {
    label: "Montedoro",
    longitude: 13.81637743,
    latitude: 37.4537366,
  },
  {
    label: "Mussomeli",
    longitude: 13.75402582,
    latitude: 37.58022427,
  },
  {
    label: "Niscemi",
    longitude: 14.38503985,
    latitude: 37.14528117,
  },
  {
    label: "Resuttano",
    longitude: 14.03105327,
    latitude: 37.68012807,
  },
  {
    label: "Riesi",
    longitude: 14.08220646,
    latitude: 37.28338286,
  },
  {
    label: "San Cataldo",
    longitude: 13.99047906,
    latitude: 37.48716186,
  },
  {
    label: "Santa Caterina Villarmosa",
    longitude: 14.03188994,
    latitude: 37.59174984,
  },
  {
    label: "Serradifalco",
    longitude: 13.88250841,
    latitude: 37.45653741,
  },
  {
    label: "Sommatino",
    longitude: 13.99122294,
    latitude: 37.33386339,
  },
  {
    label: "Sutera",
    longitude: 13.73130483,
    latitude: 37.52558206,
  },
  {
    label: "Vallelunga Pratameno",
    longitude: 13.83047129,
    latitude: 37.68020669,
  },
  {
    label: "Villalba",
    longitude: 13.8439328,
    latitude: 37.65497687,
  },
  {
    label: "Agira",
    longitude: 14.52379575,
    latitude: 37.65721229,
  },
  {
    label: "Aidone",
    longitude: 14.44789122,
    latitude: 37.4160022,
  },
  {
    label: "Assoro",
    longitude: 14.42408648,
    latitude: 37.62816262,
  },
  {
    label: "Barrafranca",
    longitude: 14.20387847,
    latitude: 37.37284964,
  },
  {
    label: "Calascibetta",
    longitude: 14.27309071,
    latitude: 37.58955793,
  },
  {
    label: "Catenanuova",
    longitude: 14.69145818,
    latitude: 37.56792494,
  },
  {
    label: "Centuripe",
    longitude: 14.7414306,
    latitude: 37.62030983,
  },
  {
    label: "Cerami",
    longitude: 14.50637122,
    latitude: 37.81035063,
  },
  {
    label: "Enna",
    longitude: 14.27909392,
    latitude: 37.56705701,
  },
  {
    label: "Gagliano Castelferrato",
    longitude: 14.53558411,
    latitude: 37.71031882,
  },
  {
    label: "Leonforte",
    longitude: 14.39206612,
    latitude: 37.63918202,
  },
  {
    label: "Nicosia",
    longitude: 14.39854731,
    latitude: 37.74805212,
  },
  {
    label: "Nissoria",
    longitude: 14.44936356,
    latitude: 37.65515695,
  },
  {
    label: "Piazza Armerina",
    longitude: 14.36603607,
    latitude: 37.38420844,
  },
  {
    label: "Pietraperzia",
    longitude: 14.13691358,
    latitude: 37.42163431,
  },
  {
    label: "Regalbuto",
    longitude: 14.64079757,
    latitude: 37.65262615,
  },
  {
    label: "Sperlinga",
    longitude: 14.35337156,
    latitude: 37.76490049,
  },
  {
    label: "Troina",
    longitude: 14.60044817,
    latitude: 37.78501819,
  },
  {
    label: "Valguarnera Caropepe",
    longitude: 14.38816347,
    latitude: 37.49667851,
  },
  {
    label: "Villarosa",
    longitude: 14.17423425,
    latitude: 37.5879175,
  },
  {
    label: "Aci Bonaccorsi",
    longitude: 15.10860732,
    latitude: 37.59789361,
  },
  {
    label: "Aci Castello",
    longitude: 15.14523329,
    latitude: 37.5558181,
  },
  {
    label: "Aci Catena",
    longitude: 15.14224165,
    latitude: 37.60268159,
  },
  {
    label: "Acireale",
    longitude: 15.16628791,
    latitude: 37.61215073,
  },
  {
    label: "Aci Sant'Antonio",
    longitude: 15.12585282,
    latitude: 37.60601727,
  },
  {
    label: "Adrano",
    longitude: 14.83268741,
    latitude: 37.66181377,
  },
  {
    label: "Belpasso",
    longitude: 14.97704833,
    latitude: 37.59138844,
  },
  {
    label: "Biancavilla",
    longitude: 14.86954107,
    latitude: 37.64125641,
  },
  {
    label: "Bronte",
    longitude: 14.83289947,
    latitude: 37.78751853,
  },
  {
    label: "Calatitudeabiano",
    longitude: 15.22774979,
    latitude: 37.82332843,
  },
  {
    label: "Caltagirone",
    longitude: 14.5127442,
    latitude: 37.23808582,
  },
  {
    label: "Camporotondo Etneo",
    longitude: 15.00595832,
    latitude: 37.56740031,
  },
  {
    label: "Castel di Iudica",
    longitude: 14.64944945,
    latitude: 37.49443804,
  },
  {
    label: "Castiglione di Sicilia",
    longitude: 15.1223902,
    latitude: 37.88270389,
  },
  {
    label: "Catania",
    longitude: 15.08704738,
    latitude: 37.50287801,
  },
  {
    label: "Fiumefreddo di Sicilia",
    longitude: 15.20820135,
    latitude: 37.7929303,
  },
  {
    label: "Giarre",
    longitude: 15.18450405,
    latitude: 37.73003683,
  },
  {
    label: "Grammichele",
    longitude: 14.63723701,
    latitude: 37.21500543,
  },
  {
    label: "Gravina di Catania",
    longitude: 15.06212657,
    latitude: 37.56058183,
  },
  {
    label: "Licodia Eubea",
    longitude: 14.70331914,
    latitude: 37.15572822,
  },
  {
    label: "Linguaglossa",
    longitude: 15.14400823,
    latitude: 37.84073315,
  },
  {
    label: "Maletto",
    longitude: 14.866289,
    latitude: 37.82950116,
  },
  {
    label: "Mascali",
    longitude: 15.19535853,
    latitude: 37.75749529,
  },
  {
    label: "Mascalucia",
    longitude: 15.05302159,
    latitude: 37.57305779,
  },
  {
    label: "Militello in Val di Catania",
    longitude: 14.79399781,
    latitude: 37.27480551,
  },
  {
    label: "Milo",
    longitude: 15.11606553,
    latitude: 37.72334446,
  },
  {
    label: "Mineo",
    longitude: 14.6911113,
    latitude: 37.2648747,
  },
  {
    label: "Mirabella Imbaccari",
    longitude: 14.4460326,
    latitude: 37.32443567,
  },
  {
    label: "Misterbianco",
    longitude: 15.00637221,
    latitude: 37.52084469,
  },
  {
    label: "Motta Sant'Anastasia",
    longitude: 14.96976761,
    latitude: 37.5121011,
  },
  {
    label: "Nicolosi",
    longitude: 15.02671373,
    latitude: 37.61413944,
  },
  {
    label: "Palagonia",
    longitude: 14.74576385,
    latitude: 37.32765928,
  },
  {
    label: "Paternò",
    longitude: 14.90251867,
    latitude: 37.57696713,
  },
  {
    label: "Pedara",
    longitude: 15.06021594,
    latitude: 37.61720408,
  },
  {
    label: "Piedimonte Etneo",
    longitude: 15.17821412,
    latitude: 37.80766824,
  },
  {
    label: "Raddusa",
    longitude: 14.5317315,
    latitude: 37.47291883,
  },
  {
    label: "Ramacca",
    longitude: 14.69378294,
    latitude: 37.38487209,
  },
  {
    label: "Randazzo",
    longitude: 14.94605369,
    latitude: 37.87790854,
  },
  {
    label: "Riposto",
    longitude: 15.20383515,
    latitude: 37.7318185,
  },
  {
    label: "San Cono",
    longitude: 14.36600469,
    latitude: 37.28994189,
  },
  {
    label: "San Giovanni la Punta",
    longitude: 15.09429701,
    latitude: 37.57706765,
  },
  {
    label: "San Gregorio di Catania",
    longitude: 15.10845966,
    latitude: 37.56488406,
  },
  {
    label: "San Michele di Ganzaria",
    longitude: 14.42611634,
    latitude: 37.27946798,
  },
  {
    label: "San Pietro Clarenza",
    longitude: 15.02419347,
    latitude: 37.57233467,
  },
  {
    label: "Sant'Agata li Battiati",
    longitude: 15.08022998,
    latitude: 37.55864644,
  },
  {
    label: "Sant'Alfio",
    longitude: 15.14411429,
    latitude: 37.74407333,
  },
  {
    label: "Santa Maria di Licodia",
    longitude: 14.88739795,
    latitude: 37.61617815,
  },
  {
    label: "Santa Venerina",
    longitude: 15.13887642,
    latitude: 37.68697501,
  },
  {
    label: "Scordia",
    longitude: 14.84609045,
    latitude: 37.29649404,
  },
  {
    label: "Trecastagni",
    longitude: 15.07920192,
    latitude: 37.61700077,
  },
  {
    label: "Tremestieri Etneo",
    longitude: 15.07214434,
    latitude: 37.57583588,
  },
  {
    label: "Valverde",
    longitude: 15.12482337,
    latitude: 37.57900347,
  },
  {
    label: "Viagrande",
    longitude: 15.09632891,
    latitude: 37.6105856,
  },
  {
    label: "Vizzini",
    longitude: 14.7485177,
    latitude: 37.16103188,
  },
  {
    label: "Zafferana Etnea",
    longitude: 15.10337582,
    latitude: 37.69470869,
  },
  {
    label: "Mazzarrone",
    longitude: 14.56159557,
    latitude: 37.08400559,
  },
  {
    label: "Maniace",
    longitude: 14.8077535,
    latitude: 37.86200981,
  },
  {
    label: "Ragalna",
    longitude: 14.93914399,
    latitude: 37.63012861,
  },
  {
    label: "Acate",
    longitude: 14.49372908,
    latitude: 37.02640029,
  },
  {
    label: "Chiaramonte Gulfi",
    longitude: 14.70126744,
    latitude: 37.03157582,
  },
  {
    label: "Comiso",
    longitude: 14.60468609,
    latitude: 36.94565701,
  },
  {
    label: "Giarratana",
    longitude: 14.79303144,
    latitude: 37.04827526,
  },
  {
    label: "Ispica",
    longitude: 14.90352486,
    latitude: 36.78617863,
  },
  {
    label: "Modica",
    longitude: 14.76136035,
    latitude: 36.85884699,
  },
  {
    label: "Monterosso Almo",
    longitude: 14.76256834,
    latitude: 37.08888285,
  },
  {
    label: "Pozzallo",
    longitude: 14.84615608,
    latitude: 36.72659325,
  },
  {
    label: "Ragusa",
    longitude: 14.7306992,
    latitude: 36.92509197,
  },
  {
    label: "Santa Croce Camerina",
    longitude: 14.52544024,
    latitude: 36.82853528,
  },
  {
    label: "Scicli",
    longitude: 14.70590362,
    latitude: 36.79359171,
  },
  {
    label: "Vittoria",
    longitude: 14.53800649,
    latitude: 36.94878747,
  },
  {
    label: "Augusta",
    longitude: 15.21944081,
    latitude: 37.23060554,
  },
  {
    label: "Avola",
    longitude: 15.13544392,
    latitude: 36.90839155,
  },
  {
    label: "Buccheri",
    longitude: 14.85046834,
    latitude: 37.12651105,
  },
  {
    label: "Buscemi",
    longitude: 14.88415569,
    latitude: 37.08523351,
  },
  {
    label: "Canicattini Bagni",
    longitude: 15.06173869,
    latitude: 37.03498945,
  },
  {
    label: "Carlentini",
    longitude: 15.0147705,
    latitude: 37.27597703,
  },
  {
    label: "Cassaro",
    longitude: 14.94764473,
    latitude: 37.10522743,
  },
  {
    label: "Ferla",
    longitude: 14.94146541,
    latitude: 37.11861277,
  },
  {
    label: "Floridia",
    longitude: 15.15289178,
    latitude: 37.08211457,
  },
  {
    label: "Francofonte",
    longitude: 14.88012787,
    latitude: 37.23078145,
  },
  {
    label: "Lentini",
    longitude: 14.99933634,
    latitude: 37.28451352,
  },
  {
    label: "Melilli",
    longitude: 15.12675487,
    latitude: 37.17850834,
  },
  {
    label: "Noto",
    longitude: 15.06670248,
    latitude: 36.89169626,
  },
  {
    label: "Pachino",
    longitude: 15.09315194,
    latitude: 36.71608657,
  },
  {
    label: "Palazzolo Acreide",
    longitude: 14.90382617,
    latitude: 37.06188027,
  },
  {
    label: "Rosolini",
    longitude: 14.95353104,
    latitude: 36.82034519,
  },
  {
    label: "Siracusa",
    longitude: 15.2933324,
    latitude: 37.05991685,
  },
  {
    label: "Solarino",
    longitude: 15.11909961,
    latitude: 37.10124313,
  },
  {
    label: "Sortino",
    longitude: 15.0328349,
    latitude: 37.16256874,
  },
  {
    label: "Portopalo di Capo Passero",
    longitude: 15.13396638,
    latitude: 36.68332543,
  },
  {
    label: "Priolo Gargallo",
    longitude: 15.18829357,
    latitude: 37.15941965,
  },
  {
    label: "Aggius",
    longitude: 9.06392097,
    latitude: 40.92880745,
  },
  {
    label: "Alà dei Sardi",
    longitude: 9.32917825,
    latitude: 40.65069405,
  },
  {
    label: "Alghero",
    longitude: 8.32608134,
    latitude: 40.54843552,
  },
  {
    label: "Anela",
    longitude: 9.05666422,
    latitude: 40.44354491,
  },
  {
    label: "Ardara",
    longitude: 8.80926358,
    latitude: 40.6238864,
  },
  {
    label: "Arzachena",
    longitude: 9.38847706,
    latitude: 41.07937642,
  },
  {
    label: "Banari",
    longitude: 8.69753059,
    latitude: 40.57197633,
  },
  {
    label: "Benetutti",
    longitude: 9.16828273,
    latitude: 40.45579466,
  },
  {
    label: "Berchidda",
    longitude: 9.16400704,
    latitude: 40.78635359,
  },
  {
    label: "Bessude",
    longitude: 8.72722075,
    latitude: 40.5556799,
  },
  {
    label: "Bonnanaro",
    longitude: 8.76638326,
    latitude: 40.5324636,
  },
  {
    label: "Bono",
    longitude: 9.02975231,
    latitude: 40.41690678,
  },
  {
    label: "Bonorva",
    longitude: 8.76853126,
    latitude: 40.41698096,
  },
  {
    label: "Bortigiadas",
    longitude: 9.04327192,
    latitude: 40.89144536,
  },
  {
    label: "Borutta",
    longitude: 8.7437418,
    latitude: 40.52227636,
  },
  {
    label: "Bottidda",
    longitude: 9.0069729,
    latitude: 40.39066943,
  },
  {
    label: "Buddusò",
    longitude: 9.26086355,
    latitude: 40.57857057,
  },
  {
    label: "Bultei",
    longitude: 9.06148663,
    latitude: 40.45707527,
  },
  {
    label: "Bulzi",
    longitude: 8.82973366,
    latitude: 40.84804202,
  },
  {
    label: "Burgos",
    longitude: 8.99368536,
    latitude: 40.39061632,
  },
  {
    label: "Calangianus",
    longitude: 9.19233471,
    latitude: 40.92314505,
  },
  {
    label: "Cargeghe",
    longitude: 8.61606937,
    latitude: 40.66755094,
  },
  {
    label: "Castelsardo",
    longitude: 8.71333007,
    latitude: 40.91582154,
  },
  {
    label: "Cheremule",
    longitude: 8.72514048,
    latitude: 40.50610251,
  },
  {
    label: "Chiaramonti",
    longitude: 8.82000085,
    latitude: 40.75181945,
  },
  {
    label: "Codrongianos",
    longitude: 8.68009951,
    latitude: 40.65809986,
  },
  {
    label: "Cossoine",
    longitude: 8.71259249,
    latitude: 40.43143046,
  },
  {
    label: "Esporlatitudeu",
    longitude: 8.99065916,
    latitude: 40.38598158,
  },
  {
    label: "Florinas",
    longitude: 8.665701,
    latitude: 40.64793049,
  },
  {
    label: "Giave",
    longitude: 8.7505426,
    latitude: 40.45201666,
  },
  {
    label: "Illorai",
    longitude: 9.0021059,
    latitude: 40.35444054,
  },
  {
    label: "Ittireddu",
    longitude: 8.90205956,
    latitude: 40.54399048,
  },
  {
    label: "Ittiri",
    longitude: 8.56880739,
    latitude: 40.59413859,
  },
  {
    label: "Laerru",
    longitude: 8.83597569,
    latitude: 40.81824972,
  },
  {
    label: "La Maddalena",
    longitude: 9.4066075,
    latitude: 41.21292425,
  },
  {
    label: "Luogosanto",
    longitude: 9.20552532,
    latitude: 41.0506433,
  },
  {
    label: "Luras",
    longitude: 9.1750081,
    latitude: 40.93619412,
  },
  {
    label: "Mara",
    longitude: 8.63690581,
    latitude: 40.41070077,
  },
  {
    label: "Martis",
    longitude: 8.80866255,
    latitude: 40.7788928,
  },
  {
    label: "Monteleone Rocca Doria",
    longitude: 8.55993756,
    latitude: 40.47187701,
  },
  {
    label: "Monti",
    longitude: 9.32588332,
    latitude: 40.80514851,
  },
  {
    label: "Mores",
    longitude: 8.833674,
    latitude: 40.54944198,
  },
  {
    label: "Muros",
    longitude: 8.61529031,
    latitude: 40.67910589,
  },
  {
    label: "Nughedu San Nicolò",
    longitude: 9.02206431,
    latitude: 40.55626911,
  },
  {
    label: "Nule",
    longitude: 9.1872891,
    latitude: 40.46090607,
  },
  {
    label: "Nulvi",
    longitude: 8.74457384,
    latitude: 40.7805803,
  },
  {
    label: "Olbia",
    longitude: 9.50486158,
    latitude: 40.92318242,
  },
  {
    label: "Olmedo",
    longitude: 8.38262067,
    latitude: 40.65003781,
  },
  {
    label: "Oschiri",
    longitude: 9.09838673,
    latitude: 40.7194813,
  },
  {
    label: "Osilo",
    longitude: 8.67132565,
    latitude: 40.74402374,
  },
  {
    label: "Ossi",
    longitude: 8.59067388,
    latitude: 40.6763426,
  },
  {
    label: "Ozieri",
    longitude: 8.99838131,
    latitude: 40.58497586,
  },
  {
    label: "Padria",
    longitude: 8.63044015,
    latitude: 40.39800657,
  },
  {
    label: "Palau",
    longitude: 9.38302871,
    latitude: 41.17768674,
  },
  {
    label: "Pattada",
    longitude: 9.11210268,
    latitude: 40.58281193,
  },
  {
    label: "Perfugas",
    longitude: 8.88347308,
    latitude: 40.83024155,
  },
  {
    label: "Ploaghe",
    longitude: 8.74930405,
    latitude: 40.66573266,
  },
  {
    label: "Porto Torres",
    longitude: 8.40122823,
    latitude: 40.83722955,
  },
  {
    label: "Pozzomaggiore",
    longitude: 8.65657616,
    latitude: 40.39932098,
  },
  {
    label: "Putifigari",
    longitude: 8.46065767,
    latitude: 40.56251422,
  },
  {
    label: "Romana",
    longitude: 8.58655605,
    latitude: 40.48434729,
  },
  {
    label: "Aglientu",
    longitude: 9.11300677,
    latitude: 41.08161989,
  },
  {
    label: "Santa Teresa Gallura",
    longitude: 9.18836077,
    latitude: 41.23983449,
  },
  {
    label: "Sassari",
    longitude: 8.55966713,
    latitude: 40.72667657,
  },
  {
    label: "Sedini",
    longitude: 8.8171572,
    latitude: 40.85017734,
  },
  {
    label: "Semestene",
    longitude: 8.72611797,
    latitude: 40.39816646,
  },
  {
    label: "Sennori",
    longitude: 8.59526497,
    latitude: 40.7881437,
  },
  {
    label: "Siligo",
    longitude: 8.72556673,
    latitude: 40.57724022,
  },
  {
    label: "Sorso",
    longitude: 8.57785537,
    latitude: 40.79697664,
  },
  {
    label: "Tempio Pausania",
    longitude: 9.10323451,
    latitude: 40.90320323,
  },
  {
    label: "Thiesi",
    longitude: 8.72058008,
    latitude: 40.52201526,
  },
  {
    label: "Tissi",
    longitude: 8.56237292,
    latitude: 40.67874659,
  },
  {
    label: "Torralba",
    longitude: 8.76198049,
    latitude: 40.51425133,
  },
  {
    label: "Trinità d'Agultu e Vignola",
    longitude: 8.91371219,
    latitude: 40.98563737,
  },
  {
    label: "Tula",
    longitude: 8.98668427,
    latitude: 40.73372886,
  },
  {
    label: "Uri",
    longitude: 8.48650236,
    latitude: 40.63791739,
  },
  {
    label: "Usini",
    longitude: 8.54056713,
    latitude: 40.66371528,
  },
  {
    label: "Villanova Monteleone",
    longitude: 8.47038149,
    latitude: 40.5035733,
  },
  {
    label: "Valledoria",
    longitude: 8.82245483,
    latitude: 40.92775535,
  },
  {
    label: "Telti",
    longitude: 9.35246713,
    latitude: 40.87610644,
  },
  {
    label: "Badesi",
    longitude: 8.87914774,
    latitude: 40.96777523,
  },
  {
    label: "Viddalba",
    longitude: 8.89457549,
    latitude: 40.91705613,
  },
  {
    label: "Golfo Aranci",
    longitude: 9.62236691,
    latitude: 41.00152384,
  },
  {
    label: "Loiri Porto San Paolo",
    longitude: 9.49836273,
    latitude: 40.8419132,
  },
  {
    label: "Sant'Antonio di Gallura",
    longitude: 9.30130196,
    latitude: 40.99103246,
  },
  {
    label: "Tergu",
    longitude: 8.7124082,
    latitude: 40.86290753,
  },
  {
    label: "Santa Maria Coghinas",
    longitude: 8.86309214,
    latitude: 40.90429476,
  },
  {
    label: "Erula",
    longitude: 8.94168684,
    latitude: 40.79133118,
  },
  {
    label: "Stintino",
    longitude: 8.22629385,
    latitude: 40.93699402,
  },
  {
    label: "Padru",
    longitude: 9.52254209,
    latitude: 40.76705051,
  },
  {
    label: "Budoni",
    longitude: 9.69975634,
    latitude: 40.70671935,
  },
  {
    label: "San Teodoro",
    longitude: 9.67191215,
    latitude: 40.7712228,
  },
  {
    label: "Aritzo",
    longitude: 9.19794984,
    latitude: 39.95938538,
  },
  {
    label: "Arzana",
    longitude: 9.52836116,
    latitude: 39.91850362,
  },
  {
    label: "Atzara",
    longitude: 9.07629541,
    latitude: 39.99167325,
  },
  {
    label: "Austis",
    longitude: 9.08850029,
    latitude: 40.07104643,
  },
  {
    label: "Bari Sardo",
    longitude: 9.64580126,
    latitude: 39.84012545,
  },
  {
    label: "Baunei",
    longitude: 9.66358993,
    latitude: 40.03178146,
  },
  {
    label: "Belvì",
    longitude: 9.18408371,
    latitude: 39.96179317,
  },
  {
    label: "Birori",
    longitude: 8.81549457,
    latitude: 40.2660499,
  },
  {
    label: "Bitti",
    longitude: 9.38148118,
    latitude: 40.4750622,
  },
  {
    label: "Bolotana",
    longitude: 8.95889386,
    latitude: 40.32786442,
  },
  {
    label: "Borore",
    longitude: 8.80331732,
    latitude: 40.2154116,
  },
  {
    label: "Bortigali",
    longitude: 8.83677489,
    latitude: 40.28295387,
  },
  {
    label: "Desulo",
    longitude: 9.22870581,
    latitude: 40.01122541,
  },
  {
    label: "Dorgali",
    longitude: 9.58812096,
    latitude: 40.28928609,
  },
  {
    label: "Dualchi",
    longitude: 8.90022839,
    latitude: 40.23037556,
  },
  {
    label: "Elini",
    longitude: 9.53114083,
    latitude: 39.90000155,
  },
  {
    label: "Fonni",
    longitude: 9.25241157,
    latitude: 40.12002775,
  },
  {
    label: "Gadoni",
    longitude: 9.18309314,
    latitude: 39.91270924,
  },
  {
    label: "Gairo",
    longitude: 9.5061878,
    latitude: 39.84762882,
  },
  {
    label: "Galtellì",
    longitude: 9.61671055,
    latitude: 40.38662593,
  },
  {
    label: "Gavoi",
    longitude: 9.19497917,
    latitude: 40.1587997,
  },
  {
    label: "Girasole",
    longitude: 9.66134528,
    latitude: 39.95336534,
  },
  {
    label: "Ilbono",
    longitude: 9.54177763,
    latitude: 39.89374018,
  },
  {
    label: "Irgoli",
    longitude: 9.630527,
    latitude: 40.40931635,
  },
  {
    label: "Jerzu",
    longitude: 9.51851706,
    latitude: 39.79284428,
  },
  {
    label: "Lanusei",
    longitude: 9.54229961,
    latitude: 39.87682776,
  },
  {
    label: "Lei",
    longitude: 8.92023143,
    latitude: 40.307525,
  },
  {
    label: "Loceri",
    longitude: 9.58207008,
    latitude: 39.8567128,
  },
  {
    label: "Loculi",
    longitude: 9.61104893,
    latitude: 40.40674552,
  },
  {
    label: "Lodè",
    longitude: 9.5395666,
    latitude: 40.59132135,
  },
  {
    label: "Lotzorai",
    longitude: 9.66253025,
    latitude: 39.96938761,
  },
  {
    label: "Lula",
    longitude: 9.48504099,
    latitude: 40.47129053,
  },
  {
    label: "Macomer",
    longitude: 8.78250254,
    latitude: 40.26504905,
  },
  {
    label: "Mamoiada",
    longitude: 9.283939,
    latitude: 40.21438631,
  },
  {
    label: "Meana Sardo",
    longitude: 9.068869,
    latitude: 39.94761193,
  },
  {
    label: "Noragugume",
    longitude: 8.9199679,
    latitude: 40.22475175,
  },
  {
    label: "Nuoro",
    longitude: 9.33029639,
    latitude: 40.32318834,
  },
  {
    label: "Oliena",
    longitude: 9.40594815,
    latitude: 40.27132245,
  },
  {
    label: "Ollolai",
    longitude: 9.1812239,
    latitude: 40.16919014,
  },
  {
    label: "Olzai",
    longitude: 9.1488792,
    latitude: 40.18284857,
  },
  {
    label: "Onanì",
    longitude: 9.44086593,
    latitude: 40.48438914,
  },
  {
    label: "Onifai",
    longitude: 9.65132236,
    latitude: 40.40719675,
  },
  {
    label: "Oniferi",
    longitude: 9.17025595,
    latitude: 40.27218119,
  },
  {
    label: "Orani",
    longitude: 9.17745617,
    latitude: 40.25006515,
  },
  {
    label: "Orgosolo",
    longitude: 9.355513,
    latitude: 40.20531708,
  },
  {
    label: "Orosei",
    longitude: 9.6971319,
    latitude: 40.37860068,
  },
  {
    label: "Orotelli",
    longitude: 9.11227876,
    latitude: 40.30281646,
  },
  {
    label: "Ortueri",
    longitude: 8.98548427,
    latitude: 40.0360808,
  },
  {
    label: "Orune",
    longitude: 9.36973906,
    latitude: 40.40613244,
  },
  {
    label: "Osidda",
    longitude: 9.21879347,
    latitude: 40.5241536,
  },
  {
    label: "Osini",
    longitude: 9.49729144,
    latitude: 39.82199597,
  },
  {
    label: "Ottana",
    longitude: 9.04544422,
    latitude: 40.23499206,
  },
  {
    label: "Ovodda",
    longitude: 9.16096608,
    latitude: 40.09618886,
  },
  {
    label: "Perdasdefogu",
    longitude: 9.44161471,
    latitude: 39.67797681,
  },
  {
    label: "Posada",
    longitude: 9.71799746,
    latitude: 40.63326084,
  },
  {
    label: "Sarule",
    longitude: 9.16599769,
    latitude: 40.22896356,
  },
  {
    label: "Silanus",
    longitude: 8.88945016,
    latitude: 40.2904498,
  },
  {
    label: "Sindia",
    longitude: 8.65939076,
    latitude: 40.29607418,
  },
  {
    label: "Siniscola",
    longitude: 9.69428347,
    latitude: 40.57500849,
  },
  {
    label: "Sorgono",
    longitude: 9.1037116,
    latitude: 40.0277987,
  },
  {
    label: "Talana",
    longitude: 9.49692038,
    latitude: 40.04116818,
  },
  {
    label: "Tertenia",
    longitude: 9.57723938,
    latitude: 39.69697794,
  },
  {
    label: "Teti",
    longitude: 9.1192594,
    latitude: 40.09643695,
  },
  {
    label: "Tiana",
    longitude: 9.14888448,
    latitude: 40.06739505,
  },
  {
    label: "Tonara",
    longitude: 9.17357341,
    latitude: 40.0247705,
  },
  {
    label: "Torpè",
    longitude: 9.67845413,
    latitude: 40.62868,
  },
  {
    label: "Tortolì",
    longitude: 9.6572995,
    latitude: 39.92543146,
  },
  {
    label: "Triei",
    longitude: 9.64122065,
    latitude: 40.03692502,
  },
  {
    label: "Ulassai",
    longitude: 9.49990537,
    latitude: 39.811571,
  },
  {
    label: "Urzulei",
    longitude: 9.5073548,
    latitude: 40.09360817,
  },
  {
    label: "Ussassai",
    longitude: 9.39347874,
    latitude: 39.810562,
  },
  {
    label: "Villagrande Strisaili",
    longitude: 9.50642291,
    latitude: 39.96182375,
  },
  {
    label: "Cardedu",
    longitude: 9.62540588,
    latitude: 39.79580077,
  },
  {
    label: "Lodine",
    longitude: 9.21935687,
    latitude: 40.14776166,
  },
  {
    label: "Assemini",
    longitude: 9.0062327,
    latitude: 39.28961728,
  },
  {
    label: "Cagliari",
    longitude: 9.11061631,
    latitude: 39.21531191,
  },
  {
    label: "Capoterra",
    longitude: 8.97449734,
    latitude: 39.17394789,
  },
  {
    label: "Decimomannu",
    longitude: 8.96564789,
    latitude: 39.31099768,
  },
  {
    label: "Maracalagonis",
    longitude: 9.22819506,
    latitude: 39.28523164,
  },
  {
    label: "Pula",
    longitude: 9.00076992,
    latitude: 39.0107024,
  },
  {
    label: "Quartu Sant'Elena",
    longitude: 9.18534391,
    latitude: 39.24108358,
  },
  {
    label: "Sarroch",
    longitude: 9.01116261,
    latitude: 39.06353751,
  },
  {
    label: "Selargius",
    longitude: 9.16555545,
    latitude: 39.25370354,
  },
  {
    label: "Sestu",
    longitude: 9.09194598,
    latitude: 39.30031871,
  },
  {
    label: "Settimo San Pietro",
    longitude: 9.18402554,
    latitude: 39.29178454,
  },
  {
    label: "Sinnai",
    longitude: 9.20553239,
    latitude: 39.29749167,
  },
  {
    label: "Uta",
    longitude: 8.95297842,
    latitude: 39.29305859,
  },
  {
    label: "Villa San Pietro",
    longitude: 8.9950801,
    latitude: 39.03382938,
  },
  {
    label: "Quartucciu",
    longitude: 9.1800272,
    latitude: 39.25427575,
  },
  {
    label: "Elmas",
    longitude: 9.04884749,
    latitude: 39.27088656,
  },
  {
    label: "Monserrato",
    longitude: 9.14343861,
    latitude: 39.25407412,
  },
  {
    label: "Andreis",
    longitude: 12.61456216,
    latitude: 46.20181387,
  },
  {
    label: "Arba",
    longitude: 12.79076084,
    latitude: 46.14619163,
  },
  {
    label: "Aviano",
    longitude: 12.5881786,
    latitude: 46.06720286,
  },
  {
    label: "Azzano Decimo",
    longitude: 12.71413134,
    latitude: 45.88124317,
  },
  {
    label: "Barcis",
    longitude: 12.56001555,
    latitude: 46.1908595,
  },
  {
    label: "Brugnera",
    longitude: 12.53679631,
    latitude: 45.89918867,
  },
  {
    label: "Budoia",
    longitude: 12.53264931,
    latitude: 46.04292467,
  },
  {
    label: "Caneva",
    longitude: 12.44902986,
    latitude: 45.96855486,
  },
  {
    label: "Casarsa della Delizia",
    longitude: 12.84014735,
    latitude: 45.95374624,
  },
  {
    label: "Castelnovo del Friuli",
    longitude: 12.90325046,
    latitude: 46.19990582,
  },
  {
    label: "Cavasso Nuovo",
    longitude: 12.76881359,
    latitude: 46.19403313,
  },
  {
    label: "Chions",
    longitude: 12.75234863,
    latitude: 45.86229625,
  },
  {
    label: "Cimolais",
    longitude: 12.43779784,
    latitude: 46.28808436,
  },
  {
    label: "Claut",
    longitude: 12.51391747,
    latitude: 46.26713878,
  },
  {
    label: "Clauzetto",
    longitude: 12.9149354,
    latitude: 46.22905519,
  },
  {
    label: "Cordenons",
    longitude: 12.70715102,
    latitude: 45.98849264,
  },
  {
    label: "Cordovado",
    longitude: 12.88212178,
    latitude: 45.85013098,
  },
  {
    label: "Erto e Casso",
    longitude: 12.37236133,
    latitude: 46.2788934,
  },
  {
    label: "Fanna",
    longitude: 12.75427142,
    latitude: 46.18767453,
  },
  {
    label: "Fiume Veneto",
    longitude: 12.73686953,
    latitude: 45.92802148,
  },
  {
    label: "Fontanafredda",
    longitude: 12.54779912,
    latitude: 45.98891061,
  },
  {
    label: "Frisanco",
    longitude: 12.72383344,
    latitude: 46.21259287,
  },
  {
    label: "Maniago",
    longitude: 12.7081671,
    latitude: 46.17104534,
  },
  {
    label: "Meduno",
    longitude: 12.78652713,
    latitude: 46.21673311,
  },
  {
    label: "Montereale Valcellina",
    longitude: 12.65605978,
    latitude: 46.15517618,
  },
  {
    label: "Morsano al Tagliamento",
    longitude: 12.92903161,
    latitude: 45.85783419,
  },
  {
    label: "Pasiano di Pordenone",
    longitude: 12.62741517,
    latitude: 45.85132778,
  },
  {
    label: "Pinzano al Tagliamento",
    longitude: 12.94600564,
    latitude: 46.18313878,
  },
  {
    label: "Polcenigo",
    longitude: 12.50174056,
    latitude: 46.0305742,
  },
  {
    label: "Porcia",
    longitude: 12.61375455,
    latitude: 45.95805568,
  },
  {
    label: "Pordenone",
    longitude: 12.66002911,
    latitude: 45.95443546,
  },
  {
    label: "Prata di Pordenone",
    longitude: 12.59726391,
    latitude: 45.89393848,
  },
  {
    label: "Pravisdomini",
    longitude: 12.69215327,
    latitude: 45.81755711,
  },
  {
    label: "Roveredo in Piano",
    longitude: 12.61755092,
    latitude: 46.00954116,
  },
  {
    label: "Sacile",
    longitude: 12.50303667,
    latitude: 45.95427679,
  },
  {
    label: "San Giorgio della Richinvelda",
    longitude: 12.86774016,
    latitude: 46.04540931,
  },
  {
    label: "San Martino al Tagliamento",
    longitude: 12.86384938,
    latitude: 46.02082205,
  },
  {
    label: "San Quirino",
    longitude: 12.680307,
    latitude: 46.03636768,
  },
  {
    label: "San Vito al Tagliamento",
    longitude: 12.85473667,
    latitude: 45.91497245,
  },
  {
    label: "Sequals",
    longitude: 12.82658083,
    latitude: 46.16621461,
  },
  {
    label: "Sesto al Reghena",
    longitude: 12.81651528,
    latitude: 45.84857474,
  },
  {
    label: "Spilimbergo",
    longitude: 12.90567156,
    latitude: 46.11211858,
  },
  {
    label: "Tramonti di Sopra",
    longitude: 12.78960725,
    latitude: 46.30996763,
  },
  {
    label: "Tramonti di Sotto",
    longitude: 12.79581629,
    latitude: 46.28495397,
  },
  {
    label: "Travesio",
    longitude: 12.87197276,
    latitude: 46.19647435,
  },
  {
    label: "Vito d'Asio",
    longitude: 12.9585332,
    latitude: 46.2333357,
  },
  {
    label: "Vivaro",
    longitude: 12.77641497,
    latitude: 46.07419348,
  },
  {
    label: "Zoppola",
    longitude: 12.77039751,
    latitude: 45.96638834,
  },
  {
    label: "Vajont",
    longitude: 12.69803146,
    latitude: 46.14660778,
  },
  {
    label: "Valvasone Arzene",
    longitude: 12.8477,
    latitude: 46.0022,
  },
  {
    label: "Acquaviva d'Isernia",
    longitude: 14.14986101,
    latitude: 41.67210928,
  },
  {
    label: "Agnone",
    longitude: 14.3665722,
    latitude: 41.80610155,
  },
  {
    label: "Bagnoli del Trigno",
    longitude: 14.45823459,
    latitude: 41.7046171,
  },
  {
    label: "Belmonte del Sannio",
    longitude: 14.42348554,
    latitude: 41.82509574,
  },
  {
    label: "Cantalupo nel Sannio",
    longitude: 14.39240437,
    latitude: 41.52197024,
  },
  {
    label: "Capracotta",
    longitude: 14.26424567,
    latitude: 41.83385194,
  },
  {
    label: "Carovilli",
    longitude: 14.29426695,
    latitude: 41.71321389,
  },
  {
    label: "Carpinone",
    longitude: 14.32380551,
    latitude: 41.5915009,
  },
  {
    label: "Castel del Giudice",
    longitude: 14.23147783,
    latitude: 41.85498249,
  },
  {
    label: "Castelpetroso",
    longitude: 14.34525728,
    latitude: 41.55952579,
  },
  {
    label: "Castelpizzuto",
    longitude: 14.29266424,
    latitude: 41.52139914,
  },
  {
    label: "Castel San Vincenzo",
    longitude: 14.06252445,
    latitude: 41.65503525,
  },
  {
    label: "Castelverrino",
    longitude: 14.39758991,
    latitude: 41.7682335,
  },
  {
    label: "Cerro al Volturno",
    longitude: 14.10315455,
    latitude: 41.65407513,
  },
  {
    label: "Chiauci",
    longitude: 14.38553217,
    latitude: 41.6780591,
  },
  {
    label: "Civitanova del Sannio",
    longitude: 14.40421907,
    latitude: 41.66770571,
  },
  {
    label: "Colli a Volturno",
    longitude: 14.10391787,
    latitude: 41.59903196,
  },
  {
    label: "Conca Casale",
    longitude: 14.00670216,
    latitude: 41.49445905,
  },
  {
    label: "Filignano",
    longitude: 14.05630859,
    latitude: 41.54512473,
  },
  {
    label: "Forlì del Sannio",
    longitude: 14.18119438,
    latitude: 41.69525415,
  },
  {
    label: "Fornelli",
    longitude: 14.14095416,
    latitude: 41.60634666,
  },
  {
    label: "Frosolone",
    longitude: 14.44913437,
    latitude: 41.60153764,
  },
  {
    label: "Isernia",
    longitude: 14.22575426,
    latitude: 41.58800826,
  },
  {
    label: "Longano",
    longitude: 14.24570854,
    latitude: 41.5217329,
  },
  {
    label: "Macchia d'Isernia",
    longitude: 14.16746944,
    latitude: 41.56280688,
  },
  {
    label: "Macchiagodena",
    longitude: 14.40583228,
    latitude: 41.55925991,
  },
  {
    label: "Miranda",
    longitude: 14.24622838,
    latitude: 41.64389506,
  },
  {
    label: "Montaquila",
    longitude: 14.11670607,
    latitude: 41.56640732,
  },
  {
    label: "Montenero Val Cocchiara",
    longitude: 14.06618342,
    latitude: 41.71473256,
  },
  {
    label: "Monteroduni",
    longitude: 14.17571112,
    latitude: 41.52279824,
  },
  {
    label: "Pesche",
    longitude: 14.2816363,
    latitude: 41.61124169,
  },
  {
    label: "Pescolanciano",
    longitude: 14.33683957,
    latitude: 41.67885835,
  },
  {
    label: "Pescopennataro",
    longitude: 14.29484726,
    latitude: 41.8782473,
  },
  {
    label: "Pettoranello del Molise",
    longitude: 14.27697599,
    latitude: 41.57357774,
  },
  {
    label: "Pietrabbondante",
    longitude: 14.38776413,
    latitude: 41.748699,
  },
  {
    label: "Pizzone",
    longitude: 14.03575095,
    latitude: 41.66677586,
  },
  {
    label: "Poggio Sannita",
    longitude: 14.41318446,
    latitude: 41.7786101,
  },
  {
    label: "Pozzilli",
    longitude: 14.061922,
    latitude: 41.51146177,
  },
  {
    label: "Rionero Sannitico",
    longitude: 14.13993804,
    latitude: 41.71224628,
  },
  {
    label: "Roccamandolfi",
    longitude: 14.34950282,
    latitude: 41.49291156,
  },
  {
    label: "Roccasicura",
    longitude: 14.23174503,
    latitude: 41.69820278,
  },
  {
    label: "Rocchetta a Volturno",
    longitude: 14.08588727,
    latitude: 41.62429524,
  },
  {
    label: "San Pietro Avellana",
    longitude: 14.18348275,
    latitude: 41.78881848,
  },
  {
    label: "Sant'Agapito",
    longitude: 14.22177172,
    latitude: 41.5437432,
  },
  {
    label: "Santa Maria del Molise",
    longitude: 14.37054743,
    latitude: 41.5527034,
  },
  {
    label: "Sant'Angelo del Pesco",
    longitude: 14.25578775,
    latitude: 41.88316451,
  },
  {
    label: "Sant'Elena Sannita",
    longitude: 14.47195626,
    latitude: 41.57673382,
  },
  {
    label: "Scapoli",
    longitude: 14.05880908,
    latitude: 41.61530062,
  },
  {
    label: "Sessano del Molise",
    longitude: 14.33197303,
    latitude: 41.63835247,
  },
  {
    label: "Sesto Campano",
    longitude: 14.07735655,
    latitude: 41.4210853,
  },
  {
    label: "Vastogirardi",
    longitude: 14.25942524,
    latitude: 41.77423652,
  },
  {
    label: "Venafro",
    longitude: 14.04510944,
    latitude: 41.4861914,
  },
  {
    label: "Abbasanta",
    longitude: 8.82102235,
    latitude: 40.12390307,
  },
  {
    label: "Aidomaggiore",
    longitude: 8.856434,
    latitude: 40.17186399,
  },
  {
    label: "Albagiara",
    longitude: 8.86062815,
    latitude: 39.78826485,
  },
  {
    label: "Ales",
    longitude: 8.81584466,
    latitude: 39.76922741,
  },
  {
    label: "Allai",
    longitude: 8.85951425,
    latitude: 39.95793491,
  },
  {
    label: "Arborea",
    longitude: 8.58218011,
    latitude: 39.77345146,
  },
  {
    label: "Ardauli",
    longitude: 8.91123069,
    latitude: 40.08728814,
  },
  {
    label: "Assolo",
    longitude: 8.915627,
    latitude: 39.81074085,
  },
  {
    label: "Asuni",
    longitude: 8.94773504,
    latitude: 39.87191568,
  },
  {
    label: "Baradili",
    longitude: 8.89760286,
    latitude: 39.72251033,
  },
  {
    label: "Baratili San Pietro",
    longitude: 8.55496644,
    latitude: 39.99330353,
  },
  {
    label: "Baressa",
    longitude: 8.87404906,
    latitude: 39.71468132,
  },
  {
    label: "Bauladu",
    longitude: 8.67136806,
    latitude: 40.01968427,
  },
  {
    label: "Bidonì",
    longitude: 8.9351206,
    latitude: 40.11300422,
  },
  {
    label: "Bonarcado",
    longitude: 8.65453282,
    latitude: 40.10082014,
  },
  {
    label: "Boroneddu",
    longitude: 8.86988195,
    latitude: 40.11273716,
  },
  {
    label: "Busachi",
    longitude: 8.89807635,
    latitude: 40.03328904,
  },
  {
    label: "Cabras",
    longitude: 8.53119235,
    latitude: 39.92991246,
  },
  {
    label: "Cuglieri",
    longitude: 8.5682177,
    latitude: 40.18978261,
  },
  {
    label: "Fordongianus",
    longitude: 8.8079653,
    latitude: 39.99491588,
  },
  {
    label: "Ghilarza",
    longitude: 8.8346932,
    latitude: 40.1214624,
  },
  {
    label: "Gonnoscodina",
    longitude: 8.83584438,
    latitude: 39.70069319,
  },
  {
    label: "Gonnosnò",
    longitude: 8.87190545,
    latitude: 39.76003369,
  },
  {
    label: "Gonnostramatza",
    longitude: 8.83143099,
    latitude: 39.68010941,
  },
  {
    label: "Marrubiu",
    longitude: 8.63626196,
    latitude: 39.74888134,
  },
  {
    label: "Masullas",
    longitude: 8.77925361,
    latitude: 39.70146376,
  },
  {
    label: "Milis",
    longitude: 8.6371819,
    latitude: 40.04930783,
  },
  {
    label: "Mogorella",
    longitude: 8.8582652,
    latitude: 39.8631237,
  },
  {
    label: "Mogoro",
    longitude: 8.77457552,
    latitude: 39.68410563,
  },
  {
    label: "Morgongiori",
    longitude: 8.76772542,
    latitude: 39.74615282,
  },
  {
    label: "Narbolia",
    longitude: 8.57500076,
    latitude: 40.04770998,
  },
  {
    label: "Neoneli",
    longitude: 8.94401269,
    latitude: 40.06150392,
  },
  {
    label: "Norbello",
    longitude: 8.83225005,
    latitude: 40.13395535,
  },
  {
    label: "Nughedu Santa Vittoria",
    longitude: 8.95429692,
    latitude: 40.10206996,
  },
  {
    label: "Nurachi",
    longitude: 8.53991438,
    latitude: 39.97379024,
  },
  {
    label: "Nureci",
    longitude: 8.97397217,
    latitude: 39.82410064,
  },
  {
    label: "Ollastra",
    longitude: 8.7346681,
    latitude: 39.95122575,
  },
  {
    label: "Oristano",
    longitude: 8.59118315,
    latitude: 39.90381075,
  },
  {
    label: "Palmas Arborea",
    longitude: 8.64568223,
    latitude: 39.87599627,
  },
  {
    label: "Pau",
    longitude: 8.80309813,
    latitude: 39.79271818,
  },
  {
    label: "Paulilatitudeino",
    longitude: 8.76049414,
    latitude: 40.08656495,
  },
  {
    label: "Pompu",
    longitude: 8.79571898,
    latitude: 39.72488017,
  },
  {
    label: "Riola Sardo",
    longitude: 8.53847299,
    latitude: 39.99654532,
  },
  {
    label: "Ruinas",
    longitude: 8.89641753,
    latitude: 39.90577058,
  },
  {
    label: "Samugheo",
    longitude: 8.93960557,
    latitude: 39.94719111,
  },
  {
    label: "San Nicolò d'Arcidano",
    longitude: 8.64669205,
    latitude: 39.68528787,
  },
  {
    label: "Santa Giusta",
    longitude: 8.60863024,
    latitude: 39.87845035,
  },
  {
    label: "Villa Sant'Antonio",
    longitude: 8.9012847,
    latitude: 39.85815513,
  },
  {
    label: "Santu Lussurgiu",
    longitude: 8.65196647,
    latitude: 40.13731299,
  },
  {
    label: "San Vero Milis",
    longitude: 8.59915025,
    latitude: 40.0127927,
  },
  {
    label: "Scano di Montiferro",
    longitude: 8.58351845,
    latitude: 40.21682334,
  },
  {
    label: "Sedilo",
    longitude: 8.92091693,
    latitude: 40.17485387,
  },
  {
    label: "Seneghe",
    longitude: 8.61146801,
    latitude: 40.08064293,
  },
  {
    label: "Senis",
    longitude: 8.93954859,
    latitude: 39.82163704,
  },
  {
    label: "Sennariolo",
    longitude: 8.55628704,
    latitude: 40.21243637,
  },
  {
    label: "Siamaggiore",
    longitude: 8.63615437,
    latitude: 39.94874157,
  },
  {
    label: "Siamanna",
    longitude: 8.75961834,
    latitude: 39.92019454,
  },
  {
    label: "Simala",
    longitude: 8.82962503,
    latitude: 39.71928821,
  },
  {
    label: "Simaxis",
    longitude: 8.69090471,
    latitude: 39.930337,
  },
  {
    label: "Sini",
    longitude: 8.90636308,
    latitude: 39.75247187,
  },
  {
    label: "Siris",
    longitude: 8.7748754,
    latitude: 39.71281218,
  },
  {
    label: "Solarussa",
    longitude: 8.67075837,
    latitude: 39.95315767,
  },
  {
    label: "Sorradile",
    longitude: 8.93276947,
    latitude: 40.10664792,
  },
  {
    label: "Tadasuni",
    longitude: 8.88260572,
    latitude: 40.11069858,
  },
  {
    label: "Terralba",
    longitude: 8.63618393,
    latitude: 39.72020036,
  },
  {
    label: "Tramatza",
    longitude: 8.64862429,
    latitude: 40.00288031,
  },
  {
    label: "Tresnuraghes",
    longitude: 8.51882002,
    latitude: 40.25092504,
  },
  {
    label: "Ulà Tirso",
    longitude: 8.90542873,
    latitude: 40.04502134,
  },
  {
    label: "Uras",
    longitude: 8.70107597,
    latitude: 39.69835218,
  },
  {
    label: "Usellus",
    longitude: 8.85263281,
    latitude: 39.80828003,
  },
  {
    label: "Villanova Truschedu",
    longitude: 8.75256917,
    latitude: 39.98959369,
  },
  {
    label: "Villaurbana",
    longitude: 8.77810331,
    latitude: 39.88618034,
  },
  {
    label: "Villa Verde",
    longitude: 8.82102064,
    latitude: 39.79603586,
  },
  {
    label: "Zeddiani",
    longitude: 8.59548717,
    latitude: 39.98957536,
  },
  {
    label: "Zerfaliu",
    longitude: 8.70754907,
    latitude: 39.9580167,
  },
  {
    label: "Siapiccia",
    longitude: 8.76176243,
    latitude: 39.92642793,
  },
  {
    label: "Curcuris",
    longitude: 8.83141321,
    latitude: 39.74636188,
  },
  {
    label: "Soddì",
    longitude: 8.87719316,
    latitude: 40.12902116,
  },
  {
    label: "Bosa",
    longitude: 8.49783022,
    latitude: 40.29914877,
  },
  {
    label: "Flussio",
    longitude: 8.54151996,
    latitude: 40.26709307,
  },
  {
    label: "Laconi",
    longitude: 9.05223689,
    latitude: 39.8539383,
  },
  {
    label: "Magomadas",
    longitude: 8.52539906,
    latitude: 40.26420338,
  },
  {
    label: "Modolo",
    longitude: 8.52782133,
    latitude: 40.27408198,
  },
  {
    label: "Montresta",
    longitude: 8.49987434,
    latitude: 40.3737132,
  },
  {
    label: "Sagama",
    longitude: 8.5780936,
    latitude: 40.26099005,
  },
  {
    label: "Suni",
    longitude: 8.55027974,
    latitude: 40.27974185,
  },
  {
    label: "Tinnura",
    longitude: 8.54820696,
    latitude: 40.26924954,
  },
  {
    label: "Ailoche",
    longitude: 8.22037978,
    latitude: 45.69805689,
  },
  {
    label: "Andorno Micca",
    longitude: 8.05331781,
    latitude: 45.61556222,
  },
  {
    label: "Benna",
    longitude: 8.12515045,
    latitude: 45.51239521,
  },
  {
    label: "Biella",
    longitude: 8.05408217,
    latitude: 45.5665112,
  },
  {
    label: "Bioglio",
    longitude: 8.13026025,
    latitude: 45.60759081,
  },
  {
    label: "Borriana",
    longitude: 8.03958043,
    latitude: 45.50597172,
  },
  {
    label: "Brusnengo",
    longitude: 8.25253753,
    latitude: 45.596129,
  },
  {
    label: "Callabiana",
    longitude: 8.09073837,
    latitude: 45.63371909,
  },
  {
    label: "Camandona",
    longitude: 8.10001027,
    latitude: 45.641708,
  },
  {
    label: "Camburzano",
    longitude: 8.00331392,
    latitude: 45.54420854,
  },
  {
    label: "Candelo",
    longitude: 8.11270153,
    latitude: 45.5462984,
  },
  {
    label: "Caprile",
    longitude: 8.21297248,
    latitude: 45.69295392,
  },
  {
    label: "Casapinta",
    longitude: 8.19340071,
    latitude: 45.61503414,
  },
  {
    label: "Castelletto Cervo",
    longitude: 8.22520133,
    latitude: 45.51900248,
  },
  {
    label: "Cavaglià",
    longitude: 8.09349127,
    latitude: 45.4057615,
  },
  {
    label: "Cerreto Castello",
    longitude: 8.15448008,
    latitude: 45.56579068,
  },
  {
    label: "Cerrione",
    longitude: 8.07399473,
    latitude: 45.48090765,
  },
  {
    label: "Coggiola",
    longitude: 8.18516433,
    latitude: 45.68458119,
  },
  {
    label: "Cossato",
    longitude: 8.18059835,
    latitude: 45.57009628,
  },
  {
    label: "Crevacuore",
    longitude: 8.24712685,
    latitude: 45.68583543,
  },
  {
    label: "Curino",
    longitude: 8.23588835,
    latitude: 45.62759246,
  },
  {
    label: "Donato",
    longitude: 7.90758202,
    latitude: 45.52829168,
  },
  {
    label: "Dorzano",
    longitude: 8.09696969,
    latitude: 45.42521789,
  },
  {
    label: "Gaglianico",
    longitude: 8.07472381,
    latitude: 45.53940449,
  },
  {
    label: "Gifflenga",
    longitude: 8.2316414,
    latitude: 45.49327795,
  },
  {
    label: "Graglia",
    longitude: 7.98111742,
    latitude: 45.5578906,
  },
  {
    label: "Magnano",
    longitude: 8.00512714,
    latitude: 45.46270375,
  },
  {
    label: "Massazza",
    longitude: 8.16516301,
    latitude: 45.49207278,
  },
  {
    label: "Masserano",
    longitude: 8.22326903,
    latitude: 45.59569512,
  },
  {
    label: "Mezzana Mortigliengo",
    longitude: 8.18944665,
    latitude: 45.62596366,
  },
  {
    label: "Miagliano",
    longitude: 8.04377432,
    latitude: 45.61413517,
  },
  {
    label: "Mongrando",
    longitude: 8.0072471,
    latitude: 45.5192294,
  },
  {
    label: "Mottalciata",
    longitude: 8.20748723,
    latitude: 45.50864035,
  },
  {
    label: "Muzzano",
    longitude: 7.98649835,
    latitude: 45.56061025,
  },
  {
    label: "Netro",
    longitude: 7.94789744,
    latitude: 45.53803303,
  },
  {
    label: "Occhieppo Inferiore",
    longitude: 8.02256281,
    latitude: 45.55321879,
  },
  {
    label: "Occhieppo Superiore",
    longitude: 8.00471909,
    latitude: 45.56477793,
  },
  {
    label: "Pettinengo",
    longitude: 8.1053,
    latitude: 45.614,
  },
  {
    label: "Piatto",
    longitude: 8.13519326,
    latitude: 45.59180085,
  },
  {
    label: "Piedicavallo",
    longitude: 7.9560587,
    latitude: 45.6896143,
  },
  {
    label: "Pollone",
    longitude: 8.00093472,
    latitude: 45.58353694,
  },
  {
    label: "Ponderano",
    longitude: 8.05681027,
    latitude: 45.53855174,
  },
  {
    label: "Portula",
    longitude: 8.17908293,
    latitude: 45.67532016,
  },
  {
    label: "Pralungo",
    longitude: 8.03805645,
    latitude: 45.59093233,
  },
  {
    label: "Pray",
    longitude: 8.20872877,
    latitude: 45.67616746,
  },
  {
    label: "Quaregna",
    longitude: 8.16475846,
    latitude: 45.57635631,
  },
  {
    label: "Ronco Biellese",
    longitude: 8.09888176,
    latitude: 45.58161079,
  },
  {
    label: "Roppolo",
    longitude: 8.06865083,
    latitude: 45.42041129,
  },
  {
    label: "Rosazza",
    longitude: 7.97753399,
    latitude: 45.67613222,
  },
  {
    label: "Sagliano Micca",
    longitude: 8.0452823,
    latitude: 45.62281145,
  },
  {
    label: "Sala Biellese",
    longitude: 7.95549106,
    latitude: 45.50831749,
  },
  {
    label: "Salussola",
    longitude: 8.10888507,
    latitude: 45.44931124,
  },
  {
    label: "Sandigliano",
    longitude: 8.07594373,
    latitude: 45.52149102,
  },
  {
    label: "Soprana",
    longitude: 8.19848524,
    latitude: 45.63897801,
  },
  {
    label: "Sordevolo",
    longitude: 7.97528638,
    latitude: 45.57149665,
  },
  {
    label: "Sostegno",
    longitude: 8.26898954,
    latitude: 45.65353479,
  },
  {
    label: "Strona",
    longitude: 8.16927886,
    latitude: 45.6207049,
  },
  {
    label: "Tavigliano",
    longitude: 8.05319422,
    latitude: 45.62362698,
  },
  {
    label: "Ternengo",
    longitude: 8.11494286,
    latitude: 45.59011149,
  },
  {
    label: "Tollegno",
    longitude: 8.05325511,
    latitude: 45.59445387,
  },
  {
    label: "Torrazzo",
    longitude: 7.95539742,
    latitude: 45.49810141,
  },
  {
    label: "Trivero",
    longitude: 8.16114656,
    latitude: 45.66449726,
  },
  {
    label: "Valdengo",
    longitude: 8.13614989,
    latitude: 45.5711059,
  },
  {
    label: "Vallanzengo",
    longitude: 8.14829733,
    latitude: 45.60348752,
  },
  {
    label: "Valle Mosso",
    longitude: 8.14241419,
    latitude: 45.63512144,
  },
  {
    label: "Valle San Nicolao",
    longitude: 8.14077093,
    latitude: 45.6070336,
  },
  {
    label: "Veglio",
    longitude: 8.11414042,
    latitude: 45.64008308,
  },
  {
    label: "Verrone",
    longitude: 8.11971546,
    latitude: 45.50393754,
  },
  {
    label: "Vigliano Biellese",
    longitude: 8.10994124,
    latitude: 45.56215813,
  },
  {
    label: "Villa del Bosco",
    longitude: 8.28264727,
    latitude: 45.62028682,
  },
  {
    label: "Villanova Biellese",
    longitude: 8.19269978,
    latitude: 45.48274602,
  },
  {
    label: "Viverone",
    longitude: 8.05065764,
    latitude: 45.42559871,
  },
  {
    label: "Zimone",
    longitude: 8.0373518,
    latitude: 45.44945092,
  },
  {
    label: "Zubiena",
    longitude: 7.99732883,
    latitude: 45.49283372,
  },
  {
    label: "Zumaglia",
    longitude: 8.08870242,
    latitude: 45.59478458,
  },
  {
    label: "Mosso",
    longitude: 8.13639953,
    latitude: 45.64643296,
  },
  {
    label: "Lessona",
    longitude: 8.1962,
    latitude: 45.5861,
  },
  {
    label: "Campiglia Cervo",
    longitude: 8.0121,
    latitude: 45.6577,
  },
  {
    label: "Abbadia Lariana",
    longitude: 9.33454921,
    latitude: 45.90049968,
  },
  {
    label: "Airuno",
    longitude: 9.42246629,
    latitude: 45.75440641,
  },
  {
    label: "Annone di Brianza",
    longitude: 9.33099149,
    latitude: 45.80521736,
  },
  {
    label: "Ballabio",
    longitude: 9.42416753,
    latitude: 45.89603656,
  },
  {
    label: "Barzago",
    longitude: 9.31649287,
    latitude: 45.7552173,
  },
  {
    label: "Barzanò",
    longitude: 9.31677649,
    latitude: 45.73463917,
  },
  {
    label: "Barzio",
    longitude: 9.46834431,
    latitude: 45.94646921,
  },
  {
    label: "Bellano",
    longitude: 9.30276896,
    latitude: 46.04329184,
  },
  {
    label: "Bosisio Parini",
    longitude: 9.2879219,
    latitude: 45.80042412,
  },
  {
    label: "Brivio",
    longitude: 9.44832519,
    latitude: 45.74211964,
  },
  {
    label: "Bulciago",
    longitude: 9.28389095,
    latitude: 45.75086323,
  },
  {
    label: "Calco",
    longitude: 9.41788138,
    latitude: 45.72538205,
  },
  {
    label: "Calolziocorte",
    longitude: 9.43211005,
    latitude: 45.80179176,
  },
  {
    label: "Carenno",
    longitude: 9.46166828,
    latitude: 45.80142537,
  },
  {
    label: "Casargo",
    longitude: 9.38827009,
    latitude: 46.03836074,
  },
  {
    label: "Casatenovo",
    longitude: 9.31559457,
    latitude: 45.69906559,
  },
  {
    label: "Cassago Brianza",
    longitude: 9.29482669,
    latitude: 45.74049083,
  },
  {
    label: "Cassina Valsassina",
    longitude: 9.47938526,
    latitude: 45.93197829,
  },
  {
    label: "Castello di Brianza",
    longitude: 9.3482511,
    latitude: 45.7558308,
  },
  {
    label: "Cernusco Lombardone",
    longitude: 9.39755363,
    latitude: 45.69048908,
  },
  {
    label: "Cesana Brianza",
    longitude: 9.30076427,
    latitude: 45.81945672,
  },
  {
    label: "Civate",
    longitude: 9.34739938,
    latitude: 45.82585276,
  },
  {
    label: "Colico",
    longitude: 9.37501095,
    latitude: 46.13383442,
  },
  {
    label: "Colle Brianza",
    longitude: 9.36590868,
    latitude: 45.76169384,
  },
  {
    label: "Cortenova",
    longitude: 9.38375114,
    latitude: 45.99971959,
  },
  {
    label: "Costa Masnaga",
    longitude: 9.27629819,
    latitude: 45.76962292,
  },
  {
    label: "Crandola Valsassina",
    longitude: 9.38010344,
    latitude: 46.02218424,
  },
  {
    label: "Cremella",
    longitude: 9.30340979,
    latitude: 45.73994321,
  },
  {
    label: "Cremeno",
    longitude: 9.47362557,
    latitude: 45.93336923,
  },
  {
    label: "Dervio",
    longitude: 9.30672896,
    latitude: 46.07707832,
  },
  {
    label: "Dolzago",
    longitude: 9.34365014,
    latitude: 45.76837239,
  },
  {
    label: "Dorio",
    longitude: 9.31936763,
    latitude: 46.1017344,
  },
  {
    label: "Ello",
    longitude: 9.36569195,
    latitude: 45.78781576,
  },
  {
    label: "Erve",
    longitude: 9.44559378,
    latitude: 45.82074408,
  },
  {
    label: "Esino Lario",
    longitude: 9.33536596,
    latitude: 45.99492366,
  },
  {
    label: "Galbiate",
    longitude: 9.37443985,
    latitude: 45.81434886,
  },
  {
    label: "Garbagnate Monastero",
    longitude: 9.3026694,
    latitude: 45.77238143,
  },
  {
    label: "Garlatitudee",
    longitude: 9.40408793,
    latitude: 45.80427241,
  },
  {
    label: "Imbersago",
    longitude: 9.44460607,
    latitude: 45.70528562,
  },
  {
    label: "Introbio",
    longitude: 9.45180466,
    latitude: 45.9763063,
  },
  {
    label: "Introzzo",
    longitude: 9.3413696,
    latitude: 46.08100075,
  },
  {
    label: "Lecco",
    longitude: 9.39339225,
    latitude: 45.8557578,
  },
  {
    label: "Lierna",
    longitude: 9.30693738,
    latitude: 45.95614539,
  },
  {
    label: "Lomagna",
    longitude: 9.37567172,
    latitude: 45.66758561,
  },
  {
    label: "Malgrate",
    longitude: 9.3734067,
    latitude: 45.85188226,
  },
  {
    label: "Mandello del Lario",
    longitude: 9.31952487,
    latitude: 45.91727086,
  },
  {
    label: "Margno",
    longitude: 9.37998387,
    latitude: 46.03048114,
  },
  {
    label: "Merate",
    longitude: 9.41001487,
    latitude: 45.69853645,
  },
  {
    label: "Missaglia",
    longitude: 9.33430617,
    latitude: 45.70851014,
  },
  {
    label: "Moggio",
    longitude: 9.49003943,
    latitude: 45.93125375,
  },
  {
    label: "Molteno",
    longitude: 9.30658657,
    latitude: 45.77706657,
  },
  {
    label: "Monte Marenzo",
    longitude: 9.4535331,
    latitude: 45.77093551,
  },
  {
    label: "Montevecchia",
    longitude: 9.38064669,
    latitude: 45.70546046,
  },
  {
    label: "Monticello Brianza",
    longitude: 9.31503825,
    latitude: 45.71068694,
  },
  {
    label: "Morterone",
    longitude: 9.4827586,
    latitude: 45.87625364,
  },
  {
    label: "Nibionno",
    longitude: 9.26395748,
    latitude: 45.75311856,
  },
  {
    label: "Oggiono",
    longitude: 9.3509846,
    latitude: 45.7919073,
  },
  {
    label: "Olgiate Molgora",
    longitude: 9.40299197,
    latitude: 45.72978003,
  },
  {
    label: "Olginate",
    longitude: 9.41032434,
    latitude: 45.80227987,
  },
  {
    label: "Oliveto Lario",
    longitude: 9.28396721,
    latitude: 45.92997974,
  },
  {
    label: "Osnago",
    longitude: 9.3880166,
    latitude: 45.67573447,
  },
  {
    label: "Paderno d'Adda",
    longitude: 9.44739335,
    latitude: 45.68024814,
  },
  {
    label: "Pagnona",
    longitude: 9.40244417,
    latitude: 46.05981895,
  },
  {
    label: "Parlasco",
    longitude: 9.34538141,
    latitude: 46.01812696,
  },
  {
    label: "Pasturo",
    longitude: 9.44344209,
    latitude: 45.95138347,
  },
  {
    label: "Perledo",
    longitude: 9.29557182,
    latitude: 46.01528711,
  },
  {
    label: "Pescate",
    longitude: 9.3945408,
    latitude: 45.82997355,
  },
  {
    label: "Premana",
    longitude: 9.42310564,
    latitude: 46.05223653,
  },
  {
    label: "Primaluna",
    longitude: 9.42946587,
    latitude: 45.98688109,
  },
  {
    label: "Robbiate",
    longitude: 9.4398135,
    latitude: 45.68882431,
  },
  {
    label: "Rogeno",
    longitude: 9.26696362,
    latitude: 45.78331911,
  },
  {
    label: "Santa Maria Hoè",
    longitude: 9.37150125,
    latitude: 45.74363037,
  },
  {
    label: "Sirone",
    longitude: 9.3236466,
    latitude: 45.77254626,
  },
  {
    label: "Sirtori",
    longitude: 9.33386291,
    latitude: 45.73527762,
  },
  {
    label: "Sueglio",
    longitude: 9.3330967,
    latitude: 46.08569063,
  },
  {
    label: "Suello",
    longitude: 9.30815929,
    latitude: 45.81854832,
  },
  {
    label: "Taceno",
    longitude: 9.36514327,
    latitude: 46.02094716,
  },
  {
    label: "Torre de' Busi",
    longitude: 9.47620478,
    latitude: 45.77277339,
  },
  {
    label: "Tremenico",
    longitude: 9.36599386,
    latitude: 46.07591544,
  },
  {
    label: "Valgreghentino",
    longitude: 9.4131964,
    latitude: 45.7694362,
  },
  {
    label: "Valmadrera",
    longitude: 9.36027083,
    latitude: 45.8469754,
  },
  {
    label: "Varenna",
    longitude: 9.28358759,
    latitude: 46.010613,
  },
  {
    label: "Vendrogno",
    longitude: 9.32984089,
    latitude: 46.03417625,
  },
  {
    label: "Vercurago",
    longitude: 9.42480039,
    latitude: 45.81074991,
  },
  {
    label: "Vestreno",
    longitude: 9.32301029,
    latitude: 46.08326652,
  },
  {
    label: "Viganò",
    longitude: 9.32419661,
    latitude: 45.72607126,
  },
  {
    label: "Verderio",
    longitude: 9.44182,
    latitude: 45.663,
  },
  {
    label: "La Valletta Brianza",
    longitude: 9.3641,
    latitude: 45.7386,
  },
  {
    label: "Abbadia Cerreto",
    longitude: 9.59252482,
    latitude: 45.31239093,
  },
  {
    label: "Bertonico",
    longitude: 9.66860158,
    latitude: 45.23388802,
  },
  {
    label: "Boffalora d'Adda",
    longitude: 9.49469045,
    latitude: 45.35863081,
  },
  {
    label: "Borghetto Lodigiano",
    longitude: 9.49802352,
    latitude: 45.21274117,
  },
  {
    label: "Borgo San Giovanni",
    longitude: 9.42534131,
    latitude: 45.27563501,
  },
  {
    label: "Brembio",
    longitude: 9.56969713,
    latitude: 45.2151031,
  },
  {
    label: "Camairago",
    longitude: 9.72690145,
    latitude: 45.20543687,
  },
  {
    label: "Casaletto Lodigiano",
    longitude: 9.36181333,
    latitude: 45.29313323,
  },
  {
    label: "Casalmaiocco",
    longitude: 9.37166246,
    latitude: 45.35304438,
  },
  {
    label: "Casalpusterlengo",
    longitude: 9.65107115,
    latitude: 45.17779667,
  },
  {
    label: "Caselle Landi",
    longitude: 9.79199005,
    latitude: 45.10376862,
  },
  {
    label: "Caselle Lurani",
    longitude: 9.3601395,
    latitude: 45.2804327,
  },
  {
    label: "Castelnuovo Bocca d'Adda",
    longitude: 9.86941312,
    latitude: 45.11236905,
  },
  {
    label: "Castiglione d'Adda",
    longitude: 9.69503024,
    latitude: 45.21568553,
  },
  {
    label: "Castiraga Vidardo",
    longitude: 9.4026397,
    latitude: 45.25645353,
  },
  {
    label: "Cavacurta",
    longitude: 9.74207463,
    latitude: 45.18991546,
  },
  {
    label: "Cavenago d'Adda",
    longitude: 9.60179501,
    latitude: 45.283965,
  },
  {
    label: "Cervignano d'Adda",
    longitude: 9.42263787,
    latitude: 45.3738302,
  },
  {
    label: "Codogno",
    longitude: 9.70319172,
    latitude: 45.16002706,
  },
  {
    label: "Comazzo",
    longitude: 9.46396154,
    latitude: 45.44065289,
  },
  {
    label: "Cornegliano Laudense",
    longitude: 9.47244994,
    latitude: 45.28440382,
  },
  {
    label: "Corno Giovine",
    longitude: 9.75575331,
    latitude: 45.13316732,
  },
  {
    label: "Cornovecchio",
    longitude: 9.79762322,
    latitude: 45.1361358,
  },
  {
    label: "Corte Palasio",
    longitude: 9.56277245,
    latitude: 45.31328084,
  },
  {
    label: "Crespiatica",
    longitude: 9.57460944,
    latitude: 45.35705659,
  },
  {
    label: "Fombio",
    longitude: 9.6835988,
    latitude: 45.13679397,
  },
  {
    label: "Galgagnano",
    longitude: 9.44295797,
    latitude: 45.35787967,
  },
  {
    label: "Graffignana",
    longitude: 9.45327197,
    latitude: 45.21132374,
  },
  {
    label: "Guardamiglio",
    longitude: 9.68130572,
    latitude: 45.10906231,
  },
  {
    label: "Livraga",
    longitude: 9.54480862,
    latitude: 45.19263197,
  },
  {
    label: "Lodi",
    longitude: 9.50372077,
    latitude: 45.31440693,
  },
  {
    label: "Lodi Vecchio",
    longitude: 9.41484635,
    latitude: 45.30071382,
  },
  {
    label: "Maccastorna",
    longitude: 9.85253271,
    latitude: 45.14662982,
  },
  {
    label: "Mairago",
    longitude: 9.57849814,
    latitude: 45.2522616,
  },
  {
    label: "Maleo",
    longitude: 9.7645369,
    latitude: 45.16820705,
  },
  {
    label: "Marudo",
    longitude: 9.374991,
    latitude: 45.25255767,
  },
  {
    label: "Massalengo",
    longitude: 9.48985029,
    latitude: 45.26351699,
  },
  {
    label: "Meleti",
    longitude: 9.83678055,
    latitude: 45.11879786,
  },
  {
    label: "Merlino",
    longitude: 9.42987934,
    latitude: 45.43290041,
  },
  {
    label: "Montanaso Lombardo",
    longitude: 9.46308821,
    latitude: 45.33528152,
  },
  {
    label: "Mulazzano",
    longitude: 9.39792838,
    latitude: 45.37214807,
  },
  {
    label: "Orio Litta",
    longitude: 9.55531306,
    latitude: 45.15721239,
  },
  {
    label: "Ospedaletto Lodigiano",
    longitude: 9.58096352,
    latitude: 45.16922848,
  },
  {
    label: "Ossago Lodigiano",
    longitude: 9.53647968,
    latitude: 45.2448653,
  },
  {
    label: "Pieve Fissiraga",
    longitude: 9.45925148,
    latitude: 45.2626919,
  },
  {
    label: "Salerano sul Lambro",
    longitude: 9.38568468,
    latitude: 45.29456749,
  },
  {
    label: "San Fiorano",
    longitude: 9.72191772,
    latitude: 45.13593789,
  },
  {
    label: "San Martino in Strada",
    longitude: 9.53053223,
    latitude: 45.27079119,
  },
  {
    label: "San Rocco al Porto",
    longitude: 9.6982919,
    latitude: 45.08047376,
  },
  {
    label: "Sant'Angelo Lodigiano",
    longitude: 9.40957845,
    latitude: 45.23811718,
  },
  {
    label: "Santo Stefano Lodigiano",
    longitude: 9.74305379,
    latitude: 45.12207369,
  },
  {
    label: "Secugnago",
    longitude: 9.59141693,
    latitude: 45.23034457,
  },
  {
    label: "Senna Lodigiana",
    longitude: 9.59358479,
    latitude: 45.1472491,
  },
  {
    label: "Somaglia",
    longitude: 9.63788616,
    latitude: 45.14698574,
  },
  {
    label: "Sordio",
    longitude: 9.36686763,
    latitude: 45.34019629,
  },
  {
    label: "Tavazzano con Villavesco",
    longitude: 9.40969561,
    latitude: 45.32750177,
  },
  {
    label: "Terranova dei Passerini",
    longitude: 9.67828733,
    latitude: 45.19868186,
  },
  {
    label: "Turano Lodigiano",
    longitude: 9.62271984,
    latitude: 45.24823835,
  },
  {
    label: "Valera Fratta",
    longitude: 9.33350996,
    latitude: 45.25682239,
  },
  {
    label: "Villanova del Sillaro",
    longitude: 9.48089049,
    latitude: 45.23730986,
  },
  {
    label: "Zelo Buon Persico",
    longitude: 9.42923234,
    latitude: 45.41160891,
  },
  {
    label: "Bellaria-Igea Marina",
    longitude: 12.45805808,
    latitude: 44.14760173,
  },
  {
    label: "Cattolica",
    longitude: 12.74464322,
    latitude: 43.96376667,
  },
  {
    label: "Coriano",
    longitude: 12.60253363,
    latitude: 43.9644061,
  },
  {
    label: "Gemmano",
    longitude: 12.58282996,
    latitude: 43.9043345,
  },
  {
    label: "Misano Adriatico",
    longitude: 12.69323617,
    latitude: 43.97336095,
  },
  {
    label: "Mondaino",
    longitude: 12.67138147,
    latitude: 43.85602804,
  },
  {
    label: "Montefiore Conca",
    longitude: 12.61126491,
    latitude: 43.89067059,
  },
  {
    label: "Montegridolfo",
    longitude: 12.68914287,
    latitude: 43.85906274,
  },
  {
    label: "Morciano di Romagna",
    longitude: 12.64593717,
    latitude: 43.91374175,
  },
  {
    label: "Riccione",
    longitude: 12.65505776,
    latitude: 44.00002553,
  },
  {
    label: "Rimini",
    longitude: 12.56562951,
    latitude: 44.06090086,
  },
  {
    label: "Saludecio",
    longitude: 12.66880924,
    latitude: 43.87266832,
  },
  {
    label: "San Clemente",
    longitude: 12.62560514,
    latitude: 43.93224578,
  },
  {
    label: "San Giovanni in Marignano",
    longitude: 12.71245963,
    latitude: 43.93898654,
  },
  {
    label: "Santarcangelo di Romagna",
    longitude: 12.44702296,
    latitude: 44.06289157,
  },
  {
    label: "Verucchio",
    longitude: 12.42138257,
    latitude: 43.98370542,
  },
  {
    label: "Casteldelci",
    longitude: 12.15511919,
    latitude: 43.79139375,
  },
  {
    label: "Maiolo",
    longitude: 12.31087171,
    latitude: 43.8736336,
  },
  {
    label: "Novafeltria",
    longitude: 12.28926775,
    latitude: 43.89583724,
  },
  {
    label: "Pennabilli",
    longitude: 12.26477255,
    latitude: 43.8183106,
  },
  {
    label: "San Leo",
    longitude: 12.34328015,
    latitude: 43.89617961,
  },
  {
    label: "Sant'Agata Feltria",
    longitude: 12.20845348,
    latitude: 43.86404084,
  },
  {
    label: "Talamello",
    longitude: 12.28613193,
    latitude: 43.90490445,
  },
  {
    label: "Poggio Torriana",
    longitude: 12.3868,
    latitude: 43.985,
  },
  {
    label: "Montescudo - Monte Colombo",
    longitude: 12.5533,
    latitude: 43.9211,
  },
  {
    label: "Cantagallo",
    longitude: 11.10781011,
    latitude: 44.02826833,
  },
  {
    label: "Carmignano",
    longitude: 11.01834807,
    latitude: 43.81383287,
  },
  {
    label: "Montemurlo",
    longitude: 11.0371372,
    latitude: 43.92690099,
  },
  {
    label: "Poggio a Caiano",
    longitude: 11.05585446,
    latitude: 43.81628696,
  },
  {
    label: "Prato",
    longitude: 11.09703315,
    latitude: 43.88062274,
  },
  {
    label: "Vaiano",
    longitude: 11.12331368,
    latitude: 43.96864659,
  },
  {
    label: "Vernio",
    longitude: 11.15575937,
    latitude: 44.05334478,
  },
  {
    label: "Belvedere di Spinello",
    longitude: 16.88684476,
    latitude: 39.21368354,
  },
  {
    label: "Caccuri",
    longitude: 16.78028917,
    latitude: 39.22719992,
  },
  {
    label: "Carfizzi",
    longitude: 16.97610613,
    latitude: 39.30619441,
  },
  {
    label: "Casabona",
    longitude: 16.9582991,
    latitude: 39.24846194,
  },
  {
    label: "Castelsilano",
    longitude: 16.76686837,
    latitude: 39.27061057,
  },
  {
    label: "Cerenzia",
    longitude: 16.78280961,
    latitude: 39.24504071,
  },
  {
    label: "Cirò",
    longitude: 17.05965462,
    latitude: 39.38168502,
  },
  {
    label: "Cirò Marina",
    longitude: 17.13083844,
    latitude: 39.36529092,
  },
  {
    label: "Cotronei",
    longitude: 16.69678105,
    latitude: 39.18958882,
  },
  {
    label: "Crotone",
    longitude: 17.12539245,
    latitude: 39.08036861,
  },
  {
    label: "Crucoli",
    longitude: 17.00369769,
    latitude: 39.42468725,
  },
  {
    label: "Cutro",
    longitude: 16.98195396,
    latitude: 39.03319468,
  },
  {
    label: "Isola di Capo Rizzuto",
    longitude: 17.09565075,
    latitude: 38.95903336,
  },
  {
    label: "Melissa",
    longitude: 17.02282168,
    latitude: 39.30518813,
  },
  {
    label: "Mesoraca",
    longitude: 16.79386451,
    latitude: 39.08252434,
  },
  {
    label: "Pallagorio",
    longitude: 16.91335025,
    latitude: 39.30831843,
  },
  {
    label: "Petilia Policastro",
    longitude: 16.79270604,
    latitude: 39.11064945,
  },
  {
    label: "Roccabernarda",
    longitude: 16.87173066,
    latitude: 39.13395116,
  },
  {
    label: "Rocca di Neto",
    longitude: 17.00234034,
    latitude: 39.19170933,
  },
  {
    label: "San Mauro Marchesato",
    longitude: 16.92253935,
    latitude: 39.10276548,
  },
  {
    label: "San Nicola dell'Alto",
    longitude: 16.97575675,
    latitude: 39.28820184,
  },
  {
    label: "Santa Severina",
    longitude: 16.91496355,
    latitude: 39.1478435,
  },
  {
    label: "Savelli",
    longitude: 16.78082128,
    latitude: 39.31229782,
  },
  {
    label: "Scandale",
    longitude: 16.95852503,
    latitude: 39.12313864,
  },
  {
    label: "Strongoli",
    longitude: 17.05273017,
    latitude: 39.26750146,
  },
  {
    label: "Umbriatico",
    longitude: 16.91718776,
    latitude: 39.35281114,
  },
  {
    label: "Verzino",
    longitude: 16.86168109,
    latitude: 39.3124779,
  },
  {
    label: "Acquaro",
    longitude: 16.18996399,
    latitude: 38.55558146,
  },
  {
    label: "Arena",
    longitude: 16.20999999,
    latitude: 38.56295629,
  },
  {
    label: "Briatico",
    longitude: 16.0341552,
    latitude: 38.72445456,
  },
  {
    label: "Brognaturo",
    longitude: 16.34247722,
    latitude: 38.60153042,
  },
  {
    label: "Capistrano",
    longitude: 16.28869251,
    latitude: 38.69067014,
  },
  {
    label: "Cessaniti",
    longitude: 16.02959092,
    latitude: 38.6639523,
  },
  {
    label: "Dasà",
    longitude: 16.19644659,
    latitude: 38.56536828,
  },
  {
    label: "Dinami",
    longitude: 16.14770415,
    latitude: 38.52963719,
  },
  {
    label: "Drapia",
    longitude: 15.91122353,
    latitude: 38.66611711,
  },
  {
    label: "Fabrizia",
    longitude: 16.30309317,
    latitude: 38.48357691,
  },
  {
    label: "Filadelfia",
    longitude: 16.29343449,
    latitude: 38.78355638,
  },
  {
    label: "Filandari",
    longitude: 16.03138461,
    latitude: 38.61541349,
  },
  {
    label: "Filogaso",
    longitude: 16.23087033,
    latitude: 38.68091561,
  },
  {
    label: "Francavilla Angitola",
    longitude: 16.27066608,
    latitude: 38.77801727,
  },
  {
    label: "Francica",
    longitude: 16.10169169,
    latitude: 38.61831486,
  },
  {
    label: "Gerocarne",
    longitude: 16.22068674,
    latitude: 38.58715265,
  },
  {
    label: "Ionadi",
    longitude: 16.05444962,
    latitude: 38.62781794,
  },
  {
    label: "Joppolo",
    longitude: 15.89747029,
    latitude: 38.58390789,
  },
  {
    label: "Limbadi",
    longitude: 15.96202569,
    latitude: 38.55879726,
  },
  {
    label: "Maierato",
    longitude: 16.19233423,
    latitude: 38.70706608,
  },
  {
    label: "Mileto",
    longitude: 16.0598991,
    latitude: 38.60321441,
  },
  {
    label: "Mongiana",
    longitude: 16.31924156,
    latitude: 38.51343965,
  },
  {
    label: "Monterosso Calabro",
    longitude: 16.2873896,
    latitude: 38.717932,
  },
  {
    label: "Nardodipace",
    longitude: 16.34295565,
    latitude: 38.47418003,
  },
  {
    label: "Nicotera",
    longitude: 15.93189887,
    latitude: 38.54358682,
  },
  {
    label: "Parghelia",
    longitude: 15.92423588,
    latitude: 38.6818366,
  },
  {
    label: "Pizzo",
    longitude: 16.16159449,
    latitude: 38.73590531,
  },
  {
    label: "Pizzoni",
    longitude: 16.24949156,
    latitude: 38.62342078,
  },
  {
    label: "Polia",
    longitude: 16.31405456,
    latitude: 38.75092029,
  },
  {
    label: "Ricadi",
    longitude: 15.86639112,
    latitude: 38.62421334,
  },
  {
    label: "Rombiolo",
    longitude: 16.00158118,
    latitude: 38.59761794,
  },
  {
    label: "San Calogero",
    longitude: 16.01327729,
    latitude: 38.57066223,
  },
  {
    label: "San Costantino Calabro",
    longitude: 16.07081575,
    latitude: 38.63339201,
  },
  {
    label: "San Gregorio d'Ippona",
    longitude: 16.10173844,
    latitude: 38.64330445,
  },
  {
    label: "San Nicola da Crissa",
    longitude: 16.28624651,
    latitude: 38.664379,
  },
  {
    label: "Sant'Onofrio",
    longitude: 16.14283521,
    latitude: 38.6965214,
  },
  {
    label: "Serra San Bruno",
    longitude: 16.3269465,
    latitude: 38.57357167,
  },
  {
    label: "Simbario",
    longitude: 16.33826797,
    latitude: 38.61080156,
  },
  {
    label: "Sorianello",
    longitude: 16.23286745,
    latitude: 38.59224575,
  },
  {
    label: "Soriano Calabro",
    longitude: 16.23011086,
    latitude: 38.59645359,
  },
  {
    label: "Spadola",
    longitude: 16.33651103,
    latitude: 38.60364244,
  },
  {
    label: "Spilinga",
    longitude: 15.90583616,
    latitude: 38.62855215,
  },
  {
    label: "Stefanaconi",
    longitude: 16.12717322,
    latitude: 38.67620452,
  },
  {
    label: "Tropea",
    longitude: 15.89789231,
    latitude: 38.67862874,
  },
  {
    label: "Vallelonga",
    longitude: 16.29598601,
    latitude: 38.64696316,
  },
  {
    label: "Vazzano",
    longitude: 16.24599797,
    latitude: 38.63353666,
  },
  {
    label: "Vibo Valentia",
    longitude: 16.10157559,
    latitude: 38.67624141,
  },
  {
    label: "Zaccanopoli",
    longitude: 15.93068747,
    latitude: 38.66409872,
  },
  {
    label: "Zambrone",
    longitude: 15.98982134,
    latitude: 38.69877958,
  },
  {
    label: "Zungri",
    longitude: 15.98363574,
    latitude: 38.65678215,
  },
  {
    label: "Antrona Schieranco",
    longitude: 8.11509976,
    latitude: 46.06141305,
  },
  {
    label: "Anzola d'Ossola",
    longitude: 8.34746103,
    latitude: 45.98947805,
  },
  {
    label: "Arizzano",
    longitude: 8.58310897,
    latitude: 45.95560733,
  },
  {
    label: "Arola",
    longitude: 8.35875818,
    latitude: 45.80953519,
  },
  {
    label: "Aurano",
    longitude: 8.58799522,
    latitude: 45.99871062,
  },
  {
    label: "Baceno",
    longitude: 8.31948395,
    latitude: 46.26149142,
  },
  {
    label: "Bannio Anzino",
    longitude: 8.14618751,
    latitude: 45.98446545,
  },
  {
    label: "Baveno",
    longitude: 8.50483277,
    latitude: 45.90891863,
  },
  {
    label: "Bee",
    longitude: 8.58023117,
    latitude: 45.96272502,
  },
  {
    label: "Belgirate",
    longitude: 8.57158795,
    latitude: 45.83835016,
  },
  {
    label: "Beura-Cardezza",
    longitude: 8.29933657,
    latitude: 46.07888063,
  },
  {
    label: "Bognanco",
    longitude: 8.20639541,
    latitude: 46.1227807,
  },
  {
    label: "Brovello-Carpugnino",
    longitude: 8.53319491,
    latitude: 45.84274418,
  },
  {
    label: "Calasca-Castiglione",
    longitude: 8.17024308,
    latitude: 46.00382348,
  },
  {
    label: "Cambiasca",
    longitude: 8.54113201,
    latitude: 45.96429341,
  },
  {
    label: "Cannero Riviera",
    longitude: 8.67933134,
    latitude: 46.02206094,
  },
  {
    label: "Cannobio",
    longitude: 8.7001928,
    latitude: 46.06172035,
  },
  {
    label: "Caprezzo",
    longitude: 8.56128575,
    latitude: 45.98065482,
  },
  {
    label: "Casale Corte Cerro",
    longitude: 8.41505526,
    latitude: 45.91677748,
  },
  {
    label: "Cavaglio-Spoccia",
    longitude: 8.64572023,
    latitude: 46.0691621,
  },
  {
    label: "Ceppo Morelli",
    longitude: 8.06670957,
    latitude: 45.97136783,
  },
  {
    label: "Cesara",
    longitude: 8.36593391,
    latitude: 45.8350971,
  },
  {
    label: "Cossogno",
    longitude: 8.51002439,
    latitude: 45.96398123,
  },
  {
    label: "Craveggia",
    longitude: 8.48978584,
    latitude: 46.14063555,
  },
  {
    label: "Crevoladossola",
    longitude: 8.30357753,
    latitude: 46.15604302,
  },
  {
    label: "Crodo",
    longitude: 8.3234115,
    latitude: 46.22345888,
  },
  {
    label: "Cursolo-Orasso",
    longitude: 8.56659126,
    latitude: 46.09784122,
  },
  {
    label: "Domodossola",
    longitude: 8.29204242,
    latitude: 46.11529474,
  },
  {
    label: "Druogno",
    longitude: 8.43180731,
    latitude: 46.13534934,
  },
  {
    label: "Falmenta",
    longitude: 8.59046809,
    latitude: 46.07185915,
  },
  {
    label: "Formazza",
    longitude: 8.42672956,
    latitude: 46.37672806,
  },
  {
    label: "Germagno",
    longitude: 8.38796347,
    latitude: 45.8924751,
  },
  {
    label: "Ghiffa",
    longitude: 8.61707384,
    latitude: 45.95635016,
  },
  {
    label: "Gignese",
    longitude: 8.50989111,
    latitude: 45.86057481,
  },
  {
    label: "Gravellona Toce",
    longitude: 8.43142163,
    latitude: 45.92758971,
  },
  {
    label: "Gurro",
    longitude: 8.57011459,
    latitude: 46.0859594,
  },
  {
    label: "Intragna",
    longitude: 8.57634414,
    latitude: 45.99529796,
  },
  {
    label: "Loreglia",
    longitude: 8.37102962,
    latitude: 45.90636433,
  },
  {
    label: "Macugnaga",
    longitude: 7.96781758,
    latitude: 45.96863285,
  },
  {
    label: "Madonna del Sasso",
    longitude: 8.368138,
    latitude: 45.7923455,
  },
  {
    label: "Malesco",
    longitude: 8.50035882,
    latitude: 46.1266524,
  },
  {
    label: "Masera",
    longitude: 8.32647658,
    latitude: 46.13697091,
  },
  {
    label: "Massiola",
    longitude: 8.31968051,
    latitude: 45.91223892,
  },
  {
    label: "Mergozzo",
    longitude: 8.45293267,
    latitude: 45.9609814,
  },
  {
    label: "Miazzina",
    longitude: 8.52391779,
    latitude: 45.97521934,
  },
  {
    label: "Montecrestese",
    longitude: 8.32648378,
    latitude: 46.1654786,
  },
  {
    label: "Montescheno",
    longitude: 8.23290672,
    latitude: 46.06660256,
  },
  {
    label: "Nonio",
    longitude: 8.3767952,
    latitude: 45.84505972,
  },
  {
    label: "Oggebbio",
    longitude: 8.65287539,
    latitude: 45.99691478,
  },
  {
    label: "Omegna",
    longitude: 8.40892832,
    latitude: 45.87561647,
  },
  {
    label: "Ornavasso",
    longitude: 8.4157735,
    latitude: 45.96919318,
  },
  {
    label: "Pallanzeno",
    longitude: 8.25785894,
    latitude: 46.04346862,
  },
  {
    label: "Piedimulera",
    longitude: 8.25981036,
    latitude: 46.0270947,
  },
  {
    label: "Pieve Vergonte",
    longitude: 8.26848611,
    latitude: 46.00555648,
  },
  {
    label: "Premeno",
    longitude: 8.59146288,
    latitude: 45.97712327,
  },
  {
    label: "Premia",
    longitude: 8.33953232,
    latitude: 46.26883718,
  },
  {
    label: "Premosello-Chiovenda",
    longitude: 8.32942323,
    latitude: 46.00273542,
  },
  {
    label: "Quarna Sopra",
    longitude: 8.37223871,
    latitude: 45.87304659,
  },
  {
    label: "Quarna Sotto",
    longitude: 8.36388175,
    latitude: 45.86822819,
  },
  {
    label: "Re",
    longitude: 8.54437265,
    latitude: 46.1305561,
  },
  {
    label: "San Bernardino Verbano",
    longitude: 8.52017874,
    latitude: 45.95679481,
  },
  {
    label: "Santa Maria Maggiore",
    longitude: 8.4668702,
    latitude: 46.13505436,
  },
  {
    label: "Stresa",
    longitude: 8.54073107,
    latitude: 45.88388873,
  },
  {
    label: "Toceno",
    longitude: 8.46755943,
    latitude: 46.14344354,
  },
  {
    label: "Trarego Viggiona",
    longitude: 8.66399212,
    latitude: 46.03389353,
  },
  {
    label: "Trasquera",
    longitude: 8.21400204,
    latitude: 46.21248066,
  },
  {
    label: "Trontano",
    longitude: 8.33448238,
    latitude: 46.12224737,
  },
  {
    label: "Valstrona",
    longitude: 8.34081863,
    latitude: 45.90729063,
  },
  {
    label: "Vanzone con San Carlo",
    longitude: 8.1103612,
    latitude: 45.97996754,
  },
  {
    label: "Varzo",
    longitude: 8.25547942,
    latitude: 46.20672601,
  },
  {
    label: "Verbania",
    longitude: 8.55107875,
    latitude: 45.9214455,
  },
  {
    label: "Vignone",
    longitude: 8.56639436,
    latitude: 45.96043591,
  },
  {
    label: "Villadossola",
    longitude: 8.26013266,
    latitude: 46.0677133,
  },
  {
    label: "Villette",
    longitude: 8.53407667,
    latitude: 46.13129239,
  },
  {
    label: "Vogogna",
    longitude: 8.29548539,
    latitude: 46.00783349,
  },
  {
    label: "Borgomezzavalle",
    longitude: 8.1968,
    latitude: 46.0532,
  },
  {
    label: "Agrate Brianza",
    longitude: 9.35176407,
    latitude: 45.57545676,
  },
  {
    label: "Aicurzio",
    longitude: 9.41103721,
    latitude: 45.64070053,
  },
  {
    label: "Albiate",
    longitude: 9.26133993,
    latitude: 45.65669353,
  },
  {
    label: "Arcore",
    longitude: 9.32108103,
    latitude: 45.62696694,
  },
  {
    label: "Barlassina",
    longitude: 9.13050805,
    latitude: 45.65611701,
  },
  {
    label: "Bellusco",
    longitude: 9.41779202,
    latitude: 45.61856248,
  },
  {
    label: "Bernareggio",
    longitude: 9.40567521,
    latitude: 45.65003338,
  },
  {
    label: "Besana in Brianza",
    longitude: 9.28851108,
    latitude: 45.700761,
  },
  {
    label: "Biassono",
    longitude: 9.27623077,
    latitude: 45.62856742,
  },
  {
    label: "Bovisio-Masciago",
    longitude: 9.14866196,
    latitude: 45.61109324,
  },
  {
    label: "Briosco",
    longitude: 9.23719684,
    latitude: 45.70929261,
  },
  {
    label: "Brugherio",
    longitude: 9.30006028,
    latitude: 45.55103032,
  },
  {
    label: "Burago di Molgora",
    longitude: 9.37906933,
    latitude: 45.59592144,
  },
  {
    label: "Camparada",
    longitude: 9.32237835,
    latitude: 45.6549953,
  },
  {
    label: "Carate Brianza",
    longitude: 9.23312209,
    latitude: 45.6770523,
  },
  {
    label: "Carnate",
    longitude: 9.37833637,
    latitude: 45.65106483,
  },
  {
    label: "Cavenago di Brianza",
    longitude: 9.4148772,
    latitude: 45.58529553,
  },
  {
    label: "Ceriano Laghetto",
    longitude: 9.07906789,
    latitude: 45.62719292,
  },
  {
    label: "Cesano Maderno",
    longitude: 9.14802853,
    latitude: 45.62648006,
  },
  {
    label: "Cogliate",
    longitude: 9.08184646,
    latitude: 45.645753,
  },
  {
    label: "Concorezzo",
    longitude: 9.33865795,
    latitude: 45.59105589,
  },
  {
    label: "Correzzana",
    longitude: 9.30446354,
    latitude: 45.66785439,
  },
  {
    label: "Desio",
    longitude: 9.20882654,
    latitude: 45.62011658,
  },
  {
    label: "Giussano",
    longitude: 9.20492999,
    latitude: 45.6914208,
  },
  {
    label: "Lazzate",
    longitude: 9.08418935,
    latitude: 45.66877588,
  },
  {
    label: "Lesmo",
    longitude: 9.30248056,
    latitude: 45.64638161,
  },
  {
    label: "Limbiate",
    longitude: 9.12302747,
    latitude: 45.60503375,
  },
  {
    label: "Lissone",
    longitude: 9.24385612,
    latitude: 45.61053451,
  },
  {
    label: "Macherio",
    longitude: 9.27120605,
    latitude: 45.64130613,
  },
  {
    label: "Meda",
    longitude: 9.15527217,
    latitude: 45.66155081,
  },
  {
    label: "Mezzago",
    longitude: 9.43948055,
    latitude: 45.62597039,
  },
  {
    label: "Misinto",
    longitude: 9.07747501,
    latitude: 45.66463093,
  },
  {
    label: "Monza",
    longitude: 9.27358247,
    latitude: 45.58439043,
  },
  {
    label: "Muggiò",
    longitude: 9.22956006,
    latitude: 45.59366403,
  },
  {
    label: "Nova Milanese",
    longitude: 9.20171692,
    latitude: 45.58698631,
  },
  {
    label: "Ornago",
    longitude: 9.42200943,
    latitude: 45.59832193,
  },
  {
    label: "Renate",
    longitude: 9.28467408,
    latitude: 45.72266094,
  },
  {
    label: "Ronco Briantino",
    longitude: 9.4007211,
    latitude: 45.66777504,
  },
  {
    label: "Seregno",
    longitude: 9.20176594,
    latitude: 45.65021257,
  },
  {
    label: "Seveso",
    longitude: 9.13851214,
    latitude: 45.64735185,
  },
  {
    label: "Sovico",
    longitude: 9.26489462,
    latitude: 45.64936183,
  },
  {
    label: "Sulbiate",
    longitude: 9.42148311,
    latitude: 45.63482681,
  },
  {
    label: "Triuggio",
    longitude: 9.26445108,
    latitude: 45.65919443,
  },
  {
    label: "Usmate Velatitudee",
    longitude: 9.36146078,
    latitude: 45.6506647,
  },
  {
    label: "Varedo",
    longitude: 9.15706477,
    latitude: 45.59804877,
  },
  {
    label: "Vedano al Lambro",
    longitude: 9.272889,
    latitude: 45.60952797,
  },
  {
    label: "Veduggio con Colzano",
    longitude: 9.26761258,
    latitude: 45.73579292,
  },
  {
    label: "Verano Brianza",
    longitude: 9.22960847,
    latitude: 45.68465171,
  },
  {
    label: "Villasanta",
    longitude: 9.30195235,
    latitude: 45.60427327,
  },
  {
    label: "Vimercate",
    longitude: 9.36974907,
    latitude: 45.61316595,
  },
  {
    label: "Busnago",
    longitude: 9.46619248,
    latitude: 45.617173,
  },
  {
    label: "Caponago",
    longitude: 9.37703299,
    latitude: 45.56531345,
  },
  {
    label: "Cornate d'Adda",
    longitude: 9.46388698,
    latitude: 45.64875974,
  },
  {
    label: "Lentate sul Seveso",
    longitude: 9.11924273,
    latitude: 45.67821761,
  },
  {
    label: "Roncello",
    longitude: 9.45696238,
    latitude: 45.60488013,
  },
  {
    label: "Altidona",
    longitude: 13.79441016,
    latitude: 43.10672038,
  },
  {
    label: "Amandola",
    longitude: 13.35571226,
    latitude: 42.9804282,
  },
  {
    label: "Belmonte Piceno",
    longitude: 13.53882338,
    latitude: 43.09152184,
  },
  {
    label: "Campofilone",
    longitude: 13.81453865,
    latitude: 43.07932883,
  },
  {
    label: "Falerone",
    longitude: 13.47390953,
    latitude: 43.10783645,
  },
  {
    label: "Fermo",
    longitude: 13.71839545,
    latitude: 43.16058534,
  },
  {
    label: "Francavilla d'Ete",
    longitude: 13.53995064,
    latitude: 43.19100149,
  },
  {
    label: "Grottazzolina",
    longitude: 13.60101053,
    latitude: 43.1109952,
  },
  {
    label: "Lapedona",
    longitude: 13.77215164,
    latitude: 43.10974917,
  },
  {
    label: "Magliano di Tenna",
    longitude: 13.58621903,
    latitude: 43.13858436,
  },
  {
    label: "Massa Fermana",
    longitude: 13.47564023,
    latitude: 43.15041673,
  },
  {
    label: "Monsampietro Morico",
    longitude: 13.55584414,
    latitude: 43.0676704,
  },
  {
    label: "Montappone",
    longitude: 13.46935716,
    latitude: 43.1355101,
  },
  {
    label: "Montefalcone Appennino",
    longitude: 13.45956774,
    latitude: 42.98770102,
  },
  {
    label: "Montefortino",
    longitude: 13.34123593,
    latitude: 42.94264481,
  },
  {
    label: "Monte Giberto",
    longitude: 13.63086077,
    latitude: 43.09128093,
  },
  {
    label: "Montegiorgio",
    longitude: 13.53778583,
    latitude: 43.13003126,
  },
  {
    label: "Montegranaro",
    longitude: 13.63259809,
    latitude: 43.23338909,
  },
  {
    label: "Monteleone di Fermo",
    longitude: 13.5288119,
    latitude: 43.04742683,
  },
  {
    label: "Montelparo",
    longitude: 13.53536974,
    latitude: 43.01800088,
  },
  {
    label: "Monte Rinaldo",
    longitude: 13.58090613,
    latitude: 43.02836327,
  },
  {
    label: "Monterubbiano",
    longitude: 13.71643339,
    latitude: 43.08540863,
  },
  {
    label: "Monte San Pietrangeli",
    longitude: 13.57960601,
    latitude: 43.19179356,
  },
  {
    label: "Monte Urano",
    longitude: 13.67111477,
    latitude: 43.20621776,
  },
  {
    label: "Monte Vidon Combatte",
    longitude: 13.6307128,
    latitude: 43.04964723,
  },
  {
    label: "Monte Vidon Corrado",
    longitude: 13.48448456,
    latitude: 43.1213994,
  },
  {
    label: "Montottone",
    longitude: 13.5848093,
    latitude: 43.06195742,
  },
  {
    label: "Moresco",
    longitude: 13.73244476,
    latitude: 43.08571288,
  },
  {
    label: "Ortezzano",
    longitude: 13.607496,
    latitude: 43.03158001,
  },
  {
    label: "Pedaso",
    longitude: 13.84092095,
    latitude: 43.09802475,
  },
  {
    label: "Petritoli",
    longitude: 13.65616614,
    latitude: 43.06699367,
  },
  {
    label: "Ponzano di Fermo",
    longitude: 13.65864462,
    latitude: 43.10192109,
  },
  {
    label: "Porto San Giorgio",
    longitude: 13.79638847,
    latitude: 43.18444175,
  },
  {
    label: "Porto Sant'Elpidio",
    longitude: 13.75807173,
    latitude: 43.25798509,
  },
  {
    label: "Rapagnano",
    longitude: 13.59280287,
    latitude: 43.16100943,
  },
  {
    label: "Santa Vittoria in Matenano",
    longitude: 13.49672144,
    latitude: 43.01995193,
  },
  {
    label: "Sant'Elpidio a Mare",
    longitude: 13.6865342,
    latitude: 43.22916559,
  },
  {
    label: "Servigliano",
    longitude: 13.4921849,
    latitude: 43.08078103,
  },
  {
    label: "Smerillo",
    longitude: 13.44547478,
    latitude: 43.00445155,
  },
  {
    label: "Torre San Patrizio",
    longitude: 13.60753129,
    latitude: 43.18445404,
  },
  {
    label: "Andria",
    longitude: 16.29520624,
    latitude: 41.22705031,
  },
  {
    label: "Barletta",
    longitude: 16.27717589,
    latitude: 41.31955658,
  },
  {
    label: "Bisceglie",
    longitude: 16.5026137,
    latitude: 41.24246439,
  },
  {
    label: "Canosa di Puglia",
    longitude: 16.06264363,
    latitude: 41.22539198,
  },
  {
    label: "Margherita di Savoia",
    longitude: 16.14888919,
    latitude: 41.37261604,
  },
  {
    label: "Minervino Murge",
    longitude: 16.07966576,
    latitude: 41.08918939,
  },
  {
    label: "San Ferdinando di Puglia",
    longitude: 16.06883848,
    latitude: 41.30079475,
  },
  {
    label: "Spinazzola",
    longitude: 16.08926535,
    latitude: 40.96943551,
  },
  {
    label: "Trani",
    longitude: 16.41721938,
    latitude: 41.27408726,
  },
  {
    label: "Trinitapoli",
    longitude: 16.08554811,
    latitude: 41.35956887,
  },
  {
    label: "Arbus",
    longitude: 8.60021437,
    latitude: 39.52888068,
  },
  {
    label: "Armungia",
    longitude: 9.38020135,
    latitude: 39.52145507,
  },
  {
    label: "Ballao",
    longitude: 9.35985141,
    latitude: 39.54850967,
  },
  {
    label: "Barrali",
    longitude: 9.10256337,
    latitude: 39.47571098,
  },
  {
    label: "Barumini",
    longitude: 9.00284088,
    latitude: 39.70479293,
  },
  {
    label: "Buggerru",
    longitude: 8.40082773,
    latitude: 39.397,
  },
  {
    label: "Burcei",
    longitude: 9.36158826,
    latitude: 39.34651389,
  },
  {
    label: "Calasetta",
    longitude: 8.36539576,
    latitude: 39.10727051,
  },
  {
    label: "Carbonia",
    longitude: 8.52624268,
    latitude: 39.16641462,
  },
  {
    label: "Carloforte",
    longitude: 8.3055801,
    latitude: 39.1457562,
  },
  {
    label: "Castiadas",
    longitude: 9.52360309,
    latitude: 39.27560709,
  },
  {
    label: "Collinas",
    longitude: 8.83685176,
    latitude: 39.64089335,
  },
  {
    label: "Decimoputzu",
    longitude: 8.91892367,
    latitude: 39.33508481,
  },
  {
    label: "Dolianova",
    longitude: 9.17578588,
    latitude: 39.37561451,
  },
  {
    label: "Domus de Maria",
    longitude: 8.86371992,
    latitude: 38.94586977,
  },
  {
    label: "Domusnovas",
    longitude: 8.65337095,
    latitude: 39.32126404,
  },
  {
    label: "Donori",
    longitude: 9.12629252,
    latitude: 39.43252841,
  },
  {
    label: "Escalaplano",
    longitude: 9.35509402,
    latitude: 39.62627857,
  },
  {
    label: "Escolca",
    longitude: 9.12370676,
    latitude: 39.69907825,
  },
  {
    label: "Esterzili",
    longitude: 9.285227,
    latitude: 39.78062365,
  },
  {
    label: "Fluminimaggiore",
    longitude: 8.49786516,
    latitude: 39.43640202,
  },
  {
    label: "Furtei",
    longitude: 8.94389583,
    latitude: 39.5629782,
  },
  {
    label: "Genoni",
    longitude: 9.0098478,
    latitude: 39.7945028,
  },
  {
    label: "Genuri",
    longitude: 8.92316498,
    latitude: 39.74279966,
  },
  {
    label: "Gergei",
    longitude: 9.1017631,
    latitude: 39.69955192,
  },
  {
    label: "Gesico",
    longitude: 9.10637449,
    latitude: 39.6151356,
  },
  {
    label: "Gesturi",
    longitude: 9.02279686,
    latitude: 39.73325037,
  },
  {
    label: "Giba",
    longitude: 8.63608564,
    latitude: 39.07133666,
  },
  {
    label: "Goni",
    longitude: 9.28644748,
    latitude: 39.57811029,
  },
  {
    label: "Gonnesa",
    longitude: 8.47141529,
    latitude: 39.26515256,
  },
  {
    label: "Gonnosfanadiga",
    longitude: 8.6617126,
    latitude: 39.49339845,
  },
  {
    label: "Guamaggiore",
    longitude: 9.07353555,
    latitude: 39.56678792,
  },
  {
    label: "Guasila",
    longitude: 9.0455535,
    latitude: 39.56156351,
  },
  {
    label: "Guspini",
    longitude: 8.62723238,
    latitude: 39.53862206,
  },
  {
    label: "Iglesias",
    longitude: 8.53146719,
    latitude: 39.30993485,
  },
  {
    label: "Isili",
    longitude: 9.10666152,
    latitude: 39.74180979,
  },
  {
    label: "Las Plassas",
    longitude: 8.98359494,
    latitude: 39.6783399,
  },
  {
    label: "Lunamatrona",
    longitude: 8.90270446,
    latitude: 39.64909104,
  },
  {
    label: "Mandas",
    longitude: 9.12979552,
    latitude: 39.65697588,
  },
  {
    label: "Masainas",
    longitude: 8.63060063,
    latitude: 39.0515453,
  },
  {
    label: "Monastir",
    longitude: 9.04409262,
    latitude: 39.38718649,
  },
  {
    label: "Muravera",
    longitude: 9.5746983,
    latitude: 39.42157603,
  },
  {
    label: "Musei",
    longitude: 8.66692588,
    latitude: 39.30233554,
  },
  {
    label: "Narcao",
    longitude: 8.67731416,
    latitude: 39.16738912,
  },
  {
    label: "Nuragus",
    longitude: 9.03863555,
    latitude: 39.77663845,
  },
  {
    label: "Nurallao",
    longitude: 9.07838185,
    latitude: 39.7949656,
  },
  {
    label: "Nuraminis",
    longitude: 9.0132012,
    latitude: 39.44162991,
  },
  {
    label: "Nurri",
    longitude: 9.22862384,
    latitude: 39.71080493,
  },
  {
    label: "Nuxis",
    longitude: 8.73994091,
    latitude: 39.1552386,
  },
  {
    label: "Orroli",
    longitude: 9.25194317,
    latitude: 39.69394256,
  },
  {
    label: "Ortacesus",
    longitude: 9.0856317,
    latitude: 39.53644862,
  },
  {
    label: "Pabillonis",
    longitude: 8.71932655,
    latitude: 39.5957205,
  },
  {
    label: "Pauli Arbarei",
    longitude: 8.92264864,
    latitude: 39.6603419,
  },
  {
    label: "Perdaxius",
    longitude: 8.60998838,
    latitude: 39.16149264,
  },
  {
    label: "Pimentel",
    longitude: 9.06560497,
    latitude: 39.48452733,
  },
  {
    label: "Piscinas",
    longitude: 8.66599648,
    latitude: 39.07544448,
  },
  {
    label: "Portoscuso",
    longitude: 8.37902223,
    latitude: 39.20388151,
  },
  {
    label: "Sadali",
    longitude: 9.27259967,
    latitude: 39.81434932,
  },
  {
    label: "Samassi",
    longitude: 8.90735563,
    latitude: 39.48200293,
  },
  {
    label: "Samatzai",
    longitude: 9.0336649,
    latitude: 39.47980558,
  },
  {
    label: "San Basilio",
    longitude: 9.19837245,
    latitude: 39.53737549,
  },
  {
    label: "San Gavino Monreale",
    longitude: 8.79228008,
    latitude: 39.54872513,
  },
  {
    label: "San Giovanni Suergiu",
    longitude: 8.52209975,
    latitude: 39.11024796,
  },
  {
    label: "San Nicolò Gerrei",
    longitude: 9.30128636,
    latitude: 39.4957063,
  },
  {
    label: "San Sperate",
    longitude: 9.00655726,
    latitude: 39.35668655,
  },
  {
    label: "San Vito",
    longitude: 9.54163191,
    latitude: 39.44159247,
  },
  {
    label: "Sanluri",
    longitude: 8.89898244,
    latitude: 39.56179766,
  },
  {
    label: "Santadi",
    longitude: 8.71648381,
    latitude: 39.09368726,
  },
  {
    label: "Sant'Andrea Frius",
    longitude: 9.17315964,
    latitude: 39.47953197,
  },
  {
    label: "Sant'Anna Arresi",
    longitude: 8.64230851,
    latitude: 39.00504486,
  },
  {
    label: "Sant'Antioco",
    longitude: 8.45509453,
    latitude: 39.06673209,
  },
  {
    label: "Sardara",
    longitude: 8.82061913,
    latitude: 39.61371776,
  },
  {
    label: "Segariu",
    longitude: 8.98332136,
    latitude: 39.56393434,
  },
  {
    label: "Selegas",
    longitude: 9.10390005,
    latitude: 39.56850248,
  },
  {
    label: "Senorbì",
    longitude: 9.13200686,
    latitude: 39.53305375,
  },
  {
    label: "Serdiana",
    longitude: 9.15692002,
    latitude: 39.3735429,
  },
  {
    label: "Serramanna",
    longitude: 8.92128497,
    latitude: 39.42465473,
  },
  {
    label: "Serrenti",
    longitude: 8.97500182,
    latitude: 39.4927156,
  },
  {
    label: "Serri",
    longitude: 9.14759116,
    latitude: 39.70120237,
  },
  {
    label: "Setzu",
    longitude: 8.93975903,
    latitude: 39.72372912,
  },
  {
    label: "Seui",
    longitude: 9.3240645,
    latitude: 39.83974909,
  },
  {
    label: "Seulo",
    longitude: 9.23848157,
    latitude: 39.8679608,
  },
  {
    label: "Siddi",
    longitude: 8.88943286,
    latitude: 39.66997322,
  },
  {
    label: "Siliqua",
    longitude: 8.80893299,
    latitude: 39.29994078,
  },
  {
    label: "Silius",
    longitude: 9.29587489,
    latitude: 39.51748382,
  },
  {
    label: "Siurgus Donigala",
    longitude: 9.1851296,
    latitude: 39.59920717,
  },
  {
    label: "Soleminis",
    longitude: 9.18134789,
    latitude: 39.34813791,
  },
  {
    label: "Suelli",
    longitude: 9.13190821,
    latitude: 39.56198008,
  },
  {
    label: "Teulada",
    longitude: 8.7725055,
    latitude: 38.96813155,
  },
  {
    label: "Tratalias",
    longitude: 8.57759316,
    latitude: 39.10311494,
  },
  {
    label: "Tuili",
    longitude: 8.96017856,
    latitude: 39.71466671,
  },
  {
    label: "Turri",
    longitude: 8.91816207,
    latitude: 39.7030991,
  },
  {
    label: "Ussana",
    longitude: 9.07770839,
    latitude: 39.39274761,
  },
  {
    label: "Ussaramanna",
    longitude: 8.91029932,
    latitude: 39.6912752,
  },
  {
    label: "Vallermosa",
    longitude: 8.79623519,
    latitude: 39.36309442,
  },
  {
    label: "Villacidro",
    longitude: 8.73213001,
    latitude: 39.45597163,
  },
  {
    label: "Villamar",
    longitude: 8.96202344,
    latitude: 39.61786758,
  },
  {
    label: "Villamassargia",
    longitude: 8.64036675,
    latitude: 39.27558098,
  },
  {
    label: "Villanova Tulo",
    longitude: 9.21507135,
    latitude: 39.77988632,
  },
  {
    label: "Villanovaforru",
    longitude: 8.86861123,
    latitude: 39.63268013,
  },
  {
    label: "Villanovafranca",
    longitude: 9.0014211,
    latitude: 39.64512436,
  },
  {
    label: "Villaperuccio",
    longitude: 8.6722357,
    latitude: 39.11158249,
  },
  {
    label: "Villaputzu",
    longitude: 9.57483207,
    latitude: 39.44008566,
  },
  {
    label: "Villasalto",
    longitude: 9.38877379,
    latitude: 39.49375831,
  },
  {
    label: "Villasimius",
    longitude: 9.52036544,
    latitude: 39.14238405,
  },
  {
    label: "Villasor",
    longitude: 8.94065031,
    latitude: 39.38068871,
  },
  {
    label: "Villaspeciosa",
    longitude: 8.92693384,
    latitude: 39.31280535,
  },
];
