import { City } from "Assets/ItalianCities";

export const computeCitiesHaversineDistance = (
  origin: City,
  destination: City
) => {
  /**
   * Compute the distance between two cities using the Haversine formula.
   **/

  // Radius of the Earth in kilometers
  const R: number = 6371.0;

  // Convert latitude and longitude from degrees to radians
  const [radLat1, radLon1, radLat2, radLon2]: number[] = [
    origin.latitude,
    origin.longitude,
    destination.latitude,
    destination.longitude,
  ].map((coord) => (Math.PI / 180) * coord);

  // Compute the differences in coordinates
  const dlat: number = radLat2 - radLat1;
  const dlon: number = radLon2 - radLon1;

  // Haversine formula to calculate distance
  const a: number =
    Math.sin(dlat / 2) ** 2 +
    Math.cos(radLat1) * Math.cos(radLat2) * Math.sin(dlon / 2) ** 2;
  const c: number = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distance in kilometers
  const distance: number = R * c;

  return distance;
};
