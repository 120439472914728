import { Button, ButtonGroup } from "@mui/material";
import styled from "styled-components";

export const StyledButtonGroup = styled(ButtonGroup)`
  width: 100%;
  justify-content: center;
`;

export const TopBarButton = styled(Button)`
  width: 33%;
`;
