import {
  MainStructureContainer,
  MainStructureContentContainer,
  MainStructureHeader,
  MainStructureHeaderLeft,
  MainStructureHeaderRight,
} from "UI/Structure/index.style";
import { FunctionComponent } from "react";

interface MainStructureProps {
  children: React.ReactNode;
}

export const MainStructure: FunctionComponent<MainStructureProps> = ({
  children,
}) => {
  return (
    <MainStructureContainer>
      <MainStructureHeader>
        <MainStructureHeaderLeft>
          <img
            src={require("Images/javis-logo-black.svg").default}
            style={{
              maxHeight: "80%",
              width: "auto",
            }}
            alt="mySvgImage"
          />
        </MainStructureHeaderLeft>
        <MainStructureHeaderRight></MainStructureHeaderRight>
      </MainStructureHeader>
      <MainStructureContentContainer>{children}</MainStructureContentContainer>
    </MainStructureContainer>
  );
};
