export interface Load {
  quantity?: number;
  weight?: number;
  height?: number;
  width?: number;
  length?: number;
}

export const DEFAULT_LOAD: Load = {
  quantity: 1,
  weight: undefined,
  height: undefined,
  width: undefined,
  length: undefined,
};
