import { hexToRgba } from "Utils/ColorConverter";
import styled from "styled-components";

const TOP_BAR_HEIGHT = "5em";

export const MainStructureContainer = styled.div`
  ::-webkit-scrollbar {
    opacity: 0;
  }
`;

export const MainStructureHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${TOP_BAR_HEIGHT};
  background: linear-gradient(
    0deg,
    ${(props) => hexToRgba(props.theme.palette.primary.main, 0)} 0%,
    ${(props) => hexToRgba(props.theme.palette.primary.main, 1)} 100%
  );
  display: flex;
  align-items: center;
  justify-content: space-between;
  backdrop-filter: blur(6px);
  z-index: 100000;
`;

export const MainStructureHeaderLeft = styled.div`
  margin-left: 1em;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const MainStructureHeaderRight = styled.div`
  margin-right: 1em;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const MainStructureContentContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
  height: calc(100vh - ${TOP_BAR_HEIGHT});
  padding-top: ${TOP_BAR_HEIGHT};
`;
