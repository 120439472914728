import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  Typography,
  Input,
  IconButton,
} from "@mui/material";
import { DEFAULT_LOAD, Load } from "DataInterfaces/Load";
import { FunctionComponent } from "react";
import update from "immutability-helper";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ValueAndSetter } from "Utils/ValueAndSetter";
import { hexToRgba } from "Utils/ColorConverter";

interface LoadsCardProps {
  loads: ValueAndSetter<Load[]>;
}

export const LoadsCard: FunctionComponent<LoadsCardProps> = ({ loads }) => {
  return (
    <Card sx={{ backgroundColor: `${hexToRgba("#8470FF", 0.1)} !important` }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Merci
        </Typography>
        <FormControl fullWidth>
          <Grid container rowGap={2} justifyContent="space-between">
            {loads.value.map((load, index) => (
              <Grid item xs={12}>
                <Grid container rowGap={2} justifyContent="space-between">
                  <Grid item xs={2}>
                    <Input
                      aria-label={"Quantità"}
                      placeholder={"Quantità"}
                      value={load.quantity}
                      onChange={(event) =>
                        loads.setValue(
                          update(loads.value, {
                            [index]: {
                              quantity: {
                                $set: Number(event.target.value) || undefined,
                              },
                            },
                          })
                        )
                      }
                      size={"small"}
                      startAdornment={
                        <Typography
                          color={"text.secondary"}
                          style={{ marginRight: 8 }}
                          fontSize={12}
                        >
                          Quantità:
                        </Typography>
                      }
                      endAdornment={
                        <Typography color={"text.secondary"}>plt</Typography>
                      }
                      type={"number"}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Input
                      aria-label={"Peso"}
                      value={load.weight}
                      onChange={(event) =>
                        loads.setValue(
                          update(loads.value, {
                            [index]: {
                              weight: {
                                $set: Number(event.target.value) || undefined,
                              },
                            },
                          })
                        )
                      }
                      size={"small"}
                      startAdornment={
                        <Typography
                          color={"text.secondary"}
                          style={{ marginRight: 8 }}
                          fontSize={12}
                        >
                          Peso:
                        </Typography>
                      }
                      endAdornment={
                        <Typography color={"text.secondary"}>kg</Typography>
                      }
                      type={"number"}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Input
                      aria-label={"Lunghezza"}
                      value={load.length}
                      onChange={(event) =>
                        loads.setValue(
                          update(loads.value, {
                            [index]: {
                              length: {
                                $set: Number(event.target.value) || undefined,
                              },
                            },
                          })
                        )
                      }
                      size={"small"}
                      startAdornment={
                        <Typography
                          color={"text.secondary"}
                          style={{ marginRight: 8 }}
                          fontSize={12}
                        >
                          Lunghezza:
                        </Typography>
                      }
                      endAdornment={
                        <Typography color={"text.secondary"}>cm</Typography>
                      }
                      type={"number"}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Input
                      aria-label={"Larghezza"}
                      value={load.width}
                      onChange={(event) =>
                        loads.setValue(
                          update(loads.value, {
                            [index]: {
                              width: {
                                $set: Number(event.target.value) || undefined,
                              },
                            },
                          })
                        )
                      }
                      size={"small"}
                      startAdornment={
                        <Typography
                          color={"text.secondary"}
                          style={{ marginRight: 8 }}
                          fontSize={12}
                        >
                          Larghezza:
                        </Typography>
                      }
                      endAdornment={
                        <Typography color={"text.secondary"}>cm</Typography>
                      }
                      type={"number"}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Input
                      aria-label={"Altezza"}
                      value={load.height}
                      onChange={(event) =>
                        loads.setValue(
                          update(loads.value, {
                            [index]: {
                              height: {
                                $set: Number(event.target.value) || undefined,
                              },
                            },
                          })
                        )
                      }
                      size={"small"}
                      startAdornment={
                        <Typography
                          color={"text.secondary"}
                          style={{ marginRight: 8 }}
                          fontSize={12}
                        >
                          Altezza:
                        </Typography>
                      }
                      endAdornment={
                        <Typography color={"text.secondary"}>cm</Typography>
                      }
                      type={"number"}
                    />
                  </Grid>
                  <Grid item xs={"auto"}>
                    <IconButton
                      aria-label="delete"
                      color={"error"}
                      size={"small"}
                      onClick={() => {
                        loads.setValue(
                          update(loads.value, {
                            $splice: [[index, 1]],
                          })
                        );
                      }}
                      disabled={loads.value.length === 1}
                    >
                      <DeleteOutlineOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Grid item xs={"auto"}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      loads.setValue([...loads.value, DEFAULT_LOAD]);
                    }}
                  >
                    Aggiungi colli
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      </CardContent>
    </Card>
  );
};
