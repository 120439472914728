import { City } from "Assets/ItalianCities";
import { Load } from "DataInterfaces/Load";
import { computeCitiesHaversineDistance } from "Utils/PriceComputer/ComputeDistance";

enum PriceCategory {
  P150,
  P300,
  P550,
  P1000,
}

const getHeightPriceCategory = (height: number): PriceCategory => {
  if (height <= 60) {
    return PriceCategory.P150;
  }
  if (height <= 80) {
    return PriceCategory.P300;
  }
  if (height <= 120) {
    return PriceCategory.P550;
  }
  return PriceCategory.P1000;
};

const getWeightPriceCategory = (weight: number): PriceCategory => {
  if (weight <= 150) {
    return PriceCategory.P150;
  }
  if (weight <= 300) {
    return PriceCategory.P300;
  }
  if (weight <= 550) {
    return PriceCategory.P550;
  }
  if (weight <= 1000) {
    return PriceCategory.P1000;
  }
  return PriceCategory.P1000;
};

const computeOverweightPercentualPriceIncrement = (weight: number): number => {
  if (weight <= 1000) {
    return 0;
  }
  const additionalWeight = weight - 1000;
  return additionalWeight / 1000;
};

const computeOversizedPercentualPriceIncrement = (
  width: number,
  length: number
): number => {
  if (width <= 120 && length <= 120) {
    return 0;
  }
  const area = width * length;

  if (area <= 120 * 120) {
    return 0;
  }

  const percentualPriceIncrement = (area * 1.25) / (120 * 120) - 1;

  console.log(percentualPriceIncrement);
  return percentualPriceIncrement > 0 ? percentualPriceIncrement : 0;
};

const computeStrictDeadlinePriceIncrement = (
  priceCategory: PriceCategory
): number => {
  switch (priceCategory) {
    case PriceCategory.P150:
      return 0.42;
    case PriceCategory.P300:
      return 0.33;
    case PriceCategory.P550:
      return 0.25;
    case PriceCategory.P1000:
      return 0.2;
  }
};

const SCALE_BY_PRICE_CATEGORY = {
  [PriceCategory.P150]: 30,
  [PriceCategory.P300]: 40,
  [PriceCategory.P550]: 60,
  [PriceCategory.P1000]: 85,
};

const SHIFT_BY_PRICE_CATEGORY = {
  [PriceCategory.P150]: 0.02,
  [PriceCategory.P300]: 0.03,
  [PriceCategory.P550]: 0.05,
  [PriceCategory.P1000]: 0.08,
};

const MAXIMUM_PRICE_BY_PRICE_CATEGORY = {
  [PriceCategory.P150]: 0.97,
  [PriceCategory.P300]: 1.37,
  [PriceCategory.P550]: 2.03,
  [PriceCategory.P1000]: 2.97,
};

export const computeTransportPrice = (
  origin: City,
  pickupDate: Date,
  destination: City,
  deliveryDate: Date,
  loads: Load[]
): number | undefined => {
  /**
   * Compute the price of a transport given the origin, the destination and the
   * loads.
   **/

  const distance = computeCitiesHaversineDistance(origin, destination);

  const minDeliveryTime = computeMinimumDeliveryTime(distance);
  if (computeHoursDifference(pickupDate, deliveryDate) < minDeliveryTime) {
    return undefined;
  }
  const isStrictDeadline =
    computeHoursDifference(pickupDate, deliveryDate) < minDeliveryTime + 24;

  const individualPrices = loads.map((load) => {
    if (
      !load.height ||
      !load.weight ||
      !load.width ||
      !load.length ||
      !load.quantity
    ) {
      throw new Error("Invalid load");
    }

    const heightPriceCategory = getHeightPriceCategory(load.height);
    const weightPriceCategory = getWeightPriceCategory(load.weight);
    const priceCategory = Math.max(
      heightPriceCategory,
      weightPriceCategory
    ) as PriceCategory;

    const pricePerKm = computePricePerKm(distance, priceCategory);
    const preIncrementPrice = pricePerKm * distance;

    const overweightPercentualPriceIncrement =
      computeOverweightPercentualPriceIncrement(load.weight);
    const oversizedPercentualPriceIncrement =
      computeOversizedPercentualPriceIncrement(load.width, load.length);
    const strictDeadlinePriceIncrement = isStrictDeadline
      ? computeStrictDeadlinePriceIncrement(priceCategory)
      : 0;

    return (
      load.quantity *
      preIncrementPrice *
      (1 +
        overweightPercentualPriceIncrement +
        oversizedPercentualPriceIncrement +
        strictDeadlinePriceIncrement)
    );
  });

  return individualPrices.reduce((a, b) => a + b, 0);
};

const computePricePerKm = (
  distance: number,
  priceCategory: PriceCategory
): number => {
  const pricePerKm =
    SCALE_BY_PRICE_CATEGORY[priceCategory] / distance +
    SHIFT_BY_PRICE_CATEGORY[priceCategory];

  return pricePerKm > MAXIMUM_PRICE_BY_PRICE_CATEGORY[priceCategory]
    ? MAXIMUM_PRICE_BY_PRICE_CATEGORY[priceCategory]
    : pricePerKm;
};

const computeHoursDifference = (date1: Date, date2: Date): number => {
  const timeDiffInMilliseconds = date2.valueOf() - date1.valueOf();
  return Math.floor(timeDiffInMilliseconds / (1000 * 60 * 60));
};

const computeMinimumDeliveryTime = (distance: number): number => {
  if (distance <= 350) {
    return 24;
  }
  if (distance <= 700) {
    return 48;
  }
  return 72;
};
