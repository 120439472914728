import { router } from "Router";
import { MainStructure } from "UI/Structure";
import { FunctionComponent } from "react";
import { RouterProvider } from "react-router";

export const App: FunctionComponent = () => {
  return (
    <MainStructure>
      <RouterProvider router={router} />
    </MainStructure>
  );
};
