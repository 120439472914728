import { createTheme } from "@mui/system";

export const lightTheme = createTheme({
  palette: {
    primary: { main: "#8470FF" },
    secondary: { main: "#003AD0" },
  },
  components: {},
  typography: {},
});
