import { FunctionComponent, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { SelectedTransportType } from "DataInterfaces/Transport";
import { TopBarButtonGroup } from "UI/Views/Search/TopBarButtonGroup";
import { SearchViewContainer } from "UI/Views/Search/index.style";
import { PickupDeliveryCard } from "UI/Views/Search/PickUpDeliveryCard";
import { LoadsCard } from "UI/Views/Search/LoadsCard";
import { Typography } from "@mui/material";
import { Country, DEFAULT_COUNTRY } from "Assets/Nations";
import { City, DEFAULT_CITY } from "Assets/ItalianCities";
import { DEFAULT_LOAD, Load } from "DataInterfaces/Load";
import { computeTransportPrice } from "Utils/PriceComputer/ComputePrice";
import LoadingButton from "@mui/lab/LoadingButton";

export const SearchView: FunctionComponent = () => {
  const [selectedTransportType, setSelectedTransportType] =
    useState<SelectedTransportType>(SelectedTransportType.PartialLoad);

  const [selectedCollectionCountry, setSelectedCollectionCountry] =
    useState<Country>(DEFAULT_COUNTRY);
  const [selectedCollectionCity, setSelectedCollectionCity] =
    useState<City>(DEFAULT_CITY);
  const [selectedCollectionDate, setSelectedCollectionDate] = useState<Date>();
  const [selectedDeliveryCountry, setSelectedDeliveryCountry] =
    useState<Country>(DEFAULT_COUNTRY);
  const [selectedDeliveryCity, setSelectedDeliveryCity] =
    useState<City>(DEFAULT_CITY);
  const [loads, setLoads] = useState<Load[]>([DEFAULT_LOAD]);
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState<Date>();

  const [price, setPrice] = useState<number>();
  const [isPriceVisualized, setIsPriceVisualized] = useState<boolean>(false);
  const [fakeLoading, setFakeLoading] = useState(false);

  const unsetPrice = () => {
    setPrice(0);
    setIsPriceVisualized(false);
  };

  const handleComputePrice = () => {
    setPrice(0);
    setIsPriceVisualized(false);
    setFakeLoading(true);

    // Simulate a delay between 2 and 3 seconds
    const delay = Math.floor(Math.random() * (3000 - 2000 + 1)) + 2000;
    // const delay = 0;

    setTimeout(() => {
      // Logic after the delay
      !!selectedCollectionDate &&
        !!selectedDeliveryDate &&
        setPrice(
          computeTransportPrice(
            selectedCollectionCity,
            selectedCollectionDate,
            selectedDeliveryCity,
            selectedDeliveryDate,
            loads
          )
        );
      setIsPriceVisualized(true);
      setFakeLoading(false);
    }, delay);
  };

  const isDataValid =
    !!selectedCollectionDate &&
    !!selectedDeliveryDate &&
    loads.every(
      (load) =>
        !!load.quantity &&
        !!load.height &&
        !!load.weight &&
        !!load.width &&
        !!load.length
    );

  useEffect(() => {
    unsetPrice();
  }, [
    selectedCollectionCountry,
    selectedCollectionCity,
    selectedDeliveryCountry,
    selectedDeliveryCity,
    loads,
  ]);

  return (
    <SearchViewContainer>
      <Grid container direction={"column"} rowGap={2}>
        <Grid container direction={"row"}>
          <Grid item xs />
          <Grid item xs={8}>
            <TopBarButtonGroup
              selectedTransportType={selectedTransportType}
              setSelectedTransportType={setSelectedTransportType}
            />
          </Grid>
          <Grid item xs />
        </Grid>

        <Grid container direction={"row"} justifyContent={"center"} spacing={2}>
          <Grid item xs={4.5}>
            <PickupDeliveryCard
              title="Ritiro"
              selectedCity={{
                value: selectedCollectionCity,
                setValue: setSelectedCollectionCity,
              }}
              selectedCountry={{
                value: selectedCollectionCountry,
                setValue: setSelectedCollectionCountry,
              }}
              selectedDate={{
                value: selectedCollectionDate,
                setValue: setSelectedCollectionDate,
              }}
            />
          </Grid>
          <Grid item xs={4.5}>
            <PickupDeliveryCard
              title="Consegna"
              selectedCity={{
                value: selectedDeliveryCity,
                setValue: setSelectedDeliveryCity,
              }}
              selectedCountry={{
                value: selectedDeliveryCountry,
                setValue: setSelectedDeliveryCountry,
              }}
              selectedDate={{
                value: selectedDeliveryDate,
                setValue: setSelectedDeliveryDate,
              }}
            />
          </Grid>
        </Grid>
        <Grid container direction={"row"} justifyContent={"center"} spacing={2}>
          <Grid item xs={9}>
            <LoadsCard loads={{ value: loads, setValue: setLoads }} />
          </Grid>
        </Grid>
        {isPriceVisualized && (
          <Grid container direction={"row"} justifyContent={"center"}>
            <Grid item xs={8}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant={"h4"}>
                  OFFERTA:{" "}
                  <Typography
                    variant={"h3"}
                    color={"primary"}
                    sx={{ textDecoration: "underline" }}
                    display={"inline"}
                  >
                    {!!price
                      ? `${price.toFixed(2)}€`
                      : "nessuna offerta disponibile"}
                  </Typography>
                </Typography>
              </div>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={2}
        >
          <Grid item xs={2}>
            <LoadingButton
              disabled={!isDataValid}
              loading={fakeLoading}
              variant={"contained"}
              fullWidth
              onClick={handleComputePrice}
            >
              {isPriceVisualized ? "Ricalcola" : "Calcola"} preventivo
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </SearchViewContainer>
  );
};
