import { SelectedTransportType } from "DataInterfaces/Transport";
import {
  StyledButtonGroup,
  TopBarButton,
} from "UI/Views/Search/TopBarButtonGroup/index.style";
import { FunctionComponent } from "react";

interface TopBarButtonGroupProps {
  selectedTransportType: SelectedTransportType;
  setSelectedTransportType: (
    selectedTransportType: SelectedTransportType
  ) => void;
}

export const TopBarButtonGroup: FunctionComponent<TopBarButtonGroupProps> = ({
  selectedTransportType,
  setSelectedTransportType,
}) => {
  const BUTTON_PROPS: {
    [key in SelectedTransportType]: {
      title: string;
      disabled: boolean;
    };
  } = {
    [SelectedTransportType.Express]: {
      title: "Espresso",
      disabled: true,
    },
    [SelectedTransportType.PartialLoad]: {
      title: "Trasporto parziale",
      disabled: false,
    },
    [SelectedTransportType.SharedLoad]: {
      title: "Carico condiviso",
      disabled: true,
    },
  };

  return (
    <StyledButtonGroup>
      {Object.values(SelectedTransportType).map(
        (selectedTransportTypeValue) => (
          <TopBarButton
            disabled={BUTTON_PROPS[selectedTransportTypeValue].disabled}
            key={selectedTransportTypeValue}
            onClick={() => {
              setSelectedTransportType(selectedTransportTypeValue);
            }}
            variant={
              selectedTransportType === selectedTransportTypeValue
                ? "contained"
                : "outlined"
            }
          >
            {BUTTON_PROPS[selectedTransportTypeValue].title}
          </TopBarButton>
        )
      )}
    </StyledButtonGroup>
  );
};
